import React, { useState } from "react";
import AmountDisbursed from "../main-components/AmountDisbursed";
import YearlyApplications from "../main-components/YearlyApplications";
import CourseWiseApplicant from "../main-components/CourseWiseApplicant";
import YearlyCourseCompletion from "../main-components/YearlyCourseCompletion";
import DistrictWiseApplication from "../main-components/DistrictWiseApplication";
import CountryWiseApplication from "../main-components/CountryWiseApplication";
import StatisticSection from "./StatisticSection";

const Statistic = ({ showStatistic, graph }) => {

  return (
    <>
      <section className="statistic_main">
        <div className="row mb-2" style={{ height: "220px" }} >
          <div className="col-lg-4 col-12" onClick={() => { showStatistic() }}>
            <YearlyApplications graph={graph.find((value) => { if (value?.type === "YEARLYAPPLICATION") return value })} />
          </div>
      
          <div className="col-lg-4 col-12" onClick={() => { showStatistic() }}>
            <AmountDisbursed graph={graph.find((value) => { if (value?.type === "AMOUNT_DISBURSED") return value })} />
          </div>
    
          <div className="col-lg-4 col-12" onClick={() => { showStatistic() }}>
            <CourseWiseApplicant graph={graph.find((value) => { if (value?.type === "COURSE_WISE_APPLICATION") return value })} />
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-lg-4 col-12" onClick={() => { showStatistic() }}>
            <YearlyCourseCompletion graph={graph.find((value) => { if (value.type === "YEARLYCOURSEAPPLICATION") return value })} />
          </div>
          <div className="col-lg-4 col-12" onClick={() => { showStatistic() }}>
            <DistrictWiseApplication graph={graph.find((value) => { if (value.type === "DISTRICT_WISE_APPLICATION") return value })} />
          </div>
          <div className="col-lg-4 col-12" onClick={() => { showStatistic() }}>
            <CountryWiseApplication graph={graph.find((value) => { if (value.type === "COUNTRYWISEAPPLICATION") return value })} />
          </div>
        </div>

      </section>

    </>
    );
  }


export default Statistic;