import './App.css';
import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import MainHeader from './common/MainHeader';
import { useDispatch, useSelector } from 'react-redux';
import ActionsHeader from './common/ActionsHeader';
import HomeScreen from './main-components/HomeScreen';
import { Redirect, useHistory } from 'react-router';
import SignIn from './main-components/SignIn';
import RegistrationForm from './main-components/RegistrationForm';
import ForgetPassword from './main-components/ForgetPassword';
import ApplicationProcess from './main-components/ApplicationProcess';
import TextModal from './modals/TextModal';
import Admin from './admin/Admin';
import { useEffect } from "react"
import { applicationCommunication } from './communications/application-communication';
import YesNoTextModal from './modals/YesNoTextModal';
import { useState } from 'react';
import { adminCommunication } from './communications/admin-communication';
import Footer from './common/Footer';
import FooterOwnership from './common/FooterOwnership';
import FrontOffice from './frontOffice/FrontOffice';
import BackOffice from './BackOffice/BackOffice';
import { frontOfficeCommunication } from './communications/front-office-communication';
import { backOfficeCommunication } from './communications/back-office-communication';
import WebsitePolicy from './main-components/WebsitePolicy';
import SocialJusticeAndSpecialAssistance from '../src/common/SocialJusticeAndSpecialAssistance';
import Commissioner from '../src/common/Commissioner';
import RegionalChart from '../src/common/RegionalChart';
import DivisionalCasteValidityCommittee from '../src/common/DivisionalCasteValidityCommittee';
import AdministrationProfileOfCorporation from '../src/common/AdministrationProfileOfCorporation';
import ResetPassword from './main-components/ResetPassword';
import Statistic from './common/Statistic';
import ApplicationFlowPreviewPublicPortal from './common/ApplicationFlowPreviewPublicPortal';

function App() {

  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
  const history = useHistory();

  const textModalShow = useSelector((state) => state.textModal);
  const yesNoTextModalShow = useSelector((state) => state.yesNoTextModal);

  const modalShow = useSelector((state) => state.textModal);

  let isUserAuthenticated = useSelector((state) => state.isUserAuthenticated);
  let isAdminAuthenticated = useSelector((state) => state.isAdminAuthenticated.value);
  let isFrontOfficeAuthenticated = useSelector((state) => state.isOfficeUserAuthenticated.value);
  let isBackOfficeAuthenticated = useSelector((state) => state.isBackOfficeAuthenticated.value);


  const dispatch = useDispatch();



  const [showActionsModal, setShowActionsModal] = useState(true);

  //To get full url
  let fullUrl = window.location.href;

  let splitUrlArray = fullUrl.split('/');


  async function initialLoad() {

    if (splitUrlArray[4] === 'user' && isUserAuthenticated === false) {
      let userJwtVerification = await applicationCommunication.getUserAuthenticateByJWT();
      if (userJwtVerification.status === "SUCCESS") {
        dispatch({ type: "AUTHENTICATE_USER", payload: userJwtVerification.jwt });
      } else {
        dispatch({ type: "DEAUTHENTICATE_USER" });
      }
    }
    if (splitUrlArray[4] === 'admin' && (isAdminAuthenticated === false || isAdminAuthenticated === undefined)) {
      let adminJwtVerification = await adminCommunication.getAdminAuthenticateByJWT();
      if (adminJwtVerification.status === "SUCCESS") {
        dispatch({ type: "AUTHENTICATE_ADMIN", payload: adminJwtVerification.jwt, adminData: adminJwtVerification.admin });
      } else {
        dispatch({ type: "DEAUTHENTICATE_ADMIN" });
      }
    }

    if (splitUrlArray[4] === 'front-office' && (isFrontOfficeAuthenticated === undefined || isFrontOfficeAuthenticated === false || isFrontOfficeAuthenticated === {})) {
      let officeUserJwtVerification = await frontOfficeCommunication.getOfficeUserAuthenticateByJWT();
      if (officeUserJwtVerification.status === "SUCCESS") {
        dispatch({ type: "AUTHENTICATE_FRONTOFFICEUSER", payload: officeUserJwtVerification.jwt, officeUser: officeUserJwtVerification.officeUser, tabs: officeUserJwtVerification.officeUser.tabs });
      } else {
        dispatch({ type: "DEAUTHENTICATE_FRONTOFFICEUSER" });
      }
    }

    if (splitUrlArray[4] === 'back-office' && (isBackOfficeAuthenticated === undefined || isBackOfficeAuthenticated === false || isBackOfficeAuthenticated === {})) {
      let backOfficeUserJwtVerification = await backOfficeCommunication.getBackOfficeUserAuthenticateByJWT();
      if (backOfficeUserJwtVerification.status === "SUCCESS") {
        dispatch({ type: "AUTHENTICATE_BACKOFFICEUSER", payload: backOfficeUserJwtVerification.jwt, backOfficeUserData: backOfficeUserJwtVerification.backOfficeUser, tabs: backOfficeUserJwtVerification.backOfficeUser.tabs });
      } else {
        dispatch({ type: "DEAUTHENTICATE_BACKOFFICEUSER" });
      }
    }
  }

  useEffect(() => {
    initialLoad();
  }, [fullUrl]);


  return (
    <HashRouter>
      <TextModal show={textModalShow.value} onHide={() => dispatch({ type: "HIDE_TEXT_MODAL" })} text={textModalShow.text} />
      <YesNoTextModal show={yesNoTextModalShow.value} onYesClick={() => dispatch({ type: "HIDE_YES_NO_TEXT_MODAL", payload: { text: "", answer: "YES" } })} onNoClick={() => dispatch({ type: "HIDE_YES_NO_TEXT_MODAL", payload: { text: "", answer: "NO" } })} text={textModalShow.text} />
      <div style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
        {
          showActionsModal
            ?
            <ActionsHeader></ActionsHeader>
            :
            <span></span>
        }
        <MainHeader></MainHeader>
        <Route exact path="/" render={() => {
          return (
            isUserAuthenticated ?
              <Redirect to="/user" /> :
              <HomeScreen />
          )
        }} />
        <Switch>
          <Route exact path="/login" component={SignIn} />
          <Route exact path="/register" component={RegistrationForm} />
          <Route exact path="/forgot-password" component={ForgetPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route path="/user" component={ApplicationProcess} />
          <Route path="/admin" component={Admin} />
          <Route path="/front-office" component={FrontOffice} />
          <Route path="/back-office" component={BackOffice} />
          <Route path="/applicationFlow" component={ApplicationFlowPreviewPublicPortal} />
          <Route path="/website-policy" component={WebsitePolicy} />
          <Route path="/social-justice-and-special-assistance" component={SocialJusticeAndSpecialAssistance} />
          <Route path="/commissioner" component={Commissioner} />
          <Route path="/regional-chart" component={RegionalChart} />
          <Route path="/divisional-caste-validity-committee" component={DivisionalCasteValidityCommittee} />
          <Route path="/administration-profile-of-corporation" component={AdministrationProfileOfCorporation} />
          <Route exact path="/home" render={() => {
            return (
              <HomeScreen />
            )
          }} />
        </Switch>
        {/* {(splitUrlArray[4] === "") ? */}
        {/* {(["back-office", "front-office", "admin", "user", "login"].includes(splitUrlArray[4]) === false) ?
          <Footer />
          :
          <span></span>
        } */}
        <FooterOwnership />
      </div>
    </HashRouter>
  );
}

export default App;
