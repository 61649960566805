import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { backOfficeCommunication } from '../../communications/back-office-communication';
import LoadingForms from '../../shared-components/LoadingForms';
import H4 from '../../text-components/H4';
import P from '../../text-components/P';
import ReactPaginate from "react-paginate";

const StudentReview = () => {
    const [marksUploadedUserList, setMarksUploadedUserList] = useState([]);
    const [filterUserList, setFilterUserList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;

    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const dispatch = useDispatch();
    const history = useHistory();

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchUserSemMarksBySearch(searchValue, currentPage + 1)
        } else {
            fetchUserSemMarks(selectedPage + 1)
        }
    }

    //Search Filter
    const searchFilter = async (searchString) => {
        console.log(searchString);
        if (searchString) {
            fetchUserSemMarksBySearch(searchString, currentPage + 1);
        } else {
            const responseFromServer = await backOfficeCommunication.getUserSemMarks()
            if (responseFromServer.data.status === "SUCCESS") {
                setMarksUploadedUserList(responseFromServer?.data?.userSemMarks);
                setFilterUserList(responseFromServer?.data?.userSemMarks);
                setPageCount(responseFromServer?.data?.userSemMarks)
            } else if (responseFromServer.status === "ERROR" || responseFromServer.jwt === null) {
                history.push('/login/back-office');
            } else {
                setMarksUploadedUserList([]);
                setFilterUserList([]);
                setPageCount(0);
            }
        }
    }

    //get user Sem Marks List By Search
    const fetchUserSemMarksBySearch = async (searchString, page) => {
        console.log();
        try {
            const responseFromServer = await backOfficeCommunication.getUserSemMarksBySearch(searchString, page)
            if (responseFromServer.data.status === "SUCCESS") {
                setMarksUploadedUserList(responseFromServer?.data?.userSemMarks);
                setFilterUserList(responseFromServer?.data?.userSemMarks);
                setPageCount(responseFromServer?.data?.userSemMarks)
            } else if (responseFromServer.status === "ERROR" || responseFromServer.jwt === null) {
                history.push('/login/back-office');
            } else {
                setMarksUploadedUserList([]);
                setFilterUserList([]);
                setPageCount(0);
            }
        } catch (error) {

        }
    }


    //get User sem List on Initial Load
    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');
    if (splitUrlArray.includes("student-review")) {
        dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "STUDENT_REVIEW" });
    } else {
        dispatch({ type: "HIDE_ACTIVE_BACK_OFFICE_TAB" });
    }
    const fetchUserSemMarks = async (page) => {
        try {
            showLoading("Loading...");
            const responseFromServer = await backOfficeCommunication.getUserSemMarks(page);
            console.log("inside code",responseFromServer);
            if (responseFromServer.data.status === "SUCCESS") {
                setMarksUploadedUserList(responseFromServer?.data?.userSemMarks);
                setFilterUserList(responseFromServer?.data?.userSemMarks);
                setPageCount(responseFromServer?.totalPages)
            } else if(responseFromServer?.data?.status === "JWT_INVALID") {
                history.push('/back-office/login')
                showModal(responseFromServer.data.message);
            }else{
                setMarksUploadedUserList([]);
                setFilterUserList([]);
                setPageCount(0)
            }

            hideLoading();
        } catch (error) {
            showModal(error.message);
            hideLoading();
        }
    }

    useEffect(() => {

        fetchUserSemMarks();

    }, []);

    // const filterStudents = (value, type) => {
    //     if (value === "") {
    //         setFilterUserList(marksUploadedUserList);
    //     } else {
    //         if (type === "NAME") {
    //             const filterData = marksUploadedUserList.filter((ele) => ele.name.toLowerCase().search(value.toLowerCase()) === -1 ? false : true);
    //             setFilterUserList(filterData);
    //         } else {
    //             const filterData = marksUploadedUserList.filter((ele) => ele.shortApplicationId.toLowerCase().search(value.toLowerCase()) === -1 ? false : true);
    //             setFilterUserList(filterData);
    //         }
    //     }
    // }

    const showSemesterList = (userId) => {
        history.push(`/back-office/dashboard/student-review-semester-list?userId=${userId}`);
    }

    return (
        <section className="dashboard_background">
            {isLoading?.value ?
                <LoadingForms message={isLoading?.message} />
                :
                <div className="container">
                    <div className="dashboard_back_office">
                        <div className="row search_wrapper">
                            <div className="col-lg-4 col-md-4 ps-0">
                                <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Application ID, Name, Status..." />
                            </div>
                            <div className="col-lg-8 col-md-8 pe-0 pagination_wrapper">
                            {pageCount > 1 &&    
                            <ReactPaginate
                                    previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                    nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            }
                            </div>
                        </div>
                        {/* <div className="back_office_search_box">
                            <div className="row">
                                <div className='col-2'></div>
                                <div className='col-3 search_box' >
                                    <input type="search" className="form-control" placeholder="Application Id" onChange={(event) => { filterStudents(event.target.value, "ApplicationID") }} />
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </div>
                                <div className="col-4 search_box">
                                    <input type="search" className="form-control" placeholder="User Name" onChange={(event) => { filterStudents(event.target.value, "NAME") }} />
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </div>
                                <div className='col-3'></div>
                            </div>
                        </div> */}
                        <div className="dashboard_view_data_heading">
                            <div className="row m-0">
                                <div className="col-2 p-0 view_dashboard">
                                    <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Serial No.</H4></span>
                                </div>
                                <div className="col-3 ps-3 view_dashboard text_align" >
                                    <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Application Id</H4></span>
                                </div>
                                <div className="col-4 ps-3 view_dashboard text_align" >
                                    <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">User Name</H4></span>
                                </div>
                                <div className="col-3 p-0 text_align">
                                    <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Status</H4></span>
                                </div>
                            </div>
                        </div>
                        {filterUserList?.length > 0 ?
                            <>
                                {filterUserList?.map((userList, index) => (
                                    <div className="dashboard_view_data" style={{ position: "relative" }} key={index}>
                                        <div className="row m-0">
                                            <div className="col-2 p-0 view_dashboard text_align">
                                                <span>
                                                    <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{limit * currentPage + (index + 1)}</P>
                                                </span>
                                            </div>
                                            <div className="col-3 ps-3 view_dashboard text_align" >
                                                <span>
                                                    <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a onClick={() => showSemesterList(userList?.userId)}>{userList?.shortApplicationId}</a> </P>
                                                </span>
                                            </div>
                                            <div className="col-4 ps-3 view_dashboard text_align">
                                                <span>
                                                    <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a onClick={() => showSemesterList(userList?.userId)}>{userList?.name}</a> </P>
                                                </span>
                                            </div>
                                            <div className="col-3 p-0 text_align">
                                                <span>
                                                    <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{userList?.status}</P>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                            :
                            <div className="col-12 text-center view_download_dashboard">
                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Record not available.</H4>
                            </div>
                        }
                    </div>

                </div>
            }
        </section>
    )
}

export default StudentReview