import { useSelector } from "react-redux";
import React from "react";

const Label = ({ whiteThemeColor, darkThemeColor, children }) => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);

    return (
        <label style={{ fontSize: `${13 * fontSizeMultiplier / 100}px`, fontFamily: 'Open Sans', marginBottom:"6px", color: `${darkWhiteThemeToggle === "WHITE" ? whiteThemeColor : darkThemeColor}` }}>{children}</label>
    );
}

export default Label;