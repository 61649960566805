import React, { useEffect, useState } from 'react'
import H5 from '../text-components/H5'
import { useDispatch } from "react-redux";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { communication } from '../communications/communication';
import Label from '../text-components/Label';

const UserMarksheetDetailForAutoTriggeredMilestones = ({ getMileStoneDocument, milestoneName, milestoneId, setShowMarksheetDetailForm, setHideSubjectTable, applicationId }) => {

    const [semester, setSemester] = useState("");
    // const [marksheetReport, setMarksheetReport] = useState("");
    const [typeOfMark, setTypeOfMark] = useState("");
    const [fileValidationMsg, setFileValidationMsg] = useState(false);
    const [semesterValidationMsg, setSemesterValidationMsg] = useState(false);
    const [validationMsg, setValidationMsg] = useState({ subject: false, heighestGrade: false, obtainedGrade: false });
    const [typeOfMarkValidationMsg, settypeOfMarkValidationMsg] = useState(false);
    const [addedSubjects, setAddedSubjects] = useState([]);
    const [grades, setGrades] = useState({
        subjects: "",
        heighestGrade: "",
        obtainedGrade: ""
    });
    const { subjects, heighestGrade, obtainedGrade } = grades;

    const [documents, setDocements] = useState([]);

    const dispatch = useDispatch();

    //If type of marks is grade then get the values of grades
    const gradeDetail = (event) => {
        setGrades({ ...grades, [event.target.name]: event.target.value });
    }

    //Get Documents
    const documentDetail = (event) => {
        setDocements({ ...documents, [event.target.name]: event.target.files[0] });
    }

    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        subject: "",
        totalMarks: "",
        obtainedMarks: "",
    }

    //****Validation for Designation Create form*****/
    let yupValidationSchema = Yup.object().shape({
        // subject: Yup.string().required("Subject Name is required"),
        // totalMarks: Yup.string().required("Total Marks is required"),
        // obtainedMarks: Yup.string().required("Obtained Marks is required")
        // .test("test", "obtained marks exceeds total marks", function (marks) {
        //     const { totalMarks } = this.parent;
        //     return Number(marks) <= Number(totalMarks);
        // }),
    });

    const { handleSubmit, handleChange, values, errors, handleReset, setFieldValue } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit

        onSubmit: async (values) => {


            if ((typeOfMark === "Percentage") && (values.obtainedMarks > values.totalMarks)) {
                showModal("Obtained marks can't be greater than total marks");
                return false;
            }

            let subject = "";
            let actualTotalMarks = "";
            let actualObtainedMarks = "";

            if ((typeOfMark === "Percentage") && ["undefined", "null", ""].includes(values.subject)) {
                setValidationMsg({ subject: true });
                return false;
            } else {
                setValidationMsg({ subject: false });
            }
            if ((typeOfMark === "Percentage") && ["undefined", "null", ""].includes(values.totalMarks)) {
                setValidationMsg({ heighestGrade: true });
                return false;
            } else {
                setValidationMsg({ heighestGrade: false });
            }

            if ((typeOfMark === "Percentage") && ["undefined", "null", ""].includes(values.obtainedMarks)) {
                setValidationMsg({ obtainedGrade: true });
                return false;
            }

            if ((typeOfMark === "Grade") && ["undefined", "null", ""].includes(subjects)) {
                setValidationMsg({ subject: true });
                return false;
            } else {
                setValidationMsg({ subject: false });
            }
            if ((typeOfMark === "Grade") && ["undefined", "null", ""].includes(heighestGrade)) {
                setValidationMsg({ heighestGrade: true });
                return false;
            } else {
                setValidationMsg({ heighestGrade: false });
            }

            if ((typeOfMark === "Grade") && ["undefined", "null", ""].includes(obtainedGrade)) {
                setValidationMsg({ obtainedGrade: true });
                return false;
            }

            if (typeOfMark === "Percentage") {
                subject = values.subject
                actualTotalMarks = values.totalMarks;
                actualObtainedMarks = values.obtainedMarks;
            }
            if (typeOfMark === "Grade") {
                subject = subjects;
                actualTotalMarks = heighestGrade;
                actualObtainedMarks = obtainedGrade;
            }

            let dataToAdd = {
                subject: subject,
                totalMarks: actualTotalMarks,
                obtainedMarks: actualObtainedMarks,
            }

            setAddedSubjects([...addedSubjects, { subject: dataToAdd?.subject, totalMarks: dataToAdd?.totalMarks, obtainedMarks: dataToAdd?.obtainedMarks }]);
            setGrades({
                subjects: "",
                heighestGrade: "",
                obtainedGrade: ""
            })
            handleReset();
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: yupValidationSchema,
    });

    // Delete added subjects from table
    const deleteSubject = (index) => {
        const updatedSubjectList = addedSubjects.filter((elem, id) => {
            return index !== id;
        })
        setAddedSubjects(updatedSubjectList);
    }

    const submitDetails = async () => {
        try {

            if (["undefined", "null", ""].includes(documents.marksheetReport)) {
                setFileValidationMsg(true);
                return false;
            } else {
                setFileValidationMsg(false);
            }
            if ([undefined, null, ""].includes(semester)) {
                setSemesterValidationMsg(true);
                return false;
            } else {
                setSemesterValidationMsg(false);
            }
            if ([undefined, null, ""].includes(typeOfMark)) {
                settypeOfMarkValidationMsg(true);
                return false;
            } else {
                settypeOfMarkValidationMsg(false);
            }
            if (addedSubjects?.length <= 1) {
                showModal("All Subjects Marks Required");
                return false;
            }

            // Send values to the server
            let IsFileUploaded = false;
            let formData = new FormData();
            if (!!documents?.hostelFeesReport || !!documents?.marksheetReport || !!documents?.attendenceReport || !!documents?.foodExpenseReport) {
                formData.append("hostelFeesReport", documents.hostelFeesReport, "hostelFeesReport");
                formData.append("marksheetReport", documents.marksheetReport, "marksheetReport");
                formData.append("attendenceReport", documents.attendenceReport, "attendenceReport");
                formData.append("foodExpenseReport", documents.foodExpenseReport, "foodExpenseReport");
                IsFileUploaded = true;
            }

            let dataToSend = {
                formData,
                semester,
                typeOfMark,
                totalMarks: addedSubjects,
                applicationId,
                IsFileUploaded,
                milestoneId
            }
            const serverResponse = await communication.uploadSemMarksAndMarkSheet(dataToSend);

            if (serverResponse?.data?.status === "SUCCESS") {
                showModal(serverResponse?.data?.message);

            } else {
                showModal(serverResponse?.data?.message);
            }
            getMileStoneDocument();
            setHideSubjectTable(false);
        }
        catch (error) {
            showModal(error?.message);
        }
    }

    return (
        <div className="marks_detail_section">

            <div className="mb-2">
                <span style={{ fontSize: "14px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
            </div>

            <div className="row mb-2 p-2">
                <div className="col-6">
                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Hostel Fees Report</H5>
                </div>
                <div className="col-6">
                    <input type="file" name="hostelFeesReport" onChange={(event) => { documentDetail(event); }} className="form-control form-control-sm" />
                </div>
            </div>

            <div className="row mb-2 p-2">
                <div className="col-6">
                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Attendance Report </H5>
                </div>
                <div className="col-6">
                    <input type="file" name="attendenceReport" onChange={(event) => { documentDetail(event); }} className="form-control form-control-sm" />
                </div>
            </div>

            <div className="row mb-2 p-2">
                <div className="col-6">
                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Foods Expense Report </H5>
                </div>
                <div className="col-6">
                    <input type="file" name="foodExpenseReport" onChange={(event) => { documentDetail(event); }} className="form-control form-control-sm" />
                </div>
            </div>

            <div className="row mb-2 p-2">
                <div className="col-6">
                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">MarkSheet Report </H5>
                </div>
                <div className="col-6">
                    <input type="file" name="marksheetReport" onChange={(event) => { documentDetail(event); }} className="form-control form-control-sm" />
                    {fileValidationMsg &&
                        <span style={{ color: "red" }}>Marksheet Report is Required</span>
                    }
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-6">
                    <select name="semester" onChange={(event) => setSemester(event.target.value)} className="form-select">
                        <option value="">Select Semester</option>
                        {milestoneName === "2ND_YEAR_1ST_INSTALLMENT_UNIVERSITY_FEE_AND_EXPENSES" &&
                            <option value="SECOND_SEMESTER">Semester II</option>
                        }
                        {milestoneName === "3RD_YEAR_1ST_INSTALLMENT_UNIVERSITY_FEE_AND_EXPENSES" &&
                            <option value="FOURTH_SEMESTER">Semester IV</option>
                        }
                        {milestoneName === "4TH_YEAR_1ST_INSTALLMENT_UNIVERSITY_FEE_AND_EXPENSES" &&
                            <option value="SIXTH_SEMESTER">Semester VI</option>
                        }
                        {milestoneName === "5TH_YEAR_1ST_INSTALLMENT_UNIVERSITY_FEE_AND_EXPENSES" &&
                            <option value="EIGHTH_SEMESTER">Semester VIII</option>
                        }
                    </select>
                    {semesterValidationMsg &&
                        <span style={{ color: "red" }}>Select Semester</span>
                    }
                </div>
                <div className="col-6">
                    <select name="typeOfMark" onChange={(event) => setTypeOfMark(event.target.value)} className="form-select">
                        <option>Select Marks Type</option>
                        <option value="Percentage">Percentage</option>
                        <option value="Grade">Grade</option>
                    </select>
                    {typeOfMarkValidationMsg &&
                        <span style={{ color: "red" }}>Select Marks Type</span>
                    }
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="row mb-2">
                    <div className="col-5">
                        {typeOfMark === "Grade" ?
                            <>
                                <H5>Subject Name</H5>
                                <input type="text" name="subjects" value={subjects} onChange={(event) => gradeDetail(event)} className="form-control" placeholder="Enter Subject Name" />
                                {validationMsg.subject === true &&
                                    <span style={{ color: "red" }}>Subject is Required</span>
                                }
                            </>
                            :
                            <>
                                <H5>Subject Name</H5>
                                <input type="text" name="subject" value={values.subject} onChange={handleChange} className="form-control" placeholder="Enter Subject Name" />
                                {validationMsg.subject === true &&
                                    <span style={{ color: "red" }}>Subject is Required</span>
                                }
                            </>
                        }
                    </div>
                    <div className="col-3">
                        {typeOfMark === "Grade" ?
                            <>
                                <H5>Heighest Grade</H5>
                                <select name="heighestGrade" value={heighestGrade} onChange={(event) => gradeDetail(event)} className="form-select">
                                    <option value="">Select Grade</option>
                                    <option value="A++">A++</option>
                                    <option value="A+">A+</option>
                                    <option value="A">A</option>
                                </select>
                                {validationMsg.heighestGrade === true &&
                                    <span style={{ color: "red" }}>Select Heighest Grade</span>
                                }
                            </>
                            :
                            <>
                                <H5>Total Marks</H5>
                                <input type={typeOfMark === "Grade" ? "text" : "number"} name="totalMarks" value={values.totalMarks} onChange={handleChange} className="form-control" placeholder="Enter Total Marks" />
                                {validationMsg.heighestGrade === true &&
                                    <span style={{ color: "red" }}>Total Marks is Required</span>
                                }
                            </>
                        }
                    </div>
                    <div className="col-3">
                        {typeOfMark === "Grade" ?
                            <>
                                <H5>Obtained Grade</H5>
                                <select name="obtainedGrade" value={obtainedGrade} onChange={(event) => gradeDetail(event)} className="form-select">
                                    <option value="">Select Grade</option>
                                    <option value="A++">A++</option>
                                    <option value="A+">A+</option>
                                    <option value="A">A</option>
                                    <option value="B++">B++</option>
                                    <option value="B+">B+</option>
                                    <option value="B">B</option>
                                    <option value="C++">C++</option>
                                    <option value="C+">C+</option>
                                    <option value="C">C</option>
                                </select>
                                {validationMsg.obtainedGrade === true &&
                                    <span style={{ color: "red" }}>Select Obtained Grade</span>
                                }
                            </>
                            :
                            <>
                                <H5>Obtained Marks</H5>
                                <input type={typeOfMark === "Grade" ? "text" : "number"} name="obtainedMarks" value={values.obtainedMarks} onChange={handleChange} className="form-control" placeholder="Enter Obtained Marks" />
                                {validationMsg.obtainedGrade === true &&
                                    <span style={{ color: "red" }}>Obtained Marks is Required</span>
                                }
                            </>
                        }
                    </div>
                    <div className="col-1 ps-0 add_button">
                        <button type="submit">Add</button>
                    </div>
                </div>
            </form>

            {/*-----Subject table----*/}
            {addedSubjects?.map((subject, index) => (
                <div className="row mb-2" key={index}>
                    <div className="col-5">
                        <input type="text" value={subject?.subject} name="subject" className="form-control" disabled />
                    </div>
                    <div className="col-3">
                        <input type="text" value={subject?.totalMarks} name="totalMarks" className="form-control" disabled />
                    </div>
                    <div className="col-3">
                        <input type="text" value={subject?.obtainedMarks} name="obtainedMarks" className="form-control" disabled />
                    </div>
                    <div className="col-1 delete_button">
                        <i className="fa fa-trash-o" aria-hidden="true" onClick={() => deleteSubject(index)}></i>
                    </div>
                </div>
            ))}

            <div className="row mt-4">
                <div className="col-12 submit_marks_button">
                    <button onClick={() => submitDetails()}>Submit</button>
                    <button className="ms-3" onClick={() => setShowMarksheetDetailForm(false)}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default UserMarksheetDetailForAutoTriggeredMilestones