import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import H2 from "../text-components/H2";
import H3 from "../text-components/H3";
import H4 from "../text-components/H4";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { communication } from "../communications/communication";
import {  useState ,useEffect } from "react";
import LoadingForms from "../shared-components/LoadingForms";


const Footer = () => {
    // spinner on loading 
    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const [feedbackFormSubmission, setFeedbackFormSubmission] = useState({});
    const history = useHistory();

    const validationSchema = Yup.object().shape({
        fullName: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Full Name is required"),
        email: Yup.string().email("must be a valid email").required("Email is required"),
        feedback: Yup.string().required("Comment is required")
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = async (data) => {

        setFeedbackFormSubmission((value) => {
            return { ...value, ...{ status: "LOADING" } };
        });

        let feedbackFormSubmitResponse = await communication.submitFeedbackForm(data);

        setFeedbackFormSubmission(value => {
            let updatedValues = {};
            updatedValues.status = feedbackFormSubmitResponse.status;
            updatedValues.message = feedbackFormSubmitResponse.message;
            return { ...value, ...updatedValues };
        });

        if (feedbackFormSubmitResponse.status === "FORM_SUBMITTED_SUCCESSFULLY") {
            reset();
        } else if (feedbackFormSubmitResponse.status === "VALIDATION_FAILED") {
            let errors = "";
            feedbackFormSubmitResponse.validationErrorsList.forEach(validationError => {
                errors = errors + `\n` + validationError.message;
            });
            setFeedbackFormSubmission(value => {
                console.log(value);
                return { ...value, ...{ message: errors } };
            });
        }
        alert(feedbackFormSubmitResponse.message);
    };

    function showWebsitePolicyPage() {
        history.push("/website-policy");
    }

    return (
        <div className="footer row pb-1 pt-4 m-0" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#1E2343" : "#000000", borderTop: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }}>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 p-0 order-3 order-md-1 footerLeft">
                <H2 whiteThemeColor="#FFFFFF" darkThemeColor="#FFFFFF"><b>Contact Us</b></H2>
                <div className="mt-3 mb-3">
                    <span style={{ display: "inline-block", alignItems: "center", cursor: "pointer" }}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 31.897 31.897">
                        <path id="Path_4" data-name="Path 4" d="M47.595,39.9A7.606,7.606,0,0,0,40,47.5V64.2A7.606,7.606,0,0,0,47.595,71.8H64.3A7.606,7.606,0,0,0,71.9,64.2V47.5A7.606,7.606,0,0,0,64.3,39.9Zm3.042,6.108a1.492,1.492,0,0,1,1.179.7c.3.469.767,1.2,1.375,2.148a2.85,2.85,0,0,1,.1,2.889l-1.112,1.583a1.515,1.515,0,0,0-.159,1.46,9.326,9.326,0,0,0,5,5,1.515,1.515,0,0,0,1.46-.159l1.583-1.112a2.852,2.852,0,0,1,2.889.1l2.148,1.375a1.492,1.492,0,0,1,.7,1.179,3.493,3.493,0,0,1-2.59,3.8c-.422,0-5.5.577-11.224-5.149s-5.149-10.8-5.149-11.224A3.493,3.493,0,0,1,50.637,46.011Zm0,0" transform="translate(-40 -39.902)" fill="#fff" />
                    </svg></span>
                    &nbsp;&nbsp;&nbsp;
                    <div style={{ display: "inline-block", alignItems: "center", fontSize: "16px" }}><H3 whiteThemeColor="#FFFFFF" darkThemeColor="#FFFFFF">Contact No : &nbsp;020 - 26127569</H3></div>
                </div>

                <div className="mt-1">
                    <span style={{ display: "inline-block", alignItems: "center", cursor: "pointer" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 39 39">
                            <image id="icons8-email-64" width="39" height="39" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAEzklEQVR4nO2aa8iURRSAn6MmXr6svFJkSURZRhdRNMqCksz6FdlFKbKIggikIhQiCPpjRRBFREVB5qULFFFoBZIVkaJlUtoNjNJMKYtSo7w9/ZiVxnX324u7+/nV+/zanTnnzJmzM2fOO+9CQUFBQUFBQUFBQcH/keiuUz0GGNYhX9rF9oj4vSENtUt9St1j72eP+qQ6uNJcK64A9WXg2saDfUSzJCJmlTceEgB1PPBJR1zqPOdGxLq8oU8FoTM75ExPcFZ5Q6UAvAesbr8vHedT4P3yxkMCEBE/AlOAxzvgVKdYCEyJiM0Naamz1J09mcIPk7/UOY1OeqJ6YvZ9nPplj06jOTapk7J5jDIl+IOolAPGAWvVaQARsR6YCLzUUCR7lg+AiRGxCkCdAKwCzi4XrBQAgOHAMnW+2icidkbETOB2YHebnG4VzwBTI2IrgHob8BFwcl3a6uyypbRcHZn1T1C/69hCrp8d6rWZnwPU58pkZpfPt9oKyLkEWKNOBoiINaQt8U5dEe0M3wDnR8QrAOpJpG1wSy3FegIAMBpYYSmjRsQvwHRgHrC/GY9byJvApIj4AkCdDqwl/UiN46FboJxFZg8W6iXqtpYu5vrYqz6g9in5EepcdV83OodsgWYCoOlYHJfpjFY/PswJNcIv6mXZ+EPU1+rQayoHVGIssFK9HiAiNpGqx4eatNcIa0lH3LsA6ljSEXdVS6xb3wrIeVrtn+nPMmXkdrBAHZiNNdPGKtWWbYFyVqtjMhunq583YacaB5W0aj9TjdIobQuA6s+WqseSnS51SZO2cjZZOoJLdkeYapNmaFkOqMRw4LwDX7LqcQ7NV4/LgfERsTJrG0A6ltuDza2AfeodmY0T1GHZ98nqDw3Y229a4n0zGxdkn49X1zfhZ1u2wF715kx/jPqtacL50h2uvl2HvT/UGZneAPX5Ut/8rH2E+lmDvrY8AH+rV2e6Z6ibs/496tysv1ax8rUH1xenquvKZB7O+o9TVzXgb0sDsFOdmulNMBUolSivHieoS03H5X51oykwgzKZK9Xfqth7TI2S3LHWX4S1LAC/qudnOherv9fQWW8qWvKxwuxcL7X1MZW43ZW0mt5bHAjC0eoHdfjdkgBsVc/J5K9Q/6xjcNVd6j1mv3TZ2OepH9ZpS9Pjbt+S7mBrH4+HHYDv1dMy2evU3Q04fIDt6mL1PvUu9REb28s5C9V+JX8Gqsu6kT2sAGxUT8nkbvTIeXX2qnpUya/+Vn8waroQ2gBcGBEbS4PcCbwA9KtTv93MAF5XB0TEbtJrvUX1KNYTgNXARRGxBcB0rD1BjTfLPcCVpCAMjIi9wE3AglpKtQKwArg0IrabMvajwPwaOj3J5aTL3K6I2Ee6Enu+O4XuAvAWMD0idpgy7bPA3S1ztX1cTArCkFIQbiWt2IpU28OLgdkRscf0rL8QuKblrraPC4Hl6rSI+NX0KL2vLk31RP+9Zxtk98fKkc4adWg2t1Hl862ayNQu4A3StXhvZgPpRclPlTqr/UNkKLAUmFSpvxfyFSmZbynvqJYEH+S/M3lIl7j3V+qolgTnkW5ebgD6V5HpLewGXgTurdRZ629yXcDI7mR6AdsiYldPO1FQUFBQUFBQUFBQcGTxD6C5hEm+SE7SAAAAAElFTkSuQmCC" />
                        </svg>
                    </span>
                    &nbsp;&nbsp;
                    <div style={{ display: "inline-block", alignItems: "center", fontSize: "16px" }}><H3 whiteThemeColor="#FFFFFF" darkThemeColor="#FFFFFF">Email ID : &nbsp;fs.directorsw@gmail.com</H3></div>
                </div>

                <br />

                <H2 whiteThemeColor="#FFFFFF" darkThemeColor="#FFFFFF"><b>Follow Us</b></H2>
                <div className="social_media_links mt-3">
                    <span><a href="https://www.facebook.com/MahaSjsa" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></span>
                    <span><a href="https://twitter.com/MahaSocialJ" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></span>
                    <span><a href="https://www.youtube.com/channel/UC3rG7F7p_nGZ8aMemOYezwQ" target="_blank"><i className="fa fa-youtube" aria-hidden="true"></i></a></span>
                    <span><a href="https://www.instagram.com/mahasjsa/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a></span>
                </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 flex-column p-0 order-2 order-md-2 footerMiddle">
                <H2 whiteThemeColor="#FFFFFF" darkThemeColor="#FFFFFF"><b>Head Office</b></H2>
                <H3 whiteThemeColor="#FFFFFF" darkThemeColor="#FFFFFF"><b>Chatrapati Shahu Maharaj Foreign Scholarship Department, Government of Maharashtra </b></H3>
                <H4 whiteThemeColor="#FFFFFF" darkThemeColor="#FFFFFF">Social Welfare Department Commissionerate, 3, Church Gate Road Pune - 411001</H4>
                {/* <H4 darkThemeColor="#FFFFFF">Pune - 411001</H4> */}
            </div>

            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-lg-0 mt-md-4 mb-lg-0 mb-md-4 p-0 order-1 order-md-3 footerRight">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row m-0">
                        <div className="col-6 p-0 name_input_box" style={{ display: "inline-block" }}>
                            <H4 whiteThemeColor="#FFFFFF" darkThemeColor="#FFFFFF">Full Name*</H4>
                            <input type="text" name="fullName" {...register("fullName")} className={`form-control ${errors.fullName ? "is-invalid" : ""}`} placeholder="" style={{ borderRadius: "10px", border: "0px", height: "32px", width: "100%", paddingLeft: "5px", backgroundColor: "#FFFFFF", border: "2px solid #FFFFFF", marginRight: "5px" }} />
                            <div className="invalid-feedback">{errors.fullName?.message}</div>
                        </div>

                        <div className="col-6 p-0 email_input_box" style={{ display: "inline-block", }}>
                            <H4 whiteThemeColor="#FFFFFF" darkThemeColor="#FFFFFF" >Email*</H4>
                            <input type="text" name="email" {...register("email")} className={`form-control ${errors.email ? "is-invalid" : ""}`} placeholder="" style={{ borderRadius: "10px", border: "0px", height: "32px", width: "100%", paddingLeft: "5px", backgroundColor: "#FFFFFF", border: "2px solid #FFFFFF", marginLeft: "5px" }} />
                            <div className="invalid-feedback">{errors.email?.message}</div>
                        </div>
                    </div>

                    <br />

                    <div className="row m-0">
                        <div className="col-12 p-0">
                            <H4 whiteThemeColor="#FFFFFF" darkThemeColor="#FFFFFF">Feedback</H4>
                            <textarea rows="4" name="feedback" {...register("feedback")} className={`form-control ${errors.feedback ? "is-invalid" : ""}`} placeholder="Comment" style={{ borderRadius: "10px", border: "0px", width: "100%", paddingLeft: "5px", backgroundColor: "#FFFFFF", border: "2px solid #FFFFFF" }} />
                            <div className="invalid-feedback">{errors.feedback?.message}</div>
                        </div>
                    </div>

                    <div className="row m-0 mt-2">
                        <div className="col-12 d-flex justify-content-end">
                            <div type="submit" style={{ justifyContent: "center", textAlign: "center", backgroundColor: "#FFFFFF", width: "100px", padding: "5px", border: "1px solid #C4C4C4", borderRadius: "50px", boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", alignItems: "end", display: "flex", cursor: "pointer" }}>
                                {
                                    feedbackFormSubmission?.status === "LOADING"
                                        ?
                                        <button className="no-display-button"><img src="/assets/images/gif/loading-circle.gif" style={{ width: "30px", height: "30px" }} /></button>
                                        :
                                        <button className="no-display-button"><H4><b>Submit</b></H4></button>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div className="col-12 d-flex justify-content-center order-4 pt-2 footerBottom">
                {/* <div style={{ cursor: "pointer" }}><H3 darkThemeColor="#FFFFFF">About Us</H3></div> */}
                {/* &nbsp;&nbsp;&nbsp;
                <H3 darkThemeColor="#FFFFFF">|</H3>
                &nbsp;&nbsp;&nbsp; */}
                <div onClick={() => showWebsitePolicyPage()} style={{ cursor: "pointer" }}><H3 whiteThemeColor="#FFFFFF" darkThemeColor="#FFFFFF">Website Policy</H3></div>
                {/* &nbsp;&nbsp;&nbsp;
                <H3 darkThemeColor="#FFFFFF">|</H3>
                &nbsp;&nbsp;&nbsp;
                <div style={{ cursor: "pointer" }}><H3 darkThemeColor="#FFFFFF">Site Map</H3></div> */}
            </div>
        </div>
    );
}

export default Footer;