import React, { useEffect, useState, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import H3 from "../../text-components/H3";
import H4 from "../../text-components/H4";
import { backOfficeCommunication } from "../../communications/back-office-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import H5 from "../../text-components/H5";
import ReactPaginate from "react-paginate";


const BackOfficeNotification = ({ changeHandler }) => {

    const BackOfficeUser = useSelector((state) => state?.isBackOfficeAuthenticated?.backOfficeUser);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const [notifications, setNotifications] = useState([]);
    const [filteredMilestoneData, setFilteredMilestoneData] = useState([]);
    const [userAlerts, setUserAlerts] = useState([]);
    const [filteredUserAlerts, setFilteredUserAlerts] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;



    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchNotificationBySearch(searchValue, selectedPage + 1)
        } else {
            fetchData(selectedPage + 1)
        }
    }

    // search filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            // console.log(searchString);
            fetchNotificationBySearch(searchString, currentPage + 1);
        } else {
            const getDataFromServer = await backOfficeCommunication.getLatestNotifications()
            if (getDataFromServer?.status === "SUCCESS") {
                setUserAlerts(getDataFromServer?.backOfficeAlert);
                setFilteredUserAlerts(getDataFromServer?.backOfficeAlert);
                setNotifications(getDataFromServer?.notifications);
                setFilteredMilestoneData(getDataFromServer?.notifications);
                setPageCount(getDataFromServer?.totalPages);

            } else if (getDataFromServer?.status === "ERROR" || getDataFromServer?.jwt === null) {
            }
            else {
                setPageCount(0);
                setUserAlerts([]);
                setFilteredUserAlerts([]);
            }
        }
    }

    // get notification by search
    const fetchNotificationBySearch = async (searchString, page) => {
       
        try {
            const getDataFromServer = await backOfficeCommunication.getNotificationBySearch(searchString, page);
            if (getDataFromServer?.status === "SUCCESS") {
                // console.log("searchdata",searchString);
                setUserAlerts(getDataFromServer?.backOfficeAlert);
                setFilteredUserAlerts(getDataFromServer?.backOfficeAlert);
                setNotifications(getDataFromServer?.notifications);
                setFilteredMilestoneData(getDataFromServer?.notifications);
                setPageCount(getDataFromServer?.totalPages);

            } else if (getDataFromServer?.status === "ERROR" || getDataFromServer?.jwt === null) {
            }
            else {
                setUserAlerts([]);
                setFilteredUserAlerts([]);
                setPageCount(0);
            }
        } catch (error) {
            showModal(error.message);
        }
    }


    // fetch data on inital load
    async function fetchData() {
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("notification")) {
            dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "NOTIFICATION" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
        }
        showLoading("Loading...");
        const getDataFromServer = await backOfficeCommunication.getLatestNotifications();
        // console.log("inside code", getDataFromServer);
        if (getDataFromServer?.status === "SUCCESS") {
            setUserAlerts(getDataFromServer?.backOfficeAlert);
            setFilteredUserAlerts(getDataFromServer?.backOfficeAlert);
            setNotifications(getDataFromServer?.notifications);
            setFilteredMilestoneData(getDataFromServer?.notifications);
            setPageCount(getDataFromServer?.totalPages);

        } else if (getDataFromServer?.status === "ERROR" || getDataFromServer?.jwt === null) {
        }
        else {
            showModal(getDataFromServer?.message);
        }
        hideLoading();
    }

    useEffect(() => {
        fetchData();
    }, []);


    // function onChangeFilterText(event, searchFor, searchForAlert) {
    //     if (searchFor === "SUBJECT") {
    //         const filterData = notifications.filter((ele) => ele.subject.search(event.target.value) === -1 ? false : true);
    //         setFilteredMilestoneData(filterData);
    //     }
    //     if (searchForAlert === "TITLE") {
    //         const filterData = userAlerts.filter((ele) => ele.title.search(event.target.value) === -1 ? false : true);
    //         setFilteredUserAlerts(filterData);
    //     }
    // }

    async function reviewHandler(Id, type) {
        const getDataFromServer = await backOfficeCommunication.changeNotificationStatus(Id, type);
        if (getDataFromServer?.status === "SUCCESS") {
            changeHandler();
        }

    }


    return (
        <section className="back_office_notification">
            {isLoading?.value ? <LoadingForms message={isLoading?.message} />
                : <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="row search_wrapper">
                                <div className="col-lg-4 col-md-4 ps-0">
                                    <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search..." />
                                </div>
                                <div className="col-lg-8 col-md-8 pe-0 pagination_wrapper">
                                    {pageCount > 1 &&
                                        <ReactPaginate
                                            previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            forcePage={currentPage}
                                            activeClassName={"active"} />
                                    }
                                </div>
                            </div>
                            {/* <div className="search_box_section">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 search_box">
                                        <input type="search" className="form-control" placeholder="Search" onChange={(event) => onChangeFilterText(event, "SUBJECT", "TITLE")} />
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div> */}
                            {
                                filteredMilestoneData?.length <= 0 && filteredUserAlerts?.length <= 0 ?

                                    <div className="text-center mt-5">
                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Records not available</H5>
                                    </div>
                                    :
                                    <>

                                        {
                                            !!filteredMilestoneData && filteredMilestoneData?.map((value, index) => {
                                                const { subject, description, createdDate } = value;

                                                return (
                                                    <div className="back_office_notification_card_main mt-3" key={index}>
                                                        <div className="row">
                                                            <div className="col-12 col-md-12 notification_card">
                                                                <div className="row">
                                                                    <div className="col-lg-8 col-md-9 card_left_col">
                                                                        <div className="notification_subject"><H3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}><b>{subject}</b></H3></div>
                                                                        <div className="firstLetterCap"><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>{description}</H4></div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-3 card_right_col">
                                                                        <div className="notification_time_date_main">
                                                                            {value?.isReviewed === false ?
                                                                                <div className="apply_btn">
                                                                                    <button onClick={() => reviewHandler(value._id, "NOTIFICATION")}>Review</button>
                                                                                </div>
                                                                                : ""
                                                                            }
                                                                            <div className="notification_time"><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>{createdDate?.day}/{createdDate?.month}/{createdDate?.year} {createdDate?.hour}:{createdDate?.minute}:{createdDate?.second}</H4></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        {
                                            !!filteredUserAlerts && filteredUserAlerts?.map((value, index) => {
                                                return (
                                                    <>
                                                        {
                                                            BackOfficeUser?.id === value?.userId && value?.type === "PRIVATE" || value?.type === "PUBLIC" ?
                                                                <div className="back_office_notification_card_main mt-3" key={index}>
                                                                    <div className="row">
                                                                        <div className="col-12 col-md-12 notification_card">
                                                                            <div className="row">
                                                                                <div className="col-lg-9 col-md-9 card_left_col">
                                                                                    <div className="notification_subject"><H3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}><b>{value?.title}</b></H3></div>
                                                                                    <div className="firstLetterCap"><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>{value?.message}</H4></div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-3 card_right_col">
                                                                                    <div className="notification_time_date_main">
                                                                                        {value?.isReviewed === false ?
                                                                                            <div className="apply_btn">
                                                                                                <button onClick={() => reviewHandler(value._id, "ALERT")}>Review</button>
                                                                                            </div>
                                                                                            : ""
                                                                                        }
                                                                                        <div className="notification_time"><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>{value?.createdDate?.day}/{value?.createdDate?.month}/{value?.createdDate?.year} {value?.createdDate?.hour}:{value?.createdDate?.minute}:{value?.createdDate?.second}</H4></div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : ""
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </>


                            }
                        </div>
                    </div>
                </div>
            }

        </section>
    );

}
export default memo(BackOfficeNotification);