import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import P from '../../text-components/P';
import H4 from "../../text-components/H4";
import { communication } from '../../communications/communication';
import { backOfficeCommunication } from '../../communications/back-office-communication';
import LoadingForms from '../../shared-components/LoadingForms';
import FirstSemesterGraph from './performanceGraphs/FirstSemesterGraph';

const StudentPerformance = () => {
    const [showYear, setShowYear] = useState(false);
    const [showStudentList, setShowStudentList] = useState(false);
    const [showGraph, setShowGraph] = useState(false);
    const [selectedStudentGraph, setSelectedStudentGraph] = useState({ university: "", currentYear: "", userId: "" });
    const [selectedUniversity, setSelectedUniversity] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [year, setYear] = useState([]);
    const [universityList, setUniversityList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [studentList, setStudentList] = useState([]);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [totalRejected, setTotalRejected] = useState(0);
    const [studentListLoader, setStudentListLoader] = useState(false);
    const [filteredStudentList, setFilteredStudentList] = useState([]);

    const dispatch = useDispatch();

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Search student list
    const searchStudentList = (event) => {
        const filteredData = studentList.filter((ele) => ele.name.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
        setFilteredStudentList(filteredData);
    }

    const FetchUniversityList = async () => {
        try {
            showLoading("Loading...");
            const responseFromServer = await communication.getUniversityRankList();

            if (responseFromServer.status === "SUCCESS") {
                setUniversityList(responseFromServer?.qsRankUniverstites?.universityList);
            }
            hideLoading();
        } catch (error) {
            alert(error.message);
        }
    }
    useEffect(() => {

        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("student-performance")) {
            dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "STUDENT_PERFORMANCE" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_BACK_OFFICE_TAB" });
        }

        // Calculate Year
        let date = new Date();
        for (let i = 2022; i <= date.getFullYear(); i++) {
            setYear([...year, i]);
        }

        FetchUniversityList();
    }, []);

    //Show university wise Years
    const showUniversityWiseYears = (university) => {
        setSelectedUniversity(university);
        setShowYear(!showYear);
    }

    //Show University and Year wise Student list
    const showUniversityAndYearWiseStudentsList = async (university, currentYear) => {
        setStudentListLoader(true);
        setSelectedYear(currentYear);
        const responseFromServer = await backOfficeCommunication.getStudentPerformance(university, currentYear);
        if (responseFromServer?.status === "SUCCESS") {
            setStudentList(responseFromServer?.userSemMarks);
            setTotalRejected(responseFromServer?.totalReject);
            setFilteredStudentList(responseFromServer?.userSemMarks);
        } else {
            setStudentList(responseFromServer?.userSemMarks);
            setTotalRejected(responseFromServer?.totalReject);
        }
        setStudentListLoader(false);
        setShowStudentList(!showStudentList);
    }

    //Show Selected Student Graph
    const showUniversityAndYearWiseStudentsGraph = async (university, currentYear, userId) => {
        setSelectedStudentGraph({ university: university, currentYear: currentYear, userId: userId });
        setShowGraph(!showGraph);
        return;
    }



    return (
        <section className="dashboard_background">
            {isLoading?.value ?
                <LoadingForms message={isLoading?.message} />
                :
                <div className="student_Performance_section">
                    <div className="back_office_search_box">
                        <div className="row">
                            <div className="col-3 search_box">
                                <input type="search" className="form-control" onChange={(event) => setFilteredData(event.target.value)} placeholder="Search by University name" />
                                <i className="fa fa-search" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    {universityList?.filter((university) => {
                        if (filteredData == "") {
                            return university;
                        } else if (university?.university.toLowerCase().includes(filteredData.toLowerCase())) {
                            return university;
                        }
                    }).map((university, index) => (
                        <div className="university_list_main" key={index}>
                            <div className="university_list">
                                <div className="university_name">
                                    {
                                        university?.university === selectedUniversity && totalRejected === 0 ?
                                            <P whiteThemeColor="green" darkThemeColor="green">{university?.university}</P>
                                            :
                                            <>
                                                {
                                                    (university?.university === selectedUniversity && totalRejected === 1) || (university?.university === selectedUniversity && totalRejected === 2) ?

                                                        <P whiteThemeColor="#FFCC00" darkThemeColor="#FFCC00">{university?.university}</P>
                                                        :
                                                        <>
                                                            {
                                                                university?.university === selectedUniversity && totalRejected >= 3 ?
                                                                    <P whiteThemeColor="red" darkThemeColor="red">{university?.university}</P>
                                                                    :
                                                                    <P whiteThemeColor="green" darkThemeColor="green">{university?.university}</P>

                                                            }
                                                        </>

                                                }
                                            </>
                                    }

                                </div>
                                {university?.university !== selectedUniversity ?
                                    < i className="fa fa-plus" aria-hidden="true" onClick={() => showUniversityWiseYears(university?.university)}></i>
                                    :
                                    < i className="fa fa-minus" aria-hidden="true" onClick={() => { showUniversityWiseYears(); setSelectedYear("") }}></i>
                                }
                            </div>
                            {showYear && university?.university === selectedUniversity &&

                                <div className="year_list">

                                    {year?.map((currentYear, index) => (
                                        <div key={index}>
                                            <div className="year">
                                                {
                                                    totalRejected === 0 ?
                                                        <P whiteThemeColor="green" darkThemeColor="green">{currentYear}</P>
                                                        :
                                                        <>
                                                            {
                                                                totalRejected === 1 || totalRejected === 2 ?

                                                                    <P whiteThemeColor="#FFCC00" darkThemeColor="#FFCC00">{currentYear}</P>
                                                                    :
                                                                    <>
                                                                        {
                                                                            totalRejected >= 3 ?
                                                                                <P whiteThemeColor="red" darkThemeColor="red">{currentYear}</P>
                                                                                :
                                                                                <P whiteThemeColor="green" darkThemeColor="green">{currentYear}</P>

                                                                        }
                                                                    </>

                                                            }
                                                        </>
                                                }
                                                {university?.university == selectedUniversity && currentYear !== selectedYear ?
                                                    <i className="fa fa-plus" aria-hidden="true" onClick={() => showUniversityAndYearWiseStudentsList(university?.university, currentYear)}></i>
                                                    :
                                                    <i className="fa fa-minus" aria-hidden="true" onClick={() => showUniversityAndYearWiseStudentsList()}></i>
                                                }
                                            </div>
                                            {studentListLoader === true ?
                                                <div className="loading">
                                                    <span></span>
                                                </div>
                                                :
                                                <div className="Student_list_main">
                                                    {showStudentList && currentYear === selectedYear &&
                                                        <>
                                                            {studentList?.length > 0 ?
                                                                <>
                                                                    {studentList?.length > 5 &&
                                                                        <div className="back_office_search_box">
                                                                            <div className="row">
                                                                                <div className="col-3 search_box" style={{ margin: "0", marginBottom: "8px" }}>
                                                                                    <input type="search" className="form-control" onChange={(event) => searchStudentList(event)} placeholder="Search by University name" />
                                                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        filteredStudentList?.map((student, index) => (

                                                                            <div key={index}>
                                                                                <div className="student_list">
                                                                                    {
                                                                                        student?.reject === 0 ?
                                                                                            <P whiteThemeColor="green" darkThemeColor="green">{student?.name}</P>
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    student?.reject === 1 || student?.reject === 2 ?

                                                                                                        <P whiteThemeColor="#FFCC00" darkThemeColor="#FFCC00">{student?.name}</P>
                                                                                                        :
                                                                                                        <>
                                                                                                            {
                                                                                                                student?.reject >= 3 ?
                                                                                                                    <P whiteThemeColor="red" darkThemeColor="red">{student?.name}</P>
                                                                                                                    :
                                                                                                                    <P whiteThemeColor="green" darkThemeColor="green">{student?.name}</P>

                                                                                                            }
                                                                                                        </>

                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                    {student?.userId !== selectedStudentGraph?.userId ?
                                                                                        <i className="fa fa-plus" aria-hidden="true" onClick={() => showUniversityAndYearWiseStudentsGraph(university?.university, currentYear, student?.userId)}></i>
                                                                                        :
                                                                                        <i className="fa fa-minus" aria-hidden="true" onClick={() => showUniversityAndYearWiseStudentsGraph()}></i>
                                                                                    }
                                                                                </div>
                                                                                {showGraph && university?.university === selectedStudentGraph?.university && currentYear === selectedStudentGraph?.currentYear && student?.userId === selectedStudentGraph?.userId &&
                                                                                    <div className="row text-center">
                                                                                        {student?.semester?.length > 0 ?
                                                                                            <>
                                                                                                {student?.reject === 0 ?
                                                                                                    <div style={{ fontWeight: "bold", color: "green" }}>Warning : {student?.reject}</div>
                                                                                                    :
                                                                                                    <>
                                                                                                        {student?.reject === 1 || student?.reject === 2 ?
                                                                                                            <div style={{ fontWeight: "bold", color: "#FFCC00" }}>Warning : {student?.reject}</div>
                                                                                                            :
                                                                                                            <>
                                                                                                                {student?.reject >= 3 ?
                                                                                                                    <div style={{ fontWeight: "bold", color: "red" }}>Warning : {student?.reject}</div>
                                                                                                                    :
                                                                                                                    <div style={{ fontWeight: "bold", color: "green" }}>Warning : {student?.reject}</div>
                                                                                                                }
                                                                                                            </>
                                                                                                        }
                                                                                                    </>
                                                                                                }
                                                                                                {student?.semester?.map((semester, index) => {

                                                                                                    return (
                                                                                                        <div className="col-6" key={index}>
                                                                                                            <FirstSemesterGraph typeOfMark={semester?.typeOfMark} semesterName={(semester?.semester.split('_').join(' '))} totalMarks={semester?.totalMarks} />
                                                                                                        </div>
                                                                                                    );
                                                                                                })
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            <div className="text-center mt-2 mb-2">
                                                                                                <h4>Data not Available</h4>
                                                                                            </div>
                                                                                        }

                                                                                    </div>
                                                                                }

                                                                            </div>
                                                                        ))
                                                                    }
                                                                </>
                                                                :
                                                                <div className="text-center mt-2 mb-2">
                                                                    <h4>Students not Available</h4>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </div>

                                            }
                                        </div>
                                    ))}

                                </div>
                            }
                        </div>
                    ))}

                </div>
            }
            {/* <div className="student_performance_select_box">
                    <div className="row">
                        <div className="col-4">
                            <select name="university" className="form-select">
                                <option value="" >Select University</option>
                                {universityList?.map((university, index) => {
                                    return (
                                        <option value="" key={index}>{university?.university}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-4">
                            <select name="year" className="form-select">
                                <option value="" >Select Year</option>
                                {year?.map((currentYear, index) => {
                                    return (
                                        <option value={currentYear} key={index}>{currentYear}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-4">
                            <select name="studentList" className="form-select">
                                <option value="" >Select Student</option>
                            </select>
                        </div>
                    </div>
                </div> */}

            {/* <div className="row">
                    <div className="col-6">
                        <FirstSemesterGraph />
                    </div>
                    <div className="col-6">
                        <SecondSemesterGraph />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-6">
                        <ThirdSemesterGraph />
                    </div>
                    <div className="col-6">
                        <FourthSemesterGraph />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-6">
                        <FifthSemesterGraph />
                    </div>
                    <div className="col-6">
                        <SixthSemesterGraph />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <H4>Warning : 0</H4>
                    </div>
                </div> */}

        </section >
    )
}

export default StudentPerformance
