import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import H4 from '../text-components/H4';
import Label from "../text-components/Label";
import FormFieldTitle from "./FormFieldTitle";
import { useLocation } from "react-router-dom";
import { applicationCommunication } from "../communications/application-communication";
import { useFormik } from 'formik';
//****  District list Array object for Maping   *****//
import { useHistory } from "react-router";
import LoadingForms from "../shared-components/LoadingForms";
import CurrencyList from "./ArrayObjectFolder/CurrencyList";
import { communication } from "../communications/communication"

const EducationExpensesDetailForm = () => {

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    // Dark White Theme
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    //****  Stopping field input- Enable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);

    const [isSubmitted, setIsSubmitted] = useState(false);

    // cancel, save and submit buttons unable & desable
    const [disableActions, setDisableActions] = useState(false);

    // spinner on loading 
    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    const [applicationId, setApplicationId] = useState(null);

    const [initialFormValues, setInitialFormValues] = useState(null);

    const [saveType, setSaveType] = useState(null);

    const history = useHistory();

    const location = useLocation();

    const [totalExpensesForeignOneYear, setTotalExpensesForeignOneYear] = useState(0);

    const [totalExpensesINROneYear, setTotalExpensesINROneYear] = useState(0);

    const [totalExpensesForeignFullYear, setTotalExpensesForeignFullYear] = useState(0);

    const [totalExpensesINRFullYear, setTotalExpensesINRFullYear] = useState(0);


    // To get current location url
    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');

    //** Fetching parent detail form ***//
    const fetchEducationExpenseData = async () => {
        try {

            showLoading("Loading Form...");

            // to show active tab
            let fullUrl = window.location.href;
            let splitUrlArray = fullUrl.split('/');
            let educationExpensesDetailUrl = "education-expenses-detail?applicationId";
            let spliteducationExpensesDetailUrl = educationExpensesDetailUrl.split('?');
            if (spliteducationExpensesDetailUrl.includes("education-expenses-detail")) {
                dispatch({ type: "SHOW_ACTIVE_APPLICATION_FORM_TAB", payload: "EDUCATION_EXPENSES_DETAILS" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_APPLICATION_FORM_TAB" });
            }

            const queryParams = new URLSearchParams(location.search);
            const applicationIdFromParams = await queryParams.get('applicationId');
            setApplicationId(applicationIdFromParams);

            // Fetching form from the server
            const getEducationExpensesDetailsFormResponse = await applicationCommunication.getEducationExpensesDetailsForm(applicationIdFromParams);
            // // Handling response from the server
            if (getEducationExpensesDetailsFormResponse.status === "EDUCATION_EXPENSES_DETAILS_FORM_FETCHED_SUCCESSFULLY") {
                let educationExpensesDetailsForm = getEducationExpensesDetailsFormResponse.educationExpensesDetailsForm;
                // // setting new form values received from the server
                setInitialFormValues(value => {
                    return {
                        ...value, ...{
                            currencyType: educationExpensesDetailsForm.currencyType,
                            oneYearEducationFeesForeignCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.educationFees.foreignCurrency,
                            oneYearEducationFeesIndianCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.educationFees.indianCurrency,
                            oneYearExamFeesForeignCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.examFees.foreignCurrency,
                            oneYearExamFeesIndianCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.examFees.indianCurrency,
                            oneYearRegistrationFeesForeignCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.registrationFees.foreignCurrency,
                            oneYearRegistrationFeesIndianCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.registrationFees.indianCurrency,
                            oneYearFoodAndResidentialExpensesForeignCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.foodAndResidentialExpenses.foreignCurrency,
                            oneYearFoodAndResidentialExpensesIndianCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.foodAndResidentialExpenses.indianCurrency,
                            oneYearAcadamicExpensesForeignCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.otherExpensesForAcademics.foreignCurrency,
                            oneYearAcadamicExpensesIndianCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.otherExpensesForAcademics.indianCurrency,
                            oneYearTotalExpensesForeignCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.totalExpenses.foreignCurrency,
                            oneYearTotalExpensesIndianCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.totalExpenses.indianCurrency,
                            oneYearAcadamicVisaExpenseForeignCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.visaExpense.foreignCurrency,
                            oneYearAcadamicVisaExpenseIndianCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.visaExpense.indianCurrency,
                            oneYearAcadamicInsuranceExpenseForeignCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.insuranceExpense.foreignCurrency,
                            oneYearAcadamicInsuranceExpenseIndianCurrency: educationExpensesDetailsForm.expenditureDetails.oneYearExpenses.insuranceExpense.indianCurrency,

                            wholeCourceEducationFeesForeignCurrency: educationExpensesDetailsForm.expenditureDetails.wholeCourseExpenses.educationFees.foreignCurrency,
                            wholeCourceEducationFeesIndianCurrency: educationExpensesDetailsForm.expenditureDetails.wholeCourseExpenses.educationFees.indianCurrency,
                            wholeCourceExamFeesForeignCurrency: educationExpensesDetailsForm.expenditureDetails.wholeCourseExpenses.examFees.foreignCurrency,
                            wholeCourceExamFeesIndianCurrency: educationExpensesDetailsForm.expenditureDetails.wholeCourseExpenses.examFees.indianCurrency,
                            wholeCourceRegistrationFeesForeignCurrency: educationExpensesDetailsForm.expenditureDetails.wholeCourseExpenses.registrationFees.foreignCurrency,
                            wholeCourceRegistrationFeesIndianCurrency: educationExpensesDetailsForm.expenditureDetails.wholeCourseExpenses.registrationFees.indianCurrency,
                            wholeCourceFoodAndResidentialExpensesForeignCurrency: educationExpensesDetailsForm.expenditureDetails.wholeCourseExpenses.foodAndResidentialExpenses.foreignCurrency,
                            wholeCourceFoodAndResidentialExpensesIndianCurrency: educationExpensesDetailsForm.expenditureDetails.wholeCourseExpenses.foodAndResidentialExpenses.indianCurrency,
                            wholeCourceAcadamicExpensesForeignCurrency: educationExpensesDetailsForm.expenditureDetails.wholeCourseExpenses.otherExpensesForAcademics.foreignCurrency,
                            wholeCourceAcadamicExpensesIndianCurrency: educationExpensesDetailsForm.expenditureDetails.wholeCourseExpenses.otherExpensesForAcademics.indianCurrency,
                            wholeCourceTotalExpensesForeignCurrency: educationExpensesDetailsForm.expenditureDetails.wholeCourseExpenses.totalExpenses.foreignCurrency,
                            wholeCourceTotalExpensesIndianCurrency: educationExpensesDetailsForm.expenditureDetails.wholeCourseExpenses.totalExpenses.indianCurrency,
                            wholeCourceAcadamicVisaExpenseForeignCurrency: educationExpensesDetailsForm?.expenditureDetails?.wholeCourseExpenses?.visaExpense?.foreignCurrency,
                            wholeCourceAcadamicVisaExpenseIndianCurrency: educationExpensesDetailsForm?.expenditureDetails?.wholeCourseExpenses?.visaExpense?.indianCurrency,
                            wholeCourceAcadamicInsuranceExpenseForeignCurrency: educationExpensesDetailsForm?.expenditureDetails?.wholeCourseExpenses?.insuranceExpense?.foreignCurrency,
                            wholeCourceAcadamicInsuranceExpenseIndianCurrency: educationExpensesDetailsForm?.expenditureDetails?.wholeCourseExpenses?.insuranceExpense?.indianCurrency,

                            otherScholarship: educationExpensesDetailsForm.approvedItems.otherScholarship,
                            fellowship: educationExpensesDetailsForm.approvedItems.fellowship,
                            graduateTeacherAttendanceSlip: educationExpensesDetailsForm.approvedItems.graduateTeacherAttendanceSlip,
                            otherHonorarian: educationExpensesDetailsForm.approvedItems.otherHonorarian,
                            job: educationExpensesDetailsForm.approvedItems.job,

                        }
                    };
                });

                setDisabled(value => value = educationExpensesDetailsForm.isSubmitted);
                setIsSubmitted(value => value = educationExpensesDetailsForm.isSubmitted);
                hideLoading();
                // handleReset();
            } else {
                showModal(getEducationExpensesDetailsFormResponse.message);
            }

            //Checking application status is incomplete & making fields undisabled
            const applicationsFromServer = await communication.getMyApplications(applicationIdFromParams);

            if (applicationsFromServer.status === "SUCCESS") {
                const applicationStatus = applicationsFromServer.userApplications[0].applicationStatus

                if (applicationStatus === "INCOMPLETE") {
                    setDisabled(false);
                } else if (applicationStatus === "SELECTED" || applicationStatus === "COMPLETED" || applicationStatus === "APPLICATION_SUBMITTED") {
                    setDisabled(true);
                    setIsSubmitted(true);
                }

            }
            hideLoading();

        } catch (error) {
            hideLoading()
            showModal(error.message)
        }
    }

    useEffect(() => {

        fetchEducationExpenseData();

    }, []);

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    function loadNextForm() {
        history.push(`/user/application/document-submission-details?applicationId=${applicationId}`);
    }

    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        currencyType: "",
        oneYearEducationFeesForeignCurrency: "",
        oneYearEducationFeesIndianCurrency: "",

        wholeCourceEducationFeesForeignCurrency: "",
        wholeCourceEducationFeesIndianCurrency: "",

        oneYearExamFeesForeignCurrency: "",
        oneYearExamFeesIndianCurrency: "",

        wholeCourceExamFeesForeignCurrency: "",
        wholeCourceExamFeesIndianCurrency: "",

        oneYearRegistrationFeesForeignCurrency: "",
        oneYearRegistrationFeesIndianCurrency: "",

        wholeCourceRegistrationFeesForeignCurrency: "",
        wholeCourceRegistrationFeesIndianCurrency: "",

        oneYearFoodAndResidentialExpensesForeignCurrency: "",
        oneYearFoodAndResidentialExpensesIndianCurrency: "",

        wholeCourceFoodAndResidentialExpensesForeignCurrency: "",
        wholeCourceFoodAndResidentialExpensesIndianCurrency: "",

        oneYearAcadamicExpensesForeignCurrency: "",
        oneYearAcadamicExpensesIndianCurrency: "",

        wholeCourceAcadamicExpensesForeignCurrency: "",
        wholeCourceAcadamicExpensesIndianCurrency: "",

        oneYearAcadamicVisaExpenseForeignCurrency: "",
        oneYearAcadamicVisaExpenseIndianCurrency: "",

        wholeCourceAcadamicVisaExpenseForeignCurrency: "",
        wholeCourceAcadamicVisaExpenseIndianCurrency: "",

        oneYearAcadamicInsuranceExpenseForeignCurrency: "",
        oneYearAcadamicInsuranceExpenseIndianCurrency: "",

        wholeCourceAcadamicInsuranceExpenseForeignCurrency: "",
        wholeCourceAcadamicInsuranceExpenseIndianCurrency: "",

        oneYearTotalExpensesForeignCurrency: 0,
        oneYearTotalExpensesIndianCurrency: 0,

        wholeCourceTotalExpensesForeignCurrency: 0,
        wholeCourceTotalExpensesIndianCurrency: 0,

        otherScholarship: null,
        fellowship: null,
        graduateTeacherAttendanceSlip: null,
        otherHonorarian: null,
        job: null
    }


    //***** Yup libary Form Validation  ****//
    let validationSchema = Yup.object().shape({

        currencyType: Yup.string().required("Currency is required"),

        //*----validation for education fess-----*//
        oneYearEducationFeesForeignCurrency: Yup.number().required("Education Fee is required").typeError('Education Fee must be a number')
            .positive('Education Fee must be greater than zero').max(100000000, "Education Fees can't be greater than 10 crore"),

        oneYearEducationFeesIndianCurrency: Yup.number().required("Education Fee is required").typeError('Education Fee must be a number')
            .positive('Education Fee must be greater than zero').max(100000000, "Education Fees can't be greater than 10 crore"),

        wholeCourceEducationFeesForeignCurrency: Yup.number().required("Education Fee is required").typeError('Education Fee must be a number')
            .positive('Education Fee must be greater than zero').max(100000000, "Education Fees can't be greater than 10 crore"),

        wholeCourceEducationFeesIndianCurrency: Yup.number().required("Education Fee is required").typeError('Education Fee must be a number')
            .positive('Education Fee must be greater than zero').max(100000000, "Education Fees can't be greater than 10 crore"),

        //*----validation for exam fess-----*//
        oneYearExamFeesForeignCurrency: Yup.number().required("Exam Fee is required").typeError('Exam Fee Fee must be a number')
            .positive('Exam Fee must be greater than zero').max(100000000, "Exam Fee can't be greater than 10 crore"),

        oneYearExamFeesIndianCurrency: Yup.number().required("Exam Fee is required").typeError('Exam Fee Fee must be a number')
            .positive('Exam Fee must be greater than zero').max(100000000, "Exam Fees can't be greater than 10 crore"),

        wholeCourceExamFeesForeignCurrency: Yup.number().required("Exam Fee is required").typeError('Exam Fee Fee must be a number')
            .positive('Exam Fee must be greater than zero').max(100000000, "Exam Fees can't be greater than 10 crore"),

        wholeCourceExamFeesIndianCurrency: Yup.number().required("Exam Fee is required").typeError('Exam Fee Fee must be a number')
            .positive('Exam Fee must be greater than zero').max(100000000, "Exam Fees can't be greater than 10 crore"),

        //*----validation for Registration fess-----*//
        oneYearRegistrationFeesForeignCurrency: Yup.number().required("Registration Fees is required").typeError('Registration Fee must be a number')
            .positive('Registration Fee must be greater than zero').max(100000000, "Registration Fees can't be greater than 10 crore"),

        oneYearRegistrationFeesIndianCurrency: Yup.number().required("Registration Fees is required").typeError('Registration Fee must be a number')
            .positive('Registration Fee must be greater than zero').max(100000000, "Registration Fees can't be greater than 10 crore"),

        wholeCourceRegistrationFeesForeignCurrency: Yup.number().required("Registration Fees is required").typeError('Registration Fee must be a number')
            .positive('Registration Fee must be greater than zero').max(100000000, "Registration Fees can't be greater than 10 crore"),

        wholeCourceRegistrationFeesIndianCurrency: Yup.number().required("Registration Fees is required").typeError('Registration Fee must be a number')
            .positive('Registration Fee must be greater than zero').max(100000000, "Registration Fees can't be greater than 10 crore"),

        //*----validation for Food and Residential -----*//
        oneYearFoodAndResidentialExpensesForeignCurrency: Yup.number().required("Food & Residential Expenses is required").typeError('Food & Residential Expenses Fee must be a number')
            .positive('Food & Residential Expenses Fee must be greater than zero').max(100000000, "Food & Residential Expenses Fees can't be greater than 10 crore"),

        oneYearFoodAndResidentialExpensesIndianCurrency: Yup.number().required("Food & Residential Expenses is required").typeError('Food & Residential Expenses Fee must be a number')
            .positive('Food & Residential Expenses Fee must be greater than zero').max(100000000, "Food & Residential Expenses Fees can't be greater than 10 crore"),

        wholeCourceFoodAndResidentialExpensesForeignCurrency: Yup.number().required("Food & Residential Expenses is required").typeError('Food & Residential Expenses Fee must be a number')
            .positive('Food & Residential Expenses Fee must be greater than zero').max(100000000, "Food & Residential Expenses Fees can't be greater than 10 crore"),

        wholeCourceFoodAndResidentialExpensesIndianCurrency: Yup.number().required("Food & Residential Expenses is required").typeError('Food & Residential Expenses Fee must be a number')
            .positive('Food & Residential Expenses Fee must be greater than zero').max(100000000, "Food & Residential Expenses Fees can't be greater than 10 crore"),

        //*----validation for Acadamic expenses -----*//
        oneYearAcadamicExpensesForeignCurrency: Yup.number().required("Academics Expenses is required")
            .typeError('Academics Expenses must be a number')
            .positive('Academics Expenses must be greater than zero').max(100000000, "Academics expenses can't be greater than 10 crore"),

        oneYearAcadamicExpensesIndianCurrency: Yup.number().required("Academics Expenses is required")
            .typeError('Academics Expenses must be a number')
            .positive('Academics Expenses must be greater than zero').max(100000000, "Academics expenses can't be greater than 10 crore"),

        wholeCourceAcadamicExpensesForeignCurrency: Yup.number().required("Academics Expenses is required")
            .typeError('Academics Expenses must be a number')
            .positive('Academics Expenses must be greater than zero').max(100000000, "Academics expenses can't be greater than 10 crore"),

        wholeCourceAcadamicExpensesIndianCurrency: Yup.number().required("Academics Expenses is required")
            .typeError('Academics Expenses must be a number')
            .positive('Academics Expenses must be greater than zero').max(100000000, "Academics expenses can't be greater than 10 crore"),

        //*----validation for Acadamic Visa Expense -----*//
        oneYearAcadamicVisaExpenseForeignCurrency: Yup.number().required("Academics Expenses is required")
            .typeError('Visa Expenses must be a number')
            .positive('Visa Expenses must be greater than zero').max(100000000, "Visa expenses can't be greater than 10 crore"),

        oneYearAcadamicVisaExpenseIndianCurrency: Yup.number().required("Visa Expenses is required")
            .typeError('Visa Expenses must be a number')
            .positive('Visa Expenses must be greater than zero').max(100000000, "Visa expenses can't be greater than 10 crore"),

        wholeCourceAcadamicVisaExpenseForeignCurrency: Yup.number().required("Visa Expenses is required")
            .typeError('Visa Expenses must be a number')
            .positive('Visa Expenses must be greater than zero').max(100000000, "Visa expenses can't be greater than 10 crore"),

        wholeCourceAcadamicVisaExpenseIndianCurrency: Yup.number().required("Visa Expenses is required")
            .typeError('Visa Expenses must be a number')
            .positive('Visa Expenses must be greater than zero').max(100000000, "Academics expenses can't be greater than 10 crore"),

        //*----validation for Acadamic Insurance Expense -----*//
        oneYearAcadamicInsuranceExpenseForeignCurrency: Yup.number().required("Academics Expenses is required")
            .typeError('Insurance Expenses must be a number')
            .positive('Insurance Expenses must be greater than zero').max(100000000, "Insurance expenses can't be greater than 10 crore"),

        oneYearAcadamicInsuranceExpenseIndianCurrency: Yup.number().required("Insurance Expenses is required")
            .typeError('Insurance Expenses must be a number')
            .positive('Insurance Expenses must be greater than zero').max(100000000, "Insurance expenses can't be greater than 10 crore"),

        wholeCourceAcadamicInsuranceExpenseForeignCurrency: Yup.number().required("Insurance Expenses is required")
            .typeError('Insurance Expenses must be a number')
            .positive('Insurance Expenses must be greater than zero').max(100000000, "Insurance expenses can't be greater than 10 crore"),

        wholeCourceAcadamicInsuranceExpenseIndianCurrency: Yup.number().required("Insurance Expenses is required")
            .typeError('Insurance Expenses must be a number')
            .positive('Insurance Expenses must be greater than zero').max(100000000, "Insurance expenses can't be greater than 10 crore"),

        //*----Approved/Received scholarship details -----*//
        otherScholarship: Yup.boolean().required("Field is required"),

        fellowship: Yup.boolean().required("Field is required"),

        graduateTeacherAttendanceSlip: Yup.boolean().required("Field is required"),

        otherHonorarian: Yup.boolean().required("Field is required"),

        job: Yup.boolean().required("Field is required"),

    });

    const { handleSubmit, handleChange, setFieldValue, values, errors, handleReset } = useFormik({
        initialValues: initialFormValues === null ? defaultFormValues : initialFormValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {

            // Loading on save and submit button
            showLoading(saveType === "SUBMIT" ? "Submitting Form..." : "Saving Form...");
            // Disable Save, Cancel and Submit Buttons
            setDisableActions(true);
            //set College admission details object to send
            let educationExpensesDetailsForm = {
                currencyType: values.currencyType,
                expenditureDetails: {
                    oneYearExpenses: {
                        educationFees: {
                            foreignCurrency: Number(values.oneYearEducationFeesForeignCurrency),
                            indianCurrency: Number(values.oneYearEducationFeesIndianCurrency)
                        },
                        examFees: {
                            foreignCurrency: Number(values.oneYearExamFeesForeignCurrency),
                            indianCurrency: Number(values.oneYearExamFeesIndianCurrency)
                        },
                        registrationFees: {
                            foreignCurrency: Number(values.oneYearRegistrationFeesForeignCurrency),
                            indianCurrency: Number(values.oneYearRegistrationFeesIndianCurrency)
                        },
                        foodAndResidentialExpenses: {
                            foreignCurrency: Number(values.oneYearFoodAndResidentialExpensesForeignCurrency),
                            indianCurrency: Number(values.oneYearFoodAndResidentialExpensesIndianCurrency)
                        },
                        otherExpensesForAcademics: {
                            foreignCurrency: Number(values.oneYearAcadamicExpensesForeignCurrency),
                            indianCurrency: Number(values.oneYearAcadamicExpensesIndianCurrency)
                        },
                        insuranceExpense: {
                            foreignCurrency: Number(values.oneYearAcadamicInsuranceExpenseForeignCurrency),
                            indianCurrency: Number(values.oneYearAcadamicInsuranceExpenseIndianCurrency)
                        },
                        visaExpense: {
                            foreignCurrency: Number(values.oneYearAcadamicVisaExpenseForeignCurrency),
                            indianCurrency: Number(values.oneYearAcadamicVisaExpenseIndianCurrency)
                        },
                        totalExpenses: {
                            foreignCurrency: totalExpensesForeignOneYear,
                            indianCurrency: totalExpensesINROneYear
                        }
                    },
                    wholeCourseExpenses: {
                        educationFees: {
                            foreignCurrency: Number(values.wholeCourceEducationFeesForeignCurrency),
                            indianCurrency: Number(values.wholeCourceEducationFeesIndianCurrency)
                        },
                        examFees: {
                            foreignCurrency: Number(values.wholeCourceExamFeesForeignCurrency),
                            indianCurrency: Number(values.wholeCourceExamFeesIndianCurrency)
                        },
                        registrationFees: {
                            foreignCurrency: Number(values.wholeCourceRegistrationFeesForeignCurrency),
                            indianCurrency: Number(values.wholeCourceRegistrationFeesIndianCurrency)
                        },
                        foodAndResidentialExpenses: {
                            foreignCurrency: Number(values.wholeCourceFoodAndResidentialExpensesForeignCurrency),
                            indianCurrency: Number(values.wholeCourceFoodAndResidentialExpensesIndianCurrency)
                        },
                        otherExpensesForAcademics: {
                            foreignCurrency: Number(values.wholeCourceAcadamicExpensesForeignCurrency),
                            indianCurrency: Number(values.wholeCourceAcadamicExpensesIndianCurrency)
                        },
                        insuranceExpense: {
                            foreignCurrency: Number(values.wholeCourceAcadamicInsuranceExpenseForeignCurrency),
                            indianCurrency: Number(values.wholeCourceAcadamicInsuranceExpenseIndianCurrency)
                        },
                        visaExpense: {
                            foreignCurrency: Number(values.wholeCourceAcadamicVisaExpenseForeignCurrency),
                            indianCurrency: Number(values.wholeCourceAcadamicVisaExpenseIndianCurrency)
                        },

                        totalExpenses: {
                            foreignCurrency: totalExpensesForeignFullYear,
                            indianCurrency: totalExpensesINRFullYear
                        }
                    }
                },
                approvedItems: {
                    otherScholarship: values.otherScholarship,
                    fellowship: values.fellowship,
                    graduateTeacherAttendanceSlip: values.graduateTeacherAttendanceSlip,
                    otherHonorarian: values.otherHonorarian,
                    job: values.job
                }

            }

            // Sending data to server
            let sendEducationExpensesDetailsFormResponse = await applicationCommunication.uploadEducationExpensesDetailsForm(educationExpensesDetailsForm, applicationId, "SAVE");
            // Handling response from the server
            if (sendEducationExpensesDetailsFormResponse.status === "FORM_SAVED_SUCCESSFULLY") {

                if (saveType === "SAVE") {
                } else {
                    history.push(`/user/application/document-submission-details?applicationId=${applicationId}`);
                }
            }
            if (sendEducationExpensesDetailsFormResponse.status === "VALIDATION_FAILED") {
                showModal(sendEducationExpensesDetailsFormResponse.validationErrorsList[0].message || sendEducationExpensesDetailsFormResponse.data.validationErrorsList[0].message);
                hideLoading();
                return false;
            }

            showModal(sendEducationExpensesDetailsFormResponse.message || sendEducationExpensesDetailsFormResponse.message);
            hideLoading();
            setDisableActions(false);
            return false;
        },
        validationSchema: validationSchema,
    });

    useEffect(() => {
        const totalOneYearExpensesForeignCurrency = Number(values.oneYearEducationFeesForeignCurrency) + Number(values.oneYearExamFeesForeignCurrency) + Number(values.oneYearRegistrationFeesForeignCurrency) + Number(values.oneYearFoodAndResidentialExpensesForeignCurrency) + Number(values.oneYearAcadamicExpensesForeignCurrency) + Number(values.oneYearAcadamicInsuranceExpenseForeignCurrency) + Number(values.oneYearAcadamicVisaExpenseForeignCurrency);
        setTotalExpensesForeignOneYear((value) => value = totalOneYearExpensesForeignCurrency);

        const totalOneYearExpensesIndianCurrency = Number(values.oneYearEducationFeesIndianCurrency) + Number(values.oneYearExamFeesIndianCurrency) + Number(values.oneYearRegistrationFeesIndianCurrency) + Number(values.oneYearFoodAndResidentialExpensesIndianCurrency) + Number(values.oneYearAcadamicExpensesIndianCurrency) + Number(values.oneYearAcadamicInsuranceExpenseIndianCurrency) + Number(values.oneYearAcadamicVisaExpenseIndianCurrency);
        setTotalExpensesINROneYear((value) => value = totalOneYearExpensesIndianCurrency);

        const totalWholeYearExpensesForeignCurrency = Number(values.wholeCourceEducationFeesForeignCurrency) + Number(values.wholeCourceExamFeesForeignCurrency) + Number(values.wholeCourceRegistrationFeesForeignCurrency) + Number(values.wholeCourceFoodAndResidentialExpensesForeignCurrency) + Number(values.wholeCourceAcadamicExpensesForeignCurrency) + Number(values.wholeCourceAcadamicInsuranceExpenseForeignCurrency) + Number(values.wholeCourceAcadamicVisaExpenseForeignCurrency);
        setTotalExpensesForeignFullYear((value) => value = totalWholeYearExpensesForeignCurrency);

        const totalWholeYearExpensesIndianCurrency = Number(values.wholeCourceEducationFeesIndianCurrency) + Number(values.wholeCourceExamFeesIndianCurrency) + Number(values.wholeCourceRegistrationFeesIndianCurrency) + Number(values.wholeCourceFoodAndResidentialExpensesIndianCurrency) + Number(values.wholeCourceAcadamicExpensesIndianCurrency) + Number(values.wholeCourceAcadamicInsuranceExpenseIndianCurrency) + Number(values.wholeCourceAcadamicVisaExpenseIndianCurrency);
        setTotalExpensesINRFullYear((value) => value = totalWholeYearExpensesIndianCurrency);
    }, [values])

    return (
        <section className="application_flow_big">
            <div className="education_expenses_detail_form">
                <div className="container">
                    {isLoading.value ?
                        <LoadingForms message={isLoading.message} />
                        :
                        <div className="row">
                            <div className="col-12">
                                <form onSubmit={handleSubmit}>
                                    {/*---family member ever availed scholarship before--*/}


                                    <div className="row">
                                        <div className="col-lg-2 col-md-2"></div>
                                        <div className="col-lg-10 col-md-10">
                                            <H4 whiteThemeColor="#0d2750" darkThemeColor="#FFFFFF">Expenditure for study abroad as per offer letter / I-20:</H4>
                                        </div>
                                    </div>
                                    {/*---Select currency---*/}
                                    <div className="row mb-lg-3 mb-md-3 mt-3">
                                        <div className="col-lg-2 col-md-3">
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-12">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Currency Type</Label>
                                            <select name="currencyType" onChange={handleChange} value={values.currencyType} className={`form-select ${errors.currencyType ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                <option value="">Select</option>
                                                {CurrencyList.map((currency, index) => {
                                                    const { name, code } = currency;
                                                    return (
                                                        <option key={index}>{name} ({code})</option>
                                                    );
                                                })}
                                            </select>
                                            <div className="invalid-feedback">{errors.currencyType}</div>
                                        </div>
                                    </div>
                                    {/*----Education fees form field----*/}
                                    <div className="row">
                                        <div className="col-lg-2 col-md-3">
                                            <FormFieldTitle name="Educational Expenses made by the student" />
                                            <FormFieldTitle name="Tuition Expense *" />
                                        </div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row mt-lg-3 mt-md-0">
                                                <span style={{ textAlign: "center" }}><Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="text-center">One Year Expenses</Label></span>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Foreign Currency</Label>
                                                    <input type="number" name="oneYearEducationFeesForeignCurrency" onChange={handleChange} value={values.oneYearEducationFeesForeignCurrency} className={`form-control mt-1 ${errors.oneYearEducationFeesForeignCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.oneYearEducationFeesForeignCurrency}</div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Indian Currency</Label>
                                                    <input type="number" name="oneYearEducationFeesIndianCurrency" onChange={handleChange} value={values.oneYearEducationFeesIndianCurrency} className={`form-control mt-1 ${errors.oneYearEducationFeesIndianCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.oneYearEducationFeesIndianCurrency}</div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-1"></div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row mt-lg-3 mt-3 mt-md-0">
                                                <span style={{ textAlign: "center" }}><Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="text-center">Whole Course Expenses</Label></span>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Foreign Currency</Label>
                                                    <input type="number" name="wholeCourceEducationFeesForeignCurrency" onChange={handleChange} value={values.wholeCourceEducationFeesForeignCurrency} className={`form-control mt-1 ${errors.wholeCourceEducationFeesForeignCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.wholeCourceEducationFeesForeignCurrency}</div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Indian Currency</Label>
                                                    <input type="number" name="wholeCourceEducationFeesIndianCurrency" onChange={handleChange} value={values.wholeCourceEducationFeesIndianCurrency} className={`form-control mt-1 ${errors.wholeCourceEducationFeesIndianCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.wholeCourceEducationFeesIndianCurrency}</div>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                    {/*----Exam fees detail form field---*/}
                                    <div className="row mt-1 mt-lg-3 mt-md-3">
                                        <div className="col-lg-2 col-md-3">
                                            <FormFieldTitle name="Exam Fees *" />
                                        </div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row">
                                                <label className="text-center education_expenses_label">One Year Expenses</label>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Foreign Currency</label>
                                                    <input type="number" name="oneYearExamFeesForeignCurrency" onChange={handleChange} value={values.oneYearExamFeesForeignCurrency} className={`form-control ${errors.oneYearExamFeesForeignCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.oneYearExamFeesForeignCurrency}</div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Indian Currency</label>
                                                    <input type="number" name="oneYearExamFeesIndianCurrency" onChange={handleChange} value={values.oneYearExamFeesIndianCurrency} className={`form-control ${errors.oneYearExamFeesIndianCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.oneYearExamFeesIndianCurrency}</div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-1"></div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row">
                                                <label className="text-center education_expenses_label">Whole Course Expenses</label>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <input type="number" name="wholeCourceExamFeesForeignCurrency" onChange={handleChange} value={values.wholeCourceExamFeesForeignCurrency} className={`form-control ${errors.wholeCourceExamFeesForeignCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.wholeCourceExamFeesForeignCurrency}</div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <input type="number" name="wholeCourceExamFeesIndianCurrency" onChange={handleChange} value={values.wholeCourceExamFeesIndianCurrency} className={`form-control ${errors.wholeCourceExamFeesIndianCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.wholeCourceExamFeesIndianCurrency}</div>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                    {/*----Registration fees detail form field---*/}
                                    <div className="row mt-3">
                                        <div className="col-lg-2 col-md-3">
                                            <FormFieldTitle name="Registration Fees *" />
                                        </div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row">
                                                <label className="text-center education_expenses_label">One Year Expenses</label>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Foreign Currency</label>
                                                    <input type="number" name="oneYearRegistrationFeesForeignCurrency" onChange={handleChange} value={values.oneYearRegistrationFeesForeignCurrency} className={`form-control ${errors.oneYearRegistrationFeesForeignCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.oneYearRegistrationFeesForeignCurrency}</div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Indian Currency</label>
                                                    <input type="number" name="oneYearRegistrationFeesIndianCurrency" onChange={handleChange} value={values.oneYearRegistrationFeesIndianCurrency} className={`form-control ${errors.oneYearRegistrationFeesIndianCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.oneYearRegistrationFeesIndianCurrency}</div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-1"></div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row">
                                                <label className="text-center education_expenses_label">Whole Course Expenses</label>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Foreign Currency</label>
                                                    <input type="number" name="wholeCourceRegistrationFeesForeignCurrency" onChange={handleChange} value={values.wholeCourceRegistrationFeesForeignCurrency} className={`form-control ${errors.wholeCourceRegistrationFeesForeignCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.wholeCourceRegistrationFeesForeignCurrency}</div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Indian Currency</label>
                                                    <input type="number" name="wholeCourceRegistrationFeesIndianCurrency" onChange={handleChange} value={values.wholeCourceRegistrationFeesIndianCurrency} className={`form-control ${errors.wholeCourceRegistrationFeesIndianCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.wholeCourceRegistrationFeesIndianCurrency}</div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    {/*----Food and Residential detail form field---*/}
                                    <div className="row mt-3">
                                        <div className="col-lg-2 col-md-3">
                                            <FormFieldTitle name="Food & Residential Expenses *" />
                                        </div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row">
                                                <label className="text-center education_expenses_label">One Year Expenses</label>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Foreign Currency</label>
                                                    <input type="number" name="oneYearFoodAndResidentialExpensesForeignCurrency" onChange={handleChange} value={values.oneYearFoodAndResidentialExpensesForeignCurrency} className={`form-control ${errors.oneYearFoodAndResidentialExpensesForeignCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.oneYearFoodAndResidentialExpensesForeignCurrency}</div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Indian Currency</label>
                                                    <input type="number" name="oneYearFoodAndResidentialExpensesIndianCurrency" onChange={handleChange} value={values.oneYearFoodAndResidentialExpensesIndianCurrency} className={`form-control ${errors.oneYearFoodAndResidentialExpensesIndianCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.oneYearFoodAndResidentialExpensesIndianCurrency}</div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-1"></div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row">
                                                <label className="text-center education_expenses_label">Whole Course Expenses</label>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Foreign Currency</label>
                                                    <input type="number" name="wholeCourceFoodAndResidentialExpensesForeignCurrency" onChange={handleChange} value={values.wholeCourceFoodAndResidentialExpensesForeignCurrency} className={`form-control ${errors.wholeCourceFoodAndResidentialExpensesForeignCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.wholeCourceFoodAndResidentialExpensesForeignCurrency}</div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Indian Currency</label>
                                                    <input type="number" name="wholeCourceFoodAndResidentialExpensesIndianCurrency" onChange={handleChange} value={values.wholeCourceFoodAndResidentialExpensesIndianCurrency} className={`form-control ${errors.wholeCourceFoodAndResidentialExpensesIndianCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.wholeCourceFoodAndResidentialExpensesIndianCurrency}</div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    {/*----Acadamics expenses form field---*/}
                                    <div className="row mt-2">
                                        <div className="col-lg-2 col-md-3">
                                            <FormFieldTitle name="Other Expenses for Acadamics *" />
                                        </div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row">
                                                <label className="text-center education_expenses_label">One Year Expenses</label>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Foreign Currency</label>
                                                    <input type="number" name="oneYearAcadamicExpensesForeignCurrency" onChange={handleChange} value={values.oneYearAcadamicExpensesForeignCurrency} className={`form-control ${errors.oneYearAcadamicExpensesForeignCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.oneYearAcadamicExpensesForeignCurrency}</div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Indian Currency</label>
                                                    <input type="number" name="oneYearAcadamicExpensesIndianCurrency" onChange={handleChange} value={values.oneYearAcadamicExpensesIndianCurrency} className={`form-control ${errors.oneYearAcadamicExpensesIndianCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.oneYearAcadamicExpensesIndianCurrency}</div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-1"></div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row">
                                                <label className="text-center education_expenses_label">Whole Course Expenses</label>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Foreign Currency</label>
                                                    <input type="number" name="wholeCourceAcadamicExpensesForeignCurrency" onChange={handleChange} value={values.wholeCourceAcadamicExpensesForeignCurrency} className={`form-control ${errors.wholeCourceAcadamicExpensesForeignCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.wholeCourceAcadamicExpensesForeignCurrency}</div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Indian Currency</label>
                                                    <input type="number" name="wholeCourceAcadamicExpensesIndianCurrency" onChange={handleChange} value={values.wholeCourceAcadamicExpensesIndianCurrency} className={`form-control ${errors.wholeCourceAcadamicExpensesIndianCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.wholeCourceAcadamicExpensesIndianCurrency}</div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    {/*---Insurance Expense form field---*/}
                                    <div className="row mt-2">
                                        <div className="col-lg-2 col-md-3">
                                            <FormFieldTitle name="Insurance Expense *" />
                                        </div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row">
                                                <label className="text-center education_expenses_label">One Year Expenses</label>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Foreign Currency</label>
                                                    <input type="number" name="oneYearAcadamicInsuranceExpenseForeignCurrency" onChange={handleChange} value={values.oneYearAcadamicInsuranceExpenseForeignCurrency} className={`form-control ${errors.oneYearAcadamicInsuranceExpenseForeignCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.oneYearAcadamicInsuranceExpenseForeignCurrency}</div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Indian Currency</label>
                                                    <input type="number" name="oneYearAcadamicInsuranceExpenseIndianCurrency" onChange={handleChange} value={values.oneYearAcadamicInsuranceExpenseIndianCurrency} className={`form-control ${errors.oneYearAcadamicInsuranceExpenseIndianCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.oneYearAcadamicInsuranceExpenseIndianCurrency}</div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-1"></div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row">
                                                <label className="text-center education_expenses_label">Whole Course Expenses</label>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Foreign Currency</label>
                                                    <input type="number" name="wholeCourceAcadamicInsuranceExpenseForeignCurrency" onChange={handleChange} value={values.wholeCourceAcadamicInsuranceExpenseForeignCurrency} className={`form-control ${errors.wholeCourceAcadamicInsuranceExpenseForeignCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.wholeCourceAcadamicInsuranceExpenseForeignCurrency}</div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Indian Currency</label>
                                                    <input type="number" name="wholeCourceAcadamicInsuranceExpenseIndianCurrency" onChange={handleChange} value={values.wholeCourceAcadamicInsuranceExpenseIndianCurrency} className={`form-control ${errors.wholeCourceAcadamicInsuranceExpenseIndianCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.wholeCourceAcadamicInsuranceExpenseIndianCurrency}</div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    {/*---Visa Expense form field---*/}
                                    <div className="row mt-2">
                                        <div className="col-lg-2 col-md-3">
                                            <FormFieldTitle name="Visa Expense *" />
                                        </div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row">
                                                <label className="text-center education_expenses_label">One Year Expenses</label>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Foreign Currency</label>
                                                    <input type="number" name="oneYearAcadamicVisaExpenseForeignCurrency" onChange={handleChange} value={values.oneYearAcadamicVisaExpenseForeignCurrency} className={`form-control ${errors.oneYearAcadamicVisaExpenseForeignCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.oneYearAcadamicVisaExpenseForeignCurrency}</div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Indian Currency</label>
                                                    <input type="number" name="oneYearAcadamicVisaExpenseIndianCurrency" onChange={handleChange} value={values.oneYearAcadamicVisaExpenseIndianCurrency} className={`form-control ${errors.oneYearAcadamicVisaExpenseIndianCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.oneYearAcadamicVisaExpenseIndianCurrency}</div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-1"></div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row">
                                                <label className="text-center education_expenses_label">Whole Course Expenses</label>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Foreign Currency</label>
                                                    <input type="number" name="wholeCourceAcadamicVisaExpenseForeignCurrency" onChange={handleChange} value={values.wholeCourceAcadamicVisaExpenseForeignCurrency} className={`form-control ${errors.wholeCourceAcadamicVisaExpenseForeignCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.wholeCourceAcadamicVisaExpenseForeignCurrency}</div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Indian Currency</label>
                                                    <input type="number" name="wholeCourceAcadamicVisaExpenseIndianCurrency" onChange={handleChange} value={values.wholeCourceAcadamicVisaExpenseIndianCurrency} className={`form-control ${errors.wholeCourceAcadamicVisaExpenseIndianCurrency ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.wholeCourceAcadamicVisaExpenseIndianCurrency}</div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    {/*----Total expenses form field---*/}
                                    <div className="row mt-2">
                                        <div className="col-lg-2 col-md-3">
                                            <FormFieldTitle name="Total Expenses *" />
                                        </div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row">
                                                <label className="text-center education_expenses_label">One Year Expenses</label>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Foreign Currency</label>
                                                    <div className=""></div>
                                                    <input type="number" name="oneYearTotalExpensesForeignCurrency" onChange={handleChange} value={totalExpensesForeignOneYear} className="form-control" disabled />
                                                    {/* <div className="invalid-feedback">{errors.oneYearTotalExpensesForeignCurrency}</div> */}
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Indian Currency</label>
                                                    <div className="total_expenses">
                                                        <input type="number" name="oneYearTotalExpensesIndianCurrency" onChange={handleChange} value={totalExpensesINROneYear} className="form-control" disabled />
                                                        <span><i className="fa fa-inr" aria-hidden="true"></i></span>
                                                        {/* <div className="invalid-feedback">{errors.oneYearTotalExpensesIndianCurrency}</div> */}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-1"></div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="row">
                                                <label className="text-center education_expenses_label">Whole Course Expenses</label>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Foreign Currency</label>
                                                    <input type="number" name="wholeCourceTotalExpensesForeignCurrency" onChange={handleChange} value={totalExpensesForeignFullYear} className="form-control" disabled />
                                                    {/* <div className="invalid-feedback">{errors.wholeCourceTotalExpensesForeignCurrency}</div> */}
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <label className="input_field_label">Indian Currency</label>
                                                    <div className="total_expenses">
                                                        <input type="number" name="wholeCourceTotalExpensesIndianCurrency" onChange={handleChange} value={totalExpensesINRFullYear} className="form-control" disabled />
                                                        <span><i className="fa fa-inr" aria-hidden="true"></i></span>
                                                        {/* <div className="invalid-feedback">{errors.wholeCourceTotalExpensesIndianCurrency}</div> */}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    {/*----Approved/Received scholarship details---*/}
                                    {/*----Other Scholarship detail-----*/}
                                    <div className="row mt-lg-5 mt-md-5 mt-4">
                                        <H4 whiteThemeColor="#0d2750" darkThemeColor="#FFFFFF">Details of the Following Approved/ Received Items to the Student:</H4>
                                        <div className="col-lg-2 col-md-3">
                                            <FormFieldTitle name="Other Scholarship *" />
                                        </div>

                                        <div className="col-lg-1 col-md-2 col-5 mt-2">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                            <input type="radio" onChange={() => setFieldValue("otherScholarship", true)} checked={values.otherScholarship === null ? (values.otherScholarship) : Boolean(values.otherScholarship)} value="true" name="otherScholarshipRadio" id="otherScholarshipTrue" className={`form-check-input mx-2 ${errors.otherScholarship ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>

                                        <div className="col-lg-1 col-md-2 col-5 mt-2">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                            <input type="radio" onChange={() => setFieldValue("otherScholarship", false)} checked={values.otherScholarship === null ? (values.otherScholarship) : Boolean(!values.otherScholarship)} value="false" name="otherScholarshipRadio" id="otherScholarshipFalse" className={`form-check-input mx-2 ${errors.otherScholarship ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>
                                        <div className="invalid-feedback">{errors.otherScholarship}</div>
                                    </div>
                                    {/*----Fellowship detail-----*/}
                                    <div className="row mt-lg-1 mt-md-2 mt-1">
                                        <div className="col-lg-2 col-md-3">
                                            <FormFieldTitle name="Fellowship *" />
                                        </div>

                                        <div className="col-lg-1 col-md-2 col-5 mt-2">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                            <input type="radio" onChange={() => setFieldValue("fellowship", true)} checked={values.fellowship === null ? (values.fellowship) : Boolean(values.fellowship)} value="true" name="fellowshipRadio" id="fellowshipTrue" className={`form-check-input mx-2 ${errors.fellowship ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>

                                        <div className="col-lg-1 col-md-2 col-5 mt-2">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                            <input type="radio" onChange={() => setFieldValue("fellowship", false)} checked={values.fellowship === null ? (values.fellowship) : Boolean(!values.fellowship)} value="false" name="fellowshipRadio" id="fellowshipFalse" className={`form-check-input mx-2 ${errors.fellowship ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>
                                        <div className="invalid-feedback">{errors.fellowship}</div>
                                    </div>
                                    {/*----Graduate Teacher Assistance Ship detail-----*/}
                                    <div className="row mt-lg-1 mt-md-1 mt-1">
                                        <div className="col-lg-2 col-md-3">
                                            <FormFieldTitle name="Graduate Teacher Assistance-Ship *" />
                                        </div>

                                        <div className="col-lg-1 col-md-2 col-5 mt-3">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                            <input type="radio" onChange={() => setFieldValue("graduateTeacherAttendanceSlip", true)} checked={values.graduateTeacherAttendanceSlip === null ? (values.graduateTeacherAttendanceSlip) : Boolean(values.graduateTeacherAttendanceSlip)} value="true" name="graduateTeacherAttendanceSlipRadio" id="graduateTeacherAttendanceSlipTrue" className={`form-check-input mx-2 ${errors.graduateTeacherAttendanceSlip ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>

                                        <div className="col-lg-1 col-md-2 col-5 mt-3">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                            <input type="radio" onChange={() => setFieldValue("graduateTeacherAttendanceSlip", false)} checked={values.graduateTeacherAttendanceSlip === null ? (values.graduateTeacherAttendanceSlip) : Boolean(!values.graduateTeacherAttendanceSlip)} value="false" name="graduateTeacherAttendanceSlipRadio" id="graduateTeacherAttendanceSlipFalse" className={`form-check-input mx-2 ${errors.graduateTeacherAttendanceSlip ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>
                                        <div className="invalid-feedback">{errors.graduateTeacherAttendanceSlip}</div>
                                    </div>
                                    {/*----Other Honorarium detail-----*/}
                                    <div className="row mt-lg-3 mt-md-2 mt-1">
                                        <div className="col-lg-2 col-md-3">
                                            <FormFieldTitle name="Other Honorarium *" />
                                        </div>

                                        <div className="col-lg-1 col-md-2 col-5 mt-2">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                            <input type="radio" onChange={() => setFieldValue("otherHonorarian", true)} checked={values.otherHonorarian === null ? (values.otherHonorarian) : Boolean(values.otherHonorarian)} value="true" name="otherHonorarianRadio" id="otherHonorarianTrue" className={`form-check-input mx-2 ${errors.otherHonorarian ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>

                                        <div className="col-lg-1 col-md-2 col-5 mt-2">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                            <input type="radio" onChange={() => setFieldValue("otherHonorarian", false)} checked={values.otherHonorarian === null ? (values.otherHonorarian) : Boolean(!values.otherHonorarian)} value="false" name="otherHonorarianRadio" id="otherHonorarianFalse" className={`form-check-input mx-2 ${errors.otherHonorarian ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>
                                        <div className="invalid-feedback">{errors.otherHonorarian}</div>
                                    </div>
                                    {/*----Campus Job/ Library detail-----*/}
                                    <div className="row mt-lg-1 mt-md-2 mt-1">
                                        <div className="col-lg-2 col-md-3">
                                            <FormFieldTitle name="Campus Job/ Library *" />
                                        </div>

                                        <div className="col-lg-1 col-md-2 col-5 mt-2">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                            <input type="radio" onChange={() => setFieldValue("job", true)} checked={values.job === null ? (values.job) : Boolean(values.job)} value="true" name="jobRadio" id="jobTrue" className={`form-check-input mx-2 ${errors.job ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>

                                        <div className="col-lg-1 col-md-2 col-5 mt-2">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                            <input type="radio" onChange={() => setFieldValue("job", false)} checked={values.job === null ? (values.job) : Boolean(!values.job)} value="false" name="jobRadio" id="jobFalse" className={`form-check-input mx-2 ${errors.job ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>
                                        <div className="invalid-feedback">{errors.job}</div>
                                    </div>



                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <div className="form_button">
                                                {splitUrlArray[4] === "user" ?
                                                    <button type="button" onClick={() => { history.push("/user"); dispatch({ type: "SHOW_APPLICATION_PROCESS_NAV" }); }} className="btn form_cancel_btn" disabled={disableActions}>Cancel</button>
                                                    : <span></span>
                                                }
                                                {
                                                    isSubmitted ?
                                                        <div className="m-1"></div>
                                                        :
                                                        <button type="button" onClick={() => {
                                                            setSaveType(value => value = "SAVE");
                                                            handleSubmit();
                                                        }} className="btn form_save-btn" disabled={disableActions}>Save</button>
                                                }
                                                {
                                                    isSubmitted ?
                                                        (splitUrlArray[4] === "user") ?
                                                            <button type="button" onClick={() => {
                                                                loadNextForm();
                                                            }} className="btn form_submit_btn">Next</button>
                                                            : <span></span>
                                                        :
                                                        <button type="button" onClick={() => {
                                                            setSaveType(value => value = "SAVE_AND_NEXT");
                                                            handleSubmit();
                                                        }} className="btn form_submit_btn" disabled={disableActions}>Save & Next</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section >
    );
}
export default EducationExpensesDetailForm;