import React from "react";
import { useSelector } from "react-redux";

const MainHeader = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);

    return (
        <div className="row m-0">
            <div className="col-12 justify-content-between p-0 mHeader">
                <img className="mandala-image" alt="Mandala" src="/assets/images/pngs/mandala-left.png"  />

                <img className="mandala-image" alt="Mandala" src="/assets/images/pngs/mandala-right.png"  style={{float:"right"}} />

                <div className="main-header" style={{ position:"absolute", bottom: "0px", width: "100%" }}>
                    <div style={{ borderTop: darkWhiteThemeToggle === "WHITE" ? "5px solid #0D2750" : "5px solid #FFFFFF", borderBottom: darkWhiteThemeToggle === "WHITE" ? "5px solid #0D2750" : "5px solid #FFFFFF", alignContent: "center" }}>
                        <div className="row m-0">
                            <div className="col-2 col-lg-3 d-flex justify-content-end align-items-center">
                                <img className="main-header-images" alt="Maharashtra Govt Department Logo" src="/assets/images/pngs/Govt Logo.png" height="100px" />
                            </div>

                            <div className="col-8 col-lg-6 d-flex">
                                <div className="row m-0">
                                    <div className="col-12 text-center p-0 ">
                                        <h1 style={{fontFamily: 'Roboto Condensed', color: `${darkWhiteThemeToggle === "WHITE" ? "#0D2750" : "#FFFF00"}` }}>Rajarshri Chhatrapati Shahu Maharaj Foreign Scholarship</h1>
                                    </div>
                                    <div className="col-12 text-center p-0">
                                        <h2 style={{fontFamily: 'Roboto Condensed', color: `${darkWhiteThemeToggle === "WHITE" ? "#6D8DAD" : "#FFFF00"}` }}>Maharashtra Govt. Social Justice & Welfare Department</h2>
                                    </div>
                                    <div className="col-12 text-center p-0">
                                        <h3 style={{fontFamily: 'Roboto Condensed', color: `${darkWhiteThemeToggle === "WHITE" ? "#6D8DAD" : "#FFFF00"}` }}>Government of Maharashtra</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-2 col-lg-3 d-flex justify-content-start align-items-center">
                                <img className="main-header-images" alt="Rajarshri Chhatrapati Shahu Maharaj" src="/assets/images/pngs/Chatrapati-Shahu-Mahara.png" height="100px" />
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainHeader;