import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import H4 from "../../text-components/H4";
import P from "../../text-components/P";
import BackOfficeGrievancePreview from "./BackOfficeGrievancePreview";
import { backOfficeCommunication } from "../../communications/back-office-communication"
import LoadingForms from "../../shared-components/LoadingForms";
import { removeUnderscoreFromText } from "../../utlis/helper";



const RefundPreview = ({ changeHandler }) => {

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const history = useHistory();
    const location = useLocation();
    const comment = useRef();

    const [remittance, setRemittance] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalRefundAmount, setRefundAmount] = useState(0);
    const [userId, setUserId] = useState('');
    const [applicationId, setApplicationId] = useState('');
    const [refundId, setRefundId] = useState('');
    const [refundData, setRefundData] = useState();

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    let fullUrl = window.location.href;
    const queryParams = new URLSearchParams(location.search);

    async function initialLoad() {
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("refund")) {
            dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "REFUND" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
        }
        showLoading("Loading...");

        const refundIdFromParams = queryParams.get('refundId');
        setRefundId(value => value = refundIdFromParams);

        const results = await backOfficeCommunication.getRefundById(refundIdFromParams);

        if (results.status === "SUCCESS") {

            const refundData = results.refund;
            setRefundData(refundData);
           
            const resultRemittance = await backOfficeCommunication.getRefundRemittance(refundData?.applicationId, refundData?.userId);

            if (resultRemittance.status === "SUCCESS") {
                setRemittance(resultRemittance?.remittance);
                setTotalAmount(resultRemittance?.totalAnnualAmount);
                setRefundAmount(Math.ceil(resultRemittance?.totalRefundAmount));
                setUserId(refundData?.userId);
                setApplicationId(refundData?.applicationId);
            }
            else {
                showModal(resultRemittance?.message);
            }
        }
        else {
            showModal(results?.message);
        }

        hideLoading();
    }

    useEffect(() => {
        initialLoad();
    }, [fullUrl])


    const receivedHandler = async () => {

        showLoading("Loading...");

        const results = await backOfficeCommunication.markRefundAsCompleted(applicationId, refundId, userId, "COMPLETED", comment.current.value, refundData.shortApplicationId, totalAmount, totalRefundAmount);

        if (results.status === "SUCCESS") {
            changeHandler();
            hideLoading();
            history.push("/back-office/dashboard/refund")

        }
        else if (results.status === "VALIDATION_FAILED") {
            showModal(results.validationErrorsList[0].message);
            hideLoading();
        }
        showModal(results.message);
        hideLoading();

    }

    return (
        <section className="dashboard_background">
            {isLoading.value ? <LoadingForms message={isLoading.message} />
                : <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="dashboard_back_office">

                                {/*----- card header section---*/}
                                <div className="dashboard_view_data_heading">
                                    <div className="row m-0">
                                        <div className="col-1 p-0 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Serial No.</H4></span>
                                        </div>
                                        <div className="col-9 p-0 view_dashboard ps-3" style={{ textAlign: "left" }}>
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Remittance</H4></span>
                                        </div>
                                        <div className="col-2 p-0 ">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Amount</H4></span>
                                        </div>
                                    </div>
                                </div>

                                {remittance?.map((value, index) => {
                                    return (
                                        <div className="dashboard_view_data_main" key={index}>
                                            <div className="dashboard_view_data">
                                                <div className="row m-0">
                                                    <div className="col-1 p-0 view_dashboard">
                                                        <span>
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{index + 1}</H4>
                                                        </span>
                                                    </div>
                                                    <div className="col-9 p-0 ps-3 view_dashboard" style={{ textAlign: "left" }}>
                                                        <span>
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{value.name === "FINAL_MILESTONE" ? "COURSE COMPLETION" : removeUnderscoreFromText(value?.milestoneName)}</H4>
                                                        </span>
                                                    </div>
                                                    <div className="col-2 p-0">
                                                        <span ><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{value?.amount}</H4></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="row mt-2">

                                    <div className="col-lg-5 col-md-6 refund_preview_comment">
                                        <label className="mb-1"><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Comment</P></label>
                                        {
                                            refundData?.status === "RECEIVED" ?
                                                <textarea className="form-control" rows="3" placeholder="No Comment" value={refundData?.comment?.length > 0 && refundData?.comment !== "" ? refundData?.comment?.pop() : "No Comment"} disabled />
                                                :
                                                <textarea className="form-control" rows="3" placeholder="Type Your Comment Here" ref={comment} />
                                        }
                                    </div>

                                    <div className="col-lg-7 col-md-6 refund_preview_total_amount" style={{ textAlign: "left" }}>
                                        <div className="row">
                                            <div className="col-lg-8 col-md-7" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                <label><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Total</P></label>
                                            </div>
                                            <div className="col-lg-4 col-md-5">
                                                <input type="number" className="form-control" value={totalAmount} disabled />
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-lg-8 col-md-7" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                <label><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Amount to be refund 10%</P></label>
                                            </div>
                                            <div className="col-lg-4 col-md-5">
                                                <input type="number" className="form-control" value={totalRefundAmount} onChange={(e) => setRefundAmount(e.target.value)} disabled={refundData?.status === "RECEIVED" ? true : false} />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="back_office_button">
                                    <button className="me-4" onClick={() => history.push("/back-office/dashboard/refund")}>Cancel</button>
                                    {
                                        refundData?.status === "RECEIVED" ? ""
                                            :
                                            <button onClick={receivedHandler}>{isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <span>Received</span>}</button>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }

        </section>
    );

}
export default RefundPreview;