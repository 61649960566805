import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import LoadingForms from "../../shared-components/LoadingForms";
import { frontOfficeCommunication } from "../../communications/front-office-communication";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import H6 from '../../text-components/H6';
import H5 from '../../text-components/H5';
import ReactPaginate from "react-paginate";


const NewApplication = () => {

    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [filteredApplication, setFilteredApplication] = useState([])
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const history = useHistory();


    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchNewApplicationsBySearch(searchValue, selectedPage + 1);
        } else {
            fetchNewApplications(selectedPage + 1);
        }
    }

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchNewApplicationsBySearch(searchString, currentPage + 1);
        } else {
            const applicationFromServer = await frontOfficeCommunication.getNewApplications();
            if (applicationFromServer.status === "SUCCESS") {
                setFilteredApplication(applicationFromServer?.userApplications);
                setPageCount(applicationFromServer?.totalPages);
            }
            else if (applicationFromServer.status === "UNAUTHORIZED_ACCESS") {
                showModal(applicationFromServer?.message);
                history.push("/front-office/login");
            } else {
                setFilteredApplication([]);
                setPageCount(0);
            }
        }
    }

    //get All New Application By Search
    const fetchNewApplicationsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await frontOfficeCommunication.getNewApplicationsBySearch(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setFilteredApplication(responseFromServer?.data?.applications);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                showModal(responseFromServer?.data?.message);
                history.push("/front-office/login");
            } else {
                setFilteredApplication([]);
                setPageCount(0);
            }
        } catch (error) {
            showModal(error?.message);
        }
    }

    async function goToApplication(applicationId, frontOfficeStatus) {
        if (frontOfficeStatus === "INCOMPLETE") {
            showModal("You can only access New application");
            return;
        }
        showLoading("Loading...");
        const accessApplicationFromServer = await frontOfficeCommunication.accessToApplication(applicationId);
        if (accessApplicationFromServer.status === "APPLICATION_AVAILABLE") {
            history.push(`/front-office/dashboard/application/?applicationId=${applicationId}&action=NEW_APPLICATION`);
        }
        showModal(accessApplicationFromServer.message);
        hideLoading();
    }

    //get All New Applications on Initial Load
    const fetchNewApplications = async (page) => {
        try {
            let fullUrl = window.location.href;
            let splitUrlArray = fullUrl.split('/');
            if (splitUrlArray.includes("new-application")) {
                dispatch({ type: "ACTIVE_TAB", payload: "NEW_APPLICATION" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_TAB" });
            }
            showLoading("Loading...");
            const applicationFromServer = await frontOfficeCommunication.getNewApplications(page);
            if (applicationFromServer.status === "SUCCESS") {
                setFilteredApplication(applicationFromServer?.userApplications);
                setPageCount(applicationFromServer?.totalPages);
            }
            else if (applicationFromServer.status === "UNAUTHORIZED_ACCESS") {
                showModal(applicationFromServer?.message);
                history.push("/front-office/login");
            } else {
                setFilteredApplication([]);
                setPageCount(0);
            }
            hideLoading();
        } catch (error) {
            showModal(error?.message);
        }
    }

    useEffect(() => {
        fetchNewApplications();
    }, []);

    // Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    return (
        <>
            <section className="dashboard_background" >
                <div className='container'>
                    {isLoading.value ?
                        <LoadingForms message={isLoading.message} />
                        :
                        <div className="dashboard_Front_office">
                            <div className="row search_wrapper">
                                <div className="col-lg-4 col-md-4 ps-0">
                                    <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Application ID, Name, University Name..." />
                                </div>
                                <div className="col-lg-8 col-md-8 pe-0 pagination_outer_wrapper">
                                    <div className="pagination_inner_wrapper">
                                        {pageCount > 1 &&
                                            <ReactPaginate
                                                previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                                nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                forcePage={currentPage}
                                                activeClassName={"active"} />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/*-----Grievance card header section---*/}
                            <div className="dashboard_view_data_heading">
                                <div className="row m-0">
                                    <div className="col-1 p-0 view_dashboard">
                                        <span><H6>Serial No.</H6></span>
                                    </div>
                                    <div className="col-1 p-0 view_dashboard">
                                        <span><H6>Application Id</H6></span>
                                    </div>
                                    <div className="col-1 p-0 view_dashboard">
                                        <span><H6>User Name</H6></span>
                                    </div>
                                    <div className="col-1 p-0 view_dashboard">
                                        <span><H6>University Name</H6></span>
                                    </div>
                                    <div className="col-1 p-0 view_dashboard text_align">
                                        <span><H6>University Rank</H6></span>
                                    </div>
                                    <div className="col-1 p-0 view_dashboard">
                                        <span><H6>Course</H6></span>
                                    </div>
                                    <div className="col-1 p-0 view_dashboard">
                                        <span><H6>Stream</H6></span>
                                    </div>
                                    <div className="col-1 p-0 view_dashboard">
                                        <span><H6>District</H6></span>
                                    </div>
                                    <div className="col-1 p-0 view_dashboard">
                                        <span><H6>Date</H6></span>
                                    </div>
                                    <div className="col-1 p-0 view_dashboard">
                                        <span><H6>Status</H6></span>
                                    </div>
                                    <div className="col-2 p-0 ">
                                        <span><H6>Comment</H6></span>
                                    </div>
                                </div>
                            </div>

                            {
                                filteredApplication?.length > 0 ?

                                    <div>
                                        {filteredApplication?.map((application, index) => {
                                            const { _id, shortApplicationId, frontOfficeStatus, universityRank, createdDate, frontOfficeApplicationComment, forms } = application;
                                            return (
                                                <div key={_id}>
                                                    <div className="dashboard_view_data_main">
                                                        <div className="dashboard_view_data">
                                                            <div className="row m-0" >
                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {index + 1}
                                                                        </H6>
                                                                    </span>
                                                                </div>
                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            <a onClick={() => { goToApplication(_id, frontOfficeStatus) }}>
                                                                                {shortApplicationId}
                                                                            </a>
                                                                        </H6>
                                                                    </span>
                                                                </div>

                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {`${forms?.personalDetailsForm?.studentName?.firstName} ${forms?.personalDetailsForm?.studentName?.middleName} ${forms?.personalDetailsForm?.studentName?.lastName}`}
                                                                        </H6>
                                                                    </span>
                                                                </div>

                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {forms.collegeAdmissionDetailsForm?.universityName?.university}
                                                                        </H6>
                                                                    </span>
                                                                </div>
                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {forms.collegeAdmissionDetailsForm?.universityName?.rank}

                                                                        </H6>
                                                                    </span>
                                                                </div>

                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {forms.collegeAdmissionDetailsForm?.courseDetails?.course}
                                                                        </H6>
                                                                    </span>
                                                                </div>

                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {forms.collegeAdmissionDetailsForm?.courseDetails?.stream}
                                                                        </H6>
                                                                    </span>
                                                                </div>

                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {forms?.personalDetailsForm?.address?.district}
                                                                        </H6>
                                                                    </span>
                                                                </div>

                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {new Date(createdDate).getDate()}-{new Date(createdDate).getMonth() + 1}-{new Date(createdDate).getFullYear()}

                                                                        </H6>
                                                                    </span>
                                                                </div>

                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span >
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {frontOfficeStatus}
                                                                        </H6>
                                                                    </span>
                                                                </div>
                                                                <div className="col-2 p-0 text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {
                                                                                frontOfficeApplicationComment ?
                                                                                    frontOfficeApplicationComment?.length > 0 ? frontOfficeApplicationComment[frontOfficeApplicationComment?.length - 1] : "No Comment"
                                                                                    :
                                                                                    "No Comment"
                                                                            }
                                                                        </H6>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            );
                                        })}
                                    </div>
                                    :

                                    <div className="text-center mt-5">
                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Records not available</H5>
                                    </div>
                            }

                            {/*-----Grievance card data section---*/}
                        </div>
                    }
                </div>

            </section>


        </>
    )
};

export default NewApplication;
