import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import H4 from "../../../text-components/H4";
import { useLocation } from "react-router-dom";
import { backOfficeCommunication } from "../../../communications/back-office-communication";
import LoadingForms from "../../../shared-components/LoadingForms";
import { useDispatch } from "react-redux";


const UserMilestoneOne = ({ changeHandler }) => {

  const [isLoading, setIsLoading] = useState({ message: "", value: false });
  const location = useLocation();

  const history = useHistory();
  const [milestone, setMileStone] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [type, setType] = useState("");
  const [applicationId, setApplicationId] = useState("");


  //Alert Modal react-redux
  const dispatch = useDispatch();
  function showModal(text) {
    dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
  }

  function showLoading(message) {
    setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
  }

  function hideLoading() {
    setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
  }

  // show document scrutiny uploaded documents preview window
  function showDocumentScrutinyUploadedDocumentPreview(applicationId, type, fileUrl) {
    setType(type)
    history.push(`/back-office/dashboard/document-scrutiny/milestone-list/uploaded-documents/preview?milestoneId=${milestone._id.toString()}&applicationId=${applicationId}&fileUrl=${fileUrl}&type=${type}`);
  }
  //close document scrutiny uploaded files window
  function closeDocumentScrutinyUploadedFiles(applicationId, type) {
    setType(type)
    history.push(`/back-office/dashboard/document-scrutiny/milestone-list?milestoneId=${milestone._id.toString()}&applicationId=${applicationId}&type=${type}`);
  }

  const [comment, setComment] = useState("");

  const getComment = (event) => {
    setComment(event.target.value);
  }


  const markOneComplete = async () => {
    try {

      showLoading("Loading...");

      const completeMark = await backOfficeCommunication.markOneComplete(applicationId, type, comment);

      if (completeMark?.status === "SUCCESS") {
        changeHandler();
        showModal(completeMark?.message);
        history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`);
      }
      else {
        showModal(completeMark?.message);
      }
      hideLoading();

    } catch (error) {
      showModal(error?.message);
      hideLoading();
    }

  }

  const markOneInComplete = async () => {
    try {
      showLoading("Loading...");
      if (comment === "") {
        showModal("you must add comment");
        hideLoading();
        return;
      }

      const incompleteMark = await backOfficeCommunication.markOneInComplete(applicationId, type, comment);

      if (incompleteMark?.status === "SUCCESS") {
        await changeHandler();
        showModal(incompleteMark?.message);
        history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`);
      }
      else {
        showModal(incompleteMark?.message);

      }
      hideLoading();

    } catch (error) {
      showModal(error?.message);
      hideLoading();
    }
  }


  const goToApplication = async (applicationId, id, type) => {
    showLoading("Loading...");
    history.push(`/back-office/dashboard/application/?applicationId=${applicationId}&action=${type}&milestoneId=${id}`);
    hideLoading();
  }

  async function getMilestoneDocument() {
    try {
      // let fullUrl = window.location.href;
      // let splitUrlArray = fullUrl.split('/');
      // if (splitUrlArray.includes("document-scrutiny")) {
      //   dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
      // } else {
      //   dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
      // }

      showLoading("Loading...");

      const queryParams = new URLSearchParams(location.search);
      const applicationId = queryParams.get('applicationId');
      const milestoneId = queryParams.get('milestoneId');
      const type = queryParams.get('type');

      const response = await backOfficeCommunication.getMilestone(milestoneId, applicationId, type);

      if (response?.status === "SUCCESS") {
        if (["NEW", "IN_COMPLETED"].includes(response?.milestones[0]?.userStatus) && ["NO_USER_ACTION", "IN_COMPLETED"].includes(response?.milestones[0]?.backOfficeStatus)) {
          setDisabled(true);
        }
        else if (response?.milestones[0].backOfficeStatus === "APPROVED") {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
        setMileStone(response?.milestones[0]);
        setApplicationId(applicationId);
        setType(type);
      } else {
        showModal(response?.message);
      }
      hideLoading();

    } catch (error) {
      showModal(error?.message);
    }
  }

  let fullUrl = window.location.href;
  let splitUrlArray = fullUrl.split('/');
  let splitUrl = splitUrlArray[splitUrlArray?.length - 1]?.split('?');
  if (splitUrl.includes("milestoneOne")) {
    dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
  } else {
    dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
  }

  useEffect(() => {
    getMilestoneDocument();
  }, [])



  return (
    <>
      <section className="back_office_preview_section">
        <div className="container">
          {
            isLoading?.value ?
              <LoadingForms message={isLoading?.message} />
              :
              <div className="row">
                <div className="col-12 p-0">
                  <div className="back_office_preview_box">
                    <div className="back_office_preview_title">
                      <H4>{milestone?.name}</H4>
                      <div className="close_back_office_preview" onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="container">
                      {
                        milestone?.signedBondUrl?.length <= 0 && milestone?.documentsArray?.length <= 0 ?
                          <div className="col-12 text-center view_download_dashboard">
                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Document not available.</H4>
                          </div>
                          :
                          <div className="row mt-3">
                            <div className="col-6">

                              <div className="uploaded_documents">
                                {/*---heading section---*/}
                                {
                                  !!milestone?.signedBondUrl && milestone?.signedBondUrl?.length > 0 ?
                                    <>
                                      <div className="row">

                                        <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                          <span>
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Serial No.</H4>
                                          </span>
                                        </div>

                                        <div className="col-10 p-0 ps-4">
                                          <span>
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Name</H4>
                                          </span>
                                        </div>
                                      </div>
                                      {/*---uploaded file section---*/}

                                      <div className="row mt-3">
                                        <div className="col-2"></div>
                                        <div className="col-10 ps-4">
                                          <span>
                                            <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">Signed Bond Documents</H4>
                                          </span>
                                        </div>

                                      </div>
                                    </>
                                    :
                                    <></>
                                }

                                {
                                  !!milestone?.signedBondUrl && milestone?.signedBondUrl?.length > 0 ?
                                    <>
                                      {
                                        milestone?.signedBondUrl?.map((value, index) => {
                                          return (
                                            <div className="row mt-3" key={index}>

                                              <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                                <span>
                                                  <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">{index + 1}</H4>
                                                </span>
                                              </div>

                                              <div className="col-10 p-0 ps-4">
                                                <span>
                                                  <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD"><a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, value?.fileUrl, value?.documentName)} title="Click to preview document">{value.documentName}<i className="fa fa-paperclip fa-1x ms-2" aria-hidden="true"></i></a></H4>
                                                </span>
                                              </div>
                                            </div>
                                          )
                                        })
                                      }
                                    </>
                                    :
                                    <></>
                                }

                              </div>


                            </div>

                            <div className="col-6">

                              <div className="uploaded_documents">
                                {/*---heading section---*/}
                                {
                                  !!milestone?.documentsArray && milestone?.documentsArray?.length > 0 ?
                                    <>
                                      <div className="row">

                                        <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                          <span>
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Serial No.</H4>
                                          </span>
                                        </div>

                                        <div className="col-10 p-0 ps-4">
                                          <span>
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Name</H4>
                                          </span>
                                        </div>
                                      </div>
                                      {/*---uploaded file section---*/}
                                      <div className="row mt-3">
                                        <div className="col-2"></div>
                                        <div className="col-10 ps-4">
                                          <span>
                                            <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">Other Documents</H4>
                                          </span>
                                        </div>

                                      </div>
                                    </>
                                    :
                                    <></>
                                }

                                {
                                  milestone?.documentsArray?.map((value, index) => {
                                    return (
                                      <div className="row mt-3" key={index}>
                                        <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                          <span>
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">{index + 1}</H4>
                                          </span>
                                        </div>
                                        <div className="col-10 p-0 ps-4">
                                          <span>
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                              <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, value.fileUrl, value?.documentName)} title="Click to preview document">
                                                {value.documentName}<i className="fa fa-paperclip fa-1x ms-2" aria-hidden="true"></i>
                                              </a>
                                            </H4>
                                          </span>
                                        </div>
                                      </div>
                                    )
                                  })}

                              </div>
                            </div>
                          </div>
                      }
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      {disabled !== true ?
                        <div className="comment_box_main">
                          <div className="comment_box_heading">
                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Comment Box</H4>
                          </div>
                          <div className="comment_box_body">
                            <div className="comment_to_write_main">
                              <div className="comment_to_write">
                                <input className="form-control" placeholder="Write comment here..." onChange={getComment} />
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <></>
                      }
                    </div>
                    <div className="col-lg-6 mt-lg-5">
                      <div className="upload_home_verification_button">
                        <button className="ml-0" onClick={() => goToApplication(milestone?.applicationId, milestone?._id, milestone?.type)}>Application Preview</button>
                        {
                          disabled !== true ?
                            <>
                              <button className="ms-3 me-3" onClick={() => markOneInComplete(type)}>Incomplete</button>
                              <button className="me-3" onClick={() => markOneComplete(type)}>Approve</button>
                            </>
                            : <span className="ms-3"></span>
                        }
                        <button onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
          }
        </div>

      </section>
    </>
  )
}

export default UserMilestoneOne