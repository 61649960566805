import React from "react";

const DepartmentHierarchyImageBox = ({hideDepartmentHierarchy}) => {
    return(
        <div className="Hierarchy_ImageBox">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        
                         <span onClick={()=>{hideDepartmentHierarchy()}}>x</span>
                         <div className="image_box">
                         <img src="/assets/images/pngs/HierarchyDesign.png" alt="HierarchyDesign.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DepartmentHierarchyImageBox;