import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect, useHistory } from 'react-router';
import { useEffect } from "react";
import FrontOfficeNav from "../common/FrontOfficeNav";
import NewApplication from "./panels/NewApplication";
import ApplicationVerification from "./panels/ApplicationVerification";
import ShortListApplication from "./panels/ShortListApplication";
import MeritList from "./panels/MeritList";
import Application from "../main-components/Application";
import ApprovedList from "./panels/ApprovedList";
import WaitList from "./panels/WaitList";
import FrontOfficeLogout from "./FrontOfficeLogout";
import MinutesOfMeeting from "./panels/MinutesOfMeeting";
import ChangeQuota from "./ChangeQuota";
import FrontOfficeWelcome from "./panels/frontOfficeWelcome";
import CourseWiseApplication from "./panels/CourseWiseApplication";


const FrontOfficeDashboard = () => {

  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

  const showFrontOfficeNav = true;

  let isOfficeUserAuthenticated = useSelector((state) => state.isOfficeUserAuthenticated.value ?? true);
  let frontOfficeUserData = useSelector((state) => state.isOfficeUserAuthenticated?.officeUser?.portal ?? "FRONT_OFFICE");

  return (

    <div >
      {/* <UserDownloadApplicationFlowNav /> */}
      <div style={{ position: "relative" }}>
        {
          showFrontOfficeNav
            ?
            <FrontOfficeNav />
            :
            <span></span>
        }
        <FrontOfficeLogout />
      </div>

      <section className="application_process_main " style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
        <div className="row m-0 ">

          <div className="col-12 p-0 ">
            <Route exact path="/front-office/dashboard" render={() => {
            return (<Redirect to="/front-office/dashboard/Welcome" />);
            }} />
            {
              isOfficeUserAuthenticated && frontOfficeUserData === "FRONT_OFFICE" ?
                <Route exact path="/front-office/dashboard/Welcome" component={FrontOfficeWelcome} />
                :
                <Redirect to="/front-office/login" />
            }
            {
              isOfficeUserAuthenticated && frontOfficeUserData === "FRONT_OFFICE" ?
                <Route exact path="/front-office/dashboard/course-wise-application" component={CourseWiseApplication} />
                :
                <Redirect to="/front-office/login" />
            }
            {
              isOfficeUserAuthenticated && frontOfficeUserData === "FRONT_OFFICE" ?
                <Route exact path="/front-office/dashboard/new-application" component={NewApplication} />
                :
                <Redirect to="/front-office/login" />
            }
            {
              isOfficeUserAuthenticated && frontOfficeUserData === "FRONT_OFFICE" ?
                <Route path="/front-office/dashboard/application" component={Application} />
                :
                <Redirect to="/front-office/login" />
            }
            {
              isOfficeUserAuthenticated && frontOfficeUserData === "FRONT_OFFICE" ?
                <Route exact path="/front-office/dashboard/application-verification" component={ApplicationVerification} />
                :
                <Redirect to="/front-office/login" />
            }
            {
              isOfficeUserAuthenticated && frontOfficeUserData === "FRONT_OFFICE" ?
                <Route exact path="/front-office/dashboard/short-list-application" component={ShortListApplication} />
                :
                <Redirect to="/front-office/login" />
            }
            {
              isOfficeUserAuthenticated && frontOfficeUserData === "FRONT_OFFICE" ?
                <Route exact path="/front-office/dashboard/merit-list" component={MeritList} />
                :
                <Redirect to="/front-office/login" />
            }
            {
              isOfficeUserAuthenticated && frontOfficeUserData === "FRONT_OFFICE" ?
                <Route exact path="/front-office/dashboard/approved-list" component={ApprovedList} />
                :
                <Redirect to="/front-office/login" />
            }
            {
              isOfficeUserAuthenticated && frontOfficeUserData === "FRONT_OFFICE" ?
                <Route exact path="/front-office/dashboard/wait-list" component={WaitList} />
                :
                <Redirect to="/front-office/login" />
            }
            {
              isOfficeUserAuthenticated && frontOfficeUserData === "FRONT_OFFICE" ?
                <Route exact path="/front-office/dashboard/minutes-of-meeting" component={MinutesOfMeeting} />
                :
                <Redirect to="/front-office/login" />
            }
            {
              isOfficeUserAuthenticated && frontOfficeUserData === "FRONT_OFFICE" ?
                <Route exact path="/front-office/dashboard/change-quota" component={ChangeQuota} />
                :
                <Redirect to="/front-office/login" />
            }
          </div>
        </div>

      </section>
    </div>
  );

}
export default FrontOfficeDashboard;