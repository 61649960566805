import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import H5 from "../../text-components/H5";
import H4 from "../../text-components/H4";
import { useLocation } from "react-router-dom";
import LoadingForms from "../../shared-components/LoadingForms";
import { adminCommunication } from "../../communications/admin-communication";

const UpdateQuote = () => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const location = useLocation();
    const history = useHistory();
    const [availableQuota, setAvailableQuota] = useState([]);
    const [noOfQuata, setNoOfQuata] = useState(0);
    const [totalOccupiedQuata, setTotalOccupiedQuata] = useState(0);

    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    // Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }



    async function getData() {
        showLoading("Loading...");
        const getQuataFromServer = await adminCommunication.getQuotaDetailsFromServer();
        if (getQuataFromServer.status === "SUCCESS") {
            setAvailableQuota(getQuataFromServer.quota.courses);
        } else {
            showModal(getQuataFromServer.message);
            hideLoading()
        }




        hideLoading();
    }



    const updateQuataHandler = async () => {
        showLoading("Loading...");
        // let noOfQuata = 0;
        // availableQuota.map((data) => {
        //     noOfQuata = noOfQuata + Number(data.quota);
        // });

        if (noOfQuata > 75) {
            hideLoading();
            showModal("Quata must be less than or equal to 75");
            return false;
        }
        const response = await adminCommunication.updateQuota(availableQuota);
        if (response.status === "SUCCESS") {
            setAvailableQuota(response.quota)
            getData();
        }
        showModal(response.message);
        hideLoading();
    }


    const handleChange = (event, idx, name) => {

        if (name === "quota") {
            availableQuota[Number(idx)].quota = Number(event.target.value);
            setAvailableQuota([...availableQuota])
        }

        if (name === "occupied") {
            availableQuota[Number(idx)].occupied = Number(event.target.value);
            setAvailableQuota([...availableQuota])
        }
    }

    function calculateQuota() {
        let sum = 0;
        availableQuota.forEach((data) => {
            sum = sum + Number(data.quota)
        });
        setNoOfQuata(sum);
    }

    function calculateOccupiedQuota() {
        let sum = 0;
        availableQuota.forEach((data) => {
            sum = sum + Number(data.occupied)
        });
        setTotalOccupiedQuata(sum);
    }

    useEffect(() => {

        getData();
        calculateQuota();
        calculateOccupiedQuota();

    }, []);



    useEffect(() => {
        calculateQuota();
        calculateOccupiedQuota();
    }, [availableQuota]);


    return (
        <>
            <section >
                <div className="container">
                    <section className="dashboard_background">
                        {isLoading.value ?
                            <LoadingForms message={isLoading.message} />
                            :
                            <>
                                <div className="dashbord_main" style={{ margin: "15px 0 0 0" }}>
                                    {/*-----Grievance card header section---*/}
                                    <div className="dashboard_view_data_heading" >
                                        <div className="row m-0">
                                            <div className="col-1 p-0 view_dashboard">
                                                <span><H5 >Serial No.</H5></span>
                                            </div>
                                            <div className="col-2 p-0 view_dashboard">
                                                <span><H5 >Course Type</H5></span>
                                            </div>
                                            <div className="col-3 p-0 view_dashboard">
                                                <span><H5 >Stream</H5></span>
                                            </div>
                                            <div className="col-3 p-0 view_dashboard">
                                                <span><H5 >Quota</H5></span>
                                            </div>
                                            <div className="col-3 p-0 ">
                                                <span><H5 >Occupied</H5></span>
                                            </div>
                                        </div>
                                    </div>

                                    {availableQuota ?
                                        availableQuota.map((allQuata, index) => {
                                            const { courseType, stream, quota, occupied } = allQuata;

                                            return (
                                                <div className="dashboard_view_data_main" key={index}>
                                                    <div className="dashboard_view_data">
                                                        <div className="row m-0">
                                                            <div className="col-1 p-0 view_dashboard text_align">
                                                                <span>
                                                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                        {index + 1}
                                                                    </H5>
                                                                </span>
                                                            </div>
                                                            <div className="col-2 p-0 view_dashboard text_align">
                                                                <span>
                                                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                        {courseType}
                                                                    </H5>
                                                                </span>
                                                            </div>
                                                            <div className="col-3 p-0 view_dashboard text_align">
                                                                <span>
                                                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                        {stream}
                                                                    </H5>
                                                                </span>
                                                            </div>
                                                            <div className="col-3 p-0 view_dashboard dashbord_input text_align">
                                                                <span>
                                                                    <input placeholder="E.g. 10" type="string" name={`quota-${index}`} value={quota} className="form-control" onChange={(event) => handleChange(event, index, "quota")} style={{ textAlign: "center" }} />
                                                                </span>
                                                            </div>
                                                            <div className="col-3 p-0 dashbord_input text_align">
                                                                <span>
                                                                    <input placeholder="E.g. 10" type="string" name={`occupied-${index}`} value={occupied} className="form-control" onChange={(event) => handleChange(event, index, "occupied")} style={{ textAlign: "center" }} />
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })

                                        :
                                        <span></span>
                                    }

                                    <div className="dashboard_view_data_main">
                                        <div className="dashboard_view_data">
                                            <div className="row m-0">
                                                <div className="col-6 p-0 view_dashboard">
                                                    <span>
                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >

                                                            Total

                                                        </H5>
                                                    </span>
                                                </div>

                                                <div className="col-3 p-0 view_dashboard">
                                                    <span>
                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                            {noOfQuata}
                                                        </H5>
                                                    </span>
                                                </div>
                                                <div className="col-3 p-0 dashbord_input">
                                                    <span>
                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                            {totalOccupiedQuata}
                                                        </H5>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row total_quota">
                                        <div className="col-6" style={{ textAlign: 'right' }}><H4>Total Quata:</H4></div>
                                        <div className="col-3" style={{ textAlign: 'center' }}><H4></H4></div>
                                    </div>
                                    <div className="row total_quota">
                                        <div className="col-6" style={{ textAlign: 'right' }}><H4>Total Occupied Quata:</H4></div>
                                        <div className="col-3" style={{ textAlign: 'center' }}><H4></H4></div>
                                    </div> */}

                                </div>
                                <section className="mb-3">
                                    <div className="row">
                                        <div className="col-2 button_div">
                                            <div className="dashboard_delete_button">
                                                <button type="button" className="btn" onClick={updateQuataHandler}>
                                                    {isLoading.value ? <img src="/assets/images/gif/loading-circle.gif" style={{ width: "10px", height: "10px" }} /> : <H5>Update</H5>}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>
                        }
                    </section>

                </div>

            </section>
        </>

    );
}
export default UpdateQuote;