import React from "react";
import { communication } from "../communications/communication";
import YearlyApplications from "../main-components/YearlyApplications";
import AmountDisbursed from "../main-components/AmountDisbursed";
import CourseWiseApplicant from "../main-components/CourseWiseApplicant";
import YearlyCourseCompletion from "../main-components/YearlyCourseCompletion";
import DistrictWiseApplication from "../main-components/DistrictWiseApplication";
import CountryWiseApplication from "../main-components/CountryWiseApplication";

const Statistic = ({ graph }) => {

  return (
    <section className="statistic_mobile_main">
      <div className="row">
        <div className="col-12">
          <YearlyApplications graph={graph?.find((value) => { if (value.type === "YEARLYAPPLICATION") return value })} />
        </div>
        <div className="col-12 mt-3 mb-3">
          <AmountDisbursed graph={graph.find((value) => { if (value.type === "AMOUNTDISBURSED") return value })} />
        </div>
        <div className="col-12">
          <CourseWiseApplicant graph={graph.find((value) => { if (value.type === "COURSEWISEAPPLICATION") return value })} />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <YearlyCourseCompletion graph={graph.find((value) => { if (value.type === "YEARLYCOURSEAPPLICATION") return value })} />
        </div>
        <div className="col-12 mt-3 mb-3">
          <DistrictWiseApplication graph={graph.find((value) => { if (value.type === "DISTRICWISEAPPLICATION") return value })} />
        </div>
        <div className="col-12">
          <CountryWiseApplication graph={graph.find((value) => { if (value.type === "COUNTRYWISEAPPLICATION") return value })} />
        </div>
      </div>

    </section>
  );
}

export default Statistic;