const subCasteNameList = [
 "Ager" ,
 "Anamuk" ,
 "Aray Mala" ,  
 "Arwa Mala" ,
 "Bahna" ,
 "Bahana" ,
 "Bakad" ,
 "Bant" ,
 "Balahi" ,
 "Balai" ,
 " Balai" ,
 "Basor" ,
 "Bansor" ,
 "Bansor" ,
 "Bansodi" ,
 "Basod" ,
 "Beda Jangam" ,
 "BudgaJangam" ,
 "Bedar" ,
 "Bhambi" ,
 "Bhambhi" ,
 "Asadaru" ,
 "Asodi" ,
 "Chamadia" ,
 "Chamar" ,
 "Chamari" ,
 "Chambahar" ,
 "Chamgar" ,
 "Haralayya" ,
 "Harali" ,
 "Khalpa" ,
 "Machigar" ,
 "Mochigar" ,
 "Madar" ,
 "Madig" ,
 "Mochi" ,
 "Ranigar" ,
 "Rohidas" ,
 "Nona" ,
 "Ramniami" ,
 "Rohit" ,
 "Samgar" ,
 "Samagara" ,
 "Satnami" ,
 "Surjyabanshi" ,
 "Surjyaramnami" ,
 "Charmakar" ,
 "Pardeshi Chamar" ,
 "Bhangi " ,
 "Mehtar" ,
 "Olgana" ,
 "Rukhi" ,
 "Malkana" ,
 "HalalKhor" ,
 "Lalbegi" ,
 "Balmiki" ,
 "Korar" ,
 "Zadmalli" ,
 "Hele" ,
 "Bindla" ,
 "Byagara" ,
 "Chalvadi" ,
 "Channayya" ,
 "Chenna Dasar" ,
 "Holaya Dasar" ,
 "Holeya Dasari" ,
 "Dakkal" ,
 "Dokkalwar" ,
 "Dhor" ,
 "Kakkayya" ,
 "Dohor" ,
 "Dom" ,
 "Dumar" ,
 "Ellamalvar" ,
 "Yellammalawandlu" ,
 "Ganda" ,
 "Gandi" ,
 "Garoda" ,
  "Gara" ,
 "Ghasi" ,
 "Ghasia" ,
 "Halleer" ,
 "Halsar " ,
 "Haslar " ,
 "Hulasvar" ,
 "Halasvar ",
 "Holar" ,
 "Valhar" ,
 "Holaya" ,
 "Holer" ,
 "Holeya" ,
 "Holiya" ,
 "Kaikadi" ,
 "Katia" ,
 "Patharia" ,
 "Khangar" ,
 "Kanera" ,
 "Mirdha" ,
 "Kanera" ,
 "Mirdha" ,
 "Mirdha" ,
 "Khatik" ,
 "Chikwa" ,
 "Chikvi" ,
 "Kolupulvandlu" ,
 "Kori" ,
 "Lingader" ,
 "Madigi" ,
 "Mahar",
 " Mehra",
 "Taral" ,
 "Dhegu Megu" ,
 "Dhed" ,
 "Vankar" ,
 "Maru Vankar" ,
 "Mahyavanshi" ,
 "Mala" ,
 "Mala Dasari" ,
 "Mala Hannai" ,
 "Mala Jangam" ,
 "Mala Masti" ,
 "Mala Sale" ,
 "Mala Sanyasi" ,
 "Netkani" ,
 "Mang" ,
 "Matang" ,
 "Minimadig" ,
 "Dankhni Mang" ,
 "Mang Mahashi" ,
 "Madari" ,
 "Garudi" ,
 "Radhe Mang" ,
 "Mang Garodi" ,
 "Mang Garudi" ,
 "Manne" ,
 "Mashti" ,
 "Meghval Menghvar" ,
 "Mitha Ayyalvar" ,
 "Mukri" ,
 "Nadia " ,
 "Hadi" ,
 "Pasi" ,
 "Sansi" ,
 "Shenva" ,
 "Chenva" ,
 "Sedma" ,
 "Ravat" ,
 "Sindhollu" ,
 "Chindollu" ,
 "Tirgar" ,
 "Tirbanda" ,
 "Turi" ,
]

export default subCasteNameList