import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import P from "../../text-components/P";
import H3 from "../../text-components/H3";
import H4 from "../../text-components/H4";
import H5 from "../../text-components/H5";
import { useLocation } from "react-router-dom";
import { backOfficeCommunication } from "../../communications/back-office-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import { useReactToPrint } from 'react-to-print'
import Label from "../../text-components/Label";



const GovernmentResolutionPreview = ({ changeHandler }) => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [prepareGrList, setPrepareGrList] = useState([]);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [signedGr, setSignedGr] = useState();
    const [disable, setDisable] = useState(false);
    const printDocumentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => printDocumentRef.current,
    })

    const location = useLocation();

    const history = useHistory();

    const closeGovernmentResolutionPreview = () => {
        history.push('/back-office/dashboard/government-resolution');
    }
    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }


    // Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }


    useEffect(() => {
        async function initialLoad() {
            let fullUrl = window.location.href;
            let splitUrlArray = fullUrl.split('/');
            if (splitUrlArray.includes("government-resolution")) {
                dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "GOVERNMENT_RESOLUTION" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
            }
            showLoading("Loading...");
            // getting committee id by queryParams
            const queryParams = new URLSearchParams(location.search);
            const grIdParams = queryParams.get('grId');
            const getPrepareGrList = await backOfficeCommunication.getPrepareGrList(grIdParams);
            let prepareGrList = getPrepareGrList.applications;
            if (getPrepareGrList.status === "SUCCESS") {
                setPrepareGrList(value => {
                    return [...value, ...prepareGrList];
                })
            }
            else {
                showModal(getPrepareGrList.message)
            }
            hideLoading();
        }
        initialLoad();
    }, [])


    const uploadSignGr = async () => {
        showLoading("Loading...");

        const queryParams = new URLSearchParams(location.search);
        const grIdParams = queryParams.get('grId');

        const formData = new FormData();

        formData.append('uploadedFile', signedGr);

        const uploadSignGr = await backOfficeCommunication.uploadSignGr(grIdParams, formData);

        if (uploadSignGr.status === "SUCCESS" || uploadSignGr.data.status === "SUCCESS") {
            changeHandler();
            history.push("/back-office/dashboard/government-resolution");
        }
        hideLoading();
        showModal(uploadSignGr.message || uploadSignGr.data.message);
    }


    function selectFile(e) {
        setSignedGr(e.target.files[0]);
        setDisable(true);
    }


    return (
        <section className="back_office_preview_section">
            {isLoading.value ?
                <LoadingForms message={isLoading.message} />
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="back_office_preview_box" >

                                <div className="back_office_preview_title">
                                    <H3>Government Resolution</H3>
                                    <div className="close_back_office_preview" onClick={() => closeGovernmentResolutionPreview()}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div ref={printDocumentRef}>
                                    {prepareGrList.map((grList, index) => {
                                        const { applications, course, stream, createdDate } = grList;
                                        return (
                                            <div className="row gr_resolution" key={index} >
                                                <div className="col-12 gr_heading mt-3" >

                                                    <table>
                                                        <tr>
                                                            <td><P >{`शासन निर्णय क्र. सा.न्या.व.वि.स.वि .,क्र पशिसो -`}
                                                                <span>{new Date(createdDate).getFullYear()}</span>
                                                                {`/प्र. क्र.      /शिक्षण-१,दिनांक `} &nbsp;
                                                                {new Date(createdDate).getDate()}-{new Date(createdDate).getMonth() + 1}-{new Date(createdDate).getFullYear()} &nbsp;
                                                                {`चे सहपत्र`}
                                                            </P>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                <H5>
                                                                    सन &nbsp;
                                                                    {new Date(createdDate).getFullYear()} - {new Date(createdDate).getFullYear() + 1} &nbsp;
                                                                    करीत अनुसूचित जातीच्या विद्यार्थ्यांना परदेश शिष्यवृत्ती - निवड यादी
                                                                </H5>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                <H5>
                                                                    प्रपत्र-अ -१
                                                                </H5>
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            <th>
                                                                <H5>
                                                                    पदव्युत्तर पदवी- {course} {stream}
                                                                </H5>
                                                            </th>
                                                        </tr>
                                                    </table>
                                                </div>

                                                <div className="col-12 gr_list_heading" >

                                                    <table>
                                                        <tr>
                                                            <th>
                                                                <H5>
                                                                    अ. क्र.
                                                                </H5>
                                                            </th>
                                                            <th>
                                                                <H5>
                                                                    विद्याथ्याचे नाव
                                                                </H5>
                                                            </th>
                                                            <th>
                                                                <H5>
                                                                    प्रवेश घेतलेल्या विद्यापीठाचे नाव
                                                                </H5>
                                                            </th>
                                                            <th>
                                                                <H5>
                                                                    विद्यापीठाच्या देशाचे नाव
                                                                </H5>
                                                            </th>
                                                            <th>
                                                                <H5>
                                                                    QS World Ranking &nbsp;
                                                                    {new Date(createdDate).getFullYear()}
                                                                </H5>
                                                            </th>
                                                            <th>
                                                                <H5>
                                                                    अभ्यास क्रम
                                                                </H5>
                                                            </th>
                                                            <th>
                                                                <H5>
                                                                    परदेशातील विद्यापीठातील अभ्यासक्रमाचे नाव
                                                                </H5>
                                                            </th>
                                                            <th>
                                                                <H5>
                                                                    अभ्यास क्रम कालावधी
                                                                </H5>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                <H5>
                                                                    1
                                                                </H5>
                                                            </th>
                                                            <th>
                                                                <H5>
                                                                    2
                                                                </H5>
                                                            </th>
                                                            <th>
                                                                <H5>
                                                                    3
                                                                </H5>
                                                            </th>
                                                            <th>
                                                                <H5>
                                                                    4
                                                                </H5>
                                                            </th>
                                                            <th>
                                                                <H5>
                                                                    5
                                                                </H5>
                                                            </th>
                                                            <th>
                                                                <H5>
                                                                    6
                                                                </H5>
                                                            </th>
                                                            <th>
                                                                <H5>
                                                                    7
                                                                </H5>
                                                            </th>
                                                            <th>
                                                                <H5>
                                                                    8
                                                                </H5>
                                                            </th>
                                                        </tr>
                                                        {applications.map((application, index) => {
                                                            const { fullName, universityRank, specialization, countryName, collegeName, courseDuration, course, stream } = application;
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <p>
                                                                            {index + 1}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            {fullName}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            {collegeName}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            {countryName}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            {universityRank}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            {course}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            {specialization}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            {courseDuration}
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </table>
                                                </div>

                                            </div>
                                        )
                                    })}

                                </div>

                            </div>
                            <div className="file_name">
                                <H4>File Name: {signedGr?.name}</H4>
                            </div>
                            <div style={{ textAlign: "end", marginTop:"10px" }}>
                                <span style={{ fontSize: "14px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                            </div>
                            <div className="back_office_button" style={{marginTop:"10px"}}>

                                <button className=" me-4" onClick={handlePrint}>
                                    {isLoading.value ? <div style={{ width: "20px", height: "20px", }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H5>Print</H5>}
                                </button>
                                <div className="me-4">
                                    <input type="file" onChange={selectFile} name="uploadfile" id="file" style={{ display: "none" }} />
                                    <label for="file"><H5>Upload Signed GR</H5></label>
                                </div>
                                {disable ?
                                    <button onClick={() => uploadSignGr()} >
                                        {isLoading.value ? <div style={{ width: "20px", height: "20px", }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H5>Submit</H5>}
                                    </button>
                                    :
                                    <span></span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </section>
    );
}
export default GovernmentResolutionPreview;