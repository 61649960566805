import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import H1 from "../text-components/H1";
import H3 from "../text-components/H3";
import H4 from "../text-components/H4";
import { communication } from "../communications/communication";


const SignIn = () => {

   //Alert Modal react-redux
   const dispatch = useDispatch();
   function showModal(text) {
      dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
   }


   const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
   const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
   const [loginFormSubmission, setLoginFormSubmission] = useState({});
   // spinner on loading 
   const [isLoading, setIsLoading] = useState({ message: "", value: false });

   // cancel, save and submit buttons unable & desable
   const [disableActions, setDisableActions] = useState(false);

   const history = useHistory();

   const validationSchema = Yup.object().shape({
      userId: Yup.string().required("User ID is required"),
      password: Yup.string().required("Password is required")
   });
   const formOptions = { resolver: yupResolver(validationSchema) };
   // get functions to build with useForm() hook
   const { register, handleSubmit, reset, formState } = useForm(formOptions);
   const { errors } = formState;

   //spinner show and hide function declaration
   function showLoading(message) {
      setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
   }

   function hideLoading() {
      setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
   }
   // Function called on submit form 
   // receive form data after successful validation 
   const onSubmit = async (data) => {
      showLoading();
      setDisableActions(true);
      setLoginFormSubmission((value) => {
         return { ...value, ...{ status: "LOADING" } };
      });

      data = {
         userId: data.userId.trim(),
         password: data.password.trim()
      }

      // Sending login credentials to the server and getting response
      let loginResponse = await communication.loginUser(data);
      // Setting the login response from the server in a local state
      setLoginFormSubmission(value => {
         let updatedValues = {};
         updatedValues.status = loginResponse.status;
         updatedValues.message = loginResponse.message;
         return { ...value, ...updatedValues };
      });

      // Handling login response from the server
      if (loginResponse.status === "LOGIN_SUCCESSFUL") {
         // using dispatch to change the isUserAuthenticated state in redux store 
         // and set the jwt token in a cookie
         dispatch({ type: "AUTHENTICATE_USER", payload: loginResponse.jwt });
         // Navigate user inside user portal
         history.push('/user/');
      } else if (loginResponse.status === "VALIDATION_FAILED") {
         let errors = "";
         loginResponse.validationErrorsList.forEach(validationError => {
            errors = errors + `\n` + validationError.message;
         });
         setLoginFormSubmission(value => {
            return { ...value, ...{ message: errors } };
         });
      }
      hideLoading();
      setDisableActions(false);
      showModal(loginResponse.message);
   };


   return (
      <div>
         <section className="signin" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
            <div className="row m-0">
               <div className="col-12">
                  <form onSubmit={handleSubmit(onSubmit)} style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000", border: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }}>
                     <div style={{ marginBottom: "30px" }}>
                        <H1 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Sign In</H1>
                     </div>

                     <div className="input_field_div1">
                        <H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>User ID</H3>
                        <i className="fa fa-user" aria-hidden="true"></i>
                        <div className="input_field1">
                           <input type="text" name="userId" {...register("userId")} className={`form-control ${errors.userId ? "is-invalid" : ""}`} />
                           <div className="invalid-feedback">{errors.userId?.message}</div>
                        </div>
                     </div>

                     <div className="input_field_div2">
                        <H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Password</H3>
                        <i className="fa fa-lock" aria-hidden="true"></i>
                        <div className="input_field2">
                           <input type="password" name="password" {...register("password")} className={`form-control ${errors.password ? "is-invalid" : ""}`} autoComplete="on" />
                           <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>
                     </div>

                     <button type="submit" style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }} className="button" disabled={disableActions}> {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H3>Sign In</H3>}</button>

                     <div className="newreg_or_forgot">
                        <button onClick={() => history.push('/register')} style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }}><H4 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">New Registration?</H4></button>
                        <button onClick={() => history.push('/forgot-password')} style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }}><H4 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">Forget ID/ Password?</H4></button>
                     </div>
                  </form>
               </div>
            </div>
         </section>
      </div>
   );
}

export default SignIn;