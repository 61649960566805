import React from "react";
import H1 from "../../text-components/H1";
import { useSelector } from "react-redux";

const FrontOfficeWelcome = () => {
    // set admin data 
    const officeUser = useSelector((state) => state.isOfficeUserAuthenticated.officeUser)

    return (
        <div>
            <div className="row dashboard_background">
                <div className="col download_excel admin_home">
                    <H1 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Welcome,
                        {officeUser ? officeUser.name : ""}
                    </H1>
                </div>
            </div>
        </div>
    )
}

export default FrontOfficeWelcome;