import React, { useState, useEffect, } from "react";
import { useHistory } from "react-router";
import H4 from "../../../text-components/H4";
import { useLocation } from "react-router-dom";
import { backOfficeCommunication } from "../../../communications/back-office-communication";
import LoadingForms from "../../../shared-components/LoadingForms";
import { useDispatch } from "react-redux";
import { removeUnderscoreFromText } from "../../../utlis/helper";
import Label from "../../../text-components/Label";


const GeneralMilestone = ({ changeHandler }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [disabled, setDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [applicationId, setApplicationId] = useState("");
    const [milestone, setMileStone] = useState("")
    const [type, setType] = useState("");


    const [getSelect, setSelectedFile] = useState(null);
    const [comment, setComment] = useState("");


    function showDocumentScrutinyUploadedDocumentPreview(applicationId, type, fileUrl) {
        setType(type)
        history.push(`/back-office/dashboard/document-scrutiny/milestone-list/uploaded-documents/preview?milestoneId=${milestone._id.toString()}&applicationId=${applicationId}&fileUrl=${fileUrl}&type=${type}`);
    }
    //close document scrutiny uploaded files window
    function closeDocumentScrutinyUploadedFiles(applicationId, type) {
        setType(type)
        history.push(`/back-office/dashboard/document-scrutiny/milestone-list?milestoneId=${milestone._id.toString()}&applicationId=${applicationId}&type=${type}`);
    }


    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    const getCommentForGeneralMilestone = (event) => {
        setComment(event.target.value);
    }


    const requestToTreasuryForGeneralMilestone = async () => {
        try {
            showLoading("Loading...");

            const requestToTreasury = await backOfficeCommunication.requestToTreasuryForGeneralMilestone(milestone._id);

            if (requestToTreasury.status === "SUCCESS") {
                changeHandler();
                hideLoading();
                showModal(requestToTreasury.message);
                // history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`);
            }
            else {
                hideLoading();
                showModal(requestToTreasury.message);

            }

        } catch (error) {
            showModal(error);
        }

    }

    const markFundReceiveForGeneralMilestone = async () => {
        try {
            showLoading("Loading...");

            const markFundReceive = await backOfficeCommunication.markFundReceiveForGeneralMilestone(milestone._id);

            if (markFundReceive.status === "SUCCESS") {
                hideLoading();
                showModal(markFundReceive.message);
                history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`);
            }
            else {
                hideLoading();
                showModal(markFundReceive.message);

            }

        } catch (error) {
            showModal(error);
        }

    }




    const ReceiptUploadForGeneralMilestone = async () => {
        try {
            showLoading("Loading...");

            if (getSelect === null || getSelect === "") {
                return showModal("Please select file");
            }

            const formData = new FormData();
            formData.append("document", getSelect);
            //  TODO VALIDATE FILE HERE
            const uploadReceipt = await backOfficeCommunication.uploadReceiptForGeneralMilestone(applicationId, formData, milestone._id);
            if (uploadReceipt.status === "SUCCESS") {
                hideLoading();
                showModal(uploadReceipt.data.message);
            }
            else {
                hideLoading();
                showModal(uploadReceipt.data.message);
            }

        } catch (error) {
            showModal(error);
        }

    }

    const generalMilestoneAsIncomplete = async () => {
        try {
            if (comment.trim() === "") {
                showModal("Please enter a comment");
                hideLoading();
                return true;
            }

            showLoading("Loading...");
            const result = await backOfficeCommunication.markGeneralMilestoneAsIncomplete(milestone._id, milestone.userId, comment);

            if (result.status === "SUCCESS") {
                showModal(result.message);
            } else {
                showModal(result.message);
            }
            getMilestoneDocument();
            hideLoading();
        } catch (error) {
            showModal(error);
            hideLoading();
        }

    }


    function selectFile(e) {

        setSelectedFile(e.target.files[0]);
    }

    async function getMilestoneDocument() {
        try {
            let fullUrl = window.location.href;
            let splitUrlArray = fullUrl?.split('/');
            if (splitUrlArray.includes("document-scrutiny")) {
                dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
            }

            showLoading("Loading...");

            const queryParams = new URLSearchParams(location.search);
            const applicationId = queryParams.get('applicationId');
            const milestoneId = queryParams.get('milestoneId');
            const type = queryParams.get('type');

            const response = await backOfficeCommunication.getMilestone(milestoneId, applicationId, type);
            if (response.status === "SUCCESS") {
                if (response.milestones[response.milestones.length - 1].backOfficeStatus === "APPROVED") {
                    setDisabled(true);
                } else {
                    setDisabled(false);
                }
                setMileStone(response.milestones[response.milestones.length - 1]);
                setApplicationId(applicationId);
                setType(type);
            } else {
                showModal(response?.message);
            }
            hideLoading();

        } catch (error) {
            showModal(error);
        }
    }

    useEffect(() => {
        getMilestoneDocument();
    }, []);

    return (
        <section className="back_office_preview_section">
            {isLoading?.value ?
                <LoadingForms message={isLoading?.message} />
                : <div className="container">
                    <div className="row">
                        <div className="row">
                            <div className="col-12 p-0">
                                <div className="back_office_preview_box">
                                    <div className="back_office_preview_title">
                                        <H4> {removeUnderscoreFromText(milestone.name)}</H4>
                                        <div className="close_back_office_preview" onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div className="uploaded_documents ps-4 pt-3 pb-2">
                                        {/*---heading section---*/}
                                        <div className="row">
                                            <div className="col-4" style={{ overflow: "hidden" }}>
                                                {milestone.name === "OTHER_MILESTONE" ?
                                                    <span>
                                                        <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">Uploaded Document</H4>
                                                    </span>
                                                    :
                                                    <span>
                                                        <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">Current year Expenditure report</H4>
                                                    </span>
                                                }

                                                {
                                                    milestone?.prevDocuments && milestone?.prevDocuments !== "" && milestone?.prevDocuments.length > 0 && milestone?.prevDocuments.map((ele, idx) => {
                                                        return (
                                                            <div className="mt-2" key={idx}>
                                                                <span>
                                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, ele.fileUrl)} title="Click to preview document">
                                                                            {removeUnderscoreFromText(ele?.documentName)} <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                                        </a>
                                                                    </H4>
                                                                </span>
                                                            </div>
                                                        )
                                                    })
                                                }

                                                {
                                                    milestone?.otherDocuments && milestone?.otherDocuments !== "" && milestone?.otherDocuments.map((ele, idx) => {
                                                        return (
                                                            <div className="mt-2 mb-2" key={idx}>
                                                                <span>
                                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, ele?.fileUrl
                                                                        )} title="Click to preview document">
                                                                            {removeUnderscoreFromText(ele?.documentName)} <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                                        </a>
                                                                    </H4>
                                                                </span>
                                                            </div>
                                                        )
                                                    })

                                                }
                                            </div>

                                            {
                                                ((!!milestone?.userFundsReceiptUrl && milestone?.userFundsReceiptUrl?.length > 0) || (milestone?.treasuryConfirmationReceipt && milestone?.treasuryConfirmationReceipt !== "")) ?
                                                    <div className="col-4">
                                                        <span>
                                                            <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00"> Department Uploaded Documents</H4>
                                                        </span>


                                                        {
                                                            milestone?.userFundsReceiptUrl && milestone?.userFundsReceiptUrl !== "" && milestone?.userFundsReceiptUrl.map((ele, idx) => {
                                                                return (
                                                                    <div className="mt-2 mb-2" key={idx}>
                                                                        <span>
                                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                                <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, ele?.fileUrl
                                                                                )} title="Click to preview document">
                                                                                    {removeUnderscoreFromText(ele?.documentName)} <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                                                </a>
                                                                            </H4>
                                                                        </span>
                                                                    </div>
                                                                )
                                                            })

                                                        }

                                                        {/* {
                                                            milestone?.otherDocuments && milestone?.otherDocuments !== "" && milestone?.otherDocuments.map((ele, idx) => {
                                                                return (
                                                                    <div className="mt-2 mb-2" key={idx}>
                                                                        <span>
                                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                                <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, ele?.fileUrl
                                                                                )} title="Click to preview document">
                                                                                    {ele?.documentName} <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                                                </a>
                                                                            </H4>
                                                                        </span>
                                                                    </div>
                                                                )
                                                            })

                                                        } */}

                                                        {
                                                            milestone?.treasuryConfirmationReceipt && milestone?.treasuryConfirmationReceipt !== "" &&
                                                            <span>
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                    <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.treasuryConfirmationReceipt,)} title="Click to preview document">
                                                                        Treasury confirmation Receipt <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                                    </a>
                                                                </H4>
                                                            </span>
                                                        }

                                                        {
                                                            milestone?.semesterFeeAndAcknowledgementCertificateUrl && milestone?.semesterFeeAndAcknowledgementCertificateUrl !== "" &&
                                                            <div className="mt-2 mb-2">
                                                                <span>
                                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.semesterFeeAndAcknowledgementCertificateUrl)} title="Click to preview document">
                                                                            SemesterFee And acknowledgement Certificate
                                                                        </a>
                                                                    </H4>
                                                                </span>
                                                            </div>
                                                        }

                                                    </div>
                                                    :
                                                    <div className="col-2"></div>
                                            }

                                            <div className="col-4">
                                                {
                                                   ( milestone?.type === "SEM_FEES" ||  milestone?.type === "COURSE_COMPLETION") && milestone?.hostelFeesReportUrl && milestone?.marksheetReportUrl && milestone?.attendenceReportUrl && milestone?.attendenceReportUrl && milestone?.foodExpenseReportUrl &&
                                                    <span>
                                                        <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">Student uploaded Documents</H4>
                                                    </span>
                                                }

                                                {
                                                    milestone?.hostelFeesReportUrl && milestone?.hostelFeesReportUrl !== "" &&
                                                    <div className="mt-2 mb-2">
                                                        <span>
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.hostelFeesReportUrl)} title="Click to preview document">
                                                                    Hostel Fees Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                                </a>
                                                            </H4>
                                                        </span>
                                                    </div>
                                                }

                                                {
                                                    milestone?.marksheetReportUrl && milestone?.marksheetReportUrl !== "" &&
                                                    <span>
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                            <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone.marksheetReportUrl)} title="Click to preview document">
                                                                MarkSheet Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                            </a>
                                                        </H4>
                                                    </span>
                                                }

                                                {
                                                    milestone?.attendenceReportUrl && milestone?.attendenceReportUrl !== "" &&
                                                    <div className="mt-2 mb-2">
                                                        <span>
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone.attendenceReportUrl)} title="Click to preview document">
                                                                    Attendance Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                                </a>
                                                            </H4>
                                                        </span>
                                                    </div>
                                                }

                                                {
                                                    milestone?.foodExpenseReportUrl && milestone?.foodExpenseReportUrl !== "" &&
                                                    <span>
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                            <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone.foodExpenseReportUrl
                                                            )} title="Click to preview document">
                                                                Foods Expense Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                            </a>
                                                        </H4>
                                                    </span>
                                                }

                                                {
                                                    milestone?.documentsArray && milestone?.documentsArray !== "" && milestone?.documentsArray.map((ele, idx) => {
                                                        return (
                                                            <div className="mt-2 mb-2" key={idx}>
                                                                <span>
                                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, ele?.fileUrl
                                                                        )} title="Click to preview document">
                                                                            {removeUnderscoreFromText(ele?.documentName)} <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                                        </a>
                                                                    </H4>
                                                                </span>
                                                            </div>
                                                        )
                                                    })

                                                }


                                            </div>


                                        </div>


                                        {/* <div className="row">

                                <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                    <span>
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Sr. No.</H4>
                                    </span>
                                </div>

                                {
                                    milestone.type === "SEM_FEES" && <>
                                        <div className="col-6 p-0 ps-4">
                                            <span>
                                                <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">Previous Sem document</H4>
                                            </span>
                                        </div>
                                        <div className="col-6 p-0 ps-4">
                                            <span>
                                                <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">User uploaded Document</H4>
                                            </span>
                                        </div>
                                    </>
                                }




                            </div> */}
                                        {/*---uploaded file section---*/}

                                        {/* <div className="row">
                                <div className="col-6">


                                    {
                                        milestone?.prevDocuments && milestone?.prevDocuments !== "" && milestone?.prevDocuments.length > 0 && milestone?.prevDocuments.map((ele, idx) => {
                                            return (
                                                <div className="row mt-3" Key={`expenditureReport-${idx}`}>



                                                    <div className="col-12 p-0 ps-4">
                                                        <span>

                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, ele.fileUrl)} title="Click to preview document">
                                                                    {ele.documentName} <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                                </a>
                                                            </H4>
                                                        </span>
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                <div className="col-6">

                                    {
                                        milestone?.hostelFeesReportUrl && milestone?.hostelFeesReportUrl !== "" &&
                                        <div className="row mt-3" Key={`hostelFeesReportUrl`} >

                                            <div className="col-12 p-0 ps-4">
                                                <span>

                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone.hostelFeesReportUrl
                                                        )} title="Click to preview document">
                                                            Hostel Fees Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                        </a>
                                                    </H4>
                                                </span>
                                            </div>

                                        </div>

                                    }

                                    {
                                        milestone?.marksheetReportUrl && milestone?.marksheetReportUrl !== "" &&

                                        <div className="row mt-3" Key={`hostelFeesReportUrl`} >

                                            <div className="col-12 p-0 ps-4">
                                                <span>
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone.marksheetReportUrl)} title="Click to preview document">
                                                            Marksheet Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                        </a>
                                                    </H4>
                                                </span>
                                            </div>

                                        </div>


                                    }


                                    {
                                        milestone?.attendenceReportUrl && milestone?.attendenceReportUrl !== "" &&
                                        <div className="row mt-3" Key={`hostelFeesReportUrl`} >

                                            <div className="col-12 p-0 ps-4">
                                                <span>
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone.attendenceReportUrl)} title="Click to preview document">
                                                            Attendence Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                        </a>
                                                    </H4>
                                                </span>
                                            </div>

                                        </div>


                                    }


                                    {
                                        milestone?.foodExpenseReportUrl && milestone?.foodExpenseReportUrl !== "" &&
                                        <div className="row mt-3" Key={`hostelFeesReportUrl`} >

                                            <div className="col-12 p-0 ps-4">
                                                <span>
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone.foodExpenseReportUrl
                                                        )} title="Click to preview document">
                                                            Foods Expense Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                        </a>
                                                    </H4>
                                                </span>
                                            </div>

                                        </div>


                                    }

                                </div>
                            </div> */}

                                        {/* <div className="row mt-3">
                                {
                                    milestone.otherDocuments && milestone.otherDocuments && milestone.otherDocuments.length > 0 && milestone.otherDocuments.map((ele, idx) => {
                                        return (
                                            <>
                                                <div className="row ">
                                                
                                                    <div className="col-12 p-0 ps-4">
                                                        <span>
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, ele.fileUrl)} title="Click to preview document">
                                                                    {ele.documentName} <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                                </a>
                                                            </H4>
                                                        </span>
                                                    </div>


                                                </div>
                                            </>
                                        )
                                    })
                                }

                            </div> */}

                                        {/* user uploaded  forms start*/}


                                        {/* user uploaded forms end */}
                                        {/* 
                            <div className="row mt-3">
                                <div className="col-12 p-0 ps-4" >
                                    {
                                        ((milestone?.userFundsReceiptUrl && milestone?.userFundsReceiptUrl !== "") || (milestone?.treasuryConfirmationReceipt && milestone?.treasuryConfirmationReceipt !== "")) &&
                                        <span>
                                            <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00"> Office user Uploaded </H4>
                                        </span>
                                    }
                                </div>
                            </div> */}


                                        {/* <div className="row mt-3">

                                {
                                    milestone?.userFundsReceiptUrl && milestone?.userFundsReceiptUrl !== "" && milestone?.userFundsReceiptUrl.map((ele, idx) => {
                                        return (

                                            <div className="row" Key={`userFundReceiptUrls-${idx}`} >

                                                <div className="col-12 p-0 ps-4">
                                                    <span>

                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                            <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, ele?.fileUrl
                                                            )} title="Click to preview document">
                                                                {ele.documentName} <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                            </a>
                                                        </H4>
                                                    </span>
                                                </div>

                                            </div>
                                        )
                                    })

                                }


                                {
                                    milestone?.treasuryConfirmationReceipt && milestone?.treasuryConfirmationReceipt !== "" && <div className="row mt-3">

                                        <div className="col-12 p-0 ps-4">
                                            <span>

                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                    <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.treasuryConfirmationReceipt,)} title="Click to preview document">
                                                        Treasury confirmation Receipt <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                    </a>
                                                </H4>
                                            </span>
                                        </div>
                                        

                                    </div>
                                }     

                                {
                                    milestone?.semesterFeeAndAcknowledgementCertificateUrl && milestone?.semesterFeeAndAcknowledgementCertificateUrl !== "" && <div className="row mt-3">

                                        <div className="col-12 p-0 ps-4">
                                            <span>

                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                    <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.semesterFeeAndAcknowledgementCertificateUrl)} title="Click to preview document">
                                                        SemesterFee And acknowledgement Certificate
                                                    </a>
                                                </H4>
                                            </span>
                                        </div>
                                       
                                    </div>
                                }
                            </div> */}

                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        {disabled !== true ?
                                            <div className="comment_box_main">
                                                {milestone?.backOfficeStatus === "NEW" && milestone?.userStatus === "SUBMITTED" ?
                                                    <>
                                                        <div className="comment_box_heading">
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Comment Box</H4>
                                                        </div>
                                                        <div className="comment_box_body">
                                                            <div className="comment_to_write_main">
                                                                <div className="comment_to_write">
                                                                    <input className="form-control" placeholder="Write comment here..." onChange={getCommentForGeneralMilestone} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                            : <span></span>
                                        }
                                    </div>
                                    <div className="col-lg-6 col-md-12 mt-lg-5 mt-md-5">

                                        {disabled !== true ?
                                            <div className="upload_report_section">
                                                {
                                                    milestone?.backOfficeStatus === "REQUEST_TO_TREASURY" && milestone?.treasuryConfirmationReceipt === "" ?
                                                        <>
                                                            <div className="row">
                                                                <div className="col-12 mb-3">
                                                                    <span style={{ fontSize: "14px", color: "rgb(109, 141, 173)", float: "left" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                                </div>
                                                                <div className="col-9">
                                                                    <input type="file" className="form-control" id="inputGroupFile02" title="upload Receipt" onChange={selectFile} />
                                                                </div>
                                                                <div className="col-3">
                                                                    <button style={{ float: "right" }} onClick={ReceiptUploadForGeneralMilestone} disabled={disabled}> Upload </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <span></span>
                                                }

                                            </div>
                                            :
                                            <span></span>
                                        }
                                        <div className="upload_files_btn mt-3" style={{ textAlign: "right" }}>
                                            {disabled !== true ?
                                                <div className="home_verification_button mb-3">
                                                    {milestone?.backOfficeStatus === "NEW" && milestone?.userStatus === "SUBMITTED" ?
                                                        <>
                                                            <button className="me-3" onClick={() => generalMilestoneAsIncomplete()}>Mark as incomplete</button>
                                                            <button className="me-3" onClick={() => requestToTreasuryForGeneralMilestone()} disabled={disabled}>Request To Treasury</button>
                                                        </>
                                                        :
                                                        <span></span>
                                                    }
                                                    {milestone.backOfficeStatus === "REQUEST_TO_TREASURY" && milestone?.treasuryConfirmationReceipt !== "" ?
                                                        <button onClick={() => markFundReceiveForGeneralMilestone()} disabled={disabled}>Fund Received by Treasury</button>
                                                        :
                                                        <span></span>
                                                    }
                                                </div>
                                                : <span></span>
                                            }
                                            <button onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button>
                                        </div>

                                        {/* <div className="input-group mb-3">
                                <input type="file" className="form-control" id="inputGroupFile02" onChange={selectFile} />
                                <label className="input-group-text" for="inputGroupFile02">Recipt Upload </label>
                            </div>
                            <div className="input-group mb-3">
                                <button className="btn btn-secondary btn-block" onClick={ReciptUploadForGeneralMilestone}> Upload </button>
                            </div>

                            <div className="col-lg-6 mt-lg-5">
                                <div className="upload_home_verification_button">
                                    <button className="btn btn-secondary btn-outline me-3" onClick={() => requestToTreassuryForGeneralMilestone()}>Request To Treassury</button>
                                    <button className="btn btn-secondary btn-outline me-3" onClick={() => markFundReciveForGeneralMilestone()}>Mark Fund Received</button>
                                    <button onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button>
                                </div>
                            </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            }
        </section >
    )
}

export default GeneralMilestone