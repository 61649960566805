import React from "react";
import { useHistory } from "react-router";

const ApplicationFlow = () => {

    const history = useHistory();

    const showApplicationFlow = () => {
        history.push("/applicationFlow");
    }
    return (
        <>
            <section className="application_flow_main" style={{ height: "300px" }}>
                <div className="row">
                    <div className="col-12">
                        <img onClick={() => showApplicationFlow()} src="/assets/images/jpgs/applicationFlowPublicWhiteTheme.jpg" alt="Application_flow" />
                    </div>
                </div>
            </section>
        </>
    );

}
export default ApplicationFlow;