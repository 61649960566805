import { useDispatch, useSelector } from "react-redux";
import PersonalDetails from "../applicationForms/PersonalDetailsForm";
import ParentDetails from "../applicationForms/ParentDetailsForm";
import ApplicationFormsNav from "../common/ApplicationFormsNav";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import { Redirect, useHistory, useParams } from 'react-router';
import { useEffect, useRef, useState } from "react";
import FamilyIncomeDetails from "../applicationForms/FamilyIncomeDetails";
import FamilyDetails from "../applicationForms/FamilyDetails";
import CollegeAdmissionDetailForm from "../applicationForms/CollegeAdmissionDetailForm";
import ExamDetails from "../applicationForms/ExamDetails"
import EducationExpensesDetailForm from "../applicationForms/EducationExpensesDetailForm";
import DocumentSubmissionDetails from "../applicationForms/DocumentSubmissionDetails";
import { applicationCommunication } from "../communications/application-communication";
import { frontOfficeCommunication } from "../communications/front-office-communication";
import H6 from "../text-components/H6";
import H5 from "../text-components/H5";

const Application = () => {

    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const [applicationId, setApplicationId] = useState(null);

    let isUserAuthenticated = useSelector((state) => state.isUserAuthenticated);
    const userAccessType = useSelector((state) => state.isOfficeUserAuthenticated?.officeUser?.access);

    const dispatch = useDispatch();

    const [userType, setUserType] = useState("");

    const [actionType, setActionType] = useState("");

    const history = useHistory();

    const [comment, setComment] = useState("");

    const [rating, setRating] = useState(0);

    const [marks, setMarks] = useState("");

    const [remittanceId, setRemittanceId] = useState("");

    const [milestoneId, setMilestoneId] = useState("");
    const [tab, setTab] = useState("");

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    async function initialLoad() {

        if (isUserAuthenticated === false) {
            let userJwtVerification = await applicationCommunication.getUserAuthenticateByJWT();
            if (userJwtVerification.status === "SUCCESS") {
                dispatch({ type: "AUTHENTICATE_USER", payload: userJwtVerification.jwt });
            } else {
                dispatch({ type: "DEAUTHENTICATE_USER" });
                history.push('/login');
            }
        }
        const queryParams = new URLSearchParams(location.search);

        const actionTypeFromParams = queryParams.get('action');
        setActionType(actionTypeFromParams);

        const applicationIdFromParams = queryParams.get('applicationId');
        setApplicationId(value => value = applicationIdFromParams);

        const remittanceIdFromParams = queryParams.get('remittanceId');
        setRemittanceId(value => value = remittanceIdFromParams);

        const milestoneFromParams = queryParams.get('milestoneId');
        setMilestoneId(value => value = milestoneFromParams);

        const homeverificationTab = queryParams.get('tab');
        setTab(value => value = homeverificationTab);

        //Hide/Show applicationProcessNav
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("application")) {
            dispatch({ type: "HIDE_APPLICATION_PROCESS_NAV" });
        } else {
            dispatch({ type: "SHOW_APPLICATION_PROCESS_NAV" });
        }

        setUserType(splitUrlArray[4]);

        document.title = "Scholarship | Application";
        return true;
    }

    // picking the applicationId from url params
    const location = useLocation();
    useEffect(() => {
        initialLoad();

        return async () => {
            const queryParams = new URLSearchParams(location.search);
            const applicationIdFromParams = queryParams.get('applicationId');
            const closeAccessToApplicationFromServer = await frontOfficeCommunication.closeAccessToApplication(applicationIdFromParams);
        }
    }, []);



    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    async function acknowledgeNewApplication(userAction) {
        showLoading("Loading...")
        const acknowledgeResponse = await frontOfficeCommunication.acknowledgeNewApplication(userAction, comment, applicationId);
        if (acknowledgeResponse.status === "SUCCESS") {
            dispatch({ type: "ACTIVE_TAB", payload: "NEW_APPLICATION" });
            history.push('/front-office/dashboard/new-application');
        }
        else if (acknowledgeResponse.status === "UNAUTHORIZED_ACCESS") {
            history.push(`/front-office/login`);
            return;
        }
        showModal(acknowledgeResponse.message);
        hideLoading();
        return true;
    }

    async function verifyApplication(userAction) {
        showLoading("Loading...")
        const verifyResponse = await frontOfficeCommunication.verifyApplication(userAction, comment, applicationId);
        if (verifyResponse.status === "SUCCESS") {
            dispatch({ type: "ACTIVE_TAB", payload: "APPLICATION_VERIFICATION" });
            history.push('/front-office/dashboard/application-verification');
        }
        else if (verifyResponse.status === "UNAUTHORIZED_ACCESS") {
            history.push(`/front-office/login`);
            return;
        }
        showModal(verifyResponse.message);
        hideLoading();
        return true;
    }

    async function rejectApplication(userAction) {
        let rejectResponse;
        if (comment === "") {
            showModal("Please enter a comment");
            return;
        }
        else {
            showLoading("Loading...");
            rejectResponse = await frontOfficeCommunication.rejectApplication(userAction, comment, applicationId);
            if (rejectResponse.status === "SUCCESS") {
                dispatch({ type: "ACTIVE_TAB", payload: "APPLICATION_VERIFICATION" });
                history.push('/front-office/dashboard/application-verification');
            }
            else if (rejectResponse.status === "UNAUTHORIZED_ACCESS") {
                history.push(`/front-office/login`);
                return;
            }
            hideLoading();
            showModal(rejectResponse.message);
            return true;
        }
    }

    async function incompleteApplication(userAction) {
        let incompleteResponse;
        if (comment === "") {
            showModal("Please enter a comment");
            return;
        }
        else {
            showLoading("Loading...");
            incompleteResponse = await frontOfficeCommunication.incompleteApplication(userAction, comment, applicationId);
            if (incompleteResponse.status === "SUCCESS") {
                dispatch({ type: "ACTIVE_TAB", payload: "NEW_APPLICATION" });
                history.push('/front-office/dashboard/new-application');
            }
            else if (incompleteResponse.status === "UNAUTHORIZED_ACCESS") {
                history.push(`/front-office/login`);
                return;
            }
            showModal(incompleteResponse.message);
            hideLoading();
            return true;
        }
    }
    async function submitApplication(userAction) {
        let submitResponse;
        if (marks === "" || Number(marks) === 0) {
            showModal("Please enter marks");
            return;
        }
        else {
            showLoading("Loading...");
            submitResponse = await frontOfficeCommunication.submitApplication(userAction, comment, applicationId, marks);
            console.log(submitResponse);
            if (submitResponse.status === "SUCCESS") {
                showModal(submitResponse.message);
                dispatch({ type: "ACTIVE_TAB", payload: "SHORT_LIST_APPLICATION" });
                history.push('/front-office/dashboard/short-list-application');
                return;
            }
            else if (submitResponse.status === "UNAUTHORIZED_ACCESS") {
                history.push(`/front-office/login`);
                return;
            }
            hideLoading();
            showModal(submitResponse.message);
        }
        return true;
    }

    const goToNewApplicationTab = async (path) => {
        showLoading("Loading...");
        const closeAccessToApplicationFromServer = await frontOfficeCommunication.closeAccessToApplication(applicationId);
        if (closeAccessToApplicationFromServer.status === "SUCCESS") {
            history.push(`/front-office/dashboard/${path}`);
        }
        hideLoading();
        showModal(closeAccessToApplicationFromServer.message);
        return true;
    }

    const goToRemittanceTab = async () => {
        history.push(`/back-office/dashboard/remittance-preview?remittanceId=${remittanceId}`);
        return true;
    }

    const gotoMilestoneTab = async () => {
        if (tab === "homeverification") {
            history.push(`/back-office/dashboard/milestoneTwo?applicationId=${applicationId}&type=MILESTONE_TWO&milestoneId=${milestoneId}&tab=${tab}`);
        } else if ("MILESTONE_ONE" === actionType) {
            history.push(`/back-office/dashboard/milestoneOne?applicationId=${applicationId}&type=MILESTONE_ONE&milestoneId=${milestoneId}`);

            // history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`)
            // history.push(`/back-office/dashboard/document-scrutiny/milestone-list/uploaded-documents?milestoneId=${milestoneId}&applicationId=${applicationId}&type=${actionType}`);
            return true;
        } else if (actionType === "MILESTONE_TWO") {
            history.push(`/back-office/dashboard/milestoneTwo?applicationId=${applicationId}&type=MILESTONE_TWO&milestoneId=${milestoneId}`);

        } else {
            history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`)

        }
    }

    // // get rating from  radio button
    // async function getRating(e) {
    //     const rate = Number(e.target.value);
    //     setRating(rate);
    //     return;
    // }



    return (
        <div >
            <ApplicationFormsNav />
            <section className="application_process_main" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
                <div className="row m-0">
                    <div className="col-12 p-0">
                        {
                            applicationId === null ?
                                (<div></div>)
                                :
                                (
                                    <div>
                                        <Route exact path={`/${userType}${userType === "user" ? "" : "/dashboard"}/application`} render={() => {
                                            let url = `/${userType}${userType === "user" ? "" : "/dashboard"}/application/personal-details?applicationId=` + applicationId + `${!!actionType ? `&action=${actionType}` : ''}`;
                                            url = url.replaceAll("^\"|\"$", "");
                                            return (<Redirect to={url} />);
                                        }} />

                                        <Route exact path={`/${userType}${userType === "back-office" ? "/dashboard" : ""}/application`} render={() => {
                                            let url = `/${userType}${userType === "user" ? "" : "/dashboard"}/application/personal-details?applicationId=` + applicationId + `${!!actionType ? `&action=${actionType}` : ''}${!!remittanceId ? `&remittanceId=${remittanceId}` : ''}`;
                                            url = url.replaceAll("^\"|\"$", "");
                                            return (<Redirect to={url} />);
                                        }} />

                                        <Route exact path={`/${userType}${userType === "back-office" ? "/dashboard" : ""}/application`} render={() => {
                                            let url = `/${userType}${userType === "user" ? "" : "/dashboard"}/application/personal-details?applicationId=` + applicationId + `${!!actionType ? `&action=${actionType}` : ''}${!!milestoneId ? `&milestoneId=${milestoneId}` : ''}${!!tab ? `&tab=${tab}` : ''}`;
                                            url = url.replaceAll("^\"|\"$", "");
                                            return (<Redirect to={url} />);
                                        }} />

                                        <Route exact path={`/${userType}${userType === "user" ? "" : "/dashboard"}/application/personal-details`} component={PersonalDetails} />

                                        <Route exact path={`/${userType}${userType === "user" ? "" : "/dashboard"}/application/parent-details`} component={ParentDetails} />

                                        <Route exact path={`/${userType}${userType === "user" ? "" : "/dashboard"}/application/exam-details`} component={ExamDetails} />

                                        {/* <Route exact path={`/${userType}${userType === "user" ? "" : "/dashboard"}/application/family-income-details`} component={FamilyIncomeDetails} />

                                        <Route exact path={`/${userType}${userType === "user" ? "" : "/dashboard"}/application/family-details`} component={FamilyDetails} /> */}

                                        <Route exact path={`/${userType}${userType === "user" ? "" : "/dashboard"}/application/college-admission-details`} component={CollegeAdmissionDetailForm} />

                                        <Route exact path={`/${userType}${userType === "user" ? "" : "/dashboard"}/application/education-expenses-detail`} component={EducationExpensesDetailForm} />

                                        <Route exact path={`/${userType}${userType === "user" ? "" : "/dashboard"}/application/document-submission-details`} component={DocumentSubmissionDetails} />

                                    </div>
                                )
                        }
                    </div>
                </div>


                <div className="container mt-2 mb-2">
                    {actionType === "NEW_APPLICATION" ?

                        <div className="row">

                            <div className="col-4">
                                <div className="dashboard_delete_button">
                                    <textarea className="form-control" placeholder="Type comment here...." rows={2} onChange={(event) => { setComment(event.target.value) }} value={comment}></textarea>
                                </div>
                            </div>

                            <div className="col-8">
                                <div className="Front_Office_Btn">
                                    <div className="dashboard_delete_button">
                                        <button type="button" className="btn"
                                            onClick={() => { acknowledgeNewApplication("ACKNOWLEDGED"); }} disabled={userAccessType === "READ" ? true : false} >
                                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6> Acknowledge</H6>}
                                        </button>
                                    </div>

                                    <div className="dashboard_delete_button">
                                        <button type="button" className="btn"
                                            onClick={() => { incompleteApplication("INCOMPLETE"); }} disabled={userAccessType === "READ" ? true : false}>
                                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6>  Incomplete</H6>}
                                        </button>
                                    </div>

                                    <div className="dashboard_delete_button">
                                        <button type="button" className="btn"
                                            onClick={() => { goToNewApplicationTab("new-application"); }}>
                                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6>  Cancel</H6>}
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        :
                        <span></span>
                    }
                </div>


                <div className="container mt-2 mb-2">
                    {actionType === "APPLICATION_VERIFICATION" ?

                        <div className="row">

                            <div className="col-4">
                                <div className="dashboard_delete_button">
                                    <textarea className="form-control" placeholder="Type comment here...." rows={2} onChange={(event) => { setComment(event.target.value) }} value={comment}></textarea>
                                </div>
                            </div>

                            <div className="col-8">
                                <div className="Front_Office_Btn">
                                    <div className="dashboard_delete_button">
                                        <button type="button" className="btn"
                                            onClick={() => { rejectApplication("REJECTED"); }} disabled={userAccessType === "READ" ? true : false} >
                                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6>  Reject</H6>}
                                        </button>
                                    </div>

                                    <div className="dashboard_delete_button">
                                        <button type="button" className="btn"
                                            onClick={() => { verifyApplication("VERIFIED"); }} disabled={userAccessType === "READ" ? true : false}>
                                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6> Verify</H6>}
                                        </button>
                                    </div>

                                    <div className="dashboard_delete_button">
                                        <button type="button" className="btn"
                                            onClick={() => { goToNewApplicationTab("application-verification"); }}>
                                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6> Cancel</H6>}
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        :
                        <span></span>
                    }
                </div>

                <div className="container mt-2 mb-2">
                    {actionType === "SHORT_LIST_APPLICATION" ?

                        <div className="row">

                            {/* <div className="col-4">
                                <div className="dashboard_delete_button">
                                    <textarea className="form-control" placeholder="Type comment here...." rows={2} onChange={(event) => { setComment(event.target.value) }} value={comment}></textarea>
                                </div>
                            </div> */}

                            <div className="col-12">
                                <div className="Front_Office_Btn">
                                    <div className="dashboard_delete_button">
                                        <button type="button" className="btn"
                                            onClick={() => { goToNewApplicationTab("short-list-application"); }}>
                                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6> Cancel</H6>}
                                        </button>
                                    </div>

                                    <div className="dashboard_delete_button">
                                        <button type="button" className="btn"
                                            onClick={() => { submitApplication("RATED"); }} disabled={userAccessType === "READ" ? true : false} >
                                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6> submit</H6>}
                                        </button>
                                    </div>

                                    <div className="marks_label">
                                        <H5>
                                            Marks
                                        </H5>
                                    </div>
                                    {/* <div className="rating_radio">
                                        <h3>
                                            1
                                        </h3>
                                        <input type="radio" value={1} checked={rating == 1} onChange={(e) => getRating(e)} disabled={userAccessType === "READ" ? true : false} />
                                    </div>
                                    <div className="rating_radio">
                                        <h3>
                                            2
                                        </h3>
                                        <input type="radio" value={2} checked={rating == 2} onChange={(e) => getRating(e)} disabled={userAccessType === "READ" ? true : false} />
                                    </div>
                                    <div className="rating_radio">
                                        <h3>
                                            3
                                        </h3>
                                        <input type="radio" value={3} checked={rating == 3} onChange={(e) => getRating(e)} disabled={userAccessType === "READ" ? true : false} />
                                    </div>
                                    <div className="rating_radio">
                                        <h3>
                                            4
                                        </h3>
                                        <input type="radio" value={4} checked={rating == 4} onChange={(e) => getRating(e)} disabled={userAccessType === "READ" ? true : false} />
                                    </div>
                                    <div className="rating_radio">
                                        <h3>
                                            5
                                        </h3>
                                        <input type="radio" value={5} checked={rating == 5} onChange={(e) => getRating(e)} disabled={userAccessType === "READ" ? true : false} />
                                    </div> */}
                                    <div className="code_extension">
                                        <input type="number" name="marks" value={marks} onChange={(e) => setMarks(Number(e.target.value))} className={`form-control`} />
                                    </div>

                                </div>
                            </div>

                        </div>
                        :
                        <span></span>
                    }
                </div>

                <div className="container mt-2 mb-2">
                    {actionType === "MERIT_LIST" ?
                        <div className="row">
                            <div className="col-4">
                            </div>
                            <div className="col-8">
                                <div className="Front_Office_Btn">
                                    <div className="dashboard_delete_button">
                                        <button type="button" className="btn"
                                            onClick={() => { goToNewApplicationTab("merit-list"); }}>
                                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6> Cancel</H6>}
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        :
                        <span></span>
                    }
                </div>

                <div className="container mt-2 mb-2">
                    {actionType === "APPROVED_LIST" ?
                        <div className="row">
                            <div className="col-4">
                            </div>
                            <div className="col-8">
                                <div className="Front_Office_Btn">
                                    <div className="dashboard_delete_button">
                                        <button type="button" className="btn"
                                            onClick={() => { goToNewApplicationTab("approved-list"); }}>
                                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6> Cancel</H6>}
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        :
                        <span></span>
                    }
                </div>

                <div className="container mt-2 mb-2">
                    {actionType === "WAIT_LIST" ?
                        <div className="row">
                            <div className="col-4">
                            </div>
                            <div className="col-8">
                                <div className="Front_Office_Btn">
                                    <div className="dashboard_delete_button">
                                        <button type="button" className="btn"
                                            onClick={() => { goToNewApplicationTab("wait-list"); }}>
                                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6> Cancel</H6>}
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        :
                        <span></span>
                    }
                </div>

                <div className="container mt-2 mb-2">
                    {actionType === "REMITTANCE" ?
                        <div className="row">
                            <div className="col-4">
                            </div>
                            <div className="col-8">
                                <div className="Front_Office_Btn">
                                    <div className="dashboard_delete_button">
                                        <button type="button" className="btn"
                                            onClick={() => { goToRemittanceTab(); }}>
                                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6> Cancel</H6>}
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        :
                        <span></span>
                    }
                </div>

                <div className="container mt-2 mb-2">
                    {["MILESTONE_ONE", "MILESTONE_TWO"].includes(actionType) ?
                        <div className="row">
                            <div className="col-4">
                            </div>
                            <div className="col-8">
                                <div className="Front_Office_Btn">
                                    <div className="dashboard_delete_button">
                                        <button type="button" className="btn"
                                            onClick={() => { gotoMilestoneTab(); }}>
                                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6> Cancel</H6>}
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        :
                        <span></span>
                    }
                </div>

            </section>
        </div>
    );

}


export default Application;