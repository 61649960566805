import { useSelector } from "react-redux";
import H3 from "../text-components/H3";
import H4 from "../text-components/H4";

const FooterOwnership = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    return (
        <div className="row p-1 m-0" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000", borderTop: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }}>
            <div className="col-12 d-flex flex-column justify-content-center text-center footer_ownership">
                <H4 whiteThemeColor="#1E2343" darkThemeColor="#FFFFFF">Website Content Managed & <b>Owned by Ministry of Social Justice & Welfare Dept. Government of Maharashtra</b></H4>
                <H4 whiteThemeColor="#1E2343" darkThemeColor="#FFFFFF">Designed and Developed by <b>All Indian IT Services LLP.</b></H4>
                {/* <H4 whiteThemeColor="#1E2343" darkThemeColor="#FFFFFF">Last Reviewed and updated on 26 Oct, 2021</H4> */}
            </div>
        </div>
    );
}

export default FooterOwnership;