import React from "react";
import { useHistory } from "react-router";

const DepartmentHierarchyMobile = () => {

    const history = useHistory();

    function routeToFirstHierarchy(pathOfRoute) {
      history.push(`${pathOfRoute}`);
    }
  
  
  
    return (
      <section className="Hierarchy_Department_mobile_main">
        <div className="row">
          <div className="col-12 hierarchy_img">
            <ul>
              <li onClick={()=>{routeToFirstHierarchy("/social-justice-and-special-assistance")}} >Social Justice And Special Assistance</li>
              <li onClick={()=>{routeToFirstHierarchy("/commissioner")}}>Commissioner</li>
              <li onClick={()=>{routeToFirstHierarchy("/regional-chart")}}>Regional Chart</li>
              <li onClick={()=>{routeToFirstHierarchy("/divisional-caste-validity-committee")}}>Divisional Caste Validity Committee</li>
              <li onClick={()=>{routeToFirstHierarchy("/administration-profile-of-corporation")}}>Administration Profile of Corporation</li>
            </ul>
          </div>
        </div>
      </section>
    );
  
    // return (
    //     <>
    //         <section className="Hierarchy_Department_mobile_main">
    //             <div className="row">
    //                 <div className="col-12">
    //                     <ul>
    //                         <li>Social Justice And Special Assistance</li>
    //                         <li>Commissioner</li>
    //                         <li>Regional Chart</li>
    //                         <li>Divisional Caste Validity Committee</li>
    //                         <li>Administration Profile of Corporation</li>
    //                     </ul>
    //                     <img onClick={() => {showHierarchyImage()}} src="/assets/images/pngs/HierarchyDesign.png" alt="HierarchyDesign.png"/>
    //                 </div>
    //             </div>
    //         </section>

    //         <section className="Hierarchy_Department_mobile_main">
    //             <div className="row">
    //                 <div className="col-12">
    //                     <img src="/assets/images/pngs/HierarchyDesign.png" alt="HierarchyDesign.png" />
    //                 </div>
    //             </div>
    //         </section>
    //     </>
    // );

}
export default DepartmentHierarchyMobile;