import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import H1 from "../../text-components/H1";
import H5 from "../../text-components/H5";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import H3 from "../../text-components/H3";

const UpdateGraphData = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [labels, setLables] = useState(["Yearly Applications", "Yearly Course Completion", "Amount Disbursed", "District Wise Applications", "Country Wise Applications", "Course Wise Applications"])

    // Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    const updateHandler = async (StatisticType) => {
        showLoading();

        if (StatisticType === "Yearly Applications") {
            const serverResponse = await adminCommunication.updateYearlyApplications();
            hideLoading();
            showModal(serverResponse.message);
            return;
        }

        if (StatisticType === "Yearly Course Completion") {
            const serverResponse = await adminCommunication.updateYearlyCourseCompletionApplications();
            showModal(serverResponse.message);
            hideLoading();
            return;
        }

        if (StatisticType === "Amount Disbursed") {
            const serverResponse = await adminCommunication.updateAmountDisbursedStatistic();
            hideLoading();
            showModal(serverResponse.message);
            return;
        }

        if (StatisticType === "District Wise Applications") {
            const serverResponse = await adminCommunication.updateDistrictWiseApplications();
            hideLoading();
            showModal(serverResponse.message);
            return;
        }

        if (StatisticType === "Country Wise Applications") {
            const serverResponse = await adminCommunication.updateCountryWiseApplications();
            hideLoading();
            showModal(serverResponse.message);
            return;
        }

        if (StatisticType === "Course Wise Applications") {
            const serverResponse = await adminCommunication.updateCourseWiseApplications();
            hideLoading();
            showModal(serverResponse.message);
            return;
        }
        hideLoading();
    }

    return (
        <section>
            <div className="container">
                <section className="dashboard_background">
                    {isLoading.value ?
                        <LoadingForms message={isLoading.message} />
                        :
                        <div className="dashbord_main">
                            <div className="update_statistics_title">
                                <H3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Update Statistics Data</H3>
                            </div>
                            <div className="dashboard_view_data_heading" >
                                <div className="row m-0">
                                    <div className="col-2 p-0 view_dashboard">
                                        <span><H5 >Serial No.</H5></span>
                                    </div>
                                    <div className="col-5 p-0 view_dashboard">
                                        <span><H5 >File Name</H5></span>
                                    </div>
                                    <div className="col-5 p-0 ">
                                        <span><H5 >Update Statistic</H5></span>
                                    </div>
                                </div>
                            </div>

                            {labels.map((StatisticName, index) => {
                                return (
                                    <div className="dashboard_view_data_main" key={index}>
                                        <div className="dashboard_view_data">
                                            <div className="row m-0">
                                                <div className="col-2 p-0 view_dashboard text_align">
                                                    <span>
                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                            {index + 1}
                                                        </H5>
                                                    </span>
                                                </div>
                                                <div className=" col-5 p-0 view_dashboard text_align">
                                                    <span>
                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                            {StatisticName}
                                                        </H5>
                                                    </span>
                                                </div>

                                                <div className="col-5 p-0 graph_data_button">

                                                    <button onClick={() => { updateHandler(StatisticName) }} >Update</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}




                        </div>
                    }
                </section>


            </div>
        </section>

        // <div>
        //     <div className="row">
        //         {isLoading.value ?
        //             <LoadingForms message={isLoading.message} />
        //             :
        //             <div className="col download_excel">
        //                 <H1 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Update statistics data</H1>
        //                 <section className="dashboard_background" >
        //                     <div className="dashbord_main">
        //                         <div className="dashboard_view_data_heading" >
        //                             <div className="row m-0">
        //                                 <div className="col-lg-2 col-md-2 col-1 p-0 view_dashboard">
        //                                     <span><H5 >Serial No.</H5></span>
        //                                 </div>
        //                                 <div className="col-lg-5 col-md-7 col-4 p-0 view_dashboard">
        //                                     <span><H5 >Statistic Name</H5></span>
        //                                 </div>
        //                                 <div className="col-lg-5 col-md-3 col-3 p-0 ">
        //                                     <span><H5 >Update Statistics</H5></span>
        //                                 </div>
        //                             </div>
        //                             {labels.map((graphName, index) => {
        //                                 return (
        //                                     <div className="dashboard_view_data_main" key={index}>
        //                                         <div className="dashboard_view_data">
        //                                             <div className="row m-0">
        //                                                 <div className="col-lg-2 col-md-2 col-1 p-0 view_dashboard text_align">
        //                                                     <span>
        //                                                         <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
        //                                                             {index + 1}
        //                                                         </H5>
        //                                                     </span>
        //                                                 </div>
        //                                                 <div className="col-lg-5 col-md-7 col-4 p-0 view_dashboard text_align">
        //                                                     <span>
        //                                                         <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
        //                                                             {graphName}
        //                                                         </H5>
        //                                                     </span>
        //                                                 </div>
        //                                                 <div className="col-lg-5 col-md-3 col-3 p-0 text_align">
        //                                                     <button onClick={() => updateHandler(graphName)} >
        //                                                         Update
        //                                                     </button>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 )
        //                             })}
        //                         </div>
        //                     </div>
        //                 </section>
        //             </div>
        //         }
        //     </div>
        // </div>
    )
}

export default UpdateGraphData;