const countryNameList = [
    { countryName: "Select Country" },
    { countryName: "Afghanistan" },
    { countryName: "Albania" },
    { countryName: "Algeria" },
    { countryName: "American Samoa" },
    { countryName: "Andorra" },
    { countryName: "Angola" },
    { countryName: "Anguilla" },
    { countryName: "Antarctica" },
    { countryName: "Antigua and Barbuda" },
    { countryName: "Argentina" },
    { countryName: "Armenia" },
    { countryName: "Aruba" },
    { countryName: "Australia" },
    { countryName: "Austria" },
    { countryName: "Azerbaijan" },
    { countryName: "Bahamas (the)" },
    { countryName: "Bahrain" },
    { countryName: "Bangladesh" },
    { countryName: "Barbados" },
    { countryName: "Belarus" },
    { countryName: "Belgium" },
    { countryName: "Belize" },
    { countryName: "Benin" },
    { countryName: "Bermuda" },
    { countryName: "Bhutan" },
    { countryName: "Bolivia (Plurinational State of)" },
    { countryName: "Bonaire, Sint Eustatius and Saba" },
    { countryName: "Bosnia and Herzegovina" },
    { countryName: "Botswana" },
    { countryName: "Bouvet Island" },
    { countryName: "Brazil" },
    { countryName: "British Indian Ocean Territory (the)" },
    { countryName: "Brunei Darussalam" },
    { countryName: "Bulgaria" },
    { countryName: "Burkina Faso" },
    { countryName: "Burundi" },
    { countryName: "Cabo Verde" },
    { countryName: "Cambodia" },
    { countryName: "Cameroon" },
    { countryName: "Canada" },
    { countryName: "Cayman Islands (the)" },
    { countryName: "Central African Republic (the)" },
    { countryName: "Chad" },
    { countryName: "Chile" },
    { countryName: "China" },
    { countryName: "Christmas Island" },
    { countryName: "Cocos (Keeling) Islands (the)" },
    { countryName: "Colombia" },
    { countryName: "Comoros (the)" },
    { countryName: "Congo (the Democratic Republic of the)" },
    { countryName: "Congo (the)" },
    { countryName: "Cook Islands (the)" },
    { countryName: "Costa Rica" },
    { countryName: "Croatia" },
    { countryName: "Cuba" },
    { countryName: "Curaçao" },
    { countryName: "Cyprus" },
    { countryName: "Czechia" },
    { countryName: "Côte d'Ivoire" },
    { countryName: "Denmark" },
    { countryName: "Djibouti" },
    { countryName: "Dominica" },
    { countryName: "Dominican Republic (the)" },
    { countryName: "Ecuador" },
    { countryName: "Egypt" },
    { countryName: "El Salvador" },
    { countryName: "Equatorial Guinea" },
    { countryName: "Eritrea" },
    { countryName: "Estonia" },
    { countryName: "Eswatini" },
    { countryName: "Ethiopia" },
    { countryName: "Falkland Islands (the) [Malvinas]" },
    { countryName: "Faroe Islands (the)" },
    { countryName: "Fiji" },
    { countryName: "Finland" },
    { countryName: "France" },
    { countryName: "French Guiana" },
    { countryName: "French Polynesia" },
    { countryName: "French Southern Territories (the)" },
    { countryName: "Gabon" },
    { countryName: "Gambia (the)" },
    { countryName: "Georgia" },
    { countryName: "Germany" },
    { countryName: "Ghana" },
    { countryName: "Gibraltar" },
    { countryName: "Greece" },
    { countryName: "Greenland" },
    { countryName: "Grenada" },
    { countryName: "Guadeloupe" },
    { countryName: "Guam" },
    { countryName: "Guatemala" },
    { countryName: "Guernsey" },
    { countryName: "Guinea" },
    { countryName: "Guinea-Bissau" },
    { countryName: "Guyana" },
    { countryName: "Haiti" },
    { countryName: "Heard Island and McDonald Islands" },
    { countryName: "Holy See (the)" },
    { countryName: "Honduras" },
    { countryName: "Hong Kong" },
    { countryName: "Hungary" },
    { countryName: "Iceland" },
    { countryName: "India" },
    { countryName: "Indonesia" },
    { countryName: "Iran (Islamic Republic of)" },
    { countryName: "Iraq" },
    { countryName: "Ireland" },
    { countryName: "Isle of Man" },
    { countryName: "Israel" },
    { countryName: "Italy" },
    { countryName: "Jamaica" },
    { countryName: "Japan" },
    { countryName: "Jersey" },
    { countryName: "Jordan" },
    { countryName: "Kazakhstan" },
    { countryName: "Kenya" },
    { countryName: "Kiribati" },
    { countryName: "Korea (the Democratic People's Republic of)" },
    { countryName: "Korea (the Republic of)" },
    { countryName: "Kuwait" },
    { countryName: "Kyrgyzstan" },
    { countryName: "Lao People's Democratic Republic (the)" },
    { countryName: "Latvia" },
    { countryName: "Lebanon" },
    { countryName: "Lesotho" },
    { countryName: "Liberia" },
    { countryName: "Libya" },
    { countryName: "Liechtenstein" },
    { countryName: "Lithuania" },
    { countryName: "Luxembourg" },
    { countryName: "Macao" },
    { countryName: "Madagascar" },
    { countryName: "Malawi" },
    { countryName: "Malaysia" },
    { countryName: "Maldives" },
    { countryName: "Mali" },
    { countryName: "Malta" },
    { countryName: "Marshall Islands (the)" },
    { countryName: "Martinique" },
    { countryName: "Mauritania" },
    { countryName: "Mauritius" },
    { countryName: "Mayotte" },
    { countryName: "Mexico" },
    { countryName: "Micronesia (Federated States of)" },
    { countryName: "Moldova (the Republic of)" },
    { countryName: "Monaco" },
    { countryName: "Mongolia" },
    { countryName: "Montenegro" },
    { countryName: "Montserrat" },
    { countryName: "Morocco" },
    { countryName: "Mozambique" },
    { countryName: "Myanmar" },
    { countryName: "Namibia" },
    { countryName: "Nauru" },
    { countryName: "Nepal" },
    { countryName: "Netherlands (the)" },
    { countryName: "New Caledonia" },
    { countryName: "New Zealand" },
    { countryName: "Nicaragua" },
    { countryName: "Niger (the)" },
    { countryName: "Nigeria" },
    { countryName: "Niue" },
    { countryName: "Norfolk Island" },
    { countryName: "Northern Mariana Islands (the)" },
    { countryName: "Norway" },
    { countryName: "Oman" },
    { countryName: "Pakistan" },
    { countryName: "Palau" },
    { countryName: "Palestine, State of" },
    { countryName: "Panama" },
    { countryName: "Papua New Guinea" },
    { countryName: "Paraguay" },
    { countryName: "Peru" },
    { countryName: "Philippines (the)" },
    { countryName: "Pitcairn" },
    { countryName: "Poland" },
    { countryName: "Portugal" },
    { countryName: "Puerto Rico" },
    { countryName: "Qatar" },
    { countryName: "Republic of North Macedonia" },
    { countryName: "Romania" },
    { countryName: "Russian Federation (the)" },
    { countryName: "Rwanda" },
    { countryName: "Réunion" },
    { countryName: "Saint Barthélemy" },
    { countryName: "Saint Helena, Ascension and Tristan da Cunha" },
    { countryName: "Saint Kitts and Nevis" },
    { countryName: "Saint Lucia" },
    { countryName: "Saint Martin (French part)" },
    { countryName: "Saint Pierre and Miquelon" },
    { countryName: "Saint Vincent and the Grenadines" },
    { countryName: "Samoa" },
    { countryName: "San Marino" },
    { countryName: "Sao Tome and Principe" },
    { countryName: "Saudi Arabia" },
    { countryName: "Senegal" },
    { countryName: "Serbia" },
    { countryName: "Seychelles" },
    { countryName: "Sierra Leone" },
    { countryName: "Singapore" },
    { countryName: "Sint Maarten (Dutch part)" },
    { countryName: "Slovakia" },
    { countryName: "Slovenia" },
    { countryName: "Solomon Islands" },
    { countryName: "Somalia" },
    { countryName: "South Africa" },
    { countryName: "South Georgia and the South Sandwich Islands" },
    { countryName: "South Sudan" },
    { countryName: "Spain" },
    { countryName: "Sri Lanka" },
    { countryName: "Sudan (the)" },
    { countryName: "Suriname" },
    { countryName: "Svalbard and Jan Mayen" },
    { countryName: "Sweden" },
    { countryName: "Switzerland" },
    { countryName: "Syrian Arab Republic" },
    { countryName: "Taiwan" },
    { countryName: "Tajikistan" },
    { countryName: "Tanzania}, United Republic of" },
    { countryName: "Thailand" },
    { countryName: "Timor-Leste" },
    { countryName: "Togo" },
    { countryName: "Tokelau" },
    { countryName: "Tonga" },
    { countryName: "Trinidad and Tobago" },
    { countryName: "Tunisia" },
    { countryName: "Turkey" },
    { countryName: "Turkmenistan" },
    { countryName: "Turks and Caicos Islands (the)" },
    { countryName: "Tuvalu" },
    { countryName: "Uganda" },
    { countryName: "Ukraine" },
    { countryName: "United Arab Emirates (the)" },
    { countryName: "United Kingdom of Great Britain and Northern Ireland (the)" },
    { countryName: "United States Minor Outlying Islands (the)" },
    { countryName: "United States of America (the)" },
    { countryName: "Uruguay" },
    { countryName: "Uzbekistan" },
    { countryName: "Vanuatu" },
    { countryName: "Venezuela (Bolivarian Republic of)" },
    { countryName: "Viet Nam" },
    { countryName: "Virgin Islands (British)" },
    { countryName: "Virgin Islands (U.S.)" },
    { countryName: "Wallis and Futuna" },
    { countryName: "Western Sahara" },
    { countryName: "Yemen" },
    { countryName: "Zambia" },
    { countryName: "Zimbabwe" },
    { countryName: "Åland Islands" },
]
export default countryNameList;
