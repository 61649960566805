import axios from "axios";
const serverDevUrl = "http://localhost:3700";
const awsServerDevUrl = "http://ec2-13-233-236-197.ap-south-1.compute.amazonaws.com:3700";
const serverProdUrl = "";
const ngrokUrl = "http://c6f7-2405-201-1004-e0c7-6499-90eb-4d6c-ddda.ngrok.io";
const newNgrokUrl = "http://bda0-47-247-214-149.ngrok.io";
const contoboUrl = "http://194.233.89.236:3700";
const machine_Ip = "http://192.168.29.169:3700";

function getServerUrl() {
    //  get node environment variable 
    const nodeEnvirnment = process.env.REACT_APP_NODE_ENV;

    if (nodeEnvirnment === "development") {
        return serverDevUrl;
    }

    if (nodeEnvirnment === "production") {
        return process.env.REACT_APP_BACKEND_SERVER_IP;
    }

    if (nodeEnvirnment === "contobo_dev") {
        return contoboUrl;
    }

    if (nodeEnvirnment === "machine_Ip") {
        return machine_Ip;
    }


    return process.env.REACT_APP_BACKEND_SERVER_IP;
}

function getCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if ("sjsadAdminToken" === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}


function getJwtTokenFromCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if ("sjsadAdminToken" === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}

export const adminCommunication = {

    loginAdmin: (adminCredentials) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/login-admin`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(adminCredentials)); // Converting JS object to JSON to send to server
        });
    },

    getAllDesignations: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/admin/get-all-designations?page=${page}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },

    createDesignations: (designation) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/add-designation`, true);
            // Attach jwt token in headers. In Authorization part of headers send as Bearer token.
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            // Content type set to JSON
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(designation)); // Converting JS object to JSON to send to server
        });
    },

    getAllDepartments: (page=1) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/admin/fetch-all-department?page=${page}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },

    createDepartments: (departments) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/add-department`, true);
            // Attach jwt token in headers. In Authorization part of headers send as Bearer token.
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            // Content type set to JSON
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(departments)); // Converting JS object to JSON to send to server
        });
    },


    getAllUser: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/admin/fetch-all-users?page=${page}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    fetchAllMembers: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/admin/fetch-all-members`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },

    createAllUser: (userData) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/create-user`, true);
            // Attach jwt token in headers. In Authorization part of headers send as Bearer token.
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            // Content type set to JSON
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(userData)); // Converting JS object to JSON to send to server
        });
    },

    getAllRoles: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/admin/get-all-roles?page=${page}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    fetchAllRoles: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/admin/fetch-all-roles`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },

    createRoles: (rolesData) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/add-role`, true);
            // Attach jwt token in headers. In Authorization part of headers send as Bearer token.
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            // Content type set to JSON
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(rolesData)); // Converting JS object to JSON to send to server
        });
    },

    getAllCommittee: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/admin/get-all-committees?page=${page}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },

    createAllCommittee: (committeeData) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/add-committee`, true);
            // Attach jwt token in headers. In Authorization part of headers send as Bearer token.
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            // Content type set to JSON
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(committeeData)); // Converting JS object to JSON to send to server
        });
    },

    getUserById: (userId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/get-user-by-id`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ userId: userId }));
        });
    },
    getRoleById: (roleId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/get-role-by-id`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ roleId: roleId }));
        });
    },
    getDepartmentById: (departmentId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/get-department-by-id`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ departmentId: departmentId }));
        });
    },
    getDesignationById: (designationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/get-designation-by-id`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ designationId: designationId }));
        });
    },
    getCommitteeById: (committeeId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/get-committee-by-id`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ committeeId: committeeId }));
        });
    },

    deleteCommitteeById: (committeeIds) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/delete-committee-by-id`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ committeeIds: committeeIds }));
        });
    },
    deleteRoleById: (roleIds) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/delete-role-by-id`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ roleIds: roleIds }));
        });
    },
    updateCommittee: (committeeData) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/update-committee`, true);
            // Attach jwt token in headers. In Authorization part of headers send as Bearer token.
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            // Content type set to JSON
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(committeeData)); // Converting JS object to JSON to send to server
        });
    },
    deleteDesignationById: (designationIds) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/delete-designation-by-id`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ designationIds: designationIds }));
        });
    },
    deleteDepartmentById: (departmentIds) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/delete-department-by-id`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ departmentIds: departmentIds }));
        });
    },
    deleteOfficeUserById: (officeUserIds) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/delete-office-user-by-id`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ officeUserIds: officeUserIds }));
        });
    },
    updateRole: (roleData) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/update-role`, true);
            // Attach jwt token in headers. In Authorization part of headers send as Bearer token.
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            // Content type set to JSON
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(roleData)); // Converting JS object to JSON to send to server
        });
    },
    updateDesignation: (designationData) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/update-designation`, true);
            // Attach jwt token in headers. In Authorization part of headers send as Bearer token.
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            // Content type set to JSON
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(designationData)); // Converting JS object to JSON to send to server
        });
    },
    updateDepartment: (departmentData) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/update-department`, true);
            // Attach jwt token in headers. In Authorization part of headers send as Bearer token.
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            // Content type set to JSON
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(departmentData)); // Converting JS object to JSON to send to server
        });
    },
    updateOfficeUser: (userData) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/update-office-user`, true);
            // Attach jwt token in headers. In Authorization part of headers send as Bearer token.
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            // Content type set to JSON
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(userData)); // Converting JS object to JSON to send to server
        });
    },
    createApplyNotification: (notification) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/add-apply-notification`, true);
            // Attach jwt token in headers. In Authorization part of headers send as Bearer token.
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            // Content type set to JSON
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(notification)); // Converting JS object to JSON to send to server
        });
    },
    createGeneralNotification: (formData, updateData) => {
        return axios.post(`${getServerUrl()}/admin/add-general-notification?updateData=${updateData}`, formData, {
            headers: {
                "Content-Type": updateData ? "multipart/form-data,boundary=MyBoundary" : "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getNotifications: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/admin/get-all-notifications?page=${page}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    getAdminAuthenticateByJWT: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/admin/get-admin-authenticate-by-jwt`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                // Converting response from json to js objects
                const response = JSON.parse(json);
                // Attaching jwt from cookie itself as it is validated by the server.
                response.jwt = getCookie();
                resolve(response);
            };
            xhr.send();
        })
    },
    getNotificationById: (notificationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/get-notification-by-id`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ notificationId: notificationId }));
        });
    },
    updateApplyNotification: (notification) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/update-apply-notification`, true);
            // Attach jwt token in headers. In Authorization part of headers send as Bearer token.
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            // Content type set to JSON
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(notification)); // Converting JS object to JSON to send to server
        });
    },
    updateGeneralNotification: (attachment, id, updateData) => {
        return axios.post(`${getServerUrl()}/admin/update-general-notification?id=${id}&updateData=${updateData}`, attachment, {
            headers: {
                "Content-Type": updateData ? "multipart/form-data" : "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    deleteNotificationById: (notificationIds) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/delete-notification-by-id`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ notificationIds: notificationIds }));
        });
    },
    getUploadDownloadDocumentById: (documentId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/get-upload-download-document-by-id`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ documentId: documentId }));
        });
    },
    deleteUploadDownloadDocumentById: (documentIds) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/delete-upload-download-document-by-id`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ uploadDownloadDocumentIds: documentIds }));
        });
    },
    uploadDownloadDocument: ({ attachment, subject, description, portal }) => {
        return axios.post(`${getServerUrl()}/admin/upload-download-document?subject=${subject}&description=${description}&portal=${portal}`, attachment, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateUploadDownloadDocument: (attachment, updateData) => {
        return axios.post(`${getServerUrl()}/admin/update-upload-download-document?updateData=${updateData}`, attachment, {
            headers: {
                "Content-Type": updateData ? "multipart/form-data" : "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getUploadDownloadDocuments: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/admin/get-upload-download-documents?page=${page}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    getNotificationPdfForAdmin: async (id) => {
        try {
            const response = await axios.get(`${getServerUrl()}/admin/get-notification-pdf-in-admin?notificationId=${id}`, {
                headers: {
                    "Content-Type": "application/pdf",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], {
                type: "application/pdf"
            });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        } catch (error) {
            alert(error);
        }
    },
    getUploadedDocumentPdfForAdmin: async (id) => {
        try {
            const response = await axios.get(`${getServerUrl()}/admin/get-uploaded-document-pdf?documentId=${id}`, {
                headers: {
                    "Content-Type": "application/pdf",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            });
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], {
                type: "application/pdf"
            });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        } catch (error) {
            alert(error);
        }
    },
    getUplodedNotification: (notificationId) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/admin/get-uploaded-notification-document?notificationId=${notificationId}`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/pdf"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },
    getUplodedUploadDownloadDocument: (documentId) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/admin/get-uploaded-upload-download-document?documentId=${documentId}`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/pdf"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },
    getQuotaDetailsFromServer: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/admin/get-quota-details`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    updateYearlyApplications: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/update-yearly-applications-graph-data`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    updateYearlyCourseCompletionApplications: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/update-yearly-course-completion-graph-data`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    updateAmountDisbursedStatistic: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/update-amount-disbursed-graph-data`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    updateDistrictWiseApplications: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/update-districtWise-applications-graph-data`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    updateCountryWiseApplications: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/update-countryWise-applications-graph-data`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    updateCourseWiseApplications: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/update-course-wise-applications-graph-data`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    updateQuota: (courses) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/admin/update-quota`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ courses })); // Converting JS object to JSON to send to server
        });
    },
    getDistriceWiseApplicationExcel: (year) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/admin/get-districtWiseApplications?year=${year}`, {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },
    getCountryWiseApplicationExcel: (year) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/admin/get-countryWiseApplications?year=${year}`, {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },
    getCourseWiseApplicationExcel: (year) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/admin/application-By-stream?year=${year}`, {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },
    getApplicationByYearExcel: (fromYear, endYear) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/admin/application-count-by-year?fromYear=${fromYear}&endYear=${endYear}`, {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },
    getYearlyCourseComplitionExcel: (fromYear, endYear) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/admin/yearly-course-complition-by-course?fromYear=${fromYear}&endYear=${endYear}`, {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the excel Stream
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },
    getAmountDisbursedByYear: (fromYear, endYear) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/admin/disbursed-amount-by-course?fromYear=${fromYear}&endYear=${endYear}`, {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the excel Stream
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                //Build a URL from the file
                const excelBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(excelBlob);
                resolve(excelBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },

    //Search API'S
    getCommittieesBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/admin/search-committees`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            alert(error.message);
        }
    },
    getDepartmentsBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/admin/search-department`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            alert(error.message);
        }
    },
    getDesignationsBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/admin/search-designation`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            alert(error.message);
        }
    },
    getNotificationsBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/admin/search-notification`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            alert(error.message);
        }
    },
    getRoleBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/admin/search-roles`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            alert(error.message);
        }
    },
    getUsersBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/admin/search-users`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            alert(error.message);
        }
    },
    getUploadedDocumentsBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/admin/search-upload-download-documents`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            alert(error.message);
        }
    }
}
