import React, { useState, useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import H4 from "../../text-components/H4";
import P from "../../text-components/P";
import LoadingForms from "../../shared-components/LoadingForms";
import { backOfficeCommunication } from "../../communications/back-office-communication";
import H5 from "../../text-components/H5";
import ReactPaginate from "react-paginate";

const UserNotification = () => {

    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [signedGr, setSignedGr] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const history = useHistory();
    const limit = process.env.REACT_APP_PAGE_LIMIT;


    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        fetchSignedGr(selectedPage + 1)
    }

    //get All Signed GR on Initial Load
    const fetchSignedGr = async (page) => {
        try {
            let fullUrl = window.location.href;
            let splitUrlArray = fullUrl.split('/');
            if (splitUrlArray.includes("user-notification")) {
                dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "USER_NOTIFICATION" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
            }
            showLoading("Loading...");
            // getting committee id by queryParams

            const getSignGrList = await backOfficeCommunication.getAllSingedGrList(page);
            // console.log(getSignGrList);
            if (getSignGrList?.status === "SUCCESS") {
                setSignedGr(getSignGrList?.grs);
                setPageCount(getSignGrList?.totalPages);
            } else if (getSignGrList?.status === "ERROR" || getSignGrList?.jwt === null) {
                showModal(getSignGrList?.message);
                history.push("/back-office/login")
            } else {
                setSignedGr([]);
                setPageCount(0)
            }
            hideLoading();
        } catch (error) {
            showModal(error.message);
        }
    }

    useEffect(() => {
        fetchSignedGr();
    }, [])

    // showUserNOtificationPreview
    const showUserNOtificationPreview = (fileUrl, grId) => {
        history.push(`/back-office/dashboard/user-notification-preview?fileUrl=${fileUrl}&grId=${grId}`);
    }


    return (
        <section className="dashboard_background">
            {isLoading.value ?
                <LoadingForms message={isLoading.message} />
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="dashboard_back_office">
                                <div className="row search_wrapper">
                                {/* <div className="col-lg-4 col-md-4 ps-0">
                                    <input type="search" className="form-control" placeholder="Search..." />
                                </div>*/}
                                    <div className="col-lg-8 col-md-8 pe-0 pagination_wrapper">
                                        {pageCount > 1 &&
                                            <ReactPaginate
                                                previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                                nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                forcePage={currentPage}
                                                activeClassName={"active"} />
                                        }
                                    </div>
                                </div>
                                {/*-----Grievance card header section---*/}
                                <div className="dashboard_view_data_heading">
                                    <div className="row m-0">
                                        <div className="col-1 p-0 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Serial No.</H4></span>
                                        </div>
                                        <div className="col-5 p-0 view_dashboard ps-4" style={{ textAlign: "left" }}>
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">File Name</H4></span>
                                        </div>
                                        <div className="col-3 p-0 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Creation Date</H4></span>
                                        </div>
                                        <div className="col-3 p-0 ">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Status</H4></span>
                                        </div>
                                    </div>
                                </div>
                                {
                                    signedGr?.length > 0 ?

                                        <>
                                            {
                                                signedGr?.map((grs, index) => {
                                                    const { isGrSigned, fileUrl, areUsersNotified } = grs;
                                                    let fileName = fileUrl?.split('/')
                                                    return (
                                                        <div className="dashboard_view_data_main" key={index}>
                                                            <div className="dashboard_view_data">
                                                                <div className="row m-0">
                                                                    <div className="col-1 p-0 view_dashboard text_align">
                                                                        <span>
                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{limit * currentPage + (index + 1)}</P>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-5 p-0 ps-4 view_dashboard align" style={{ textAlign: "left" }}>
                                                                        <span>
                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a onClick={() => showUserNOtificationPreview(grs?.fileUrl, grs._id)}>{!!fileUrl ? fileName : fileUrl}&nbsp; &nbsp;
                                                                            </a>
                                                                            </P>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-3 p-0 view_dashboard text_align">
                                                                        <span ><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {new Date(grs?.createdDate).getDate()} - {new Date(grs?.createdDate).getMonth() + 1} - {new Date(grs?.createdDate).getFullYear()}
                                                                        </P></span>
                                                                    </div>
                                                                    <div className="col-3 p-0  text_align">
                                                                        <span ><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{isGrSigned === true && areUsersNotified === true ? "Notified" : 'New'}</P></span>
                                                                    </div>
                                                                </div>
                                                                {areUsersNotified === false ?
                                                                    <div className="new_badge">
                                                                        <p>New</p>
                                                                    </div>
                                                                    : <span></span>
                                                                }
                                                            </div>
                                                        </div>
                                                    )

                                                })}
                                        </>
                                        :
                                        <div className="text-center mt-5">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Records not available</H5>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </section>
    );

}
export default memo(UserNotification);