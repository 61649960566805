import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import H4 from "../text-components/H4";
import P from "../text-components/P";
import { communication } from '../communications/communication';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormik } from 'formik';
import { removeUnderscoreFromText } from "../utlis/helper";


const UserMilestone5Form = () => {
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [userAcknowledgeView, setExpensesReportUrl] = useState();

    const history = useHistory();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [initialFormValues, setInitialFormValues] = useState(null);

    // getting committee id by queryParams
    const queryParams = new URLSearchParams(location.search);
    const milestoneId = queryParams.get('milestoneId');
    const applicationId = queryParams.get('applicationId');

    const [milestoneDocument, setMilestoneDocument] = useState({});

    const CloseUserMilestone5 = () => {
        history.push("/user/doc-submission");
    }

    function getFilePath(e) {
        setExpensesReportUrl(e.currentTarget.files[0]);
        setFieldValue('userAcknowledgeView', e.currentTarget.files[0]);
    }

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }
    //onClick redirecting user to personal details form
    const formStatus = async (applicationId) => {
        history.push(`/user/application/?applicationId=${applicationId}`);
    }

    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    useEffect(() => {


        async function getMileStoneDocument(applicationId) {

            try {
                const result = await communication.getUserMilestone(applicationId, "MILESTONE_FIVE");
                if (result.status === "SUCCESS") {
                    setMilestoneDocument(result.milestoneDetails[result.milestoneDetails.length - 1]);
                } else {
                    showModal(result.message);
                }

            } catch (error) {
                showModal(error.message);
            }

        }

        getMileStoneDocument(applicationId);

    }, []);

    const defaultFormValues = {
        userAcknowledgeView: null,
    }
    const validationSchema = Yup.object().shape({
        userAcknowledgeView: Yup.mixed().required(),
    });

    const { handleSubmit, handleChange, setFieldValue, values, errors, handleReset } = useFormik({
        initialValues: initialFormValues === null ? defaultFormValues : initialFormValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {

            try {
                showLoading("Uploading...");

                let formData = new FormData();
                formData.append("document", values.userAcknowledgeView, values.userAcknowledgeView.name);


                const uploadResult = await communication.uploadUserAcknowledgeReportFromUniversity(formData, applicationId);
                if (uploadResult.data.status === "SUCCESS") {
                    showModal("Document uploaded successfully.")
                } else {
                    showModal(uploadResult.data.message);
                }

                handleReset();
                hideLoading();
                return true;

            } catch (error) {
                hideLoading();
                showModal(error.message);
                return false
            }
        },
        validationSchema: validationSchema,
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const downloadDocumentFromServer = async (attachment) => {
        if (attachment !== "") {
            await communication.getMilestoneDocumentfromServer(attachment);
        }
    }

    return (
        <div className="user_milestone_main">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/*  Show status */}
                        {/* { */}
                        {/* ['APPROVED', 'NEW'].includes(milestoneDocument.userStatus) === true && <> */}
                        <div className="user_milestone">
                            <div className="user_milestone_header">
                                <H4 style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> {removeUnderscoreFromText(milestoneDocument?.name)} </H4>
                            </div>
                            <div className="user_milestone_body">
                                {
                                    ["REQUEST_TO_TREASURY", "FUNDS_RECEIVED_FROM_TREASURY", "NEW"].includes(milestoneDocument.backOfficeStatus) === true && <>

                                        <div className="row">
                                            <div className="col-12">
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Status: In Process </H4>
                                            </div>
                                        </div>

                                    </>
                                }

                                {
                                    milestoneDocument.backOfficeStatus === "APPROVED" && <>
                                        <div className="row">
                                            <div className="col-12">
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Status: Milestone approved</H4>
                                            </div>
                                        </div>
                                    </>
                                }

                                {/* show uploaded documents here */}

                                {/* Expenditure report url */}
                                {
                                    milestoneDocument.expenditureReportUrl && milestoneDocument.expenditureReportUrl !== "" && <div className="row mt-3">
                                        <div className="col-10">
                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Expenditure report:  </P>
                                        </div>
                                        <div className="col-2">
                                            <button onClick={() => downloadDocumentFromServer(milestoneDocument.expenditureReportUrl)}>Preview</button>
                                        </div>
                                    </div>
                                }

                                {
                                    milestoneDocument.userFundsReceiptUrl && milestoneDocument.userFundsReceiptUrl !== "" && milestoneDocument.userFundsReceiptUrl.length > 0 && <div className="row mt-3">
                                        <div className="col-12">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Funds Received url: </H4>
                                        </div>
                                    </div>
                                }

                                {
                                    milestoneDocument.userFundsReceiptUrl && milestoneDocument.userFundsReceiptUrl.length > 0 && milestoneDocument.userFundsReceiptUrl.map((ele, idx) => {
                                        return (
                                            <div className="row mt-3" key={idx}>
                                                <div className="col-10">
                                                    <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> {ele.documentName} </P>
                                                </div>
                                                <div className="col-2">
                                                    <button onClick={() => downloadDocumentFromServer(ele.fileUrl)}>Preview</button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                {/* {
                                    milestoneDocument.treasuryConfirmationReceipt && milestoneDocument.treasuryConfirmationReceipt !== "" && <div className="row mt-3">
                                        <div className="col-10">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Treasury Confirmation Receipt:  </H4>
                                        </div>
                                        <div className="col-2">
                                            <button onClick={() => downloadDocumentFromServer(milestoneDocument.treasuryConfirmationReceipt)}>Preview</button>
                                        </div>
                                    </div>
                                } */}

                                {
                                    milestoneDocument.userAckReportUrl && milestoneDocument.userAckReportUrl !== "" && milestoneDocument.userAckReportUrl.length > 0 && <>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> User Acknowledged Receipt: </H4>
                                            </div>
                                        </div>
                                    </>
                                }


                                {

                                    milestoneDocument.userAckReportUrl && milestoneDocument.userAckReportUrl.length > 0 && milestoneDocument.userAckReportUrl.map((ele, idx) => {
                                        return (
                                            <div className="row mt-3" key={idx}>
                                                <div className="col-10">
                                                    <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> {ele.documentName} </P>
                                                </div>
                                                <div className="col-2">
                                                    <button onClick={() => downloadDocumentFromServer(ele.fileUrl)}>Preview</button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }




                                {/* show uploaded documents here */}

                                <div className="row mt-4">
                                    <div className="col-12" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                        <button className="ms-4" onClick={() => CloseUserMilestone5()}>Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* </div></> */}
                        {/* // } */}

                        {/* show input */}

                        {/* {
                            milestoneDocument.userStatus === "FUNDS_RECEIVED_FROM_TREASURY" &&
                            <div className="user_milestone">
                                <div className="user_milestone_header">
                                    <H4 style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>First Year First Installment</H4>
                                </div>
                                <div className="user_milestone_body">
                                    <div className="row">
                                        <div className="col-12">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>* Please attach required First Installment Slip.</H4>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-4 pe-0" style={{ display: "flex", alignItems: "center" }}>
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>First Installment Slip</H4>
                                        </div>
                                        <div className="col-6 ps-0">
                                            <input className="form-control form-control-sm" type="file" id="formFile" onChange={getFilePath} value={values.userAcknowledgeView?.filename} />
                                            <p className='text-danger'> {errors.userAcknowledgeView} </p>
                                        </div>
                                        <div className="col-2" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                            <button onClick={handleSubmit}>Upload</button>
                                        </div>
                                    </div>
                                    {
                                        milestoneDocument.fundsReceivedUrl && milestoneDocument.fundsReceivedUrl !== "" && <div className="row mt-3">
                                            <div className="col-9">
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Fund Received Receipt </H4>
                                            </div>
                                            <div className="col-3">
                                                <button onClick={() => downloadDocumentFromServer(milestoneDocument.fundsReceivedUrl)}>Preview</button>
                                            </div>
                                        </div>
                                    }

                                    {
                                        milestoneDocument.treasuryConfirmationReceipt && milestoneDocument.treasuryConfirmationReceipt !== "" && <div className="row mt-3">
                                            <div className="col-9">
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Treasury Confirmation Receipt  </H4>
                                            </div>
                                            <div className="col-3">
                                                <button onClick={() => downloadDocumentFromServer(milestoneDocument.treasuryConfirmationReceipt)}>Preview</button>
                                            </div>
                                        </div>
                                    }

                                    {
                                        milestoneDocument.semesterFeeAndAcknowledgementCertificateUrl && milestoneDocument.semesterFeeAndAcknowledgementCertificateUrl !== "" && <div className="row mt-3">
                                            <div className="col-9">
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Acknowledgement Receipt  </H4>
                                            </div>
                                            <div className="col-3">
                                                <button onClick={() => downloadDocumentFromServer(milestoneDocument.semesterFeeAndAcknowledgementCertificateUrl)}>Preview</button>
                                            </div>
                                        </div>
                                    }
                                    <div className="row mt-4">
                                        <div className="col-12" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                            <button >Submit</button>
                                            <button className="ms-4" onClick={() => CloseUserMilestone5()}>Cancel</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }

                        {
                            milestoneDocument.userStatus === "REQUEST_TO_TREASURY" && <>
                                <div className="user_milestone">
                                    <div className="user_milestone_header">
                                        <H4 style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Requested to Treasury.</H4>
                                    </div>
                                    <div className="user_milestone_body">
                                        <div className="row">
                                            <div className="col-12">
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Requested to Treasury. </H4>
                                            </div>
                                        </div>

                                        {
                                            milestoneDocument.fundsReceivedUrl && milestoneDocument.fundsReceivedUrl !== "" && <div className="row mt-3">
                                                <div className="col-9">
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Fund Received Receipt </H4>
                                                </div>
                                                <div className="col-3">
                                                    <button onClick={() => downloadDocumentFromServer(milestoneDocument.fundsReceivedUrl)}>Preview</button>
                                                </div>
                                            </div>
                                        }

                                        {
                                            milestoneDocument.treasuryConfirmationReceipt && milestoneDocument.treasuryConfirmationReceipt !== "" && <div className="row mt-3">
                                                <div className="col-9">
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Treasury Confirmation Receipt  </H4>
                                                </div>
                                                <div className="col-3">
                                                    <button onClick={() => downloadDocumentFromServer(milestoneDocument.treasuryConfirmationReceipt)}>Preview</button>
                                                </div>
                                            </div>
                                        }

                                        {
                                            milestoneDocument.semesterFeeAndAcknowledgementCertificateUrl && milestoneDocument.semesterFeeAndAcknowledgementCertificateUrl !== "" && <div className="row mt-3">
                                                <div className="col-9">
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Acknowledgement Receipt  </H4>
                                                </div>
                                                <div className="col-3">
                                                    <button onClick={() => downloadDocumentFromServer(milestoneDocument.semesterFeeAndAcknowledgementCertificateUrl)}>Preview</button>
                                                </div>
                                            </div>
                                        }


                                        <div className="row mt-4">
                                            <div className="col-12" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                <button className="ms-4" onClick={() => CloseUserMilestone5()}>Cancel</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                        }

                        {
                            milestoneDocument.userStatus === "NEW" && <>
                                <div className="user_milestone">
                                    <div className="user_milestone_header">
                                        <H4 style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>In Progress</H4>
                                    </div>
                                    <div className="user_milestone_body">
                                        <div className="row">
                                            <div className="col-12">
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}></H4>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-12" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                <button className="ms-4" onClick={() => CloseUserMilestone5()}>Cancel</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                        }


                        {
                            milestoneDocument.userStatus === "COMPLETED" && <>
                                <div className="user_milestone">
                                    <div className="user_milestone_header">
                                        <H4 style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Milestone completed</H4>
                                    </div>
                                    <div className="user_milestone_body">


                                        {
                                            milestoneDocument.fundsReceivedUrl && milestoneDocument.fundsReceivedUrl !== "" && <div className="row mt-3">
                                                <div className="col-9">
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Fund Received Receipt </H4>
                                                </div>
                                                <div className="col-3">
                                                    <button onClick={() => downloadDocumentFromServer(milestoneDocument.fundsReceivedUrl)}>Preview</button>
                                                </div>
                                            </div>
                                        }

                                        {
                                            milestoneDocument.treasuryConfirmationReceipt && milestoneDocument.treasuryConfirmationReceipt !== "" && <div className="row mt-3">
                                                <div className="col-9">
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Treasury Confirmation Receipt  </H4>
                                                </div>
                                                <div className="col-3">
                                                    <button onClick={() => downloadDocumentFromServer(milestoneDocument.treasuryConfirmationReceipt)}>Preview</button>
                                                </div>
                                            </div>
                                        }

                                        {
                                            milestoneDocument.semesterFeeAndAcknowledgementCertificateUrl && milestoneDocument.semesterFeeAndAcknowledgementCertificateUrl !== "" && <div className="row mt-3">
                                                <div className="col-9">
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Acknowledgement Receipt  </H4>
                                                </div>
                                                <div className="col-3">
                                                    <button onClick={() => downloadDocumentFromServer(milestoneDocument.semesterFeeAndAcknowledgementCertificateUrl)}>Preview</button>
                                                </div>
                                            </div>
                                        }


                                        <div className="row mt-4">
                                            <div className="col-12" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                <button className="ms-4" onClick={() => CloseUserMilestone5()}>Cancel</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                        } */}


                    </div>
                </div>
            </div>

        </div >
    );
}
export default UserMilestone5Form;