
import HomeScreenNav from "../common/HomeScreenNav";
import { Carousel } from "react-bootstrap";
import { useSelector } from "react-redux";
import H1 from "../text-components/H1";
import H3 from "../text-components/H3";
import H4 from "../text-components/H4";
import { useState, useEffect } from "react";
import HomeScreenNotificationsCard from "../common/HomeScreenNotificationsCard";
import { HomeScreenScholarshipScheme } from "../common/HomeScreenScholarshipScheme";
import { communication } from "../communications/communication";
import MobileMenu from "../common/MobileMenu";
import MobileImageGallery from "../common/MobileImageGallery";
import LoadingForms from "../shared-components/LoadingForms";
import Footer from "../common/Footer";


const HomeScreen = () => {
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const [isLoading, setIsLoading] = useState({ message: "", value: false });


    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    const [universityList, setUniversityList] = useState([]);

    useEffect(async () => {
        showLoading("Loading...");
        document.title = "Scholarship | Home";
        const qsWorldRankUniversityList = await communication.getUniversityRankList();
        if (qsWorldRankUniversityList.status === "SUCCESS") {
            let qsWorldRankUniversityArray = qsWorldRankUniversityList.qsRankUniverstites.universityList;
            setUniversityList(value => {
                return [...value, ...qsWorldRankUniversityArray]
            });
        }
        hideLoading();
    }, []);

    return (
        <>
            <div>
                <HomeScreenNav />

                <section className="carousel_main_section">
                    <Carousel>
                        <Carousel.Item>
                            <div className="main_carousel_text">
                                <h1 style={{ fontSize: `${30 * fontSizeMultiplier / 100}px` }}>Social Justice & Social Assistance Department</h1>
                                <h2 style={{ fontSize: `${30 * fontSizeMultiplier / 100}px` }}>Government of Maharashtra</h2>
                                <h3 style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>We work hard to increase positive emotions in our school.
                                    We try to build greater engagement with the school by the wider school
                                    community – school staff, students and parents. A general body meeting
                                    of parents is held regularly in the school. The PTA members of our
                                    school are closely involved in most academic activities.
                                    We encourage students to take part in different competitions.
                                    We celebrate annual gathering, sports week, science exhibition
                                    every year and each and every student take part in these events.</h3>
                            </div>
                            <img
                                className="d-block w-100"
                                src="/assets/images/pngs/background-img.png"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                            <Carousel.Caption />
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="main_carousel_text">
                                <h1 style={{ fontSize: `${30 * fontSizeMultiplier / 100}px` }}>Social Justice & Social Assistance Department</h1>
                                <h2 style={{ fontSize: `${30 * fontSizeMultiplier / 100}px` }}>Government of Maharashtra</h2>
                                <h3 style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>We work hard to increase positive emotions in our school.
                                    We try to build greater engagement with the school by the wider school
                                    community – school staff, students and parents. A general body meeting
                                    of parents is held regularly in the school. The PTA members of our
                                    school are closely involved in most academic activities.
                                    We encourage students to take part in different competitions.
                                    We celebrate annual gathering, sports week, science exhibition
                                    every year and each and every student take part in these events.</h3>
                            </div>
                            <img
                                className="d-block w-100"
                                src="/assets/images/pngs/background-img.png"
                                alt="Second slide"
                                style={{ height: "500px" }}
                            />
                            <Carousel.Caption />
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="main_carousel_text homeCrousel">
                                <h1 style={{ fontSize: `${30 * fontSizeMultiplier / 100}px` }}>Social Justice & Social Assistance Department</h1>
                                <h2 style={{ fontSize: `${30 * fontSizeMultiplier / 100}px` }}>Government of Maharashtra</h2>
                                <h3 style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>We work hard to increase positive emotions in our school.
                                    We try to build greater engagement with the school by the wider school
                                    community – school staff, students and parents. A general body meeting
                                    of parents is held regularly in the school. The PTA members of our
                                    school are closely involved in most academic activities.
                                    We encourage students to take part in different competitions.
                                    We celebrate annual gathering, sports week, science exhibition
                                    every year and each and every student take part in these events.</h3>
                            </div>
                            <img
                                className="d-block w-100"
                                src="/assets/images/pngs/background-img.png"
                                alt="Third slide"
                                style={{ height: "500px" }}
                            />
                            <Carousel.Caption />
                        </Carousel.Item>
                    </Carousel>
                </section>


                <div className="row m-0 homescreen_notification_card_main">
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 pb-1">
                        <HomeScreenScholarshipScheme />
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 pb-1">
                        <HomeScreenNotificationsCard />
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 pb-1">
                        <div className="homescreen_notification_card" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
                            <div className="homescreen_notification_card_header" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#6D8DAD" : "#000000" }}>
                                <div className="homescreen_notification_card_title" style={{ fontSize: `${20 * fontSizeMultiplier / 100}px`, color: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#FFFF00" }}><b>QS World Rank University</b></div>
                            </div>
                            {/*------ ranking data header start----*/}
                            {
                                isLoading.value ?
                                    <LoadingForms message={isLoading.message} />
                                    :
                                    <div style={{ height: "450px", overflowY: "scroll" }}>

                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Rank</th>
                                                    <th scope="col">University Name</th>
                                                    <th scope="col">Country</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {universityList.map((university, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{university.rank}</H4></th>
                                                            <td><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{university.university}</H4></td>
                                                            <td><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{university.countryName}({university.countryCode})</H4></td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>

                                    </div>
                            }
                            {/*----world ranking table end-----*/}

                            {/* <div style={{ marginTop: "10px", display: "flex", justifyContent: "end", marginRight: "30px" }}>
                                <div style={{ justifyContent: "center", textAlign: "center", backgroundColor: "#FFFFFF", width: "100px", padding: "5px", border: "1px solid #C4C4C4", borderRadius: "50px", boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", alignItems: "end", display: "flex", cursor: "pointer" }}><H4 whiteThemeColor="#1E2343" ><b>View All</b></H4></div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/*------------gallery section start---------*/}
                <div className="row m-0 pt-2 gallery_section">
                    <div className="col-12 d-flex justify-content-center mb-4 mt-2">
                        <H1 whiteThemeColor="#1E2343" darkThemeColor="#FFFF00"><u><b>OUR GALLERY</b></u></H1>
                    </div>

                    <div className="col-12 d-flex justify-content-center">
                        <H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF"></H3>
                    </div>
                </div>

                <div className=" desktop_gallery row m-0 pt-2" style={{ paddingLeft: "100px", paddingRight: "100px" }}>
                    <div className="col-4">
                        <img style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", height: "450px", width: "100%", borderRadius: "14px" }} src="/assets/images/pngs/side_commencement.png" alt="Gallery Image 1" />
                        <br />
                        <img className="mt-3" style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", height: "250px", width: "100%", borderRadius: "14px" }} src="/assets/images/jpgs/Graduation_ceremony_with_Azim_Premji.jpg" alt="Gallery Image 1" />
                    </div>

                    <div className="col-4">
                        <img style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", height: "380px", width: "100%", borderRadius: "14px" }} src="/assets/images/jpgs/600-nca-grads-get-degrees-at-convocation-1548627112-3779.jpg" alt="Gallery Image 1" />
                        <br />
                        <img className="mt-3" style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", height: "320px", width: "100%", borderRadius: "14px" }} src="/assets/images/jpgs/convocation.jpg" alt="Gallery Image 1" />
                    </div>

                    <div className="col-4">
                        <img style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", height: "310px", width: "100%", borderRadius: "14px" }} src="/assets/images/jpgs/Students_are_Very_Excite_To_Click_Their_Picture_During_1st_Annual_Convocation_At_Punjab_University_constitute_college_Patto_Hira_Singh_Moga_Punjab_India-696x464.jpg" alt="Gallery Image 1" />
                        <br />
                        <img className="mt-3" style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", height: "390px", width: "100%", borderRadius: "14px" }} src="/assets/images/jpgs/graduation-1-1024x466-1.jpg" alt="Gallery Image 1" />
                    </div>

                </div>

                {/*----gallery section end---------*/}
                <MobileMenu />
                <MobileImageGallery />
                <Footer />
            </div>

        </>
    );
}

export default HomeScreen;
