import React, { useState, useEffect, useRef, memo } from "react";
import { useHistory } from "react-router";
import H4 from "../../../text-components/H4";
import { useLocation } from "react-router-dom";
import { backOfficeCommunication } from "../../../communications/back-office-communication";
import LoadingForms from "../../../shared-components/LoadingForms";
import { useDispatch } from "react-redux";
import Label from "../../../text-components/Label";

const UserMilestoneFour = ({ changeHandler }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [disabled, setDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [applicationId, setApplicationId] = useState("");
    const [milestone, setMileStone] = useState("")
    const [type, setType] = useState("");
    const [sponsorshipLetter, setSponsorshipLetter] = useState();

    const message = useRef();
    const universityEmail = useRef()

    function showDocumentScrutinyUploadedDocumentPreview(applicationId, type, fileUrl) {
        setType(type)
        history.push(`/back-office/dashboard/document-scrutiny/milestone-list/uploaded-documents/preview?milestoneId=${milestone._id.toString()}&applicationId=${applicationId}&fileUrl=${fileUrl}&type=${type}`);
    }
    //close document scrutiny uploaded files window
    function closeDocumentScrutinyUploadedFiles(applicationId, type) {
        setType(type)
        history.push(`/back-office/dashboard/document-scrutiny/milestone-list?milestoneId=${milestone._id.toString()}&applicationId=${applicationId}&type=${type}`);
    }


    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }



    //  when back office user will upload sponsorship letter it will be status will be marked as
    const UploadSponsorshipReport = async () => {
        try {
            showLoading("Loading...");


            if (sponsorshipLetter === null || sponsorshipLetter === undefined) {
                showModal("Please attach file");
                hideLoading();
                return;
            }

            const formData = new FormData();
            formData.append("sponsorshipLetter", sponsorshipLetter);
            //  TODO VALIDATE FILE HERE
            const uploadSponsorShip = await backOfficeCommunication.uploadSponsorshipLetter(applicationId, formData);

            if (uploadSponsorShip?.status === "SUCCESS" || uploadSponsorShip?.data?.status === "SUCCESS") {
                showModal(uploadSponsorShip?.message || uploadSponsorShip?.data?.message);
                await getMilestoneDocument();
                hideLoading();
                return;
            }
            else {
                showModal(uploadSponsorShip?.message || uploadSponsorShip?.data?.message);
                hideLoading();
                return;
            }
        } catch (error) {
            showModal(error?.message);
            hideLoading();
            return;
        }

    }


    function selectFile(e) {
        setSponsorshipLetter(e.target.files[0]);
        return;
    }

    const sendMail = async () => {
        const userId = milestone.userId;
        const universityMessage = message.current.value;
        const email = universityEmail.current.value;

        try {
            showLoading("Loading...");
            const mailSend = await backOfficeCommunication.sendMailToUniversity(applicationId, userId, universityMessage, email);
            if (mailSend.status === "SUCCESS") {
                hideLoading();
                await changeHandler();
                showModal(mailSend.message);
                history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`);
                return;
            }

            if (mailSend.status === "VALIDATION_FAILED") {
                hideLoading();
                showModal(mailSend.validationErrorsList[0].message);
                return;
            }

            showModal(mailSend?.message);
            hideLoading();
        } catch (error) {
            showModal(error?.message);
            hideLoading();
            return;
        }
    }

    async function getMilestoneDocument() {
        try {
            // let fullUrl = window.location.href;
            // let splitUrlArray = fullUrl.split('/');
            // if (splitUrlArray.includes("document-scrutiny")) {
            //     dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
            // } else {
            //     dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
            // }

            showLoading("Loading...");

            const queryParams = new URLSearchParams(location.search);
            const applicationId = queryParams.get('applicationId');
            const milestoneId = queryParams.get('milestoneId');
            const type = queryParams.get('type');

            const response = await backOfficeCommunication.getMilestone(milestoneId, applicationId, type);
            if (response.status === "SUCCESS") {

                if (response.milestones[response.milestones.length - 1].backOfficeStatus === "APPROVED") {
                    setDisabled(true);
                } else {
                    setDisabled(false);
                }

                setMileStone(response.milestones[response.milestones.length - 1]);
                setApplicationId(applicationId);
                setType(type);
            } else {
                showModal(response.message);
            }
            hideLoading();

        } catch (error) {
            showModal(error);
        }
    }

    useEffect(() => {
        getMilestoneDocument();
    }, []);

    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');
    let splitUrl = splitUrlArray[splitUrlArray?.length - 1]?.split('?');
    if (splitUrl.includes("milestoneFour")) {
        dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
    } else {
        dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
    }

    return (
        <section className="back_office_preview_section">
            {isLoading.value ?
                <LoadingForms message={isLoading.message} />
                :
                <div className="container">
                    <div className="row">

                        <div className="col-12 p-0">
                            <div className="back_office_preview_box">
                                <div className="back_office_preview_title">
                                    <H4>{milestone?.name}</H4>
                                    <div className="close_back_office_preview" onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </div>
                                </div>
                                {
                                    milestone?.sponsorshipLetterUrl !== "" ?
                                        <div className="uploaded_documents">
                                            {/*---heading section---*/}
                                            <div className="row mt-3">

                                                <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                                    <span>
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Serial No.</H4>
                                                    </span>
                                                </div>

                                                <div className="col-10 p-0 ps-4">
                                                    <span>
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Name</H4>
                                                    </span>
                                                </div>
                                            </div>
                                            {/*---uploaded file section---*/}

                                            {
                                                milestone?.sponsorshipLetterUrl && milestone?.sponsorshipLetterUrl !== "" && <div className="row mt-3">

                                                    <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                                        <span>
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">{1}</H4>
                                                        </span>
                                                    </div>

                                                    <div className="col-10 p-0 ps-4">
                                                        <span>
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD"><a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.sponsorshipLetterUrl)} title="Click to preview document"> Sponsorship Report Letter <i className="fa fa-paperclip fa-1x ms-2" aria-hidden="true"></i></a></H4>
                                                        </span>
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                        :
                                        <div className="text-center mt-5">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Document not available.</H4>
                                        </div>
                                }

                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    {/* <div className="comment_box_main">
                <div className="comment_box_heading">
                    <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>Comment Box</P>
                </div>
                <div className="comment_box_body">
                    <div className="comment_to_write_main">
                        <div className="comment_to_write">
                            <input className="form-control" placeholder="Write comment here..." />
                            <button>Send <i className="fa fa-paper-plane" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div> */}
                                </div>
                                <div className="col-lg-6 mt-lg-5">
                                    {disabled !== true ?
                                        <>
                                            <div className="row">
                                                <div className="col-lg-2 col-md-4"></div>
                                                {
                                                    milestone?.sponsorshipLetterUrl === "" ?
                                                        <>
                                                            <div className="col-lg-12">
                                                                <span style={{ fontSize: "14px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                            </div>
                                                            <div className="col-lg-10 col-md-8 mt-md-3">
                                                                <div className="upload_report_section">
                                                                    <input type="file" className="form-control" id="inputGroupFile02" title="upload sponsorship letter" onChange={(e) => { selectFile(e) }} />
                                                                    <button className="ms-3" onClick={UploadSponsorshipReport}> Upload </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                            {
                                                milestone?.sponsorshipLetterUrl && milestone?.sponsorshipLetterUrl !== "" ?
                                                    <div className="row">
                                                        <div className="col-lg-2 col-md-4"></div>
                                                        <div className="col-lg-10 col-md-8">
                                                            <div className="send_email_to_university">
                                                                <input type="text" className="form-control" id="inputGroupFile02" placeholder="enter email" ref={universityEmail} />
                                                                <textarea style={{ minHeight: "50px" }} className="form-control" placeholder="message..." ref={message} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <> </>
                                            }
                                        </>
                                        : <></>
                                    }
                                    <div className="send_email_to_university_button">
                                        {
                                            milestone?.sponsorshipLetterUrl && milestone?.sponsorshipLetterUrl !== "" ?
                                                <>
                                                    {disabled !== true ?
                                                        <button onClick={sendMail}>Send Mail To University</button>
                                                        : <span></span>
                                                    }
                                                </>
                                                :
                                                <></>
                                        }
                                        <button className="ms-3" onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button>
                                    </div>
                                    <div className="upload_home_verification_button">
                                        {/* <button className="me-3" onClick={() => markCompleteFour()}>Reviewed</button> */}
                                        {/* <button onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </section>
    )
}

export default memo(UserMilestoneFour)