import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
//----DatePicker (NPM Package)----//
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import H5 from "../../text-components/H5";
import H3 from "../../text-components/H3";


const NotificationMaintenanceSave = () => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const [requiredDocuments, setRequiredDocuments] = useState([]);

    const history = useHistory();

    const location = useLocation();

    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    const [notificationMaintenanceData, setNotificationMaintenanceData] = useState({});
    const [notificationId, setNotificationId] = useState("")

    //****  Input/Select filed disable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);

    const [disablePortalType, setDisablePortalType] = useState(true);

    const [selectedType, setSelectedType] = useState(true);

    const [portalType, setPortalType] = useState(true);


    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }



    function notificationType(type) {
        if (type === "APPLY") {
            setSelectedType(val => val = true);
        }
        else {
            setSelectedType(val => val = false);
        }
    }

    function selectPortalType(e) {
        if (e.target.value === "USER") {
            setPortalType((val) => val = true);
        }
        else {
            setPortalType((val) => val = false);
        }
    }


    function setFileToUpload(event) {
        event.preventDefault();
        let data = ({ file: event.target.files[0], fileName: event.target.files[0].name });
        setRequiredDocuments(data);
        setFieldValue("attachment", data)
    }

    useEffect(async () => {
        showLoading("Loading...");

        dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "NOTIFICATION_MAINTENANCE_UPDATE" });

        // getting designation id From queryParams 
        const queryParams = new URLSearchParams(location.search);

        const notificationIdFromParams = queryParams.get('notificationId');
        setNotificationId(notificationIdFromParams);

        const notificationsFromServer = await adminCommunication.getNotificationById(notificationIdFromParams);

        if (notificationsFromServer.status === "SUCCESS") {
            let notificationsArray = notificationsFromServer.notification;
            // change notificationsArray startDate and endDate format from 2022-02-13T11:30:00.000Z to YYYY/MM/DD format
            notificationsArray.endDate = notificationsArray.endDate.split('T')[0];
            notificationsArray.startDate = notificationsArray.startDate.split('T')[0];
            setNotificationMaintenanceData(value => value = notificationsArray);
            notificationType(notificationsArray.type);
            if (notificationsArray.portal === "USER") {
                setPortalType(true);
            }
            else {
                setPortalType(false);
            }
        } else {
            showModal(notificationsFromServer.message);
        }

        hideLoading();
    }, []);



    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        portal: notificationMaintenanceData.portal,
        type: notificationMaintenanceData.type,
        subject: notificationMaintenanceData.subject,
        startDate: "",
        endDate: "",
        description: notificationMaintenanceData.description,
        attachment: "",
        updateData: false,
    }



    if (notificationMaintenanceData.startDate != '-' && notificationMaintenanceData.endDate != '-') {
        defaultFormValues.startDate = notificationMaintenanceData.startDate;
        defaultFormValues.endDate = notificationMaintenanceData.endDate;
    }
    if (notificationMaintenanceData.attachment != '-') {
        // defaultFormValues.attachment = notificationMaintenanceData.attachment;
    }

    //****Validation for Designation Create form*****/
    const validationSchema = Yup.object().shape({
        portal: Yup.string().required("Select portal"),
        type: Yup.string().required("Select type"),
        // startDate: Yup.string().required("start date is required"),
        // endDate: Yup.string().required("end date is required"),
        subject: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Subject is required"),
        // description: Yup.string().required("Description is required"),
        // attachment: Yup.string().required("Attachment name is required"),
        updateData: Yup.boolean().required("Select update data"),
        attachment: Yup.mixed()
            .when("updateData", {
                is: true,
                then: Yup.mixed().required("please attachment file "),
                otherwise: Yup.mixed().nullable()
            })

    });



    // Destructuring useFormik to get functions to control aspects of form
    // What does Formik do?
    // 1. Handling form events like submit, errors, change in value
    // 2. Fetching values from the form 
    // 3. Support for a validation library on the data fetched. We are using Yup library for validations. 
    // 4. Reset the form
    // 5. Pass initial values to the form
    // 6. Handle errors thrown by the Yup (or any validation library).
    // 7. Reinitialize form values
    // 8. Perform async operations
    const { handleSubmit, handleChange, setFieldValue, values, errors, handleReset } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit
        onSubmit: async (values) => {

            showLoading("Loading...");
            try {
                // Show loading 

                // Send values to the server
                let notificationData = {
                    id: notificationMaintenanceData._id,
                    portal: values.portal,
                    type: values.type,
                    startDate: values.startDate,
                    endDate: values.endDate,
                    subject: values.subject,
                    description: values.description,
                    attachment: "",
                }
                let startDate = values.startDate;
                let endDate = values.endDate;

                if ((Date.parse(endDate) <= Date.parse(startDate))) {
                    showModal("End date should be greater than Start date");
                    hideLoading();
                    return;
                }

                let notificationCreationResponse;

                if (values.type === "APPLY") {
                    notificationCreationResponse = await adminCommunication.updateApplyNotification(notificationData);
                }
                if (values.type === "GENERAL") {

                    let generalNotification = {
                        portal: values.portal,
                        subject: values.subject,
                        updateData: values.updateData,
                        startDate: '-',
                        endDate: '-',
                        createdDate: new Date().toString(),
                        description: values.description,
                        attachment: "",
                        type: "GENERAL"
                    }
                    let formData = new FormData();
                    if (values.updateData === true) {
                        formData.append("attachment", requiredDocuments.file, requiredDocuments.fileName);
                        formData.append('notificationData', JSON.stringify(generalNotification));
                        notificationCreationResponse = await adminCommunication.updateGeneralNotification(formData, notificationMaintenanceData._id, values.updateData);
                    } else {
                        notificationCreationResponse = await adminCommunication.updateGeneralNotification(generalNotification, notificationMaintenanceData._id, values.updateData);
                    }


                }

                console.log(notificationCreationResponse)

                // Handle response from the server
                if (notificationCreationResponse.status === "SUCCESS" || notificationCreationResponse.data.status === "SUCCESS") {
                    handleReset();
                    history.push('/admin/dashboard/notification-maintenance-update');
                }

                // Show response message from server in modal dialog
                showModal(notificationCreationResponse.message || notificationCreationResponse.data.message);


                // Hide spinner 
                hideLoading();
            }
            catch (err) {
                hideLoading();
                showModal(err.message);
            }
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: validationSchema,
    });


    return (

        <div className="mb-5" >
            <section className="create_form create_designation" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000", border: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }}>
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :


                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <H3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Update Notification</H3>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Portal</H5>
                            </label>
                            <div className="col-sm-7">
                                <select name="portal" onChange={handleChange} value={values.portal} className={`form-select ${errors.portal ? "is-invalid" : ""}`} disabled={disabled}>
                                    <option value="">Select</option>
                                    <option value="USER">USER</option>
                                    <option value="FRONT_OFFICE">FRONT_OFFICE</option>
                                    <option value="BACK_OFFICE">BACK_OFFICE</option>
                                    <option value="PUBLIC">PUBLIC</option>
                                </select>
                                <div className="invalid-feedback">{errors.portal}</div>

                            </div>
                        </div>
                        {
                            (portalType === true) ?
                                <div className="mb-3 row">
                                    <label className="col-sm-4 ">
                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Type</H5>
                                    </label>
                                    <div className="col-sm-7">
                                        <select name="type" onChange={(e) => { handleChange(e); selectPortalType(e) }} value={values.type} className={`form-select ${errors.type ? "is-invalid" : ""}`} disabled={disablePortalType}>
                                            <option value="">Select</option>
                                            <option value="GENERAL">GENERAL</option>
                                            <option value="APPLY">APPLY</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.type}</div>

                                    </div>
                                </div>
                                :
                                ""}

                        {
                            (selectedType === true) ?
                                <>

                                    <div className="mb-3 row">
                                        <label className="col-sm-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Start Date</H5>
                                        </label>
                                        <div className="col-sm-7">
                                            <DatePicker selected={(values.startDate && new Date(values.startDate)) || null} onChange={(date) => setFieldValue("startDate", date)} name="startDate" dateFormat="dd/MM/yyyy" value={values.startDate} className={`form-control ${errors.startDate ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors.startDate?.message}</div>

                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label className="col-sm-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >End Date</H5>
                                        </label>
                                        <div className="col-sm-7">
                                            <DatePicker selected={(values.endDate && new Date(values.endDate)) || null} onChange={(date) => setFieldValue("endDate", date)} name="endDate" dateFormat="dd/MM/yyyy" value={values.endDate} className={`form-control ${errors.endDate ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors.endDate?.message}</div>

                                        </div>
                                    </div>

                                </>
                                :
                                ""
                        }

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Subject</H5>
                            </label>
                            <div className="col-sm-7">
                                <input type="text" name="subject" value={values.subject} onChange={handleChange} className={`form-control ${errors.subject ? "is-invalid" : ""}`} disabled={disabled} />
                                <div className="invalid-feedback">{errors.subject}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Description</H5>
                            </label>
                            <div className="col-sm-7">
                                <textarea name="description" value={values.description} onChange={handleChange} className={`form-control ${errors.description ? "is-invalid" : ""}`} disabled={disabled} />
                                <div className="invalid-feedback">{errors.description}</div>
                            </div>
                        </div>
                        {
                            (selectedType === true) ?
                                ""
                                :
                                <>
                                    <div className="mb-3 row">
                                        <label className="col-sm-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Attachment* (.pdf only) </H5>
                                        </label>
                                        <div className="col-sm-2">
                                            <button type="button" onClick={async () => { await adminCommunication.getUplodedNotification(notificationId) }} className="button">
                                                <H5>Preview</H5>
                                            </button>
                                        </div>
                                        <div className="col-sm-5">
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label className="col-sm-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Do you want to update attachment file?</H5>
                                        </label>

                                        <div className="col-4 arrayFetch">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                Yes
                                            </H5>
                                            <input onChange={() => setFieldValue("updateData", true)} id="updateDataTrue" type="radio" value="true" name="updateData" className={`mx-3 ${errors.updateData ? "is-invalid" : ""}`} />
                                            <div className="invalid-feedback">{errors.updateData?.message}</div>
                                        </div>
                                        <div className="col-4 arrayFetch">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                No
                                            </H5>
                                            <input onChange={() => setFieldValue("updateData", false)} id="updateDataFalse" type="radio" value="false" name="updateData" className={`mx-3 ${errors.updateData ? "is-invalid" : ""}`} />
                                            <div className="invalid-feedback">{errors.updateData?.message}</div>
                                        </div>
                                    </div>
                                    {values.updateData ?
                                        <>
                                            <div className="mb-3 row">
                                                <label className="col-sm-4 ">
                                                </label>
                                                <div className="col-sm-7">
                                                    <input type="file" name="attachment" onChange={(e) => setFileToUpload(e)} value={values.attachment?.filename} className={`form-control ${errors.attachment ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.attachment}</div>
                                                </div>
                                            </div>
                                        </>

                                        : null
                                    }



                                </>

                        }

                        <button type="button" onClick={() => {
                            handleSubmit()
                        }} className="button" >
                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H5>Update</H5>}
                        </button>
                    </form>
                }
            </section>
        </div>

    );
}
export default NotificationMaintenanceSave;