import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { communication } from '../communications/communication';
import LoadingForms from "../shared-components/LoadingForms";
import H4 from "../text-components/H4";
import { removeUnderscoreFromText } from '../utlis/helper';

const UserMilestone1Form = () => {
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);

    // spinner on loading 
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const history = useHistory();
    const location = useLocation();

    const [milestoneDocument, setMilestoneDocument] = useState({});


    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const downloadDocumentFromServer = async (attachment) => {
        if (attachment !== "") {
            let result = await communication.getMilestoneDocumentfromServer(attachment);
            return result;
        }
    }

    const CloseUserMilestone2 = () => {
        history.push("/user/doc-submission");
    }

    useEffect(() => {


        // getting committee id by queryParams
        const queryParams = new URLSearchParams(location.search);
        const applicationId = queryParams.get('applicationId');

        async function getMileStoneDocument(applicationId) {
            showLoading("Loading...");
            try {
                const result = await communication.getUserMilestone(applicationId, "MILESTONE_TWO");
                if (result.status === "SUCCESS") {
                    setMilestoneDocument(result.milestoneDetails[result.milestoneDetails.length - 1]);
                } else {
                    showModal(result.message);
                }
                hideLoading();
            } catch (error) {
                showModal(error?.message);
                hideLoading();
            }

        }

        getMileStoneDocument(applicationId);

    }, []);

    return (
        <section className="application_flow_big" >

            <div className="user_milestone_main">
                {
                    isLoading?.value ?
                        <LoadingForms message={isLoading?.message} />
                        :
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="user_milestone">
                                        <div className="user_milestone_header">
                                            <H4 style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Home Verification</H4>
                                        </div>
                                        <div className="user_milestone_body">
                                            <div className="row">
                                                <div className="col-12">
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>{milestoneDocument?.name}</H4>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-12 pe-0">
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Application ID : {(!!milestoneDocument?.application) ? milestoneDocument?.application[milestoneDocument?.application?.length - 1]?.shortApplicationId : ""}</H4>
                                                </div>
                                                <div className="col-12 pe-0 mt-4">
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Status : {removeUnderscoreFromText(milestoneDocument?.userStatus)}</H4>
                                                </div>
                                            </div>

                                            {
                                                milestoneDocument?.homeVerificationCertificateUrl && milestoneDocument?.userStatus === "HV_COMPLETED" ?
                                                    <div className="row mt-4">
                                                        <div className="col-12 pe-0 user_milestone_attached_file">
                                                            <button onClick={() => downloadDocumentFromServer(milestoneDocument?.homeVerificationCertificateUrl)}> Preview </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    ""
                                            }

                                            <div className="row mt-4">
                                                <div className="col-12" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                    <button className="ms-4" onClick={() => CloseUserMilestone2()}>Cancel</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </section>
    );
}
export default UserMilestone1Form;