import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import H4 from "../../text-components/H4";
import P from "../../text-components/P";
import { backOfficeCommunication } from "../../communications/back-office-communication"
import { useEffect, useState } from "react";
import LoadingForms from "../../shared-components/LoadingForms";
import ReactPaginate from "react-paginate";

const Refund = () => {

    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const history = new useHistory();
    const [refundApplications, setRefundApplications] = useState([]);
    const [filteredRefundApplication, setFilteredRefundApplication] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchRefundListBySearch(searchValue, selectedPage + 1);
        } else {
            fetchData(selectedPage + 1);
        }
    }
    //Search Filter
    const searchFilter = async (searchString) => {
        // console.log(searchString);
        if (searchString) {
            fetchRefundListBySearch(searchString, currentPage + 1);
        } else {
            const results = await backOfficeCommunication.getAllRefundApplications()
            if (results?.status === "SUCCESS") {
                const applications = results?.refunds;
                setRefundApplications(applications?.map((data) => {
                    return ({
                        refundId: data?._id,
                        applicationId: data?.shortApplicationId,
                        status: data?.status,
                        name: data?.studentName,
                        comments: data?.comment
                    })
                }));

                setFilteredRefundApplication(applications?.map((data) => {
                    return ({
                        refundId: data?._id,
                        applicationId: data?.shortApplicationId,
                        status: data?.status,
                        name: data?.studentName,
                        comments: data?.comment
                    })
                }));
                setPageCount(results?.status?.totalPages);
            } else if (results.status === "ERROR" || results.jwt === null) {
                history.push('/login/back-office');
            } else {
                setRefundApplications([]);
                setFilteredRefundApplication([]);
                setPageCount(0);
            }
        }
    }

    //get refund List By Search
    const fetchRefundListBySearch = async (searchString, page) => {
        try {
            const results = await backOfficeCommunication.getRefundListBySearch(searchString, page)
            // console.log("result", searchString, results);
            if (results?.data?.status === "SUCCESS") {
                const applications = results?.data?.refunds;
                setRefundApplications(applications?.map((data) => {
                    return ({
                        refundId: data?._id,
                        applicationId: data?.shortApplicationId,
                        status: data?.status,
                        name: data?.studentName,
                        comments: data?.comment
                    })
                }));

                setFilteredRefundApplication(applications?.map((data) => {
                    return ({
                        refundId: data?._id,
                        applicationId: data?.shortApplicationId,
                        status: data?.status,
                        name: data?.studentName,
                        comments: data?.comment
                    })
                }));
                setPageCount(results?.data?.totalPages)
            } else if (results.status === "ERROR" || results.jwt === null) {
                history.push('/login/back-office');
            } else {
                setRefundApplications([]);
                setFilteredRefundApplication([]);
                setPageCount(0)
            }
        } catch (error) {

        }
    }

    //get Refund List on Initial Load
    async function fetchData(page) {

        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("refund")) {
            dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "REFUND" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
        }

        showLoading("Loading...");

        const results = await backOfficeCommunication.getAllRefundApplications(page);
        // console.log("inside code", results);

        if (results?.status === "SUCCESS") {

            const applications = results?.refunds;

            setRefundApplications(applications?.map((data) => {
                return ({
                    refundId: data?._id,
                    applicationId: data?.shortApplicationId,
                    status: data?.status,
                    name: data?.studentName,
                    comments: data?.comment
                })
            }));

            setFilteredRefundApplication(applications?.map((data) => {
                return ({
                    refundId: data?._id,
                    applicationId: data?.shortApplicationId,
                    status: data?.status,
                    name: data?.studentName,
                    comments: data?.comment
                })
            }));
            setPageCount(results?.totalPages)
        }
        else if (results.status === "ERROR" || results.jwt === null) {
        }
        else {
            showModal(results.message);
        }
        hideLoading();
    }
    useEffect(() => {
        fetchData();
    }, [])

    const getRemittanceHandler = async (id) => {
        history.push(`/back-office/dashboard/refundpreview?refundId=${id}`);
    }


    function onChangeFilterText(event, searchFor) {

        if (searchFor === "APPLICATION_ID") {
            const filterData = refundApplications.filter((ele) => ele.applicationId.search(event.target.value) === -1 ? false : true);
            setFilteredRefundApplication(filterData);
        }

        if (searchFor === "NAME") {
            const filterData = refundApplications.filter((ele) => ele.name.search(event.target.value) === -1 ? false : true);
            setFilteredRefundApplication(filterData);
        }

        if (searchFor === "STATUS") {
            const filterData = refundApplications.filter((ele) => ele.status.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
            setFilteredRefundApplication(filterData);
        }
    }

    return (
        <section className="dashboard_background">
            {isLoading.value ? <LoadingForms message={isLoading.message} />
                : <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="dashboard_back_office">
                                <div className="row search_wrapper">
                                    <div className="col-lg-4 col-md-4 ps-0">
                                        <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Application ID, Name, Status..." />
                                    </div>
                                    <div className="col-lg-8 col-md-8 pe-0 pagination_wrapper">
                                        {pageCount > 1 &&
                                            <ReactPaginate
                                                previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                                nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                forcePage={currentPage}
                                                activeClassName={"active"} />
                                        }
                                    </div>
                                </div>
                                {/*-----Remittance search box----*/}
                                {/* <div className="back_office_search_box">
                                    <div className="row">
                                        <div className="col-1"></div>
                                        <div className="col-3 search_box">
                                            <input type="search" className="form-control" placeholder="Application Id" onChange={(event) => onChangeFilterText(event, "APPLICATION_ID")} />
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-3 search_box">
                                            <input type="search" className="form-control" placeholder="Name" onChange={(event) => onChangeFilterText(event, "NAME")} />
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-2 search_box">
                                            <input type="search" className="form-control" placeholder="Status" onChange={(event) => onChangeFilterText(event, "STATUS")} />
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-3 search_box">
                                        </div>
                                    </div>
                                </div> */}
                                {/*----- card header section---*/}
                                <div className="dashboard_view_data_heading">
                                    <div className="row m-0">
                                        <div className="col-1 p-0 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Serial No.</H4></span>
                                        </div>
                                        <div className="col-2 p-0 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Application ID</H4></span>
                                        </div>
                                        <div className="col-3 p-0 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Name</H4></span>
                                        </div>
                                        <div className="col-3 p-0 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Status</H4></span>
                                        </div>
                                        <div className="col-3 p-0">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">comment</H4></span>
                                        </div>
                                    </div>
                                </div>

                                {
                                    filteredRefundApplication?.length > 0 ?

                                        <>
                                            {
                                                filteredRefundApplication?.map((application, index) => {
                                                    return (
                                                        <div className="dashboard_view_data_main" key={index}>
                                                            <div className="dashboard_view_data">
                                                                <div className="row m-0">
                                                                    <div className="col-1 p-0 view_dashboard text_align">
                                                                        <span>
                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{limit * currentPage + (index + 1)}</P>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-2 p-0 view_dashboard text_align">
                                                                        <span>
                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a onClick={() => { getRemittanceHandler(application?.refundId) }}>{application?.applicationId}</a></P>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-3 p-0 view_dashboard text_align">
                                                                        <span>
                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{application?.name}</P>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-3 p-0 view_dashboard text_align">
                                                                        <span ><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{application?.status}</P></span>
                                                                    </div>
                                                                    <div className="col-3 p-0 text_align">
                                                                        <span>
                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                                {!!application?.comments[application?.comments?.length - 1] ? application?.comments[application?.comments?.length - 1] : "No Comment"}
                                                                            </P>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {application?.status === "NEW" ?
                                                                    <div className="new_badge">
                                                                        <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">New</P>
                                                                    </div>
                                                                    : <span></span>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                        </>
                                        :
                                        <div className="col-12 text-center view_download_dashboard">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Records not available.</H4>
                                        </div>
                                }

                            </div>

                        </div>

                    </div>
                </div>
            }
        </section>
    );

}
export default React.memo(Refund);