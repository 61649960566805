import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import H5 from "../../text-components/H5";
import { confirm } from "react-confirm-box";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";

const UsersMaintenanceUpdate = () => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [allUsersMaintenanceData, setAllUsersMaintenanceData] = useState([]);
    const [idsOfUser, setIdsOfUser] = useState([]);
    const history = useHistory();
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchUsersBySearch(searchValue, selectedPage + 1);
        } else {
            fetchAllUsers(selectedPage + 1);
        }
    }
//Search Filter
const searchFilter = async (searchString) => {
    // console.log("searchString",searchString);
    if (searchString) {
        fetchUsersBySearch(searchString, currentPage + 1);
    } else {
        const usersFromServer = await adminCommunication.getAllUser();
        // console.log("usersFromServer 1",usersFromServer);
        if (usersFromServer.status === "SUCCESS") {
            setAllUsersMaintenanceData(usersFromServer?.users);
            setPageCount(usersFromServer?.totalPages);
        } else {
            setAllUsersMaintenanceData([]);
            setPageCount(0);
        }
    }
}

//get Users By Search
const fetchUsersBySearch = async (searchString, page) => {
    try {
        const responseFromServer = await adminCommunication.getUsersBySearch(searchString, page);
        console.log("responseFromServer",responseFromServer);
        if (responseFromServer?.data?.status === "SUCCESS") {
           
            setAllUsersMaintenanceData(responseFromServer?.data?.users);
            setPageCount(responseFromServer?.data?.totalPages);
        } else if (responseFromServer?.data?.status === "JWT_INVALID") {
            history.push("/admin/login");
            showModal(responseFromServer?.data?.message);
        } else {
            setAllUsersMaintenanceData([]);
            setPageCount(0);
        }
    } catch (error) {
        showModal(error.message);
    }
}

//get All Users on Initial Load
const fetchAllUsers = async (page) => {
    try {
        showLoading("Loading...");
        dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "USERS_MAINTENANCE_UPDATE" });
        const usersFromServer = await adminCommunication.getAllUser(page);
        if (usersFromServer.status === "SUCCESS") {
            setAllUsersMaintenanceData(usersFromServer.users);
            setPageCount(usersFromServer?.totalPages);
            // console.log("usersFromServer 2",usersFromServer.users);
        } else {
            showModal(usersFromServer.message);
            setAllUsersMaintenanceData([]);
            setPageCount(0);
        }
        
                hideLoading();
            } catch (error) {
                showModal(error.message);
            }
        }
       


        useEffect(async () => {
            fetchAllUsers();
    }, []);
    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    // set ids in to state
    const handleOnChange = (e) => {
        let checked = e.target.checked;
        let id = e.target.value;
        if (checked) {
            setIdsOfUser(() => [...idsOfUser, id]);
        } else {
            // to remove from local storage
            setIdsOfUser(
                idsOfUser.filter((Id) => Id !== id)
            );
        }
        return;
    }


    // delete User by id
    const deleteOfficeUser = async (options) => {
        if (idsOfUser.length !== 0) {
            const confirmBox = await confirm(`Are you sure , do you want to delete this office user . If you deleted this office user,all the related  committee will be deleted.`, options);
            if (confirmBox === true) {
                showLoading("Loading...");
                const deleteOfficeUserByIdData = await adminCommunication.deleteOfficeUserById(idsOfUser);
                if (deleteOfficeUserByIdData.status === "SUCCESS") {
                    dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "USERS_MAINTENANCE_UPDATE" });
                    // calling refreshPage function after 4 sec to show model before refreshing the page
                    setTimeout(() => {
                        history.push(`/admin/dashboard/users-maintenance-view`);
                    }, 1000);
                }
                showModal(deleteOfficeUserByIdData.message);
            }

        }
        else {
            showModal("Please select atleast one record");
        }
        hideLoading();
        return;
    }
    // redirect to committee maintenance save according to Department id
    const officeUserEdit = (userId) => {
        history.push(`/admin/dashboard/user-maintenance-save?userId=${userId}`);
        return;
    }

    return (
        <>
            <section className="dashboard_background_update" >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :

                    <div className="dashbord_main">
                    <div className="row search_wrapper">
                            <div className="col-lg-4 col-md-4 ps-0">
                                <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By User Name, Department..." />
                            </div>
                            <div className="col-lg-8 col-md-8 pagination_outer_wrapper">
                                <div className="pagination_inner_wrapper">
                                    {pageCount > 1 &&
                                        <ReactPaginate
                                            previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            forcePage={currentPage}
                                            activeClassName={"active"} />
                                    }
                                </div>
                            </div>
                        </div>
                        {/*-----Grievance card header section---*/}
                        <div className="dashboard_view_data_heading">
                            <div className="row m-0">
                                <div className="col-1 p-0 view_dashboard">
                                    <span><H5>Serial No.</H5></span>
                                </div>
                                <div className="col-3 p-0 view_dashboard">
                                    <span><H5>User Name</H5></span>
                                </div>
                                <div className="col-3 p-0 view_dashboard">
                                    <span><H5>Department</H5></span>
                                </div>
                                <div className="col-3 p-0 view_dashboard">
                                    <span><H5>Designation</H5></span>
                                </div>
                                <div className="col-2 p-0 ">
                                    <span><H5>Mobile No.</H5></span>
                                </div>
                            </div>
                        </div>

                        {allUsersMaintenanceData?.length > 0 ?
                            <div>
                                {/*-----Grievance card data section---*/}
                                {allUsersMaintenanceData?.map((users, index) => {
                                    const { _id, fullName, departmentName, designationName, phoneNumber, userId, deleteCount } = users;
                                    return (
                                        <div key={index}>
                                            
                                                <div className="dashboard_view_data_main">
                                                    <div className="dashboard_view_data">
                                                        <div className="row m-0">
                                                            <div className="col-1 p-0 view_dashboard sr-no text_align">
                                                                <span>
                                                                    <input type="checkbox" value={userId} onClick={(e) => handleOnChange(e)} />
                                                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {limit * currentPage + (index + 1)}
                                                                    </H5>
                                                                </span>
                                                            </div>
                                                            <div className="col-3 p-0 view_dashboard text_align">
                                                                <span>
                                                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                        <a onClick={() => officeUserEdit(userId)}>
                                                                            {fullName}
                                                                        </a>
                                                                    </H5>
                                                                </span>
                                                            </div>
                                                            <div className="col-3 p-0 view_dashboard text_align">
                                                                <span>
                                                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                        {departmentName}
                                                                    </H5>
                                                                </span>
                                                            </div>
                                                            <div className="col-3 p-0 view_dashboard text_align">
                                                                <span>
                                                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                        {designationName}
                                                                    </H5>
                                                                </span>
                                                            </div>
                                                            <div className="col-2 p-0 text_align">
                                                                <span>
                                                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                        {phoneNumber}
                                                                    </H5>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                        </div>

                                    );
                                })}
                            </div>
                            :
                            <div className="col-12 text-center view_download_dashboard">
                                <h6>Records not available.</h6>
                            </div>
                        }

                    </div>
                }
            </section>
            <section className="mb-3">
                <div className="row">
                    <div className="col-2 button_div">
                        <div className="dashboard_delete_button">
                            <button type="button" className="btn" onClick={() => deleteOfficeUser()}>
                                {isLoading.value ? <img src="/assets/images/gif/loading-circle.gif" style={{ width: "10px", height: "10px" }} /> : <H5 >Delete</H5>}
                            </button>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default UsersMaintenanceUpdate;