import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import H1 from "../text-components/H1";
import H3 from "../text-components/H3";
import { communication } from "../communications/communication"
import LoadingForms from "../shared-components/LoadingForms";
import H4 from "../text-components/H4";

const ResetPassword = () => {
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    // const query = new URLSearchParams(this.props.location.search);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const password = useRef();
    const confirmPassword = useRef();


    const history = useHistory();
    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }
    // Spinner on loading
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    const resetPasswordHandler = async () => {
        showLoading("Loading ...");

        const code = queryParams.get('code');
        const token = queryParams.get('token');
        const newPassword = password.current.value;
        const confPassword = confirmPassword.current.value;

        if (newPassword === "" || confPassword === "") {
            showModal("Enter Password & confirm password");
            hideLoading()
        } else if (newPassword.length <= 8) {
            showModal(" Password must be at least 8 characters");
            hideLoading()
        } else if (newPassword !== confPassword) {
            showModal(" Password & confirm password mismatch");
            hideLoading()
        } else {
            const checkToken = await communication.checkForgetPasswordCode(newPassword, code, token)
            if (checkToken.status === "SUCCESS") {
                showModal(checkToken.message);
                hideLoading();
                history.push("/login");
            } else {
                showModal(checkToken.message);
                hideLoading();
            }
        }


    }

    // const validationSchema = Yup.object().shape({
    //     password: Yup.string().matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    //         "Password must contain at least 8 characters, one lowercase, one uppercase, one number and one special case character").required("Password is required"),
    //     confirmPassword: Yup.string()
    //         .oneOf([Yup.ref('password'), null], 'Passwords must match').required("Password is required"),

    // });

    // const formOptions = { resolver: yupResolver(validationSchema) };
    // // get functions to build with useForm() hook
    // const { register, handleSubmit, reset, formState } = useForm(formOptions);
    // const { errors } = formState;

    // const onSubmit = async (data) => {
    //     showLoading();
    //     console.log(data);
    //     setResetPasswordFormSubmission((value) => {
    //         return { ...value, ...{ status: "LOADING" } };
    //     });

    //     //   let regstrationResponse = await communication.registrationUser(data);

    //     // setResetPasswordFormSubmission(value => {
    //     //     let updatedValues = {};
    //     //     updatedValues.status = regstrationResponse.status;
    //     //     updatedValues.message = regstrationResponse.message;
    //     //     return { ...value, ...updatedValues };
    //     // });

    //     setResetPasswordFormSubmission(value => {
    //         return { ...value, ...{ message: errors } };
    //     });
    // }

    return (
        // <div style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
        //     <section className="reset_password_section" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
        //         <div className="reset_password_form" onSubmit={handleSubmit(onSubmit)} style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000", border: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }}>
        //             <div><H1 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${30 * fontSizeMultiplier / 100}px` }}>Reset Password</H1></div>

        //             {/*----password field----*/}
        //             <div className="mb-3 row">
        //                 <label style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }} className="col-sm-4 col-form-label"><H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">Password</H3></label>
        //                 <div className="col-sm-7 password_field">
        //                     <input type={showPassword} name="password" {...register("password")} className={`form-control ${errors.password ? "is-invalid" : ""}`} />
        //                     <div className="invalid-feedback">{errors.password?.message}</div>

        //                     {showPassword === "password" ?
        //                         <span onClick={() => setShowPassword("text")}> <i style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000000" : "#FFFFFF" }} className="fa fa-eye-slash btn" aria-hidden="true"></i></span>
        //                         :
        //                         <span className="showPass" onClick={() => setShowPassword("password")}><i style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000000" : "#FFFFFF" }} className="fa fa-eye" aria-hidden="true"></i></span>
        //                     }
        //                 </div>
        //             </div>

        //             {/*----Re-password field----*/}
        //             <div className="mb-3 row">
        //                 <label style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }} className="col-sm-4 col-form-label"><H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">Confirm Password</H3></label>
        //                 <div className="col-sm-7">
        //                     <input type="password" name="confirmPassword" {...register("confirmPassword")} className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`} />
        //                     <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
        //                 </div>
        //             </div>

        //             <button onClick={() => handleSubmit()} type="submit" style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }} className="button">{isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H3>Reset</H3>}</button>

        //         </div>
        //     </section>
        // </div>

        <div style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
            {/* <HomeScreenNav /> */}
            <section className="forget_password" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    : <div className="row m-0">
                        <div className="col-12">
                            <div className="forget_password_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000", border: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }}>
                                <H1 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${30 * fontSizeMultiplier / 100}px` }}>New Password</H1>
                                <div className="input_field_div1">
                                    <label style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}><H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">Password</H3></label>
                                    <div className="input_field1">
                                        <input className="form-control" type="password" ref={password} required />
                                        {/* <i className="fa fa-user" aria-hidden="true"></i> */}
                                    </div>
                                    <label style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}><H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">Confirm Password</H3></label>
                                    <div className="input_field1">
                                        <input className="form-control" type="password" ref={confirmPassword} required />
                                        {/* <i className="fa fa-user" aria-hidden="true"></i> */}
                                    </div>
                                    <button style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }} className="button" onClick={resetPasswordHandler} >Change Password</button>
                                </div>

                            </div>
                        </div>
                    </div>
                }

            </section>
        </div>


    );
}
export default ResetPassword;