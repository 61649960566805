import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import H4 from "../../text-components/H4";
import LoadingForms from "../../shared-components/LoadingForms";
import { backOfficeCommunication } from "../../communications/back-office-communication";
import { removeUnderscoreFromText } from '../../utlis/helper'
import ReactPaginate from "react-paginate";
import { set } from "react-hook-form";

const BackOfficeRemittance = () => {

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [allRemittanceData, setAllRemittanceData] = useState([]);
    const [filteredMilestoneData, setFilteredMilestoneData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchRemittanceListBySearch(searchValue, selectedPage + 1);
        } else {
            get(selectedPage + 1);
        }
    }

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchRemittanceListBySearch(searchString, currentPage + 1);
        }else{
            const allRemittanceFromServer =await backOfficeCommunication.getAllRemittances();
            // console.log("inside search",allRemittanceFromServer);
            if(allRemittanceFromServer?.status === "SUCCESS"){
                setAllRemittanceData(allRemittanceFromServer?.remittances);
                setFilteredMilestoneData(allRemittanceData?.remittances);
                setPageCount(allRemittanceData?.totalPages);
            }else if (allRemittanceFromServer?.status === "ERROR" || allRemittanceFromServer?.jwt === null){
                history.push(`/back-office/login`);
            }else{
                setAllRemittanceData([]);
                setFilteredMilestoneData([]);
                setPageCount(0)
            }
        }
    }
    //get Merit List By Search
    const fetchRemittanceListBySearch = async (searchString, page) => {
        // console.log(searchString);
        try {
            const allRemittanceFromServer = await backOfficeCommunication.getRemittanceListBySearch(searchString, page) 
            if(allRemittanceFromServer?.data?.status === "SUCCESS"){
                setAllRemittanceData(allRemittanceFromServer?.data?.remittances);
                setFilteredMilestoneData(allRemittanceFromServer?.data?.remittances);
                setPageCount(allRemittanceFromServer?.data?.remittances);
            }else if (allRemittanceFromServer?.data?.status === "ERROR" || allRemittanceFromServer?.jwt === null){
                history.push(`/back-office/login`);
            }else{
                setAllRemittanceData([]);
                setFilteredMilestoneData([]);
                setPageCount(0)
            }
        } catch (error) {
            
        }
    }

    //get All Remittances List on Initial Load
    async function get(page) {
        try {

            let fullUrl = window.location.href;
            let splitUrlArray = fullUrl.split('/');
            if (splitUrlArray.includes("remittance")) {
                dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "REMITTANCE" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
            }
            showLoading("Loading...");
            const allRemittanceFromServer = await backOfficeCommunication.getAllRemittances(page);
            // console.log("indide code",allRemittanceFromServer);
            if (allRemittanceFromServer?.status === "SUCCESS") {
                let remittanceArray = allRemittanceFromServer?.remittances;
                setAllRemittanceData(remittanceArray);
                setFilteredMilestoneData(remittanceArray)
                setPageCount(allRemittanceFromServer?.totalPages);
            } else if (allRemittanceFromServer?.status === "ERROR" || allRemittanceFromServer?.jwt === null) {
                history.push('/back-office/login')
            }
            else {
                setAllRemittanceData([]);
                setFilteredMilestoneData([]);
                setPageCount(0)
                // showModal(allRemittanceFromServer?.message);
            }

            hideLoading();
        }
        catch (error) {
            showModal(error?.message);
            hideLoading();
            return;
        }
    }
    useEffect(() => {
        get();
    }, []);

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    function showRemittancePreview(_id) {
        history.push(`/back-office/dashboard/remittance-preview?remittanceId=${_id}`);
    }


    // function onChangeFilterText(event, searchFor) {

    //     if (searchFor === "APPLICATION_ID") {
    //         const filterData = allRemittanceData.filter((ele) => ele.shortApplicationId.search(event.target.value) === -1 ? false : true);
    //         setFilteredMilestoneData(filterData);
    //     }

    //     if (searchFor === "NAME") {
    //         const filterData = allRemittanceData.filter((ele) => {
    //             const fullname = ele.studentName.toLowerCase();
    //             return fullname.search(event.target.value.toLowerCase()) === -1 ? false : true;
    //         });
    //         setFilteredMilestoneData(filterData);
    //     }

    //     if (searchFor === "STATUS") {
    //         const filterData = allRemittanceData.filter((ele) => ele.backOfficeStatus.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
    //         setFilteredMilestoneData(filterData);
    //     }
    // }


    return (
        <section className="dashboard_background">
            {isLoading?.value ?
                <LoadingForms message={isLoading?.message} />
                :

                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="dashboard_back_office">
                                <div className="row search_wrapper">
                                    <div className="col-lg-4 col-md-4 ps-0">
                                        <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Application ID, Name, Status..." />
                                    </div>
                                    <div className="col-lg-8 col-md-8 pe-0 pagination_wrapper">
                                    {pageCount > 1 &&    
                                    <ReactPaginate
                                            previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            forcePage={currentPage}
                                            activeClassName={"active"} />
                                        }
                                    </div>
                                </div>
                                {/*-----Remittance search box----*/}
                                {/* <div className="back_office_search_box">
                                    <div className="row">
                                        <div className="col-1">
                                        </div>
                                        <div className="col-3 search_box">
                                            <input type="search" className="form-control" placeholder="Application Id" onChange={(event) => onChangeFilterText(event, "APPLICATION_ID")} />
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-4 search_box">
                                            <input type="search" className="form-control" placeholder="Name" onChange={(event) => onChangeFilterText(event, "NAME")} />
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-2 search_box">
                                            <input type="search" className="form-control" placeholder="Status" onChange={(event) => onChangeFilterText(event, "STATUS")} />
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-2 search_box">
                                        </div>
                                    </div>
                                </div> */}
                                {/*----- card header section---*/}
                                <div className="dashboard_view_data_heading">
                                    <div className="row m-0">
                                        <div className="col-1 p-0 view_dashboard">
                                            <span><h3 style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }}>Serial No.</h3></span>
                                        </div>
                                        <div className="col-3 p-0 view_dashboard ps-3" style={{ textAlign: "left" }}>
                                            <span><h3 style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }}>Application ID</h3></span>
                                        </div>
                                        <div className="col-4 p-0 ps-3 view_dashboard" style={{ textAlign: "left" }}>
                                            <span><h3 style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }}>Name</h3></span>
                                        </div>
                                        <div className="col-2 p-0 ps-3 view_dashboard" style={{ textAlign: "left" }}>
                                            <span><h3 style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }}>Status</h3></span>
                                        </div>
                                        <div className="col-2 p-0 ">
                                            <span><h3 style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }}>Date</h3></span>
                                        </div>
                                    </div>
                                </div>
                                {
                                    filteredMilestoneData?.length > 0 ?
                                        <>

                                            {
                                                filteredMilestoneData?.map((data, index) => {

                                                    const { _id, studentName, backOfficeStatus, createdDate } = data;

                                                    return (
                                                        <div className="dashboard_view_data_main" key={index}>
                                                            <div className="dashboard_view_data" style={{ position: "relative" }}>
                                                                <div className="row m-0">
                                                                    <div className="col-1 p-0 view_dashboard">
                                                                        <span>
                                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>{limit * currentPage + (index + 1)}</H4>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-3 p-0 ps-3 view_dashboard" style={{ textAlign: "left" }}>
                                                                        <span>
                                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}><a onClick={() => showRemittancePreview(_id)}>{data?.shortApplicationId}</a></H4>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-4 p-0 ps-3 view_dashboard" style={{ textAlign: "left" }}>
                                                                        <span>
                                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}><a onClick={() => showRemittancePreview(_id)}>{studentName}</a></H4>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-2 p-0 ps-3 view_dashboard" style={{ textAlign: "left" }}>
                                                                        <span>
                                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>{removeUnderscoreFromText(backOfficeStatus)}</H4>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-2 p-0" >
                                                                        <span ><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>
                                                                            {new Date(createdDate).getDate()}-{new Date(createdDate).getMonth() + 1}-{new Date(createdDate).getFullYear()}
                                                                        </H4></span>
                                                                    </div>
                                                                </div>
                                                                {backOfficeStatus === "NEW" ?
                                                                    <div className="new_badge">
                                                                        <p>New</p>
                                                                    </div>
                                                                    : <span></span>
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </>
                                        :
                                        <div className="col-12 text-center view_download_dashboard">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Record not available.</H4>
                                        </div>
                                }

                            </div>

                        </div>

                    </div>
                </div>
            }
        </section>
    );

}
export default memo(BackOfficeRemittance);