import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useFormik } from 'formik';
import { communication } from "../communications/communication";
import LoadingForms from "../shared-components/LoadingForms";
import H4 from "../text-components/H4";
import H2 from "../text-components/H2";
import H5 from "../text-components/H5";
import P from "../text-components/P";

const Grievance = () => {

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const dispatch = useDispatch();
    const history = useHistory();
    const [showGravienceModal, setShowGravienceModal] = useState(false);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [userGrievanceData, setUserGrievanceData] = useState([]);

    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    async function initialLoad() {
        showLoading("Loading...");

        const userGrievanceFromServer = await communication.getAllUserGrievance();
        if (userGrievanceFromServer.status === "SUCCESS") {
            let userGrievanceArray = userGrievanceFromServer.tickets;
            setUserGrievanceData(userGrievanceArray);
        }
        else {
            showModal(userGrievanceFromServer.message);
        }

        hideLoading();
    }

    useEffect(() => {
        initialLoad();
    }, []);


    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        title: "",
        description: "",
    }

    //****Validation for Designation Create form*****/
    let yupValidationSchema = Yup.object().shape({
        title: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Subject is required"),
        description: Yup.string().required("Query is required"),
    });

    // Destructuring useFormik to get functions to control aspects of form
    // What does Formik do?
    // 1. Handling form events like submit, errors, change in value
    // 2. Fetching values from the form 
    // 3. Support for a validation library on the data fetched. We are using Yup library for validations. 
    // 4. Reset the form
    // 5. Pass initial values to the form
    // 6. Handle errors thrown by the Yup (or any validation library).
    // 7. Reinitialize form values
    // 8. Perform async operations
    const { handleSubmit, handleChange, values, errors, handleReset } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit
        onSubmit: async (values) => {
            // Show loading 
            showLoading("Loading...");

            // Send values to the server
            const grievance = {
                title: values.title,
                description: values.description
            }

            const grievanceCreationResponse = await communication.createGrievance(grievance);


            // Handle response from the server
            if (grievanceCreationResponse.status === "SUCCESS") {
                handleReset();
                // history.push('/user/grievance');
                // window.location.reload();
                setShowGravienceModal(false)
                initialLoad();
            }

            // Show response message from server in modal dialog
            showModal(grievanceCreationResponse.message);

            // Hide spinner 
            hideLoading();
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: yupValidationSchema,
    });


    useEffect(() => {
        // to show active tab
        let fullUrl = window.location.href;
        let splitGrievanceUrl = fullUrl.split('/');
        if (splitGrievanceUrl.includes("grievance")) {
            dispatch({ type: "SHOW_ACTIVE_APPLICATION_PROCESS_TAB", payload: "GRIEVANCE" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_APPLICATION_PROCESS_TAB" });
        }
    }, []);

    function ShowGravienceCommentBox(ticketId) {
        history.push(`/user/grievance-user-reply?&ticketId=${ticketId}`);
    }
    return (
        <>
            <section className="application_flow_big" >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :
                    <>
                        <div className="grievance_btn">
                            <button onClick={() => setShowGravienceModal(true)}>Raise Grievance</button>
                        </div>

                        {/*----Grievance modal popup start-----*/}
                        {showGravienceModal ?
                            <div className="grievance_modal">
                                <div className="row m-0">
                                    <div className="col-12">
                                        <form style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
                                            <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Raise Grievance</H2>

                                            <div className="row mb-3">
                                                <label className="col-4 col-form-label"><H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Subject</H5></label>
                                                <div className="col-8">
                                                    <input type="text" name="title" value={values.title} onChange={handleChange} className={`form-control ${errors.title ? "is-invalid" : ""}`} />
                                                    <div className="invalid-feedback">{errors.title}</div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <label><H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Write Your Query</H5></label>
                                                    <div className="textarea">
                                                        <textarea name="description" value={values.description} onChange={handleChange} className={`form-control ${errors.description ? "is-invalid" : ""}`} />
                                                        <div className="invalid-feedback">{errors.description}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="grievance_form_btn">
                                                <button onClick={() => setShowGravienceModal(false)}>
                                                    {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H5>Cancel</H5>}
                                                </button>
                                                <button type="button" onClick={() => { handleSubmit() }} className="button" >
                                                    {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H5>Create</H5>}
                                                </button>

                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            : null
                        }
                        {/*----Grievance modal popup end-----*/}
                        {/*-----Grievance card header section---*/}
                        <div className="tabel_heading">
                            <div className="row m-0">
                                <div className="col-2 p-0 data">
                                    <span><H4>Serial No.</H4></span>
                                </div>
                                <div className="col-4 p-0 data">
                                    <span><H4>Grienvance</H4></span>
                                </div>
                                <div className="col-3 p-0 data">
                                    <span> <H4>Status</H4></span>
                                </div>
                                <div className="col-3 p-0 align" style={{ justifyContent: "center" }}>
                                    <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Date</H4></span>
                                </div>
                            </div>
                        </div>
                        {userGrievanceData.length > 0 ?
                            <>
                                <div className="grievance_main">

                                    {/*-----Grievance card data section---*/}
                                    {userGrievanceData.map((tickets, index) => {
                                        const { title, ticketId, _id, userStatus, creationDate } = tickets;
                                        return (
                                            <div key={_id}>
                                                <div className="tabel_data">
                                                    <div className="row m-0">
                                                        <div className="col-2 p-0 data">
                                                            <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>{index + 1}</P></span>
                                                        </div>
                                                        <div className="col-4 p-0 data">
                                                            <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}><a onClick={() => ShowGravienceCommentBox(ticketId)}>{title}</a></P></span>
                                                        </div>
                                                        <div className="col-3 p-0 data">
                                                            <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}><a onClick={() => ShowGravienceCommentBox(ticketId)}>{userStatus}</a></P></span>
                                                        </div>
                                                        <div className="col-3 p-0 align" style={{ justifyContent: "center" }}>
                                                            <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>
                                                                {/* {creationDate} */}
                                                                {new Date(creationDate).getDate()}-{new Date(creationDate).getMonth() + 1}-{new Date(creationDate).getFullYear()}
                                                            </P></span>
                                                        </div>
                                                    </div>
                                                    {userStatus === "NEW" ?
                                                        < div className="new_badge">
                                                            <p>New</p>
                                                        </div>
                                                        : <span></span>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })}



                                </div>
                            </>
                            :
                            <div className="col-12 text-center view_download_dashboard">
                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Grievance not available.</H4>
                            </div>
                        }




                    </>

                }
            </section>
        </>
    );

}
export default Grievance;