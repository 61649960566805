import { useSelector } from "react-redux";

const NavSelectedUnderline = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    return (
        <div style={{ width: "100%", height:"2px", backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#6D8DAD" : "#FFFF00" }}></div>
    );
}

export default NavSelectedUnderline;