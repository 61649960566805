const StateAndDistrictList = [
   {
      state: 'Andaman & Nicobar',
      city: ['Alipur', 'Andaman Island', 'Anderson Island', 'Arainj-Laka-Punga', 'Austinabad', 'Bamboo Flat', 'Barren Island', 'Beadonabad', 'Betapur', 'Bindraban', 'Bonington', 'Brookesabad', 'Cadell Point', 'Calicut', 'Chetamale', 'Cinque Islands', 'Defence Island', 'Digilpur', 'Dolyganj', 'Flat Island', 'Geinyale', 'Great Coco Island', 'Haddo', 'Havelock Island', 'Henry Lawrence Island', 'Herbertabad', 'Hobdaypur', 'Ilichar', 'Ingoie', 'Inteview Island', 'Jangli Ghat', 'Jhon Lawrence Island', 'Karen', 'Kartara', 'KYD Islannd', 'Landfall Island', 'Little Andmand', 'Little Coco Island', 'Long Island', 'Maimyo', 'Malappuram', 'Manglutan', 'Manpur', 'Mitha Khari', 'Neill Island', 'Nicobar Island', 'North Brother Island', 'North Passage Island', 'North Sentinel Island', 'Nothen Reef Island', 'Outram Island', 'Pahlagaon', 'Palalankwe', 'Passage Island', 'Phaiapong', 'Phoenix Island', 'Port Blair', 'Preparis Island', 'Protheroepur', 'Rangachang', 'Rongat', 'Rutland Island', 'Sabari', 'Saddle Peak', 'Shadipur', 'Smith Island', 'Sound Island', 'South Sentinel Island', 'Spike Island', 'Tarmugli Island', 'Taylerabad', 'Titaije', 'Toibalawe', 'Tusonabad', 'West Island', 'Wimberleyganj', 'Yadita']
   },
   {
      state: 'Andhra Pradesh',
      city: ['Adilabad', 'Anantapur', 'Chittoor', 'Kakinada', 'Guntur', 'Hyderabad', 'Karimnagar', 'Khammam', 'Krishna', 'Kurnool', 'Mahbubnagar', 'Medak', 'Nalgonda', 'Nizamabad', 'Ongole', 'Hyderabad', 'Srikakulam', 'Nellore', 'Visakhapatnam', 'Vizianagaram', 'Warangal', 'Eluru', 'Kadapa',]
   },
   {
      state: 'Arunachal Pradesh',
      city: ['Anjaw', 'Changlang', 'East Siang', 'Kurung Kumey', 'Lohit', 'Lower Dibang Valley', 'Lower Subansiri', 'Papum Pare', 'Tawang', 'Tirap', 'Dibang Valley', 'Upper Siang', 'Upper Subansiri', 'West Kameng', 'West Siang',]
   },
   {
      state: 'Assam',
      city: ['Baksa', 'Barpeta', 'Bongaigaon', 'Cachar', 'Chirang', 'Darrang', 'Dhemaji', 'Dima Hasao', 'Dhubri', 'Dibrugarh', 'Goalpara', 'Golaghat', 'Hailakandi', 'Jorhat', 'Kamrup', 'Kamrup Metropolitan', 'Karbi Anglong', 'Karimganj', 'Kokrajhar', 'Lakhimpur', 'Marigaon', 'Nagaon', 'Nalbari', 'Sibsagar', 'Sonitpur', 'Tinsukia', 'Udalguri',]
   },
   {
      state: 'Bihar',
      city: ['Araria', 'Arwal', 'Aurangabad', 'Banka', 'Begusarai', 'Bhagalpur', 'Bhojpur', 'Buxar', 'Darbhanga', 'East Champaran', 'Gaya', 'Gopalganj', 'Jamui', 'Jehanabad', 'Kaimur', 'Katihar', 'Khagaria', 'Kishanganj', 'Lakhisarai', 'Madhepura', 'Madhubani', 'Munger', 'Muzaffarpur', 'Nalanda', 'Nawada', 'Patna', 'West Champaran', 'Chandigarh']
   },
   {
      state: 'Chhattisgarh',
      city: ['Bastar', 'Bijapur', 'Bilaspur', 'Dantewada', 'Dhamtari', 'Durg', 'Jashpur', 'Janjgir-Champa', 'Korba', 'Koriya', 'Kanker', 'Kabirdham (Kawardha)', 'Mahasamund', 'Narayanpur', 'Raigarh', 'Rajnandgaon', 'Raipur', 'Surguja',]
   },
   {
      state: 'Dadra & Nagar Haveli',
      city: ['Dadra and Nagar Haveli']
   },
   {
      state: 'Daman & Diu',
      city: ['Daman', 'Diu',]
   },
   {
      state: 'Delhi',
      city: ['Central Delhi', 'East Delhi', 'New Delhi', 'North Delhi', 'North East Delhi', 'North West Delhi', 'South Delhi', 'South West Delhi', 'West Delhi',]
   },
   {
      state: 'Goa',
      city: ['North Goa', 'South Goa']
   },
   {
      state: 'Gujarat',
      city: ['Ahmedabad', 'Amreli district', 'Anand', 'Banaskantha', 'Bharuch', 'Bhavnagar', 'Dahod', 'The Dangs', 'Gandhinagar', 'Jamnagar', 'Junagadh', 'Kutch', 'Kheda', 'Mehsana', 'Narmada', 'Navsari', 'Patan', 'Panchmahal', 'Porbandar', 'Rajkot', 'Sabarkantha', 'Surendranagar', 'Surat', 'Vyara', 'Vadodara', 'Valsad',]
   },
   {
      state: 'Haryana',
      city: ['Ambala', 'Bhiwani', 'Faridabad', 'Fatehabad', 'Gurgaon', 'Hissar', 'Jhajjar', 'Jind', 'Karnal', 'Kaithal', 'Kurukshetra', 'Mahendragarh', 'Mewat', 'Palwal', 'Panchkula', 'Panipat', 'Rewari', 'Rohtak', 'Sirsa', 'Sonipat', 'Yamuna Nagar',]
   },
   {
      state: 'Himachal Pradesh',
      city: ['Bilaspur', 'Chamba', 'Hamirpur', 'Kangra', 'Kinnaur', 'Kullu', 'Lahaul and Spiti', 'Mandi', 'Shimla', 'Sirmaur', 'Solan', 'Una',]
   },
   {
      state: 'Jammu & Kashmir',
      city: ['Anantnag', 'Badgam', 'Bandipora', 'Baramulla', 'Doda', 'Ganderbal', 'Jammu', 'Kargil', 'Kathua', 'Kishtwar', 'Kupwara', 'Kulgam', 'Leh', 'Poonch', 'Pulwama', 'Rajauri', 'Ramban', 'Reasi', 'Samba', 'Shopian', 'Srinagar', 'Udhampur',]
   },
   {
      state: 'Jharkhand',
      city: ['Bokaro', 'Chatra', 'Deoghar', 'Dhanbad', 'Dumka', 'East Singhbhum', 'Garhwa', 'Giridih', 'Godda', 'Gumla', 'Hazaribag', 'Jamtara', 'Khunti', 'Koderma', 'Latehar', 'Lohardaga', 'Pakur', 'Palamu', 'Ramgarh', 'Ranchi', 'Sahibganj', 'Seraikela Kharsawan', 'Simdega', 'West Singhbhum']
   },
   {
      state: 'Karnataka',
      city: ['Bagalkot', 'Bangalore Rural', 'Bangalore Urban', 'Belgaum', 'Bellary', 'Bidar', 'Bijapur', 'Chamarajnagar', 'Chikkamagaluru', 'Chikkaballapur', 'Chitradurga', 'Davanagere', 'Dharwad', 'Dakshina Kannada', 'Gadag', 'Gulbarga', 'Hassan', 'Haveri district', 'Kodagu', 'Kolar', 'Koppal', 'Mandya', 'Mysore', 'Raichur', 'Shimoga', 'Tumkur', 'Udupi', 'Uttara Kannada', 'Ramanagara', 'Yadgir',]
   },
   {
      state: 'Kerala',
      city: ['Alappuzha', 'Ernakulam', 'Idukki', 'Kannur', 'Kasaragod', 'Kollam', 'Kottayam', 'Kozhikode', 'Malappuram', 'Palakkad', 'Pathanamthitta', 'Thrissur', 'Thiruvananthapuram']
   },
   {
      state: 'Madhya Pradesh',
      city: ['Alirajpur', 'Anuppur', 'Ashok Nagar', 'Balaghat', 'Barwani', 'Betul', 'Bhind', 'Bhopal', 'Burhanpur', 'Chhatarpur', 'Chhindwara', 'Damoh', 'Datia', 'Dewas', 'Dhar', 'Dindori', 'Guna', 'Gwalior', 'Harda', 'Hoshangabad', 'Indore', 'Jabalpur', 'Jhabua', 'Katni', 'Khandwa (East Nimar)', 'Khargone (West Nimar)', 'Mandla', 'Mandsaur', 'Morena', 'Narsinghpur', 'Neemuch', 'Panna', 'Rewa', 'Rajgarh', 'Ratlam', 'Raisen', 'Sagar', 'Satna', 'Sehore', 'Seoni', 'Shahdol', 'Shajapur', 'Sheopur', 'Shivpuri', 'Sidhi', 'Singrauli', 'Tikamgarh', 'Ujjain', 'Umaria', 'Vidisha',]
   },
   {
      state: 'Maharashtra',
      city: ['Ahmednagar', 'Akola', 'Amravati', 'Aurangabad', 'Bhandara', 'Beed', 'Buldhana', 'Chandrapur', 'Dhule', 'Gadchiroli', 'Gondia', 'Hingoli', 'Jalgaon', 'Jalna', 'Kolhapur', 'Latur', 'Mumbai City', 'Mumbai suburban', 'Nandurbar', 'Nanded', 'Nagpur', 'Nashik', 'Osmanabad',"Palghar", 'Parbhani', 'Pune', 'Raigad', 'Ratnagiri', 'Sindhudurg', 'Sangli', 'Solapur', 'Satara', 'Thane', 'Wardha', 'Washim', 'Yavatmal',]
   },
   {
      state: 'Manipur',
      city: ['Bishnupur', 'Churachandpur', 'Chandel', 'Imphal East', 'Senapati', 'Tamenglong', 'Thoubal', 'Ukhrul', 'Imphal West',]
   },
   {
      state: 'Meghalaya',
      city: ['East Garo Hills', 'East Khasi Hills', 'Jaintia Hills', 'Ri Bhoi', 'South Garo Hills', 'West Garo Hills', 'West Khasi Hills',]
   },
   {
      state: 'Mizoram',
      city: ['Aizawl', 'Champhai', 'Kolasib', 'Lawngtlai', 'Lunglei', 'Mamit', 'Saiha', 'Serchhip',]
   },
   {
      state: 'Nagaland',
      city: ['Dimapur', 'Kohima', 'Mokokchung', 'Mon', 'Phek', 'Tuensang', 'Wokha', 'Zunheboto',]
   },
   {
      state: 'Orissa',
      city: ['Angul', 'Boudh (Bauda)', 'Bhadrak', 'Balangir', 'Bargarh (Baragarh)', 'Balasore', 'Cuttack', 'Debagarh (Deogarh)', 'Dhenkanal', 'Ganjam', 'Gajapati', 'Jharsuguda', 'Jajpur', 'Jagatsinghpur', 'Khordha', 'Kendujhar (Keonjhar)', 'Kalahandi', 'Kandhamal', 'Koraput', 'Kendrapara', 'Malkangiri', 'Mayurbhanj', 'Nabarangpur', 'Nuapada', 'Nayagarh', 'Puri', 'Rayagada', 'Sambalpur', 'Subarnapur (Sonepur)', 'Sundergarh',]
   },
   {
      state: 'Pondicherry',
      city: ['Karaikal', 'Mahe', 'Pondicherry', 'Yanam']
   },
   {
      state: 'Punjab',
      city: ['Amritsar', 'Barnala', 'Bathinda', 'Firozpur', 'Faridkot', 'Fatehgarh Sahib', 'Fazilka', 'Gurdaspur', 'Hoshiarpur', 'Jalandhar', 'Kapurthala', 'Ludhiana', 'Mansa', 'Moga', 'Sri Muktsar Sahib', 'Pathankot', 'Patiala', 'Rupnagar', 'Ajitgarh (Mohali)', 'Sangrur', 'Nawanshahr', 'Tarn Taran',]
   },
   {
      state: 'Rajasthan',
      city: ['Ajmer', 'Alwar', 'Bikaner', 'Barmer', 'Banswara', 'Bharatpur', 'Baran', 'Bundi', 'Bhilwara', 'Churu', 'Chittorgarh', 'Dausa', 'Dholpur', 'Dungapur', 'Ganganagar', 'Hanumangarh', 'Jhunjhunu', 'Jalore', 'Jodhpur', 'Jaipur', 'Jaisalmer', 'Jhalawar', 'Karauli', 'Kota', 'Nagaur', 'Pali', 'Pratapgarh', 'Rajsamand', 'Sikar', 'Sawai Madhopur', 'Sirohi', 'Tonk', 'Udaipur',]
   },
   {
      state: 'Sikkim',
      city: ['East Sikkim', 'North Sikkim', 'South Sikkim', 'West Sikkim',]
   },
   {
      state: 'Tamil Nadu',
      city: ['Ariyalur', 'Chennai', 'Coimbatore', 'Cuddalore', 'Dharmapuri', 'Dindigul', 'Erode', 'Kanchipuram', 'Kanyakumari', 'Karur', 'Madurai', 'Nagapattinam', 'Nilgiris', 'Namakkal', 'Perambalur', 'Pudukkottai', 'Ramanathapuram', 'Salem', 'Sivaganga', 'Tirupur', 'Tiruchirappalli', 'Theni', 'Tirunelveli', 'Thanjavur', 'Thoothukudi', 'Tiruvallur', 'Tiruvarur', 'Tiruvannamalai', 'Vellore', 'Viluppuram', 'Virudhunagar',]
   },
   {
      state: 'Tripura',
      city: ['Dhalai', 'North Tripura', 'South Tripura', 'Khowai', 'West Tripura',]
   },
   {
      state: 'Uttar Pradesh',
      city: ['Agra', 'Allahabad', 'Aligarh', 'Ambedkar Nagar', 'Auraiya', 'Azamgarh', 'Barabanki', 'Budaun', 'Bagpat', 'Bahraich', 'Bijnor', 'Ballia', 'Banda', 'Balrampur', 'Bareilly', 'Basti', 'Bulandshahr', 'Chandauli', 'Chhatrapati Shahuji Maharaj Nagar', 'Chitrakoot', 'Deoria', 'Etah', 'Kanshi Ram Nagar', 'Etawah', 'Firozabad', 'Farrukhabad', 'Fatehpur', 'Faizabad', 'Gautam Buddh Nagar', 'Gonda', 'Ghazipur', 'Gorakhpur', 'Ghaziabad', 'Hamirpur', 'Hardoi', 'Mahamaya Nagar', 'Jhansi', 'Jalaun', 'Jyotiba Phule Nagar', 'Jaunpur district', 'Ramabai Nagar (Kanpur Dehat)', 'Kannauj', 'Kanpur', 'Kaushambi', 'Kushinagar', 'Lalitpur', 'Lakhimpur Kheri', 'Lucknow', 'Mau', 'Meerut', 'Maharajganj', 'Mahoba', 'Mirzapur', 'Moradabad', 'Mainpuri', 'Mathura', 'Muzaffarnagar', 'Panchsheel Nagar district (Hapur)', 'Pilibhit', 'Shamli', 'Pratapgarh', 'Rampur', 'Raebareli', 'Saharanpur', 'Sitapur', 'Shahjahanpur', 'Sant Kabir Nagar', 'Siddharthnagar', 'Sonbhadra', 'Sant Ravidas Nagar', 'Sultanpur', 'Shravasti', 'Unnao', 'Varanasi',]
   },
   {
      state: 'Uttarakhand',
      city: ['Almora', 'Bageshwar', 'Chamoli', 'Champawat', 'Dehradun', 'Haridwar', 'Nainital', 'Pauri Garhwal', 'Pithoragarh', 'Rudraprayag', 'Tehri Garhwal', 'Udham Singh Nagar', 'Uttarkashi',]
   },
   {
      state: 'West Bengal',
      city: ['Birbhum', 'Bankura', 'Bardhaman', 'Darjeeling', 'Dakshin Dinajpur', 'Hooghly', 'Howrah', 'Jalpaiguri', 'Cooch Behar', 'Kolkata', 'Maldah', 'Paschim Medinipur', 'Purba Medinipur', 'Murshidabad', 'Nadia', 'North 24 Parganas', 'South 24 Parganas', 'Purulia', 'Uttar Dinajpur']
   }

];
export default StateAndDistrictList;


