import H2 from "../text-components/H2";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import NavSelectedUnderline from "../shared-components/NavSelectedUnderline";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ApplicationFormsNav = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

  const showActiveApplicationFormTab = useSelector(state => state.showActiveApplicationFormTab);

  const [userType, setUserType] = useState("");

  const [selectedNavItem, setSelectedNavItem] = useState("");

  const [applicationId, setApplicationId] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  // function goToHome() {
  //   history.push('/home');
  // }

  useEffect(async () => {

    const queryParams = new URLSearchParams(location.search);
    const applicationIdFromParams = await queryParams.get('applicationId');
    setApplicationId(value => value = applicationIdFromParams);

    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');
    setUserType(splitUrlArray[4]);

    switch (window.location.pathname) {
      case `/${userType}${userType === "user" ? "" : "/dashboard"}/application/personal-details`:
        setSelectedNavItem(value => value = "PERSONAL_DETAILS")
        break;
      case `/${userType}${userType === "user" ? "" : "/dashboard"}/application/parent-details`:
        setSelectedNavItem(value => value = "PARENT_DETAILS")
        break;
      case `/${userType}${userType === "user" ? "" : "/dashboard"}/application/exam-details`:
        setSelectedNavItem(value => value = "EXAM_DETAILS")
        break;
      // case `/${userType}${userType === "user" ? "" : "/dashboard"}/application/family-income-details`:
      //   setSelectedNavItem(value => value = "FAMILY_INCOME_DETAILS")
      //   break;
      // case `/${userType}${userType === "user" ? "" : "/dashboard"}/application/family-details`:
      //   setSelectedNavItem(value => value = "FAMILY_DETAILS")
      //   break;
      case `/${userType}${userType === "user" ? "" : "/dashboard"}/application/college-admission-details`:
        setSelectedNavItem(value => value = "COLLEGE_ADMISSION_DETAILS")
        break;
      case `/${userType}${userType === "user" ? "" : "/dashboard"}/application/education-expenses-detail`:
        setSelectedNavItem(value => value = "EDUCATION_EXPENSES_DETAILS")
        break;
      case `/${userType}${userType === "user" ? "" : "/dashboard"}/application/document-submission-details`:
        setSelectedNavItem(value => value = "DOCUMENT_SUBMISSION_DETAILS")
        break;
    }
  }, []);

  const selectNavItem = (navItem) => {
    setSelectedNavItem(value => value = navItem);

    switch (navItem) {
      case "PERSONAL_DETAILS":
        history.push(`/${userType}${userType === "user" ? "" : "/dashboard"}/application/personal-details?applicationId=${applicationId}`);
        break;
      case "PARENT_DETAILS":
        history.push(`/${userType}${userType === "user" ? "" : "/dashboard"}/application/parent-details?applicationId=${applicationId}`);
        break;
      case "EXAM_DETAILS":
        history.push(`/${userType}${userType === "user" ? "" : "/dashboard"}/application/exam-details?applicationId=${applicationId}`);
        break;
      // case "FAMILY_INCOME_DETAILS":
      //   history.push(`/${userType}${userType === "user" ? "" : "/dashboard"}/application/family-income-details?applicationId=${applicationId}`);
      //   break;
      // case "FAMILY_DETAILS":
      //   history.push(`/${userType}${userType === "user" ? "" : "/dashboard"}/application/family-details?applicationId=${applicationId}`);
      //   break;
      case "COLLEGE_ADMISSION_DETAILS":
        history.push(`/${userType}${userType === "user" ? "" : "/dashboard"}/application/college-admission-details?applicationId=${applicationId}`);
        break;
      case "EDUCATION_EXPENSES_DETAILS":
        history.push(`/${userType}${userType === "user" ? "" : "/dashboard"}/application/education-expenses-detail?applicationId=${applicationId}`);
        break;
      case "DOCUMENT_SUBMISSION_DETAILS":
        history.push(`/${userType}${userType === "user" ? "" : "/dashboard"}/application/document-submission-details?applicationId=${applicationId}`);
        break;
    }
  }

  useEffect(() => {
  }, [selectedNavItem]);

  return (
    <>

      {/*-----navbar for laptop and mobile device--------------*/}

      <nav className="navbar navbar-expand-lg application_process_nav" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000" }}>
        <div className="container-fluid p-0">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span><i className="fa fa-bars" aria-hidden="true"></i></span>
          </button>
          <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
            <ul className="navbar-nav application_form_nav_list">

              {/* <li className="home_icon"><H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00"><i className="fa fa-home" aria-hidden="true" onClick={goToHome}></i></H2></li> */}
              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationFormTab === "PERSONAL_DETAILS") ? selectNavItem(" ") : selectNavItem("PERSONAL_DETAILS")}>

                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Personal Details</H2>
                {(showActiveApplicationFormTab === "PERSONAL_DETAILS") ? <NavSelectedUnderline /> : <span></span>}
              </li>
              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationFormTab === "PARENT_DETAILS") ? selectNavItem(" ") : selectNavItem("PARENT_DETAILS")}>
                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Parents Details</H2>
                {(showActiveApplicationFormTab === "PARENT_DETAILS") ? <NavSelectedUnderline /> : <span></span>}
              </li>

              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationFormTab === "EXAM_DETAILS") ? selectNavItem(" ") : selectNavItem("EXAM_DETAILS")}>
                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Qualification</H2>
                {(showActiveApplicationFormTab === "EXAM_DETAILS") ? <NavSelectedUnderline /> : <span></span>}
              </li>

              {/* <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationFormTab === "FAMILY_INCOME_DETAILS") ? selectNavItem(" ") : selectNavItem("FAMILY_INCOME_DETAILS")}>
                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Family Income Details</H2>
                {(showActiveApplicationFormTab === "FAMILY_INCOME_DETAILS") ? <NavSelectedUnderline /> : <span></span>}
              </li>

              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationFormTab === "FAMILY_DETAILS") ? selectNavItem(" ") : selectNavItem("FAMILY_DETAILS")}>
                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Family Details</H2>
                {(showActiveApplicationFormTab === "FAMILY_DETAILS") ? <NavSelectedUnderline /> : <span></span>}
              </li> */}
              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationFormTab === "COLLEGE_ADMISSION_DETAILS") ? selectNavItem(" ") : selectNavItem("COLLEGE_ADMISSION_DETAILS")}>
                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">College Admission Details</H2>
                {(showActiveApplicationFormTab === "COLLEGE_ADMISSION_DETAILS") ? <NavSelectedUnderline /> : <span></span>}
              </li>
              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationFormTab === "EDUCATION_EXPENSES_DETAILS") ? selectNavItem(" ") : selectNavItem("EDUCATION_EXPENSES_DETAILS")}>
                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Education Expenses</H2>
                {(showActiveApplicationFormTab === "EDUCATION_EXPENSES_DETAILS") ? <NavSelectedUnderline /> : <span></span>}
              </li>
              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationFormTab === "DOCUMENT_SUBMISSION_DETAILS") ? selectNavItem(" ") : selectNavItem("DOCUMENT_SUBMISSION_DETAILS")}>
                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Documents</H2>
                {(showActiveApplicationFormTab === "DOCUMENT_SUBMISSION_DETAILS") ? <NavSelectedUnderline /> : <span></span>}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default ApplicationFormsNav; 