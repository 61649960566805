import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import { useHistory } from "react-router";
import H5 from "../../text-components/H5";
import H3 from "../../text-components/H3";
import H7 from "../../text-components/H7";

const RoleMaintenanceCreate = () => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const [portals, setPortals] = useState([
        {
            name: "FRONT_OFFICE",
            tabs: ["COURSE_WISE_APPLICATION","NEW_APPLICATION", "APPLICATION_VERIFICATION", "SHORT_LIST_APPLICATION", "WAITING_LIST", "MERIT_LIST", "APPROVED_LIST", "MINUTES_OF_MEETING"]
        },
        {
            name: "BACK_OFFICE",
            tabs: ["GOVERNMENT_RESOLUTION", "USER_NOTIFICATION", "DOCUMENT_SCRUTINY", "NOTIFICATION", "REMITTANCE", "GRIEVANCE", "REFUND", "HOME_VERIFICATION","STUDENT_PERFORMANCE", "STUDENT_REVIEW"]
        },
    ]);

    const [selectPortalTabs, setSelectPortalTabs] = useState([]);

    const [selectedTabs, setSelectedTabs] = useState([]);

    //****  Input/Select filed disable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);

    const history = useHistory();

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const [isLoading, setIsLoading] = useState({ message: "", value: false });


    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }


    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        name: "",
        portal: "",
        tabs: "",
        access: true,
    }

    //****Validation for Designation Create form*****/
    const validationSchema = Yup.object().shape({
        name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Role name is required"),
        portal: Yup.string().required("Select Portal "),
        // tabs: Yup.array().min(1),
        access: Yup.boolean().required("Role is required"),
    });


    // Destructuring useFormik to get functions to control aspects of form
    // What does Formik do?
    // 1. Handling form events like submit, errors, change in value
    // 2. Fetching values from the form 
    // 3. Support for a validation library on the data fetched. We are using Yup library for validations. 
    // 4. Reset the form
    // 5. Pass initial values to the form
    // 6. Handle errors thrown by the Yup (or any validation library).
    // 7. Reinitialize form values
    // 8. Perform async operations
    const { handleSubmit, handleChange, setFieldValue, values, errors, handleReset } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property needs to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit
        onSubmit: async (values) => {
            // Show loading 
            showLoading("Loading...");

            // Send values to the server
            let rolesData = {
                name: values.name,
                portal: values.portal,
                tabs: selectedTabs,
                access: values.access,
            }

            let showTabError = checkTabError(selectedTabs);

            if (rolesData.access === true) {
                rolesData.access = "READ"
            }
            else if (rolesData.access === false) {
                rolesData.access = "WRITE"
            }

            if (showTabError == true) {
                const rolesCreationResponse = await adminCommunication.createRoles(rolesData);
                // Handle response from the server
                if (rolesCreationResponse.status === "SUCCESS") {
                    handleReset();
                    dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "ROLES_MAINTENANCE_VIEW" });
                    history.push('/admin/dashboard/roles-maintenance-view');

                }
                // Show response message from server in modal dialog
                showModal(rolesCreationResponse.message);
            }
            else {
                showModal("Please select at least one tab");
            }

            // Hide spinner 
            hideLoading();
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: validationSchema,
    });

    function setSelectPortalTabsBySelectedPortal(value) {
        for (let i = 0; i < portals.length; i++) {
            if (portals[i].name === value) {
                setSelectPortalTabs(val => val = portals[i].tabs);
                // clear data from the selectedTabs state
                setSelectedTabs(val => val = []);
            }
        }
    }
    const checkTabError = (selectedTabs) => {
        if (selectedTabs.length == 0) {
            return false;
        }
        else {
            return true;
        }
    }


    // set ids in to state
    const handleOnChange = (e) => {
        let checked = e.target.checked;
        let tab = e.target.value;
        if (checked) {
            setSelectedTabs(() => [...selectedTabs, tab]);
        } else {
            setSelectedTabs(
                selectedTabs.filter((Tab) => Tab !== tab)
            );
        }
        return;
    }
    return (

        <div >
            <section className="create_form create_designation" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000", border: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }} >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :

                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <H3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Create Role</H3>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Role Name</H5>
                            </label>
                            <div className="col-sm-7">
                                <input type="text" name="name" onChange={handleChange} value={values.name} className={`form-control ${errors.name ? "is-invalid" : ""}`} />
                                <div className="invalid-feedback">{errors.name}</div>
                            </div>
                        </div>


                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Portal</H5>
                            </label>
                            <div className="col-sm-7">
                                <select name="portal" onChange={(e) => { handleChange(e); setSelectPortalTabsBySelectedPortal(e.target.value) }} value={values.portal} className={`form-select ${errors.portal ? "is-invalid" : ""}`}>
                                    <option value={""} defaultValue>Select</option>
                                    {portals?.map((portal, index) => {
                                        const { name } = portal;
                                        return (
                                            <option key={index} value={name}>{name}</option>
                                        );
                                    })}
                                </select>
                                <div className="invalid-feedback">{errors.portal}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Tabs</H5>
                            </label>

                            <div className="col-sm-7">
                                <div className="row admin_tab">
                                    {selectPortalTabs.map((tab, index) => {
                                        return (
                                            <div className="col-sm-6" key={index}>
                                                <div className="Checkbox-inline" key={tab}>
                                                    <input type="checkbox" name="tab" value={tab} onClick={(e) => handleOnChange(e)} />
                                                    <H7 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{tab}</H7>
                                                </div>
                                            </div>

                                        )
                                    })}

                                </div>
                            </div>
                        </div>


                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Access</H5>
                            </label>

                            <div className="col-4 arrayFetch">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                    Read
                                </H5>
                                <input onChange={() => setFieldValue("access", true)} id="accessTrue" checked={values.access === null ? (values.access) : Boolean(values.access)} type="radio" value="true" name="access" className={`mx-3 ${errors.access ? "is-invalid" : ""}`} disabled={disabled} />
                                <div className="invalid-feedback">{errors.access?.message}</div>
                            </div>
                            <div className="col-4 arrayFetch">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                    Write
                                </H5>
                                <input onChange={() => setFieldValue("access", false)} id="accessFalse" checked={values.access === null ? (values.access) : Boolean(!values.access)} type="radio" value="false" name="access" className={`mx-3 ${errors.access ? "is-invalid" : ""}`} disabled={disabled} />
                                <div className="invalid-feedback">{errors.access?.message}</div>
                            </div>
                        </div>


                        <button type="button" onClick={() => {
                            handleSubmit();
                        }} className="button" >
                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H5>Create</H5>}
                        </button>

                    </form>
                }
            </section>
        </div>

    );
}
export default RoleMaintenanceCreate;