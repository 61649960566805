import React from 'react'
import HomeScreenNav from './HomeScreenNav';

const AdministrationProfileOfCorporation = () => {
    return (
        <>
            <HomeScreenNav />

            <div className='container'>
                <div className='row'>
                    <div className='col-12 hierarchy_img'>
                        <img src="/assets/images/jpgs/engProfileCorpGraph.jpg" alt="engProfileCorpGraph.jpg" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdministrationProfileOfCorporation;