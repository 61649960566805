import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from "react-router";
import * as Yup from "yup";
import LoadingForms from '../shared-components/LoadingForms';
import H3 from '../text-components/H3';
import { frontOfficeCommunication } from "../communications/front-office-communication";
import { useDispatch } from 'react-redux';
import H5 from '../text-components/H5';


const MinutesOfMeetingBoxFirst = ({ hideMomFirst }) => {

    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    const [requiredDocuments, setRequiredDocuments] = useState({});

    const history = useHistory();


    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        title: "",
        description: "",
    }

    //****Validation for Designation Create form*****/
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("title is required"),
        description: Yup.string().required("Description is required"),
        uploadedFile: Yup.mixed().required("Attachment is required").nullable(),
    });


    function setFileToUpload(event) {
        event.preventDefault();
        let fileNameArray = event.target.files[0].name.split('.');
        let formData = new FormData();
        formData.append("uploadedFile", event.target.files[0], event.target.files[0].name);
        setRequiredDocuments({ formData, fileNameArray });
        setFieldValue('uploadedFile', event.target.files[0]);
    };

    // Destructuring useFormik to get functions to control aspects of form
    // What does Formik do?
    // 1. Handling form events like submit, errors, change in value
    // 2. Fetching values from the form 
    // 3. Support for a validation library on the data fetched. We are using Yup library for validations. 
    // 4. Reset the form
    // 5. Pass initial values to the form
    // 6. Handle errors thrown by the Yup (or any validation library).
    // 7. Reinitialize form values
    // 8. Perform async operations
    const { handleSubmit, handleChange, setFieldValue, values, errors, handleReset } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit
        onSubmit: async (values) => {
            // Show loading 
            showLoading("Loading...");

            // Send values to the server
            let minutesOfMeetingData = {
                title: values.title,
                description: values.description,
                uploadedFile: requiredDocuments.formData,
                documentName: requiredDocuments.fileNameArray[0],
                documentType: requiredDocuments.fileNameArray[1],
            }

            let minutesOfMeetingCreationResponse = await frontOfficeCommunication.createMinutesOfMeeting(minutesOfMeetingData.uploadedFile, minutesOfMeetingData.documentName, minutesOfMeetingData.documentType, minutesOfMeetingData.title, minutesOfMeetingData.description);

            // Handle response from the server
            if (minutesOfMeetingCreationResponse.status === "SUCCESS" || minutesOfMeetingCreationResponse.data.status === "SUCCESS") {
                handleReset();
                history.push('/front-office/dashboard/minutes-of-meeting');
            }

            else if (minutesOfMeetingCreationResponse.status === "UNAUTHORIZED_ACCESS") {
                history.push(`/front-office/login`);
                return;
            }

            // Show response message from server in modal dialog
            showModal(minutesOfMeetingCreationResponse.message || minutesOfMeetingCreationResponse.data.message);

            // Hide spinner 
            hideLoading();

        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: validationSchema,
    });



    return (
        <>
            <div className='mom_box' >

                <section className="create_form create_designation mom_first"  >
                    <span onClick={() => { hideMomFirst() }}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </span>

                    {isLoading.value ?
                        <LoadingForms message={isLoading.message} />
                        :

                        <form onSubmit={handleSubmit}>


                            <div className="mt-4 mb-3 row">
                                <label className="col-sm-5 ">
                                    <h3 >Title</h3>
                                </label>
                                <div className="col-sm-7">
                                    <input type="text" name="title" onChange={handleChange} className={`form-control ${errors.title ? "is-invalid" : ""}`} />
                                    <div className="invalid-feedback">{errors.title}</div>
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <label className="col-sm-5 ">
                                    <h3 >Description</h3>
                                </label>
                                <div className="col-sm-7">
                                    <textarea name="description" onChange={handleChange} className={`form-control ${errors.description ? "is-invalid" : ""}`} />
                                    <div className="invalid-feedback">{errors.description}</div>
                                </div>
                            </div>

                            <div className="mb-2 row">
                                <label className="col-sm-5 ">
                                    <h3 >Upload File</h3>
                                </label>
                                <div className="col-sm-7">
                                    <input type="file" name="uploadedFile" onChange={(e) => setFileToUpload(e)} value={values.uploadedFile?.filename} className={`form-control ${errors.uploadedFile ? "is-invalid" : ""}`} />
                                    <div className="invalid-feedback">{errors.uploadedFile}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4"></div>
                                <div className="col-7">
                                    <div style={{ fontSize: "10.5px", color: "rgb(109, 141, 173)", marginBottom: "15px" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></div>
                                </div>
                            </div>
                            <div className='minOfMeeting'>
                                <button type="button" onClick={() => { handleSubmit() }} >
                                    {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H5>Submit</H5>}
                                </button>
                            </div>
                        </form>
                    }
                </section>
            </div>

        </>

    )
};

export default MinutesOfMeetingBoxFirst;
