import React, { useEffect, useState } from "react";
import { communication } from "../communications/communication";
import H5 from "../text-components/H5";
import LoadingForms from "../shared-components/LoadingForms";
import fileDownload from 'js-file-download';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const DownloadsInfo = () => {

  //Alert Modal react-redux
  const dispatch = useDispatch();
  function showModal(text) {
    dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
  }


  const history = useHistory();
  const [downloads, setDownloads] = useState([]);
  const [isLoading, setIsLoading] = useState({ message: "", value: false });


  //spinner show and hide function declaration
  function showLoading(message) {
    setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
  }

  function hideLoading() {
    setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
  }

  const fetchDownloads = async () => {
    showLoading("Loading...");
    const downloadDocumentData = await communication.getDownloadDocuments("PUBLIC");
    if (downloadDocumentData.status === "SUCCESS") {
      setDownloads(downloadDocumentData.documents);
    } else {
      setDownloads([]);
    }
    hideLoading();
  }

  useEffect(() => {
    fetchDownloads();
  }, []);

  async function downloadDocument(id, attachment) {
    // if the attachment is empty or not
    let attachmentSplit = attachment.split("/");
    if (id !== "") {
      const getDownloadDocuments = await communication.getDownloadPdf(id);
      // fileDownload(getDownloadDocuments, attachmentSplit[1]);
      return getDownloadDocuments;
    }
  }


  return (
    <section className="downloads_tab_info">
      {
        isLoading.value ?
          <LoadingForms message={isLoading.message} />
          :
          <div>
            {
              downloads.length > 0 ?

                <>
                  {downloads.map((download, index) => {
                    const { _id, subject, description, attachment } = download;
                    return (
                      <div key={index}>
                        <div className="row">
                          <div className="col-9">
                            <ul>
                              <li>
                                <H5>{subject}-{description}</H5>
                              </li>
                            </ul>
                          </div>
                          <div className="col-3">
                            <div style={{ justifyContent: "center", padding: "5px 3px", width: "90px", textAlign: "center", marginLeft: "20px", display: "flex" }}>
                              <span onClick={() => downloadDocument(_id, attachment)}>
                                <i className="fa fa-download" style={{ color: "black", fontSize: "1.2rem" }} aria-hidden="true"></i>
                              </span>
                              {/* <H5 whiteThemeColor="#1E2343" style={{ alignSelf: "end", cursor: "pointer" }}>
                          <span onClick={() => downloadDocument(_id, attachment)}>
                            Download
                          </span>
                        </H5> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
                :

                <div className="text-center mt-5">
                  <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Records not available</H5>
                </div>
            }



          </div>
      }
    </section>
  );
}

export default DownloadsInfo;