import { logDOM } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import H5 from "../../text-components/H5";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";


const UsersMaintenanceView = () => {

    const history = new useHistory();
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [allUsersMaintenanceData, setAllUsersMaintenanceData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchUsersBySearch(searchValue, selectedPage + 1);
        } else {
            fetchAllUsers(selectedPage + 1);
        }
    }

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchUsersBySearch(searchString, currentPage + 1);
        } else {
            const usersFromServer = await adminCommunication.getAllUser();
            // console.log("usersFromServer 1",usersFromServer);
            if (usersFromServer.status === "SUCCESS") {
                setAllUsersMaintenanceData(usersFromServer?.users);
                setPageCount(usersFromServer?.totalPages);
            } else {
                setAllUsersMaintenanceData([]);
                setPageCount(0);
            }
        }
    }
    //get Users By Search
    const fetchUsersBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await adminCommunication.getUsersBySearch(searchString, page);
            console.log("responseFromServer",responseFromServer);
            if (responseFromServer?.data?.status === "SUCCESS") {
               
                setAllUsersMaintenanceData(responseFromServer?.data?.users);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                history.push("/admin/login");
                showModal(responseFromServer?.data?.message);
            } else {
                setAllUsersMaintenanceData([]);
                setPageCount(0);
            }
        } catch (error) {
            showModal(error.message);
        }
    }

    //get All Users on Initial Load
    const fetchAllUsers = async (page) => {
        try {
            showLoading("Loading...");
            dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "USERS_MAINTENANCE_VIEW" });
            const usersFromServer = await adminCommunication.getAllUser(page);
            if (usersFromServer.status === "SUCCESS") {
                setAllUsersMaintenanceData(usersFromServer.users);
                setPageCount(usersFromServer?.totalPages);
                // console.log("usersFromServer 2",usersFromServer.users);
            } else {
                showModal(usersFromServer.message);
                setAllUsersMaintenanceData([]);
                setPageCount(0);
            }
            
            hideLoading();
        } catch (error) {
            showModal(error.message);
        }
    }

    useEffect(() => {
        fetchAllUsers();
    }, []);

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }


    useEffect(() => {
        console.log("allUsersMaintenanceData",allUsersMaintenanceData)
    }, [allUsersMaintenanceData]);

    return (
        <>
            <section className="dashboard_background"  >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :

                    <div className="dashbord_main">
                        <div className="row search_wrapper">
                            <div className="col-lg-4 col-md-4 ps-0">
                                <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By User Name, Department..." />
                            </div>
                            <div className="col-lg-8 col-md-8 pagination_outer_wrapper">
                                <div className="pagination_inner_wrapper">
                                    {pageCount > 1 &&
                                        <ReactPaginate
                                            previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            forcePage={currentPage}
                                            activeClassName={"active"} />
                                    }
                                </div>
                            </div>
                        </div>
                        {/*-----Grievance card header section---*/}
                        <div className="dashboard_view_data_heading">
                            <div className="row m-0">
                                <div className="col-1 p-0 view_dashboard">
                                    <span><H5>Serial No.</H5></span>
                                </div>
                                <div className="col-3 p-0 view_dashboard">
                                    <span><H5>User Name</H5></span>
                                </div>
                                <div className="col-3 p-0 view_dashboard">
                                    <span><H5>Department</H5></span>
                                </div>
                                <div className="col-3 p-0 view_dashboard">
                                    <span><H5>Designation</H5></span>
                                </div>
                                <div className="col-2 p-0 ">
                                    <span><H5>Mobile No.</H5></span>
                                </div>
                            </div>
                        </div>

                        {allUsersMaintenanceData?.length > 0 ?
                            <div>
                                {/*-----Grievance card data section---*/}
                                {allUsersMaintenanceData?.map((users, index) => {
                                    const { fullName, departmentName, designationName, phoneNumber, deleteCount } = users;
                                    return (
                                        <div key={index}>
                                            
                                                <div className="dashboard_view_data_main">
                                                    <div className="dashboard_view_data">
                                                        <div className="row m-0">
                                                            <div className="col-1 p-0 view_dashboard text_align">
                                                                <span>
                                                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                        {limit * currentPage + (index + 1)}
                                                                    </H5>
                                                                </span>
                                                            </div>
                                                            <div className="col-3 p-0 view_dashboard text_align">
                                                                <span>
                                                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                        {fullName}
                                                                    </H5>
                                                                </span>
                                                            </div>
                                                            <div className="col-3 p-0 view_dashboard text_align">
                                                                <span>
                                                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >{departmentName}</H5>
                                                                </span>
                                                            </div>
                                                            <div className="col-3 p-0 view_dashboard text_align">
                                                                <span>
                                                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                        {designationName}
                                                                    </H5>
                                                                </span>
                                                            </div>
                                                            <div className="col-2 p-0 text_align">
                                                                <span>
                                                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                        {phoneNumber}
                                                                    </H5>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                        </div>

                                    );
                                })}
                            </div>
                            :
                            <div className="col-12 text-center view_download_dashboard">
                                <h6>Records not available.</h6>
                            </div>
                        }
                    </div>
                }
            </section>
        </>

    )
}

export default UsersMaintenanceView;