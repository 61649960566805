import React from 'react';
import { useState } from "react";
import { Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);



const CountryWiseApplication = ({ graph }) => {

    const [userData, setUserData] = useState(graph?.data);


    return (

        <>
            <div className='amount_disbursed'>
                <div className='row graph_heading_div'>
                    <div className='col-12 graph_heading'>
                        <h6>Country Wise Application</h6>
                        <select className='form-select'>
                            <option>2021{ }</option>
                        </select>
                    </div>
                    <div className='col-12 '>
                        <Pie data={userData} height={250} />
                    </div>
                </div>
            </div>

        </>

    )
}

export default CountryWiseApplication;