import React from 'react';
import { useState } from "react";
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);



const CourseWiseApplicant = ({ graph }) => {
  const userDataNew = graph?.data;

  const [userData, setUserData] = useState({
    labels: userDataNew?.years?.map((data) => data.year),
    datasets: userDataNew?.datasets
  });


  return (

    <>
      <div className='amount_disbursed'>
        <div className='row graph_heading_div'>
          <div className='col-12 graph_heading'>
            <select className='form-select'>
              <option>2021{ }</option>
            </select>

          </div>
        </div>
        <Bar data={userData}
          options={{
            responsive: true,
            radius: 3,
            hitRadius: 3,
            hoverRadius: 5,
            plugins: {
              title: {
                display: true,
                text: 'Course Wise Applicant'
              },
            },
            interaction: {
              intersect: false
            },
            scales: {
              x: {
                display: true,
                barThickness: 6,
                ticks: {
                  display: true
                },
                title: {
                  display: true,
                  text: 'Course',
                }

              },
              y: {

                display: true,
                ticks: {
                  display: true
                },
                title: {
                  display: true,
                  text: 'Applicants'

                }
              }
            }
          }}

        />
      </div>

    </>

  )
}

export default CourseWiseApplicant;