import React, { useState, useEffect } from "react";
import H4 from '../text-components/H4';
import Label from "../text-components/Label";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from 'formik';
import { applicationCommunication } from "../communications/application-communication";
import { communication } from "../communications/communication"

const FamilyMembersAvailedScholarshipDetails = ({ familyMemberAvailedSccholarshipFormData }) => {

    // Dark White Theme
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    //****  Stopping field input- Enable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);

    const [initialFormValues, setInitialFormValues] = useState(null);

    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        courseYear: "",
        collegeName: "",
        city: "",
        scholarshipProvider: "",

    }

    //****Validation for Family income details form*****/
    let validationSchema = Yup.object().shape({
        courseYear: Yup.string().required("Cource Year is required")
            .matches(/^[0-9]+$/, "Must be only digits"),
        collegeName: Yup.string().required("College name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        city: Yup.string().required("city name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        scholarshipProvider: Yup.string().required("Field is required"),
    });

    const { handleSubmit, handleChange, values, errors, handleReset, setFieldValue } = useFormik({
        initialValues: initialFormValues === null ? defaultFormValues : initialFormValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {
            //set parent details object to send
            let familyDetailsForm = {
                courseYear: values.courseYear,
                collegeName: values.collegeName,
                city: values.city,
                scholarshipProvider: values.scholarshipProvider
            };

            familyMemberAvailedSccholarshipFormData(familyDetailsForm);
            handleReset();
            return false;
        },
        validationSchema: validationSchema,
    });

    return (
        <div className="family_details_main mb-5">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={handleSubmit}>
                            <div className="row scholarship_availed_form mt-5">
                                <H4 whiteThemeColor="#0d2750" darkThemeColor="#FFFFFF">Details of the family members who availed Abroad Scholarship except applicant:</H4>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Course Year</Label>
                                            <input name="courseYear" placeholder="E.g. 2021" onChange={handleChange} value={values.courseYear} type="number" className={`form-control ${errors.courseYear ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors.courseYear}</div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">College Name</Label>
                                            <input name="collegeName" placeholder="E.g. South Texas College." onChange={handleChange} value={values.collegeName} type="text" className={`form-control ${errors.collegeName ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors.collegeName}</div>
                                        </div>

                                        <div className="col-lg-3 col-md-3">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">City</Label>
                                            <input name="city" placeholder="E.g. Landon" onChange={handleChange} value={values.city} type="text" className={`form-control ${errors.city ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors.city}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Maharashtra Government/ Central Government or Other State Government Scholarship Program?</Label>
                                    <div className="row mt-2">
                                        <div className="col-lg-3 col-md-4 col-6">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Maharashtra Government</Label>
                                            <input type="radio" onChange={() => setFieldValue("scholarshipProvider", "MAHARASHTRA_GOVT")} checked={values.scholarshipProvider === "MAHARASHTRA_GOVT" ? true : false} value="MAHARASHTRA_GOVT" name="scholarshipProviderRadio" id="govscholarshipProvider" className={`form-check-input mx-2 ${errors.scholarshipProvider ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>

                                        <div className="col-lg-3 col-md-3 col-6">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Central Government</Label>
                                            <input type="radio" onChange={() => setFieldValue("scholarshipProvider", "CENTRAL_GOVT")} checked={values.scholarshipProvider === "CENTRAL_GOVT" ? true : false} value="CENTRAL_GOVT" name="scholarshipProviderRadio" id="centralscholarshipProvider" className={`form-check-input mx-2 ${errors.scholarshipProvider ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>

                                        <div className="col-lg-3 col-md-4 col-8">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Other State Government</Label>
                                            <input type="radio" onChange={() => setFieldValue("scholarshipProvider", "STATE_GOVT")} checked={values.scholarshipProvider === "STATE_GOVT" ? true : false} value="STATE_GOVT" name="scholarshipProvider" id="statescholarshipProvider" className={`form-check-input mx-2 ${errors.scholarshipProvider ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors.scholarshipProvider}</div>
                                        </div>

                                        <div className="col-lg-3 col-md-1 col-4 scholarship_availed_add_btn">
                                            <button type="button" onClick={() => { handleSubmit() }}>Add</button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </form>

                    </div>
                </div>
            </div>

        </div>

    );
}
export default FamilyMembersAvailedScholarshipDetails;