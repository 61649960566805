import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import H3 from "../../text-components/H3";
import H4 from "../../text-components/H4";
import P from "../../text-components/P";
import { useLocation } from "react-router-dom";
import { backOfficeCommunication } from "../../communications/back-office-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import { useDispatch } from "react-redux";
import { removeUnderscoreFromText } from '../../utlis/helper';

function MilestoneOneComponent(props) {
    // const dispatch = useDispatch();
    const [desabled, setDesabled] = useState(false);
    const history = useHistory();
    const {
        fontSizeMultiplier,
        milestone,
        showDocumentScrutinyUploadedDocumentPreview,
        closeDocumentScrutinyUploadedFiles,
        getMilestoneDocument,
        applicationId, type, showLoading, hideLoading, showModal, isLoading } = props;
    const [comment, setComment] = useState("");

    const getComment = (event) => {
        setComment(event.target.value);
    }

    useEffect(() => {

        if (milestone.backOfficeStatus === "APPROVED") {
            setDesabled(true);
        } else {
            setDesabled(false);
        }

    }, []);
    const markOneComplete = async () => {
        try {
            showLoading("Loading...");

            const completeMark = await backOfficeCommunication.markOneComplete(applicationId, type);

            if (completeMark.status === "SUCCESS") {
                showModal(completeMark.message);
            }
            else {
                showModal(completeMark.message);
            }

            hideLoading();

        } catch (error) {
            showModal(error);
            hideLoading();

        }

    }

    const markOneInComplete = async () => {
        try {
            showLoading("Loading...");
            if (comment === "") {
                showModal("you must add comment");
                hideLoading();
                return;
            }

            const incompleteMark = await backOfficeCommunication.markOneInComplete(applicationId, type, comment);

            if (incompleteMark.status === "SUCCESS") {
                showModal(incompleteMark.message);
            }
            else {
                showModal(incompleteMark.message);

            }
            hideLoading();

        } catch (error) {
            showModal(error);
            hideLoading();
        }
    }


    const goToApplication = async (applicationId, id, type) => {
        showLoading("Loading...");
        history.push(`/back-office/dashboard/application/?applicationId=${applicationId}&action=${type}&milestoneId=${id}`);
        hideLoading();
    }


    return (
        <>
            <div className="row">
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :
                    <div className="col-12 p-0">
                        <div className="back_office_preview_box">
                            <div className="back_office_preview_title">
                                <H4>{milestone?.name}</H4>
                                <div className="close_back_office_preview" onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row mt-3">
                                    <div className="col-6">

                                        <div className="uploaded_documents">
                                            {/*---heading section---*/}
                                            {
                                                milestone?.signedBondUrl && milestone?.signedBondUrl !== "" && milestone?.signedBondUrl.length > 0 &&
                                                <>
                                                    <div className="row">

                                                        <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                                            <span>
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Serial No.</H4>
                                                            </span>
                                                        </div>

                                                        <div className="col-10 p-0 ps-4">
                                                            <span>
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Name</H4>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/*---uploaded file section---*/}

                                                    <div className="row mt-3">
                                                        <div className="col-2"></div>
                                                        <div className="col-10 ps-4">
                                                            <span>
                                                                <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">Signed Bond Documents</H4>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </>

                                            }


                                            {milestone?.signedBondUrl && milestone?.signedBondUrl !== "" && milestone?.signedBondUrl.length > 0 && milestone?.signedBondUrl?.map((value, index) => {
                                                return (
                                                    <div className="row mt-3" key={index}>

                                                        <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                                            <span>
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">{index + 1}</H4>
                                                            </span>
                                                        </div>

                                                        <div className="col-10 p-0 ps-4">
                                                            <span>
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD"><a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, value?.fileUrl, value?.documentName)} title="Click to preview document">{value.documentName}<i className="fa fa-paperclip fa-1x ms-2" aria-hidden="true"></i></a></H4>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })

                                            }

                                        </div>


                                    </div>

                                    <div className="col-6">

                                        <div className="uploaded_documents">
                                            {/*---heading section---*/}
                                            {
                                                milestone?.documentsArray && milestone?.documentsArray !== "" && milestone?.documentsArray.length > 0 &&
                                                <>
                                                    <div className="row">

                                                        <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                                            <span>
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Serial No.</H4>
                                                            </span>
                                                        </div>

                                                        <div className="col-10 p-0 ps-4">
                                                            <span>
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Name</H4>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/*---uploaded file section---*/}




                                                    <div className="row mt-3">
                                                        <div className="col-2"></div>
                                                        <div className="col-10 ps-4">
                                                            <span>
                                                                <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">Other Documents</H4>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </>

                                            }

                                            {milestone?.documentsArray?.map((value, index) => {

                                                return (
                                                    <div className="row mt-3" key={index}>


                                                        <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                                            <span>
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">{index + 1}</H4>
                                                            </span>
                                                        </div>


                                                        <div className="col-10 p-0 ps-4">
                                                            <span>
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                    <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, value.fileUrl, value?.documentName)} title="Click to preview document">
                                                                        {value.documentName}<i className="fa fa-paperclip fa-1x ms-2" aria-hidden="true"></i>
                                                                    </a>
                                                                </H4>
                                                            </span>
                                                        </div>


                                                    </div>
                                                )
                                            })}

                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="comment_box_main">
                                    <div className="comment_box_heading">
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Comment Box</H4>
                                    </div>
                                    <div className="comment_box_body">
                                        <div className="comment_to_write_main">
                                            <div className="comment_to_write">
                                                <input className="form-control" placeholder="Write comment here..." onChange={getComment} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-lg-5">
                                <div className="upload_home_verification_button">
                                    <button className="ml-0" onClick={() => goToApplication(milestone.applicationId, milestone._id, milestone.type)}>Application Preview</button>
                                    <button className="ms-3 me-3" onClick={() => markOneInComplete(type)} disabled={desabled}>Incomplete</button>
                                    <button className="me-3" onClick={() => markOneComplete(type)} disabled={desabled}>Approve</button>
                                    <button onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button>
                                </div>
                            </div>
                        </div>

                    </div>
                }
            </div>
        </>
    )
}

function MilestoneTwoComponent(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [desabled, setDesabled] = useState(false);
    const {
        fontSizeMultiplier,
        milestone,
        showDocumentScrutinyUploadedDocumentPreview,
        closeDocumentScrutinyUploadedFiles,
        getMilestoneDocument,
        applicationId, type, showLoading, hideLoading, showModal, isLoading } = props;

    const [selectedFile, setSelectedFile] = useState(null);
    const [comment, setComment] = useState("");

    useEffect(() => {

        if (milestone.backOfficeStatus === "HV_COMPLETED") {
            setDesabled(true);
        } else {
            setDesabled(false);
        }
    }, []);

    const getComment = (event) => {
        setComment(event.target.value);
    }
    const markCompleteTwo = async () => {
        try {
            showLoading("Loading...");
            const completeMarkMilestoneTwo = await backOfficeCommunication.markTwoComplete(applicationId, type);
            if (completeMarkMilestoneTwo.status === "SUCCESS") {
                history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`);
            }
            showModal(completeMarkMilestoneTwo.message);
            hideLoading();
        } catch (error) {
            showModal(error);
        }
    }

    const markInCompleteTwo = async () => {
        try {
            showLoading("Loading...");
            if (comment === "") {
                showModal("Please add comment !");
                hideLoading();
                return;
            }

            const incompleteMarkMilestoneTwo = await backOfficeCommunication.markTwoInComplete(applicationId, type, comment);

            if (incompleteMarkMilestoneTwo.status === "SUCCESS") {
                history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`);
            }
            showModal(incompleteMarkMilestoneTwo.message);
            hideLoading();
        } catch (error) {
            showModal(error);
        }
    }

    const uploadHomeVerification = async () => {
        try {
            showLoading("Loading...");
            if (selectedFile === null) {
                return showModal("Please select file");
            }
            const formData = new FormData();
            formData.append("document", selectedFile);
            //  TODO VALIDATE FILE HERE
            const uploadHomeVerification = await backOfficeCommunication.uploadHomeVerification(applicationId, selectedFile);

            if (uploadHomeVerification.status === "SUCCESS" || uploadHomeVerification.data.status === "SUCCESS") {
                getMilestoneDocument();
            }
            showModal(uploadHomeVerification.message || uploadHomeVerification.data.message);
            hideLoading();

        } catch (error) {
            showModal(error);
        }
    }


    function selectFile(event) {
        event.preventDefault();
        let formData = new FormData();
        formData.append("document", event.target.files[0], event.target.files[0].name);
        setSelectedFile(formData);
    }


    const goToApplication = async (applicationId, id, type) => {
        showLoading("Loading...");
        history.push(`/back-office/dashboard/application/?applicationId=${applicationId}&action=${type}&milestoneId=${id}`);
        hideLoading();
    }

    return (
        <>

            <div className="row">
                <div className="col-12 p-0">
                    <div className="back_office_preview_box">
                        <div className="back_office_preview_title">
                            <H4>{milestone?.name}</H4>
                            <div className="close_back_office_preview" onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className="uploaded_documents">
                            {/*---heading section---*/}
                            <div className="row mt-3">

                                <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                    <span>
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Serial No.</H4>
                                    </span>
                                </div>

                                <div className="col-10 p-0 ps-4">
                                    <span>
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Name</H4>
                                    </span>
                                </div>
                            </div>
                            {/*---uploaded file section---*/}
                            <div className="row mt-3">

                                <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                    <span>
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>{milestone.index - 1}</H4>
                                    </span>
                                </div>

                                <div className="col-10 p-0 ps-4 ">
                                    <span>
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}><a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.homeVerificationCertificateUrl)} title="Click to preview document"> Home Verification Certificate <i className="fa fa-paperclip fa-1x ms-2" aria-hidden="true"></i></a></H4>
                                    </span>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="comment_box_main">
                                <div className="comment_box_heading">
                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Comment Box</H4>
                                </div>
                                <div className="comment_box_body">
                                    <div className="comment_to_write_main">
                                        <div className="comment_to_write">
                                            <input className="form-control" placeholder="Write comment here..." onChange={getComment} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-5">

                            <div className="upload_report_section row">
                                <div className="col-lg-6">
                                    <input type="file" name="document" className="form-control" id="inputGroupFile02" onChange={(e) => { selectFile(e) }} />
                                </div>
                                <div className="col-lg-6">
                                    <button className="ms-3" onClick={uploadHomeVerification} disabled={desabled}> Upload </button>
                                </div>
                            </div>

                            <div className="upload_home_verification mt-3">
                                <button className="me-3" onClick={() => goToApplication(milestone.applicationId, milestone._id, milestone.type)}>Application Preview</button>
                                <button className="me-3" onClick={() => markInCompleteTwo()} disabled={desabled}>Incomplete</button>
                                <button className="me-3" onClick={() => markCompleteTwo()} disabled={desabled}>Approve</button>
                                <button onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button>
                            </div>


                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

function MilestoneThreeComponent(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [desabled, setDesabled] = useState(false);
    const {
        fontSizeMultiplier,
        milestone,
        showDocumentScrutinyUploadedDocumentPreview,
        closeDocumentScrutinyUploadedFiles,
        applicationId, type, showLoading, hideLoading, showModal, isLoading, backOfficeUsers,
        getMilestoneDocument } = props;

    const [selectedFile, setSelectedFile] = useState(null);
    const [userId, setUserId] = useState("");

    const [comment, setComment] = useState("");

    const getComment = (event) => {
        setComment(event.target.value);
    }
    const markCompleteThree = async () => {
        try {
            showLoading("Loading...");

            const completeMarkMilestoneTwo = await backOfficeCommunication.markCompleteThree(milestone._id, type);

            if (completeMarkMilestoneTwo.status === "SUCCESS") {
                // history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`);
                getMilestoneDocument();
            }
            showModal(completeMarkMilestoneTwo.message);

            hideLoading();
        } catch (error) {
            showModal(error);
        }

    }

    const markInCompleteThree = async () => {
        try {
            if (comment === "") {
                showModal("Please enter comment");
                return;
            }
            showLoading("Loading...");

            const incompleteMarkMilestoneTwo = await backOfficeCommunication.markInCompleteThree(milestone._id, comment);

            if (incompleteMarkMilestoneTwo.status === "SUCCESS") {
                history.push(`/back-office/dashboard/document-scrutiny`);
            }
            else {
                showModal(incompleteMarkMilestoneTwo.message);

            }

            hideLoading();
        } catch (error) {
            showModal(error);
        }

    }

    const UploadOfficeExpenditureReport = async () => {
        try {
            showLoading("Loading...");

            if (selectedFile === null) {
                return showModal("Please select file");
            }
            const formData = new FormData();
            formData.append("document", selectedFile);
            //  TODO VALIDATE FILE HERE
            const uploadExpenditureReport = await backOfficeCommunication.uploadExpenditureReport(milestone._id, milestone.applicationId, formData);
            if (uploadExpenditureReport.status === "SUCCESS" || uploadExpenditureReport.data.status) {
                getMilestoneDocument();
            }
            showModal(uploadExpenditureReport.message || uploadExpenditureReport.data.message);
            hideLoading();

        } catch (error) {
            showModal(error);
        }

    }

    const assignedMilestoneToUser = async () => {
        try {
            if (comment === "") {
                showModal("Please enter comment");
                return;
            }
            showLoading("Loading...");

            const incompleteMarkMilestoneTwo = await backOfficeCommunication.assignBackOfficeToMilestone(milestone._id, userId, comment);

            if (incompleteMarkMilestoneTwo.status === "SUCCESS") {
                history.push(`/back-office/dashboard/document-scrutiny?applicationId=${applicationId}`);
            }
            else {
                showModal(incompleteMarkMilestoneTwo.message);
            }

            hideLoading();
        } catch (error) {
            showModal(error);
        }

    }
    const changeHandler = (e) => {
        let userId = e.target.value;
        setUserId(userId)

    }

    useEffect(() => {
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("document-scrutiny")) {
            dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
        }

        if (milestone.backOfficeStatus === "APPROVED") {
            setDesabled(true);
        } else {
            setDesabled(false);
        }

    }, []);

    function selectFile(e) {

        setSelectedFile(e.target.files[0]);
    }

    return (
        <>

            <div className="row">
                <div className="col-12 p-0">
                    <div className="back_office_preview_box">
                        <div className="back_office_preview_title">
                            <H4>{milestone?.name}</H4>
                            <div className="close_back_office_preview" onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className="uploaded_documents">
                            {/*---heading section---*/}
                            <div className="row mt-3">

                                <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                    <span>
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Serial No.</H4>
                                    </span>
                                </div>

                                <div className="col-10 p-0 ps-4">
                                    <span>
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Name</H4>
                                    </span>
                                </div>
                            </div>
                            {/*---uploaded file section---*/}

                            {
                                milestone?.expenditureReportUrl && milestone?.expenditureReportUrl !== "" && <div className="row mt-3">

                                    <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                        <span>
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">{1}</H4>
                                        </span>
                                    </div>

                                    <div className="col-10 p-0 ps-4">
                                        <span>

                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD"><a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.expenditureReportUrl, milestone?.expenditureReportUrl, milestone?.fileUrl)} title="Click to preview document">Expenditure Report by candidate<i className="fa fa-paperclip fa-1x ms-2" aria-hidden="true"></i></a></H4>
                                        </span>
                                    </div>
                                </div>
                            }

                            {
                                milestone?.officeExpenditureReportUrl && milestone?.officeExpenditureReportUrl !== "" &&

                                <div className="row mt-3">

                                    <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                        <span>
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">{2}</H4>
                                        </span>
                                    </div>

                                    <div className="col-10 p-0 ps-4">
                                        <span>

                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD"><a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.officeExpenditureReportUrl, milestone?.officeExpenditureReportUrl, milestone?.fileUrl)} title="Click to preview document">Expenditure Report by Office <i className="fa fa-paperclip fa-1x ms-2" aria-hidden="true"></i></a></H4>
                                        </span>
                                    </div>

                                </div>
                            }

                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-2"></div>
                        <div className="col-lg-6 col-md-10 mt-lg-5 mt-md-5">

                            <div className="upload_report_section">
                                <input type="file" className="form-control form-select-sm" id="inputGroupFile02" title="upload expenditure report" onChange={(e) => { selectFile(e) }} />
                                <button className="ms-3" onClick={UploadOfficeExpenditureReport} disabled={desabled}> Upload </button>
                            </div>

                            <div className="Assign_section">
                                <div className="select_to_assign">
                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Assign To</H4>
                                    <select className="form-control form-select form-select-sm" onChange={(e) => { changeHandler(e) }}>
                                        <option>select</option>
                                        {
                                            backOfficeUsers.map(((value, index) => {
                                                const { userId, name } = value;
                                                return (
                                                    <option key={index} value={userId}>{name}</option>
                                                )
                                            }))
                                        }
                                    </select>
                                </div>
                                <textarea className="form-control mt-3 mb-3" placeholder="Write your comment..." onChange={getComment}></textarea>
                                <button className="ms-3" onClick={assignedMilestoneToUser} disabled={desabled}>Assign To</button>
                            </div>


                            <div className="upload_home_verification_button">
                                <button className="ms-3 me-3" onClick={() => markInCompleteThree()} disabled={desabled}>Incomplete</button>
                                <button className="me-3" onClick={() => markCompleteThree()} disabled={desabled}>Approve</button>
                                <button onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

function MilestoneFourComponent(props) {
    const dispatch = useDispatch();
    const [desabled, setDesabled] = useState(false);
    const {
        fontSizeMultiplier,
        milestone,
        showDocumentScrutinyUploadedDocumentPreview,
        closeDocumentScrutinyUploadedFiles,
        getMilestoneDocument,
        applicationId, type, showLoading, hideLoading, showModal, isLoading } = props;

    const [sponsorshipLetter, setSponsorshipLetter] = useState()
    const message = useRef();
    const universityEmail = useRef()

    useEffect(() => {

        if (milestone.backOfficeStatus === "APPROVED") {
            setDesabled(true);
        } else {
            setDesabled(false);
        }
    }, []);
    //  when back office user will upload sponsorship letter it will be status will be marked as
    const UploadSponsorshipReport = async () => {
        try {
            showLoading("Loading...");


            if (sponsorshipLetter === null) {
                return showModal("Please attach file");
            }

            const formData = new FormData();
            formData.append("sponsorshipLetter", sponsorshipLetter);
            //  TODO VALIDATE FILE HERE
            const uploadSponsorShip = await backOfficeCommunication.uploadSponsorshipLetter(applicationId, formData);

            if (uploadSponsorShip.status === "SUCCESS" || uploadSponsorShip.data.status === "SUCCESS") {
                getMilestoneDocument();
            }
            showModal(uploadSponsorShip.message || uploadSponsorShip.data.message);
            hideLoading();
        } catch (error) {
            showModal(error);
        }

    }


    function selectFile(e) {
        setSponsorshipLetter(e.target.files[0]);
    }
    const sendMail = async () => {
        const userId = milestone.userId;
        const universityMessage = message.current.value;
        const email = universityEmail.current.value;
        try {
            showLoading("Loading...");
            const mailSend = await backOfficeCommunication.sendMailToUniversity(applicationId, userId, universityMessage, email);
            if (mailSend.status === "SUCCESS") {
                hideLoading();
                showModal(mailSend.message);
                return;
            }

            if (mailSend.status === "VALIDATION_FAILED") {
                hideLoading();
                showModal(mailSend.validationErrorsList[0].message);
                return;
            }

            showModal(mailSend.message);
            hideLoading();
        } catch (error) {
            showModal(error);
        }
    }

    return (
        <>
            <div className="row">

                <div className="col-12 p-0">
                    <div className="back_office_preview_box">
                        <div className="back_office_preview_title">
                            <H4>{milestone?.name}</H4>
                            <div className="close_back_office_preview" onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className="uploaded_documents">
                            {/*---heading section---*/}
                            <div className="row mt-3">

                                <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                    <span>
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Serial No.</H4>
                                    </span>
                                </div>

                                <div className="col-10 p-0 ps-4">
                                    <span>
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Name</H4>
                                    </span>
                                </div>
                            </div>
                            {/*---uploaded file section---*/}

                            {
                                milestone?.sponsorshipLetterUrl && milestone?.sponsorshipLetterUrl !== "" && <div className="row mt-3">

                                    <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                        <span>
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">{1}</H4>
                                        </span>
                                    </div>

                                    <div className="col-10 p-0 ps-4">
                                        <span>
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD"><a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.sponsorshipLetterUrl)} title="Click to preview document"> sponsorship Report Letter <i className="fa fa-paperclip fa-1x ms-2" aria-hidden="true"></i></a></H4>
                                        </span>
                                    </div>

                                </div>
                            }



                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            {/* <div className="comment_box_main">
                                <div className="comment_box_heading">
                                    <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>Comment Box</P>
                                </div>
                                <div className="comment_box_body">
                                    <div className="comment_to_write_main">
                                        <div className="comment_to_write">
                                            <input className="form-control" placeholder="Write comment here..." />
                                            <button>Send <i className="fa fa-paper-plane" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-lg-6 mt-lg-5">
                            <div className="row">
                                <div className="col-lg-2 col-md-4"></div>
                                <div className="col-lg-10 col-md-8 mt-md-3">
                                    <div className="upload_report_section">
                                        <input type="file" className="form-control" id="inputGroupFile02" title="upload sponsorship letter" onChange={(e) => { selectFile(e) }} />
                                        <button className="ms-3" onClick={UploadSponsorshipReport} disabled={desabled}> Upload </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2 col-md-4"></div>
                                <div className="col-lg-10 col-md-8">
                                    <div className="send_email_to_university">
                                        <input type="text" className="form-control" id="inputGroupFile02" placeholder="enter email" ref={universityEmail} />
                                        <textarea style={{ minHeight: "50px" }} className="form-control" placeholder="message..." ref={message} />
                                    </div>
                                </div>
                            </div>
                            <div className="send_email_to_university_button">
                                <button onClick={sendMail} disabled={desabled}>Send Mail To University</button>
                                <button className="ms-3" onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button>
                            </div>
                            <div className="upload_home_verification_button">
                                {/* <button className="me-3" onClick={() => markCompleteFour()}>Reviewed</button> */}
                                {/* <button onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

function MilestoneFiveComponent(props) {
    const history = useHistory();
    const [desabled, setDesabled] = useState(false);
    const {
        fontSizeMultiplier,
        milestone,
        showDocumentScrutinyUploadedDocumentPreview,
        closeDocumentScrutinyUploadedFiles,
        applicationId, type, showLoading, hideLoading, showModal, isLoading } = props;

    const [getSelect, SetGetSelect] = useState(null);
    const [comment, setComment] = useState("");

    useEffect(() => {

        if (milestone.backOfficeStatus === "APPROVED") {
            setDesabled(true);
        } else {
            setDesabled(false);
        }
    }, []);
    const getComment = (event) => {
        setComment(event.target.value);
    }

    const markFundReceive = async () => {
        try {
            showLoading("Loading...");

            const markFundReceive = await backOfficeCommunication.markFundReceive(milestone._id);

            if (markFundReceive.status === "SUCCESS") {
                history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}&type=${type}`);
            }
            hideLoading();
            showModal(markFundReceive.message);
        } catch (error) {
            showModal(error);
        }

    }

    const requestToTreasury = async () => {
        try {
            showLoading("Loading...");
            const requestToTreasury = await backOfficeCommunication.requestToTreasury(applicationId);
            if (requestToTreasury.status === "SUCCESS") {
                history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}&type=${type}`);
            }
            hideLoading();
            showModal(requestToTreasury.message);
        } catch (error) {
            showModal(error);
        }

    }


    const ReceiptUpload = async () => {
        try {
            showLoading("Loading...");

            if (getSelect === null) {
                return showModal("Please select file");
            }

            const formData = new FormData();
            formData.append("document", getSelect);
            //  TODO VALIDATE FILE HERE
            const uploadBankChalan = await backOfficeCommunication.uploadReceipt(applicationId, formData);

            if (uploadBankChalan.status === "SUCCESS" || uploadBankChalan.data.status === "SUCCESS") {
                history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}&type=${type}`);
            }
            hideLoading();
            showModal(uploadBankChalan.message || uploadBankChalan.data.message);

        } catch (error) {
            showModal(error);
        }

    }

    const markAsIncomplete = async () => {
        try {
            showLoading("Loading...");
            if (comment === "") {
                showModal("Please enter a comment");
                hideLoading();
                return;
            }

            const uploadComment = await backOfficeCommunication.markMilestoneFiveAsIncomplete(milestone._id, milestone.userId, comment);

            if (uploadComment.status === "SUCCESS") {
                history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}&type=${type}`);
            }
            showModal(uploadComment.message);
            hideLoading();
        } catch (error) {
            showModal(error);
        }

    }

    function selectFile(e) {

        SetGetSelect(e.target.files[0]);
    }
    return (
        <>
            <div className="row">
                <div className="col-12 p-0">
                    <div className="back_office_preview_box">
                        <div className="back_office_preview_title">
                            <H4>{removeUnderscoreFromText(milestone?.name)}</H4>
                            <div className="close_back_office_preview" onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className="uploaded_documents">
                            {/*---heading section---*/}
                            <div className="row mt-3 ms-4">


                                <div className="col-10 p-0 ps-4">
                                    <span>
                                        <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">Name</H4>
                                    </span>
                                </div>
                            </div>
                            {/*---uploaded file section---*/}

                            <div className="row mt-3 ms-4">
                                {
                                    milestone?.prevDocuments && milestone?.prevDocuments !== "" && milestone?.prevDocuments.length > 0 && milestone?.prevDocuments.map((ele, idx) => {
                                        return (
                                            <div className="row " Key={idx}>


                                                <div className="col-12 p-0 ps-4">
                                                    <span>
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                            <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, ele.fileUrl)} title="Click to preview document">
                                                                {ele.documentName} <i className="fa fa-paperclip fa-1x ms-1" aria-hidden="true"></i>
                                                            </a>
                                                        </H4>
                                                    </span>
                                                </div>

                                            </div>
                                        )
                                    })
                                }

                            </div>


                            <div className="row mt-3 ms-4">
                                {
                                    milestone?.userFundsReceiptUrl && milestone?.userFundsReceiptUrl !== "" && <div className="row ">


                                        <div className="col-12 p-0 ps-4">
                                            <span>

                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                    <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.fundsReceivedUrl)} title="Click to preview document">
                                                        User fund Receipt  <i className="fa fa-paperclip fa-1x ms-1" aria-hidden="true"></i>
                                                    </a>
                                                </H4>
                                            </span>
                                        </div>

                                    </div>
                                }
                                {
                                    milestone?.treasuryConfirmationReceipt && milestone?.treasuryConfirmationReceipt !== "" && <div className="row mt-3">

                                        <div className="col-12 p-0 ps-4">
                                            <span>
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                    <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.treasuryConfirmationReceipt,)} title="Click to preview document">
                                                        Treasury confirmation Receipt <i className="fa fa-paperclip fa-1x ms-1" aria-hidden="true"></i>
                                                    </a>
                                                </H4>
                                            </span>
                                        </div>
                                    </div>
                                }
                                {/* {
                                    milestone?.expenditureReportUrl && milestone?.expenditureReportUrl !== "" && <div className="row mt-3">

                                        <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                            <span>
                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{1}</P>
                                            </span>
                                        </div>

                                        <div className="col-10 p-0 ps-4">
                                            <span>

                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.expenditureReportUrl)} title="Click to preview document">Receipt Url</a></P>
                                            </span>
                                        </div>

                                    </div>
                                } */}
                                {
                                    milestone?.semesterFeeAndAcknowledgementCertificateUrl && milestone?.semesterFeeAndAcknowledgementCertificateUrl !== "" && <div className="row mt-3">

                                        <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                            <span>
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">{1}</H4>
                                            </span>
                                        </div>

                                        <div className="col-10 p-0 ps-4">
                                            <span>

                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD"><a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.semesterFeeAndAcknowledgementCertificateUrl)} title="Click to preview document">Semester Fee and Acknowledgement Certificate</a></H4>
                                            </span>
                                        </div>

                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="comment_box_main">
                                <div className="comment_box_heading">
                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Comment Box</H4>
                                </div>
                                <div className="comment_box_body">
                                    <div className="comment_to_write_main">
                                        <div className="comment_to_write">
                                            <input className="form-control" placeholder="Write comment here..." onChange={getComment} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mt-lg-5 mt-md-5">

                            <div className="upload_report_section">
                                <input type="file" className="form-control" id="inputGroupFile02" title="upload Receipt" onChange={selectFile} />
                                <button className="ms-3" onClick={ReceiptUpload} disabled={desabled}> Upload </button>
                            </div>
                            <div className="upload_files_btn  mt-3 " style={{ textAlign: "right" }}>
                                <div className="home_verification_button mb-3">
                                    {/* <button className="me-3" onClick={() => markAsIncomplete()}>Mark Incomplete</button> */}
                                    <button className="mt-3 me-2" onClick={() => requestToTreasury()} disabled={desabled}>Requested To Treasury</button>
                                    <button className="mt-3" onClick={() => markFundReceive()} disabled={desabled}>Fund Received by Treassury</button>
                                </div>
                                <button onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

// Semester fees 
function GeneralMilestone(props) {
    const dispatch = useDispatch();
    const [desabled, setDesabled] = useState(false);
    const {
        fontSizeMultiplier,
        milestone,
        showDocumentScrutinyUploadedDocumentPreview,
        closeDocumentScrutinyUploadedFiles,
        applicationId,
        type,
        showLoading,
        hideLoading,
        showModal,
        getMilestoneDocument,
        isLoading } = props;

    const [getSelect, setSelectedFile] = useState(null);
    const [comment, setComment] = useState("");

    const getCommentForGeneralMilestone = (event) => {
        setComment(event.target.value);
    }

    useEffect(() => {
        if (milestone.backOfficeStatus === "APPROVED") {
            setDesabled(true);
        } else {
            setDesabled(false);
        }
    }, []);
    const requestToTreasuryForGeneralMilestone = async () => {
        try {
            showLoading("Loading...");


            const requestToTreasury = await backOfficeCommunication.requestToTreasuryForGeneralMilestone(milestone._id);

            if (requestToTreasury.status === "SUCCESS") {
                hideLoading();
                showModal(requestToTreasury.message);
            }
            else {
                hideLoading();
                showModal(requestToTreasury.message);

            }

        } catch (error) {
            showModal(error);
        }

    }

    const markFundReceiveForGeneralMilestone = async () => {
        try {
            showLoading("Loading...");

            const markFundReceive = await backOfficeCommunication.markFundReceiveForGeneralMilestone(milestone._id);

            if (markFundReceive.status === "SUCCESS") {
                hideLoading();
                showModal(markFundReceive.message);
            }
            else {
                hideLoading();
                showModal(markFundReceive.message);

            }

        } catch (error) {
            showModal(error);
        }

    }




    const ReceiptUploadForGeneralMilestone = async () => {
        try {
            showLoading("Loading...");

            if (getSelect === null) {
                return showModal("Please select file");
            }

            const formData = new FormData();
            formData.append("document", getSelect);
            //  TODO VALIDATE FILE HERE
            const uploadReceipt = await backOfficeCommunication.uploadReceiptForGeneralMilestone(applicationId, formData, milestone._id);
            if (uploadReceipt.status === "SUCCESS") {
                hideLoading();
                showModal(uploadReceipt.data.message);
            }
            else {
                hideLoading();
                showModal(uploadReceipt.data.message);
            }

        } catch (error) {
            showModal(error);
        }

    }

    const generalMilestoneAsIncomplete = async () => {
        try {
            showLoading("Loading...");
            if (comment.trim() === "") {
                showModal("you must include comment");
                hideLoading();
                return true;
            }

            const result = await backOfficeCommunication.markGeneralMilestoneAsIncomplete(milestone._id, milestone.userId, comment);

            if (result.status === "SUCCESS") {
                showModal(result.message);
            } else {
                showModal(result.message);
            }
            hideLoading();
        } catch (error) {
            showModal(error);
            hideLoading();
        }

    }


    function selectFile(e) {

        setSelectedFile(e.target.files[0]);
    }
    return (
        <>
            <div className="row">
                <div className="col-12 p-0">
                    <div className="back_office_preview_box">
                        <div className="back_office_preview_title">
                            <H4> {removeUnderscoreFromText(milestone.name)}</H4>
                            <div className="close_back_office_preview" onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className="uploaded_documents ps-4 pt-3 pb-2">
                            {/*---heading section---*/}
                            <div className="row">
                                <div className="col-4" style={{ overflow: "hidden" }}>
                                    {
                                        milestone.type === "SEM_FEES" &&
                                        <span>
                                            <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">Previous Semester document</H4>
                                        </span>
                                    }

                                    {
                                        milestone?.prevDocuments && milestone?.prevDocuments !== "" && milestone?.prevDocuments.length > 0 && milestone?.prevDocuments.map((ele, idx) => {
                                            return (
                                                <div className="mt-2" key={idx}>
                                                    <span>
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                            <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, ele.fileUrl)} title="Click to preview document">
                                                                {ele.documentName} <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                            </a>
                                                        </H4>
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className="col-4">
                                    {
                                        ((milestone?.userFundsReceiptUrl && milestone?.userFundsReceiptUrl !== "") || (milestone?.treasuryConfirmationReceipt && milestone?.treasuryConfirmationReceipt !== "")) &&
                                        <span>
                                            <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00"> Office user Uploaded Document</H4>
                                        </span>
                                    }

                                    {
                                        milestone?.userFundsReceiptUrl && milestone?.userFundsReceiptUrl !== "" && milestone?.userFundsReceiptUrl.map((ele, idx) => {
                                            return (
                                                <div className="mt-2 mb-2">
                                                    <span>
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                            <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, ele?.fileUrl
                                                            )} title="Click to preview document">
                                                                {ele.documentName} <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                            </a>
                                                        </H4>
                                                    </span>
                                                </div>
                                            )
                                        })

                                    }

                                    {
                                        milestone?.treasuryConfirmationReceipt && milestone?.treasuryConfirmationReceipt !== "" &&
                                        <span>
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.treasuryConfirmationReceipt,)} title="Click to preview document">
                                                    Treasury confirmation Recipt <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                </a>
                                            </H4>
                                        </span>
                                    }

                                    {
                                        milestone?.semesterFeeAndAcknowledgementCertificateUrl && milestone?.semesterFeeAndAcknowledgementCertificateUrl !== "" &&
                                        <div className="mt-2 mb-2">
                                            <span>
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                    <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.semesterFeeAndAcknowledgementCertificateUrl)} title="Click to preview document">
                                                        SemesterFee And acknowledgement Certificate
                                                    </a>
                                                </H4>
                                            </span>
                                        </div>
                                    }

                                </div>

                                <div className="col-4">
                                    {
                                        milestone.type === "SEM_FEES" &&
                                        <span>
                                            <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">User uploaded Document</H4>
                                        </span>
                                    }

                                    {
                                        milestone?.hostelFeesReportUrl && milestone?.hostelFeesReportUrl !== "" &&
                                        <div className="mt-2 mb-2">
                                            <span>
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                    <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone.hostelFeesReportUrl
                                                    )} title="Click to preview document">
                                                        Hostel Fees Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                    </a>
                                                </H4>
                                            </span>
                                        </div>
                                    }

                                    {
                                        milestone?.marksheetReportUrl && milestone?.marksheetReportUrl !== "" &&
                                        <span>
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone.marksheetReportUrl)} title="Click to preview document">
                                                    Marksheet Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                </a>
                                            </H4>
                                        </span>
                                    }

                                    {
                                        milestone?.attendenceReportUrl && milestone?.attendenceReportUrl !== "" &&
                                        <div className="mt-2 mb-2">
                                            <span>
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                    <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone.attendenceReportUrl)} title="Click to preview document">
                                                        Attendence Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                    </a>
                                                </H4>
                                            </span>
                                        </div>
                                    }

                                    {
                                        milestone?.foodExpenseReportUrl && milestone?.foodExpenseReportUrl !== "" &&
                                        <span>
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone.foodExpenseReportUrl
                                                )} title="Click to preview document">
                                                    Foods Expense Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                </a>
                                            </H4>
                                        </span>
                                    }

                                </div>


                            </div>


                            {/* <div className="row">

                                <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                    <span>
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Sr. No.</H4>
                                    </span>
                                </div>

                                {
                                    milestone.type === "SEM_FEES" && <>
                                        <div className="col-6 p-0 ps-4">
                                            <span>
                                                <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">Previous Sem document</H4>
                                            </span>
                                        </div>
                                        <div className="col-6 p-0 ps-4">
                                            <span>
                                                <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">User uploaded Document</H4>
                                            </span>
                                        </div>
                                    </>
                                }




                            </div> */}
                            {/*---uploaded file section---*/}

                            {/* <div className="row">
                                <div className="col-6">


                                    {
                                        milestone?.prevDocuments && milestone?.prevDocuments !== "" && milestone?.prevDocuments.length > 0 && milestone?.prevDocuments.map((ele, idx) => {
                                            return (
                                                <div className="row mt-3" Key={`expenditureReport-${idx}`}>



                                                    <div className="col-12 p-0 ps-4">
                                                        <span>

                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, ele.fileUrl)} title="Click to preview document">
                                                                    {ele.documentName} <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                                </a>
                                                            </H4>
                                                        </span>
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                <div className="col-6">

                                    {
                                        milestone?.hostelFeesReportUrl && milestone?.hostelFeesReportUrl !== "" &&
                                        <div className="row mt-3" Key={`hostelFeesReportUrl`} >

                                            <div className="col-12 p-0 ps-4">
                                                <span>

                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone.hostelFeesReportUrl
                                                        )} title="Click to preview document">
                                                            Hostel Fees Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                        </a>
                                                    </H4>
                                                </span>
                                            </div>

                                        </div>

                                    }

                                    {
                                        milestone?.marksheetReportUrl && milestone?.marksheetReportUrl !== "" &&

                                        <div className="row mt-3" Key={`hostelFeesReportUrl`} >

                                            <div className="col-12 p-0 ps-4">
                                                <span>
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone.marksheetReportUrl)} title="Click to preview document">
                                                            Marksheet Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                        </a>
                                                    </H4>
                                                </span>
                                            </div>

                                        </div>


                                    }


                                    {
                                        milestone?.attendenceReportUrl && milestone?.attendenceReportUrl !== "" &&
                                        <div className="row mt-3" Key={`hostelFeesReportUrl`} >

                                            <div className="col-12 p-0 ps-4">
                                                <span>
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone.attendenceReportUrl)} title="Click to preview document">
                                                            Attendence Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                        </a>
                                                    </H4>
                                                </span>
                                            </div>

                                        </div>


                                    }


                                    {
                                        milestone?.foodExpenseReportUrl && milestone?.foodExpenseReportUrl !== "" &&
                                        <div className="row mt-3" Key={`hostelFeesReportUrl`} >

                                            <div className="col-12 p-0 ps-4">
                                                <span>
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone.foodExpenseReportUrl
                                                        )} title="Click to preview document">
                                                            Foods Expense Report <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                        </a>
                                                    </H4>
                                                </span>
                                            </div>

                                        </div>


                                    }

                                </div>
                            </div> */}

                            {/* <div className="row mt-3">
                                {
                                    milestone.otherDocuments && milestone.otherDocuments && milestone.otherDocuments.length > 0 && milestone.otherDocuments.map((ele, idx) => {
                                        return (
                                            <>
                                                <div className="row ">
                                                
                                                    <div className="col-12 p-0 ps-4">
                                                        <span>
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, ele.fileUrl)} title="Click to preview document">
                                                                    {ele.documentName} <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                                </a>
                                                            </H4>
                                                        </span>
                                                    </div>


                                                </div>
                                            </>
                                        )
                                    })
                                }

                            </div> */}

                            {/* user uploaded  forms start*/}


                            {/* user uploaded forms end */}
                            {/* 
                            <div className="row mt-3">
                                <div className="col-12 p-0 ps-4" >
                                    {
                                        ((milestone?.userFundsReceiptUrl && milestone?.userFundsReceiptUrl !== "") || (milestone?.treasuryConfirmationReceipt && milestone?.treasuryConfirmationReceipt !== "")) &&
                                        <span>
                                            <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00"> Office user Uploaded </H4>
                                        </span>
                                    }
                                </div>
                            </div> */}


                            {/* <div className="row mt-3">

                                {
                                    milestone?.userFundsReceiptUrl && milestone?.userFundsReceiptUrl !== "" && milestone?.userFundsReceiptUrl.map((ele, idx) => {
                                        return (

                                            <div className="row" Key={`userFundReceiptUrls-${idx}`} >

                                                <div className="col-12 p-0 ps-4">
                                                    <span>

                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                            <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, ele?.fileUrl
                                                            )} title="Click to preview document">
                                                                {ele.documentName} <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                            </a>
                                                        </H4>
                                                    </span>
                                                </div>

                                            </div>
                                        )
                                    })

                                }


                                {
                                    milestone?.treasuryConfirmationReceipt && milestone?.treasuryConfirmationReceipt !== "" && <div className="row mt-3">

                                        <div className="col-12 p-0 ps-4">
                                            <span>

                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                    <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.treasuryConfirmationReceipt,)} title="Click to preview document">
                                                        Treasury confirmation Recipt <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                    </a>
                                                </H4>
                                            </span>
                                        </div>
                                        

                                    </div>
                                }     

                                {
                                    milestone?.semesterFeeAndAcknowledgementCertificateUrl && milestone?.semesterFeeAndAcknowledgementCertificateUrl !== "" && <div className="row mt-3">

                                        <div className="col-12 p-0 ps-4">
                                            <span>

                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                    <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.semesterFeeAndAcknowledgementCertificateUrl)} title="Click to preview document">
                                                        SemesterFee And acknowledgement Certificate
                                                    </a>
                                                </H4>
                                            </span>
                                        </div>
                                       
                                    </div>
                                }
                            </div> */}

                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="comment_box_main">
                                <div className="comment_box_heading">
                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Comment Box</H4>
                                </div>
                                <div className="comment_box_body">
                                    <div className="comment_to_write_main">
                                        <div className="comment_to_write">
                                            <input className="form-control" placeholder="Write comment here..." onChange={getCommentForGeneralMilestone} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mt-lg-5 mt-md-5">

                            <div className="upload_report_section">
                                <input type="file" className="form-control" id="inputGroupFile02" title="upload Receipt" onChange={selectFile} />
                                <button className="ms-3" onClick={ReceiptUploadForGeneralMilestone} disabled={desabled}> Upload </button>
                            </div>
                            <div className="upload_files_btn mt-3" style={{ textAlign: "right" }}>
                                <div className="home_verification_button mb-3">
                                    {/* <button className="me-3" onClick={() => generalMilestoneAsIncomplete()}>Mark as incomplete</button> */}
                                    <button className="me-3" onClick={() => requestToTreasuryForGeneralMilestone()} disabled={desabled}>Request To Treasury</button>
                                    <button onClick={() => markFundReceiveForGeneralMilestone()} disabled={desabled}>Fund Received by Treassury</button>
                                </div>
                                <button onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button>
                            </div>

                            {/* <div className="input-group mb-3">
                                <input type="file" className="form-control" id="inputGroupFile02" onChange={selectFile} />
                                <label className="input-group-text" for="inputGroupFile02">Recipt Upload </label>
                            </div>
                            <div className="input-group mb-3">
                                <button className="btn btn-secondary btn-block" onClick={ReciptUploadForGeneralMilestone}> Upload </button>
                            </div>

                            <div className="col-lg-6 mt-lg-5">
                                <div className="upload_home_verification_button">
                                    <button className="btn btn-secondary btn-outline me-3" onClick={() => requestToTreassuryForGeneralMilestone()}>Request To Treassury</button>
                                    <button className="btn btn-secondary btn-outline me-3" onClick={() => markFundReciveForGeneralMilestone()}>Mark Fund Received</button>
                                    <button onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

// this milestone is similar like milestone three
function GeneralMilestoneNewSession(props) {
    const dispatch = useDispatch();
    const [desabled, setDesabled] = useState(false);
    const history = useHistory();
    const {
        fontSizeMultiplier,
        milestone,
        showDocumentScrutinyUploadedDocumentPreview,
        closeDocumentScrutinyUploadedFiles,
        getMilestoneDocument,
        applicationId, type, showLoading, hideLoading, showModal, isLoading, backOfficeUsers } = props;

    const [selectedFile, setSelectedFile] = useState(null);
    const [userId, setUserId] = useState("");


    const markComplete = async () => {
        try {
            showLoading("Loading...");

            const completeMarkMilestoneTwo = await backOfficeCommunication.markCompleteNewSession(milestone._id, type);

            if (completeMarkMilestoneTwo.status === "SUCCESS") {
                history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`);
            }
            showModal(completeMarkMilestoneTwo.message);

            hideLoading();
        } catch (error) {
            showModal(error);
        }

    }

    const markInComplete = async () => {
        try {
            showLoading("Loading...");

            const incompleteMarkMilestoneTwo = await backOfficeCommunication.markInCompleteNewSession(milestone._id, type);

            if (incompleteMarkMilestoneTwo.status === "SUCCESS") {
                history.push(`/back-office/dashboard/document-scrutiny`);
            }
            else {
                showModal(incompleteMarkMilestoneTwo.message);

            }

            hideLoading();
        } catch (error) {
            showModal(error);
        }

    }

    const uploadOfficeExpenditureReport = async () => {
        try {
            showLoading("Loading...");

            if (selectedFile === null) {
                return showModal("Please select file");
            }
            const formData = new FormData();
            formData.append("document", selectedFile);
            //  TODO VALIDATE FILE HERE
            const uploadExpenditureReport = await backOfficeCommunication.uploadExpenditureReport(milestone._id, milestone.applicationId, formData);
            if (uploadExpenditureReport.status === "SUCCESS" || uploadExpenditureReport.data.status) {
                window.location.reload();
                getMilestoneDocument();
            }
            showModal(uploadExpenditureReport.message || uploadExpenditureReport.data.message);
            hideLoading();

        } catch (error) {
            showModal(error);
        }

    }

    const assignedMilestoneToUser = async () => {
        try {
            showLoading("Loading...");

            const incompleteMarkMilestoneTwo = await backOfficeCommunication.assignBackOfficeToMilestone(milestone._id, userId);

            if (incompleteMarkMilestoneTwo.status === "SUCCESS") {
                history.push(`/back-office/dashboard/document-scrutiny?applicationId=${applicationId}`);
            }
            else {
                showModal(incompleteMarkMilestoneTwo.message);
            }

            hideLoading();
        } catch (error) {
            showModal(error);
        }
    }

    const changeHandler = (e) => {
        let userId = e.target.value;
        // const user = backOfficeUsers.find((value) => {
        //     if (value.fullName === e.target.value) {
        //         return value;
        //     }
        // });
        setUserId(userId)

    }

    useEffect(() => {
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("document-scrutiny")) {
            dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
        }

        if (milestone.backOfficeStatus === "APPROVED") {
            setDesabled(true);
        } else {
            setDesabled(false);
        }

    }, []);

    function selectFile(e) {

        setSelectedFile(e.target.files[0]);
    }



    return (
        <>

            <div className="row">
                <div className="col-12 p-0">
                    <div className="back_office_preview_box">
                        <div className="back_office_preview_title">
                            <H4>{milestone.name.split('_').join(' ')} </H4>
                            <div className="close_back_office_preview" onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className="uploaded_documents">
                            {/*---heading section---*/}
                            <div className="row pt-3 ps-4">
                                <div className="col-4">
                                    <>
                                        <div className="mb-2">
                                            <span>
                                                <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">
                                                    Expenditure Report
                                                </H4>
                                            </span>
                                        </div>
                                        {
                                            milestone?.expenditureReportUrl && milestone?.expenditureReportUrl !== "" && milestone?.expenditureReportUrl.length > 0 && milestone?.expenditureReportUrl.map((ele, idx) => {
                                                return (

                                                    <span>
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                            <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone.applicationId, milestone.type, ele.fileUrl)} title="Click to preview document">
                                                                {ele.documentName}  <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                            </a>
                                                        </H4>
                                                    </span>

                                                )
                                            })
                                        }
                                    </>
                                </div>
                                <div className="col-4">
                                    <>
                                        <div className="mb-2">
                                            <span>
                                                <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>
                                                    Other Documents
                                                </H4>
                                            </span>
                                        </div>
                                        {
                                            milestone?.otherDocuments && milestone?.otherDocuments !== "" && milestone?.otherDocuments.length > 0 && milestone?.otherDocuments.map((ele, idx) => {
                                                return (

                                                    <span>
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                            <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone.applicationId, milestone.type, ele.fileUrl)} title="Click to preview document">
                                                                {ele.documentName}  <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                            </a>
                                                        </H4>
                                                    </span>

                                                )
                                            })
                                        }
                                    </>
                                </div>
                                <div className="col-4">
                                    <>
                                        <div className="mb-2">
                                            <span>
                                                <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>
                                                    Expenditure Report by Office
                                                </H4>
                                            </span>
                                        </div>
                                        {
                                            milestone?.officeExpenditureReportUrl && milestone?.officeExpenditureReportUrl !== "" &&


                                            <span>

                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                    <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.officeExpenditureReportUrl, milestone?.officeExpenditureReportUrl, milestone?.fileUrl)} title="Click to preview document">
                                                        Expenditure Report by Office  <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                    </a>
                                                </H4>
                                            </span>

                                        }
                                    </>
                                </div>
                            </div>

                            {/* <div className="row">


                                <div className="col-12 p-0 ps-4">
                                    <span>
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Name</H4>
                                    </span>
                                </div>
                            </div> */}
                            {/*---uploaded file section---*/}

                            {/* {
                                milestone?.expenditureReportUrl && milestone?.expenditureReportUrl !== "" && milestone?.expenditureReportUrl.length && <>
                                    <div className="row mt-3">
                                        <div className="col-12 p-0 ps-4" >
                                            <span>
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>
                                                    Expenditure Report
                                                </H4>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            } */}



                            {/* {
                                milestone?.expenditureReportUrl && milestone?.expenditureReportUrl !== "" && milestone?.expenditureReportUrl.length > 0 && milestone?.expenditureReportUrl.map((ele, idx) => {
                                    return (
                                        <div className="row mt-3">

                                            <div className="col-10 p-0 ps-4">
                                                <span>
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone.applicationId, milestone.type, ele.fileUrl)} title="Click to preview document">
                                                            {ele.documentName}  <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                        </a>
                                                    </H4>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            } */}


                            {/* {
                                milestone?.otherDocuments && milestone?.otherDocuments !== "" && milestone?.otherDocuments.length && <>
                                    <div className="row mt-3">
                                        <div className="col-12 p-0 ps-4">
                                            <span>
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>
                                                    Other Documents
                                                </H4>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            } */}

                            {/* {
                                milestone?.otherDocuments && milestone?.otherDocuments !== "" && milestone?.otherDocuments.length > 0 && milestone?.otherDocuments.map((ele, idx) => {
                                    return (
                                        <div className="row mt-3">

                                            <div className="col-12 p-0 ps-4">
                                                <span>
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone.applicationId, milestone.type, ele.fileUrl)} title="Click to preview document">
                                                            {ele.documentName}  <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                        </a>
                                                    </H4>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            } */}

                            {/* {
                                milestone?.officeExpenditureReportUrl && milestone?.officeExpenditureReportUrl !== "" && <>
                                    <div className="row mt-3">
                                        <div className="col-12 p-0 ps-4">
                                            <span>
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>
                                                    Expenditure Report by Office
                                                </H4>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            } */}

                            {/* {
                                milestone?.officeExpenditureReportUrl && milestone?.officeExpenditureReportUrl !== "" &&

                                <div className="row mt-3">


                                    <div className="col-12 p-0 ps-4">
                                        <span>

                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.officeExpenditureReportUrl, milestone?.officeExpenditureReportUrl, milestone?.fileUrl)} title="Click to preview document">
                                                    Expenditure Report by Office  <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                </a>
                                            </H4>
                                        </span>
                                    </div>

                                </div>
                            } */}

                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-2"></div>
                        <div className="col-lg-6 col-md-12 mt-lg-5 mt-md-5">

                            <div className="upload_report_section">
                                <input type="file" className="form-control form-select-sm" id="inputGroupFile02" title="upload expenditure report" onChange={(e) => { selectFile(e) }} />
                                <button className="ms-3" onClick={uploadOfficeExpenditureReport} disabled={desabled}> Upload </button>
                            </div>

                            <div className="Assign_section">
                                <div className="select_to_assign">
                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Assign To</H4>
                                    <select className="form-control form-select form-select-sm" onChange={(e) => { changeHandler(e) }}>
                                        <option>select</option>
                                        {
                                            backOfficeUsers.map(((value, index) => {
                                                const { userId, name } = value;
                                                return (
                                                    <option key={index} value={userId}>{name}</option>
                                                )
                                            }))
                                        }
                                    </select>
                                </div>

                                <button className="ms-3 mt-3" onClick={assignedMilestoneToUser} >Assign To</button>
                            </div>


                            <div className="upload_home_verification_button">
                                <button className="ms-3 me-3" onClick={() => markInComplete()} desabled={desabled}>Incomplete</button>
                                <button className="me-3" onClick={() => markComplete()} desabled={desabled}>Approve</button>
                                <button onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}



const DocumentScrutinyUploadedFiles = () => {
    const dispatch = useDispatch();
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [milestone, setMileStone] = useState({});
    const [applicationId, setApplicationId] = useState("");
    const [uploadFile, setUploadFile] = useState();
    const [type, setType] = useState("")
    const history = useHistory();
    const location = useLocation();
    const [backOfficeUsers, setBackOfficeUsers] = useState([]);



    const [isLoading, setIsLoading] = useState({ message: "", value: false });


    // show document scrutiny uploaded documents preview window
    function showDocumentScrutinyUploadedDocumentPreview(applicationId, type, fileUrl) {
        setType(type)
        history.push(`/back-office/dashboard/document-scrutiny/milestone-list/uploaded-documents/preview?milestoneId=${milestone._id.toString()}&applicationId=${applicationId}&fileUrl=${fileUrl}&type=${type}`);
    }
    //close document scrutiny uploaded files window
    function closeDocumentScrutinyUploadedFiles(applicationId, type) {
        setType(type)
        history.push(`/back-office/dashboard/document-scrutiny/milestone-list?milestoneId=${milestone._id.toString()}&applicationId=${applicationId}&type=${type}`);
    }

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    // Alert Modal react-redux

    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const fetchBackOfficeUser = async () => {
        try {
            showLoading("Loading...");

            const getBackOfficeUser = await backOfficeCommunication.getBackOfficeUsers();

            if (getBackOfficeUser.status === "SUCCESS") {
                let data = getBackOfficeUser.users;
                setBackOfficeUsers(value => { return [...value, ...data] });
            }
            else {
                showModal(getBackOfficeUser.message);
            }

            hideLoading();
        } catch (error) {
            showModal(error);
        }

    }

    async function getMilestoneDocument() {
        try {
            let fullUrl = window.location.href;
            let splitUrlArray = fullUrl.split('/');
            if (splitUrlArray.includes("document-scrutiny")) {
                dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
            }

            showLoading("Loading...");

            const queryParams = new URLSearchParams(location.search);
            const applicationId = queryParams.get('applicationId');
            const milestoneId = queryParams.get('milestoneId');
            const type = queryParams.get('type');

            const response = await backOfficeCommunication.getMilestone(milestoneId, applicationId, type);
            if (response.status === "SUCCESS") {
                setMileStone(response.milestones[response.milestones.length - 1]);
                setApplicationId(applicationId);
                setType(type);
            } else {
                showModal(response.message);
            }
            hideLoading();

        } catch (error) {
            showModal(error);
        }
    }

    useEffect(() => {

        getMilestoneDocument();

    }, []);


    useEffect(() => {

        if (["MILESTONE_THREE", "NEW_SESSION"].includes(type) === true) {
            fetchBackOfficeUser();
        }

    }, [type]);



    return (

        <section className="back_office_preview_section">
            {isLoading.value ?
                <LoadingForms message={isLoading.message} />
                :
                <div className="container">
                    {
                        type === "MILESTONE_ONE" && <MilestoneOneComponent
                            fontSizeMultiplier={fontSizeMultiplier}
                            milestone={milestone}
                            showDocumentScrutinyUploadedDocumentPreview={showDocumentScrutinyUploadedDocumentPreview}
                            closeDocumentScrutinyUploadedFiles={closeDocumentScrutinyUploadedFiles}
                            applicationId={applicationId}
                            type={type}
                            showLoading={showLoading}
                            hideLoading={hideLoading}
                            showModal={showModal}
                            isLoading={isLoading}
                            getMilestoneDocument={getMilestoneDocument}


                        />
                    }
                    {
                        type === "MILESTONE_TWO" && <MilestoneTwoComponent
                            fontSizeMultiplier={fontSizeMultiplier}
                            milestone={milestone}
                            showDocumentScrutinyUploadedDocumentPreview={showDocumentScrutinyUploadedDocumentPreview}
                            closeDocumentScrutinyUploadedFiles={closeDocumentScrutinyUploadedFiles}
                            applicationId={applicationId}
                            type={type}
                            showLoading={showLoading}
                            hideLoading={hideLoading}
                            showModal={showModal}
                            isLoading={isLoading}
                            getMilestoneDocument={getMilestoneDocument}

                        />
                    }
                    {
                        type === "MILESTONE_THREE" && <MilestoneThreeComponent
                            fontSizeMultiplier={fontSizeMultiplier}
                            milestone={milestone}
                            showDocumentScrutinyUploadedDocumentPreview={showDocumentScrutinyUploadedDocumentPreview}
                            closeDocumentScrutinyUploadedFiles={closeDocumentScrutinyUploadedFiles}
                            applicationId={applicationId}
                            type={type}
                            showLoading={showLoading}
                            hideLoading={hideLoading}
                            showModal={showModal}
                            isLoading={isLoading}
                            backOfficeUsers={backOfficeUsers}
                            getMilestoneDocument={getMilestoneDocument}


                        />
                    }
                    {
                        type === "MILESTONE_FOUR" && <MilestoneFourComponent
                            fontSizeMultiplier={fontSizeMultiplier}
                            milestone={milestone}
                            showDocumentScrutinyUploadedDocumentPreview={showDocumentScrutinyUploadedDocumentPreview}
                            closeDocumentScrutinyUploadedFiles={closeDocumentScrutinyUploadedFiles}
                            applicationId={applicationId}
                            type={type}
                            showLoading={showLoading}
                            hideLoading={hideLoading}
                            showModal={showModal}
                            isLoading={isLoading}
                            getMilestoneDocument={getMilestoneDocument}


                        />
                    }
                    {
                        type === "MILESTONE_FIVE" && <MilestoneFiveComponent
                            fontSizeMultiplier={fontSizeMultiplier}
                            milestone={milestone}
                            showDocumentScrutinyUploadedDocumentPreview={showDocumentScrutinyUploadedDocumentPreview}
                            closeDocumentScrutinyUploadedFiles={closeDocumentScrutinyUploadedFiles}
                            applicationId={applicationId}
                            type={type}
                            showLoading={showLoading}
                            hideLoading={hideLoading}
                            showModal={showModal}
                            isLoading={isLoading}
                            getMilestoneDocument={getMilestoneDocument}


                        />
                    }

                    {
                        type === "NEW_SESSION" && <GeneralMilestoneNewSession
                            fontSizeMultiplier={fontSizeMultiplier}
                            milestone={milestone}
                            showDocumentScrutinyUploadedDocumentPreview={showDocumentScrutinyUploadedDocumentPreview}
                            closeDocumentScrutinyUploadedFiles={closeDocumentScrutinyUploadedFiles}
                            applicationId={applicationId}
                            type={type}
                            showLoading={showLoading}
                            hideLoading={hideLoading}
                            showModal={showModal}
                            isLoading={isLoading}
                            backOfficeUsers={backOfficeUsers}
                            getMilestoneDocument={getMilestoneDocument}
                        />
                    }

                    {
                        ["MILESTONE_ONE", "MILESTONE_TWO", "MILESTONE_THREE", "MILESTONE_FOUR", "MILESTONE_FIVE", "NEW_SESSION"].includes(type) === false && <>

                            <GeneralMilestone
                                fontSizeMultiplier={fontSizeMultiplier}
                                milestone={milestone}
                                showDocumentScrutinyUploadedDocumentPreview={showDocumentScrutinyUploadedDocumentPreview}
                                closeDocumentScrutinyUploadedFiles={closeDocumentScrutinyUploadedFiles}
                                applicationId={applicationId}
                                type={type}
                                showLoading={showLoading}
                                hideLoading={hideLoading}
                                showModal={showModal}
                                isLoading={isLoading}
                                getMilestoneDocument={getMilestoneDocument}

                            />

                        </>
                    }


                </div>
            }

        </section>

    );
}
export default DocumentScrutinyUploadedFiles;