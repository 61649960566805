import React, { useEffect } from "react";
import { Redirect, Route, Router } from "react-router-dom";
import FrontOfficeDashboard from "./FrontOfficeDashboard";
import FrontOfficeSignIn from "./FrontOfficeSignIn";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { frontOfficeCommunication } from "../communications/front-office-communication";


const FrontOffice = () => {

    let isOfficeUserAuthenticated = useSelector((state) => state.isOfficeUserAuthenticated.value);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(async () => {
        let frontOfficeUserJwtVerification = await frontOfficeCommunication.getOfficeUserAuthenticateByJWT();
        if (isOfficeUserAuthenticated === undefined || isOfficeUserAuthenticated === false || isOfficeUserAuthenticated === null) {
            if (frontOfficeUserJwtVerification.status === "SUCCESS") {
                dispatch({ type: "AUTHENTICATE_FRONTOFFICEUSER", payload: frontOfficeUserJwtVerification.jwt, officeUser: frontOfficeUserJwtVerification.officeUser, tabs: frontOfficeUserJwtVerification.officeUser.tabs });
            } else {
                dispatch({ type: "DEAUTHENTICATE_FRONTOFFICEUSER" });
                history.push("/front-office/login");
            }
        }
    }, []);
    return (
        <div>
            <Route exact path="/front-office" render={() => {
                return (
                    isOfficeUserAuthenticated ?
                        <Redirect to="/front-office/dashboard" />
                        :
                        <Redirect to="/front-office/login" />
                );
            }} />
            <Route exact path="/front-office/login" component={FrontOfficeSignIn} />
            <Route path="/front-office/dashboard" component={FrontOfficeDashboard} />
        </div>
    );
}

export default FrontOffice;