import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import H4 from "../text-components/H4";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { communication } from '../communications/communication';



export default function MulipleDocumentUpload({ selectedDocumentArray, setSelectedDocumentArray, title, uploadedDocumentArray, status }) {
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    //****  Stopping field input- Enable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState(null);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    const inputFeild = useRef();


    //***** Setting default value for the form  ****//
    const defaultFormValues = {
        fileUrl: "",
        documentName: ""
    }
    const validationSchema = Yup.object().shape({
        fileUrl: Yup.mixed().required("Select a file"),
        documentName: Yup.string().required("Document name is required"),
    });

    function onFileSelect(event) {
        event.preventDefault();

        setFieldValue("fileUrl", event.currentTarget.files[0]);
    }

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const { handleSubmit, handleChange, setFieldValue, values, errors, handleReset, resetForm } = useFormik({
        initialValues: defaultFormValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {
            try {
                selectedDocumentArray.push({ documentName: values.documentName, file: values.fileUrl });
                handleReset();
                resetForm({});

                // clearing file input feild
                inputFeild.current.value = null;

                return false;

            } catch (error) {
                handleReset();
                showModal(error?.message);
                return false;
            }
        },
        validationSchema: validationSchema,
    });

    const downloadDocumentFromServer = async (attachment) => {
        if (attachment !== "") {
            await communication.getMilestoneDocumentfromServer(attachment);
        }
    }

    const removeFileFromArray = (idx) => {
        // removing file from array
        const newArr = selectedDocumentArray;
        newArr.splice(idx, 1);
        setSelectedDocumentArray([...newArr]);
    }

    return (

        <>
            {
                ["NEW", "IN_COMPLETED", "PAID"].includes(status) === true ?
                    <form onSubmit={handleSubmit}>
                        <div className='mt-3 mb-2'>
                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>{title}</H4>
                        </div>
                        <div className="row">
                            <div className="col-lg-5 col-md-5 col-12">
                                <input type="text" name="documentName" onChange={(e) => handleChange(e)} value={values?.documentName} className="form-control" disabled={disabled} placeholder={"Document Name"} />
                                <p className='text-danger'> {errors?.documentName} </p>
                            </div>
                            <div className="col-lg-5 col-md-5 col-12 multiple_documents_upload">
                                <input type="file"
                                    name="fileUrl"
                                    onChange={(e) => { onFileSelect(e) }}
                                    value={values?.fileUrl?.filename}
                                    className="form-control form-control-sm"
                                    disabled={disabled}
                                    ref={inputFeild}
                                    accept="application/pdf"
                                />
                                <p className='text-danger'> {errors?.fileUrl} </p>
                            </div>
                            <div className="col-lg-2 col-md-2 col-12 create_milestone_btn mt-0" style={{ textAlign: "right" }}>
                                <button type="submit" onClick={handleSubmit} >Add</button>
                            </div>
                        </div>
                    </form>
                    :
                    <>
                        {
                            uploadedDocumentArray?.length > 0 ?
                                <div className='mt-3 mb-2'>
                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>{title}</H4>
                                </div>
                                :
                                <> </>
                        }
                    </>
            }

            {["NEW", "IN_COMPLETED", "PAID"].includes(status) === true ?
                <>
                    {
                        !!selectedDocumentArray && selectedDocumentArray?.map((ele, idx) => {
                            return (
                                <div className="row mt-2" key={idx}>
                                    <div className="col-lg-1 col-md-1 col-2">
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>{idx + 1}</H4>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-10">
                                        <p className=''> {ele.documentName} </p>
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-12">
                                        <p className=''> {ele.file?.name} </p>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-12 remove_btn" style={{ textAlign: "right" }}>
                                        <button type="button" onClick={() => removeFileFromArray(idx)}>Remove</button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </> :
                <>

                    {
                        !!uploadedDocumentArray && uploadedDocumentArray?.map((ele, idx) => {
                            return (
                                <div className="row mt-2" key={idx}>
                                    <div className="col-lg-1 col-md-1 col-2">
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>{idx + 1}</H4>
                                    </div>
                                    <div className="col-lg-8 col-md-4 col-10">
                                        <p className=''> {ele?.documentName} </p>
                                    </div>

                                    <div className="col-lg-3 col-md-2 col-12" style={{ textAlign: "right" }}>
                                        <button onClick={() => downloadDocumentFromServer(ele?.fileUrl)}> Preview </button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </>
            }
        </>
    );
}
