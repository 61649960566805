import React from 'react';
import { useSelector } from 'react-redux';

const MinutesOfMeetingFirst = ({ showMomFirst }) => {
    const userAccessType = useSelector((state) => state.isOfficeUserAuthenticated?.officeUser?.access);


    return (
        <>
            <div className='row '>
                <div className='Front_Office_Btn'>
                    <div className="dashboard_delete_button" style={{ marginRight: "72px" }}>
                        <button type="button" className="btn" onClick={() => { showMomFirst(); }} disabled={userAccessType === "READ" ? true : false}>
                            Upload Minutes Of Meeting
                        </button>
                    </div>
                </div>
            </div>
        </>

    )
};

export default MinutesOfMeetingFirst;
