import React from "react";
import { Carousel } from "react-bootstrap";
const MobileImageGallery = () => {
    return(
<>

<section className="mobile_img_gallery">
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/jpgs/graduation-1-1024x466-1.jpg"
                            alt="First slide"
                            style={{ height: "500px" }}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/jpgs/600-nca-grads-get-degrees-at-convocation-1548627112-3779.jpg"
                            alt="Second slide"
                            style={{ height: "500px" }}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/jpgs/Graduation_ceremony_with_Azim_Premji.jpg"
                            alt="Third slide"
                            style={{ height: "500px" }}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/jpgs/convocation.jpg"
                            alt="Third slide"
                            style={{ height: "500px" }}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/jpgs/graduation-1-1024x466-1.jpg"
                            alt="Third slide"
                            style={{ height: "500px" }}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/jpgs/Students_are_Very_Excite_To_Click_Their_Picture_During_1st_Annual_Convocation_At_Punjab_University_constitute_college_Patto_Hira_Singh_Moga_Punjab_India-696x464.jpg"
                            alt="Third slide"
                            style={{ height: "500px" }}
                        />
                    </Carousel.Item>
                </Carousel>
                </section>
                
</>

    );

}
export default MobileImageGallery;