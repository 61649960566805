import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import H4 from "../../../text-components/H4";
import { useLocation } from "react-router-dom";
import { backOfficeCommunication } from "../../../communications/back-office-communication";
import LoadingForms from "../../../shared-components/LoadingForms";
import { useDispatch } from "react-redux";
import Label from "../../../text-components/Label";

const GeneralMilestoneNewSession = ({ changeHandler }) => {
    const dispatch = useDispatch();
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);

    const BackOfficeUser = useSelector((state) => state?.isBackOfficeAuthenticated?.backOfficeUser);
    const [comment, setComment] = useState("");

    const history = useHistory();
    const location = useLocation();

    const [disabled, setDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [applicationId, setApplicationId] = useState("");
    const [milestone, setMileStone] = useState("")
    const [type, setType] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [userId, setUserId] = useState("");
    const [backOfficeUsers, setBackOfficeUsers] = useState([]);


    function showDocumentScrutinyUploadedDocumentPreview(applicationId, type, fileUrl) {
        setType(type)
        history.push(`/back-office/dashboard/document-scrutiny/milestone-list/uploaded-documents/preview?milestoneId=${milestone._id.toString()}&applicationId=${applicationId}&fileUrl=${fileUrl}&type=${type}`);
    }
    //close document scrutiny uploaded files window
    function closeDocumentScrutinyUploadedFiles(applicationId, type) {
        setType(type);
        history.goBack();
        // history.push(`/back-office/dashboard/document-scrutiny/milestone-list?milestoneId=${milestone._id.toString()}&applicationId=${applicationId}&type=${type}`);
    }


    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    const markComplete = async () => {
        try {
            showLoading("Loading...");

            const completeMarkMilestoneTwo = await backOfficeCommunication.markCompleteNewSession(milestone._id, comment);

            if (completeMarkMilestoneTwo.status === "SUCCESS") {
                await changeHandler();
                // history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`);
            }
            showModal(completeMarkMilestoneTwo.message);

            hideLoading();
        } catch (error) {
            showModal(error);
        }

    }

    const markInComplete = async () => {
        try {
            showLoading("Loading...");

            if (comment.trim() === "") {
                showModal("Please enter a comment");
                hideLoading();
                return true;
            }

            const incompleteMarkMilestoneTwo = await backOfficeCommunication.markInCompleteNewSession(milestone._id, milestone.name, comment);

            if (incompleteMarkMilestoneTwo.status === "SUCCESS") {
                history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`);
            }
            else {
                showModal(incompleteMarkMilestoneTwo?.message);

            }

            hideLoading();
        } catch (error) {
            showModal(error);
        }

    }

    const uploadOfficeExpenditureReport = async () => {
        try {
            showLoading("Loading...");

            if (selectedFile === null) {
                return showModal("Please select file");
            }
            const formData = new FormData();
            formData.append("document", selectedFile);
            //  TODO VALIDATE FILE HERE
            const uploadExpenditureReport = await backOfficeCommunication.uploadExpenditureReport(milestone._id, milestone.applicationId, formData);
            if (uploadExpenditureReport.status === "SUCCESS" || uploadExpenditureReport.data.status) {
                showModal(uploadExpenditureReport.message || uploadExpenditureReport.data.message);
                hideLoading();
                await getMilestoneDocument();
            }
            else {
                showModal(uploadExpenditureReport.message || uploadExpenditureReport.data.message);
                hideLoading();
            }

        } catch (error) {
            showModal(error);
        }

    }

    const assignedMilestoneToUser = async () => {
        try {
            showLoading("Loading...");

            const responseFromServer = await backOfficeCommunication.assignBackOfficeToMilestone(milestone._id, userId, comment);

            if (responseFromServer?.status === "SUCCESS") {
                history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`);
            }
            else {
                showModal(responseFromServer?.message);
            }

            hideLoading();
        } catch (error) {
            showModal(error?.message);
            hideLoading();
        }
    }

    const selectUser = (e) => {
        let userId = e.target.value;
        // const user = backOfficeUsers.find((value) => {
        //     if (value.fullName === e.target.value) {
        //         return value;
        //     }
        // });
        setUserId(userId)

    }

    async function getMilestoneDocument() {
        try {
            let fullUrl = window.location.href;
            let splitUrlArray = fullUrl.split('/');


            if (splitUrlArray.includes("document-scrutiny")) {
                dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
            }

            showLoading("Loading...");

            const queryParams = new URLSearchParams(location.search);
            const applicationId = queryParams.get('applicationId');
            const milestoneId = queryParams.get('milestoneId');
            const type = queryParams.get('type');


            const response = await backOfficeCommunication.getMilestone(milestoneId, applicationId, type);
            if (response.status === "SUCCESS") {

                if (response.milestones[response.milestones.length - 1].userStatus === "APPROVED") {
                    setDisabled(true);
                } else {
                    setDisabled(false);
                }
                setMileStone(response.milestones[response.milestones.length - 1]);
                setApplicationId(applicationId);
                setType(type);
            } else {
                showModal(response.message);
            }
            hideLoading();

        } catch (error) {
            showModal(error);
        }
    }

    const fetchBackOfficeUser = async () => {
        try {
            showLoading("Loading...");

            const getBackOfficeUser = await backOfficeCommunication.getBackOfficeUsers();

            if (getBackOfficeUser.status === "SUCCESS") {
                let data = getBackOfficeUser?.users;
                setBackOfficeUsers(data);
            }
            else {
                showModal(getBackOfficeUser?.message);
            }

            hideLoading();
        } catch (error) {
            showModal(error);
        }

    }

    const getCommentForNewSessionMilestone = (event) => {
        setComment(event.target.value);
    }


    function selectFile(e) {

        setSelectedFile(e.target.files[0]);
    }

    useEffect(() => {
        getMilestoneDocument();
        fetchBackOfficeUser();
    }, []);

    useEffect(() => {


    }, []);

    return (
        <section className="back_office_preview_section">
            {isLoading.value ?
                <LoadingForms message={isLoading.message} />
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="back_office_preview_box">
                                <div className="back_office_preview_title">
                                    <H4>{milestone?.name?.split('_').join(' ')} </H4>
                                    <div className="close_back_office_preview" onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div className="uploaded_documents">
                                    {/*---heading section---*/}
                                    <div className="row pt-3 ps-4">
                                        <div className="col-4">
                                            <>
                                                <div className="mb-2">
                                                    <span>
                                                        <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">
                                                            Expenditure Report by Student
                                                        </H4>
                                                    </span>
                                                </div>
                                                {
                                                    milestone?.expenditureReportUrl && milestone?.expenditureReportUrl !== "" && milestone?.expenditureReportUrl.length > 0 && milestone?.expenditureReportUrl.map((ele, idx) => {
                                                        return (

                                                            <span key={idx}>
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                    <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone.applicationId, milestone.type, ele.fileUrl)} title="Click to preview document">
                                                                        {ele.documentName}  <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                                    </a>
                                                                </H4>
                                                            </span>

                                                        )
                                                    })
                                                }
                                            </>
                                        </div>
                                        {
                                            milestone?.otherDocuments && milestone?.otherDocuments !== "" && milestone?.otherDocuments.length > 0 ?


                                                <div className="col-4">
                                                    <>
                                                        <div className="mb-2">
                                                            <span>
                                                                <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>
                                                                    Other Documents
                                                                </H4>
                                                            </span>
                                                        </div>
                                                        {
                                                            milestone?.otherDocuments && milestone?.otherDocuments !== "" && milestone?.otherDocuments.length > 0 && milestone?.otherDocuments.map((ele, idx) => {
                                                                return (

                                                                    <span key={idx}>
                                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                            <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone.applicationId, milestone.type, ele.fileUrl)} title="Click to preview document">
                                                                                {ele.documentName}  <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                                            </a>
                                                                        </H4>
                                                                    </span>

                                                                )
                                                            })
                                                        }
                                                    </>
                                                </div>
                                                : ""
                                        }
                                        {
                                            milestone?.officeExpenditureReportUrl && milestone?.officeExpenditureReportUrl !== "" ?
                                                <div className="col-4">
                                                    <>
                                                        <div className="mb-2">
                                                            <span>
                                                                <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>
                                                                    Expenditure Report by Department
                                                                </H4>
                                                            </span>
                                                        </div>
                                                        <span>
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.officeExpenditureReportUrl, milestone?.officeExpenditureReportUrl, milestone?.fileUrl)} title="Click to preview document">
                                                                    Expenditure Report by Department  <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                                </a>
                                                            </H4>
                                                        </span>

                                                    </>
                                                </div>
                                                :
                                                <></>

                                        }
                                    </div>
                                    {/* <div className="row">


                                <div className="col-12 p-0 ps-4">
                                    <span>
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Name</H4>
                                    </span>
                                </div>
                            </div> */}
                                    {/*---uploaded file section---*/}

                                    {/* {
                                milestone?.expenditureReportUrl && milestone?.expenditureReportUrl !== "" && milestone?.expenditureReportUrl.length && <>
                                    <div className="row mt-3">
                                        <div className="col-12 p-0 ps-4" >
                                            <span>
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>
                                                    Expenditure Report
                                                </H4>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            } */}



                                    {/* {
                                milestone?.expenditureReportUrl && milestone?.expenditureReportUrl !== "" && milestone?.expenditureReportUrl.length > 0 && milestone?.expenditureReportUrl.map((ele, idx) => {
                                    return (
                                        <div className="row mt-3">

                                            <div className="col-10 p-0 ps-4">
                                                <span>
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone.applicationId, milestone.type, ele.fileUrl)} title="Click to preview document">
                                                            {ele.documentName}  <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                        </a>
                                                    </H4>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            } */}


                                    {/* {
                                milestone?.otherDocuments && milestone?.otherDocuments !== "" && milestone?.otherDocuments.length && <>
                                    <div className="row mt-3">
                                        <div className="col-12 p-0 ps-4">
                                            <span>
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>
                                                    Other Documents
                                                </H4>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            } */}

                                    {/* {
                                milestone?.otherDocuments && milestone?.otherDocuments !== "" && milestone?.otherDocuments.length > 0 && milestone?.otherDocuments.map((ele, idx) => {
                                    return (
                                        <div className="row mt-3">

                                            <div className="col-12 p-0 ps-4">
                                                <span>
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                        <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone.applicationId, milestone.type, ele.fileUrl)} title="Click to preview document">
                                                            {ele.documentName}  <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                        </a>
                                                    </H4>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            } */}

                                    {/* {
                                milestone?.officeExpenditureReportUrl && milestone?.officeExpenditureReportUrl !== "" && <>
                                    <div className="row mt-3">
                                        <div className="col-12 p-0 ps-4">
                                            <span>
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>
                                                    Expenditure Report by Office
                                                </H4>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            } */}

                                    {/* {
                                milestone?.officeExpenditureReportUrl && milestone?.officeExpenditureReportUrl !== "" &&

                                <div className="row mt-3">


                                    <div className="col-12 p-0 ps-4">
                                        <span>

                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.officeExpenditureReportUrl, milestone?.officeExpenditureReportUrl, milestone?.fileUrl)} title="Click to preview document">
                                                    Expenditure Report by Office  <i className="fa fa-paperclip fa-1x" aria-hidden="true"></i>
                                                </a>
                                            </H4>
                                        </span>
                                    </div>

                                </div>
                            } */}

                                </div>

                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-2">
                                    {disabled !== true ?
                                        <div className="comment_box_main">
                                            {
                                                ["", BackOfficeUser?.id].includes(milestone?.assignedTo) ?
                                                    <>
                                                        <div className="comment_box_heading">
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Comment Box</H4>
                                                        </div>
                                                        <div className="comment_box_body">
                                                            <div className="comment_to_write_main">
                                                                <div className="comment_to_write">
                                                                    <input className="form-control" placeholder="Write comment here..." onChange={getCommentForNewSessionMilestone} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : <></>
                                            }
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                                <div className="col-lg-6 col-md-12 mt-lg-5 mt-md-5">
                                    {
                                        ["NEW", "REJECTED"].includes(milestone?.backOfficeStatus) && ["SUBMITTED"].includes(milestone?.userStatus) && ["", BackOfficeUser?.id].includes(milestone?.assignedTo) ?
                                            <>
                                                <div className="col-lg-12">
                                                    <span style={{ fontSize: "14px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                </div>
                                                <div className="upload_report_section">
                                                    <input type="file" className="form-control form-select-sm" id="inputGroupFile02" title="upload expenditure report" onChange={(e) => { selectFile(e) }} />
                                                    <button className="ms-3" onClick={uploadOfficeExpenditureReport} > Upload </button>
                                                </div>
                                            </>
                                            :
                                            ""
                                    }
                                    {disabled !== true ?
                                        <>

                                            <div className="Assign_section">
                                                {
                                                    milestone?.backOfficeStatus === "APPROVED" && ["", BackOfficeUser?.id].includes(milestone?.assignedTo) && BackOfficeUser.designation !== "COMMISSIONER" ?
                                                        <div className="select_to_assign">
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Assign To</H4>
                                                            <select className="form-control form-select form-select-sm" onChange={(e) => { selectUser(e) }}>
                                                                <option>select</option>
                                                                {
                                                                    backOfficeUsers.map(((value, index) => {
                                                                        const { userId, name, designation } = value;
                                                                        return (
                                                                            <option key={index} value={userId}>{name} - ({designation})</option>
                                                                        )
                                                                    }))
                                                                }
                                                            </select>
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    milestone?.backOfficeStatus === "APPROVED" && ["", BackOfficeUser?.id].includes(milestone?.assignedTo) && BackOfficeUser.designation !== "COMMISSIONER" ?
                                                        <button className="ms-3 mt-3" onClick={assignedMilestoneToUser} >Assign To</button>
                                                        :
                                                        ""
                                                }
                                            </div>
                                        </>
                                        : <span></span>
                                    }
                                    <div className="upload_home_verification_button">
                                        {
                                            disabled !== true ?
                                                <>
                                                    {
                                                        (milestone?.assignedTo === "" || milestone?.assignedTo === BackOfficeUser?.id) ?
                                                            <>
                                                                {
                                                                    milestone?.backOfficeStatus === "ASSIGNED" || milestone?.backOfficeStatus === "NEW" || milestone?.backOfficeStatus === "REJECTED" ?
                                                                        <>
                                                                            <button className="ms-3 me-3" onClick={() => markInComplete()} >Incomplete</button>
                                                                            {
                                                                                !!milestone?.expenditureReportUrl && milestone?.expenditureReportUrl !== "" && milestone?.officeExpenditureReportUrl && milestone?.officeExpenditureReportUrl !== "" ?
                                                                                    <button className="me-3" onClick={() => markComplete()} >Approve</button>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                        </>
                                                                        :
                                                                        ""
                                                                }
                                                            </>
                                                            : <span></span>
                                                    }
                                                </>
                                                : ""
                                        }
                                        <button onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            }
        </section>
    )
}

export default GeneralMilestoneNewSession;