import H5 from "../text-components/H5";
import { useSelector } from "react-redux";
import { useState } from "react";
import NavSelectedUnderline from "../shared-components/NavSelectedUnderline";
import { useHistory } from "react-router";
import { useEffect } from "react";

const FrontOfficeNav = () => {

  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

  const activeTab = useSelector(state => state.activeTab);

  const [selectedNavItem, setSelectedNavItem] = useState("");

  const history = useHistory();

  const tabs = useSelector(state => state.isOfficeUserAuthenticated.tabs ?? ["NEW_APPLICATION", "APPLICATION_VERIFICATION"]);


  useEffect(() => {
    switch (window.location.pathname) {

      case "/front-office/dashboard/course-wise-application":
        setSelectedNavItem(value => value = "COURSE_WISE_APPLICATION")
        break;
      case "/front-office/dashboard/new-application":
        setSelectedNavItem(value => value = "NEW_APPLICATION")
        break;
      case "/front-office/dashboard/application-verification":
        setSelectedNavItem(value => value = "APPLICATION_VERIFICATION")
        break;
      case "/front-office/dashboard/short-list-application":
        setSelectedNavItem(value => value = "SHORT_LIST_APPLICATION")
        break;
      case "/front-office/dashboard/merit-list":
        setSelectedNavItem(value => value = "MERIT_LIST")
        break;
      case "/front-office/dashboard/approved-list":
        setSelectedNavItem(value => value = "APPROVED_LIST")
        break;
      case "/front-office/dashboard/wait-list":
        setSelectedNavItem(value => value = "WAIT_LIST")
        break;
      case "/front-office/dashboard/minutes-of-meeting":
        setSelectedNavItem(value => value = "MINUTES_OF_MEETING")
        break;
    }
  }, []);

  const selectNavItem = async (navItem) => {
    setSelectedNavItem(value => value = navItem);

    switch (navItem) {

      case "COURSE_WISE_APPLICATION":
        history.push('/front-office/dashboard/course-wise-application');
        break;
      case "NEW_APPLICATION":
        history.push('/front-office/dashboard/new-application');
        break;
      case "APPLICATION_VERIFICATION":
        history.push('/front-office/dashboard/application-verification');
        break;
      case "SHORT_LIST_APPLICATION":
        history.push('/front-office/dashboard/short-list-application');
        break;
      case "MERIT_LIST":
        history.push('/front-office/dashboard/merit-list');
        break;
      case "APPROVED_LIST":
        history.push('/front-office/dashboard/approved-list');
        break;
      case "WAIT_LIST":
        history.push('/front-office/dashboard/wait-list');
        break;
      case "MINUTES_OF_MEETING":
        history.push('/front-office/dashboard/minutes-of-meeting');
        break;

    }
  }

  useEffect(() => {
    selectNavItem(activeTab);
  }, []);



  return (
    <>

      {/*-----navbar for laptop and mobile device--------------*/}

      <nav className="navbar navbar-expand-lg application_process_nav mb-1" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000" }}>
        <div className="container-fluid p-0">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span><i className="fa fa-bars" aria-hidden="true"></i></span>
          </button>
          <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
            <ul className="navbar-nav">

              {/* <li className="home_icon"><H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00"><i className="fa fa-home" aria-hidden="true" onClick={goToHome}></i></H2></li> */}

              {tabs.includes("COURSE_WISE_APPLICATION") ?
                <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeTab === "COURSE_WISE_APPLICATION") ? selectNavItem(" ") : selectNavItem("COURSE_WISE_APPLICATION")}>
                  <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Course Wise Application</H5>
                  {(activeTab === "COURSE_WISE_APPLICATION") ? <NavSelectedUnderline /> : <span></span>}
                </li>
                : " "
              }
              {tabs.includes("NEW_APPLICATION") ?
                <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeTab === "NEW_APPLICATION") ? selectNavItem(" ") : selectNavItem("NEW_APPLICATION")}>
                  <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">New Application</H5>
                  {(activeTab === "NEW_APPLICATION") ? <NavSelectedUnderline /> : <span></span>}
                </li>
                : " "
              }
              {
                tabs.includes("APPLICATION_VERIFICATION") ?
                  <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeTab === "APPLICATION_VERIFICATION") ? selectNavItem(" ") : selectNavItem("APPLICATION_VERIFICATION")}>
                    <H5 className="APPLICATION_VERIFICATION" whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Application Verification</H5>
                    {(activeTab === "APPLICATION_VERIFICATION") ? <NavSelectedUnderline /> : <span></span>}
                  </li> :
                  " "
              }
              {tabs.includes("SHORT_LIST_APPLICATION") ?
                <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeTab === "SHORT_LIST_APPLICATION") ? selectNavItem(" ") : selectNavItem("SHORT_LIST_APPLICATION")}>
                  <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Short-List Application</H5>
                  {(activeTab === "SHORT_LIST_APPLICATION") ? <NavSelectedUnderline /> : <span></span>}
                </li> :
                " "
              }
              {tabs.includes("MERIT_LIST") ?
                <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeTab === "MERIT_LIST") ? selectNavItem(" ") : selectNavItem("MERIT_LIST")}>
                  <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Merit List</H5>
                  {(activeTab === "MERIT_LIST") ? <NavSelectedUnderline /> : <span></span>}
                </li> :
                " "}
              {tabs.includes("APPROVED_LIST") ?
                <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeTab === "APPROVED_LIST") ? selectNavItem(" ") : selectNavItem("APPROVED_LIST")}>
                  <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Approved List</H5>
                  {(activeTab === "APPROVED_LIST") ? <NavSelectedUnderline /> : <span></span>}
                </li> :
                " "
              }
              {
                tabs.includes("WAITING_LIST") ?
                  <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeTab === "WAIT_LIST") ? selectNavItem(" ") : selectNavItem("WAIT_LIST")}>
                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Wait List</H5>
                    {(activeTab === "WAIT_LIST") ? <NavSelectedUnderline /> : <span></span>}
                  </li> :
                  " "
              }
              {
                tabs.includes("MINUTES_OF_MEETING") ?
                  <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeTab === "MINUTES_OF_MEETING") ? selectNavItem(" ") : selectNavItem("MINUTES_OF_MEETING")}>
                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Minutes of Meeting</H5>
                    {(activeTab === "MINUTES_OF_MEETING") ? <NavSelectedUnderline /> : <span></span>}
                  </li> :
                  " "
              }
            </ul>

          </div>
        </div>
      </nav>

    </>
  );
};

export default FrontOfficeNav;