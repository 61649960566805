// const districtList = [
//     { districtName: "Ahmednagar" },
//     { districtName: "Akola" },
//     { districtName: "Amravati" },
//     { districtName: "Aurangabad" },
//     { districtName: "Beed" },
//     { districtName: "Bhandara" },
//     { districtName: "Buldhana" },
//     { districtName: "Chandrapur" },
//     { districtName: "Dhule" },
//     { districtName: "Gadchiroli" },
//     { districtName: "Gondia" },
//     { districtName: "Hingoli" },
//     { districtName: "Jalgaon" },
//     { districtName: "Jalna" },
//     { districtName: "Kolhapur" },
//     { districtName: "Latur" },
//     { districtName: "Mumbai City" },
//     { districtName: "Mumbai Suburban" },
//     { districtName: "Nagpur" },
//     { districtName: "Nanded" },
//     { districtName: "Nandurbar" },
//     { districtName: "Nashik" },
//     { districtName: "Osmanabad" },
//     { districtName: "Palghar" },
//     { districtName: "Parbhani" },
//     { districtName: "Pune" },
//     { districtName: "Raigad" },
//     { districtName: "Ratnagiri" },
//     { districtName: "Sangli" },
//     { districtName: "Satara" },
//     { districtName: "Sindhudurg" },
//     { districtName: "Solapur" },
//     { districtName: "Thane" },
//     { districtName: "Wardha" },
//     { districtName: "Washim" },
//     { districtName: "Yavatmal" },
// ];
// export default districtList;


