import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Router } from "react-router-dom";
import { adminCommunication } from "../communications/admin-communication";
import AdminDashboard from "./AdminDashboard";
import AdminSignIn from "./AdminSignIn";
import { useHistory } from "react-router";


const Admin = () => {

    let isAdminAuthenticated = useSelector((state) => state.isAdminAuthenticated.value);

    const dispatch = useDispatch();
    const history = useHistory();

    async function handleAdminSignIn() {
        let adminJwtVerification = await adminCommunication.getAdminAuthenticateByJWT();
        if (isAdminAuthenticated === undefined || isAdminAuthenticated === false || isAdminAuthenticated === null) {
            if (adminJwtVerification.status === "SUCCESS") {
                dispatch({ type: "AUTHENTICATE_ADMIN", payload: adminJwtVerification.jwt, adminData: adminJwtVerification.admin });
            } else {
                dispatch({ type: "DEAUTHENTICATE_ADMIN" });
                history.push("/admin/login");
            }
        }
    }

    useEffect(() => {
        handleAdminSignIn();
    }, []);
    return (
        <div>
            <Route exact path="/admin" render={() => {
                console.log(isAdminAuthenticated);
                return (
                    isAdminAuthenticated ?
                        <Redirect to="/admin/dashboard" />
                        :
                        <Redirect to="/admin/login" />
                );
            }} />
            <Route exact path="/admin/login" component={AdminSignIn} />
            <Route path="/admin/dashboard" component={AdminDashboard} />
        </div>
    );
}

export default Admin;