import React from "react";

const ApplicationFlowMobile = () => {
    return (
        <>
            <section className="application_flow_mobile_main">
                <div className="row">
                    <div className="col-12">
                        <img src="/assets/images/pngs/Application Flow.png" alt="Application_flow" />
                    </div>
                </div>
            </section>
        </>
    );

}
export default ApplicationFlowMobile;