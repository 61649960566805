import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import LoadingForms from "../../shared-components/LoadingForms";
import { frontOfficeCommunication, getServerUrl } from "../../communications/front-office-communication";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import fileDownload from 'js-file-download';
import H5 from '../../text-components/H5';
import ReactPaginate from "react-paginate";

const MinutesOfMeeting = () => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [minutesOfMeetings, setMinutesOfMeetings] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;
    const history = useHistory();

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;setCurrentPage(selectedPage);
        if (searchValue) {
            fetchMinutesOfMeetingListBySearch(searchValue, selectedPage + 1);
        }else{
            initialLoad(selectedPage + 1);
        }
    
    }

    // Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchMinutesOfMeetingListBySearch(searchString, currentPage + 1); 
        }else{
            const minutesOfMeetingFromServer = await frontOfficeCommunication.getMinutesOfMeetingList();
            if(minutesOfMeetingFromServer?.status === "SUCCESS"){
                setMinutesOfMeetings(minutesOfMeetingFromServer?.minutesOfMeetings);
                setPageCount(minutesOfMeetingFromServer?.totalPages);
            } else if (minutesOfMeetingFromServer?.status === "UNAUTHORIZED_ACCESS"){
                history.push('/front-office/login');
            }else{
                setMinutesOfMeetings([]);
                setPageCount(0)
            }
        }
    }

     //get minutes of meeting List By Search
        const fetchMinutesOfMeetingListBySearch = async (searchString, page) => {
            // console.log(searchString);
        try {
        const minutesOfMeetingFromServer = await frontOfficeCommunication.getMinutesOfMeetingListBySearch(searchString, page);
        if(minutesOfMeetingFromServer?.data?.status === "SUCCESS"){
            setMinutesOfMeetings(minutesOfMeetingFromServer?.data?.minutesOfMeetings);
            setPageCount(minutesOfMeetingFromServer?.totalPages);
            }else if(minutesOfMeetingFromServer?.data?.status === "UNAUTHORIZED_ACCESS"){
                history.push('/front-office/login');
            }else{
                setMinutesOfMeetings([]);
                setPageCount(0)
            }
        } catch (error) {
        showModal(error?.message);   
        }
    }

  
        async function initialLoad() {
            let fullUrl = window.location.href;
            let splitUrlArray = fullUrl.split('/');
            if (splitUrlArray.includes("minutes-of-meeting")) {
                dispatch({ type: "ACTIVE_TAB", payload: "MINUTES_OF_MEETING" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_TAB" });
            }
            showLoading("Loading...");

            const minutesOfMeetingFromServer = await frontOfficeCommunication.getMinutesOfMeetingList();
            // console.log("inside code",minutesOfMeetingFromServer);
            if (minutesOfMeetingFromServer?.status === "SUCCESS") {
                let minutesOfMeeting = minutesOfMeetingFromServer?.minutesOfMeetings;
                setMinutesOfMeetings(minutesOfMeeting);
            } else if (minutesOfMeetingFromServer?.status === "UNAUTHORIZED_ACCESS") {
                history.push(`/front-office/login`);
            }
            else {
                showModal(minutesOfMeetingFromServer?.message);
            }
            hideLoading();
        }
        useEffect(() => {
        initialLoad();
    }, []);

    const getMinutesOfMeetingFileFromServer = async (title, file) => {
        showLoading("Loading...");
        let uploadedFile = file.split("/")[1];
        let minutesOfMeetingFile = await frontOfficeCommunication.getMinutesOfMeetingFile(title, uploadedFile);
        // fileDownload(minutesOfMeetingFile, uploadedFile)
        hideLoading();
        return minutesOfMeetingFile;
    }

    // Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }
    return (
        <>

            <section className="dashboard_background" >
                <div className='container'>

                    {isLoading.value ?
                        <LoadingForms message={isLoading.message} />
                        :
                        <div className="dashboard_Front_office">
                            <div className="row search_wrapper">
                                <div className="col-lg-4 col-md-4 ps-0">
                                    <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Title, Description..." />
                                </div>
                                <div className="col-lg-8 col-md-8 pe-0 pagination_outer_wrapper">
                                    <div className="pagination_inner_wrapper">
                                    {pageCount > 1 &&
                                        <ReactPaginate
                                            previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            forcePage={currentPage}
                                            activeClassName={"active"} />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/*-----Grievance card header section---*/}
                            <div className="dashboard_view_data_heading">
                                <div className="row m-0">
                                    <div className="col-1 p-0 view_dashboard">
                                        <span><H5>Serial No.</H5></span>
                                    </div>
                                    <div className="col-5 p-0 view_dashboard">
                                        <span><H5>Title</H5></span>
                                    </div>
                                    <div className="col-4 p-0 view_dashboard">
                                        <span><H5>Description</H5></span>
                                    </div>
                                    <div className="col-2 p-0 ">
                                        <span><H5>Creation Date</H5></span>
                                    </div>
                                </div>
                            </div>

                            {
                                minutesOfMeetings.length > 0 ?
                                    <div>
                                        {/*-----Grievance card data section---*/}
                                        {minutesOfMeetings?.map((minutesOfMeeting, index) => {
                                            const { _id, title, creationDate, uploadedFile, description } = minutesOfMeeting;
                                            return (
                                                <div key={_id}>
                                                    <div className="dashboard_view_data_main" key={_id}>
                                                        <div className="dashboard_view_data">
                                                            <div className="row m-0">
                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                        {limit * currentPage + (index + 1)}
                                                                        </H5>
                                                                    </span>
                                                                </div>
                                                                <div className="col-5 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            <a onClick={() => getMinutesOfMeetingFileFromServer(title, uploadedFile)} download>
                                                                                {title}
                                                                            </a>
                                                                        </H5>
                                                                    </span>
                                                                </div>
                                                                <div className="col-4 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {description}
                                                                        </H5>
                                                                    </span>
                                                                </div>
                                                                <div className="col-2 p-0 text_align">
                                                                    <span>
                                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {new Date(creationDate).getDate()}-{new Date(creationDate).getMonth() + 1}-{new Date(creationDate).getFullYear()}
                                                                        </H5>
                                                                    </span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            );
                                        })}
                                    </div>
                                    :
                                    <div className="text-center mt-5">
                                        <p>Records not available</p>

                                    </div>
                            }

                        </div>
                    }
                </div>

            </section>


        </>
    )
};

export default MinutesOfMeeting;
