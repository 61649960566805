import React, { useEffect, useState } from 'react';
import LoadingForms from "../../shared-components/LoadingForms";
import { frontOfficeCommunication } from "../../communications/front-office-communication";
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import MinutesOfMeetingFirst from '../MinutesOfMeetingFirst';
import MinutesOfMeetingBoxFirst from '../MinutesOfMeetingBoxFirst';
import H6 from '../../text-components/H6';
import { removeUnderscoreFromText } from '../../utlis/helper';
import ReactPaginate from "react-paginate";

const ApprovedList = () => {

    const history = useHistory();

    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [approvedApplications, setApprovedApplications] = useState([]);
    const [applicationIds, setApplicationIds] = useState([]);
    const [showMomSecond, setShowMomSecond] = useState(false);
    const [filteredApplication, setFilteredApplication] = useState([])
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;
    const userAccessType = useSelector((state) => state.isOfficeUserAuthenticated?.officeUser?.access);


    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }
    // set ids in to state
    const handleOnChange = (e) => {
        let checked = e.target.checked;
        let id = e.target.value;
        if (checked) {
            setApplicationIds(applicationIds => [...applicationIds, id]);
        } else {
            setApplicationIds(
                applicationIds.filter((Id) => Id !== id)
            );
        }
        return;
    }

    // send waitList applications list to server
    const revokeApplications = async () => {
        if (applicationIds.length !== 0) {
            showLoading("Loading...");
            let action = "REVOKED"
            const submitSelectedApplicationsList = await frontOfficeCommunication.sendToRevokedApplication(applicationIds, action);
            if (submitSelectedApplicationsList.status === "SUCCESS") {
                showModal(submitSelectedApplicationsList.message);
                // clear data from setApplicationIds
                setApplicationIds([]);
                await getApprovedApplications();
                hideLoading();
                return;
            }
            else if (submitSelectedApplicationsList.status === "UNAUTHORIZED_ACCESS") {
                history.push(`/front-office/login`);
                return;
            }
            showModal(submitSelectedApplicationsList.message);
        }
        else {
            showModal("Please select atleast one record");
        }
        setApplicationIds([]);
        hideLoading();
        return;
    }

    // send waitList applications list to server
    const submitGRApplications = async () => {
        showLoading("Loading...");
        const submitSelectedApplicationsList = await frontOfficeCommunication.submitGrApprovedList();
        if (submitSelectedApplicationsList.status === "SUCCESS") {
            // clear data from setApplicationIds
            setApplicationIds([]);
            await getApprovedApplications();
            showModal(submitSelectedApplicationsList.message);
            hideLoading();
            return;
        }
        setApplicationIds([]);
        showModal(submitSelectedApplicationsList.message);
        hideLoading();
        return;
    }
    // First Minutes of meeting box sho hide
    function showMomModal() {
        setShowMomSecond(true);
    }
    function hideMomModal() {
        setShowMomSecond(false);
    }
    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchApprovedListBySearch(searchValue, selectedPage + 1);
        } else {
            getApprovedApplications(selectedPage + 1);
        }
    }

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchApprovedListBySearch(searchString, currentPage + 1);
        } else {
            const getApprovedListFromServer = await frontOfficeCommunication.getApprovedListApplications()
            if (getApprovedListFromServer?.status === "SUCCESS") {
                setApprovedApplications(getApprovedListFromServer?.applications);
                setFilteredApplication(getApprovedListFromServer?.applications);
                setPageCount(getApprovedListFromServer?.totalPages);
            } else if (getApprovedListFromServer.status === "UNAUTHORIZED_ACCESS") {
                history.push(`/front-office/login`);
            } else {
                setApprovedApplications([]);
                setFilteredApplication([]);
                setPageCount(0);
            }
        }
    }


    //get Merit List By Search
    const fetchApprovedListBySearch = async (searchString, page) => {
        try {
            const getApprovedListFromServer = await frontOfficeCommunication.getApprovedListBySearch(searchString, page);
            if (getApprovedListFromServer?.data?.status === "SUCCESS") {
                setApprovedApplications(getApprovedListFromServer?.data?.applications);
                setFilteredApplication(getApprovedListFromServer?.data?.applications);
                setPageCount(getApprovedListFromServer?.data?.totalPages);
            } else if (getApprovedListFromServer?.data?.status === "UNAUTHORIZED_ACCESS") {
                history.push(`/front-office/login`);
            } else {
                setApprovedApplications([]);
                setFilteredApplication([]);
                setPageCount(0);
            }
        } catch (error) {
            showModal(error.message);
        }
    }

    //get Approved List on Initial Load
    async function getApprovedApplications(page) {
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("approved-list")) {
            dispatch({ type: "ACTIVE_TAB", payload: "APPROVED_LIST" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_TAB" });
        }
        showLoading("Loading...");
        const getApprovedListFromServer = await frontOfficeCommunication.getApprovedListApplications(page);
        if (getApprovedListFromServer.status === "SUCCESS") {
            let applications = getApprovedListFromServer.applications;
            setApprovedApplications(getApprovedListFromServer?.applications);
            setFilteredApplication(getApprovedListFromServer?.applications);
        } else if (getApprovedListFromServer.status === "UNAUTHORIZED_ACCESS") {
            history.push(`/front-office/login`);
        }
        else {
            setApprovedApplications([]);
            setFilteredApplication([]);
        }

        hideLoading();
    }

    useEffect(() => {
        getApprovedApplications();
    }, []);


    // Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }


    const sendToWaitingList = async () => {
        dispatch({ type: "ACTIVE_TAB", payload: "WAIT_LIST" });
        history.push(`/front-office/dashboard/wait-list`);
    }


    async function goToApplication(applicationId) {
        showLoading("Loading...");
        const accessApplicationFromServer = await frontOfficeCommunication.accessToApplication(applicationId);
        if (accessApplicationFromServer.status === "APPLICATION_AVAILABLE") {
        }
        history.push(`/front-office/dashboard/application/?applicationId=${applicationId}&action=APPROVED_LIST`);
        hideLoading();
        showModal(accessApplicationFromServer.message);
    }



    return (
        <>
            {/* <div className="row mt-3 container" style={{ "margin": "auto" }}>
                <div className="back_office_search_box">
                    <div className="row">
                        <div className="col-3 search_box">
                            <input type="search" className="form-control" placeholder="Application Id" onChange={(event) => onChangeFilterText(event, "APPLICATION_ID")} />
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <div className="col-3 search_box">
                            <input type="search" className="form-control" placeholder="Course" onChange={(event) => onChangeFilterText(event, "COURSE")} />
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <div className="col-3 search_box">
                            <input type="search" className="form-control" placeholder="Stream" onChange={(event) => onChangeFilterText(event, "STREAM")} />
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='container-fluid mt-3'>
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :
                    <>
                        <div className="row search_wrapper">
                            <div className="col-lg-4 col-md-4 ps-0">
                                <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Application ID, University, Rank, Course..." />
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <MinutesOfMeetingFirst showMomFirst={showMomModal} />
                            </div>
                            <div className="col-lg-5 col-md-4 pe-0 pagination_outer_wrapper">
                                <div className="pagination_inner_wrapper">
                                    {pageCount > 1 &&
                                        <ReactPaginate
                                            previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            forcePage={currentPage}
                                            activeClassName={"active"} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='wholeTable'>
                            <div className='frontOfficeTable'>
                                <div className='frontOfficeTableHeader'>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder srNo'>
                                        <span><H6>Serial No.</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder ApplicationID' >
                                        <span><H6>Application ID</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder University'>
                                        <span><H6>University Name</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder UniversityRank'>
                                        <span><H6>University Rank</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Income'>
                                        <span><H6>Income</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Course'>
                                        <span><H6>Course</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Stream'>
                                        <span><H6>Stream</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Age'>
                                        <span><H6>Age</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder OtherScholar'>
                                        <span><H6>Other Scholarship</H6></span>
                                    </div>
                                    {/* <div className='frontOfficeTableHeaderRow tableRowRightBorder percentage'>
                                    <span><H6>Percentage</H6></span>
                                </div> */}
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Attempt'>
                                        <span><H6>Attempt</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Status'>
                                        <span><H6>Status</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow Rating'>
                                        <span><H6>Marks</H6></span>
                                    </div>

                                </div>
                                {
                                    filteredApplication?.length > 0 ?
                                        <div>
                                            {filteredApplication?.map((application, index) => {
                                                const { age, shortId, applicationId, stream, course, frontOfficeStatus, income, otherScholarship, university, universityRank, marks, percentage, attempt } = application;
                                                return (
                                                    <div key={index}>

                                                        <div className='frontOfficeTableBody'>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder srNo'>
                                                                <span>
                                                                    <input type="checkbox" value={applicationId} onClick={(e) => { handleOnChange(e, stream, course) }} disabled={userAccessType === "READ" ? true : false} />
                                                                    <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{limit * currentPage + (index + 1)}</H6>
                                                                </span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder applicationId' >
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                    <a onClick={() => { goToApplication(applicationId) }}>
                                                                        {shortId}
                                                                    </a>
                                                                </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder University '>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {university} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder UniversityRank'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  {universityRank} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Income'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {income} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Course'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {course}</H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Stream'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {stream} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Age'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                    {`${age.year} Y ${age.month} M ${age.day} D`}
                                                                </H6></span>
                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder OtherScholar'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  {otherScholarship === false ? "yes" : "No"} </H6></span>

                                                            </div>
                                                            {/* <div className='frontOfficeTableBodyRow tableRowRightBorder percentage'>
                                                            <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {percentage} </H6></span>

                                                        </div> */}
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Attempt'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {attempt} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Status'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {removeUnderscoreFromText(frontOfficeStatus)} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow Rating'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  {marks} </H6></span>

                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        :
                                        <div className="text-center mt-5">
                                            <p>Records not available </p>
                                        </div>
                                }

                                {showMomSecond ?
                                    <MinutesOfMeetingBoxFirst hideMomFirst={hideMomModal} />
                                    : <span></span>
                                }
                            </div>

                        </div>
                    </>
                }
            </div>


            <div className='Front_Office_Btn'>

                <div className="dashboard_delete_button">
                    <button type="button" className="btn" onClick={() => sendToWaitingList()} disabled={userAccessType === "READ" ? true : false} >
                        {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6>Replace</H6>}
                    </button>
                </div>

                <div className="dashboard_delete_button">
                    <button type="button" className="btn" onClick={() => revokeApplications()} disabled={userAccessType === "READ" ? true : false} >
                        {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6>Revoke</H6>}
                    </button>
                </div>

                <div className="dashboard_delete_button">

                    <button type="button" className="btn" onClick={() => submitGRApplications()} disabled={userAccessType === "READ" ? true : false} >
                        {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6>Prepare GR</H6>}
                    </button>
                </div>

            </div>


        </>

    )
};

export default ApprovedList;
