import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import H1 from "../text-components/H1";
import H3 from "../text-components/H3";
import { frontOfficeCommunication } from "../communications/front-office-communication";
import { backOfficeCommunication } from "../communications/back-office-communication";


const FrontOfficeSignIn = () => {

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [loginFormSubmission, setLoginFormSubmission] = useState({});
    // spinner on loading 
    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    const [committees, setCommittees] = useState([]);

    // cancel, save and submit buttons unable & disable
    const [disableActions, setDisableActions] = useState(false);

    const history = useHistory();

    const validationSchema = Yup.object().shape({
        userId: Yup.string().required("User ID is required"),
        password: Yup.string().required("Password is required"),
        committeeId: Yup.string().required("Committee is required")
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    // get functions to build with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }
    // Function called on submit form 
    // receive form data after successful validation 
    const onSubmit = async (data) => {
        showLoading();
        setDisableActions(true);
        setLoginFormSubmission((value) => {
            return { ...value, ...{ status: "LOADING" } };
        });

        // Sending login credentials to the server and getting response
        let loginResponse = await frontOfficeCommunication.loginFrontOffice(data);

        // Setting the login response from the server in a local state
        setLoginFormSubmission(value => {
            let updatedValues = {};
            updatedValues.status = loginResponse.status;
            updatedValues.message = loginResponse.message;
            return { ...value, ...updatedValues };
        });

        // Handling login response from the server
        if (loginResponse.status === "SUCCESS") {

            //check the portal type 
            if (loginResponse.userData.portal !== "FRONT_OFFICE") {
                showModal("You are not authorized to access this portal");
                hideLoading();
                return;
            }
            // using dispatch to change the isAdminAuthenticated state in redux store 
            // and set the jwt token in a cookie
            dispatch({ type: "AUTHENTICATE_FRONTOFFICEUSER", payload: loginResponse.jwt, officeUser: loginResponse.userData, tabAccess: loginResponse.userData.tabs });
            // Navigate admin inside admin portal 
            history.push('/front-office/dashboard');
        } else if (loginResponse.status === "VALIDATION_FAILED") {
            // let errors = "";
            // loginResponse.validationErrorsList.forEach(validationError => {
            //     errors = errors + `\n` + validationError.message;
            // });
            setLoginFormSubmission(value => {
                return { ...value, ...{ message: errors } };
            });
        }
        hideLoading();
        setDisableActions(false);
        showModal(loginResponse.message);
    };

    useEffect(async () => {
        const getCommitteeDataFromServer = await backOfficeCommunication.getCommittees();
        let CommitteeArray = getCommitteeDataFromServer.committees;
        setCommittees(value => { return [...value, ...CommitteeArray] });
    }, []);


    return (
        <div>
            <section className="signin" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
                <div className="row m-0">
                    <div className="col-12">
                        <form onSubmit={handleSubmit(onSubmit)} style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000", border: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }}>
                            <div style={{ marginBottom: "30px" }}>
                                <H1 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Sign In</H1>
                            </div>

                            <div className="input_field_div1">
                                <H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>User ID</H3>
                                <i className="fa fa-user" aria-hidden="true"></i>
                                <div className="input_field1">
                                    <input type="text" name="userId" {...register("userId")} className={`form-control ${errors.userId ? "is-invalid" : ""}`} />
                                    <div className="invalid-feedback">{errors.userId?.message}</div>
                                </div>
                            </div>

                            <div className="input_field_div2">
                                <H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Password</H3>
                                <i className="fa fa-lock" aria-hidden="true"></i>
                                <div className="input_field2">
                                    <input type="password" name="password" {...register("password")} className={`form-control ${errors.password ? "is-invalid" : ""}`} />
                                    <div className="invalid-feedback">{errors.password?.message}</div>
                                </div>
                            </div>

                            <div className="select_committee">
                                <select name="committeeId" {...register("committeeId")} className={`form-select ${errors.committeeId ? "is-invalid" : ""}`}>
                                    <option value="">Select committeeId</option>
                                    {committees.map((committeeId) => {
                                        const { _id, name } = committeeId;
                                        return (
                                            <option value={_id} key={_id}>{name}</option>
                                        );
                                    })}

                                </select>
                                <div className="invalid-feedback">{errors.committeeId?.message}</div>
                            </div>
                            <button type="submit" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }} className="button" disabled={disableActions}> {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H3>Sign In</H3>}</button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default FrontOfficeSignIn;