import React, { useState, useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import H4 from "../../text-components/H4";
import P from "../../text-components/P";
import { backOfficeCommunication } from "../../communications/back-office-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import H5 from "../../text-components/H5";
import ReactPaginate from "react-paginate";

const DocumentScrutiny = () => {

    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const history = useHistory();
    const [milestoneData, setMilestoneData] = useState([]);
    const [filteredMilestoneData, setFilteredMilestoneData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage)
        if(searchValue){
            fetchDoucumentScrutiny(searchValue, selectedPage + 1)
        }else{
            fetchData(selectedPage + 1)
        }
    }

    //search Filter
    const searchFilter = async(searchString) =>{
        if(searchString){
        fetchDoucumentScrutiny(searchString, currentPage + 1);
        // console.log(searchString);
        }else{
            const response = await backOfficeCommunication.getUserApplicationWithMilestone();
            if(response?.status === "SUCCESS"){
                setMilestoneData(response?.users);
                setFilteredMilestoneData(response?.users);
                setPageCount(response?.totalPages);
            }else if (response?.status === "JWT_INVALID"){
                history.push("/back-office/login");
                showModal(response?.message);
            }else{
                setMilestoneData([]);
                setFilteredMilestoneData([]);
                setPageCount(0)
            }
        }
    }

    // get all DOCUMENT_SCRUTINY by search 
    const fetchDoucumentScrutiny = async (searchString, page) =>{
        // console.log("serarch-code",searchString);
    try {
        const response = await backOfficeCommunication.getGeneralMilestoneBySearch(searchString, page);
        if(response?.data?.status === "SUCCESS"){
            // console.log(response?.data);
            setMilestoneData(response?.data?.users);
            setFilteredMilestoneData(response?.data?.users);
            setPageCount(response?.data.totalPages);
        }else if(response?.data?.status === "JWT_INVALID"){
            history.push("/back-office/login");
            showModal(response?.message)
        }else{
            setFilteredMilestoneData([]);
            setMilestoneData([]);
            setPageCount(0)
        }
        } catch (error) {
        showModal(error)
        }  
    }


//get All Application With Milestone on 
    async function fetchData(page) {
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("document-scrutiny")) {
            dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
        }

        showLoading("Loading...");
        try {
            const response = await backOfficeCommunication.getUserApplicationWithMilestone(page);
            // console.log("inside code",response);
            if (response?.status === "SUCCESS") {
                setMilestoneData(response?.users);
                setFilteredMilestoneData(response?.users);
                setPageCount(response?.totalPages);
            }
            else if (response?.status === "ERROR" || response?.jwt === null) {
            }
            else {
                setMilestoneData([]);
                setFilteredMilestoneData([]);
                setPageCount(0)
                showModal(response?.message);
            }
        } catch (e) {
            showModal(e?.message);
        }
        hideLoading();
    }

    useEffect(() => {
        fetchData();
    }, []);

    function forApplicationId(applicationId) {
        history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`);

    }

    function showMilestoneList() {
        history.push("/back-office/dashboard/document-scrutiny/milestone-list");

    }


    // function onChangeFilterText(event, searchFor) {

    //     if (searchFor === "APPLICATION_ID") {
    //         const filterData = milestoneData.filter((ele) => ele.shortApplicationId.search(event.target.value) === -1 ? false : true);
    //         setFilteredMilestoneData(filterData);
    //     }

    //     if (searchFor === "FIRST_NAME") {
    //         const filterData = milestoneData.filter((ele) => {
    //             const fullname = ele.name.middleName.toLowerCase() + ele.name.lastName.toLowerCase() + ele.name.firstName.toLowerCase();
    //             return fullname.search(event.target.value.toLowerCase()) === -1 ? false : true;
    //         });
    //         setFilteredMilestoneData(filterData);
    //     }

    //     if (searchFor === "DISTRICT") {
    //         const filterData = milestoneData.filter((ele) => ele.district.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
    //         setFilteredMilestoneData(filterData);
    //     }

    //     if (searchFor === "STATUS") {
    //         const filterData = milestoneData.filter((ele) => ele.status.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
    //         setFilteredMilestoneData(filterData);
    //     }
    // }

    return (
        <section className="dashboard_background">
            {isLoading?.value ?
                <LoadingForms message={isLoading?.message} />
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="dashboard_back_office">
                                {/*-----Document scrutiny search box----*/}
                                <div className="row search_wrapper">
                                    <div className="col-lg-4 col-md-4 ps-0">
                                        <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Application ID, Name, District, Status ..." />
                                    </div>
                                    <div className="col-lg-8 col-md-8 pe-0 pagination_wrapper">
                                        {pageCount > 1 &&
                                        <ReactPaginate
                                            previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            forcePage={currentPage}
                                            activeClassName={"active"} />
                                        }
                                    </div>
                                </div>
                                {/* <div className="back_office_search_box">
                                    <div className="row">
                                        <div className="col-1"></div>
                                        <div className="col-3 search_box">
                                            <input type="search" className="form-control"  placeholder="Application Id" onChange={(event) => onChangeFilterText(event, "APPLICATION_ID")} />
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-3 search_box">
                                            <input type="search" className="form-control" placeholder="Name" onChange={(event) => onChangeFilterText(event, "FIRST_NAME")} />
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-3 search_box">
                                            <input type="search" className="form-control" placeholder="District" onChange={(event) => onChangeFilterText(event, "DISTRICT")} />
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div> */}
                                {/*----- card header section---*/}
                                <div className="dashboard_view_data_heading">
                                    <div className="row m-0">
                                        <div className="col-1 p-0 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Serial No.</H4></span>
                                        </div>
                                        <div className="col-3 p-0 view_dashboard ps-2">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Application ID</H4></span>
                                        </div>
                                        <div className="col-3 p-0 view_dashboard ps-2">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Name</H4></span>
                                        </div>
                                        <div className="col-3 p-0 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">District</H4></span>
                                        </div>
                                        <div className="col-2 p-0 ">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Status</H4></span>
                                        </div>
                                    </div>
                                </div>
                                {
                                    filteredMilestoneData.length > 0 ?
                                        <>
                                            {
                                                filteredMilestoneData?.map((value, index) => {
                                                    return (
                                                        <div className="dashboard_view_data_main" key={index}>
                                                            <div className="dashboard_view_data">
                                                                <div className="row m-0">
                                                                    <div className="col-1 p-0 view_dashboard text_align">
                                                                        <span>
                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{limit * currentPage + (index + 1)}</P>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-3 p-0 ps-2 view_dashboard text_align">
                                                                        <span>
                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a onClick={() => forApplicationId(value.applicationId)}>{value.shortApplicationId}</a></P>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-3 p-0 ps-2 view_dashboard text_align">
                                                                        <span>
                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a onClick={() => forApplicationId(value.applicationId)} >{`${value.name.firstName} ${value.name.middleName} ${value.name.lastName} `}</a></P>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-3 p-0 ps-2 view_dashboard text_align">
                                                                        <span>
                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{value.district}</P>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-2 p-0  text_align">
                                                                        <span ><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{value.backOfficeStatus}</P></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                        </>
                                        :
                                        <div className="text-center mt-5">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Records not available</H5>
                                        </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            }
        </section>
    );

}
export default memo(DocumentScrutiny);