import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import H3 from "../../text-components/H3";
import H4 from "../../text-components/H4";
import { useLocation } from "react-router-dom";
import { backOfficeCommunication, getServerUrl } from "../../communications/back-office-communication";
// import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import H5 from "../../text-components/H5";
import LoadingForms from "../../shared-components/LoadingForms";
import { useDispatch, useSelector } from "react-redux";
import { Document, Page } from "react-pdf";

const UserNotificationPreview = ({ changeHandler }) => {
    const history = useHistory();
    const [fileUrl, setFileUrl] = useState("");
    const [grFiles, setGRFiles] = useState({});
    const [showPdfFlag, setShowPdfFlag] = useState(false);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    // Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }



    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const location = useLocation();

    useEffect(() => {
        async function initialLoad() {
            let fullUrl = window.location.href;
            let splitUrlArray = fullUrl.split('/');
            if (splitUrlArray.includes("user-notification")) {
                dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "USER_NOTIFICATION" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
            }
            showLoading("Loading...");
            try {
                const queryParams = new URLSearchParams(location.search);
                const fileUrlFromQuery = queryParams.get('fileUrl');
                const gridId = queryParams.get('grId');

                const grDocument = await backOfficeCommunication.getPrepareGrList(gridId);
                setGRFiles(grDocument);

                setFileUrl(fileUrlFromQuery)

            } catch (error) {
                showModal(error.message);
            }
            hideLoading();
        }
        initialLoad();
    }, [])

    const notifyUser = async () => {
        showLoading("Loading...");

        const queryParams = new URLSearchParams(location.search);
        const grIdParams = queryParams.get('grId');
        const notify = await backOfficeCommunication.notifyUsers(grIdParams);
        if (notify.status === "SUCCESS") {
            changeHandler();
            history.push("/back-office/dashboard/user-notification");
        }
        hideLoading();
        showModal(notify.message);
    }

    function closeUserNotificationPreview() {
        history.push("/back-office/dashboard/user-notification");
    }
    return (
        <section className="back_office_preview_section">
            {isLoading.value ?
                <LoadingForms message={isLoading.message} />
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="back_office_preview_box">
                                <div className="back_office_preview_title">
                                    <H3>{fileUrl}</H3>
                                    <div className="close_back_office_preview" onClick={() => closeUserNotificationPreview()}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div className="back_office_document_preview">
                                    <Document file={`${getServerUrl()}/getfile/${fileUrl}`} onLoadSuccess={onDocumentLoadSuccess}>
                                        {Array.apply(null, Array(numPages))
                                            .map((x, i) => i + 1)
                                            .map((page, index) => <Page pageNumber={page} key={index} />)}
                                    </Document>
                                </div>

                            </div>
                            <div className="back_office_button">

                                {
                                    grFiles.gr?.areUsersNotified === false && <button className="ms-4 me-4" onClick={notifyUser}>
                                        {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H5>Notify User</H5>}
                                    </button>
                                }

                                <button onClick={closeUserNotificationPreview}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </section>
    );
}
export default UserNotificationPreview;