import React, { useState } from "react";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import { useHistory } from "react-router";
import H5 from "../../text-components/H5";
import H3 from "../../text-components/H3";


const DesignationMaintainanceCreate = () => {

    const history = useHistory();

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    //**********Signing Authority Name Array*** */
    const [signingAuthorityArray, setSigningAuthorityArray] = useState(["COMMISSIONER"]);

    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        name: "",
        signingAuthority: "",
    }

    //****Validation for Designation Create form*****/
    let yupValidationSchema = Yup.object().shape({
        name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Name is required"),
        signingAuthority: Yup.string().required("Select Signing Authority ")
    });

    // Destructuring useFormik to get functions to control aspects of form
    // What does Formik do?
    // 1. Handling form events like submit, errors, change in value
    // 2. Fetching values from the form 
    // 3. Support for a validation library on the data fetched. We are using Yup library for validations. 
    // 4. Reset the form
    // 5. Pass initial values to the form
    // 6. Handle errors thrown by the Yup (or any validation library).
    // 7. Reinitialize form values
    // 8. Perform async operations
    const { handleSubmit, handleChange, values, errors, handleReset } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit
        onSubmit: async (values) => {
            // Show loading 
            showLoading("Loading...");

            // Send values to the server
            const designation = {
                name: values.name,
                signingAuthority: values.signingAuthority
            }

            const designationCreationResponse = await adminCommunication.createDesignations(designation);

            // Handle response from the server
            if (designationCreationResponse.status === "SUCCESS") {
                dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "DESIGNATION_MAINTAINANCE_VIEW" });
                history.push('/admin/dashboard/designation-maintenance-view');
                handleReset();
            }

            // Show response message from server in modal dialog
            showModal(designationCreationResponse.message);

            // Hide spinner 
            hideLoading();
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: yupValidationSchema,
    });


    return (

        <div >
            <section className="create_form create_designation" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000", border: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }} >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :

                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <H3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Create Designation</H3>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"  >Designation Name</H5>
                            </label>
                            <div className="col-sm-7">
                                <input type="text" name="name" onChange={handleChange} value={values.name} className={`form-control ${errors.name ? "is-invalid" : ""}`} />
                                <div className="invalid-feedback">{errors.name}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"  >Signing Authority</H5>
                            </label>

                            <div className="col-sm-7">
                                <select name="signingAuthority" onClick={handleChange} className={`form-select ${errors.signingAuthority ? "is-invalid" : ""}`}>
                                    <option value={""} defaultValue>Select</option>
                                    {/* <option value="COMMISSIONER">COMMISSIONER</option> */}
                                    {signingAuthorityArray.map((signingAuthority, index) => {
                                        return (
                                            <option key={index} value={signingAuthority}>{signingAuthority}</option>
                                        );
                                    })}
                                </select>
                                <div className="invalid-feedback">{errors.signingAuthority}</div>
                            </div>
                        </div>


                        <button type="button" onClick={() => {
                            handleSubmit()
                        }} className="button" >
                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <h3>Create</h3>}
                        </button>

                    </form>
                }
            </section>
        </div>

    );
}
export default DesignationMaintainanceCreate;