import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const BackOfficeLogoutBox = ({ hideLogoutBox }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const BackOfficeUser = useSelector((state) => state?.isBackOfficeAuthenticated?.backOfficeUser);

    // logout 
    function logoutBackOfficeUser() {
        dispatch({ type: "DEAUTHENTICATE_BACKOFFICEUSER" });
        document.cookie = "sjsadBackOfficeToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        history.push('/back-office/login');
    }


    return (
        <>
            <div className="row logout_box_front">
                <span onClick={() => { hideLogoutBox() }}>
                    <i className="fa fa-times"></i>
                </span>
                <div className="col-12" style={{ marginBottom: "0px", marginTop: "5px" }}>
                    <h5>Name : {BackOfficeUser?.name}</h5>
                    <h5>Id : {BackOfficeUser?.id}</h5>
                    <h5>Designation : {BackOfficeUser?.designation}</h5>
                    <button type="button" className="button" onClick={() => logoutBackOfficeUser()} >
                        <h3>Logout</h3>
                    </button>
                </div>
            </div>
        </>
    )
};

export default BackOfficeLogoutBox;
