import React, { useState, useEffect } from "react";
import P from "../../text-components/P";
import Multiselect from "multiselect-react-dropdown";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { backOfficeCommunication } from "../../communications/back-office-communication";
import { useDispatch } from "react-redux";
import LoadingForms from "../../shared-components/LoadingForms";
import Label from "../../text-components/Label";

const IndividualMessageForm = ({ setShowIndividualMsgForm, get }) => {

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }
    // spinner on loading
    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    const [year, setYear] = useState([]);
    const [validationMessage, setValidationMessage] = useState(false);
    const [message, setMessage] = useState();
    const [fileUrl, setFileUrl] = useState();
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [studentList, setStudentList] = useState([])

    async function initialLoad() {
        const response = await backOfficeCommunication.getUsers();
        if (response?.status === "SUCCESS") {
            setStudentList(response?.userIds);
            return response;
        }
    }

    // function for loader
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    useEffect(() => {
        // Calculate Year
        let date = new Date();
        for (let i = 2022; i <= date.getFullYear(); i++) {
            setYear([...year, i]);
        }
        initialLoad();
    }, [])

    const validationSchema = Yup.object().shape({
        year: Yup.string().required("Select Year"),
        message: Yup.string().required("Message is required"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    // get functions to build with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    //Send Message Function
    const onSubmit = async () => {
        try {
            showLoading("Loading...");

            if ([undefined, null, ""].includes(selectedStudents)) {
                setValidationMessage(true);
                return false;
            }

            let userIds = [];

            selectedStudents.forEach((student) => {
                userIds.push(student.userId);
            });

            // Send values to the server
            let isFileAttached = false;
            let formData = new FormData();
            if (fileUrl) {
                formData.append("grievanceFile", fileUrl)
                isFileAttached = true;
            }

            const dataToSend = {
                message,
                fileUrl,
                userIds,
                isFileAttached
            }

            const response = await backOfficeCommunication.sendIndividualMessage(dataToSend);

            if (response?.data?.status === "SUCCESS") {
                get();
                setShowIndividualMsgForm(false);
                hideLoading();
                return;
            }

            if (response?.data?.status === "SUCCESS") {
                showModal(response?.data?.message);
                get();
                setShowIndividualMsgForm(false);
                hideLoading();
                return;
            }
            if (response?.data?.status === "VALIDATION_FAILED") {
                showModal(response?.data?.validationErrorsList[response?.data?.validationErrorsList?.length - 1]?.message)
                get();
                setShowIndividualMsgForm(false);
                hideLoading();
                return;
            } else {
                showModal(response?.data?.message);
                get();
                setShowIndividualMsgForm(false);
                hideLoading();
                return;
            }
        }
        catch (err) {
            showModal(err?.message);
            get();
            setShowIndividualMsgForm(false);
            hideLoading();
            return;
        }
    }

    return (
        <div className="individual_msg_form_main">
            <div className="individual_msg_form">
                {isLoading?.value ?
                    <LoadingForms message={isLoading?.message} />
                    :
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-lg-5 col-md-5">
                                <select {...register("year")} className={`form-select ${errors.year ? "is-invalid" : ""}`}>
                                    <option value="">Select Year</option>
                                    {year?.map((currentYear, index) => (
                                        <option value={currentYear} key={index}>{currentYear}</option>
                                    ))}
                                </select>
                                <div className="invalid-feedback">{errors.year?.message}</div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <Multiselect
                                    options={studentList}
                                    displayValue="fullName"
                                    showCheckbox={true}
                                    onSelect={(event) => setSelectedStudents(event)}
                                    onRemove={(event) => setSelectedStudents(event)}
                                    placeholder="Select Students"
                                    showArrow
                                    closeIcon="cancel"
                                    customArrow={<><i className="fa fa-angle-down"></i></>}
                                    keepSearchTerm={true}
                                />
                                {
                                    validationMessage &&
                                    <p className="message_form_validation">Student is required</p>
                                }
                            </div>
                        </div>
                        <div className="user_chatbot_textarea_main">
                            <div className="user_chatbot_textarea">
                                <div className="user_chatbot_text_file">
                                    <textarea placeholder="Type your message here..." {...register("message")} onChange={(event) => setMessage(event.target.value)} className={`form-control ${errors.message ? "is-invalid" : ""}`} />
                                    <div className="invalid-feedback">{errors.message?.message}</div>
                                    <>
                                        <div className="input-group attach_file" title="Click to attach file">
                                            <input type="file" id="file" onChange={(event) => setFileUrl(event.target.files[0])} style={{ display: "none" }} />
                                            <label htmlFor="file"><i className="fa fa-paperclip fa-1x ms-2" aria-hidden="true"></i></label>
                                        </div>
                                        <div className="attache_file_name">
                                            <P>{fileUrl?.name}</P>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>

                        <div style={{ textAlign: "end" }}>
                            <span style={{ fontSize: "12px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf/jpg/jpeg/png & size must be less than 2mb.</b></span>
                        </div>

                        <div className="row individual_form_button">
                            <div className="col-12">
                                <button type="submit">Send</button>
                                <button className="ms-3" onClick={() => setShowIndividualMsgForm(false)}>Cancel</button>
                            </div>
                        </div>
                    </form>
                }
            </div>
        </div>
    );
}
export default IndividualMessageForm;