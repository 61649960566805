import React , {useState} from "react";

const EligibleCriteria = () => {


  return (
    <section className="eligible_criteria_info">
      <div className="row">
        <div className="col-12">
          <ul>
            <li>Candidate should be<b> a Resident </b> of State of <b> Maharashtra </b> in the <b> Scheduled Castes </b> and <b> Non-Bouddha </b> Community.</li>
            <li>Candidate shall have <b>unconditional offer letter</b> from Universities having <b>QS world ranking till 300</b>.</li>
            <li>Candidate <b>shall not</b> have <b>availed benefits from any of the other State Govt</b>. or Central Govt. in past before applying from this scheme.</li>
            <li>The candidate shall have unconditional offer letter for a <b>full-time course</b> only.</li>
            <li><b>Executive and Part time courses</b> will <b>not</b> be considered from this scheme.</li>
            <li>Candidates shall complete their course within the course duration. Additional duration shall be considered for scholarship.</li>
            <li>Age limit for applications shall be:For Post Graduation : <b>till 35 Years</b> For PhD :<b>till 40 Years</b></li>
            <li>Annual Income of the family including the candidate shall not be exceeding <b>Rs.6 Lakhs per annum</b>.</li>
            <li>Candidate shall have <b>minimum 55% in Graduation</b>.</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default EligibleCriteria;