import React from "react";
import H1 from "../../text-components/H1";
import { useSelector } from "react-redux";

const AdminWelcome = () => {
    // set admin data 
    const adminData = useSelector((state) => state.isAdminAuthenticated.admin);

    return (
        <div>
            <div className="row dashboard_background">
                <div className="col download_excel admin_home">
                    <H1 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Welcome, {adminData ? adminData.name : ""}</H1>
                </div>
            </div>
        </div>
    )
}

export default AdminWelcome;