import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { communication } from '../communications/communication';
import LoadingForms from "../shared-components/LoadingForms";
import H4 from "../text-components/H4";
import MulipleDocumentUpload from '../shared-components/MulipleDocumentUpload';


const UserMilestone1Form = ({ changeHandler }) => {
    const history = useHistory();
    const location = useLocation();

    // getting committee id by queryParams
    const queryParams = new URLSearchParams(location.search);
    const applicationId = queryParams.get('applicationId');
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);

    // spinner on loading 
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [bondCertificateUrl, setBondCertificateUrl] = useState([]);
    const [selectedDocumentArray, setSelectedDocumentArray] = useState([]);



    const [milestoneDocument, setMilestoneDocument] = useState({});

    const CloseUserMilestone1 = () => {
        history.push("/user/doc-submission");
    }

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    async function getMileStoneDocument() {

        try {
            const result = await communication.getUserMilestone(applicationId, "MILESTONE_ONE");
            if (result.status === "SUCCESS") {
                setMilestoneDocument(result.milestoneDetails[result.milestoneDetails.length - 1]);
            } else {
                showModal(result.message);
            }

        } catch (error) {
            showModal(error.message);
        }
    }

    useEffect(() => {

        getMileStoneDocument(applicationId);

    }, []);


    const handleSubmit = async (values) => {
        try {

            // check if files are selected
            if (bondCertificateUrl.length === 0) {
                return showModal("Please select Files before uploading");
            }

            showLoading("Uploading...");

            const formData = new FormData();


            //  construct for signed bond url
            let newSignedBondCertificate = [];
            for (let index = 0; index < bondCertificateUrl.length; index++) {
                const element = bondCertificateUrl[index];
                element.file.documentName = element['documentName'];
                newSignedBondCertificate.push(element.file);
            }

            //  convert all image to form data
            newSignedBondCertificate.forEach((ele) => {
                formData.append('bondCertificate', ele, ele.documentName);
            });


            // add document name to files
            let newDocumentArray = [];

            for (let index = 0; index < selectedDocumentArray.length; index++) {
                const element = selectedDocumentArray[index];
                element.file.documentName = element['documentName'];
                newDocumentArray.push(element.file);
            }

            //  convert all image to form data
            newDocumentArray.forEach((ele) => {
                formData.append('document', ele, ele.documentName);
            });


            const uploadResult = await communication.uploadDocumentForMilestoneOne(formData, applicationId);
            if (uploadResult?.data?.status === "SUCCESS") {
                showModal("Document uploaded successfully.");
                changeHandler();
                // remove all selected file from state
                setBondCertificateUrl("");
                setSelectedDocumentArray([]);
                getMileStoneDocument();
            } else {
                showModal(uploadResult?.data?.message);
            }

            hideLoading();
            return;

        } catch (error) {
            hideLoading();
            showModal(error.message);
            return false
        }

    };


    return (
        <div className="user_milestone_main">
            {isLoading?.value ?
                <LoadingForms message={isLoading?.message} />
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="user_milestone">
                                <div className="user_milestone_header">
                                    <H4 style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Document Verification</H4>
                                </div>
                                <div className="user_milestone_body">
                                    <div className="row">
                                        <div className="col-12" style={{ textAlign: 'center' }}>

                                            {
                                                ["NEW", "IN_COMPLETED"].includes(milestoneDocument?.userStatus) === true ?
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>* Please attach required Bond Certificate.</H4>
                                                    :
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Uploaded Documents</H4>

                                            }
                                        </div>
                                    </div>


                                    <MulipleDocumentUpload
                                        setSelectedDocumentArray={setBondCertificateUrl}
                                        selectedDocumentArray={bondCertificateUrl}
                                        title={"Signed Bond Certificates"}
                                        status={milestoneDocument?.userStatus}
                                        uploadedDocumentArray={milestoneDocument?.signedBondUrl}
                                    />

                                    <MulipleDocumentUpload
                                        setSelectedDocumentArray={setSelectedDocumentArray}
                                        selectedDocumentArray={selectedDocumentArray}
                                        title={"Other Documents"}
                                        status={milestoneDocument?.userStatus}
                                        uploadedDocumentArray={milestoneDocument?.documentsArray}

                                    />

                                    <div className="mt-4 mb-2" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                        {
                                            ["NEW", "IN_COMPLETED"].includes(milestoneDocument?.userStatus) === true &&
                                            <button className="ms-4" type='submit' onClick={handleSubmit}>Submit</button>
                                        }
                                        <button className="ms-4" onClick={() => CloseUserMilestone1()}>Cancel</button>
                                    </div>
                                    {
                                        ["NEW", "IN_COMPLETED"].includes(milestoneDocument?.userStatus) === true &&
                                        <span style={{ fontSize: "14px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
export default UserMilestone1Form;