import React, { useState } from 'react'
import { useSelector } from "react-redux";
import H5 from "../text-components/H5";

export const HomeScreenScholarshipScheme = () => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);

    const [scholarshipSchemeArray, setScholarshipSchemeArray] = useState(
        [
            {
                description: "Education & Training",
                downloadLink: "https://sjsa.maharashtra.gov.in/en/scheme-category/education-training",
            },
            {
                description: "Economic Upliftment",
                downloadLink: "https://sjsa.maharashtra.gov.in/en/scheme-category/economic-upliftment",

            },
            {
                description: "Employment",
                downloadLink: "https://sjsa.maharashtra.gov.in/en/scheme-category/employment",
            },
            {
                description: "Special Assistance",
                downloadLink: "https://sjsa.maharashtra.gov.in/en/scheme-category/special-assistance",
            },
            {
                description: "Disability Welfare",
                downloadLink: "https://sjsa.maharashtra.gov.in/en/scheme-category/disability-welfare",
            },
            {
                description: "Social Integration",
                downloadLink: "https://sjsa.maharashtra.gov.in/en/scheme-category/social-integration",
            },
            {
                description: "Social Remedies",
                downloadLink: "https://sjsa.maharashtra.gov.in/en/scheme-category/social-remedies",
            },
            {
                description: "Awards",
                downloadLink: "https://sjsa.maharashtra.gov.in/en/scheme-category/awards",
            }
        ]
    );
    return (

        <div className="homescreen_notification_card" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
            <div className="homescreen_notification_card_header" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#6D8DAD" : "#000000" }}>
                <div className="homescreen_notification_card_title" style={{ fontSize: `${20 * fontSizeMultiplier / 100}px`, color: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#FFFF00" }}><b>Other Department  Scheme</b></div>
            </div>

            <div className="homescreen_notification_card_body">
                {
                    scholarshipSchemeArray.map((scholarship, index) => {
                        const { description, downloadLink } = scholarship;
                        return (
                            <div className='mb-3' key={index} style={{ width: "100%", display: "flex" }}>
                                <div style={{ display: "inline-block", display: "flex", alignSelf: "start" }}><span><svg xmlns="http://www.w3.org/2000/svg" width="17.252" height="16.914" viewBox="0 0 17.252 16.914">
                                    <g id="Group_18" data-name="Group 18" transform="translate(-291.061 -2128.062)" opacity="0.8">
                                        <path id="Path_2" data-name="Path 2" d="M194.475,1355.475l6.984,6.867-6.984,7.219" transform="translate(98 774)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Path_3" data-name="Path 3" d="M194.475,1355.475l6.984,6.867-6.984,7.219" transform="translate(105.853 774)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </g>
                                </svg>
                                </span>
                                </div>&nbsp;&nbsp;
                                <div style={{ display: "inline-block", display: "flex", alignSelf: "center" }}>
                                    <a href={downloadLink} target="_blank">
                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                            <span style={{ cursor: "pointer" }}>{description}</span>
                                        </H5>
                                    </a>
                                </div>
                            </div>
                        )
                    })

                }
            </div>

            {/* <div style={{ marginTop: "10px", display:"flex", justifyContent:"end", marginRight:"30px" }}>
                <div style={{ justifyContent:"center", textAlign: "center", backgroundColor: "#FFFFFF", width:"100px", padding:"5px", border: "1px solid #C4C4C4", borderRadius: "50px", boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", alignItems: "end", display:"flex", cursor:"pointer" }}><H4 whiteThemeColor="#1E2343"><b>View All</b></H4></div>
            </div> */}
        </div>
    )
}
