import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import H5 from '../text-components/H5';

const AdminLogoutBox = ({ hideLogoutBox }) => {

    const history = useHistory();

    const dispatch = useDispatch();

    // set admin data 
    const admin = useSelector((state) => state.isAdminAuthenticated.admin)

    // logout 
    function logoutAdminPortal() {
        dispatch({ type: "DEAUTHENTICATE_ADMIN" });
        document.cookie = "sjsadAdminToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        history.push('/admin/login');
    }


    return (
        <>
            <div className="row logout_box_admin">
                <span onClick={() => { hideLogoutBox() }}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                </span>
                <div className="col-12" style={{ marginBottom: "0px", marginTop: "5px" }}>
                    <H5>Name : {admin ? admin.name : ""}</H5>
                    <H5>Id : {admin ? admin.id : ""}</H5>
                </div>
                <div className="col-12">

                </div>
                <div className="col-4" style={{ margin: "auto", marginTop: "0" }} >
                    <button type="button" className="button" onClick={() => logoutAdminPortal()} >
                        <H5>Logout</H5>
                    </button>
                </div>
            </div>
        </>
    )
};

export default AdminLogoutBox;
