import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import { communication } from '../communications/communication';
import { removeUnderscoreFromText } from '../utlis/helper';
import P from "../text-components/P";

const PreviewUserMarksheetDetail = ({ semesterId, setShowUpdateMarksheetDetailForm }) => {
    const [semesterMarks, setSemesterMarks] = useState({});
    const [addedSubjects, setAddedSubjects] = useState([]);
    const dispatch = useDispatch();

    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //Fetch user Semester List by userId
    const fetchUserSemesterMarks = async () => {
        try {
            const responseFromServer = await communication.getUserSemesterMarksBySemesterId(semesterId);
            if (responseFromServer.status === "SUCCESS") {
                if (semesterId === responseFromServer?.userSemMarks?._id) {
                    setSemesterMarks(responseFromServer?.userSemMarks);
                    setAddedSubjects(responseFromServer?.userSemMarks?.totalMarks);
                }
            } else {
                showModal(responseFromServer.message);
            }
        } catch (error) {
            showModal(error.message);
        }
    }

    useEffect(() => {
        fetchUserSemesterMarks();
    }, []);

    return (
        <div className="marks_detail_section">
            <div>
                <div className="row mb-3">
                    <div className="col-6">
                        <input type="text" name="semester" value={removeUnderscoreFromText(semesterMarks?.semester)} className="form-control" />
                    </div>
                    <div className="col-6">
                        <input type="text" name="typeOfMark" value={semesterMarks?.typeOfMark} className="form-control" />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-4 text-center">
                        <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Subject Name</P>
                    </div>
                    <div className="col-4 text-center">
                        <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Total Marks</P>
                    </div>
                    <div className="col-4 text-center">
                        <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Obtained Marks</P>
                    </div>
                </div>
                {/*-----Subject table----*/}
                {addedSubjects?.map((subject, index) => (
                    <div className="row mb-2" key={index}>
                        <div className="col-4">
                            <input type="text" value={subject?.subject} name="subject" className="form-control" />
                        </div>
                        <div className="col-4">
                            <input type="text" value={subject?.totalMarks} name="totalMarks" className="form-control" />
                        </div>
                        <div className="col-4">
                            <input type="text" value={subject?.obtainedMarks} name="obtainedMarks" className="form-control" />
                        </div>
                    </div>
                ))}

                <div className="row mt-4">
                    <div className="col-12 submit_marks_button">
                        <button className="ms-3" onClick={() => setShowUpdateMarksheetDetailForm(false)}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviewUserMarksheetDetail