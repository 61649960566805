
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import H3 from "../../text-components/H3";
import H4 from "../../text-components/H4";
import { useLocation } from "react-router-dom";
import { backOfficeCommunication, getServerUrl } from "../../communications/back-office-communication";
// import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import fileDownload from 'js-file-download';
import LoadingForms from "../../shared-components/LoadingForms";
import { useDispatch } from "react-redux";
import { Document, Page } from "react-pdf";


const DocumentScrutinyFilesPreview = () => {

    const history = useHistory();


    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    const location = useLocation();

    const [fileUrl, setFileUrl] = useState("")
    const [numPages, setNumPages] = useState(null);
    const [applicationId, setApplicationId] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [type, setType] = useState("")
    const [milestoneType, setMilestoneType] = useState();
    const [milestoneId, setMilestoneId] = useState('');
    const [documentName, setDocumentName] = useState('');

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    //close document scrutiny uploaded files window
    function closeDocumentScrutinyFilesPreview(applicationId, type) {
        history.goBack();
        // history.push(`/back-office/dashboard/document-scrutiny/milestone-list/uploaded-documents?milestoneId=${milestoneId}&applicationId=${applicationId}&type=${type}`);
    }

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    // Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }



    useEffect(() => {
        async function fetchData() {
            try {
                let fullUrl = window.location.href;
                let splitUrlArray = fullUrl.split('/');
                if (splitUrlArray.includes("document-scrutiny")) {
                    dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
                } else {
                    dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
                }
                showLoading("Loading...");
                const queryParams = new URLSearchParams(location.search);
                const fileUrlFromQuery = queryParams.get('fileUrl');
                const applicationUrlFromQuery = queryParams.get('applicationId');
                const type = queryParams.get('type');
                const milestoneIdFromQuery = queryParams.get("milestoneId");
                setMilestoneId(value => value = milestoneIdFromQuery);

                // const result = await backOfficeCommunication.getUploadUserMilestone(fileUrlFromQuery, applicationUrlFromQuery, type)
                // showModal(response);
                setApplicationId(applicationUrlFromQuery)
                setFileUrl(fileUrlFromQuery)
                setType(type);

                //Removing .pdf extension from uploaded file
                let file = fileUrlFromQuery;
                let filename = file.split('.').slice(0, -1).join('.');
                setDocumentName(filename);
                hideLoading();
                // return result;
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();

    }, [])
    return (
        <section className="back_office_preview_section">
            {isLoading.value ?
                <LoadingForms message={isLoading.message} />
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="back_office_preview_box">
                                <div className="back_office_preview_title">
                                    <H3>{documentName?.split('/')[2]}</H3>
                                    <div className="close_back_office_preview" onClick={() => closeDocumentScrutinyFilesPreview(applicationId, type)}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </div>
                                </div>

                                <div className="back_office_document_preview">
                                    <Document file={`${getServerUrl()}/getUserFiles/${fileUrl}`} onLoadSuccess={onDocumentLoadSuccess}>
                                        {Array.apply(null, Array(numPages))
                                            .map((x, i) => i + 1)
                                            .map(page => <Page pageNumber={page} />)}
                                    </Document>

                                </div>

                            </div>


                            <div className="back_office_button">

                                <button className="me-4"><a href={`${getServerUrl()}/getUserFiles/${fileUrl}`} target={"_blank"} rel={"noreferrer"}>Print</a></button>

                                <button onClick={() => closeDocumentScrutinyFilesPreview(applicationId, type)}>Cancel</button>
                            </div >
                        </div >
                    </div >
                </div >
            }
        </section >
    );
}
export default DocumentScrutinyFilesPreview;