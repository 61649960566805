import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { communication } from '../communications/communication';
import H4 from "../text-components/H4";
import * as Yup from "yup";
import { useFormik } from 'formik';
import LoadingForms from "../shared-components/LoadingForms";
import { removeUnderscoreFromText } from '../utlis/helper'

const UserMilestone3Form = ({ changeHandler }) => {

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const [expenseReportUrl, setExpensesReportUrl] = useState();
    const history = useHistory();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [initialFormValues, setInitialFormValues] = useState(null);

    // getting committee id by queryParams
    const queryParams = new URLSearchParams(location.search);
    const applicationId = queryParams.get('applicationId');

    const [milestoneDocument, setMilestoneDocument] = useState({});

    const CloseUserMilestone3 = () => {
        history.push("/user/doc-submission");
    }

    function getFilePath(e) {
        setExpensesReportUrl(e.currentTarget.files[0]);
        setFieldValue('expenseReportUrl', e.currentTarget.files[0]);
    }

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    async function getMileStoneDocument() {
        showLoading("Loading...");
        try {
            const result = await communication.getUserMilestone(applicationId, "MILESTONE_THREE");
            if (result?.status === "SUCCESS") {
                let data = result?.milestoneDetails[result?.milestoneDetails?.length - 1];
                setMilestoneDocument(data);
            } else {
                showModal(result?.message);
            }
            hideLoading();
        } catch (error) {
            showModal(error?.message)
            hideLoading();
        }

    }

    useEffect(() => {
        getMileStoneDocument(applicationId);
    }, []);

    const defaultFormValues = {
        expenseReportUrl: null,
    }
    const validationSchema = Yup.object().shape({
        expenseReportUrl: Yup.mixed().required("Attachment is required !"),
    });

    const { handleSubmit, handleChange, setFieldValue, values, errors, handleReset } = useFormik({
        initialValues: initialFormValues === null ? defaultFormValues : initialFormValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {

            try {
                showLoading("Uploading...");
                //set personal details object to send
                // let percentage = personalDetailsForm.totalMarks / personalDetailsForm.totalNumber * 100;

                let formData = new FormData();
                formData.append("document", values.expenseReportUrl, values.expenseReportUrl.name);


                const uploadResult = await communication.uploadExpenditureReportForMilestoneThreeByUser(formData, applicationId);
                if (uploadResult?.data?.status === "SUCCESS") {
                    history.push("/user/doc-submission");
                    getMileStoneDocument(applicationId);
                }
                changeHandler();
                showModal(uploadResult?.data?.message || uploadResult?.message);
                handleReset();
                hideLoading();
                return true;

            } catch (error) {
                hideLoading();
                showModal(error?.message);
                return;
            }
        },
        validationSchema: validationSchema,
    });

    const downloadDocumentFromServer = async (attachment) => {

        if (attachment !== "") {
            await communication.getMilestoneDocumentfromServer(attachment);
        }
    }

    return (
        <div className="user_milestone_main">
            {isLoading.value ?
                <LoadingForms message={isLoading?.message} />
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="user_milestone">
                                <div className="user_milestone_header">
                                    <H4 style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Annual Expenses Report</H4>
                                </div>
                                <div className="row mt-2 ml-1 user_milestone_body">
                                    <div className="col-12 pe-0">
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px`, fontWeight: 600 }}>Application ID : {(!!milestoneDocument?.application) ? milestoneDocument?.application[milestoneDocument?.application?.length - 1]?.shortApplicationId : ""}</H4>
                                    </div>
                                    <div className="col-12 pe-0 mt-4">
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Status : {removeUnderscoreFromText(milestoneDocument?.userStatus)}</H4>
                                    </div>
                                </div>

                                {
                                    (milestoneDocument.userStatus === "NEW" || milestoneDocument.userStatus === "IN_COMPLETED") &&
                                    <div className="user_milestone_body" style={{ marginTop: "-15px" }}>
                                        <div className="row mt-4">
                                            <div className="col-4 pe-0" style={{ display: "flex", alignItems: "center" }}>
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Annual Expenses Report </H4>
                                            </div>
                                            <div className="col-8 ps-0">
                                                <input className="form-control form-control-sm" name="expenseReportUrl" value={values.expenseReportUrl?.filename} type="file" id="formFile" onChange={(e) => getFilePath(e)} />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-4"></div>
                                            <div className='col-8'>
                                                <p className='text-danger'> {errors.expenseReportUrl} </p>
                                            </div>
                                        </div>
                                        <div className="row mt-4 mb-3">
                                            <div className="col-12" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                <button className='ms-4' onClick={handleSubmit}>Submit</button>
                                                <button className="ms-4" onClick={() => CloseUserMilestone3()}>Cancel</button>
                                            </div>
                                        </div>

                                        <span style={{ fontSize: "14px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>

                                    </div>
                                }
                                {
                                    ["NEW", "ASSIGNED", "IN_COMPLETED", "SUBMITTED", "APPROVED"].includes(milestoneDocument.userStatus) &&
                                    <div className='user_milestone_body' style={{ marginTop: "-20px" }}>
                                        {
                                            ["SUBMITTED", "IN_COMPLETED", "APPROVED"].includes(milestoneDocument.userStatus) &&
                                            !!milestoneDocument.expenditureReportUrl &&
                                            <div className="row">
                                                <div className="col-9">
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Annual Expense report</H4>
                                                </div>
                                                <div className="col-3" style={{ textAlign: "right" }}>
                                                    <button className='ms-4' onClick={() => downloadDocumentFromServer(milestoneDocument?.expenditureReportUrl)}> Preview </button>
                                                </div>
                                            </div>

                                        }
                                        {
                                            ["APPROVED"].includes(milestoneDocument.userStatus) && !!milestoneDocument?.officeExpenditureReportUrl
                                                ?
                                                <>
                                                    <div className="row mt-3">
                                                        <div className="col-9">
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Expenditure report (Office)</H4>

                                                        </div>
                                                        <div className="col-3" style={{ textAlign: "right" }}>
                                                            <button className='ms-4' onClick={() => downloadDocumentFromServer(milestoneDocument?.officeExpenditureReportUrl)}> Preview </button>

                                                        </div>
                                                    </div>
                                                    {/* <div className="row mt-3">
                                                        <div className="col-12" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                            <button className="ms-4" onClick={() => CloseUserMilestone3()}>Cancel</button>
                                                        </div>
                                                    </div> */}
                                                </>
                                                : ""

                                        }

                                        {
                                            ["SUBMITTED", "APPROVED"].includes(milestoneDocument.userStatus) &&
                                            <div className="row mt-3">
                                                <div className="col-12" style={{textAlign:"right"}}>
                                                    <button className="ms-4" onClick={() => CloseUserMilestone3()}>Cancel</button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
export default UserMilestone3Form;