import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import { useHistory } from "react-router";
import H5 from "../../text-components/H5";
import H3 from "../../text-components/H3";
import H7 from "../../text-components/H7";


const DepartmentMaintainanceCreate = () => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const history = useHistory();

    const [designationIds, setDesignationIds] = useState([]);


    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    //**********Signing Authority Name Array*** */
    // const [signingAuthorityArray, setSigningAuthorityArray] = useState(["COMMISSIONER"]);

    const [allDesignationsMaintenanceData, setAllDesignationsMaintenanceData] = useState([]);


    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    async function getData() {
        showLoading("Loading...");

        dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "DEPARTMENT_MAINTENANCE_CREATE" });

        const designationsFromServer = await adminCommunication.getAllDesignations();

        if (designationsFromServer.status === "SUCCESS") {
            let designationsArray = designationsFromServer.designations;

            // designationsArray.fill((value) => {
            //     value.checked = false;
            // });
            designationsArray.forEach((ele) => {
                ele.checked = false;
            })

            // get all designation from server
            setAllDesignationsMaintenanceData(value => {
                return [...value, ...designationsArray]
            });
        } else {
            showModal(designationsFromServer.message);
        }

        hideLoading();
    }


    useEffect(() => {
        getData();
    }, []);



    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        name: "",
        location: "",
        signingAuthority: "",
        designationArray: allDesignationsMaintenanceData,
    }

    //****Validation for Designation Create form*****/
    let yupValidationSchema = Yup.object().shape({
        name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Name is required"),
        location: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Location is required"),
        signingAuthority: Yup.string().required("Select Signing Authority "),
        designationArray: Yup.array().test("check designation array", "Please select atleast one", function (designationArr) {
            console.log(designationArr);
            return designationArr.some((value) => value.checked === true);
        })
    });

    // Destructuring useFormik to get functions to control aspects of form
    // What does Formik do?
    // 1. Handling form events like submit, errors, change in value
    // 2. Fetching values from the form 
    // 3. Support for a validation library on the data fetched. We are using Yup library for validations. 
    // 4. Reset the form
    // 5. Pass initial values to the form
    // 6. Handle errors thrown by the Yup (or any validation library).
    // 7. Reinitialize form values
    // 8. Perform async operations
    const { handleSubmit, handleChange, values, errors, handleReset, setFieldValue } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit
        onSubmit: async (values) => {
            // Show loading 
            showLoading("Loading...");

            // send on those designatio whose property checked is true
            let designationArr = [];
            for (let index = 0; index < values.designationArray.length; index++) {
                const element = values.designationArray[index];
                if (element.checked === true) {
                    console.log({ element });
                    designationArr.push(element._id);
                }
            }

            // Send values to the server
            const departments = {
                name: values.name.trim(),
                location: values.location.trim(),
                signingAuthority: values.signingAuthority,
                designationArray: designationArr,
            }

            const departmentsCreationResponse = await adminCommunication.createDepartments(departments);

            // Handle response from the server
            if (departmentsCreationResponse.status === "SUCCESS") {
                handleReset();
                dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "DEPARTMENT_MAINTENANCE_VIEW" });
                history.push('/admin/dashboard/department-maintenance-view');
            }

            if (departmentsCreationResponse.status === "DEPARTMENT_ALREADY_EXISTS") {
                showModal(departmentsCreationResponse.message);
                hideLoading();
                return;
            }
            // Show response message from server in modal dialog
            showModal(departmentsCreationResponse.message);

            // Hide spinner 
            hideLoading();
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: yupValidationSchema,
    });



    // set ids in to state
    const handleOnChange = (e, idx) => {
        let checked = e.target.checked;


        let newArr = [...allDesignationsMaintenanceData];

        newArr[idx].checked = checked;

        setFieldValue('designationArray', newArr);
        setAllDesignationsMaintenanceData(newArr);

        // if (checked) {
        //     setFieldValue('designationArray', [...designationIds, id]);
        //     setDesignationIds(() => [...designationIds, id]);
        // } else {
        //     let filtered = designationIds.filter((Id) => Id !== id)
        //     setFieldValue('designationArray', filtered);
        //     setDesignationIds(
        //         filtered
        //     );
        // }
        return;
    }

    return (

        <div className="mb-5" >
            <section className="create_form create_designation" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000", border: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }} >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :

                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <H3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Create Department</H3>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"  >Department Name</H5>
                            </label>
                            <div className="col-sm-7">
                                <input type="text" name="name" onChange={handleChange} value={values.name} className={`form-control ${errors.name ? "is-invalid" : ""}`} />
                                <div className="invalid-feedback">{errors.name}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"  >Location</H5>
                            </label>
                            <div className="col-sm-7">
                                <input type="text" name="location" onChange={handleChange} value={values.location} className={`form-control ${errors.location ? "is-invalid" : ""}`} />
                                <div className="invalid-feedback">{errors.location}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"  >Signing Authority</H5>
                            </label>

                            <div className="col-sm-7">
                                <select name="signingAuthority" onChange={handleChange} value={values.signingAuthority} className={`form-select ${errors.signingAuthority ? "is-invalid" : ""}`} >
                                    <option value={""}>Select Authority</option>
                                    <option value="COMMISSIONER">COMMISSIONER</option>
                                    {/* {signingAuthorityArray.map(signingAuthority => {
                                    return (
                                        <option value={signingAuthority}>{signingAuthority}</option>
                                    );
                                    })} */}
                                </select>
                                <div className="invalid-feedback">{errors.signingAuthority}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-lg-4 col-md-2 col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"  >Designation</H5>
                            </label>
                            <div className="col-lg-8 col-md-10 col-sm-8">
                                <div className={`row ${errors.designationArray ? "is-invalid" : ""}`}>
                                    {
                                        allDesignationsMaintenanceData.map((designationData, idx) => {
                                            let { _id, name, checked } = designationData;
                                            return (
                                                <div className="col-sm-4" key={idx}>
                                                    <div className="Checkbox-inline">
                                                        <input type="checkbox" name="designationArray" value={_id} onClick={(e) => { handleOnChange(e, idx) }} checked={checked} className={'form-checkbox ' + (errors.designationArray ? ' is-invalid' : '')} />
                                                        <H7 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{name}</H7>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="invalid-feedback">{errors.designationArray}</div>
                            </div>
                            <div className="col-sm-4">

                            </div>
                            <div className="col-sm-8" >
                                <div className="invalid-feedback" >{errors.designationArray}</div>
                            </div>
                        </div>



                        {/* <button type="button" onClick={() => {
                            checkBoxError(designationIds) ? handleSubmit() : showModal("Please select at least one designation")
                        }} className="button" > */}
                        <button type="button" onClick={handleSubmit} className="button" >
                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <h3>Create</h3>}
                        </button>

                    </form>
                }
            </section>
        </div >

    );
}
export default DepartmentMaintainanceCreate;