import React, { useState, useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import H4 from "../../text-components/H4";
import P from "../../text-components/P";
import { backOfficeCommunication } from "../../communications/back-office-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import IndividualMessageForm from "./IndividualMessageForm";
import BulkMessageForm from "./BulkMessageForm";
import ReactPaginate from "react-paginate";

const BackOfficeGrievance = () => {

    const [allTicketsList, setAllTicketsList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [showIndividualMsgForm, setShowIndividualMsgForm] = useState(false);
    const [showBulkMsgForm, setBulkMsgForm] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;
    // spinner on loading
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const history = useHistory();

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }
    // function for loader
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);if (searchValue) {
            fetchGrievanceListBySearch(searchValue, selectedPage + 1);
        } else {
            get(selectedPage + 1);
        }
    } 

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchGrievanceListBySearch(searchString, currentPage + 1);
        } else{
            const response = await backOfficeCommunication.getAllTickets()
            if (response?.status === "SUCCESS"){
                setAllTicketsList(response?.tickets)
                setFilteredData(response?.tickets)
                setPageCount(response?.totalPages)
            } else if (response?.status === "ERROR" || response?.jwt === null){
                history.push('/login/back-office')
            }else{
                setAllTicketsList([]);
                setFilteredData([]);
                setPageCount(0);
            }
        }
    }
//get grievance List By Search
    const fetchGrievanceListBySearch = async (searchString,page) => {
        try {
            // console.log(searchString, page);
           const response = await backOfficeCommunication.getGrievanceListBySearch(searchString, page);
           if (response?.status?.data === "SUCCESS") {
        //  console.log(response?.status?.data);
            setAllTicketsList(response?.data?.tickets);
            setFilteredData(response?.data?.tickets);
            setPageCount(response?.data?.totalPages)
           }else if  (response?.status === "ERROR" || response?.jwt === null){
            history.push('/login/back-office')
           }else{
            setAllTicketsList([]);
            setFilteredData([]);
            setPageCount(0)
           }
        } catch (error) {
            
        }
    } 

      //get grievance List on Initial Load
    async function get(page) {
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("grievance")) {
            dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "GRIEVANCE" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
        }
        showLoading("Loading...");
        try {
            const response = await backOfficeCommunication.getAllTickets(page);
            // console.log("inside code",response);
            if (response?.status === "SUCCESS") {
                setAllTicketsList(response?.tickets);
                setFilteredData(response?.tickets);
                setPageCount(response?.totalPages)
            } else if (response?.status === "ERROR" || response?.jwt === null) {
            }
            else {
                setAllTicketsList([]);
                setPageCount(0);
                setFilteredData([]);
                showModal(response?.message);
            }
            hideLoading();
        } catch (e) {
            showModal(e?.message);
            hideLoading();
        }
        hideLoading();
    }
    useEffect(() => {
        get();
    }, []);

    function showGrievancePreview(ticketId) {
        history.push(`/back-office/dashboard/grievance-preview?ticketId=${ticketId}`);
    }

    // function onChangeFilterText(event, searchFor) {

    //     if (searchFor === "USER_NAME") {
    //         const filterData = allTicketsList.filter((ele) => ele.userName.toLowerCase().search(event.target.value) === -1 ? false : true);
    //         setFilteredData(filterData);
    //     }

    //     if (searchFor === "GRIEVANCE_ID") {
    //         const filterData = allTicketsList.filter((ele) => ele.ticketId.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
    //         setFilteredData(filterData);
    //     }

    //     if (searchFor === "STATUS") {
    //         const filterData = allTicketsList.filter((ele) => ele.userStatus.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
    //         setFilteredData(filterData);
    //     }
    // }

    return (
        <section className="dashboard_background">
            {isLoading?.value ?
                <LoadingForms message={isLoading?.message} />
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="dashboard_back_office">
                                <div className="row search_wrapper">
                                    <div className="col-lg-4 col-md-3 ps-0">
                                        <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By User Name, Grievance ID, Status..." />
                                    </div>
                                    <div className="col-lg-5 col-md-5 pe-0 pagination_wrapper">
                                    {pageCount > 1 &&
                                        <ReactPaginate
                                            previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            forcePage={currentPage}
                                            activeClassName={"active"} />
                                        }
                                    </div>
                                    <div className="col-lg-3 col-md-4 grievance_send_msg_button">
                                        <button onClick={() => setShowIndividualMsgForm(true)}>Individual</button>
                                        <button className="ms-3" onClick={() => setBulkMsgForm(true)}>Bulk</button>
                                    </div>
                                </div>
                                {/*-----Remittance search box----*/}
                                {/* <div className="back_office_search_box">
                                    <div className="row">
                                        <div className="col-2 search_box">
                                        </div>
                                        <div className="col-4 search_box">
                                            <input type="search" className="form-control" placeholder="User Name" onChange={(event) => onChangeFilterText(event, "USER_NAME")} />
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-2 search_box">
                                            <input type="search" className="form-control" placeholder="Grievance Id" onChange={(event) => onChangeFilterText(event, "GRIEVANCE_ID")} />
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-4 grievance_send_msg_button">
                                            <button onClick={() => setShowIndividualMsgForm(true)}>Individual</button>
                                            <button className="ms-3" onClick={() => setBulkMsgForm(true)}>Bulk</button>
                                        </div>
                                    </div>
                                </div> */}
                                {/*----- card header section---*/}
                                <div className="dashboard_view_data_heading">
                                    <div className="row m-0">
                                        <div className="col-2 p-0 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Serial No.</H4></span>
                                        </div>
                                        <div className="col-4 ps-3 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">User Name</H4></span>
                                        </div>
                                        <div className="col-3 p-0 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Grievance ID</H4></span>
                                        </div>
                                        <div className="col-3 p-0 ">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Status</H4></span>
                                        </div>
                                        {/* <div className="col-4 p-0" >
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Description</H4></span>
                                        </div> */}
                                    </div>
                                </div>
                                {
                                    filteredData?.length > 0 ?
                                        <>
                                            {
                                                filteredData?.map((ticketsList, index) => {
                                                    const { _id, ticketId, description, backOfficeStatus, userName } = ticketsList
                                                    return (
                                                        <div className="dashboard_view_data_main" key={_id}>
                                                            <div className="dashboard_view_data" style={{ position: "relative" }} >
                                                                <div className="row m-0">
                                                                    <div className="col-2 p-0 view_dashboard text_align">
                                                                        <span>
                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{limit * currentPage + (index + 1)}</P>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-4 ps-3 view_dashboard text_align">
                                                                        <span>
                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a onClick={() => showGrievancePreview(ticketId)}> {userName} </a></P>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-3 p-0 view_dashboard text_align">
                                                                        <span>
                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a onClick={() => showGrievancePreview(ticketId)}>{ticketId}</a></P>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-3 p-0 text_align">
                                                                        <span ><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{backOfficeStatus}</P></span>
                                                                    </div>


                                                                    {/* <div className="col-4 p-0 ps-3 align text_align">
                                                                        <span>
                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{description}</P>
                                                                        </span>
                                                                    </div> */}
                                                                </div>
                                                                {
                                                                    backOfficeStatus === "NEW" ?
                                                                        <div className="new_badge">
                                                                            <p>New</p>
                                                                        </div>
                                                                        : <span></span>
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </>
                                        :
                                        <div className="col-12 text-center view_download_dashboard">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Records not available.</H4>
                                        </div>
                                }

                            </div>
                        </div>

                    </div>
                </div>
            }

            {/*-------Individual message form----------*/}
            {showIndividualMsgForm &&
                <IndividualMessageForm setShowIndividualMsgForm={setShowIndividualMsgForm} get={get} />
            }

            {/*----------Bulk message form----------*/}
            {showBulkMsgForm &&
                <BulkMessageForm setBulkMsgForm={setBulkMsgForm} get={get} />
            }
        </section >
    );

}
export default memo(BackOfficeGrievance);