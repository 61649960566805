import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import H4 from '../text-components/H4';
import Label from "../text-components/Label";
import FormFieldTitle from "./FormFieldTitle";
import { useLocation } from "react-router-dom";
import { applicationCommunication } from "../communications/application-communication";
import { communication } from "../communications/communication";
import { useFormik } from 'formik';
//****  District and State list Array object for Maping   *****//
import StateAndDistrictList from "./ArrayObjectFolder/StateAndDistrictList";
//****  District list Array object for Maping   *****//
import districtList from "./ArrayObjectFolder/DistrictListForSelect";
import { useHistory } from "react-router";
//----DatePicker (NPM Package)----//
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingForms from "../shared-components/LoadingForms";


const CollegeAdmissionDetailForm = () => {

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //****  Stopping field input- Enable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    // spinner on loading 
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    // cancel, save and submit buttons unable & desable
    const [disableActions, setDisableActions] = useState(false);

    const [applicationId, setApplicationId] = useState(null);

    const [initialFormValues, setInitialFormValues] = useState(null);

    const [saveType, setSaveType] = useState(null);


    const [startdate, setStartDate] = useState(new Date());

    const history = useHistory();

    const location = useLocation();

    const [districtList, setDistrictList] = useState([]);

    const [universityList, setUniversityList] = useState([]);

    const [showExtensionValidation, setShowExtensionValidation] = useState("");
    //on state selection in city field displaying selected state cities 
    function selectState(value) {
        for (let i = 0; i < StateAndDistrictList.length; i++) {
            if (StateAndDistrictList[i].state === value) {
                setDistrictList(value => value = StateAndDistrictList[i].city);
            }
        }
    }

    // To get current location url
    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');

    async function initialLoad() {

        showLoading("Loading Form...");

        // to show active tab
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        let collegeAdmissionDetailUrl = "college-admission-details?applicationId";
        let splitcollegeAdmissionDetailUrl = collegeAdmissionDetailUrl.split('?');
        if (splitcollegeAdmissionDetailUrl.includes("college-admission-details")) {
            dispatch({ type: "SHOW_ACTIVE_APPLICATION_FORM_TAB", payload: "COLLEGE_ADMISSION_DETAILS" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_APPLICATION_FORM_TAB" });
        }

        const queryParams = new URLSearchParams(location.search);
        const applicationIdFromParams = await queryParams.get('applicationId');
        setApplicationId(applicationIdFromParams);

        // Fetching form from the server
        const getCollegeAdmissionDetailsFormResponse = await applicationCommunication.getCollegeAdmissionDetailsForm(applicationIdFromParams);
        // Handling response from the server
        if (getCollegeAdmissionDetailsFormResponse.status === "COLLEGE_ADMISSION_DETAILS_FORM_FETCHED_SUCCESSFULLY") {
            let collegeAdmissionDetailsForm = getCollegeAdmissionDetailsFormResponse.collegeAdmissionDetailsForm;
            // Fetching university array from server
            const qsWorldRankUniversityList = await communication.getUniversityRankList();
            let qsWorldRankUniversityArrray = qsWorldRankUniversityList.qsRankUniverstites.universityList;
            setUniversityList(value => {
                return [...value, ...qsWorldRankUniversityArrray]
            });

            // // setting new form values received from the server
            setInitialFormValues(value => {
                return {
                    ...value, ...{
                        collegeName: collegeAdmissionDetailsForm.collegeName,
                        universityName: collegeAdmissionDetailsForm.universityName.university,
                        universityRank:collegeAdmissionDetailsForm.universityName.rank,
                        course: collegeAdmissionDetailsForm.courseDetails.course,
                        stream: collegeAdmissionDetailsForm.courseDetails.stream,
                        specialization: collegeAdmissionDetailsForm.courseDetails.specialization,
                        courseType: collegeAdmissionDetailsForm.courseType,
                        minimumDurationOfTheCourse: collegeAdmissionDetailsForm.minimumDurationOfTheCourse,
                        admissionDate: collegeAdmissionDetailsForm.admissionDate.year === 0 ? "" : new Date(collegeAdmissionDetailsForm.admissionDate.year, collegeAdmissionDetailsForm.admissionDate.month, collegeAdmissionDetailsForm.admissionDate.day),
                        houseNumber: collegeAdmissionDetailsForm.collegeAddress.houseNumber,
                        street: collegeAdmissionDetailsForm.collegeAddress.street,
                        landmark: collegeAdmissionDetailsForm.collegeAddress.landmark,
                        area: collegeAdmissionDetailsForm.collegeAddress.area,
                        country: collegeAdmissionDetailsForm.collegeAddress.country,
                        city: collegeAdmissionDetailsForm.collegeAddress.city,
                        pincode: collegeAdmissionDetailsForm.collegeAddress.pincode,
                        extension: collegeAdmissionDetailsForm.collegeOfficialContactNumber.primaryContactNumber.extension,
                        number: collegeAdmissionDetailsForm.collegeOfficialContactNumber.primaryContactNumber.number,
                        altExtension: collegeAdmissionDetailsForm.collegeOfficialContactNumber.alternateContactNumber.extension,
                        altNumber: collegeAdmissionDetailsForm.collegeOfficialContactNumber.alternateContactNumber.number,
                        primaryEmail: collegeAdmissionDetailsForm.collegeEmail.primaryEmail,
                        alternateEmail: collegeAdmissionDetailsForm.collegeEmail.alternateEmail,
                        accountHolderName: collegeAdmissionDetailsForm.collegeBankDetails.accountHolderName,
                        bankName: collegeAdmissionDetailsForm.collegeBankDetails.bankName,
                        branchName: collegeAdmissionDetailsForm.collegeBankDetails.branchName,
                        accountNumber: collegeAdmissionDetailsForm.collegeBankDetails.accountNumber,
                        ifscCode: collegeAdmissionDetailsForm.collegeBankDetails.ifscCode,
                        micrCode: collegeAdmissionDetailsForm.collegeBankDetails.micrCode,

                    }
                };
            });

            setDisabled(value => value = collegeAdmissionDetailsForm.isSubmitted);
            setIsSubmitted(value => value = collegeAdmissionDetailsForm.isSubmitted);
            hideLoading();
        } else {

            showModal(getCollegeAdmissionDetailsFormResponse.message);
        }

        //Checking application status is incomplete & making fields enabled
        const applicationsFromServer = await communication.getMyApplications(applicationIdFromParams);
        if (applicationsFromServer.status === "SUCCESS") {
            const applicationStatus = applicationsFromServer.userApplications[0].applicationStatus

            if (applicationStatus === "INCOMPLETE") {
                setDisabled(false);
            } else if (applicationStatus === "SELECTED" || applicationStatus === "COMPLETED" || applicationStatus === "APPLICATION_SUBMITTED") {
                setDisabled(true);
                setIsSubmitted(true);
            }

        }

        hideLoading();
    }

    //** Fetching parent detail form ***//
    useEffect(() => {
        initialLoad();
    }, []);
    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    function loadNextForm() {
        history.push(`/user/application/education-expenses-detail?applicationId=${applicationId}`);
    }

   function getUniversityRank(e){
    let rank;
    universityList.forEach((universityObj) => {
        if (e.target.value == universityObj.university) {
            rank = universityObj.rank;
        }
    })
    values.universityRank=rank;
    }

    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        collegeName: "",
        universityName: "",
        universityRank:"",
        course: "",
        stream: "",
        specialization: "",
        courseType: "",
        minimumDurationOfTheCourse: "",
        admissionDate: "",
        houseNumber: "",
        street: "",
        landmark: "",
        area: "",
        country: "",
        city: "",
        pincode: "",
        extension: "91",
        number: "",
        altExtension: "91",
        altNumber: "",
        primaryEmail: "",
        alternateEmail: "",
        accountHolderName: "",
        bankName: "",
        branchName: "",
        accountNumber: "",
        ifscCode: "",
        micrCode: "",

    }

    //***** Yup libary Form Validation  ****//
    let validationSchema = Yup.object().shape({
        //******Validation for College details form */
        collegeName: Yup.string().required("College Name is required")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        universityName: Yup.string().required("universityName is required"),
        course: Yup.string().required("Course is required").test('Tests', 'Select course', value => value && value.trim().length > 0 && value.trim() !== 'select'),
        stream: Yup.string().required("Stream is required").test('Tests', 'Select stream', value => value && value.trim().length > 0 && value.trim() !== 'select'),
        specialization: Yup.string().required("Specialization is required"),
        courseType: Yup.string().required("Specialization is required").test('Tests', 'Select district', value => value && value.trim().length > 0 && value.trim() !== 'select'),
        minimumDurationOfTheCourse: Yup.number()
            .positive("Course duration can't start with a minus")
            .integer("Course duration can't include a decimal point")
            .min(1, "Minimum 1").max(4, "Maxmimum 4")
            .required('Course duration is required'),
        admissionDate: Yup.string().required("Admission date is required"),
        //****Validation for Address details ****//
        houseNumber: Yup.string().required("House no. is required"),
        street: Yup.string().required("Required"),
        landmark: Yup.string().required("Landmark is required"),
        area: Yup.string().required("Area is required"),
        country: Yup.string().required("Country is required"),
        city: Yup.string().required("City is required"),
        pincode: Yup.string().required("Pin code is required")
            // .matches(/^[0-9]+$/, "Must be only digits")
            .min(5, 'Minimum 5 digits')
            .max(15, 'Maximum 15 digits'),
        //****Validation for Contact details ****//
        extension: Yup.string().required("Extension is required")
            .matches(/^[0-9()+-]+$/, "Must be only digits & special characters + , - ()")
            .min(2, 'Minimum 2 digits')
            .max(12, 'Maximum 12 digits'),
        number: Yup.string()
            .required("Phone Number is required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(4, 'Minimum 4 digits')
            .max(15, 'Maximum 15 digits'),

        altExtension: Yup.string()
            .matches(/^[0-9()+-]+$/, "Must be only digits & special characters + , - ()")
            .min(2, 'Minimum 2 digits')
            .max(12, 'Maximum 12 digits'),
        altNumber: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(4, 'Minimum 4 digits')
            .max(15, 'Maximum 15 digits'),

        //****Validation for Email ****//
        primaryEmail: Yup.string().email("must be a valid email").required("Email is required"),
        alternateEmail: Yup.string().email("must be a valid email"),

        //****Validation for Bank Details ****//
        accountHolderName: Yup.string().required("Account holder name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        bankName: Yup.string().required("Bank Name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        branchName: Yup.string().required("Branch name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        accountNumber: Yup.string().required("Account Number is required"),
        ifscCode: Yup.string().required("IFSC code is required"),
        micrCode: Yup.string().required("MICR Code is required"),


    });

    const { handleSubmit, handleChange, setFieldValue, values, errors, handleReset } = useFormik({
        initialValues: initialFormValues === null ? defaultFormValues : initialFormValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {

            try {
                // Loading on save and submit button
                showLoading(saveType === "SUBMIT" ? "Submitting Form..." : "Saving Form...");
                // Disable Save, Cancel and Submit Buttons
                setDisableActions(true);

                let universityData;
                universityList.map((universityObj) => {
                    if (values.universityName == universityObj.university) {
                        universityData = universityObj;
                    }
                })

                
               

                //set College admission details object to send
                let collegeAdmissionDetailsForm = {

                    collegeName: values.collegeName,
                    universityName: universityData,
                    courseDetails: {
                        course: values.course,
                        stream: values.stream,
                        specialization: values.specialization,
                    },
                    courseType: values.courseType,
                    minimumDurationOfTheCourse: Number(values.minimumDurationOfTheCourse),
                    admissionDate: {
                        day: values.admissionDate.getDate(),
                        month: values.admissionDate.getMonth() + 1,
                        year: values.admissionDate.getFullYear(),
                    },
                    collegeAddress: {
                        houseNumber: values.houseNumber,
                        street: values.street,
                        landmark: values.landmark,
                        area: values.area,
                        country: values.country,
                        city: values.city,
                        pincode: values.pincode,
                    },
                    collegeOfficialContactNumber: {
                        primaryContactNumber: {
                            extension: String(values.extension),
                            number: values.number,
                        },
                        alternateContactNumber: {
                            extension: String(values.altExtension),
                            number: values.altNumber,
                        }
                    },
                    collegeEmail: {
                        primaryEmail: values.primaryEmail,
                        alternateEmail: values.alternateEmail,
                    },
                    collegeBankDetails: {
                        accountHolderName: values.accountHolderName,
                        bankName: values.bankName,
                        branchName: values.branchName,
                        accountNumber: values.accountNumber,
                        ifscCode: values.ifscCode,
                        micrCode: values.micrCode
                    },

                }



                //Sending data to server
                let sendCollegeAdmissionDetailsFormResponse = await applicationCommunication.uploadCollegeAdmissionDetailsForm(collegeAdmissionDetailsForm, applicationId, "SAVE");
                // Handling response from the server
                if (sendCollegeAdmissionDetailsFormResponse.status === "FORM_SAVED_SUCCESSFULLY") {
                    if (saveType === "SAVE") {
                        showModal(sendCollegeAdmissionDetailsFormResponse.message || sendCollegeAdmissionDetailsFormResponse.data.message);
                        hideLoading();
                    } else {

                        history.push(`/user/application/education-expenses-detail?applicationId=${applicationId}`);
                        showModal(sendCollegeAdmissionDetailsFormResponse.message || sendCollegeAdmissionDetailsFormResponse.data.message);
                    }
                } else if (sendCollegeAdmissionDetailsFormResponse.status === "VALIDATION_FAILED" || sendCollegeAdmissionDetailsFormResponse.data.status === "VALIDATION_FAILED") {
                    showModal(sendCollegeAdmissionDetailsFormResponse.validationErrorsList[0].message || sendCollegeAdmissionDetailsFormResponse.data.validationErrorsList[0].message);
                    hideLoading();
                    setDisableActions(false);
                    return false;
                } else {
                    showModal(sendCollegeAdmissionDetailsFormResponse.message || sendCollegeAdmissionDetailsFormResponse.data.message);
                    hideLoading();
                }
                hideLoading();
                setDisableActions(false);
                return false;

            } catch (e) {
                showModal(e);
                return false;
            }

        },
        validationSchema: validationSchema,
    });

    return (
        <section className="application_flow_big">
            <div className="college_admission_detail_form">
                <div className="container">
                    {isLoading.value ?
                        <LoadingForms message={isLoading.message} />
                        :
                        <div className="row">
                            <div className="col-12">
                                <form>
                                    {/* College name Field */}
                                    <div className="row">
                                        <div className="col-lg-2 col-md-2 col-12">
                                            <FormFieldTitle name="College Name *" />
                                        </div>
                                        <div className="col-lg-6 col-md-8 col-12">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">College Name</Label>
                                            <input type="text" placeholder="E.g. Massachusetts Institute of Technology" name="collegeName" onChange={handleChange} value={values.collegeName} className={`form-control ${errors.collegeName ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors.collegeName}</div>
                                        </div>
                                    </div>

                                    {/* University Rank Field */}
                                    <div className="row mt-3">
                                        <div className="col-lg-2 col-md-2 col-12">
                                            <FormFieldTitle name="University Name *" />
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-12">
                                     <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">University Name</Label>
                                            <select name="universityName" onChange={(e)=>{handleChange(e);getUniversityRank(e) }} value={values.universityName} className={`form-select ${errors.universityName ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                <option value="">Select</option>
                                                {universityList.map((universityName, index) => {
                                                    const { university } = universityName;
                                                    return (
                                                        <option key={index} value={university}>{university} </option>
                                                    );
                                                })}
                                            </select>
                                            <div className="invalid-feedback">{errors.universityName}</div>
                                        </div>
                                        
                                        <div className=" col-lg-3 col-md-3 col-12">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Ranking</Label>
                                            <input type="text" placeholder="University Ranking" name="universityranking"  value={values.universityRank} className="form-control" disabled={disabled} />
                                        </div>
                                    </div>

                                    {/* Course Details Field */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Course Name *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row p-0">
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">PG/PhD</Label>
                                                    <select name="course" onChange={handleChange} value={values.course} className={`form-select ${errors.course ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                        <option value="">Select</option>
                                                        <option value="PG">PG</option>
                                                        <option value="Phd">PhD</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.course}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Stream</Label>
                                                    <select name="stream" onChange={handleChange} value={values.stream} className={`form-select ${errors.stream ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                        <option value="">Select</option>
                                                        <option value="ART">Art</option>
                                                        <option value="COMMERCE">Commerce</option>
                                                        <option value="SCIENCE">Science</option>
                                                        <option value="ENGINEERING">Engineering</option>
                                                        <option value="MANAGEMENT">Management</option>
                                                        <option value="MEDICAL">Medical</option>
                                                        <option value="LAW">Law</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.stream}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Specialization</Label>
                                                    <input type="text" placeholder="E.g. LLB" name="specialization" onChange={handleChange} value={values.specialization} className={`form-control ${errors.specialization ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.specialization}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* Course Type Field */}
                                    <div className="row mt-3">
                                        <div className="col-lg-2 col-md-2 col-12">
                                            <FormFieldTitle name="Course Type (Full Time/ Part Time/Online/ Executive) *" />
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-12">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Course Type</Label>
                                            <select name="courseType" onChange={handleChange} value={values.courseType} className={`form-select ${errors.courseType ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                <option value="">Select</option>
                                                <option value="Full Time"> Full Time</option>
                                                <option value="Part Time"> Part Time</option>
                                            </select>
                                            <div className="invalid-feedback">{errors.courseType}</div>
                                        </div>
                                    </div>

                                    {/* Duration of Cource Field */}
                                    <div className="row mt-3">
                                        <div className="col-lg-2 col-md-2 col-12">
                                            <FormFieldTitle name="Minimum Duration of the Course *" />
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-12">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Course duration in (year)</Label>
                                            <input type="number" placeholder="E.g. 3" name="minimumDurationOfTheCourse" onChange={handleChange} value={values.minimumDurationOfTheCourse} className={`form-control ${errors.minimumDurationOfTheCourse ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors.minimumDurationOfTheCourse}</div>
                                        </div>
                                    </div>

                                    {/* Admission date Field */}
                                    <div className="row mt-3">
                                        <div className="col-lg-2 col-md-2 col-12">
                                            <FormFieldTitle name="Admission Date *" />
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-12">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">DD/MM/YYYY</Label>
                                            <DatePicker selected={(values.admissionDate && new Date(values.admissionDate)) || null} onChange={(date) => setFieldValue("admissionDate", date)} name="admissionDate" dateFormat="dd/MM/yyyy" value={values.admissionDate} className={`form-control ${errors.admissionDate ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors.admissionDate}</div>
                                        </div>
                                    </div>

                                    {/* College Address (for Postal) Details Field */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="College Address (for Postal) *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Building Number</Label>
                                                    <input placeholder="E.g. 778" type="text" name="houseNumber" value={values.houseNumber} onChange={handleChange} className={`form-control ${errors.houseNumber ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                    <div className="invalid-feedback">{errors.houseNumber}</div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Street</Label>
                                                    <input type="text" placeholder="E.g.Lombard Street" name="street" value={values.street} onChange={handleChange} className={`form-control ${errors.street ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                    <div className="invalid-feedback">{errors.street}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 col-md-2">
                                        </div>

                                        <div className="col-lg-10 col-md-10 mt-3">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Land Mark</Label>
                                                    <input type="text" placeholder="E.g. beside Parilement" name="landmark" onChange={handleChange} value={values.landmark} className={`form-control ${errors.landmark ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                    <div className="invalid-feedback">{errors.landmark}</div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Area</Label>
                                                    <input type="text" placeholder="E.g. Times Square" name="area" onChange={handleChange} value={values.area} className={`form-control ${errors.area ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                    <div className="invalid-feedback">{errors.area}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 col-md-2">
                                        </div>

                                        <div className="col-lg-10 col-md-10 mt-3">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Country</Label>
                                                    <input type="text" placeholder="E.g.Japan" name="country" onChange={handleChange} value={values.country} className={`form-control ${errors.country ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.country}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">City</Label>
                                                    <input type="text" name="city" placeholder="E.g. Tokiyo" onChange={handleChange} value={values.city} className={`form-control ${errors.city ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.city}</div>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Pin Code / Zip code</Label>
                                                    <input type="text" placeholder="E.g. 7865432" name="pincode" onChange={handleChange} value={values.pincode} className={`form-control ${errors.pincode ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                    <div className="invalid-feedback">{errors.pincode}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {/* College Official Contact Numberr Details Field */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="College Official Contact Number *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">

                                                <div className="col-lg-6 col-md-6">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-4">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="extension">Code</Label>
                                                            <div className="code_extension">
                                                                {/* <span>+</span> */}
                                                                <div>
                                                                    <input type="text" placeholder="E.g. +81" name="extension" value={values.extension} onChange={handleChange} className={`form-control ${errors.extension ? "is-invalid" : ""}`} disabled={disabled} />
                                                                    <div className="invalid-feedback">{errors.extension}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8 col-md-8 col-8">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Mobile Number</Label>
                                                            <input type="number" placeholder="E.g. 5698741235" name="number" value={values.number} onChange={handleChange} className={`form-control ${errors.number ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.number}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 mt-lg-0 mt-md-0 mt-sm-2">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-4">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="extension">Code</Label>
                                                            <div className="code_extension">
                                                                {/* <span>+</span> */}
                                                                <div>
                                                                    <input type="text" placeholder="E.g. +81" name="altExtension" value={values.altExtension} onChange={handleChange} className={`form-control ${errors.altExtension ? "is-invalid" : ""}`} disabled={disabled} />
                                                                    <div className="invalid-feedback">{errors.altExtension}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8 col-md-8 col-8">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Alternate Mobile/Telephone number</Label>
                                                            <input type="number" placeholder="E.g. 6987458213" name="altNumber" value={values.altNumber} onChange={handleChange} className={`form-control ${errors.altNumber ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.altNumber}</div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                    {/* College Official Email Address Details Field */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="College Official Email Address *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Primary Email</Label>
                                                    <input type="text" placeholder="E.g. xyz@gmail.com" name="primaryEmail" value={values.primaryEmail} onChange={handleChange} className={`form-control ${errors.primaryEmail ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                    <div className="invalid-feedback">{errors.primaryEmail}</div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Alternate Email</Label>
                                                    <input type="text" placeholder="abc@example.com" name="alternateEmail" value={values.alternateEmail} onChange={handleChange} className={`form-control ${errors.alternateEmail ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                    <div className="invalid-feedback">{errors.alternateEmail}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {/* Bank Details */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="College Account Details as per unconditional / conditional offer letter *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">A/c Holder Name</Label>
                                                    <input type="text" name="accountHolderName" placeholder="E.g. Ram Prasad Srivastava" onChange={handleChange} value={values.accountHolderName} className={`form-control ${errors.accountHolderName ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.accountHolderName}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Bank Name</Label>
                                                    <input type="text" name="bankName" placeholder="E.g. Bank of India" onChange={handleChange} value={values.bankName} className={`form-control ${errors.bankName ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.bankName}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Branch Name</Label>
                                                    <input type="text" name="branchName" placeholder="E.g.Bhiwapur" onChange={handleChange} value={values.branchName} className={`form-control ${errors.branchName ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.branchName}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 col-md-2">

                                        </div>

                                        <div className="col-lg-10 col-md-10 mt-3">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Account Number</Label>
                                                    <input type="text" name="accountNumber" placeholder="E.g. 789654123012547" onChange={handleChange} value={values.accountNumber} className={`form-control ${errors.accountNumber ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.accountNumber}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">IFSC Code</Label>
                                                    <input type="text" name="ifscCode" placeholder="E.g.BKID0008712" onChange={handleChange} value={values.ifscCode} className={`form-control ${errors.ifscCode ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.ifscCode}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">MICR Code</Label>
                                                    <input type="text" name="micrCode" placeholder="E.g. 441201789" onChange={handleChange} value={values.micrCode} className={`form-control ${errors.micrCode ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.micrCode}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>



                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <div className="form_button">
                                                {splitUrlArray[4] === "user" ?
                                                    <button type="button" onClick={() => { history.push("/user"); dispatch({ type: "SHOW_APPLICATION_PROCESS_NAV" }); }} className="btn form_cancel_btn" disabled={disableActions}>Cancel</button>
                                                    : <span></span>
                                                }
                                                {
                                                    isSubmitted ?
                                                        <div className="m-1"></div>
                                                        :
                                                        <button type="button" onClick={() => {
                                                            setSaveType(value => value = "SAVE");
                                                            handleSubmit();
                                                        }} className="btn form_save-btn" disabled={disableActions}>Save</button>
                                                }
                                                {
                                                    isSubmitted ?
                                                        (splitUrlArray[4] === "user") ?
                                                            <button type="button" onClick={() => {
                                                                loadNextForm();
                                                            }} className="btn form_submit_btn">Next</button>
                                                            : <span></span>
                                                        :
                                                        <button type="button" onClick={() => {
                                                            setSaveType(value => value = "SAVE_AND_NEXT");
                                                            handleSubmit();
                                                        }} className="btn form_submit_btn" disabled={disableActions}>Save & Next</button>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    }
                </div>

            </div>
        </section>
    );
}
export default CollegeAdmissionDetailForm;