import React, { useState, memo } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import P from "../text-components/P";
import H4 from "../text-components/H4";
import { communication } from "../communications/communication";
import LoadingForms from "../shared-components/LoadingForms";
import { removeUnderscoreFromText } from "../utlis/helper";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";

const MyApplication = () => {

    const [userAlerts, setUserAlerts] = useState([]);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;
    const dispatch = useDispatch();
    const history = new useHistory();
    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }
    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchAlertsBySearch(searchValue, selectedPage + 1);
        } else {
            fetchData(selectedPage + 1);
        }
    }

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchAlertsBySearch(searchString, currentPage + 1);
        } else {
            const response = await communication.getAlertsNotification();
            if (response?.status === "SUCCESS") {
                setUserAlerts(response?.alerts);
                setPageCount(response?.totalPages);
            }
            else {
                setUserAlerts([]);
                setPageCount(0);
            }
        }
    }

    //get Remittance By Search
    const fetchAlertsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.getAlertsBySearch(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setUserAlerts(responseFromServer?.data?.alerts);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                showModal(responseFromServer?.data?.message);
                history.push("/login");
            } else {
                setUserAlerts([]);
                setPageCount(0);
            }
        } catch (error) {
            showModal(error?.message);
        }
    }

    //get Alerts on Initial Load
    async function fetchData(page) {
        showLoading("Loading...");
        // to show active tab
        let fullUrl = window.location.href;
        let splitAlertUrl = fullUrl.split('/');
        if (splitAlertUrl.includes("alert")) {
            dispatch({ type: "SHOW_ACTIVE_APPLICATION_PROCESS_TAB", payload: "ALERT" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_APPLICATION_PROCESS_TAB" });
        }

        const response = await communication.getAlertsNotification(page);
        if (response?.status === "SUCCESS") {
            setUserAlerts(response?.alerts);
            setPageCount(response?.totalPages);
        }
        else {
            showModal(response?.message);
            setUserAlerts([]);
            setPageCount(0);
        }
        hideLoading();
    }

    useEffect(() => {
        fetchData();
    }, []);



    return (
        <>
            <section className="application_flow_big" >
                {
                    isLoading.value ?
                        <LoadingForms message={isLoading?.message} />
                        :
                        <div className="AlertNotification_main">
                            <div className="row search_wrapper">
                                <div className="col-lg-4 col-md-4 ps-0">
                                    <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Title, Message..." />
                                </div>
                                <div className="col-lg-8 col-md-8 pe-0 pagination_outer_wrapper">
                                    <div className="pagination_inner_wrapper">
                                        {pageCount > 1 &&
                                            <ReactPaginate
                                                previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                                nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                forcePage={currentPage}
                                                activeClassName={"active"} />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/*-----Grievance card header section---*/}
                            <div className="tabel_heading">
                                <div className="row m-0">
                                    <div className="col-1 p-0 data">
                                        <span><H4>Serial No.</H4></span>
                                    </div>
                                    <div className="col-3 p-0 ps-3 data" style={{ justifyContent: "start" }}>
                                        <span><H4>Title</H4></span>
                                    </div>
                                    <div className="col-6 p-0 ps-3 data" style={{ justifyContent: "start" }}>
                                        <span><H4>Message</H4></span>
                                    </div>

                                    <div className="col-2 p-0 align" style={{ justifyContent: "center" }}>
                                        <span><H4>Date</H4></span>
                                    </div>
                                </div>
                            </div>
                            {
                                userAlerts?.length > 0 ?
                                    <>
                                        {/*-----Grievance card data section---*/}
                                        {
                                            userAlerts?.map((notification, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className="tabel_data">
                                                            <div className="row m-0">
                                                                <div className="col-1 p-0 data">
                                                                    <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{limit * currentPage + (index + 1)}</P></span>
                                                                </div>
                                                                <div className="col-3 p-0 ps-3 data" style={{ justifyContent: "start", textAlign: "left" }}>
                                                                    <span className="firstLetterCap"><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{notification?.title}</P></span>
                                                                </div>

                                                                <div className="col-6 p-0 ps-3 data" style={{ justifyContent: "start", textAlign: "left", }}>
                                                                    <span className="firstLetterCap"><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{removeUnderscoreFromText(notification?.message)}</P></span>

                                                                </div>
                                                                <div className="col-2 p-0 align" style={{ justifyContent: "center" }}>
                                                                    <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{new Date(notification?.createdDate).getDate()}-{new Date(notification?.createdDate).getMonth() + 1}-{new Date(notification?.createdDate).getFullYear()}</P></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                );
                                            })
                                        }

                                    </>
                                    :
                                    <div className="col-12 text-center view_download_dashboard">
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Alert not available.</H4>
                                    </div>
                            }
                        </div>
                }
            </section>
        </>
    );
}
export default memo(MyApplication);