import axios from "axios";
const serverDevUrl = "http://localhost:3700";
const awsServerDevUrl =
    "http://ec2-13-233-236-197.ap-south-1.compute.amazonaws.com:3700";
const serverProdUrl = "";
const ngrokUrl = "http://5f6b-2405-201-1004-e0c7-14a-3ef6-1734-3cae.ngrok.io";
const newNgrokUrl = "http://bda0-47-247-214-149.ngrok.io";
const machine_Ip = "http://192.168.29.169:3700";

export function getServerUrl() {
    //  get node environment variable 
    const nodeEnvirnment = process.env.REACT_APP_NODE_ENV;

    if (nodeEnvirnment === "development") {
        return serverDevUrl;
    }

    if (nodeEnvirnment === "production") {
        return process.env.REACT_APP_BACKEND_SERVER_IP;
    }
    if (nodeEnvirnment === "machine_Ip") {
        return machine_Ip;
    }

    return process.env.REACT_APP_BACKEND_SERVER_IP;
}

function getCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
        if ("sjsadBackOfficeToken" == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}

function getJwtTokenFromCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
        if ("sjsadBackOfficeToken" == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}

export const backOfficeCommunication = {
    loginBackOffice: (backOfficeCredentials) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open(
                "post",
                `${getServerUrl()}/back-office/login-back-office-user`,
                true
            );
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(backOfficeCredentials)); // Converting JS object to JSON to send to server
        });
    },

    getBackOfficeUserAuthenticateByJWT: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open(
                "get",
                `${getServerUrl()}/back-office/get-back-office-user-authenticate-by-jwt`,
                true
            );
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                // Converting response from json to js objects
                const response = JSON.parse(json);
                // Attaching jwt from cookie itself as it is validated by the server.
                response.jwt = getCookie();
                resolve(response);
            };
            xhr.send();
        });
    },

    getCommittees: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/back-office/get-committees`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    getAllGrList: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/back-office/fetch-all-gr?page=${page}`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },


    getPrepareGrList: (grId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open(
                "post",
                `${getServerUrl()}/back-office/fetch-prepare-gr-list`,
                true
            );
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ grId: grId })); // Converting JS object to JSON to send to server
        });
    },
    // ---------------------------------Docunenty Scrutiny-------------------------------------------------
    getUserApplicationWithMilestone: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/fetch-all-users-with-general-milestone-status?page=${page}`, true
            );
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json));

            };
            xhr.send();
        });
    },

    getGeneralMilestoneBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-user-by-general-milestone-status`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                },
            });
        } catch (error) {
            alert(error.message);
        }
    },

    uploadSignGr: (grId, formData) => {

        return axios.post(`${getServerUrl()}/back-office/upload-signed-gr-transaction?grId=${grId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    // ----------------------------------GrList------------------------------------------
    getAllSingedGrList: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/back-office/fetch-all-sign-gr-list?page=${page}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },




    getUploadGrFile: (fileUrl) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/back-office/get-files-upload?uploadedFile=${fileUrl}`, {
                headers: {
                    "Content-Type": "application/pdf",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/pdf"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                // window.open(pdfBlob);
                resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },

    notifyUsers: (grId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/notify-users`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ grId: grId }));
        })
    },

    getUploadUserMilestone: (fileUrl) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/back-office/get-user-files?uploadedFile=${fileUrl}`, {
                headers: {
                    "Content-Type": "application/pdf",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/pdf"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },

    fetchAllMilestone: (applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/fetch-all-milestones-for-application`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId }));
        })
    },

    getMilestone: (milestoneId, applicationId, type) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/get-milestone-document`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ milestoneId: milestoneId, applicationId: applicationId, milestoneType: type }));
        })
    },

    getAllTickets: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/back-office/get-all-tickets?page=${page}`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        })
    },

    getUserTickets: (ticketId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/get-ticket-from-back-office`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ ticketId: ticketId }));

        })
    },

    getAllRefundApplications: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/back-office/get-all-application-for-refund?page=${page}`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        })
    },
    getRefundRemittance: (applicationId, userId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/get-refund-application-list`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId, userId: userId }));
        })
    },
    markRefundAsCompleted: (applicationId, refundId, userId, applicationStatus, comment, shortApplicationId, totalAmount, totalRefundAmount) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/mark-refund-as-completed`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId, refundId: refundId, userId: userId, applicationStatus: applicationStatus, comment: comment, shortApplicationId: shortApplicationId, totalAnnualAmount: totalAmount, totalRefundAmount: totalRefundAmount }));
        })
    },

    getAllRemittances: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/back-office/get-remittances?page=${page}`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        })
    },

    getRemittancesDataById: (remittanceId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/get-remittance-by-id`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ remittanceId: remittanceId }));
        })
    },
    markOneComplete: (applicationId, type, comment) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/mark-milestone-one-as-complete`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId, milestoneType: type, comment: comment }));
        })
    },
    markOneInComplete: (applicationId, type, comment) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/mark-milestone-one-as-incomplete`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId, milestoneType: type, comment: comment }));
        })
    },

    markTwoComplete: (applicationId, type, comment) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/mark-milestone-two-as-complete`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId, milestoneType: type, comment: comment }));
        })
    },
    markTwoInComplete: (applicationId, type, comment) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/mark-milestone-two-as-incomplete`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId, milestoneType: type, comment: comment }));

        })
    },
    uploadHomeVerification: (applicationId, formData) => {

        return axios.post(`${getServerUrl()}/back-office/upload-home-verification-receipt`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
                "applicationid": applicationId,
            }
        });
    },

    uploadExpenditureReport: (milestoneId, applicationId, formData) => {
        return axios.post(`${getServerUrl()}/back-office/upload-expenditure-report-by-back-office?applicationId=${applicationId}&milestoneId=${milestoneId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
            }
        });
    },

    remittanceUpdate: (remittanceCreate) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/update-remittance`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify(remittanceCreate));
        })
    },
    getBackOfficeUsers: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/back-office/get-all-back-users-for-assigning`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        })
    },
    getLatestNotifications: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/back-office/get-latest-notifications-for-back-office?page=${page}`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        })
    },

    assignBackOfficeToMilestone: (milestoneId, userId, comment) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/assign-document-to-user`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ milestoneId, accessUserId: userId, comment: comment }));
        })
    },

    markCompleteThree: (milestoneId, comment) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/mark-milestone-three-as-complete`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ milestoneId: milestoneId, comment: comment }));
        })
    },
    markInCompleteThree: (milestoneId, comment) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/mark-milestone-three-as-incomplete`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ milestoneId: milestoneId, comment: comment }));

        })
    },

    uploadSponsorshipLetter: (applicationId, formData) => {

        return axios.post(`${getServerUrl()}/back-office/upload-sponsorship-letter`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
                "applicationid": applicationId,
            }
        });
    },

    markCompleteFour: (applicationId, type) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/mark-milestone-four-complete`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId, milestoneType: type }));
        })
    },
    markInCompleteFour: (applicationId, type) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/sponsorship-mark-incomplete`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId, milestoneType: type }));

        })
    },
    sendMailToUniversity: (applicationId, userId, message, universityEmail) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/milestone-four-send-email-to-university`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId, userId: userId, message: message, universityEmail: universityEmail }));

        })
    },
    requestToTreasury: (applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/milestone-five-request-to-treasury`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId }));

        })
    },

    uploadReceipt: (applicationId, formData) => {
        return axios.post(`${getServerUrl()}/back-office/upload-receipt-from-treasury`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
                "applicationId": applicationId,
            }
        });
    },
    markFundReceive: (milestoneId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/milestone-five-received-fund-from-treasury`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ milestoneId: milestoneId }));

        })
    },
    uploadBankReceipt: (formData, remittanceId, applicationId) => {
        // old method
        // return axios.post(`${getServerUrl()}/back-office/upload-back-receipt?remittanceId=${remittanceId}&applicationId=${applicationId}`, formData, {
        //     headers: {
        //         "Content-Type": "multipart/form-data",
        //         "Authorization": `Bearer ${getCookie()}`,
        //     }
        // });
        // old method
        return axios.post(`${getServerUrl()}/back-office/upload-bank-receipt-and-remit-signed-report?remittanceId=${remittanceId}&applicationId=${applicationId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
            }
        });
    },

    uploadSignedRemittanceReceipt: (formData, remittanceId, applicationId) => {
        return axios.post(`${getServerUrl()}/back-office/upload-signed-remittance-receipt?remittanceId=${remittanceId}&applicationId=${applicationId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
            }
        });
    },
    // ============================== For general milestone ==============================  
    requestToTreasuryForGeneralMilestone: (milestoneId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/general-milestone-change-status-to-request-to-treasury`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ milestoneId }));

        })
    },

    uploadReceiptForGeneralMilestone: (applicationId, formData, milestoneId) => {

        return axios.post(`${getServerUrl()}/back-office/general-milestone-upload-receipt-from-treasury`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
                "applicationid": applicationId,
                "milestoneid": milestoneId,
            }
        });
    },
    markFundReceiveForGeneralMilestone: (milestoneId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/general-milestone-received-found-from-treasury`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ milestoneId }));

        })
    },
    uploadBankReceiptForGeneralMilestone: (formData, remittanceId, applicationId) => {
        return axios.post(`${getServerUrl()}/back-office/upload-back-receipt?remittanceId=${remittanceId}&applicationId=${applicationId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
            }
        });
    },

    uploadSignedRemittanceReceiptForGeneralMilestone: (formData, remittanceId, applicationId) => {
        return axios.post(`${getServerUrl()}/back-office/upload-signed-remittance-receipt?remittanceId=${remittanceId}&applicationId=${applicationId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
            }
        });
    },
    markMilestoneFiveAsIncomplete: (milestoneId, userId, comment) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/mark-milestone-five-as-incomplete`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ milestoneId, userId, comment }));

        })
    },
    markGeneralMilestoneAsIncomplete: (milestoneId, userId, comment) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/mark-general-milestone-as-incomplete`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ milestoneId, userId, comment }));

        })
    },

    replyUserTickets: (ticketId, message) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/reply-ticket`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ ticketId, message }));

        })
    },
    markInCompleteNewSession: (milestoneId, milestoneName, comment) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/mark-new-session-as-incomplete`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ milestoneId: milestoneId, milestoneName: milestoneName, comment: comment }));

        })
    },
    markCompleteNewSession: (milestoneId, comment) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/mark-new-session-as-complete`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ milestoneId: milestoneId, comment: comment }));
        })
    },
    getRefundById: (refundId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/get-refund-by-Id`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ refundId }));
        })
    },
    getUserHomeVericationMilestone: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/back-office/get-milestone-according-to-location?page=${page}`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json));

            };
            xhr.send();
        });
    },

    getNotificationCounts: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/back-office/get-back-office-new-notification-count`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        })
    },
    changeNotificationStatus: (notificationId, type) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/change-notification-status`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ notificationId: notificationId, type: type }));
        })
    },
    getUsers: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/back-office/get-users-year-wise`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        })
    },
    sendIndividualMessage: (dataToSend) => {
        return axios.post(`${getServerUrl()}/back-office/raise-back-office-user-bulk-grievance-ticket`, dataToSend.formData, {
            headers: {
                "Content-Type": dataToSend?.isFileAttached ? "multipart/form-data,boundary=MyBoundary" : "application/json",
                "Authorization": `Bearer ${getCookie()}`,
                "data": JSON.stringify(dataToSend)
            }
        });
    },
    getUserSemMarks: (page) => {
        return axios.get(`${getServerUrl()}/back-office/get-user-sem-marks?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`,
            }
        });
    },
    getUserSemesterList: (userId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/get-user-sem-marks-by-userId`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ userId: userId }));
        })
    },
    approveUserSemMarks: (semesterId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/approve-user-sem-marks`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ semesterId: semesterId }));
        })
    },
    RejectUserSemMarks: (semesterId, message) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/reject-user-sem-marks`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ semesterId: semesterId, message: message }));
        })
    },
    getUserSemesterMarks: (semesterId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/get-user-sem-marks-by-semesterId`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ semesterId: semesterId }));
        })
    },
    getStudentPerformance: (university, year) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/get-user-sem-marks-for-graph`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ university, year }));
        })
    },
    getUserMilestoneForCommissioner: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get",`${getServerUrl()}/back-office/fetch-user-milestone-for-commissioner?page=${page}`,true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json));

            };
            xhr.send();
        });
    },
    getNotificationBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-latest-notification-for-back-office`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            })
        } catch (error) {
            alert(error?.message);
        }
    },
    getRemittanceListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-back-office-remittance`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            })
        } catch (error) {
            alert(error?.message);
        }
    },


    getGrievanceListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-tickets`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            })
        } catch (error) {
            alert(error?.message);
        }
    },


    getRefundListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-application-for-refund`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            })
        } catch (error) {
            alert(error?.message);
        }
    },


    getUserHomeVericationMilestoneBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-milestone-according-to-location`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            })
        } catch (error) {
            alert(error?.message);
        }
    },

    getGeneralMilestoneBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-all-users-with-general-milestone-status`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            })
        } catch (error) {
            alert(error?.message);
        }
    },

    getUserSemMarksBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/back-office/search-user-sem-marks`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            })
        } catch (error) {
            alert(error?.message);
        }
    },

getCommissionerMilestoneBySearch: (searchString, page) => {
    try {
        return axios.post(`${getServerUrl()}/back-office/search-user-milestone-for-commissioner`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        })
    } catch (error) {
        alert(error?.message);
    }
},


};
