import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import H5 from "../../text-components/H5";
import { confirm } from "react-confirm-box";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";

const DesignationMaintainanceUpdate = () => {
const [isLoading, setIsLoading] = useState({ message: "", value: false });
const [allDesignationsMaintenanceData, setAllDesignationsMaintenanceData] = useState([]);
const [idsOfDesignation, setIdsOfDesignation] = useState([]);
const history = useHistory();
const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
const [pageCount, setPageCount] = useState(0);
const [currentPage, setCurrentPage] = useState(0);
const [searchValue, setSearchValue] = useState("");
const limit = process.env.REACT_APP_PAGE_LIMIT;


    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

//Onclick set current page
const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage);
    if (searchValue) {
        fetchDesignationsBySearch(searchValue, selectedPage + 1);
    } else {
        fetchAllDesignations(selectedPage + 1);
    }
}

 //Search Filter
const searchFilter = async (searchString) => {
    if (searchString) {
        fetchDesignationsBySearch(searchString, currentPage + 1);
    } else {
        const designationsFromServer = await adminCommunication.getAllDesignations();
        if (designationsFromServer.status === "SUCCESS") {
            let designationsArray = designationsFromServer.designations;
            setAllDesignationsMaintenanceData(value => {
                return [...value, ...designationsArray]
            });
            setPageCount(designationsFromServer?.totalPages);
        } else if (designationsFromServer?.status === "JWT_INVALID") {
            history.push("/admin/login");
            showModal(designationsFromServer?.message);
        } else {
            setAllDesignationsMaintenanceData([]);
            setPageCount(0);
        }
    }
}

//Get Designations by Search
const fetchDesignationsBySearch = async (searchString, page) => {
    try {
        const responseFromServer = await adminCommunication.getDesignationsBySearch(searchString, page);
        if (responseFromServer?.data?.status === "SUCCESS") {
            setAllDesignationsMaintenanceData(responseFromServer?.data?.designations);
            setPageCount(responseFromServer?.data?.totalPages);
        } else if (responseFromServer?.data?.status === "JWT_INVALID") {
            history.push("/admin/login");
            showModal(responseFromServer?.data?.message);
        } else {
            setAllDesignationsMaintenanceData([]);
            setPageCount(0);
        }
    } catch (error) {
        showModal(error.message);
    }
}


 //get All Designations on Initial Load
    const fetchAllDesignations = async (page) =>{
        try {
            showLoading("Loading ...");
        dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "DESIGNATION_MAINTAINANCE_UPDATE" });
        const designationsFromServer = await adminCommunication.getAllDesignations(page);
            if (designationsFromServer.status === "SUCCESS") {
                setAllDesignationsMaintenanceData(designationsFromServer.designations);
                setPageCount(designationsFromServer?.totalPages);
            } else if (designationsFromServer?.status === "JWT_INVALID") {
                history.push("/admin/login");
                showModal(designationsFromServer?.message);
            } else {
                showModal(designationsFromServer.message);
                setAllDesignationsMaintenanceData([]);
                setPageCount(0);
            }

            hideLoading();
        } catch (error) {
            hideLoading();  
        }
    }

        useEffect(async () => {
            fetchAllDesignations()
    }, []);

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    // set ids in to state
    const handleOnChange = (e) => {
        let checked = e.target.checked;
        let id = e.target.value;
        if (checked) {
            setIdsOfDesignation(() => [...idsOfDesignation, id]);
        } else {
            // to remove from local storage
            setIdsOfDesignation(
                idsOfDesignation.filter((Id) => Id !== id)
            );
        }
        return;
    }



    // delete committee by id
    const deleteDesignation = async (options) => {
        // check if applicationIds is empty
        if (idsOfDesignation.length !== 0) {
            const confirmBox = await confirm(`Are you sure , do you want to delete this designation . If you deleted this designation,all the related  departments and office users will be deleted.`, options);
            if (confirmBox === true) {
                showLoading("Loading ...");
                const deleteDesignationByIdData = await adminCommunication.deleteDesignationById(idsOfDesignation);
                if (deleteDesignationByIdData.status === "SUCCESS") {
                    // calling refreshPage function after 4 sec to show model before refreshing the page
                    setTimeout(() => {
                        history.push(`/admin/dashboard/designation-maintenance-view`);
                    }, 1000);
                }
                showModal(deleteDesignationByIdData.message);
            }
        }
        else {
            showModal("Please select atleast one record")
        }
        hideLoading();
        return;
    }
    // redirect to committee maintenance save according to Designation id
    const designationEdit = (designationId) => {
        history.push(`/admin/dashboard/designation-maintenance-save?designationId=${designationId}`);
        return;
    }








    return (
        <>
            <section className="dashboard_background_update" >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :

                    <div className="dashbord_main">
                    <div className="row search_wrapper">
                            <div className="col-lg-4 col-md-4 ps-0">
                                <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Designation" />
                            </div>
                            <div className="col-lg-8 col-md-8 pagination_outer_wrapper">
                                <div className="pagination_inner_wrapper">
                                    {pageCount > 1 &&
                                        <ReactPaginate
                                            previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            forcePage={currentPage}
                                            activeClassName={"active"} />
                                    }
                                </div>
                            </div>
                        </div>
                        {/*-----Grievance card header section---*/}
                        <div className="dashboard_view_data_heading">
                            <div className="row m-0">
                                <div className="col-lg-2 col-md-2 col-1 p-0 view_dashboard">
                                    <span><H5>Serial No.</H5></span>
                                </div>
                                <div className="col-7 p-0 view_dashboard">
                                    <span><H5>Designation Name</H5></span>
                                </div>
                                <div className="col-lg-3 col-md-3 col-4 p-0 ">
                                    <span><H5>Creation Date</H5></span>
                                </div>
                            </div>
                        </div>

                        {allDesignationsMaintenanceData.length > 0 ?
                            <div>
                                {/*-----Grievance card data section---*/}
                                {allDesignationsMaintenanceData.map((designation, index) => {
                                    const { _id, name } = designation;
                                    return (
                                        <div key={index}>
                                            <div className="dashboard_view_data_main" key={_id}>
                                                <div className="dashboard_view_data" >
                                                    <div className="row m-0">
                                                        <div className="col-lg-2 col-md-2 col-1 p-0 view_dashboard sr-no text_align">
                                                            <span>
                                                                {/* <input type="checkbox" value={_id} onClick={(e) => handleOnChange(e)} /> */}
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                {limit * currentPage + (index + 1)}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-7 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    <a onClick={() => designationEdit(_id)}>
                                                                        {name}
                                                                    </a>
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-4 p-0 text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {new Date(designation.creationDate).getDate()}-{new Date(designation.creationDate).getMonth() + 1}-{new Date(designation.creationDate).getFullYear()}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    );
                                })}
                            </div>
                            :
                            <div className="col-12 text-center view_download_dashboard">
                                <h6>Records not available.</h6>
                            </div>
                        }

                    </div>
                }
            </section>
            {/* <section className="mb-3">
                <div className="row">
                    <div className="col-2 button_div">
                        <div className="dashboard_delete_button">
                            <button type="button" className="btn" onClick={() => deleteDesignation()}>
                                {isLoading.value ? <img src="/assets/images/gif/loading-circle.gif" style={{ width: "10px", height: "10px" }} /> : <H5>Delete</H5>}
                            </button>
                        </div>
                    </div>
                </div>
            </section> */}

        </>

    )
}

export default DesignationMaintainanceUpdate;