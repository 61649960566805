import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import H4 from "../text-components/H4";
import P from "../text-components/P";
import { communication } from "../communications/communication";
import { useHistory } from "react-router";
import LoadingForms from "../shared-components/LoadingForms";
import { removeUnderscoreFromText } from "../utlis/helper";

const MyApplication = () => {

    // spinner on loading 
    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    const [applicationData, setApplicationData] = useState([]);

    const history = new useHistory();


    const formStatus = async (applicationId) => {
        history.push(`/user/application/?applicationId=${applicationId}`);
    }


    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    async function loadApplication() {
        showLoading("Loading applications...");

        // to show active tab
        let fullUrl = window.location.href;
        let splitMyApplicationUrl = fullUrl.split('/');
        if (splitMyApplicationUrl.includes("my-application")) {
            dispatch({ type: "SHOW_ACTIVE_APPLICATION_PROCESS_TAB", payload: "MY_APPLICATION" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_APPLICATION_PROCESS_TAB" });
        }

        if (splitMyApplicationUrl.includes("application")) {
            dispatch({ type: "HIDE_APPLICATION_PROCESS_NAV" });
        } else {
            dispatch({ type: "SHOW_APPLICATION_PROCESS_NAV" });
        }

        //Fetching data from the server
        const applicationsFromServer = await communication.getMyApplicationsByUserId();
        if (applicationsFromServer.status === "SUCCESS") {
            let applicationsArray = applicationsFromServer.userApplications;
            setApplicationData(applicationsArray);
        }
        else {
            showModal(applicationsFromServer.message);
        }
        hideLoading();

    }

    useEffect(() => {
        loadApplication()
    }, []);

    return (
        <>
            <section className="application_flow_big" >

                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :
                    <>
                        {applicationData.length > 0 ?
                            <>
                                <div className="myapplication_main">
                                    {/*-----Grievance card header section---*/}
                                    <div className="tabel_heading">
                                        <div className="row m-0">
                                            <div className="col-2 p-0 data">
                                                <span><H4>
                                                    Application Number
                                                </H4></span>
                                            </div>
                                            <div className="col-2 p-0 data">
                                                <span><H4>
                                                    Course
                                                </H4></span>
                                            </div>
                                            <div className="col-3 p-0 data">
                                                <span><H4>
                                                    Status
                                                </H4></span>
                                            </div>
                                            <div className="col-3 p-0 data">
                                                <span><H4>
                                                    Comment
                                                </H4></span>
                                            </div>
                                            <div className="col-2 p-0 align" style={{ justifyContent: "center" }}>
                                                <span><H4>
                                                    Date
                                                </H4></span>
                                            </div>
                                        </div>
                                    </div>
                                    {/*-----Grievance card data section---*/}
                                    {applicationData?.map((application, index) => {
                                        const { _id, shortApplicationId, applicationStatus, createdDate, frontOfficeApplicationComment } = application;
                                        return (
                                            <div key={_id}>
                                                <div className="tabel_data" key={index}>
                                                    <div className="row m-0">
                                                        <div className="col-2 p-0 data">
                                                            <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                <a onClick={() => { formStatus(_id) }}>{shortApplicationId}</a>
                                                            </P></span>
                                                        </div>
                                                        <div className="col-2 p-0 data">
                                                            <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                {application?.forms?.personalDetailsForm?.courseDetails?.courseType === '' ? "Select Course " : removeUnderscoreFromText(application?.forms?.personalDetailsForm?.courseDetails?.courseType)}
                                                            </P></span>
                                                        </div>
                                                        <div className="col-3 p-0 data">
                                                            {applicationStatus === "COMPLETED" ?
                                                                <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                    COURSE COMPLETED
                                                                </P></span>
                                                                :
                                                                <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                    {removeUnderscoreFromText(applicationStatus) ?? "New"}
                                                                </P></span>
                                                            }
                                                        </div>


                                                        <div className="col-3 p-0 data">
                                                            <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                {
                                                                    ["INCOMPLETE", "REJECTED"].includes(applicationStatus) ?
                                                                        frontOfficeApplicationComment?.length > 0 ?
                                                                            frontOfficeApplicationComment[frontOfficeApplicationComment.length - 1]
                                                                            :
                                                                            "No comment"
                                                                        :
                                                                        "No comment"
                                                                }

                                                            </P></span>
                                                        </div>

                                                        <div className="col-2 p-0 align" style={{ justifyContent: "center" }}>
                                                            <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                {new Date(createdDate).getDate()}-{new Date(createdDate).getMonth() + 1}-{new Date(createdDate).getFullYear()}
                                                            </P></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                            :
                            <div className="col-12 text-center view_download_dashboard">
                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Applications not available.</H4>
                            </div>
                        }
                    </>


                }
            </section>
        </>
    );
}
export default MyApplication;