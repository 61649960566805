import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import H3 from "../text-components/H3";
import H4 from "../text-components/H4";
import { communication } from "../communications/communication";
import { useHistory } from "react-router";
import LoadingForms from "../shared-components/LoadingForms";
import ReactPaginate from "react-paginate";

const LatestNotification = () => {

  //Alert Modal react-redux
  const dispatch = useDispatch();
  function showModal(text) {
    dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
  }

  // spinner on loading 
  const [isLoading, setIsLoading] = useState({ message: "", value: false });
  const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
  const [notificationCardData, setNotificationCardData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const history = new useHistory();

  //spinner show and hide function declaration
  function showLoading(message) {
    setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
  }

  function hideLoading() {
    setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
  }

  //onclick set current page
  const handlePageClick = (e) => {
    let selectedPage = e.selected;
    setCurrentPage(selectedPage);
    if (searchValue) {
      fetchLatestNotificationBySearch(searchValue, selectedPage + 1);
    } else {
      loadNotification(selectedPage + 1);
    }
  }

  //Search Filter
  const searchFilter = async (searchString) => {
    if (searchString) {
      fetchLatestNotificationBySearch(searchString, currentPage + 1);
    } else {
      const notificationsFromServer = await communication.getAllLatestNotifications();
      if (notificationsFromServer.status === "LATEST_NOTIFICATIONS_AVAILABLE") {
        setNotificationCardData(notificationsFromServer.latestNotificationsList);
        setPageCount(notificationsFromServer?.totalPages);
      } else {
        setNotificationCardData([]);
        setPageCount(0);
      }
    }
  }

  //get Latest Notifications By Search
  const fetchLatestNotificationBySearch = async (searchString, page) => {
    try {
      const responseFromServer = await communication.getLatestNotificationBySearch(searchString, page);
      if (responseFromServer?.data?.status === "LATEST_NOTIFICATIONS_AVAILABLE") {
        setNotificationCardData(responseFromServer?.data?.latestNotificationsList);
        setPageCount(responseFromServer?.data?.totalPages);
      } else if (responseFromServer?.data?.status === "JWT_INVALID") {
        showModal(responseFromServer?.data?.message);
        history.push("/login");
      } else {
        setNotificationCardData([]);
        setPageCount(0);
      }
    } catch (error) {
      showModal(error?.message);
    }
  }

  //get Latest Notifications on Initial Load
  async function loadNotification(page) {
    showLoading("Loading Notifications...");
    // to show active tab
    let fullUrl = window.location.href;
    let splitLatestNotificationUrl = fullUrl.split('/');
    if (splitLatestNotificationUrl.includes("latest-notification")) {
      dispatch({ type: "SHOW_ACTIVE_APPLICATION_PROCESS_TAB", payload: "LATEST_NOTIFICATION" });
    } else {
      dispatch({ type: "HIDE_ACTIVE_APPLICATION_PROCESS_TAB" });
    }

    if (splitLatestNotificationUrl.includes("application")) {
      dispatch({ type: "HIDE_APPLICATION_PROCESS_NAV" });
    } else {
      dispatch({ type: "SHOW_APPLICATION_PROCESS_NAV" });
    }

    //Fetching data from the server
    const notificationsFromServer = await communication.getAllLatestNotifications(page);
    if (notificationsFromServer.status === "LATEST_NOTIFICATIONS_AVAILABLE") {
      setNotificationCardData(notificationsFromServer.latestNotificationsList);
      setPageCount(notificationsFromServer?.totalPages);
    } else {
      hideLoading();
      setNotificationCardData([]);
      setPageCount(0);
    }
    hideLoading();
  }


  useEffect(() => {
    loadNotification();
  }, []);



  const canUserApply = async (notificationId) => {
    showLoading("Checking if eligible to apply...");
    const canApply = await communication.checkIfApplicationIsValidToApply(notificationId);

    if (canApply.status === "APPLICATION_AVAILABLE_TO_APPLY") {
      history.push(`/user/application/?applicationId=${canApply.applicationId}`);

    } else if (canApply.status === "APPLICATION_NOT_AVAILABLE") {
      hideLoading();
    } else if (canApply.status === "APPLICATION_EXPIRED") {
      setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
      history.push("/user/latest-notification");
    } else if (canApply.status === "APPLICATION_AVAILABLE_TO_APPLY") {

    } else if (canApply.status === "APPLICATION_DOES_NOT_EXIST") {

    } else {

    }
    showModal(canApply.message);
    hideLoading();
  }

  const downloadNotification = async (id, attachment) => {
    // if the attachment is empty or not
    if (attachment !== "") {
      const getNotificationPdf = await communication.getNotificationPdf(id);
      return getNotificationPdf;
    }

  }

  return (
    <>
      <section className="application_flow_big" >

        {isLoading.value ?
          <LoadingForms message={isLoading.message} />
          :
          <>

            <div className="latest_notification_main">
              <div className="row search_wrapper">
                <div className="col-lg-4 col-md-4 ps-0">
                  <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Subject, Description..." />
                </div>
                <div className="col-lg-8 col-md-8 pe-0 pagination_outer_wrapper">
                  <div className="pagination_inner_wrapper">
                    {pageCount > 1 &&
                      <ReactPaginate
                        previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                        nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        forcePage={currentPage}
                        activeClassName={"active"} />
                    }
                  </div>
                </div>
              </div>
              <>
                {notificationCardData?.length > 0 ?
                  <>
                    {
                      notificationCardData?.map((notification, index) =>
                        <div key={index}>
                          {notification.type === "APPLY" ?
                            <div className="notification_card_apply">
                              <div className="row">
                                <div className="col-lg-9 col-md-8 col-12 card_left_col">
                                  <div className="notification_subject"><H3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }}><b>{notification.subject}</b></H3></div>
                                  <div className="notification_message"><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>{notification.description}</H4></div>
                                  <div className="notification_date_main">
                                    <div className="start_date"><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Start Date: {notification.startDate.day}/{(notification.startDate.month) + 1}/{notification.startDate.year} <span>{(notification.startDate.hour) + 1}:{notification.startDate.minute}</span></H4></div>
                                    <div className="end_date"><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>End Date: {notification.endDate.day}/{(notification.endDate.month) + 1}/{notification.endDate.year} <span>{(notification.endDate.hour + 1)}:{notification.endDate.minute}</span></H4></div>
                                  </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-12 card_right_col" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                  <div className="notification_time_date_main">
                                    <div className="apply_btn"><button onClick={() => canUserApply(notification._id)}>Apply</button></div>
                                    <div className="notification_time"><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>{notification.createdDate.day}/{(notification.createdDate.month) + 1}/{notification.createdDate.year}</H4></div>
                                  </div>
                                </div>
                              </div>
                              {notification.notificationStatus === "NEW" ?
                                <div className="new_notification_badge">
                                  <p>New</p>
                                </div>
                                :
                                <span></span>
                              }
                            </div>
                            :
                            <div className="notification_card">
                              <div className="row">
                                <div className="col-lg-9 col-md-8 col-12 card_left_col">
                                  <div className="notification_subject"><H3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}><b>{notification.subject}</b></H3></div>
                                  <div className="notification_message"><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>{notification.description}</H4></div>
                                </div>

                                <div className="col-lg-3 col-md-4 col-12 card_right_col" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                  <div className="notification_time_date_main">
                                    {(notification.attachment !== "") ?
                                      <div className="apply_btn"><button onClick={() => downloadNotification(notification._id, notification.attachment)}>Download</button></div>
                                      :
                                      <span></span>
                                    }
                                    <div className="notification_time"><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>{notification.createdDate.day}/{(notification.createdDate.month) + 1}/{notification.createdDate.year}</H4></div>
                                  </div>
                                </div>
                              </div>
                              {notification.notificationStatus === "NEW" ?
                                <div className="new_notification_badge">
                                  <p>New</p>
                                </div>
                                :
                                <span></span>
                              }
                            </div>
                          }
                        </div>
                      )
                    }
                  </>
                  :
                  <div className="col-12 text-center view_download_dashboard">
                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Notifications not available.</H4>
                  </div>
                }
              </>
            </div>
          </>

        }
      </section>
    </>
  );

}
export default LatestNotification;