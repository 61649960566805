import React from 'react';
import { useState } from "react";
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);


const YearlyApplications = ({ graph }) => {

  const userDataNew = graph?.data;

  const [userData, setUserData] = useState({
    labels: userDataNew?.years?.map((data) => data?.year),
    datasets: userDataNew?.datasets
  });



  return (

    <>
      <div className='amount_disbursed'>
        {/* <Bar height="150px" data={userData} /> */}
        <Bar data={userData}
          options={{
            responsive: true,
            radius: 3,
            hitRadius: 3,
            hoverRadius: 5,
            plugins: {
              title: {
                display: true,
                text: 'Yearly Applications'
              },
            },
            interaction: {
              intersect: false
            },
            scales: {
              x: {

                display: true,
                ticks: {
                  display: true
                },
                title: {
                  display: true,
                  text: 'Years',
                }

              },
              y: {

                display: true,
                ticks: {
                  display: true
                },
                title: {
                  display: true,
                  text: 'Applications'

                }
              }
            }
          }}
        />
      </div>

    </>

  )
}

export default YearlyApplications;