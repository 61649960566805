import React from 'react'
import HomeScreenNav from './HomeScreenNav';
import MobileMenu from './MobileMenu';

const SocialJusticeAndSpecialAssistance = () => {
    return (
        <>
            <HomeScreenNav />
            <div className='container'>
                <div className='row'>
                    <div className='col-12 hierarchy_img'>
                        <img src="/assets/images/jpgs/minister-sjsaGraph.jpg" alt="minister-sjsaGraph.jpg" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SocialJusticeAndSpecialAssistance;