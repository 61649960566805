import { useSelector } from "react-redux";
import React from "react";

const PrivacyP = ({ whiteThemeColor, darkThemeColor, children }) => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);

    return (
        <p style={{ fontSize: `${14 * fontSizeMultiplier / 100}px`, fontFamily: 'Open Sans', color: `${darkWhiteThemeToggle === "WHITE" ? whiteThemeColor : darkThemeColor}` }}>{children}</p>
    );
}

export default PrivacyP;