import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from 'formik';
import { applicationCommunication } from "../communications/application-communication";
import FamilyMembersDetailsForm from "./FamilyMembersDetailsForm";
import FamilyMembersAvailedScholarshipDetails from "./FamilyMembersAvailedScholarshipDetails";
import LoadingForms from "../shared-components/LoadingForms";
import H4 from "../text-components/H4";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { communication } from "../communications/communication"


const FamilyDetailsFormMain = () => {

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //****  Stopping field input- Enable & Disable Button *****//
    const [disabled, setDisabled] = useState(true);

    // spinner on loading 
    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    // cancel, save and submit buttons unable & desable
    const [disableActions, setDisableActions] = useState(false);

    const [applicationId, setApplicationId] = useState(null);

    const [saveType, setSaveType] = useState(" ");

    const [familyMembersDetails, setFamilyMembersDetails] = useState([]);

    const [familyMembersAvailedScholarshipDetails, setFamilyMembersAvailedScholarshipDetails] = useState([]);

    const [totalAnnualFamilyIncome, setTotalAnnualFamilyIncome] = useState(0);

    const [totalAnnualFamilyIncomeError, setTotalAnnualFamilyIncomeError] = useState("");

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [availScholorshipProgram, setAvailScholorshipProgram] = useState(false);

    const history = useHistory();

    const location = useLocation();

    // To get current location url
    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');

    //** Fetching parent detail form ***//

    async function initialLoad() {

        showLoading("Loading Form...");

        // to show active tab
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');

        let familyDetailUrl = "family-details?applicationId";
        let splitfamilyDetailUrl = familyDetailUrl.split('?');
        if (splitfamilyDetailUrl.includes("family-details")) {
            dispatch({ type: "SHOW_ACTIVE_APPLICATION_FORM_TAB", payload: "FAMILY_DETAILS" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_APPLICATION_FORM_TAB" });
        }

        const queryParams = new URLSearchParams(location.search);
        const applicationIdFromParams = queryParams.get('applicationId');
        setApplicationId(applicationIdFromParams);

        // Fetching form from the server
        const getFamilyDetailsFormResponse = await applicationCommunication.getFamilyDetailsForm(applicationIdFromParams);
        // Handling response from the server
        if (getFamilyDetailsFormResponse.status === "FAMILY_DETAILS_FORM_FETCHED_SUCCESSFULLY") {
            let familyDetailsForm = getFamilyDetailsFormResponse.familyDetailsForm;

            setFamilyMembersDetails(value => value = [...value, ...familyDetailsForm.familyMembersDetails]);
            setFamilyMembersAvailedScholarshipDetails(value => value = [...value, ...familyDetailsForm.familyMembersAvailedScholarshipDetails]);
            setDisabled(value => value = familyDetailsForm.isSubmitted);
            setIsSubmitted(value => value = familyDetailsForm.isSubmitted);
            setTotalAnnualFamilyIncome(value => value = familyDetailsForm.totalFamilyAnnualIncome)
        } else {
            showModal(getFamilyDetailsFormResponse.message);
        }

        //Checking application status is incomplete & making fields undisabled
        const applicationsFromServer = await communication.getMyApplications(applicationIdFromParams);

        if (applicationsFromServer.status === "SUCCESS") {
            const applicationStatus = applicationsFromServer.userApplications[0].applicationStatus

            if (applicationStatus === "INCOMPLETE") {
                setDisabled(false);
                setIsSubmitted(false);
            } else if (applicationStatus === "SELECTED" || applicationStatus === "COMPLETED" || applicationStatus === "APPLICATION_SUBMITTED") {
                setDisabled(true);
                setIsSubmitted(true);
            }
        }

        hideLoading();
    }

    useEffect(() => {
        initialLoad();
    }, []);

    // Spinner on loading

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    // delete table row
    function deleteFamilyMemberDetails(itemToDelete) {
        setFamilyMembersDetails(value => {
            return value.filter(item => item !== itemToDelete);
        });
    }

    function deleteFamilyMembersAvailedScholarshipDetails(itemToDelete) {
        setFamilyMembersAvailedScholarshipDetails(value => {
            return value.filter(item => item !== itemToDelete);
        });
    }

    //setting max length to total family annual income field
    const maxLengthCheck = (e) => {
        if (e.target.value > 600000) {
            setTotalAnnualFamilyIncomeError("maxError");
        }
    }

    const uploadForm = async (saveTypeFromUi) => {
        // To validate total annual income field
        // if (totalAnnualFamilyIncome == 0) {
        //     setTotalAnnualFamilyIncomeError("can't be 0");
        // } else {
        //     setTotalAnnualFamilyIncomeError("");
        // }

        // Loading on save and submit button
        showLoading(saveTypeFromUi === "SUBMIT" ? "Submitting Form..." : "Saving Form...");
        // Disable Save, Cancel and Submit Buttons
        setDisableActions(true);

        let familyDetailsForm = {
            familyMembersDetails: familyMembersDetails,
            familyMembersAvailedScholarshipDetails: familyMembersAvailedScholarshipDetails,
            totalFamilyAnnualIncome: Number(totalAnnualFamilyIncome)
        }
        // Sending data to server
        let sendFamilyDetailsFormResponse = await applicationCommunication.uploadFamilyDetailsForm(familyDetailsForm, applicationId, "SAVE");

        // Handling response from the server
        if (sendFamilyDetailsFormResponse.status === "FORM_SAVED_SUCCESSFULLY") {

            if (saveTypeFromUi === "SAVE") {

            } else {
                history.push(`/user/application/college-admission-details?applicationId=${applicationId}`);
            }
        }

        if (sendFamilyDetailsFormResponse.status === "VALIDATION_FAILED") {
            showModal(sendFamilyDetailsFormResponse.validationErrorsList[0].message || sendFamilyDetailsFormResponse.data.validationErrorsList[0].message);
            hideLoading();
            setDisableActions(false);
            return false;
        }
        showModal(sendFamilyDetailsFormResponse.message || sendFamilyDetailsFormResponse.data.message);
        hideLoading();
        setDisableActions(false);
        return false;
    }

    function loadNextForm() {
        history.push(`/user/application/college-admission-details?applicationId=${applicationId}`);
    }

    useEffect(() => {

        let calculatedAmount = 0;
        familyMembersDetails.forEach((ele) => {
            calculatedAmount = calculatedAmount + ele.totalAnnualIncome
        });

        setTotalAnnualFamilyIncome(calculatedAmount);

    }, [familyMembersDetails]);

    return (
        <section className="application_flow_big">
            {isLoading.value ?
                <LoadingForms message={isLoading.message} />
                :

                <>
                    {isSubmitted ?
                        <div className="mt-5"></div>
                        : <FamilyMembersDetailsForm
                            setAvailScholorshipProgram={setAvailScholorshipProgram}
                            formData={(formDataValues) => {
                                setFamilyMembersDetails(value => { return [...value, { ...formDataValues }] });
                            }
                            } />
                    }

                    <div className="container family_member_details_table">

                        {familyMembersDetails.map((familyMemberDetail, index) => {
                            return (
                                <div className="row mt-3" key={index}>

                                    <div className="col-lg-1 col-md-1 col-3">
                                        <input name="" type="text" value={index + 1} className="form-control text-center" disabled />
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-9">
                                        <input name="fullName" type="text" value={familyMemberDetail.fullName} className="form-control" disabled />
                                    </div>

                                    <div className="col-lg-1 col-md-2 col-3 mt-md-0 mt-lg-0 mt-2">
                                        <input name="age" type="number" value={familyMemberDetail.age} className="form-control" disabled />
                                    </div>

                                    <div className="col-lg-2 col-md-4 col-9 mt-md-0 mt-lg-0 mt-2">
                                        <input name="relationWithStudent" value={familyMemberDetail.relationWithStudent} type="text" className="form-control" disabled />
                                    </div>

                                    <div className="col-lg-2 col-md-4 col-7 mt-md-2 mt-lg-0 mt-2">
                                        <input name="business" value={familyMemberDetail.business} type="text" className="form-control" disabled />
                                    </div>

                                    <div className="col-lg-1 col-md-3 col-5 mt-md-2 mt-lg-0 mt-2">
                                        <input name="totalAnnualIncome" value={familyMemberDetail.totalAnnualIncome} type="number" className="form-control" disabled />
                                    </div>

                                    <div className="col-lg-1 col-md-2 col-6 mt-md-2 mt-lg-0 mt-2">
                                        <input type="text" value={familyMemberDetail.hasAvailedScholarshipProgram ? "Yes" : "No"} className="form-control text-center" disabled />
                                    </div>
                                    {isSubmitted ? <span></span> :
                                        <div className="col-lg-1 col-md-1 col-6 mt-sm-2 mt-lg-0 mt-2 trash_btn">
                                            <button onClick={() => deleteFamilyMemberDetails(familyMemberDetail)}><i className="fa fa-trash-o" aria-hidden="true"></i></button>
                                        </div>
                                    }
                                </div>
                            );
                        })}

                        <div className="row mt-3">
                            <div className="col-lg-8 col-md-0"></div>
                            <div className="col-lg-2 col-md-4 col-6">
                                <H4 whiteThemeColor="#0d2750" darkThemeColor="#FFFFFF">Total Annual Family Income</H4>
                            </div>
                            <div className="col-lg-2 col-md-3 col-6">
                                <input type="number" value={totalAnnualFamilyIncome} onChange={(event) => { setTotalAnnualFamilyIncome(event.target.value) }} onInput={(e) => maxLengthCheck(e)} className="form-control" disabled />
                                {/* {totalAnnualFamilyIncomeError === "maxError" ? <div style={{ color: "red" }}>Total family annual income can't be greater than 6 Lakhs</div> : <span></span>}
                                {totalAnnualFamilyIncomeError === "can't be 0" ? <div style={{ color: "red" }}>Total family annual income can't be zero</div> : <span></span>} */}
                            </div>
                            {/* <div className="col-lg-2 col-md-2"></div> */}
                        </div>
                    </div>
                    {/* seconds component */}
                    {/* {isSubmitted && availScholorshipProgram === false ? */}
                    {availScholorshipProgram === false ?
                        <div className="mt-5"></div>
                        : <FamilyMembersAvailedScholarshipDetails
                            familyMemberAvailedSccholarshipFormData={(familyMemberAvailedSccholarshipFormDataValues) => {
                                setFamilyMembersAvailedScholarshipDetails(value => { return [...value, { ...familyMemberAvailedSccholarshipFormDataValues }] });
                            }
                            } />
                    }

                    <div className="container family_member_availed_scholarship_details_table">
                        {familyMembersAvailedScholarshipDetails.map((familyMembersAvailedScholarshipDetails, index) => {
                            return (
                                <div className="row mt-3" key={index}>

                                    <div className="col-lg-1 col-md-1 col-3 mt-lg-0 mt-2">
                                        <input name="" type="text" value={index + 1} className="form-control text-center" disabled />
                                    </div>

                                    <div className="col-lg-2 col-md-3 col-9 mt-lg-0 mt-2">
                                        <input name="courseYear" type="number" value={familyMembersAvailedScholarshipDetails.courseYear} className="form-control" disabled />
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-12 mt-lg-0 mt-2">
                                        <input name="collegeName" type="text" value={familyMembersAvailedScholarshipDetails.collegeName} className="form-control" disabled />
                                    </div>

                                    <div className="col-lg-2 col-md-4 col-12 mt-lg-0 mt-2">
                                        <input name="country" value={familyMembersAvailedScholarshipDetails.city} type="text" className="form-control" disabled />
                                    </div>

                                    <div className="col-lg-2 col-md-4 col-12 mt-md-2 mt-lg-0 mt-2">
                                        <input name="scholarshipProvider" value={familyMembersAvailedScholarshipDetails.scholarshipProvider} type="text" className="form-control" disabled />
                                    </div>
                                    {isSubmitted ? <span></span> :
                                        <div className="col-lg-1 col-md-1 col-12 mt-sm-2 mt-lg-0 mt-2 trash_btn">
                                            <button onClick={() => deleteFamilyMembersAvailedScholarshipDetails(familyMembersAvailedScholarshipDetails)}><i className="fa fa-trash-o" aria-hidden="true"></i></button>
                                        </div>
                                    }
                                </div>
                            );
                        })}

                    </div>


                    <div className="container mb-3">
                        <div className="row">
                            <div className="col-12">
                                <div className="form_button">
                                    {splitUrlArray[4] === "user" ?
                                        <button type="button" onClick={() => { history.push("/user"); dispatch({ type: "SHOW_APPLICATION_PROCESS_NAV" }); }} className="btn form_cancel_btn" disabled={disableActions}>Cancel</button>
                                        : <span></span>
                                    }
                                    {
                                        isSubmitted ?
                                            <div className="m-1"></div>
                                            :
                                            <button type="button" onClick={() => {
                                                // setSaveType("SAVE");
                                                uploadForm("SAVE");
                                            }} className="btn form_save-btn" disabled={disableActions}>Save</button>
                                    }
                                    {
                                        isSubmitted ?
                                            (splitUrlArray[4] === "user") ?
                                                <button type="button" onClick={() => {
                                                    loadNextForm();
                                                }} className="btn form_submit_btn">Next</button>
                                                : <span></span>
                                            :
                                            <button type="button" onClick={() => {
                                                // setSaveType(value => value = "SAVE_AND_NEXT");
                                                uploadForm("SAVE_AND_NEXT");
                                            }} className="btn form_submit_btn" disabled={disableActions}>Save & Next</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                </>
            }
        </section>

    );
}
export default FamilyDetailsFormMain;
