import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoadingForms from "../../shared-components/LoadingForms";
import H5 from "../../text-components/H5";
import { confirm } from "react-confirm-box";
import ReactPaginate from "react-paginate";
// import { useHistory } from "react-router-dom";


const RoleMaintenanceUpdate = () => { 

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [allRolesMaintenanceData, setAllRolesMaintenanceData] = useState([]);
    const [idsOfRole, setIdsOfRole] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchRoleBySearch(searchValue, selectedPage + 1);
        } else {
            fetchAllRoles(selectedPage + 1);
        }
    }

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchRoleBySearch(searchString, currentPage + 1);
        } else {
            const roleFromServer = await adminCommunication.getAllRoles();
            if (roleFromServer.status === "SUCCESS") {
                setAllRolesMaintenanceData(roleFromServer.roles);
                setPageCount(roleFromServer?.totalPages);
            } else if (roleFromServer?.status === "JWT_INVALID") {
                history.push("/admin/login");
                showModal(roleFromServer?.message);
            } else {
                setAllRolesMaintenanceData([]);
                setPageCount(0);
            }
        }
    }
    
    //get Role By Search
    const fetchRoleBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await adminCommunication.getRoleBySearch(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setAllRolesMaintenanceData(responseFromServer?.data?.roles);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                history.push("/admin/login");
                showModal(responseFromServer?.data?.message);
            } else {
                setAllRolesMaintenanceData([]);
                setPageCount(0);
            }
        } catch (error) {
            showModal(error.message);
        }
    }

    //get All Roles on Initial Load
    const fetchAllRoles = async (page) => {
        try {
            showLoading("Loading...");
            dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "ROLES_MAINTENANCE_VIEW" });
            const roleFromServer = await adminCommunication.getAllRoles(page);
            if (roleFromServer.status === "SUCCESS") {
                setAllRolesMaintenanceData(roleFromServer.roles);
                setPageCount(roleFromServer?.totalPages);
            } else if (roleFromServer?.status === "JWT_INVALID") {
                history.push("/admin/login");
                showModal(roleFromServer?.message);
            } else {
                showModal(roleFromServer.message);
                setAllRolesMaintenanceData([]);
                setPageCount(0);
            }
            hideLoading();
        } catch (error) {
            showModal(error.message);  
        }
    }

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    useEffect(async () => {
        showLoading("Loading...");
        dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "ROLES_MAINTENANCE_UPDATE" });
        const roleFromServer = await adminCommunication.getAllRoles();
        if (roleFromServer.status === "SUCCESS") {
            let roleArray = roleFromServer.roles;
            setAllRolesMaintenanceData(value => {
                return [...value, ...roleArray]
            });
        } else {
            showModal(roleFromServer.message);
        }
        
        fetchAllRoles();
    }, []);

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }


    // set ids in to state
    const handleOnChange = (e) => {
        let checked = e.target.checked;
        let id = e.target.value;
        if (checked) {
            setIdsOfRole(() => [...idsOfRole, id]);
        } else {
            // to remove from state
            setIdsOfRole(
                idsOfRole.filter((Id) => Id !== id)
            );
        }
        return;
    }

    // delete role by id
    const deleteRole = async (options) => {
        if (idsOfRole.length !== 0) {
            const confirmBox = await confirm(`Are you sure , do you want to delete this role ? If you deleted this role . All the related committee will be deleted.`, options);
            if (confirmBox === true) {
                showLoading("Loading...");
                const deleteRoleByIdData = await adminCommunication.deleteRoleById(idsOfRole);
                if (deleteRoleByIdData.status === "SUCCESS") {
                    // calling refreshPage function after 5 sec to show model before refreshing the page
                    setTimeout(() => {
                        history.push(`/admin/dashboard/roles-maintenance-view`);
                    }, 1000);
                }
                showModal(deleteRoleByIdData.message);
            }

        }
        else {
            showModal('Please select atleast one record');
        }
        hideLoading();
        return;
    }

    // redirect to committee maintenance save according to committee id
    const roleEdit = (roleId) => {
        history.push(`/admin/dashboard/roles-maintenance-save?roleId=${roleId}`);
        return;
    }



    return (
        <>

            <section className="dashboard_background_update" >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :

                    <div className="dashbord_main">
                    <div className="row search_wrapper">
                    <div className="col-lg-4 col-md-4 ps-0">
                        <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Role, Portal..." />
                    </div>
                    <div className="col-lg-8 col-md-8 pagination_outer_wrapper">
                        <div className="pagination_inner_wrapper">
                            {pageCount > 1 &&
                                <ReactPaginate
                                    previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                    nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            }
                        </div>
                    </div>
                </div>
                        {/*-----Grievance card header section---*/}
                        <div className="dashboard_view_data_heading">
                            <div className="row m-0">
                                <div className="col-1 p-0 view_dashboard">
                                    <span><H5>Serial No.</H5></span>
                                </div>
                                <div className="col-2 p-0 view_dashboard">
                                    <span><H5>Role</H5></span>
                                </div>
                                <div className="col-2 p-0 view_dashboard">
                                    <span><H5>Portal</H5></span>
                                </div>
                                <div className="col-5 p-0 view_dashboard">
                                    <span><H5>Tabs</H5></span>
                                </div>
                                <div className="col-2 p-0 ">
                                    <span><H5>Access</H5></span>
                                </div>
                            </div>
                        </div>

                        {allRolesMaintenanceData.length > 0 ?
                            <div>
                                {/*-----Grievance card data section---*/}
                                {allRolesMaintenanceData.map((users, index) => {
                                    const { _id, name, portal, tabs, access } = users;
                                    return (
                                        <div key={index}>
                                            <div className="dashboard_view_data_main">
                                                <div className="dashboard_view_data">
                                                    <div className="row m-0">
                                                        <div className="col-1 p-0 view_dashboard sr-no text_align">
                                                            <span>
                                                                {/* <input type="checkbox" value={_id} onClick={(e) => { handleOnChange(e) }} /> */}
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                {limit * currentPage + (index + 1)}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-2 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    <a onClick={() => roleEdit(_id)}>
                                                                        {name}
                                                                    </a>
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-2 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >{portal}</H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-5 p-0 view_dashboard text_align">
                                                            <span>
                                                                <div className="arrayFetch">
                                                                    {tabs.map((data, index, arr) => {
                                                                        return (
                                                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" key={index}>
                                                                                {index != (arr.length - 1) ? `${data},` : data}
                                                                            </H5>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div className="col-2 p-0 text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {access}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    );
                                })}
                            </div>
                            :
                            <div className="col-12 text-center view_download_dashboard">
                                <h6>Records not available.</h6>
                            </div>
                        }

                    </div>
                }
            </section>
            {/* <section className="mb-3">
                <div className="row">
                    <div className="col-2 button_div">
                        <div className="dashboard_delete_button">
                            <button type="button" className="btn" onClick={() => deleteRole()} >

                                {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H5>Delete</H5>}

                            </button>
                        </div>
                    </div>
                </div>
            </section> */}
        </>

    )
}

export default RoleMaintenanceUpdate;