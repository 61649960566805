import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from 'react-router';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BackOfficeNav from "../common/BackOfficeNav";
import GovernmentResolution from "./Panels/GovernmentResolution";
import UserNotification from "./Panels/UserNotification";
import DocumentScrutiny from "./Panels/DocumentScrutiny";
import BackOfficeNotification from "./Panels/BackOfficeNotification";
import BackOfficeRemittance from "./Panels/BackOfficeRemittance";
import BackOfficeGrievance from "./Panels/BackOfficeGrievance";
import Refund from "./Panels/Refund";
import GovernmentResolutionPreview from "./Panels/GovernmentResolutionPreview";
import DocumentScrutinyMilestoneList from "./Panels/DocumentScrutinyMilestoneList";
import DocumentScrutinyUploadedFiles from "./Panels/DocumentScrutinyUploadedFiles";
import DocumentScrutinyFilesPreview from "./Panels/DocumentScrutinyFilesPreview";
import UserNotificationPreview from "./Panels/UserNotificationPreview";
import BackOfficeRemittancePreview from "./Panels/BackOfficeRemittancePreview";
import BackOfficeGrievancePreview from "./Panels/BackOfficeGrievancePreview";
import RefundPreview from "./Panels/RefundPreview";
import BackOfficeRemittancePrintPreview from "./Panels/BackOfficeRemittancePrintPreview";
import BackOfficeLogout from "./BackOfficeLogout";
import Application from "../main-components/Application";
import BackOfficeWelcome from "./Panels/BackOfficeWelcome";
import UserMilestoneOne from './Panels/milestone/UserMilestoneOne'
import UserMilestoneTwo from "./Panels/milestone/UserMilestoneTwo";
import UserMilestoneThree from "./Panels/milestone/UserMilestoneThree";
import UserMilestoneFour from "./Panels/milestone/UserMilestoneFour";
import UserMilestoneFive from "./Panels/milestone/UserMilestoneFive";
import GeneralMilestone from "./Panels/milestone/GeneralMilestone";
import GeneralMilestoneNewSession from "./Panels/milestone/GeneralMilestoneNewSession";
import HomeVerification from "./Panels/HomeVerification";
import StudentPerformance from "./Panels/StudentPerformance";
import StudentReview from "./Panels/StudentReview";
import StudentReviewSemesterList from "./Panels/StudentReviewSemesterList";
import StudentMarksheetDetail from "./Panels/StudentMarksheetDetail";
import DocumentScrutinyForCommissioner from './Panels/DocumentScrutinyForCommissioner';

const DashboardBackOffice = () => {

  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
  const [applicationId, setApplicationId] = useState(null);
  const showBackOfficeNav = true;
  const [remittanceId, setRemittanceId] = useState(null);
  const isBackOfficeAuthenticated = useSelector((state) => state.isBackOfficeAuthenticated.value ?? true);
  const [refundId, setRefundId] = useState(null);
  const [type, setType] = useState(null);
  const [milestoneId, setMilestoneId] = useState(null);
  const [toRefresh, setToRefresh] = useState(true);
  const [tab, setTab] = useState("");
  const isBackOfficeUserData = useSelector((state) => state.isBackOfficeAuthenticated ?? {
    backOfficeUser: { designation: "", name: "", id: "" }, tabs: [], value: false
  });



  const location = useLocation();

  useEffect(() => {
    // getting Application id by queryParams
    const queryParams = new URLSearchParams(location.search);
    const applicationIdFromParams = queryParams.get('applicationId');
    setApplicationId(applicationIdFromParams);

    const typeFromParams = queryParams.get('type');
    setType(value => value = typeFromParams);

    const remittanceIdFromParams = queryParams.get('remittanceId');
    setRemittanceId(remittanceIdFromParams);

    const homeverificationTab = queryParams.get('tab');
    setTab(homeverificationTab);

    const milestoneIdFromQuery = queryParams.get('milestoneId');
    setMilestoneId(value => value = milestoneIdFromQuery);

    const refundIdFromParams = queryParams.get('refundId');
    setRefundId(value => value = refundIdFromParams);

  }, [location.search]);

  function changeHandler() {
    setToRefresh(!toRefresh);
  }

  return (

    <div >
      {/* <UserDownloadApplicationFlowNav /> */}
      <div style={{ position: "relative" }}>
        {
          showBackOfficeNav
            ?
            <BackOfficeNav toRefresh={toRefresh} />
            :
            <span></span>
        }
        <BackOfficeLogout />
      </div>

      <section className="application_process_main " style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
        <div className="row m-0 ">

          <div className="col-12 p-0 ">
            <Route exact path="/back-office/dashboard" render={() => {
              return (<Redirect to="/back-office/dashboard/Welcome" />);
            }} />
            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/Welcome" component={BackOfficeWelcome} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/government-resolution" component={GovernmentResolution} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/user-notification-preview" component={() => <UserNotificationPreview changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/user-notification" component={UserNotification} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <>
                  {
                    (isBackOfficeUserData?.backOfficeUser?.designation === "COMMISSIONER") ?
                      <Route exact path="/back-office/dashboard/document-scrutiny" component={DocumentScrutinyForCommissioner} />
                      :
                      <Route exact path="/back-office/dashboard/document-scrutiny" component={DocumentScrutiny} />

                  }
                </>
                :
                <Redirect to="/back-office/login" />
            }

            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/home-verification" component={HomeVerification} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/notification" component={() => <BackOfficeNotification changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/remittance" component={BackOfficeRemittance} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/grievance" component={BackOfficeGrievance} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/refund" component={Refund} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/student-performance" component={StudentPerformance} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/student-review" component={StudentReview} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/student-review-semester-list" component={() => <StudentReviewSemesterList changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/refundpreview" component={() => <RefundPreview changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/student-marks-preview" component={() => <StudentMarksheetDetail changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }

            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/government-resolution-preview" component={() => <GovernmentResolutionPreview changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }

            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/document-scrutiny/milestone-list" component={DocumentScrutinyMilestoneList} />
                :
                <Redirect to="/back-office/login" />
            }

            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/document-scrutiny/milestone-list/uploaded-documents" component={DocumentScrutinyUploadedFiles} />
                :
                <Redirect to="/back-office/login" />
            }

            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/document-scrutiny/milestone-list/uploaded-documents/preview" component={DocumentScrutinyFilesPreview} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/notification-preview" component={() => <UserNotificationPreview changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/remittance-preview" component={() => <BackOfficeRemittancePreview changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }

            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/grievance-print-preview" component={BackOfficeRemittancePrintPreview} />
                :
                <Redirect to="/back-office/login" />
            }

            {
              isBackOfficeAuthenticated ?
                <Route exact path="/back-office/dashboard/grievance-preview" component={() => <BackOfficeGrievancePreview changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }
            {
              isBackOfficeAuthenticated ?
                <Route path="/back-office/dashboard/application" component={Application} />
                :
                <Redirect to="/back-office/login" />
            }

            {
              isBackOfficeAuthenticated ?
                <Route path="/back-office/dashboard/milestoneOne" component={() => <UserMilestoneOne changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }

            {
              applicationId !== null && type === "MILESTONE_ONE" ?
                (
                  <Route exact path="/back-office/dashboard/milestoneOne" render={() => {
                    return (<Redirect to={`/back-office/dashboard/milestoneOne?applicationId=${applicationId}&type=${type}&milestoneId=${milestoneId}`} />);
                  }} />
                )
                :
                (<div></div>)
            }

            {
              isBackOfficeAuthenticated ?
                <Route path="/back-office/dashboard/milestoneTwo" component={() => <UserMilestoneTwo changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }

            {
              applicationId !== null && type === "MILESTONE_TWO" ?
                (
                  <Route exact path="/back-office/dashboard/milestoneTwo" render={() => {
                    return (<Redirect to={`/back-office/dashboard/milestoneTwo?applicationId=${applicationId}&type=${type}&milestoneId=${milestoneId}`} />);
                  }} />
                )
                :
                (<div></div>)
            }
            {
              applicationId !== null && tab === "homeverification" && type === "MILESTONE_TWO" ?
                (
                  <Route exact path="/back-office/dashboard/milestoneTwo" render={() => {
                    return (<Redirect to={`/back-office/dashboard/milestoneTwo?applicationId=${applicationId}&type=${type}&milestoneId=${milestoneId}&tab=${tab}`} />);
                  }} />
                )
                :
                (<div></div>)
            }


            {
              isBackOfficeAuthenticated ?
                <Route path="/back-office/dashboard/milestoneThree" component={() => <UserMilestoneThree changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }

            {
              applicationId !== null && type === "MILESTONE_THREE" && milestoneId !== null ?
                (
                  <Route exact path="/back-office/dashboard/milestoneThree" render={() => {
                    return (<Redirect to={`/back-office/dashboard/milestoneThree?applicationId=${applicationId}&type=${type}&milestoneId=${milestoneId}`} />);
                  }} />
                )
                :
                (<div></div>)
            }

            {
              isBackOfficeAuthenticated ?
                <Route path="/back-office/dashboard/milestoneFour" component={() => <UserMilestoneFour changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }

            {
              applicationId !== null && type === "MILESTONE_FOUR" && milestoneId !== null ?
                (
                  <Route exact path="/back-office/dashboard/milestoneFour" render={() => {
                    return (<Redirect to={`/back-office/dashboard/milestoneFour?applicationId=${applicationId}&type=${type}&milestoneId=${milestoneId}`} />);
                  }} />
                )
                :
                (<div></div>)
            }

            {
              isBackOfficeAuthenticated ?
                <Route path="/back-office/dashboard/milestoneFive" component={() => <UserMilestoneFive changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }

            {
              applicationId !== null && type === "MILESTONE_FIVE" && milestoneId === null ?
                (
                  <Route exact path="/back-office/dashboard/milestoneFive" render={() => {
                    return (<Redirect to={`/back-office/dashboard/milestoneFive?applicationId=${applicationId}&type=${type}&milestoneId=${milestoneId}`} />);
                  }} />
                )
                :
                (<div></div>)
            }

            {
              isBackOfficeAuthenticated ?
                <Route path="/back-office/dashboard/generalMilestone" component={() => <GeneralMilestone changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }

            {
              (applicationId !== null) && (["MILESTONE_ONE", "MILESTONE_TWO", "MILESTONE_THREE", "MILESTONE_FOUR", "MILESTONE_FIVE", "NEW_SESSION"].includes(type) === false) && (milestoneId !== null) ?
                (
                  <Route exact path="/back-office/dashboard/generalMilestone" render={() => {
                    return (<Redirect to={`/back-office/dashboard/generalMilestone?applicationId=${applicationId}&type=${type}&milestoneId=${milestoneId}`} />);
                  }} />
                )
                :
                (<div></div>)
            }

            {
              isBackOfficeAuthenticated ?
                <Route path="/back-office/dashboard/generalMilestoneNewSession" component={() => <GeneralMilestoneNewSession changeHandler={changeHandler} />} />
                :
                <Redirect to="/back-office/login" />
            }

            {
              applicationId !== null && type === "NEW_SESSION" && milestoneId === null ?
                (
                  <Route exact path="/back-office/dashboard/generalMilestoneNewSession" render={() => {
                    return (<Redirect to={`/back-office/dashboard/generalMilestoneNewSession?applicationId=${applicationId}&type=${type}&milestoneId=${milestoneId}`} />);
                  }} />
                )
                :
                (<div></div>)
            }

            {
              applicationId !== null ?
                (
                  <Route exact path="/back-office/dashboard/document-scrutiny/milestone-list" render={() => {
                    return (<Redirect to={"/back-office/dashboard/document-scrutiny/milestone-list?applicationId=" + applicationId} />);
                  }} />
                )
                :
                (<div></div>)
            }

            {
              remittanceId !== null ?
                (
                  <Route exact path="/back-office/dashboard/remittance-preview" render={() => {
                    return (<Redirect to={"/back-office/dashboard/remittance-preview?remittanceId=" + remittanceId} />);
                  }} />
                )
                :
                (<></>)
            }


            {
              remittanceId !== null ?
                (
                  <Route exact path="/back-office/dashboard/grievance-print-preview" render={() => {
                    return (<Redirect to={"/back-office/dashboard/grievance-print-preview?remittanceId=" + remittanceId} />);
                  }} />
                )
                :
                (<></>)
            }
            {
              refundId !== null ?
                (
                  <Route exact path="/back-office/dashboard/refund-preview" render={() => {
                    return (<Redirect to={"/back-office/dashboard/refund-preview?refundId=" + refundId} />);
                  }} />
                )
                :
                (<></>)
            }

          </div>
        </div>

      </section>
    </div>
  );

}
export default DashboardBackOffice;