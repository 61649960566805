import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import './mediaQueries.css';


// Reducers --------------------------------------------------------------------------------------------------------------
// This is a function to assign to set font size of text in percentage. Default value is 100. Max is 105. Min is 95. 
const fontSizeMultiplier = (state = 100, action) => {
  if (action.type === "INCREMENT_WEBSITE_FONT_SIZE") {
    if (state < 105) {
      return (state + 5);
    }
  } else if (action.type === "DECREMENT_WEBSITE_FONT_SIZE") {
    if (state > 95 && state <= 105) {
      return (state - 5);
    }
  } else if (action.type === "SET_DEFAULT_WEBSITE_FONT_SIZE") {
    return (state = 100);
  } else {
    return state;
  }
  return state;
}

// Dark / White theme
const darkWhiteThemeToggle = (state = "WHITE", action) => {
  if (action.type === "CHANGE_WEBSITE_THEME_COLOR") {
    if (state === "WHITE") {
      return (state = "DARK");
    } else if (state === "DARK") {
      return (state = "WHITE");
    }
  }
  return state;
}

// User Authentication
export const isUserAuthenticated = (state = false, action) => {
  if (action.type === "AUTHENTICATE_USER") {
    document.cookie = `sjsadUserToken=${action.payload};path=/`;
    return (state = true);
  }
  if (action.type === "DEAUTHENTICATE_USER") {
    document.cookie = "sjsadUserToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    return (state = false);
  }
  return state;
}

// Admin Authentication
const isAdminAuthenticated = (state = {}, action) => {
  if (action.type === "AUTHENTICATE_ADMIN") {
    document.cookie = `sjsadAdminToken=${action.payload};path=/`;
    return (state = { value: true, admin: action.adminData });
  }
  if (action.type === "DEAUTHENTICATE_ADMIN") {
    document.cookie = "sjsadAdminToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    return (state = { value: false, admin: {} });
  }
  return state;
}

// Admin Authentication
const isOfficeUserAuthenticated = (state = {}, action) => {
  if (action.type === "AUTHENTICATE_FRONTOFFICEUSER") {
    document.cookie = `sjsadFrontOfficeToken=${action.payload};path=/`;
    return (state = { value: true, officeUser: action.officeUser, tabs: action.officeUser.tabs });
  }
  if (action.type === "DEAUTHENTICATE_FRONTOFFICEUSER") {
    document.cookie = "sjsadFrontOfficeToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    return (state = { value: false, officeUser: {}, tabs: [] });
  }
  return state;
}


// Back Office Authentication
const isBackOfficeAuthenticated = (state = {}, action) => {
  if (action.type === "AUTHENTICATE_BACKOFFICEUSER") {
    document.cookie = `sjsadBackOfficeToken=${action.payload};path=/`;
    return (state = { value: true, backOfficeUser: action.backOfficeUserData, tabs: action.tabs });
  }
  if (action.type === "DEAUTHENTICATE_BACKOFFICEUSER") {
    document.cookie = "sjsadBackOfficeToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    return (state = { value: false, backOfficeUser: {}, tabs: [] });
  }
  return state;
}


// Reducer to hide/show text modal
const textModal = (state = { value: false, text: "" }, action) => {
  if (action.type === "SHOW_TEXT_MODAL") {
    return (state = { value: true, text: action.payload });
  } else if (action.type === "HIDE_TEXT_MODAL") {
    return (state = { value: false, text: "" });
  }
  return state;
}

const yesNoTextModal = (state = { value: false, text: "", answer: null }, action) => {
  if (action.type === "SHOW_YES_NO_TEXT_MODAL") {
    return (state = { value: true, text: action.payload.text });
  } else if (action.type === "HIDE_YES_NO_TEXT_MODAL") {
    return (state = { value: false, text: "", answer: action.payload.answer });
  }
  return state;
}

//Reducer to hide/show ApplicationProcessNav
const showApplicationProcessNav = (state = true, action) => {
  if (action.type === "SHOW_APPLICATION_PROCESS_NAV") {
    return (state = true);
  } else if (action.type === "HIDE_APPLICATION_PROCESS_NAV") {
    return (state = false);
  }
  return state;
}

//Reducer to show active application process tab
const showActiveApplicationProcessTab = (state = "", action) => {
  if (action.type === "SHOW_ACTIVE_APPLICATION_PROCESS_TAB") {
    return state = action.payload;
  } else if (action.type === "HIDE_ACTIVE_APPLICATION_PROCESS_TAB") {
    return state = "";
  }
  return state;
}

//Reducer to show active application form tab
const showActiveApplicationFormTab = (state = "", action) => {
  if (action.type === "SHOW_ACTIVE_APPLICATION_FORM_TAB") {
    return state = action.payload;
  } else if (action.type === "HIDE_ACTIVE_APPLICATION_FORM_TAB") {
    return state = "";
  }
  return state;
}


//Reducer to show active front office  process tab
const activeTab = (state = "", action) => {
  if (action.type === "ACTIVE_TAB") {
    return state = action.payload;
  }
  else if (action.type === "HIDE_ACTIVE_TAB") {
    return state = "";
  }
  return state;
}

//Reducer to show active tab in admin panel
const adminActiveTab = (state = "", action) => {
 
  if (action.type === "ACTIVE_SIDEBAR_TAB") {
    return state = action.payload;
  }
  else if (action.type === "HIDE_ACTIVE_SIDEBAR_TAB") {
    return state = "";
  }
  return state;
}

//Reducer to show active back office  process tab
const activeBackOfficeTab = (state = "", action) => {
  if (action.type === "ACTIVE_BACK_OFFICE_TAB") {
    return state = action.payload;
  }
  else if (action.type === "HIDE_ACTIVE_BACK_OFFICE_TAB") {
    return state = "";
  }
  return state;
}


// Reducers End Here ---------------------------------------------------------------------------------------

let store = createStore(
  combineReducers({
    fontSizeMultiplier: fontSizeMultiplier,
    darkWhiteThemeToggle: darkWhiteThemeToggle,
    isUserAuthenticated: isUserAuthenticated,
    textModal: textModal,
    showApplicationProcessNav: showApplicationProcessNav,
    isAdminAuthenticated: isAdminAuthenticated,
    isOfficeUserAuthenticated: isOfficeUserAuthenticated,
    yesNoTextModal: yesNoTextModal,
    showActiveApplicationProcessTab: showActiveApplicationProcessTab,
    showActiveApplicationFormTab: showActiveApplicationFormTab,
    activeTab: activeTab,
    isBackOfficeAuthenticated: isBackOfficeAuthenticated,
    adminPanelParentActiveTab: adminActiveTab,
    activeBackOfficeTab: activeBackOfficeTab
  })
);
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);


reportWebVitals();
