import React, { useState, useEffect } from "react";
import H4 from '../text-components/H4';
import Label from "../text-components/Label";
import * as Yup from "yup";
import { useFormik } from 'formik';
import { applicationCommunication } from "../communications/application-communication";
import { communication } from "../communications/communication";
import { useLocation } from "react-router-dom";



const FamilyMembersDetailsForm = ({ formData, setAvailScholorshipProgram }) => {
    //****  Stopping field input- Enable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);

    const [applicationId, setApplicationId] = useState(null);
    const [initialFormValues, setInitialFormValues] = useState(null);

    const [saveType, setSaveType] = useState(null);

    const location = useLocation();


    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        fullName: "",
        age: "",
        relationWithStudent: "",
        business: "",
        totalAnnualIncome: "",
        hasAvailedScholarshipProgram: null,
    }

    //****Validation for Family details form*****/
    let validationSchema = Yup.object().shape({
        fullName: Yup.string().required("Full name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        age: Yup.string().matches(/^[0-9]+$/, "Must be only digits").required("Age is required"),
        relationWithStudent: Yup.string().required("Field is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        business: Yup.string().required("Field is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        totalAnnualIncome: Yup.number().required("Annual Income is required")
        //     .typeError('Annual Income must be a number')
        //     .positive('Annual Income must be greater than zero')
            .max(600000, "Annual Income can't be greater than 6 Lakhs"),
        hasAvailedScholarshipProgram: Yup.boolean().required("Field is required"),
    });

    const { handleSubmit, handleChange, values, errors, handleReset, setFieldValue } = useFormik({
        initialValues: initialFormValues === null ? defaultFormValues : initialFormValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {

            //set parent details object to send
            let familyDetailsForm = {
                fullName: values.fullName,
                age: values.age,
                relationWithStudent: values.relationWithStudent,
                business: values.business,
                totalAnnualIncome: values.totalAnnualIncome,
                hasAvailedScholarshipProgram: values.hasAvailedScholarshipProgram,
            };

            formData(familyDetailsForm);
            handleReset();
            return false;
        },
        validationSchema: validationSchema,
    });

    useEffect(async () => {

        const queryParams = new URLSearchParams(location.search);
        const applicationIdFromParams = await queryParams.get('applicationId');

        //Checking application status is incomplete & making fields undisabled
        const applicationsFromServer = await communication.getMyApplications(applicationIdFromParams);

        if (applicationsFromServer.status === "SUCCESS") {
            const applicationStatus = applicationsFromServer.userApplications[0].applicationStatus

            if (applicationStatus === "INCOMPLETE") {
                setDisabled(false);
            }

        }
    }, [])

    return (
        <div className="family_details_main">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={handleSubmit}>
                            <div className="row family_detail_form">

                                <div className="col-lg-2 col-md-4 col-12">
                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Full Name</Label>
                                    <input name="fullName" placeholder="E.g Ram Shyam Yadav" onChange={handleChange} value={values.fullName} type="text" className={`form-control ${errors.fullName ? "is-invalid" : ""}`} disabled={disabled} />
                                    <div className="invalid-feedback">{errors.fullName}</div>
                                </div>

                                <div className="col-lg-1 col-md-2 col-4 family_member_table">
                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Age</Label>
                                    <input name="age" placeholder="E.g. 32" onChange={handleChange} value={values.age} type="number" className={`form-control ${errors.age ? "is-invalid" : ""}`} disabled={disabled} />
                                    <div className="invalid-feedback">{errors.age}</div>
                                </div>

                                <div className="col-lg-2 col-md-3 col-8">
                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Relation with Student </Label>
                                    <input name="relationWithStudent" placeholder="E.g. Brother" onChange={handleChange} value={values.relationWithStudent} type="text" className={`form-control ${errors.relationWithStudent ? "is-invalid" : ""}`} disabled={disabled} />
                                    <div className="invalid-feedback">{errors.relationWithStudent}</div>
                                </div>

                                <div className="col-lg-2 col-md-3 col-7">
                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Occupation</Label>
                                    <input name="business" placeholder="E.g. Clerk" onChange={handleChange} value={values.business} type="text" className={`form-control ${errors.business ? "is-invalid" : ""}`} disabled={disabled} />
                                    <div className="invalid-feedback">{errors.business}</div>
                                </div>

                                <div className="col-lg-2 col-md-3 col-5 mt-md-2 mt-lg-0 family_member_table">
                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Total Income</Label>

                                    <input name="totalAnnualIncome" placeholder="E.g.452600" onChange={handleChange} value={values.totalAnnualIncome} type="number" className={`form-control ${errors.totalAnnualIncome ? "is-invalid" : ""}`} disabled={disabled} />
                                    <div className="invalid-feedback">{errors.totalAnnualIncome}</div>
                                </div>

                                <div className="col-lg-2 col-md-5 col-8 mt-md-2 mt-lg-0">
                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Ever availed State or Central Scholarship?</Label>
                                    <div className="row mt-1">
                                        <div className="col-6 col-lg-6 col-md-4 mt-md-3 mt-lg-0">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                            <input type="radio" onChange={() => { setFieldValue("hasAvailedScholarshipProgram", true); setAvailScholorshipProgram(true) }} checked={values.hasAvailedScholarshipProgram === null ? (values.hasAvailedScholarshipProgram) : Boolean(values.hasAvailedScholarshipProgram)} value="true" name="hasAvailedScholarshipProgramRadio" id="hasAvailedScholarshipProgramTrue" className={`form-check-input mx-2 ${errors.hasAvailedScholarshipProgram ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>

                                        <div className="col-6 col-lg-6 col-md-4 mt-md-3 mt-lg-0">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                            <input type="radio" onChange={() => { setFieldValue("hasAvailedScholarshipProgram", false); setAvailScholorshipProgram(false) }} checked={values.hasAvailedScholarshipProgram === null ? (values.hasAvailedScholarshipProgram) : Boolean(!values.hasAvailedScholarshipProgram)} value="false" name="hasAvailedScholarshipProgramRadio" id="hasAvailedScholarshipProgramFalse" className={`form-check-input mx-2 ${errors.hasAvailedScholarshipProgram ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>
                                        <div className="invalid-feedback">{errors.hasAvailedScholarshipProgram}</div>
                                    </div>
                                </div>

                                <div className="col-lg-1 col-md-1 col-4 mt-md-5 mt-lg-4 field_add_btn">
                                    <button type="button" onClick={() => { handleSubmit() }}>Add</button>
                                </div>

                            </div>

                        </form>

                    </div>
                </div>
            </div>

        </div>

    );
}
export default FamilyMembersDetailsForm;