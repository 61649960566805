import React, { useState, useEffect } from 'react';
import H4 from "../text-components/H4";
import { useHistory, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { communication } from '../communications/communication';
import LoadingForms from "../shared-components/LoadingForms";


const UserMilestone4Form = ({ changeHandler }) => {
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const history = useHistory();
    const location = useLocation();

    // spinner on loading 
    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }


    // getting by queryParams
    const queryParams = new URLSearchParams(location.search);
    const applicationId = queryParams.get('applicationId');

    const [milestoneDocument, setMilestoneDocument] = useState({});

    const CloseUserMilestone4 = () => {
        history.push("/user/doc-submission");
    }

    async function getMileStoneDocument() {
        showLoading("Loading...");
        try {
            const result = await communication.getUserMilestone(applicationId, "MILESTONE_FOUR");
            if (result?.status === "SUCCESS") {
                let data = result?.milestoneDetails[result?.milestoneDetails?.length - 1];
                setMilestoneDocument(data);
            } else {
                showModal(result?.message);
            }
            hideLoading();
            return;
        } catch (error) {
            showModal(error?.message);
            hideLoading();
            return;
        }
    }

    useEffect(() => {
        getMileStoneDocument(applicationId);
    }, []);

    const dispatch = useDispatch();

    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const downloadDocumentFromServer = async (attachment) => {
        if (attachment !== "") {
            await communication.getMilestoneDocumentfromServer(attachment);
        }
    }

    const markCompleteFour = async () => {
        showLoading("Loading...");
        try {
            const completeMarkMilestoneFour = await communication.markCompleteFour(milestoneDocument.applicationId, milestoneDocument.type);
            if (completeMarkMilestoneFour?.status === "SUCCESS") {
                history.push("/user/doc-submission");
                changeHandler();
            }
            showModal(completeMarkMilestoneFour?.message);
            hideLoading();
        } catch (error) {
            showModal(error?.message);
            hideLoading();
        }
    }

    return (
        <div className="user_milestone_main">
            {
                isLoading?.value ?
                    <LoadingForms message={isLoading?.message} />
                    :
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="user_milestone">
                                    <div className="user_milestone_header">
                                        <H4 style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Sponsorship Letter</H4>
                                    </div>
                                    {
                                        milestoneDocument?.userStatus === "NEW" && <>
                                            <div className="user_milestone_body">

                                                <div className="col-12 pe-0 mt-4">
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Status :  {milestoneDocument?.userStatus === "NEW" && "In progress"}</H4>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                        <button className="ms-4" onClick={CloseUserMilestone4}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {
                                        milestoneDocument.userStatus === "EMAIL_SENT" && <>
                                            <div className="user_milestone_body">

                                                <div className="row">
                                                    <div className="col-9">
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>  Sponsorship letter</H4>
                                                    </div>
                                                    <div className="col-3" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                        <button className="ms-4" onClick={() => downloadDocumentFromServer(milestoneDocument.sponsorshipLetterUrl)}>Preview</button>
                                                    </div>

                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                        <button className="me-3" onClick={markCompleteFour}>Acknowledge</button>
                                                        <button className="ms-4" onClick={CloseUserMilestone4}>Cancel</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                    }

                                    {
                                        milestoneDocument.userStatus === "APPROVED" && <>
                                            <div className="user_milestone_body">

                                                <div className="row">
                                                    <div className="col-9">
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Sponsorship letter sent to university and acknowledged by user. </H4>
                                                    </div>
                                                    <div className="col-3" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                        <button className="ms-4" onClick={() => downloadDocumentFromServer(milestoneDocument.sponsorshipLetterUrl)}>Preview</button>
                                                    </div>

                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                        <button className="ms-4" onClick={CloseUserMilestone4}>Cancel</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}
export default UserMilestone4Form;