import axios from "axios";

const serverDevUrl = "http://localhost:3700";
const awsDevServerUrl = "http://ec2-13-233-236-197.ap-south-1.compute.amazonaws.com:3700";
const serverProdUrl = "localhost:3700";
const ngrokUrl = "http://18a8-2405-201-1004-e0c7-b43f-5ec8-2796-a80d.ngrok.io";
const contoboUrl = "http://194.233.89.236:3700";

export const getServerUrl = () => {
    //  get node environment variable 
    const nodeEnvirnment = process.env.REACT_APP_NODE_ENV;

    if (nodeEnvirnment === "development") {
        return serverDevUrl;
    }

    if (nodeEnvirnment === "production") {
        return process.env.REACT_APP_BACKEND_SERVER_IP;
    }

    if (nodeEnvirnment === "contobo_dev") {
        // return contoboUrl;
    }
    if (nodeEnvirnment === "machine_Ip") {
        return "http://192.168.29.164:3700";
    }
    return process.env.REACT_APP_BACKEND_SERVER_IP;
}


function getJwtCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("front-office")) {
            if ("sjsadFrontOfficeToken" === cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        } else if (splitUrlArray.includes("back-office")) {
            if ("sjsadBackOfficeToken" === cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }
        else {
            /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
            if ("sjsadUserToken" === cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }
    }

    // Return null if not found
    return null;
}


export const applicationCommunication = {
    getPersonalDetailsForUserApplication: (applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/application/get-personal-details-form`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId }));
        })
    },

    // uploadPersonalDetailsForm: (personalDetailsForm, applicationId, saveType) => {
    //     return new Promise((resolve, reject) => {
    //         const xhr = new XMLHttpRequest();
    //         xhr.open("post", `${getServerUrl()}/user/application/upload-personal-details?applicationId=${applicationId}&formName=personalDetailsForm`, true);
    //         xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    //         xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
    //         xhr.onload = () => {
    //             const json = xhr.responseText;
    //             resolve(JSON.parse(json));
    //         };
    //         xhr.send(JSON.stringify({ uploadType: saveType, personalDetailsForm: personalDetailsForm, applicationId: applicationId }));
    //     });
    // },

    getParentDetailsForUserApplication: (applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/application/get-parent-details-form`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId }));
        })
    },

    // uploadParentDetailsForm: (parentDetailsForm, applicationId, saveType) => {
    //     return new Promise((resolve, reject) => {
    //         const xhr = new XMLHttpRequest();
    //         xhr.open("post", `${getServerUrl()}/user/application/upload-parent-details-form?applicationId=${applicationId}&formName=parentDetailsForm`, true);
    //         xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    //         xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
    //         xhr.onload = () => {
    //             const json = xhr.responseText;
    //             resolve(JSON.parse(json));
    //         };
    //         xhr.send(JSON.stringify({ uploadType: saveType, parentDetailsForm: parentDetailsForm, applicationId: applicationId }));
    //     });
    // },

    // get family Income details form
    getFamilyIncomeDetailsFormUserApplication: (applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/application/get-family-income-details`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId }));
        })
    },
    // upload family Income details form
    // uploadFamilyIncomeDetailsForm: (familyIncomeDetailsForm, applicationId, saveType, formData) => {
    //     return new Promise((resolve, reject) => {
    //         const xhr = new XMLHttpRequest();
    //         xhr.open("post", `${getServerUrl()}/user/application/upload-family-income-details?applicationId=${applicationId}&formName=familyIncomeDetailsForm`, true);
    //         xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    //         xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
    //         xhr.onload = () => {
    //             const json = xhr.responseText;
    //             resolve(JSON.parse(json));
    //         };
    //         xhr.send(JSON.stringify({ uploadType: saveType, familyIncomeDetailsForm: familyIncomeDetailsForm, applicationId: applicationId, formData: formData }));
    //     });
    // },
    uploadFamilyIncomeDetailsForm: (formData, applicationId, saveType) => {

        return axios.post(`${getServerUrl()}/user/application/upload-family-income-details?applicationId=${applicationId}&formName=familyIncomeDetailsForm`, formData, {
            headers: {
                "Content-Type": "multipart/form-data,boundary=MyBoundary",
                "Authorization": `Bearer ${getJwtCookie()}`,
                "applicationid": applicationId,
                "uploadtype": saveType
            }
        });

        // return new Promise((resolve, reject) => {
        //     const xhr = new XMLHttpRequest();
        //     xhr.open("post", `${getServerUrl()}/user/application/upload-family-income-details?applicationId=${applicationId}&formName=familyIncomeDetailsForm`, true);
        //     xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        //     xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
        //     xhr.onload = () => {
        //         const json = xhr.responseText;
        //         resolve(JSON.parse(json));
        //     };
        //     xhr.send(JSON.stringify({ uploadType: saveType, familyIncomeDetailsForm: familyIncomeDetailsForm, applicationId: applicationId, formData: formData }));
        // });
    },

    // get family details form
    getFamilyDetailsForm: (applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/application/get-family-details-form`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId }));
        })
    },
    // upload family details form
    uploadFamilyDetailsForm: (familyDetailsForm, applicationId, saveType) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/application/upload-family-details?applicationId=${applicationId}&formName=familyDetailsForm`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ uploadType: saveType, familyDetailsForm: familyDetailsForm, applicationId: applicationId }));
        });
    },

    // get College Admission details form
    getCollegeAdmissionDetailsForm: (applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/application/get-college-admission-details-form`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId }));
        })
    },
    // upload College Admission details form
    uploadCollegeAdmissionDetailsForm: (collegeAdmissionDetailsForm, applicationId, saveType) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/application/upload-college-admission-details?formName=collegeAdmissionDetailsForm&applicationId=${applicationId}`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ uploadType: saveType, collegeAdmissionDetailsForm: collegeAdmissionDetailsForm, applicationId: applicationId }));
        });
    },

    // get education expenses details form
    getEducationExpensesDetailsForm: (applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/application/get-education-expenses-details-form`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId }));
        })
    },
    // upload education expenses detail form
    uploadEducationExpensesDetailsForm: (educationExpensesDetailsForm, applicationId, saveType) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/application/upload-education-expenses-details?applicationId=${applicationId}&formName=educationExpensesDetailsForm`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ uploadType: saveType, educationExpensesDetailsForm: educationExpensesDetailsForm, applicationId: applicationId }));
        });
    },

    getDocumentsSubmission: (applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/application/get-documents-form`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId }));
        })
    },

    uploadDocument: (fileToUpload, documentName, documentType, applicationId) => {
        return axios.post(`${getServerUrl()}/user/application/upload-document?applicationId=${applicationId}&formName=documentsForm&documentName=${documentName}&documentType=${documentType}`, fileToUpload, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getJwtCookie()}`
            }
        });
    },

    submitApplicationForm: (applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/application/submit-user-application?applicationId=${applicationId}`);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId }));
        });
    },
    getUserAuthenticateByJWT: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-user-authenticate-by-jwt`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getJwtCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                // Converting response from json to js objects
                const response = JSON.parse(json);
                // Attaching jwt from cookie itself as it is validated by the server.
                response.jwt = getJwtCookie();
                resolve(response);
            };
            xhr.send();
        })
    },
    uploadPersonalDetailsForm: (formData, data) => {

        return axios.post(`${getServerUrl()}/user/application/upload-personal-details?applicationId=${data.applicationId}&formName=personalDetailsForm`, formData, {
            headers: {
                "Content-Type": "multipart/form-data,boundary=MyBoundary",
                "Authorization": `Bearer ${getJwtCookie()}`,
                "applicationid": data.applicationId,
                "uploadtype": data.uploadType,
                "employmentcertificate": data.employmentCertificate,
                'personaldetailsform': data.dataToSend
            }
        });
    },
    uploadParentDetailsForm: (formData, data) => {

        return axios.post(`${getServerUrl()}/user/application/upload-parent-details-form?applicationId=${data.applicationId}&formName=parentDetailsForm`, formData, {
            headers: {
                "Content-Type": "multipart/form-data,boundary=MyBoundary",
                "Authorization": `Bearer ${getJwtCookie()}`,
                "applicationid": data.applicationId,
                "uploadtype": data.uploadType,
                'parentdetailsform': data.parentDetailsForm,
                'familyincomedetails': data.familyIncomeDetails,
                'familydetails': data.familyDetails,
            }
        });
    },

    getUploadQualificationDetails: (applicationId) =>{
        return axios.post(`${getServerUrl()}/user/application/get-education-details-form`, {applicationId}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getJwtCookie()}`,
               
            }
        })
    },
 

    uploadQualificationDetails: (formData, applicationId, uploadType) => {
        
        return axios.post(`${getServerUrl()}/user/application/upload-education-details?applicationId=${applicationId}&formName=educationDetailsForm`, formData, {
            headers: {
                "Content-Type": "multipart/form-data,boundary=MyBoundary",
                "Authorization": `Bearer ${getJwtCookie()}`,
                "applicationid": applicationId,
                "uploadtype": uploadType,
            }
        });
    },

}
