import React, { useEffect, useState } from "react";
import { communication } from "../communications/communication";
// import H4 from "../text-components/H4";
import LoadingForms from "../shared-components/LoadingForms";
// import fileDownload from 'js-file-download';

const DownloadsInfoMobile = () => {

  const [downloads, setDownloads] = useState([]);

  const [isLoading, setIsLoading] = useState({ message: "", value: false });


  //spinner show and hide function declaration
  function showLoading(message) {
    setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
  }

  function hideLoading() {
    setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
  }
  useEffect(async () => {
    showLoading("Loading...");

    const downloadDocumentData = await communication.getDownloadDocuments("PUBLIC");
    if (downloadDocumentData.status === "SUCCESS") {
      let data = downloadDocumentData.documents;
      setDownloads((value) => { return [...value, ...data] });
    }
    hideLoading();
  }, []);

  async function downloadDocument(id, attachment) {
    // if the attachment is empty or not
    let attachmentSplit = attachment.split("/");
    if (id !== "") {
      const getDownloadDocuments = await communication.getDownloadPdf(id);
      // fileDownload(getDownloadDocuments, attachmentSplit[1]);
      return getDownloadDocuments;
    }
  }


  return (
    <section className="downloads_tab_info_mobile">
      {
        isLoading.value ?
          <LoadingForms message={isLoading.message} />
          : <>          {
            downloads.map((download, index) => {
              const { _id, subject, description, attachment } = download;
              return (
                <div className="row mb-2" key={index}>
                  <div className="col-8">
                    <ul>
                      <li>{subject} - {description}</li>
                    </ul>
                  </div>
                  <div className="col-4 d-flex justify-content-center align-item-center">
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#FFFFFF", width: "100px", height: "35px", border: "1px solid #C4C4C4", borderRadius: "50px", boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}><span onClick={() => downloadDocument(_id, attachment)} style={{ fontSize: "16px", color: "#000" }}>Download</span></div>
                  </div>
                </div>
              );
            })
          }
          </>

      }
    </section>
  );
}

export default DownloadsInfoMobile;