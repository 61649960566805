import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import HomeScreenNav from "../common/HomeScreenNav";
import H1 from "../text-components/H1";
import H3 from "../text-components/H3";
import H4 from "../text-components/H4";
import { communication } from "../communications/communication";
import LoadingForms from "../shared-components/LoadingForms";

const ForgetPassword = () => {
   const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
   const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
   const dispatch = useDispatch();

   // spinner on loading 
   const [isLoading, setIsLoading] = useState({ message: "", value: false });

   //Alert Modal react-redux
   function showModal(text) {
      dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
   }
   //spinner show and hide function declaration
   function showLoading(message) {
      setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
   }

   function hideLoading() {
      setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
   }

   const Id = useRef();
   const emailOrPhoneNumber = useRef();
   const history = useHistory();


   //Sending reset password link using UserId
   const resetPasswordHandler = async () => {
      showLoading("Loading ...");
      const userId = Id.current.value;

      if (userId === '' || userId === undefined) {
         showModal("Please Enter your userId");
         hideLoading();
         return;
      }

      const getResponseFromServer = await communication.forgetUserPassword(userId);


      if (getResponseFromServer.status === "SUCCESS") {
         showModal(getResponseFromServer.message);
         hideLoading();
      } else {
         showModal(getResponseFromServer.message);
      }

      hideLoading();

   }


   //sending UserId using user phone number or email
   const sendUserIdToUser = async () => {
      showLoading("Loading ...");

      const phoneNumberOremail = emailOrPhoneNumber.current.value;
      console.log(phoneNumberOremail)
      if (phoneNumberOremail === "" || phoneNumberOremail === null) {
         showModal("Enter your Email");
         hideLoading();
         return;
      }
      const getResponseFromServer = await communication.sendUserIdToForgetedUser(phoneNumberOremail);

      if (getResponseFromServer.status === "SUCCESS") {
         showModal(getResponseFromServer.message);
         hideLoading();
         history.push("/login");
      } else {
         showModal(getResponseFromServer.message);
         hideLoading();
      }

   }

   return (
      <div style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
         {/* <HomeScreenNav /> */}
         <section className="forget_password" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
            {isLoading.value ?
               <LoadingForms message={isLoading.message} />
               : <div className="row m-0">
                  <div className="col-12">
                     <div className="forget_password_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000", border: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }}>
                        <H1 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${30 * fontSizeMultiplier / 100}px` }}>Forgot Password</H1>
                        <div className="input_field_div1">
                           <label style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}><H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">User ID</H3></label>
                           <div className="input_field1">
                              <input className="form-control" type="text" ref={Id} required />
                              <i className="fa fa-user" aria-hidden="true"></i>
                           </div>
                           <button style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }} className="button" onClick={resetPasswordHandler}>Reset Password</button>
                        </div>
                        <div className="input_field_div2">
                           <label style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}><H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">Enter your email </H3></label>
                           <div className="input_field2">
                              <input className="form-control" type="text" ref={emailOrPhoneNumber} />
                           </div>
                           <button style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }} className="button" onClick={sendUserIdToUser}>Send ID</button>
                        </div>

                        <div className="newreg_or_forgot">
                           <button onClick={() => history.push('/register')} style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }}><H4 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">New Registration?</H4></button>
                           <button onClick={() => history.push('/login')} style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }}><H4 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">Sign In</H4></button>
                        </div>
                     </div>
                  </div>
               </div>
            }

         </section>
      </div>
   );

}

export default ForgetPassword;