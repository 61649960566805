import React from "react";
import AmountDisbursed from "../main-components/AmountDisbursed";
import CourseWiseApplicant from "../main-components/CourseWiseApplicant";
import YearlyApplications from "../main-components/YearlyApplications";
import YearlyCourseCompletion from "../main-components/YearlyCourseCompletion";
import DistrictWiseApplication from "../main-components/DistrictWiseApplication";
import CountryWiseApplication from "../main-components/CountryWiseApplication";


const StatisticSection = ({ hideStatistic, graph }) => {
    return (
        <div className="statistic_section">
            <div className="container-fluid">
                {/* <div className="row">
                    <div className="col-12">

                        <span onClick={() => { hideStatistic() }}>x</span>

                        <div className="statistic_img mt-5">
                            <YearlyApplications />
                        </div>
                        <div className="statistic_img mt-5">
                            <AmountDisbursed />
                        </div>
                        <div className="statistic_img mt-5">
                            <CourseWiseApplicant />
                        </div>
                        <div className="statistic_img mt-5">
                            <DistrictWiseApplication />
                        </div>
                        <div className="statistic_img mt-5">
                            <CountryWiseApplication />
                        </div>
                        <div className="statistic_img mt-5">
                            <YearlyCourseCompletion />
                        </div>
                    </div>
                </div> */}

                <div className="container">
                    <div className="row">
                        <div className="col-12 mt-5">
                            <span onClick={() => { hideStatistic() }}>x</span>

                        </div>


                        <div className="col-12">
                            <YearlyApplications graph={graph.find((value) => { if (value.type === "YEARLYAPPLICATION") return value })} />
                        </div>

                        <div className="col-12">
                            <AmountDisbursed graph={graph.find((value) => { if (value.type === "AMOUNT_DISBURSED") return value })} />
                        </div>

                        <div className="col-12">
                            <CourseWiseApplicant graph={graph.find((value) => { if (value.type === "COURSE_WISE_APPLICATION") return value })} />
                        </div>

                        <div className="col-12">
                            <YearlyCourseCompletion graph={graph.find((value) => { if (value.type === "YEARLYCOURSEAPPLICATION") return value })} />
                        </div>

                        <div className="col-12">
                            <DistrictWiseApplication graph={graph.find((value) => { if (value.type === "DISTRICT_WISE_APPLICATION") return value })} />
                        </div>

                        <div className="col-12">
                            <CountryWiseApplication graph={graph.find((value) => { if (value.type === "COUNTRYWISEAPPLICATION") return value })} />
                        </div>

                    </div>


                </div>

            </div>
        </div>
    );
}
export default StatisticSection;