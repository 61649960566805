import { useSelector } from "react-redux";
import React from "react";

const P = ({ whiteThemeColor, darkThemeColor, children }) => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);

    return (
        <p style={{ fontSize: `${14 * fontSizeMultiplier / 100}px`, marginBottom: "0", fontFamily: 'Open Sans', fontWeight: "600", color: `${darkWhiteThemeToggle === "WHITE" ? whiteThemeColor : darkThemeColor}` }}>{children}</p>
    );
}

export default P;