import React , { useState } from "react";
import H5 from "../text-components/H5";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { communication } from "../communications/communication";
import { useDispatch, useSelector } from "react-redux";



const UploadRequiredDocuments = ({ getMileStoneDocument, milestoneId, applicationId, setRequiredDocumentsForm }) => {

    const dispatch = useDispatch();

    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }


    const defaultFormValues = {
        marksheetReport: "",
        hostelFeesReport: "",
        attendenceReport: "",
        foodExpenseReport: ""
    }

    function onFileSelectForSem(event) {
        event.preventDefault();
        const elementName = event.target.name;
        const fileName = event.target.files[0];
        setFieldValue(elementName, fileName);
    }


    //****Validation for Designation Create form*****/
    let yupValidationSchema = Yup.object().shape({
        // marksheetReport: Yup.mixed().required("Please select file."),
        // hostelFeesReport: Yup.mixed().required("Please select file."),
        // attendenceReport: Yup.mixed().required("Please select file."),
        // foodExpenseReport: Yup.mixed().required("Please select file."),
    });

    const { handleSubmit,  values, errors, handleReset, setFieldValue } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit

        onSubmit: async (values) => {
            try {

                const formData = new FormData();

                console.log("values", values)

                let IsFileUploaded = false;

                if (!!values.marksheetReport === true) {

                    formData.append("marksheetReport", values.marksheetReport, "marksheetReport");
                    IsFileUploaded = true;
                }

                if (!!values.hostelFeesReport === true) {
                    formData.append("hostelFeesReport", values.hostelFeesReport, "hostelFeesReport");
                    IsFileUploaded = true;
                }

                if (!!values.attendenceReport) {
                    formData.append("attendenceReport", values.attendenceReport, "attendenceReport");
                    IsFileUploaded = true;
                }

                if (!!values.foodExpenseReport) {
                    formData.append("foodExpenseReport", values.foodExpenseReport, "foodExpenseReport");
                    IsFileUploaded = true;
                }
                // Send values to the server
                const data = {
                    applicationId: applicationId,
                    milestoneId: milestoneId,
                    formData,
                    IsFileUploaded
                }

                console.log("formData", formData);

                const updateRequiredDocumentResponse = await communication.updateMilestoneRequiredDocuments(data);

                // Handle response from the server
                if (updateRequiredDocumentResponse?.data?.status === "SUCCESS") {
                    showModal(updateRequiredDocumentResponse?.data?.message);
                }
                else {
                    showModal(updateRequiredDocumentResponse?.data?.message);
                }

                getMileStoneDocument();
                handleReset();
                return;
            }
            catch (error) {
                showModal(error?.message);
                return;
            }

        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: yupValidationSchema,
    });



    return (
        <div className="marks_detail_section" style={{ marginTop: "0" }}>
            <form onSubmit={handleSubmit}>
                <span style={{ fontSize: "13px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & file size must be less than 2mb.</b></span>
                <div className="row mb-4 mt-4">
                    <div className="col-6">
                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Marksheet Report :</H5>
                    </div>
                    <div className="col-6">
                        <input type="file" name="marksheetReport" onChange={(e) => { onFileSelectForSem(e); }} value={values?.marksheetReport?.filename} className={`form-control form-control-sm ${errors.marksheetReport ? "is-invalid" : ""}`} />
                        <div className="invalid-feedback">{errors?.marksheetReport}</div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-6">
                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Hostel Fees Report :</H5>
                    </div>
                    <div className="col-6">
                        <input type="file" name="hostelFeesReport" onChange={(e) => { onFileSelectForSem(e); }} value={values?.hostelFeesReport?.filename} className={`form-control form-control-sm ${errors.hostelFeesReport ? "is-invalid" : ""}`} />
                        <div className="invalid-feedback">{errors?.hostelFeesReport}</div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-6">
                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Attendance Report :</H5>
                    </div>
                    <div className="col-6">
                        <input type="file" name="attendenceReport" onChange={(e) => { onFileSelectForSem(e); }} value={values?.attendenceReport?.filename} className={`form-control form-control-sm ${errors.attendenceReport ? "is-invalid" : ""}`} />
                        <div className="invalid-feedback">{errors?.attendenceReport}</div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-6">
                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Foods Expense Report :</H5>
                    </div>
                    <div className="col-6">
                        <input type="file" name="foodExpenseReport" onChange={(e) => { onFileSelectForSem(e); }} value={values?.foodExpenseReport?.filename} className={`form-control form-control-sm ${errors.foodExpenseReport ? "is-invalid" : ""}`} />
                        <div className="invalid-feedback">{errors?.foodExpenseReport}</div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12 submit_marks_button">
                        <button type="submit">Update</button>
                        <button className="ms-3" onClick={() => setRequiredDocumentsForm(false)}>Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default UploadRequiredDocuments;