import React, { useEffect, useState } from 'react';
import LoadingForms from "../../shared-components/LoadingForms";
import { frontOfficeCommunication } from "../../communications/front-office-communication";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MinutesOfMeetingFirst from '../MinutesOfMeetingFirst';
import MinutesOfMeetingBoxFirst from '../MinutesOfMeetingBoxFirst';
import H5 from '../../text-components/H5';
import ReactPaginate from "react-paginate";

const ApplicationVerification = () => {

    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [filteredApplication, setFilteredApplication] = useState([])
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;

    const [showMomSecond, setShowMomSecond] = useState(false);
    const userAccessType = useSelector((state) => state.isOfficeUserAuthenticated?.officeUser?.access);

    const history = useHistory();

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchAcknowledgedApplicationsBySearch(searchValue, selectedPage + 1);
        } else {
            fetchAcknowledgedApplications(selectedPage + 1);
        }
    }

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchAcknowledgedApplicationsBySearch(searchString, currentPage + 1);
        } else {
            const acknowledgedFromServer = await frontOfficeCommunication.getAcknowledgedApplications();
            if (acknowledgedFromServer.status === "SUCCESS") {
                setFilteredApplication(acknowledgedFromServer.applications);
                setPageCount(acknowledgedFromServer?.totalPages);
            }
            else if (acknowledgedFromServer.status === "UNAUTHORIZED_ACCESS") {
                showModal(acknowledgedFromServer?.message);
                history.push(`/front-office/login`);
            }
            else {
                setFilteredApplication([]);
                setPageCount(0);
            }
        }
    }
    
    

    //get Acknowledged Application By Search
    const fetchAcknowledgedApplicationsBySearch = async (searchString, page) => {
        try {
            
            const responseFromServer = await frontOfficeCommunication.getAcknowledgedApplicationsBySearch(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                // console.log(responseFromServer?.data)
                setFilteredApplication(responseFromServer?.data?.applications);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                showModal(responseFromServer?.data?.message);
                history.push("/front-office/login");
            } else {
                setFilteredApplication([]);
                setPageCount(0);
            }
        } catch (error) {
            showModal(error?.message);
        }
    }
    // First Minutes of meeting box sho hide
    function showMomModal() {
        setShowMomSecond(true);
    }
    function hideMomModal() {
        setShowMomSecond(false);
    }


    const goToApplication = async (applicationId, frontOfficeStatus) => {
        if (frontOfficeStatus === "REJECTED") {
            showModal("You can only access Acknowledged application");
            return;
        }

        showLoading("Loading...");
        const accessApplicationFromServer = await frontOfficeCommunication.accessToApplication(applicationId);
        if (accessApplicationFromServer.status === "APPLICATION_AVAILABLE") {
            history.push(`/front-office/dashboard/application/?applicationId=${applicationId}&action=APPLICATION_VERIFICATION`);
        }
        hideLoading();
        showModal(accessApplicationFromServer.message);
    }

    //get Acknowledged Application on Initial Load
    const fetchAcknowledgedApplications = async (page) => {
        try {
            let fullUrl = window.location.href;
            let splitUrlArray = fullUrl.split('/');
            if (splitUrlArray.includes("application-verification")) {
                dispatch({ type: "ACTIVE_TAB", payload: "APPLICATION_VERIFICATION" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_TAB" });
            }
            showLoading("Loading...");
            const acknowledgedFromServer = await frontOfficeCommunication.getAcknowledgedApplications(page);
            // console.log("inside code",acknowledgedFromServer);
            if (acknowledgedFromServer.status === "SUCCESS") {
                setFilteredApplication(acknowledgedFromServer.applications);
                setPageCount(acknowledgedFromServer?.totalPages);
            }
            else if (acknowledgedFromServer.status === "UNAUTHORIZED_ACCESS") {
                showModal(acknowledgedFromServer?.message);
                history.push(`/front-office/login`);
            }
            else {
                setFilteredApplication([]);
                setPageCount(0);
            }
            hideLoading();
        } catch (error) {
            showModal(error?.message);
        }
    }
    useEffect(() => {
        fetchAcknowledgedApplications();
    }, []);


    // Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    return (
        <>

            <section className="dashboard_background" >

                <div className='container'>
                    {isLoading.value ?
                        <LoadingForms message={isLoading.message} />
                        :

                        <div className="dashboard_Front_office">
                            <div className="row search_wrapper">
                                <div className="col-lg-4 col-md-4 ps-0">
                                    <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Application ID, Name, University Name..." />
                                </div>
                                {userAccessType === "READ" ?
                                    <></>
                                    :
                                    <div className="col-lg-3 col-md-4">
                                        <MinutesOfMeetingFirst showMomFirst={showMomModal} />
                                    </div>
                                }
                                <div className="col-lg-5 col-md-4 pe-0 pagination_outer_wrapper">
                                    <div className="pagination_inner_wrapper">
                                        {pageCount > 1 &&
                                            <ReactPaginate
                                                previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                                nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                forcePage={currentPage}
                                                activeClassName={"active"} />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/*-----Grievance card header section---*/}
                            <div className="dashboard_view_data_heading">
                                <div className="row m-0">
                                    <div className="col-1 p-0 view_dashboard">
                                        <span><H5>Serial No.</H5></span>
                                    </div>
                                    <div className="col-3 p-0 view_dashboard">
                                        <span><H5>Application Id</H5></span>
                                    </div>
                                    <div className="col-3 p-0 view_dashboard">
                                        <span><H5>Date</H5></span>
                                    </div>
                                    <div className="col-2 p-0 view_dashboard">
                                        <span><H5>Status</H5></span>
                                    </div>
                                    <div className="col-3 p-0 ">
                                        <span><H5>Comment</H5></span>
                                    </div>
                                </div>
                            </div>
                            {
                                filteredApplication?.length > 0 ?
                                    <div>

                                        {filteredApplication?.map((application, index) => {
                                            const { _id, shortApplicationId, frontOfficeStatus, createdDate, frontOfficeApplicationComment } = application;
                                            return (
                                                <div key={_id}>
                                                    <div className="dashboard_view_data_main" key={_id}>
                                                        <div className="dashboard_view_data">
                                                            <div className="row m-0">
                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {index + 1}
                                                                        </H5>
                                                                    </span>
                                                                </div>
                                                                <div className="col-3 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            <a onClick={() => { goToApplication(_id, frontOfficeStatus) }}>
                                                                                {shortApplicationId}
                                                                            </a>
                                                                        </H5>
                                                                    </span>
                                                                </div>
                                                                <div className="col-3 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {new Date(createdDate).getDate()}-{new Date(createdDate).getMonth() + 1}-{new Date(createdDate).getFullYear()}
                                                                        </H5>
                                                                    </span>
                                                                </div>

                                                                <div className="col-2 p-0 view_dashboard text_align">
                                                                    <span >
                                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {frontOfficeStatus}
                                                                        </H5>
                                                                    </span>
                                                                </div>
                                                                <div className="col-3 p-0  text_align">
                                                                    <span >
                                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {
                                                                                frontOfficeApplicationComment?.length > 0 ?
                                                                                    !!frontOfficeApplicationComment[frontOfficeApplicationComment?.length - 1] ?
                                                                                        frontOfficeApplicationComment[frontOfficeApplicationComment?.length - 1]
                                                                                        : "No comment"
                                                                                    :
                                                                                    "No comment"
                                                                            }
                                                                        </H5>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    :
                                    <div className="text-center mt-5">
                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Records not available</H5>
                                    </div>
                            }

                        </div>
                    }
                </div>
                {
                    showMomSecond ?
                        <MinutesOfMeetingBoxFirst hideMomFirst={hideMomModal} />
                        : <span></span>
                }

            </section>
        </>

    )
};

export default ApplicationVerification;
