import React, { useEffect, useState } from 'react'
import H5 from '../text-components/H5'
import { useDispatch } from "react-redux";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { communication } from '../communications/communication';
import { removeUnderscoreFromText } from '../utlis/helper';
import { backOfficeCommunication } from '../communications/back-office-communication';

const UpdateUserMarksheetDetail = ({ getMileStoneDocument, applicationId, semesterId, setShowUpdateMarksheetDetailForm }) => {
    const [addedSubjects, setAddedSubjects] = useState([]);
    const [semesterMarks, setSemesterMarks] = useState({});

    const dispatch = useDispatch();

    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //Fetch user Semester List by userId
    const fetchUserSemesterMarks = async () => {
        try {
            const responseFromServer = await communication.getUserSemesterMarksBySemesterId(semesterId);
            if (responseFromServer.status === "SUCCESS") {
                setSemesterMarks(responseFromServer?.userSemMarks);
                setAddedSubjects(responseFromServer?.userSemMarks?.totalMarks);
            } else {
                showModal(responseFromServer.message);
            }
        } catch (error) {
            showModal(error.message);
        }
    }

    useEffect(() => {
        fetchUserSemesterMarks();
    }, []);


    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        subject: "",
        totalMarks: "",
        obtainedMarks: "",
    }

    //****Validation for Designation Create form*****/
    let yupValidationSchema = Yup.object().shape({
        subject: Yup.string().required("Subject Name is required"),
        totalMarks: Yup.string().required("Total Marks is required"),
        obtainedMarks: Yup.string().required("Obtained Marks is required")
        // .test("test", "obtained marks exceeds total marks", function (marks) {
        //     const { totalMarks } = this.parent;
        //     return Number(marks) <= Number(totalMarks);
        // }),
    });

    const { handleSubmit, handleChange, values, errors, handleReset,setFieldValue } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit

        onSubmit: async (values) => {
            if (values.subject === addedSubjects?.subject) {
                alert("Subject is already exist");
                return false;
            }
            let dataToAdd = {
                subject: values.subject,
                totalMarks: values.totalMarks,
                obtainedMarks: values.obtainedMarks
            }
            setAddedSubjects([...addedSubjects, { subject: dataToAdd?.subject, totalMarks: dataToAdd?.totalMarks, obtainedMarks: dataToAdd?.obtainedMarks }]);
            handleReset();
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: yupValidationSchema,
    });

    // Delete added subjects from table
    const deleteSubject = (index) => {
        const updatedSubjectList = addedSubjects.filter((elem, id) => {
            return index !== id;
        })
        setAddedSubjects(updatedSubjectList);
    }

    const submitDetails = async () => {
        try {

            let dataToSend = {
                semesterId,
                semester: semesterMarks?.semester,
                typeOfMark: semesterMarks?.typeOfMark,
                totalMarks: addedSubjects,
                applicationId
            }

            const serverResponse = await communication.updateSemMarks(dataToSend);

            if (serverResponse?.data?.status === "SUCCESS") {
                setShowUpdateMarksheetDetailForm(false);
                showModal(serverResponse?.data?.message);
                getMileStoneDocument();
            } else {
                showModal(serverResponse?.data?.message);
            }
        }
        catch (error) {
            showModal(error?.message);
        }
    }

    return (
        <div className="marks_detail_section">

            <div>
                <div className="row mb-3">
                    <div className="col-6">
                        <select name="semester" className="form-select text-center" disabled>
                            <option value="">{removeUnderscoreFromText(semesterMarks?.semester)}</option>
                        </select>
                    </div>
                    <div className="col-6">
                        <input type="text" name="typeOfMark" value={semesterMarks?.typeOfMark} className="form-control" />
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="row mb-2">
                        <div className="col-5 text-center">
                            <H5>Subject Name</H5>
                            <input type="text" name="subject" value={values.subject} onChange={handleChange} className={`form-control ${errors.subject ? "is-invalid" : ""}`} placeholder="Enter Subject Name" />
                            <div className="invalid-feedback">{errors.subject}</div>
                        </div>
                        <div className="col-3 text-center">
                            <H5>Total Marks</H5>
                            <input name="totalMarks" type={semesterMarks?.typeOfMark === "Grade" ? "text" : "number"} value={values.totalMarks} onChange={handleChange} className={`form-control ${errors.totalMarks ? "is-invalid" : ""}`} placeholder="Enter Total Marks" />
                            <div className="invalid-feedback">{errors.totalMarks}</div>
                        </div>
                        <div className="col-3 text-center">
                            <H5>Obtained Marks</H5>
                            <input name="obtainedMarks" type={semesterMarks?.typeOfMark === "Grade" ? "text" : "number"} value={values.obtainedMarks} onChange={handleChange} className={`form-control ${errors.obtainedMarks ? "is-invalid" : ""}`} placeholder="Enter Obtained Marks" />
                            <div className="invalid-feedback">{errors.obtainedMarks}</div>
                        </div>
                        <div className="col-1 ps-0 add_button">
                            <button type="submit">Add</button>
                        </div>
                    </div>
                </form>
                {/*-----Subject table----*/}
                {addedSubjects?.map((subject, index) => (
                    <div className="row mb-2" key={index}>
                        <div className="col-5">
                            <input type="text" value={subject?.subject} name="subject" className="form-control" disabled />
                        </div>
                        <div className="col-3">
                            <input type="text" value={subject?.totalMarks} name="totalMarks" className="form-control" disabled />
                        </div>
                        <div className="col-3">
                            <input type="text" value={subject?.obtainedMarks} name="obtainedMarks" className="form-control" disabled />
                        </div>
                        <div className="col-1 delete_button">
                            <i className="fa fa-trash-o" aria-hidden="true" onClick={() => deleteSubject(index)}></i>
                        </div>
                    </div>
                ))}

                <div className="row mt-4">
                    <div className="col-12 submit_marks_button">
                        <button onClick={() => submitDetails()}>Update</button>
                        <button className="ms-3" onClick={() => setShowUpdateMarksheetDetailForm(false)}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateUserMarksheetDetail