import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import H5 from "../../text-components/H5";
import H3 from "../../text-components/H3";
import H7 from "../../text-components/H7";


const CommitteeMaintenanceCreate = () => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const [roleData, setRoleData] = useState([]);

    const [showTabError, setShowTabError] = useState(true);

    const [userIds, setUserIds] = useState([]);

    const [allUsersMaintenanceData, setAllUsersMaintenanceData] = useState([]);


    //****  Input/Select filed disable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);

    const history = useHistory();


    // Roles Data fetched from the server 
    useEffect(async () => {
        showLoading("Loading...");
        dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "COMMITTEE_MAINTENANCE_VIEW" });
        const rolesFromServer = await adminCommunication.fetchAllRoles();

        if (rolesFromServer.status === "SUCCESS") {
            let rolesArray = rolesFromServer.roles;
            setRoleData(value => {
                return [...value, ...rolesArray]
            });
        } else {
            showModal(rolesFromServer.message);
        }

        const usersFromServer = await adminCommunication.fetchAllMembers();
        // console.log("inside code",usersFromServer);
        if (usersFromServer.status === "SUCCESS") {
            let usersArray = usersFromServer.users;
            setAllUsersMaintenanceData(value => {
                return [...value, ...usersArray]
            });
        } else {
            showModal(usersFromServer.message);
        }
        
        hideLoading();
    }, []);


    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const [isLoading, setIsLoading] = useState({ message: "", value: false });


    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }


    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        name: "",
        role: "",
    }

    //****Validation for Designation Create form*****/
    const validationSchema = Yup.object().shape({
        name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Committee name is required"),
        role: Yup.string().required("Select Role"),
        // userId: Yup.array().required("Select minimum one"),

    });




    // Destructuring useFormik to get functions to control aspects of form
    // What does Formik do?
    // 1. Handling form events like submit, errors, change in value
    // 2. Fetching values from the form 
    // 3. Support for a validation library on the data fetched. We are using Yup library for validations. 
    // 4. Reset the form
    // 5. Pass initial values to the form
    // 6. Handle errors thrown by the Yup (or any validation library).
    // 7. Reinitialize form values
    // 8. Perform async operations
    const { handleSubmit, handleChange, values, errors, handleReset } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit
        onSubmit: async (values) => {
            // Show loading 
            showLoading("Loading...");

            // let members = [];
            // membersFormData.map((user) => {
            //     members.push(user.id);
            // })

            // Send values to the server
            let committeeData = {
                name: values.name,
                role: values.role,
                members: userIds,
            }

            let showCheckError = checkBoxError(userIds);

            if (showCheckError == true) {

                const committeeCreationResponse = await adminCommunication.createAllCommittee(committeeData);

                // Handle response from the server
                if (committeeCreationResponse.status === "SUCCESS") {
                    handleReset();
                    history.push('/admin/dashboard/committee-maintenance-view');
                }

                // Show response message from server in modal dialog
                showModal(committeeCreationResponse.message);
            }
            else {
                showModal("Please select at least one member");
            }

            // Hide spinner 
            hideLoading();
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: validationSchema,
    });

    const checkBoxError = (userIds) => {
        if (userIds.length == 0) {
            return false;
        }
        else {
            return true;
        }
    }

    // set ids in to state
    const handleOnChange = (e) => {
        let checked = e.target.checked;
        let id = e.target.value;
        if (checked) {
            setUserIds(() => [...userIds, id]);
        } else {
            setUserIds(
                userIds.filter((Id) => Id !== id)
            );
        }
        return;
    }

    return (

        <div >
            <section className="create_form create_designation" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000", border: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }}>
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :


                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <H3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Create Committee</H3>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Committee Name</H5>
                            </label>
                            <div className="col-sm-7">
                                <input type="text" name="name" onChange={handleChange} value={values.name} className={`form-control ${errors.name ? "is-invalid" : ""}`} />
                                <div className="invalid-feedback">{errors.name}</div>
                            </div>
                        </div>


                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Role</H5>
                            </label>
                            <div className="col-sm-7">
                                <select name="role" onChange={handleChange} value={values.role} className={`form-select ${errors.role ? "is-invalid" : ""}`}>
                                    <option value={""} selected>Select</option>
                                    {roleData?.map((roles, index) => {
                                        const { _id, name } = roles;
                                        return (
                                            <option key={_id} value={_id}>{name}</option>
                                        );
                                    })}
                                </select>
                                <div className="invalid-feedback">{errors.role}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Members</H5>
                            </label>

                            <div className="col-sm-8">
                                <div className="row">
                                    {allUsersMaintenanceData.map((membersData, index) => {
                                        let { userId, fullName, deleteCount } = membersData;
                                        return (
                                            <div key={index}>
                                                {
                                                    deleteCount == 0 ?
                                                        <div className="col-sm-4" >
                                                            <div className="Checkbox-inline">
                                                                <input type="checkbox" name="userId" value={userId} onClick={(e) => handleOnChange(e)} />
                                                                <H7 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >{fullName}</H7>
                                                            </div>
                                                        </div>
                                                        : " "
                                                }
                                            </div>
                                        )
                                    })
                                    }

                                </div>
                            </div>


                        </div>






                        <button type="button" onClick={() => {
                            handleSubmit()
                        }} className="button" >
                            {isLoading.value ? <img src="/assets/images/gif/loading-circle.gif" style={{ width: "10px", height: "10px" }}
                            /> : <H5 >Create</H5>}
                        </button>
                    </form>
                }
            </section>
        </div>

    );
}
export default CommitteeMaintenanceCreate;