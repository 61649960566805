import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Router } from "react-router-dom";
import { backOfficeCommunication } from "../communications/back-office-communication";
import DashboardBackOffice from "./DashboardBackOffice";
import BackOfficeSignIn from "./BackOfficeSignIn";
import { useHistory } from "react-router";

const BackOffice = () => {

    const isBackOfficeAuthenticated = useSelector((state) => state.isBackOfficeAuthenticated.value ?? false);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        async function init() {
            let backOfficeUserJwtVerification = await backOfficeCommunication.getBackOfficeUserAuthenticateByJWT();
            if (isBackOfficeAuthenticated === undefined || isBackOfficeAuthenticated === false || isBackOfficeAuthenticated === null) {
                if (backOfficeUserJwtVerification.status === "SUCCESS") {
                    dispatch({ type: "AUTHENTICATE_BACKOFFICEUSER", payload: backOfficeUserJwtVerification.jwt, backOfficeUserData: backOfficeUserJwtVerification.backOfficeUser, tabs: backOfficeUserJwtVerification.backOfficeUser.tabs });

                } else {
                    dispatch({ type: "DEAUTHENTICATE_BACKOFFICEUSER" });
                    history.push("/back-office/login");
                }
            }
        }
        init();
    }, []);

    return (
        <div>
            <Route exact path="/back-office/" render={() => {
                return (
                    isBackOfficeAuthenticated ?
                        <Redirect to="/back-office/dashboard" />
                        :
                        <Redirect to="/back-office/login" />
                );
            }} />
            <Route exact path="/back-office/login" render={() => {
                return (
                    isBackOfficeAuthenticated ?
                        <Redirect to="/back-office/dashboard" />
                        :
                        <Redirect to="/back-office/login" />
                );
            }} />
            <Route exact path="/back-office/login" component={BackOfficeSignIn} />
            <Route path="/back-office/dashboard" component={DashboardBackOffice} />
        </div>
    );
}

export default BackOffice;