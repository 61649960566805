import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import H5 from "../../text-components/H5";
import H3 from "../../text-components/H3";
import H7 from "../../text-components/H7";


const RoleMaintenanceSave = () => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const [portals, setPortals] = useState([

        {
            name: "FRONT_OFFICE",
            tabs: ["COURSE_WISE_APPLICATION","NEW_APPLICATION", "APPLICATION_VERIFICATION", "SHORT_LIST_APPLICATION", "WAITING_LIST", "MERIT_LIST", "APPROVED_LIST", "MINUTES_OF_MEETING"]
        },
        {
            name: "BACK_OFFICE",
            tabs: ["GOVERNMENT_RESOLUTION", "USER_NOTIFICATION", "DOCUMENT_SCRUTINY", "NOTIFICATION", "REMITTANCE", "GRIEVANCE", "REFUND","HOME_VERIFICATION","STUDENT_PERFORMANCE", "STUDENT_REVIEW"]
        },
    ]);

    const [selectPortalTabs, setSelectPortalTabs] = useState([]);

    const [selectedTabs, setSelectedTabs] = useState([]);

    //****  Input/Select filed disable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);

    const location = useLocation();

    const [roleData, setRoleData] = useState({});

    const history = useHistory();


    function checkBoxError(selectedTabs) {
        if (selectedTabs.length == 0) {
            return false;
        }
        else {
            return true;
        }
    }


    // Roles Data fetched from the server 
    useEffect(async () => {
        showLoading("Loading...");

        dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "ROLES_MAINTENANCE_UPDATE" });

        // getting applicationId From queryParams 
        const queryParams = new URLSearchParams(location.search);
        const roleIdFromParams = queryParams.get('roleId');

        const roleDataFromServer = await adminCommunication.getRoleById(roleIdFromParams);

        if (roleDataFromServer.status === "SUCCESS") {
            if (roleDataFromServer.role.access === "WRITE") {
                roleDataFromServer.role.access = false;
            }
            else if (roleDataFromServer.role.access === "READ") {
                roleDataFromServer.role.access = true;
            }
            setRoleData(value => value = roleDataFromServer.role);
            portals.map(portal => {
                if (portal.name === roleDataFromServer.role.portal) {
                    setSelectPortalTabs(value => value = portal.tabs);
                }
            })
            setSelectedTabs(value => value = roleDataFromServer.role.tabs);
        }
        else {
            showModal(roleDataFromServer.message);
        }

        hideLoading();
    }, []);


    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const [isLoading, setIsLoading] = useState({ message: "", value: false });


    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }


    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        name: roleData.name,
        portal: roleData.portal,
        tabs: roleData.tabs,
        access: roleData.access,
    }

    function setSelectPortalTabsBySelectedPortal(value) {
        for (let i = 0; i < portals.length; i++) {
            if (portals[i].name === value) {
                setSelectPortalTabs(val => val = portals[i].tabs);
                setSelectedTabs(val => val = []);
                defaultFormValues.tabs = [];
                setRoleData(value => value = { ...roleData, ...{ tabs: [] } });
            }
        }
    }

    //****Validation for Designation Create form*****/
    const validationSchema = Yup.object().shape({
        name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Role name is required"),
        portal: Yup.string().required("Select Portal "),
        // tabs: Yup.string().required("Select Tab "),
        access: Yup.string().required("Role is required"),

    });


    // Destructuring useFormik to get functions to control aspects of form
    // What does Formik do?
    // 1. Handling form events like submit, errors, change in value
    // 2. Fetching values from the form 
    // 3. Support for a validation library on the data fetched. We are using Yup library for validations. 
    // 4. Reset the form
    // 5. Pass initial values to the form
    // 6. Handle errors thrown by the Yup (or any validation library).
    // 7. Reinitialize form values
    // 8. Perform async operations
    const { handleSubmit, handleChange, setFieldValue, values, errors, handleReset } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit
        onSubmit: async (values) => {
            // Show loading 
            showLoading("Loading...");

            // Send values to the server
            let rolesData = {
                roleId: roleData._id,
                name: values.name,
                portal: values.portal,
                tabs: selectedTabs,
                access: values.access,
            }

            if (rolesData.access === true) {
                rolesData.access = "READ"
            }
            else if (rolesData.access === false) {
                rolesData.access = "WRITE"
            }

            let filtered = rolesData.tabs.filter((item, index) => rolesData.tabs.indexOf(item) === index)
            rolesData.tabs = filtered;
            let showCheckBoxError = checkBoxError(selectedTabs);

            if (showCheckBoxError == true) {
                const rolesUpdateResponse = await adminCommunication.updateRole(rolesData);

                // Handle response from the server
                if (rolesUpdateResponse.status === "SUCCESS") {
                    handleReset();
                    dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "ROLES_MAINTENANCE_UPDATE" });
                    history.push('/admin/dashboard/roles-maintenance-update');

                }

                // Show response message from server in modal dialog
                showModal(rolesUpdateResponse.message);
            }
            else {
                showModal("Please select at least one tab");
            }

            // Hide spinner 
            hideLoading();
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: validationSchema,
    });




    // set ids in to state
    const handleOnChange = (e) => {
        let checked = e.target.checked;
        let tab = e.target.value;
        if (checked) {
            setSelectedTabs(() => [...selectedTabs, tab]);
            defaultFormValues.tabs.push(tab);
        } else {
            setSelectedTabs(
                selectedTabs.filter((Tab) => Tab !== tab)

            );

            Array.prototype.remove = function (value) {
                this.splice(this.indexOf(value), 1);
            }
            defaultFormValues.tabs.remove(tab);
        }

        return;
    }

    console.log(selectedTabs);
    return (

        <div >
            <section className="create_form create_designation mb-5" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000", border: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }} >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :

                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <H3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Update Role</H3>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Role Name</H5>
                            </label>
                            <div className="col-sm-7">
                                <input type="text" name="name" onChange={handleChange} value={values.name} className={`form-control ${errors.name ? "is-invalid" : ""}`} />
                                <div className="invalid-feedback">{errors.name}</div>
                            </div>
                        </div>


                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Portal</H5>
                            </label>
                            <div className="col-sm-7">
                                <select name="portal" onChange={(e) => { handleChange(e); setSelectPortalTabsBySelectedPortal(e.target.value); }} value={values.portal} className={`form-select ${errors.portal ? "is-invalid" : ""}`}>
                                    <option defaultValue>Select</option>
                                    {portals?.map((portal, index) => {
                                        const { name } = portal;
                                        return (
                                            <option key={index} value={name}>{name}</option>
                                        );
                                    })}
                                </select>
                                <div className="invalid-feedback">{errors.portal}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Tabs</H5>
                            </label>

                            <div className="col-sm-7">
                                <div className="row">
                                    {selectPortalTabs.map((tab, index) => {

                                        return (
                                            <div className="col-sm-6" key={index}>
                                                <div className="Checkbox-inline">
                                                    <input type="checkbox" checked={(roleData === undefined || roleData.tabs === []) ? " " : roleData.tabs.includes(tab)} value={tab} onChange={(e) => handleOnChange(e)} />
                                                    <H7 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >{tab}</H7>
                                                </div>
                                            </div>

                                        )
                                    })}

                                </div>
                            </div>

                        </div>


                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                    Access
                                </H5>
                            </label>

                            <div className="col-4 arrayFetch">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                    Read
                                </H5>
                                <input onChange={() => setFieldValue("access", true)} id="accessTrue" checked={values.access === null ? (values.access) : Boolean(values.access)} type="radio" value="true" name="access" className={`mx-3 ${errors.access ? "is-invalid" : ""}`} disabled={disabled} />
                                <div className="invalid-feedback">{errors.access?.message}</div>
                            </div>
                            <div className="col-4 arrayFetch">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                    Write
                                </H5>
                                <input onChange={() => setFieldValue("access", false)} id="accessFalse" checked={values.access === null ? (values.access) : Boolean(!values.access)} type="radio" value="false" name="access" className={`mx-3 ${errors.access ? "is-invalid" : ""}`} disabled={disabled} />
                                <div className="invalid-feedback">{errors.access?.message}</div>
                            </div>
                        </div>


                        <button type="button" onClick={() => {
                            handleSubmit();
                        }} className="button" >
                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H5>Update</H5>}
                        </button>

                    </form>
                }
            </section>
        </div>

    );
}
export default RoleMaintenanceSave;