import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import H3 from "../text-components/H3";
import H4 from "../text-components/H4";
import P from "../text-components/P";
// import BackgroundDark from '../milestone-bg/milestone_arrow_dark.png';
import BackgroundDark from '../milestone-bg/blue1092.png';
// import BackgroundWhite from '../milestone-bg/milestone_arrow_white.png';
import BackgroundWhite from '../milestone-bg/white1092.png';
import { communication } from "../communications/communication";
import LoadingForms from "../shared-components/LoadingForms";
import { removeUnderscoreFromText } from "../utlis/helper";

const Milestone = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const dispatch = useDispatch();
    const [milestoneDetails, setMilestoneDetails] = useState([]);
    // spinner on loading 
    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }
    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    useEffect(() => {
        async function fetchData() {
            showLoading("Loading ...");
            // to show active tab
            let fullUrl = window.location.href;
            let splitMilestoneUrl = fullUrl.split('/');
            if (splitMilestoneUrl.includes("milestone")) {
                dispatch({ type: "SHOW_ACTIVE_APPLICATION_PROCESS_TAB", payload: "MILESTONE" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_APPLICATION_PROCESS_TAB" });
            }
            const getMilestoneFromServer = await communication.getUserMilestones();
            if (getMilestoneFromServer.status === "SUCCESS") {
                let milestoneData = getMilestoneFromServer.milestoneDetails
                setMilestoneDetails(milestoneData);
            }
            else {
                setMilestoneDetails([]);
            }
            hideLoading();
        }
        fetchData();
    }, []);


    return (
        <>
            <section className="application_flow_big  mb-3" >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :
                    <>
                        {
                            (milestoneDetails.length) > 0 ?
                                <div className="milestone_section">
                                    <H3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Milestone Status</H3>
                                    <div className="milestone_status_color_code_main">
                                        <div className="milestone_status_completed">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Completed</H4>
                                        </div>
                                        <div className="milestone_status_inprogress">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">In-Progress</H4>
                                        </div>
                                        <div className="milestone_status_new_triggered">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">New Triggered</H4>
                                        </div>
                                        <div className="milestone_status_rejected">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Rejected</H4>
                                        </div>
                                    </div>

                                    <div className="milestone_main" style={{ background: darkWhiteThemeToggle === "WHITE" ? `url(${BackgroundDark})` : `url(${BackgroundWhite})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }} >

                                        <div className="milestone_div">
                                            <div className="milestone_list">

                                                {milestoneDetails.map((milestone, index) => {
                                                    const { name, userStatus } = milestone;
                                                    return (
                                                        <div className="milestone_circle_main" key={index}>

                                                            {
                                                                ["SUBMITTED", "FUNDS_RECEIVED_FROM_TREASURY", "REQUEST_TO_TREASURY", "ASSIGNED", "HV_REQUESTED", "EMAIL_SENT"].includes(userStatus) && <div className="milestone_circle" style={{ backgroundColor: "yellow" }}> M {index + 1} </div>
                                                            }
                                                            {
                                                                ["APPROVED", "HV_COMPLETED"].includes(userStatus) && <div className="milestone_circle" style={{ backgroundColor: "green" }}> M {index + 1} </div>
                                                            }
                                                            {
                                                                ["REJECTED", "IN_COMPLETE", "HV_IN_COMPLETED", "IN_COMPLETED"].includes(userStatus) && <div className="milestone_circle" style={{ backgroundColor: "red" }}> M {index + 1} </div>
                                                            }
                                                            {
                                                                ["NEW"].includes(userStatus) && <div className="milestone_circle" style={{ backgroundColor: "gray" }}> M {index + 1} </div>
                                                            }
                                                            <div className="verticle_line"></div>
                                                            <div className="milestone_name">
                                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{removeUnderscoreFromText(name)}</P>
                                                            </div>
                                                        </div>
                                                    )
                                                })}


                                            </div>
                                        </div>
                                    </div>

                                </div>
                                :
                                <div className="col-12 text-center view_download_dashboard">
                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">milestones not available.</H4>
                                </div>
                        }
                    </>

                }
            </section>
        </>
    );
}
export default Milestone;