 import H5 from "../text-components/H5";
import H2 from "../text-components/H2";
import { useSelector } from "react-redux";
import { useState } from "react";
import NavSelectedUnderline from "../shared-components/NavSelectedUnderline";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { backOfficeCommunication } from "../communications/back-office-communication";

const BackOfficeNav = ({ toRefresh }) => {


    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const activeBackOfficeTab = useSelector(state => state.activeBackOfficeTab);

    const [selectedNavItem, setSelectedNavItem] = useState("");
    const [count, setCount] = useState();

    const history = useHistory();

    const tabs = useSelector(state => state.isBackOfficeAuthenticated.tabs ?? ["GOVERNMENT_RESOLUTION"]);

    async function getCounts() {
        const responseFromServer = await backOfficeCommunication.getNotificationCounts();
        if (responseFromServer.status === "SUCCESS") {
            setCount(responseFromServer.counts)
        }
    }

    useEffect(() => {
        getCounts();
    }, [toRefresh]);

    useEffect(() => {

        switch (window.location.pathname) {
            case "/back-office/dashboard/government-resolution":
                setSelectedNavItem(value => value = "GOVERNMENT_RESOLUTION")
                break;
            case "/back-office/dashboard/user-notification":
                setSelectedNavItem(value => value = "USER_NOTIFICATION")
                break;
            case "/back-office/dashboard/document-scrutiny":
                setSelectedNavItem(value => value = "DOCUMENT_SCRUTINY")
                break;
            case "/back-office/dashboard/home-verification":
                setSelectedNavItem(value => value = "HOME_VERIFICATION")
                break;
            case "/back-office/dashboard/notification":
                setSelectedNavItem(value => value = "NOTIFICATION")
                break;
            case "/back-office/dashboard/remittance":
                setSelectedNavItem(value => value = "REMITTANCE")
                break;
            case "/back-office/dashboard/grievance":
                setSelectedNavItem(value => value = "GRIEVANCE")
                break;
            case "/back-office/dashboard/refund":
                setSelectedNavItem(value => value = "REFUND")
                break;
            case "/back-office/dashboard/student-performance":
                setSelectedNavItem(value => value = "STUDENT_PERFORMANCE")
                break;
            case "/back-office/dashboard/student-review":
                setSelectedNavItem(value => value = "STUDENT_REVIEW")
                break;

        }

    }, [activeBackOfficeTab]);

    const selectNavItem = async (navItem) => {
        setSelectedNavItem(value => value = navItem);

        switch (navItem) {

            case "GOVERNMENT_RESOLUTION":
                history.push('/back-office/dashboard/government-resolution');
                break;
            case "USER_NOTIFICATION":
                history.push('/back-office/dashboard/user-notification');
                break;
            case "DOCUMENT_SCRUTINY":
                history.push('/back-office/dashboard/document-scrutiny');
                break;
            case "HOME_VERIFICATION":
                history.push('/back-office/dashboard/home-verification');
                break;
            case "NOTIFICATION":
                history.push('/back-office/dashboard/notification');
                break;
            case "REMITTANCE":
                history.push('/back-office/dashboard/remittance');
                break;
            case "GRIEVANCE":
                history.push('/back-office/dashboard/grievance');
                break;
            case "REFUND":
                history.push('/back-office/dashboard/refund');
                break;
            case "STUDENT_PERFORMANCE":
                history.push('/back-office/dashboard/student-performance');
                break;
            case "STUDENT_REVIEW":
                history.push('/back-office/dashboard/student-review');
                break;
        }
    }

    useEffect(() => {
        selectNavItem(activeBackOfficeTab);
    }, []);



    return (
        <>

            {/*-----navbar for laptop and mobile device--------------*/}

            <nav className="navbar navbar-expand-lg application_process_nav mb-1" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000" }}>
                <div className="container-fluid p-0">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span><i className="fa fa-bars" aria-hidden="true"></i></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                        <ul className="navbar-nav back_office_nav_list">

                            {/* <li className="home_icon"><H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00"><i className="fa fa-home" aria-hidden="true" onClick={goToHome}></i></H5></li> */}
                            {
                                tabs.includes("GOVERNMENT_RESOLUTION") ?
                                    <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeBackOfficeTab === "GOVERNMENT_RESOLUTION") ? selectNavItem(" ") : selectNavItem("GOVERNMENT_RESOLUTION")}>
                                        <div style={{ position: "relative" }}>
                                            <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Government Resolution</H2>
                                            {count?.countOfNewGr !== 0 ?
                                                <span className="badge rounded-pill">
                                                    {count?.countOfNewGr}
                                                </span>
                                                :
                                                <span></span>
                                            }
                                        </div>
                                        {activeBackOfficeTab === "GOVERNMENT_RESOLUTION" ? <NavSelectedUnderline /> : <span></span>}
                                    </li>
                                    : " "
                            }
                            {tabs.includes("USER_NOTIFICATION") ?
                                <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeBackOfficeTab === "USER_NOTIFICATION") ? selectNavItem(" ") : selectNavItem("USER_NOTIFICATION")}>
                                    <div style={{ position: "relative" }}>
                                        <H2 className="APPLICATION_VERIFICATION" whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Notify User</H2>
                                        {count?.countOfNewNotifyUser !== 0 ?
                                            <span className="badge rounded-pill">
                                                {count?.countOfNewNotifyUser}
                                            </span>
                                            :
                                            <span></span>
                                        }
                                    </div>
                                    {activeBackOfficeTab === "USER_NOTIFICATION" ? <NavSelectedUnderline /> : <span></span>}
                                </li>
                                : ""
                            }
                            {
                                tabs.includes("DOCUMENT_SCRUTINY") ?
                                    <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeBackOfficeTab === "DOCUMENT_SCRUTINY") ? selectNavItem(" ") : selectNavItem("DOCUMENT_SCRUTINY")}>
                                        <div style={{ position: "relative" }}>
                                            <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Document Scrutiny</H2>
                                            {count?.countsOfDocumentScrutiny !== 0 ?
                                                <span className="badge rounded-pill">
                                                    {count?.countsOfDocumentScrutiny}
                                                </span>
                                                :
                                                <span></span>
                                            }
                                        </div>
                                        {activeBackOfficeTab === "DOCUMENT_SCRUTINY" ? <NavSelectedUnderline /> : <span></span>}
                                    </li>
                                    : " "
                            }
                            {
                                tabs.includes("HOME_VERIFICATION") ?
                                    <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeBackOfficeTab === "HOME_VERIFICATION") ? selectNavItem(" ") : selectNavItem("HOME_VERIFICATION")}>
                                        <div style={{ position: "relative" }}>
                                            <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Home Verification</H2>
                                            {count?.countOfNewHomevarification !== 0 ?
                                                <span className="badge rounded-pill">
                                                    {count?.countOfNewHomevarification}
                                                </span>
                                                :
                                                <span></span>
                                            }
                                        </div>
                                        {activeBackOfficeTab === "HOME_VERIFICATION" ? <NavSelectedUnderline /> : <span></span>}
                                    </li>
                                    : " "
                            }
                            {tabs.includes("NOTIFICATION") ?
                                <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeBackOfficeTab === "NOTIFICATION") ? selectNavItem(" ") : selectNavItem("NOTIFICATION")}>
                                    <div style={{ position: "relative" }}>
                                        <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Notification</H2>
                                        {count?.CountOfNewNotification !== 0 ?
                                            <span className="badge rounded-pill">
                                                {count?.CountOfNewNotification}
                                            </span>
                                            :
                                            <span></span>
                                        }
                                    </div>
                                    {activeBackOfficeTab === "NOTIFICATION" ? <NavSelectedUnderline /> : <span></span>}
                                </li>
                                : " "
                            }
                            {
                                tabs?.includes("REMITTANCE") ?
                                    <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeBackOfficeTab === "REMITTANCE") ? selectNavItem(" ") : selectNavItem("REMITTANCE")}>
                                        <div style={{ position: "relative" }}>
                                            <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Remittance</H2>
                                            {count?.countOfNewRemittance !== 0 ?
                                                <span className="badge rounded-pill">
                                                    {count?.countOfNewRemittance}
                                                </span>
                                                :
                                                <span></span>
                                            }
                                        </div>
                                        {activeBackOfficeTab === "REMITTANCE" ? <NavSelectedUnderline /> : <span></span>}
                                    </li>
                                    : " "
                            }
                            {tabs.includes("GRIEVANCE") ?
                                <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeBackOfficeTab === "GRIEVANCE") ? selectNavItem(" ") : selectNavItem("GRIEVANCE")}>
                                    <div style={{ position: "relative" }}>
                                        <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Grievance</H2>
                                        {count?.countOfNewGreivance !== 0 ?
                                            <span className="badge rounded-pill">
                                                {count?.countOfNewGreivance}
                                            </span>
                                            :
                                            <span></span>
                                        }
                                    </div>
                                    {activeBackOfficeTab === "GRIEVANCE" ? <NavSelectedUnderline /> : <span></span>}
                                </li>
                                : " "
                            }
                            {
                                tabs.includes("REFUND") ?
                                    <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeBackOfficeTab === "REFUND") ? selectNavItem(" ") : selectNavItem("REFUND")}>
                                        <div style={{ position: "relative" }}>
                                            <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Refund</H2>
                                            {count?.countOfNewRefunds !== 0 ?
                                                <span className="badge rounded-pill">
                                                    {count?.countOfNewRefunds}
                                                </span>
                                                :
                                                <span></span>
                                            }
                                        </div>
                                        {activeBackOfficeTab === "REFUND" ? <NavSelectedUnderline /> : <span></span>}
                                    </li>
                                    : " "
                            }
                            {
                                tabs.includes("STUDENT_PERFORMANCE") ?
                                    <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeBackOfficeTab === "STUDENT_PERFORMANCE") ? selectNavItem(" ") : selectNavItem("STUDENT_PERFORMANCE")}>
                                        <div style={{ position: "relative" }}>
                                            <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Performance Graph</H2>
                                        </div>
                                        {activeBackOfficeTab === "STUDENT_PERFORMANCE" ? <NavSelectedUnderline /> : <span></span>}
                                    </li>
                                    : " "
                            }
                            {
                                tabs.includes("STUDENT_REVIEW") ?
                                    <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (activeBackOfficeTab === "STUDENT_REVIEW") ? selectNavItem(" ") : selectNavItem("STUDENT_REVIEW")}>
                                        <div style={{ position: "relative" }}>
                                            <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Marks Review</H2>
                                        </div>
                                        {activeBackOfficeTab === "STUDENT_REVIEW" ? <NavSelectedUnderline /> : <span></span>}
                                    </li>
                                    : " "
                            }

                        </ul>

                    </div>
                </div>
            </nav>

        </>
    );
};

export default BackOfficeNav;