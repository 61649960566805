import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FrontOfficeLogoutBox from './FrontOfficeLogoutBox';

const FrontOfficeLogout = () => {

    const [selectedNavItem, setSelectedNavItem] = useState(false);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    function logOutBox() {
        setSelectedNavItem(false);
    }
    return (
        <>
            <div className='profile_tab' onClick={() => { setSelectedNavItem("FRONT_OFFICE_LOGOUT"); }}  >
                <a whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                    {darkWhiteThemeToggle === "WHITE" ? <i className="fa fa-user-circle-o" style={{ color: "black" }} aria-hidden="true"></i> : <i className="fa fa-user-circle-o" style={{ color: "white" }} aria-hidden="true"></i>}
                </a>
            </div>
            {selectedNavItem === "FRONT_OFFICE_LOGOUT" ? <FrontOfficeLogoutBox hideLogoutBox={logOutBox} /> : <span></span>}

        </>

    )
};

export default FrontOfficeLogout;
