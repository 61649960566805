import axios from 'axios';
const serverDevUrl = "http://localhost:3700";
const awsServerDevUrl = "http://ec2-13-233-236-197.ap-south-1.compute.amazonaws.com:3700";
const serverProdUrl = "";
const ngrokUrl = "http://18a8-2405-201-1004-e0c7-b43f-5ec8-2796-a80d.ngrok.io";
const contoboUrl = "http://194.233.89.236:3700";
const machine_Ip = "http://192.168.29.254:3700";

function getServerUrl() {
    //  get node environment variable 
    const nodeEnvirnment = process.env.REACT_APP_NODE_ENV;

    if (nodeEnvirnment === "development") {
        return serverDevUrl;
    }

    if (nodeEnvirnment === "production") {
        return process.env.REACT_APP_BACKEND_SERVER_IP;
    }

    if (nodeEnvirnment === "contobo_dev") {
        // return contoboUrl;
    }

    if (nodeEnvirnment === "machine_Ip") {
        return machine_Ip;
    }
    return process.env.REACT_APP_BACKEND_SERVER_IP;
}

function getCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if ("sjsadUserToken" == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}

export const communication = {
    getScholarshipSchemes: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-scholarship-schemes`, true);
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },

    getNotifications: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-home-screen-notifications`, true);
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },

    getUniversityRankList: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-qs-rank-universities`, true);
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },

    submitFeedbackForm: (feedbackFormData) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/submit-feedback-form`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(feedbackFormData)); // Converting JS object to JSON to send to server
        });
    },

    loginUser: (userCredentials) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/login-user`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(userCredentials)); // Converting JS object to JSON to send to server
        });
    },

    registrationUser: (registrationForm) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/register-user`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(registrationForm)); // Converting JS object to JSON to send to server
        });
    },

    getLatestNotifications: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-latest-notification`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },

    getMyApplications: (applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-user-application-by-applicationId?applicationId=${applicationId}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },

    getMyApplicationsByUserId: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-all-application-by-userId`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },

    checkIfApplicationIsValidToApply: (id) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/application/is-application-available-to-apply`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ notificationId: id }));
        });
    },

    getUserDownloadFile: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-all-applications`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    getAlertsNotification: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open(
                "get",
                `${getServerUrl()}/user/get-latest-alerts?page=${page}`,
                true
            );
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    getAllPublicNotifications: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-all-public-notifications`, true);
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    getUploadDownloadDocumentsForUser: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-admin-upload-download-document?page=${page}`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    getUserMilestones: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-all-user-milestone?page=${page}`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    getGraphData: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/general/get-graph-data`, true);
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    createGrievance: (grievance) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/create-grievance-ticket`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(grievance)); // Converting JS object to JSON to send to server
        });
    },

    getAllLatestNotifications: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-all-latest-general-notification?page=${page}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    getAllUserGrievance: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-all-user-tickets`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },

    getUserTicketData: (ticketId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/get-ticket-from-user`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ ticketId: ticketId })); // Converting JS object to JSON to send to server
        });
    },

    // userGrevianceMessage: (ticketId, message) => {
    //     return new Promise((resolve, reject) => {
    //         const xhr = new XMLHttpRequest();
    //         xhr.open("post", `${getServerUrl()}/user/reply-ticket`, true);
    //         xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    //         xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
    //         xhr.onload = () => {
    //             const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
    //             resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
    //         };
    //         xhr.send(JSON.stringify({ message, ticketId })); // Converting JS object to JSON to send to server
    //     });
    // },

    userGrevianceMessage: (ticketId, message, formData, isFileAttched) => {

        return axios.post(`${getServerUrl()}/user/reply-ticket`, formData, {
            headers: {
                "Content-Type": isFileAttched ? "multipart/form-data,boundary=MyBoundary" : "application/json",
                "Authorization": `Bearer ${getCookie()}`,
                "ticketid": ticketId,
                "message": message,
                "isfileattched": isFileAttched
            }
        });
    },

    markTicket: (userGrevianceResponse) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/mark-ticket`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(userGrevianceResponse)); // Converting JS object to JSON to send to server
        });
    },
    uploadSignedBondCertificate: (formData, applicationId) => {
        return axios.post(`${getServerUrl()}/user/application/milestone/upload-bond-certificate`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
                'applicationid': applicationId
            }
        });
    },
    getUserMilestone: (applicationId, milestoneType) => {
        const dataToSend = {
            applicationId,
            milestoneType
        }
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/application/milestone/get-milestone-details`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify(dataToSend));
        });
    },
    uploadDocumentForMilestoneOne: (formData, applicationId) => {
        return axios.post(`${getServerUrl()}/user/application/milestone/upload-milestone-one-document`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
                'applicationid': applicationId,
            }
        });
    },
    uploadExpenditureReportForMilestoneThreeByUser: (formData, applicationId) => {
        return axios.post(`${getServerUrl()}/user/upload-expenditure-report-by-user`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
                'applicationid': applicationId,
            }
        });
    },
    uploadSponsorshipLetterForMilestoneThreeByUser: (formData, applicationId) => {
        return axios.post(`${getServerUrl()}/user/upload-expenditure-report-by-user`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
                'applicationid': applicationId,
            }
        });
    },
    getNotificationPdf: async (id) => {
        try {
            const response = await axios.get(`${getServerUrl()}/user/get-notification-pdf?notificationId=${id}`, {
                headers: {
                    "Content-Type": "application/pdf",
                },
                responseType: "blob"
            });
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], {
                type: "application/pdf"
            });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            console.log(fileURL);
            //Open the URL on new Window
            window.open(fileURL);
        } catch (error) {
            alert(error);
        }
    },
    getDownloadDocuments: (portal) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/get-download-documents?page`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ portal }));
        });
    },
    getDownloadPdf: async (id) => {
        try {
            const response = await axios.get(`${getServerUrl()}/user/get-download-pdf?documentId=${id}`, {
                headers: {
                    "Content-Type": "application/pdf",
                },
                responseType: "blob"
            });
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], {
                type: "application/pdf"
            });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            console.log(fileURL);
            //Open the URL on new Window
            window.open(fileURL);
        } catch (error) {
            alert(error);
        }
    },

    getAllUserRemittance: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-all-user-remittance?page=${page}`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    uploadAcknowledgedReport: (formData, selectedRemittanceAndApplicationId) => {
        return axios.post(`${getServerUrl()}/user/upload-user-ack-report`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`,
                "remittanceid": selectedRemittanceAndApplicationId.remittanceId,
                "applicationid": selectedRemittanceAndApplicationId.applicationId
            }
        });
    },
    createGeneralMilestone: (data, formData) => {
        return axios.post(`${getServerUrl()}/user/create-general-milestone`, formData, {
            headers: {
                "Content-Type": "multipart/form-data Multipart:Boundary=true",
                "Authorization": `Bearer ${getCookie()}`,
                "milestonetype": data.milestoneType,
                "milestonename": data.milestoneName,
                "applicationid": data.applicationId,
                "semesterid": data.semesterId
            }
        });
    },
    updateIncompleteMilestone: (milestoneId, applicationId, formData) => {
        return axios.post(`${getServerUrl()}/user/update-general-milestone`, formData, {
            headers: {
                "Content-Type": "multipart/form-data Multipart:Boundary=true",
                "Authorization": `Bearer ${getCookie()}`,
                "milestoneid": milestoneId,
                "applicationid": applicationId
            }
        });
    },
    forgetUserPassword: (userId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/reset-password`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ userId: userId }));
        });
    },
    checkForgetPasswordCode: (password, code, token) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/get-reset-password`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ password: password, code: code, token: token }));
        });
    },
    sendUserIdToForgetedUser: (phoneNumberOremail) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/send-user-id`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ phoneNumberOremail: phoneNumberOremail }));
        });
    },
    async getMilestoneDocumentfromServer(filename) {

        try {
            const response = await axios.get(`${getServerUrl()}/user/get-milestone-uploaded-document-file?documentFileDetails=${filename}`, {
                headers: {
                    "Content-Type": "application/pdf",
                    "Authorization": `Bearer ${getCookie()}`,
                },
                responseType: "blob"
            });
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], {
                type: "application/pdf"
            });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
            return;
        } catch (error) {
            alert(error.message);
            return;
        }

    },

    markCompleteFour: (applicationId, type) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/milestone-four-user-ack-sponsorship-letter`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ applicationId: applicationId, milestoneType: type }));
        })
    },

    getUploadedDocumets: async (fileUrl) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getServerUrl()}/user/get-family-income-documment?fileUrl=${fileUrl}`, {
                headers: {
                    "Content-Type": "application/pdf",
                    "Authorization": `Bearer ${getCookie()}`
                },
                responseType: "blob"
            }).then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/pdf"
                });
                //Build a URL from the file
                const pdfBlob = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(pdfBlob);
                resolve(pdfBlob);
            }).catch(error => {
                reject(error);
            })
        });
    },
    getUserMilestoneById: (id) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/user/get-milestone-by-id`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ milestoneId: id }));
        });
    },
    getRefunds: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-refund-by-userId`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    getCurrentYearMilestone: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-current-milestone-of-user`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    getGrivanceFile: async (url) => {
        const splitUrl = url.split(".");
        const extention = splitUrl[splitUrl.length - 1];
        try {
            const response = await axios.get(`${getServerUrl()}/user/get-grivance-document?fileUrl=${url}`, {
                headers: {
                    // "Content-Type": "application/pdf",
                },
                responseType: "blob"
            });
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], {
                // type: "application/pdf"
                type: extention === "pdf" ? "application/pdf" : "image/jpg"
            });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);


            //Open the URL on new Window
            window.open(fileURL);


        } catch (error) {
            alert(error);
        }
    },
    getCounts: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/user/get-new-notification-count`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    userCreateGrievance: (message, formData, isFileAttached) => {
        return axios.post(`${getServerUrl()}/user/raise-user-grievance-ticket`, formData, {
            headers: {
                "Content-Type": isFileAttached ? "multipart/form-data,boundary=MyBoundary" : "application/json",
                "Authorization": `Bearer ${getCookie()}`,
                "message": message,
                "isfileattached": isFileAttached
            }
        });
    },

    uploadSemMarks: (dataToSend) => {
        return axios.post(`${getServerUrl()}/user/get-upload-sem-marks`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateSemMarks: (dataToSend) => {
        return axios.post(`${getServerUrl()}/user/get-update-upload-sem-marks`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getUserSemesterMarksBySemesterId: (semesterId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/back-office/get-user-sem-marks-by-semesterId`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send(JSON.stringify({ semesterId: semesterId }));
        })
    },
    uploadSemMarksAndMarkSheet: (dataToSend) => {
        return axios.post(`${getServerUrl()}/user/get-upload-markSheet-document-and-marks`, dataToSend?.formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`,
                "semester": dataToSend?.semester,
                "typeofmark": dataToSend?.typeOfMark,
                "totalmarks": JSON.stringify(dataToSend?.totalMarks),
                "applicationid": dataToSend?.applicationId,
                "isfileuploaded": dataToSend?.IsFileUploaded,
                "milestoneid": dataToSend?.milestoneId,
            }
        });
    },

    updateMilestoneRequiredDocuments: (dataToSend) => {
        return axios.post(`${getServerUrl()}/user/get-update-upload-milestone-required-documents`, dataToSend?.formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`,
                "applicationid": dataToSend?.applicationId,
                "isfileuploaded": dataToSend?.IsFileUploaded,
                "milestoneid": dataToSend?.milestoneId,
            }
        });
    },

    //Search API'S
    getRemittanceBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/user/search-user-remittance`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            });
        } catch (error) {
            alert(error.message);
        }
    },
    getLatestNotificationBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/user/search-latest-general-notification`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            });
        } catch (error) {
            alert(error.message);
        }
    },
    getMilestonesBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/user/search-user-milestone`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            });
        } catch (error) {
            alert(error.message);
        }
    },
    getAlertsBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/user/search-latest-alerts`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            });
        } catch (error) {
            alert(error.message);
        }
    },
    getDownloadsBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/user/search-admin-upload-documents`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            });
        } catch (error) {
            alert(error.message);
        }
    }
};
