import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormFieldTitle from "./FormFieldTitle";
import * as Yup from "yup";
import { useFormik } from 'formik';
import { applicationCommunication } from "../communications/application-communication";
import LoadingForms from "../shared-components/LoadingForms";
import Label from "../text-components/Label";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { communication } from "../communications/communication"


const ExamDetails = () => {

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //****  Stopping field input- Enable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);

    // spinner on loading 
    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    // cancel, save and submit buttons unable & desable
    const [disableActions, setDisableActions] = useState(false);

    const [applicationId, setApplicationId] = useState(null);

    const [initialFormValues, setInitialFormValues] = useState(null);

    const [saveType, setSaveType] = useState(null);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [url, setUrl] = useState("");



    const [documents, setDocuments] = useState({
        sscCertificateUrl: "",
        hscCertificateUrl: "",
        degreeCertificateUrl: "",
        pgCertificateUrl: "",
        greCertificateUrl: "",
        tofelCertificateUrl: "",
    });


    const history = useHistory();

    const location = useLocation();

    // To get current location url
    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');

    async function initialLoad() {

        showLoading("Loading Form...");

        // to show active tab
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        let examDetailsUrl = "exam-details?applicationId";
        let splitexamDetailsUrl = examDetailsUrl.split('?');

        if (splitexamDetailsUrl.includes("exam-details")) {
            dispatch({ type: "SHOW_ACTIVE_APPLICATION_FORM_TAB", payload: "EXAM_DETAILS" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_APPLICATION_FORM_TAB" });
        }

        // To get application ID from URL
        const queryParams = new URLSearchParams(location.search);
        const applicationIdFromParams = await queryParams.get('applicationId');
        setApplicationId(applicationIdFromParams);

        // Fetching form from the server
        const getUploadQualificationDetailsFormResponse = await applicationCommunication.getUploadQualificationDetails(applicationIdFromParams);
        // Handling response from the server
        if (getUploadQualificationDetailsFormResponse.data.status === "EDUCATION_DETAILS_FORM_FETCHED_SUCCESSFULLY") {
            let uploadQualificationDetails = getUploadQualificationDetailsFormResponse.data.educationDetailsForm;
            setDocuments({
                ...documents, sscCertificateUrl: uploadQualificationDetails?.SscExamDetails?.certificateUrl,
                hscCertificateUrl: uploadQualificationDetails?.HscExamDetails?.certificateUrl,
                degreeCertificateUrl: uploadQualificationDetails?.degreeExamDetails?.certificateUrl,
                pgCertificateUrl: uploadQualificationDetails?.graduationOrPostGraduationExamDetails?.certificateUrl,
                greCertificateUrl: uploadQualificationDetails?.GreExamDetails?.certificateUrl,
                tofelCertificateUrl: uploadQualificationDetails?.TOEFLorIELTsExamDetails?.certificateUrl
            });
            // setting new form values received from the server
            setInitialFormValues(value => {
                return {
                    ...value, ...{

                        sscName: uploadQualificationDetails.SscExamDetails.examName,
                        sscPassingYear: uploadQualificationDetails.SscExamDetails.passingYear,
                        sscPassedExamName: uploadQualificationDetails.SscExamDetails.universityName,
                        sscTotalNumber: uploadQualificationDetails.SscExamDetails.totalMarks,
                        sscObtain: uploadQualificationDetails.SscExamDetails.obtainedMarks,
                        sscPercentage: uploadQualificationDetails.SscExamDetails.percentage,
                        isSscCertificateAttached: uploadQualificationDetails.SscExamDetails.isCertificateAttached,
                        sscCertificateUrl: uploadQualificationDetails?.SscExamDetails?.certificateUrl,

                        hscCollegeName: uploadQualificationDetails.HscExamDetails.examName,
                        hscPassingYear: uploadQualificationDetails.HscExamDetails.passingYear,
                        hscPassedExamName: uploadQualificationDetails.HscExamDetails.universityName,
                        hscTotalNumber: uploadQualificationDetails.HscExamDetails.totalMarks,
                        hscObtain: uploadQualificationDetails.HscExamDetails.obtainedMarks,
                        hscPercentage: uploadQualificationDetails.HscExamDetails.percentage,
                        isHscCertificateAttached: uploadQualificationDetails.HscExamDetails.isCertificateAttached,
                        hscCertificateUrl: uploadQualificationDetails?.HscExamDetails?.certificateUrl,

                        degreeCollegeName: uploadQualificationDetails.degreeExamDetails.examName,
                        degreePassingYear: uploadQualificationDetails.degreeExamDetails.passingYear,
                        degreePassedExamName: uploadQualificationDetails.degreeExamDetails.universityName,
                        degreeTotalNumber: uploadQualificationDetails.degreeExamDetails.totalMarks,
                        degreeObtain: uploadQualificationDetails.degreeExamDetails.obtainedMarks,
                        degreePercentage: uploadQualificationDetails.degreeExamDetails.percentage,
                        isDegreeCertificateAttached: uploadQualificationDetails.degreeExamDetails.isCertificateAttached,
                        degreeCertificateUrl: uploadQualificationDetails?.degreeExamDetails?.certificateUrl,

                        pgCollegeName: uploadQualificationDetails.graduationOrPostGraduationExamDetails.examName,
                        pgPassingYear: uploadQualificationDetails.graduationOrPostGraduationExamDetails.passingYear,
                        pgPassedExamName: uploadQualificationDetails.graduationOrPostGraduationExamDetails.universityName,
                        pgTotalNumber: uploadQualificationDetails.graduationOrPostGraduationExamDetails.totalMarks,
                        pgObtain: uploadQualificationDetails.graduationOrPostGraduationExamDetails.obtainedMarks,
                        pgPercentage: uploadQualificationDetails.graduationOrPostGraduationExamDetails.percentage,
                        isPgCertificateAttached: uploadQualificationDetails.graduationOrPostGraduationExamDetails.isCertificateAttached,
                        pgCertificateUrl: uploadQualificationDetails?.graduationOrPostGraduationExamDetails?.certificateUrl,

                        greCollegeName: uploadQualificationDetails.GreExamDetails.examName,
                        grePassingYear: uploadQualificationDetails.GreExamDetails.passingYear,
                        grePassedExamName: uploadQualificationDetails.GreExamDetails.universityName,
                        greTotalNumber: uploadQualificationDetails.GreExamDetails.totalMarks,
                        greObtain: uploadQualificationDetails.GreExamDetails.obtainedMarks,
                        grePercentage: uploadQualificationDetails.GreExamDetails.percentage,
                        isGreCertificateAttached: uploadQualificationDetails.GreExamDetails.isCertificateAttached,
                        greCertificateUrl: uploadQualificationDetails?.GreExamDetails?.certificateUrl,

                        tofelCollegeName: uploadQualificationDetails.TOEFLorIELTsExamDetails.examName,
                        tofelPassingYear: uploadQualificationDetails.TOEFLorIELTsExamDetails.passingYear,
                        tofelPassedExamName: uploadQualificationDetails.TOEFLorIELTsExamDetails.universityName,
                        tofelTotalNumber: uploadQualificationDetails.TOEFLorIELTsExamDetails.totalMarks,
                        tofelObtain: uploadQualificationDetails.TOEFLorIELTsExamDetails.obtainedMarks,
                        tofelPercentage: uploadQualificationDetails.TOEFLorIELTsExamDetails.percentage,
                        isTofelCertificateAttached: uploadQualificationDetails.TOEFLorIELTsExamDetails.isCertificateAttached,
                        tofelCertificateUrl: uploadQualificationDetails?.TOEFLorIELTsExamDetails?.certificateUrl,
                    }
                };
            });

            setDisabled(value => value = uploadQualificationDetails.isSubmitted);
            setIsSubmitted(value => value = uploadQualificationDetails.isSubmitted);
            hideLoading();
        } else {
            showModal(getUploadQualificationDetailsFormResponse.message);
            hideLoading();
        }

        //Checking application status is incomplete & making fields undisabled
        const applicationsFromServer = await communication.getMyApplications(applicationIdFromParams);

        if (applicationsFromServer.status === "SUCCESS") {
            const applicationStatus = applicationsFromServer.userApplications[0].applicationStatus

            if (applicationStatus === "INCOMPLETE") {
                setDisabled(false);
                setIsSubmitted(false);
            } else if (applicationStatus === "SELECTED" || applicationStatus === "COMPLETED" || applicationStatus === "APPLICATION_SUBMITTED") {
                setDisabled(true);
                setIsSubmitted(true);
            }

        }
        hideLoading();

        return true;
    }

    //** Fetching parent detail form ***//
    useEffect(() => {
        initialLoad();
    }, []);

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        sscName: "",
        sscPassingYear: "",
        sscPassedExamName: "",
        sscTotalNumber: "",
        sscObtain: "",
        sscPercentage: "",
        sscCertificateUrl: null,
        isSscCertificateAttached: false,

        hscCollegeName: "",
        hscPassingYear: "",
        hscPassedExamName: "",
        hscTotalNumber: "",
        hscObtain: "",
        hscPercentage: "",
        hscCertificateUrl: null,
        isHscCertificateAttached: false,

        degreeCollegeName: "",
        degreePassingYear: "",
        degreePassedExamName: "",
        degreeTotalNumber: "",
        degreeObtain: "",
        degreePercentage: "",
        degreeCertificateUrl: null,
        isDegreeCertificateAttached: false,

        pgCollegeName: "",
        pgPassingYear: "",
        pgPassedExamName: "",
        pgTotalNumber: "",
        pgObtain: "",
        pgPercentage: "",
        pgCertificateUrl: null,
        isPgCertificateAttached: false,

        greCollegeName: "",
        grePassingYear: "",
        grePassedExamName: "",
        greTotalNumber: "",
        greObtain: "",
        grePercentage: "",
        greCertificateUrl: null,
        isGreCertificateAttached: false,

        tofelCollegeName: "",
        tofelPassingYear: "",
        tofelPassedExamName: "",
        tofelTotalNumber: "",
        tofelObtain: "",
        tofelPercentage: "",
        tofelCertificateUrl: null,
        isTofelCertificateAttached: false,

    }
    //****Validation for Exam Details details form*****/
    let validationSchema = Yup.object().shape({

        sscName: Yup.string().required("Pased Course Name is required")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        sscPassingYear: Yup.number()
            .positive("Passing year can't start with a minus")
            .integer("Passing year can't include a decimal point")
            .min(1995, "Minimum 1995").max(2100, "Maxmimum 2100")
            .required('Passing year is required'),
        sscPassedExamName: Yup.string().required("University Name is required")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        sscTotalNumber: Yup.number()
            .positive("Total Marks can't start with a minus")
            .integer("Total Marks can't include a decimal point")
            .required('Total Marks is required'),
        sscObtain: Yup.number()
            .positive("Obtain Marks can't start with a minus")
            .integer("Obtain Marks can't include a decimal point")
            .required('Obtain Marks is required'),
        sscPercentage: Yup.number()
            .positive("Percentage can't start with a minus")
            .integer("Percentage can't include a decimal point")
            .min(1, "Minimum 1").max(100, "Maxmimum 100")
            .required('Percentage is required'),
        sscCertificateUrl: Yup.mixed().required("Upload certificate"),

        hscCollegeName: Yup.string().required("Pased Course Name is required")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        hscPassingYear: Yup.number()
            .positive("Passing year can't start with a minus")
            .integer("Passing year can't include a decimal point")
            .min(1995, "Minimum 1995").max(2100, "Maxmimum 2100")
            .required('Passing year is required'),
        hscPassedExamName: Yup.string().required("University Name is required")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        hscTotalNumber: Yup.number()
            .positive("Total Marks can't start with a minus")
            .integer("Total Marks can't include a decimal point")
            .required('Total Marks is required'),
        hscObtain: Yup.number()
            .positive("Obtain Marks can't start with a minus")
            .integer("Obtain Marks can't include a decimal point")
            .required('Obtain Marks is required'),
        hscPercentage: Yup.number()
            .positive("Percentage can't start with a minus")
            .integer("Percentage can't include a decimal point")
            .min(1, "Minimum 1").max(100, "Maxmimum 100")
            .required('Percentage is required'),
        hscCertificateUrl: Yup.mixed().required("Upload certificate"),

        degreeCollegeName: Yup.string().required("Pased Course Name is required")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        degreePassingYear: Yup.number()
            .positive("Passing year can't start with a minus")
            .integer("Passing year can't include a decimal point")
            .min(1995, "Minimum 1995").max(2100, "Maxmimum 2100")
            .required('Passing year is required'),
        degreePassedExamName: Yup.string().required("University Name is required")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        degreeTotalNumber: Yup.number()
            .positive("Total Marks can't start with a minus")
            .integer("Total Marks can't include a decimal point")
            .required('Total Marks is required'),
        degreeObtain: Yup.number()
            .positive("Obtain Marks can't start with a minus")
            .integer("Obtain Marks can't include a decimal point")
            .required('Obtain Marks is required'),
        degreePercentage: Yup.number()
            .positive("Percentage can't start with a minus")
            .integer("Percentage can't include a decimal point")
            .min(1, "Minimum 1").max(100, "Maxmimum 100")
            .required('Percentage is required'),
        degreeCertificateUrl: Yup.mixed().required("Upload certificate"),

        pgCollegeName: Yup.string()
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        pgPassingYear: Yup.number(),
        pgPassedExamName: Yup.string()
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        pgTotalNumber: Yup.number(),
        pgObtain: Yup.number(),
        pgPercentage: Yup.number(),
        pgCertificateUrl: Yup.mixed(),

        greCollegeName: Yup.string().required("Pased Course Name is required")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        grePassingYear: Yup.number()
            .positive("Passing year can't start with a minus")
            .integer("Passing year can't include a decimal point")
            .min(1995, "Minimum 1995").max(2100, "Maxmimum 2100")
            .required('Passing year is required'),
        grePassedExamName: Yup.string().required("University Name is required")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        greTotalNumber: Yup.number()
            .positive("Total Marks can't start with a minus")
            .integer("Total Marks can't include a decimal point")
            .required('Total Marks is required'),
        greObtain: Yup.number()
            .positive("Obtain Marks can't start with a minus")
            .integer("Obtain Marks can't include a decimal point")
            .required('Obtain Marks is required'),
        grePercentage: Yup.number()
            .positive("Percentage can't start with a minus")
            .integer("Percentage can't include a decimal point")
            .min(1, "Minimum 1").max(100, "Maxmimum 100")
            .required('Percentage is required'),
        greCertificateUrl: Yup.mixed().required("Upload certificate"),

        tofelCollegeName: Yup.string().required("Pased Course Name is required")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        tofelPassingYear: Yup.number()
            .positive("Passing year can't start with a minus")
            .integer("Passing year can't include a decimal point")
            .min(1995, "Minimum 1995").max(2100, "Maxmimum 2100")
            .required('Passing year is required'),
        tofelPassedExamName: Yup.string().required("University Name is required")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        tofelTotalNumber: Yup.number()
            .positive("Total Marks can't start with a minus")
            .integer("Total Marks can't include a decimal point")
            .required('Total Marks is required'),
        tofelObtain: Yup.number()
            .positive("Obtain Marks can't start with a minus")
            .integer("Obtain Marks can't include a decimal point")
            .required('Obtain Marks is required'),
        tofelPercentage: Yup.number()
            .positive("Percentage can't start with a minus")
            .integer("Percentage can't include a decimal point")
            .min(1, "Minimum 1").max(100, "Maxmimum 100")
            .required('Percentage is required'),
        tofelCertificateUrl: Yup.mixed().required("Upload certificate"),

    });

    function loadNextForm() {
        history.push(`/user/application/college-admission-details?applicationId=${applicationId}`);
    }

    const previewDocumentsHandler = async (fileUrl) => {

        if (typeof (fileUrl) === "string") {
            await communication.getUploadedDocumets(fileUrl)
        }
        if (typeof (fileUrl) === "object") {
            const preview = URL.createObjectURL(fileUrl);
            window.open(preview);
        }




    }

    const onFileSelect = (event, fileName) => {
        if (fileName === "SSC_CERTIFICATE") {
            setFieldValue("isSscCertificateAttached", true)
        }
        if (fileName === "HSC_CERTIFICATE") {
            setFieldValue("isHscCertificateAttached", true)
        }
        if (fileName === "DEGREE_CERTIFICATE") {
            setFieldValue("isDegreeCertificateAttached", true)
        }
        if (fileName === "PG_CERTIFICATE") {
            setFieldValue("isPgCertificateAttached", true)
        }
        if (fileName === "GRE_CERTIFICATE") {
            setFieldValue("isGreCertificateAttached", true)
        }
        if (fileName === "TOFEL_CERTIFICATE") {
            setFieldValue("isTofelCertificateAttached", true)
        }

        setDocuments({ ...documents, [event.target.name]: event.target.files[0] });
        setFieldValue(event.target.name, event.target.files[0])

    }

    const { handleSubmit, handleChange, values, errors, handleReset, setFieldValue } = useFormik({
        initialValues: initialFormValues === null ? defaultFormValues : initialFormValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {
            try {
                // Loading on save and submit button
                showLoading(saveType === "SUBMIT" ? "Submitting Form..." : "Saving Form...");
                // Disable Save, Cancel and Submit Buttons
                setDisableActions(true);
                //set parent details object to send

                let formData = new FormData();

                if (values.isSscCertificateAttached === true) {
                    formData.append("sscCertificate", documents.sscCertificateUrl); // "SSC_CERTIFICATE"
                }
                if (values.isHscCertificateAttached === true) {
                    formData.append("hscCertificate", documents.hscCertificateUrl); // "HSC_CERTIFICATE"
                }
                if (values.isDegreeCertificateAttached === true) {
                    formData.append("degreeCertificate", documents.degreeCertificateUrl); // "DEGREE_CERTIFICATE"
                }
                if (values.isPgCertificateAttached === true) {
                    formData.append("graduationCertificate", documents.pgCertificateUrl); // "PG_CERTIFICATE"
                }
                // if (values.isGreCertificateAttached === true) {
                //     formData.append("greCertificate", documents.greCertificateUrl); // "GRE_CERTIFICATE"
                // }
                // if (values.isTofelCertificateAttached === true) {
                //     formData.append("toeflOrIELTSCertificate", documents.tofelCertificateUrl); // "GRE_CERTIFICATE"
                // }



                let studentExamDetails = {
                    SscExamDetails: {
                        examName: values.sscName,
                        passingYear: values.sscPassingYear,
                        universityName: values.sscPassedExamName,
                        totalMarks: values.sscTotalNumber,
                        obtainedMarks: values.sscObtain,
                        percentage: values.sscPercentage,
                        isCertificateAttached: values.isSscCertificateAttached,
                        certificateUrl: values.sscCertificateUrl,
                    },
                    HscExamDetails: {
                        examName: values.hscCollegeName,
                        passingYear: values.hscPassingYear,
                        universityName: values.hscPassedExamName,
                        totalMarks: values.hscTotalNumber,
                        obtainedMarks: values.hscObtain,
                        percentage: values.hscPercentage,
                        isCertificateAttached: values.isHscCertificateAttached,
                        certificateUrl: values.hscCertificateUrl,
                    },
                    degreeExamDetails: {
                        examName: values.degreeCollegeName,
                        passingYear: values.degreePassingYear,
                        universityName: values.degreePassedExamName,
                        totalMarks: values.degreeTotalNumber,
                        obtainedMarks: values.degreeObtain,
                        percentage: values.degreePercentage,
                        isCertificateAttached: values.isDegreeCertificateAttached,
                        certificateUrl: values.degreeCertificateUrl,
                    },
                    graduationOrPostGraduationExamDetails: {
                        examName: values.pgCollegeName,
                        passingYear: values.pgPassingYear,
                        universityName: values.pgPassedExamName,
                        totalMarks: values.pgTotalNumber,
                        obtainedMarks: values.pgObtain,
                        percentage: values.pgPercentage,
                        isCertificateAttached: values.isPgCertificateAttached,
                        certificateUrl: values.pgCertificateUrl,
                    },
                    // GreExamDetails: {
                    //     examName: values.greCollegeName,
                    //     passingYear: values.grePassingYear,
                    //     universityName: values.grePassedExamName,
                    //     totalMarks: values.greTotalNumber,
                    //     obtainedMarks: values.greObtain,
                    //     percentage: values.grePercentage,
                    //     isCertificateAttached: values.isGreCertificateAttached,
                    //     certificateUrl: values.greCertificateUrl,
                    // },
                    // TOEFLorIELTsExamDetails: {
                    //     examName: values.tofelCollegeName,
                    //     passingYear: values.tofelPassingYear,
                    //     universityName: values.tofelPassedExamName,
                    //     totalMarks: values.tofelTotalNumber,
                    //     obtainedMarks: values.tofelObtain,
                    //     percentage: values.tofelPercentage,
                    //     isCertificateAttached: values.isTofelCertificateAttached,
                    //     certificateUrl: values.tofelCertificateUrl,
                    // },
                    isSubmitted: false,
                }

                formData.append('studentExamDetails', JSON.stringify(studentExamDetails));

                // Sending data to server
                let sendUplodaQualificationDetailsFormResponse = await applicationCommunication.uploadQualificationDetails(formData, applicationId, "SAVE");

                // Handling response from the server
                if (sendUplodaQualificationDetailsFormResponse.data.status === "FORM_SAVED_SUCCESSFULLY" || sendUplodaQualificationDetailsFormResponse.status === "FORM_SAVED_SUCCESSFULLY") {

                    showModal(sendUplodaQualificationDetailsFormResponse.data.message);
                    if (saveType === "SAVE") {
                    } else {
                        history.push(`/user/application/college-admission-details?applicationId=${applicationId}`);
                    }
                }
                if (sendUplodaQualificationDetailsFormResponse.data.status === "VALIDATION_FAILED" || sendUplodaQualificationDetailsFormResponse.status === "VALIDATION_FAILED") {
                    showModal(sendUplodaQualificationDetailsFormResponse.data.validationErrorsList[0].message || sendUplodaQualificationDetailsFormResponse.validationErrorsList[0].message);
                    hideLoading();
                    return false;
                }

                showModal(sendUplodaQualificationDetailsFormResponse.data.message || sendUplodaQualificationDetailsFormResponse.message);
                hideLoading();
                setDisableActions(false);
                return false;

            }
            catch (errors) {
                hideLoading();
                setDisableActions(false);
                showModal(errors.message);
                return false;
            }
        },
        validationSchema: validationSchema,
    });

    return (
        <section className="application_flow_big">
            <div className="family_income_details_main">
                <div className="container-fluid">
                    {isLoading.value ?
                        <LoadingForms message={isLoading.message} />
                        :
                        <div className="row">
                            <div className="col-12">
                                <FormFieldTitle name="Educational Status/Eligibility information note- CGPA/GPA Conversion Mentioning the percentage on the basis of the certificate and the same will be disqualified if it is not attached" />
                                <form onSubmit={handleSubmit}>

                                    <div className="row" >
                                        <div className="col-lg-2 col-md-4 table_row">
                                        </div>
                                        <div className="col-lg-1 col-md-6 table_row">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Passed course name</Label>
                                        </div>
                                        <div className="col-lg-1 col-md-6 table_row">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Exam year of passing</Label>
                                        </div>
                                        <div className="col-lg-2 col-md-6 table_row">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Name of the educational institution passed and name of the university</Label>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Total marks of university</Label>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Marks obtained</Label>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Percentage of marks or grade</Label>
                                        </div>
                                        <div className="col-lg-2 col-md-3 table_row">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Add a certificate</Label>
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">*NOTE:- You can upload pdf format file & file size must be less than 2mb.</Label>
                                        </div>
                                    </div>

                                    <div className="row" >
                                        <div className="col-lg-2 col-md-4 table_row*">
                                            <FormFieldTitle name="SSC" />
                                        </div>
                                        <div className="col-lg-1 col-md-6 table_row">
                                            <input type="text" placeholder="E.g. State" name="sscName" value={values.sscName} onChange={handleChange} className={`form-control ${errors.sscName ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.sscName}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-6 table_row">
                                            <input type="number" placeholder="E.g. 2021" name="sscPassingYear" value={values.sscPassingYear} onChange={handleChange} className={`form-control ${errors.sscPassingYear ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.sscPassingYear}</div>
                                        </div>
                                        <div className="col-lg-2 col-md-6 table_row">
                                            <textarea placeholder="E.g. Maharshata State Board" name="sscPassedExamName" value={values.sscPassedExamName} onChange={handleChange} className={`form-control ${errors.sscPassedExamName ? "is-invalid" : ""}`} disabled={disabled}></textarea>
                                            <div className="invalid-feedback">{errors.sscPassedExamName}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" placeholder="E.g. 600" name="sscTotalNumber" value={values.sscTotalNumber} onChange={handleChange} className={`form-control ${errors.sscTotalNumber ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.sscTotalNumber}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" placeholder="E.g. 375" name="sscObtain" value={values.sscObtain} onChange={handleChange} className={`form-control ${errors.sscObtain ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.sscObtain}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" placeholder="E.g. 60" name="sscPercentage" value={values.sscPercentage} onChange={handleChange} className={`form-control ${errors.sscPercentage ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.sscPercentage}</div>
                                        </div>
                                        <div className="col-lg-2 col-md-3 table_row">
                                            <input type="file" name="sscCertificateUrl" onChange={(e) => { handleChange(e); onFileSelect(e, "SSC_CERTIFICATE"); defaultFormValues.isSscCertificateAttached = true }} value={values.sscCertificateUrl?.filename} className={`form-control ${errors.sscCertificateUrl ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <button type="button" onClick={() => { previewDocumentsHandler(documents.sscCertificateUrl) }}>Preview</button>
                                        </div>
                                    </div>

                                    <div className="row mt-2" >
                                        <div className="col-lg-2 col-md-4 table_row">
                                            <FormFieldTitle name="HSC" />
                                        </div>
                                        <div className="col-lg-1 col-md-6 table_row">
                                            <input type="text" name="hscCollegeName" value={values.hscCollegeName} onChange={handleChange} className={`form-control ${errors.hscCollegeName ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.hscCollegeName}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-6 table_row">
                                            <input type="number" name="hscPassingYear" value={values.hscPassingYear} onChange={handleChange} className={`form-control ${errors.hscPassingYear ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.hscPassingYear}</div>
                                        </div>
                                        <div className="col-lg-2 col-md-6 table_row">
                                            <textarea name="hscPassedExamName" value={values.hscPassedExamName} onChange={handleChange} className={`form-control ${errors.hscPassedExamName ? "is-invalid" : ""}`} disabled={disabled}></textarea>
                                            <div className="invalid-feedback">{errors.hscPassedExamName}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" name="hscTotalNumber" value={values.hscTotalNumber} onChange={handleChange} className={`form-control ${errors.hscTotalNumber ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.hscTotalNumber}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" name="hscObtain" value={values.hscObtain} onChange={handleChange} className={`form-control ${errors.hscObtain ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.hscObtain}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" name="hscPercentage" value={values.hscPercentage} onChange={handleChange} className={`form-control ${errors.hscPercentage ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.hscPercentage}</div>
                                        </div>
                                        <div className="col-lg-2 col-md-3 table_row">
                                            <input type="file" name="hscCertificateUrl" onChange={(e) => { handleChange(e); onFileSelect(e, "HSC_CERTIFICATE"); }} value={values.hscCertificateUrl?.filename} className={`form-control ${errors.hscCertificateUrl ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <button type="button" onClick={() => { previewDocumentsHandler(documents.hscCertificateUrl) }}>Preview</button>
                                        </div>
                                    </div>

                                    <div className="row mt-2" >
                                        <div className="col-lg-2 col-md-4 table_row">
                                            <FormFieldTitle name="Degree" />
                                        </div>
                                        <div className="col-lg-1 col-md-6 table_row">
                                            <input type="text" name="degreeCollegeName" value={values.degreeCollegeName} onChange={handleChange} className={`form-control ${errors.degreeCollegeName ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.degreeCollegeName}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-6 table_row">
                                            <input type="number" name="degreePassingYear" value={values.degreePassingYear} onChange={handleChange} className={`form-control ${errors.degreePassingYear ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.degreePassingYear}</div>
                                        </div>
                                        <div className="col-lg-2 col-md-6 table_row">
                                            <textarea name="degreePassedExamName" value={values.degreePassedExamName} onChange={handleChange} className={`form-control ${errors.degreePassedExamName ? "is-invalid" : ""}`} disabled={disabled}></textarea>
                                            <div className="invalid-feedback">{errors.degreePassedExamName}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" name="degreeTotalNumber" value={values.degreeTotalNumber} onChange={handleChange} className={`form-control ${errors.degreeTotalNumber ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.degreeTotalNumber}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" name="degreeObtain" value={values.degreeObtain} onChange={handleChange} className={`form-control ${errors.degreeObtain ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.degreeObtain}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" name="degreePercentage" value={values.degreePercentage} onChange={handleChange} className={`form-control ${errors.degreePercentage ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.degreePercentage}</div>
                                        </div>
                                        <div className="col-lg-2 col-md-3 table_row">
                                            <input type="file" name="degreeCertificateUrl" onChange={(e) => { handleChange(e); onFileSelect(e, "DEGREE_CERTIFICATE"); defaultFormValues.isDegreeCertificateAttached = true }} value={values.degreeCertificateUrl?.filename} className={`form-control ${errors.degreeCertificateUrl ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <button type="button" onClick={() => { previewDocumentsHandler(documents.degreeCertificateUrl) }}>Preview</button>
                                        </div>
                                    </div>

                                    <div className="row mt-2" >
                                        <div className="col-lg-2 col-md-4 table_row">
                                            <FormFieldTitle name="Post Graduate Degree / Diploma" />
                                        </div>
                                        <div className="col-lg-1 col-md-6 table_row">
                                            <input type="text" name="pgCollegeName" value={values.pgCollegeName} onChange={handleChange} className={`form-control ${errors.pgCollegeName ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.pgCollegeName}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-6 table_row">
                                            <input type="number" name="pgPassingYear" value={values.pgPassingYear} onChange={handleChange} className={`form-control ${errors.pgPassingYear ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.pgPassingYear}</div>
                                        </div>
                                        <div className="col-lg-2 col-md-6 table_row">
                                            <textarea name="pgPassedExamName" value={values.pgPassedExamName} onChange={handleChange} className={`form-control ${errors.pgPassedExamName ? "is-invalid" : ""}`} disabled={disabled}></textarea>
                                            <div className="invalid-feedback">{errors.pgPassedExamName}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" name="pgTotalNumber" value={values.pgTotalNumber} onChange={handleChange} className={`form-control ${errors.pgTotalNumber ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.pgTotalNumber}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" name="pgObtain" value={values.pgObtain} onChange={handleChange} className={`form-control ${errors.pgObtain ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.pgObtain}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" name="pgPercentage" value={values.pgPercentage} onChange={handleChange} className={`form-control ${errors.pgPercentage ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.pgPercentage}</div>
                                        </div>
                                        <div className="col-lg-2 col-md-3 table_row">
                                            <input type="file" name="pgCertificateUrl" onChange={(e) => { handleChange(e); onFileSelect(e, "PG_CERTIFICATE"); defaultFormValues.isPgCertificateAttached = true }} value={values.pgCertificateUrl?.filename} className={`form-control ${errors.pgCertificateUrl ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <button type="button" onClick={() => { previewDocumentsHandler(documents.pgCertificateUrl) }}>Preview</button>
                                        </div>
                                    </div>

                                    <div className="row mt-2" >
                                        <div className="col-lg-2 col-md-4 table_row">
                                            <FormFieldTitle name="GRE" />
                                        </div>
                                        <div className="col-lg-1 col-md-6 table_row">
                                            <input type="text" name="greCollegeName" value={values.greCollegeName} onChange={handleChange} className={`form-control ${errors.greCollegeName ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.greCollegeName}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-6 table_row">
                                            <input type="number" name="grePassingYear" value={values.grePassingYear} onChange={handleChange} className={`form-control ${errors.grePassingYear ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.grePassingYear}</div>
                                        </div>
                                        <div className="col-lg-2 col-md-6 table_row">
                                            <textarea name="grePassedExamName" value={values.grePassedExamName} onChange={handleChange} className={`form-control ${errors.grePassedExamName ? "is-invalid" : ""}`} disabled={disabled}></textarea>
                                            <div className="invalid-feedback">{errors.grePassedExamName}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" name="greTotalNumber" value={values.greTotalNumber} onChange={handleChange} className={`form-control ${errors.greTotalNumber ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.greTotalNumber}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" name="greObtain" value={values.greObtain} onChange={handleChange} className={`form-control ${errors.greObtain ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.greObtain}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" name="grePercentage" value={values.grePercentage} onChange={handleChange} className={`form-control ${errors.grePercentage ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.grePercentage}</div>
                                        </div>
                                        <div className="col-lg-2 col-md-3 table_row">
                                            <input type="file" name="greCertificateUrl" onChange={(e) => { handleChange(e); onFileSelect(e, "GRE_CERTIFICATE"); defaultFormValues.isGreCertificateAttached = true }} value={values.greCertificateUrl?.filename} className={`form-control ${errors.greCertificateUrl ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <button type="button" onClick={() => { previewDocumentsHandler(documents.greCertificateUrl) }}>Preview</button>
                                        </div>
                                    </div>

                                    <div className="row mt-2" >
                                        <div className="col-lg-2 col-md-4 table_row">
                                            <FormFieldTitle name="TOEFL / IELTS" />
                                        </div>
                                        <div className="col-lg-1 col-md-6 table_row">
                                            <input type="text" name="tofelCollegeName" value={values.tofelCollegeName} onChange={handleChange} className={`form-control ${errors.tofelCollegeName ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.tofelCollegeName}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-6 table_row">
                                            <input type="number" name="tofelPassingYear" value={values.tofelPassingYear} onChange={handleChange} className={`form-control ${errors.tofelPassingYear ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.tofelPassingYear}</div>
                                        </div>
                                        <div className="col-lg-2 col-md-6 table_row">
                                            <textarea name="tofelPassedExamName" value={values.tofelPassedExamName} onChange={handleChange} className={`form-control ${errors.tofelPassedExamName ? "is-invalid" : ""}`} disabled={disabled}></textarea>
                                            <div className="invalid-feedback">{errors.tofelPassedExamName}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" name="tofelTotalNumber" value={values.tofelTotalNumber} onChange={handleChange} className={`form-control ${errors.tofelTotalNumber ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.tofelTotalNumber}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" name="tofelObtain" value={values.tofelObtain} onChange={handleChange} className={`form-control ${errors.tofelObtain ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.tofelObtain}</div>
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <input type="number" name="tofelPercentage" value={values.tofelPercentage} onChange={handleChange} className={`form-control ${errors.tofelPercentage ? "is-invalid" : ""}`} disabled={disabled}></input>
                                            <div className="invalid-feedback">{errors.tofelPercentage}</div>
                                        </div>
                                        <div className="col-lg-2 col-md-3 table_row">
                                            <input type="file" name="tofelCertificateUrl" onChange={(e) => { handleChange(e); onFileSelect(e, "TOFEL_CERTIFICATE"); defaultFormValues.isTofelCertificateAttached = true }} value={values.tofelCertificateUrl?.filename} className={`form-control ${errors.tofelCertificateUrl ? "is-invalid" : ""}`} disabled={disabled} />
                                        </div>
                                        <div className="col-lg-1 col-md-3 table_row">
                                            <button type="button" onClick={() => { previewDocumentsHandler(documents.tofelCertificateUrl) }}>Preview</button>
                                        </div>
                                    </div>



                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form_button">
                                                {splitUrlArray[4] === "user" ?
                                                    <button type="button" onClick={() => { history.push("/user"); dispatch({ type: "SHOW_APPLICATION_PROCESS_NAV" }); }} className="btn form_cancel_btn" disabled={disableActions}>Cancel</button>
                                                    : <span></span>
                                                }
                                                {
                                                    isSubmitted ?
                                                        <div className="m-1"></div>
                                                        :
                                                        <button type="button" onClick={() => {
                                                            setSaveType(value => value = "SAVE");
                                                            handleSubmit();
                                                        }} className="btn form_save-btn" disabled={disableActions}>Save</button>
                                                }
                                                {
                                                    isSubmitted ?
                                                        (splitUrlArray[4] === "user") ?
                                                            <button type="button" onClick={() => {
                                                                loadNextForm();
                                                            }} className="btn form_submit_btn">Next</button>
                                                            : <span></span>
                                                        :
                                                        <button type="button" onClick={() => {
                                                            setSaveType(value => value = "SAVE_AND_NEXT");
                                                            handleSubmit();
                                                        }} className="btn form_submit_btn" disabled={disableActions}>Save & Next</button>
                                                }
                                            </div>
                                        </div>
                                    </div>


                                </form>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>
    );
}

export default ExamDetails;