import React, { useEffect, useState } from 'react';
import LoadingForms from "../../shared-components/LoadingForms";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router';
import { frontOfficeCommunication } from "../../communications/front-office-communication";
import H6 from '../../text-components/H6';
import ReactPaginate from "react-paginate";


const WaitList = () => {


    const history = useHistory();

    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [waitingApplication, setWaitingApplication] = useState([])
    const [applicationIds, setApplicationIds] = useState([]);
    const [applicationCountToApproved, setApplicationCountToApproved] = useState(0);
    const [filteredApplication, setFilteredApplication] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;
    const userAccessType = useSelector((state) => state.isOfficeUserAuthenticated?.officeUser?.access);

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }
 // set ids in to state
    const handleOnChange = (e) => {
        let checked = e.target.checked;
        let id = e.target.value;
        if (checked) {
            setApplicationIds(() => [...applicationIds, id]);
        } else {
            setApplicationIds(
                applicationIds.filter((Id) => Id !== id)
            );
        }
        return;
    }

 //Onclick set current page
 const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage);
    if (searchValue) {
        fetchWaitListBySearch(searchValue, selectedPage + 1);   
    }else{
        initialLoad(selectedPage + 1);
    }
}
//Search Filter
const searchFilter = async (searchString) => {
    if (searchString){
        fetchWaitListBySearch (searchString, currentPage + 1);
    }else{
        const applicationFromServer = await frontOfficeCommunication.getWaitingListApplications();
        if(applicationFromServer?.status === "SUCCESS"){
        setApplicationCountToApproved(applicationFromServer?.applications);
        setWaitingApplication (applicationFromServer?.applications);
        setFilteredApplication(applicationFromServer?.applications);
        setPageCount(applicationFromServer?.totalPages)
        }else if(applicationFromServer?.status ==="UNAUTHORIZED_ACCESS"){
            history.push(`/front-office/login`);  
        }else{
            setApplicationCountToApproved([]);
            setWaitingApplication([]);
            setFilteredApplication([]);
            setPageCount(0)

        }
    }
}
  //get Wait List By Search
const fetchWaitListBySearch = async (searchString,page) =>{
    // console.log(searchString);
    try {
        const applicationFromServer = await frontOfficeCommunication.getwaitListBySearch(searchString,page);
        if(applicationFromServer?.data?.status === "SUCCESS"){
            setApplicationCountToApproved(applicationFromServer?.applications);
            setWaitingApplication(applicationFromServer?.applications);
            setFilteredApplication(applicationFromServer?.application);
            setPageCount(applicationFromServer?.totalPages)
        } else if(applicationFromServer?.data?.status === "UNAUTHORIZED_ACCESS"){
            history.push('/fornt-office/login');
        } else{
            setApplicationCountToApproved([]);
            setWaitingApplication([]);
            setFilteredApplication([]);
            setPageCount(0)
        }
    } catch (error) {
        showModal(error?.message); 
    }
}


    
// }
//get Waiting List on Initial Load
    async function initialLoad(page) {

        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("wait-list")) {
            dispatch({ type: "ACTIVE_TAB", payload: "WAIT_LIST" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_TAB" });
        }
        showLoading("Loading...");

        const applicationFromServer = await frontOfficeCommunication.getWaitingListApplications(page);
    // console.log("inside code",applicationFromServer);
        if (applicationFromServer.status === "SUCCESS") {
            let applicationsArray = applicationFromServer.applications;
            setApplicationCountToApproved(applicationFromServer.applicationReplaceMentCount);
            setWaitingApplication(applicationsArray);
            setFilteredApplication(applicationsArray);
        } else if (applicationFromServer.status === "UNAUTHORIZED_ACCESS") {
            history.push(`/front-office/login`);
            return;
        }
        else {
            setApplicationCountToApproved([]);
            setWaitingApplication([]);
            setFilteredApplication([]);
        }
        hideLoading();
    }
    useEffect(() => {
        initialLoad();
    }, []);




    // send waitList applications list to server
    const sendSelectedApplicationsListToServer = async () => {
        //check if applicationIds is empty

        if (applicationIds.length > 0 && applicationIds.length > applicationCountToApproved) {
            showModal(`You can't select more than ${applicationCountToApproved} applications to replace the revoked applications !`);
        }
        else {
            if (applicationIds.length !== 0) {
                showLoading("Loading...");
                let action = "APPROVED"
                const submitSelectedApplicationsList = await frontOfficeCommunication.submitWaitingListApplicationTOApproved(applicationIds, action);
                if (submitSelectedApplicationsList.status === "SUCCESS") {
                    dispatch({ type: "ACTIVE_TAB", payload: "APPROVED_LIST" });
                    // calling refreshPage function after 5 sec to show model before refreshing the page
                    history.push(`/front-office/dashboard/approved-list`);
                }
                else if (submitSelectedApplicationsList.status === "UNAUTHORIZED_ACCESS") {
                    history.push(`/front-office/login`);
                }
                showModal(submitSelectedApplicationsList.message);
                hideLoading();
            }
            else {
                showModal("Please select atleast one record");
            }
        }
        return;
    }

    // Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const sendToApprovedList = async () => {
        dispatch({ type: "ACTIVE_TAB", payload: "APPROVED_LIST" });
        history.push(`/front-office/dashboard/approved-list`);
    }

    async function goToApplication(applicationId) {
        showLoading("Loading...");
        const accessApplicationFromServer = await frontOfficeCommunication.accessToApplication(applicationId);
        if (accessApplicationFromServer.status === "APPLICATION_AVAILABLE") {
        }
        history.push(`/front-office/dashboard/application/?applicationId=${applicationId}&action=WAIT_LIST`);
        hideLoading();
        showModal(accessApplicationFromServer.message);
    }

    // function onChangeFilterText(event, searchFor) {

    //     if (searchFor === "APPLICATION_ID") {
    //         const filterData = waitingApplication.filter((ele) => ele.shortId.search(event.target.value) === -1 ? false : true);
    //         setFilteredApplication(filterData);
    //     }

    //     if (searchFor === "STATUS") {
    //         const filterData = waitingApplication.filter((ele) => ele.frontOfficeStatus.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
    //         setFilteredApplication(filterData);
    //     }

    //     if (searchFor === "COURSE") {
    //         const filterData = waitingApplication.filter((ele) => ele.course.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
    //         setFilteredApplication(filterData);
    //     }

    //     if (searchFor === "STREAM") {
    //         const filterData = waitingApplication.filter((ele) => ele.stream.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
    //         setFilteredApplication(filterData);
    //     }
    // }

    return (
        <>
            {/* <div className="row mt-3 container" style={{ "margin": "auto" }}>
                <div className="back_office_search_box">
                    <div className="row">
                        <div className="col-3 search_box">
                            <input type="search" className="form-control" placeholder="Application Id" onChange={(event) => onChangeFilterText(event, "APPLICATION_ID")} />
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <div className="col-3 search_box">
                            <input type="search" className="form-control" placeholder="Course" onChange={(event) => onChangeFilterText(event, "COURSE")} />
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <div className="col-3 search_box">
                            <input type="search" className="form-control" placeholder="Stream" onChange={(event) => onChangeFilterText(event, "STREAM")} />
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='container-fluid'>
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :
                    <>
                        <div className="row search_wrapper">
                            <div className="col-lg-4 col-md-4 ps-0">
                                <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Application ID, University, Rank, Course..." />
                            </div>
                            <div className="col-lg-8 col-md-8 pe-0 pagination_outer_wrapper">
                                <div className="pagination_inner_wrapper">
                                {pageCount > 1 && 
                                    <ReactPaginate
                                        previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                        nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        forcePage={currentPage}
                                        activeClassName={"active"} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='wholeTable'>
                            <div className='frontOfficeTable'>

                                <div className='frontOfficeTableHeader'>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder srNo'>
                                        <span><H6>Serial No.</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder ApplicationID' >
                                        <span><H6>Application ID</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder University'>
                                        <span><H6>University</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder UniversityRank'>
                                        <span><H6>University Rank</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Income'>
                                        <span><H6>Income</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Course'>
                                        <span><H6>Course</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Stream'>
                                        <span><H6>Stream</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Age'>
                                        <span><H6>Age</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder OtherScholar'>
                                        <span><H6>Other Scholarship</H6></span>
                                    </div>
                                    {/* <div className='frontOfficeTableHeaderRow tableRowRightBorder percentage'>
                                    <span><H6>Percentage</H6></span>
                                </div> */}
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Attempt'>
                                        <span><H6>Attempt</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Status'>
                                        <span><H6>Status</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow Rating'>
                                        <span><H6>Marks</H6></span>
                                    </div>

                                </div>
                                {
                                    filteredApplication?.length > 0 ?
                                        <div>
                                            {filteredApplication?.map((application, index) => {
                                                const { age,
                                                    applicationId,
                                                    course,
                                                    stream,
                                                    frontOfficeStatus,
                                                    income,
                                                    otherScholarship,
                                                    university,
                                                    universityRank,
                                                    marks,
                                                    percentage,
                                                    attempt,
                                                    shortId
                                                } = application;
                                                return (
                                                    <div key={applicationId}>

                                                        <div className='frontOfficeTableBody'>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder srNo'>
                                                                <span>
                                                                    <input type="checkbox" value={applicationId} onClick={(e) => { handleOnChange(e) }} disabled={userAccessType === "READ" ? true : false} />
                                                                    <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{limit * currentPage + (index + 1)}</H6>
                                                                </span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder applicationId' >
                                                                <span>
                                                                    <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                        <a onClick={() => { goToApplication(applicationId) }}>
                                                                            {shortId}
                                                                        </a>
                                                                    </H6>
                                                                </span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder University '>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {university} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder UniversityRank'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  {universityRank} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Income'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {income} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Course'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {course} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Stream'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {stream} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Age'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                    {`${age.year} Y ${age.month} M ${age.day} D`}
                                                                </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder OtherScholar'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  {otherScholarship === false ? "yes" : "No"} </H6></span>

                                                            </div>
                                                            {/* <div className='frontOfficeTableBodyRow tableRowRightBorder percentage'>
                                                            <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {percentage} </H6></span>

                                                        </div> */}
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Attempt'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {attempt} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Status'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {frontOfficeStatus} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow Rating'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  {marks} </H6></span>

                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        :
                                        <div className="text-center mt-5">
                                            <p>Records not available </p>
                                        </div>
                                }

                            </div>

                        </div>
                    </>
                }
            </div>


            <div className='Front_Office_Btn'>

                <div className="dashboard_delete_button">
                    <button type="button" className="btn" onClick={sendToApprovedList}>
                        <H6>Back</H6>
                    </button>
                </div>

                <div className="dashboard_delete_button">
                    <button type="button" className="btn" onClick={() => sendSelectedApplicationsListToServer()} disabled={userAccessType === "READ" ? true : false} >
                        {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6> Confirm Selection</H6>}
                    </button>
                </div>

            </div>



        </>

    )
};

export default WaitList;
