import React, { useState, useEffect } from 'react'
import H4 from '../../text-components/H4'
import P from '../../text-components/P'
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingForms from "../../shared-components/LoadingForms";
import { backOfficeCommunication } from '../../communications/back-office-communication';
import { removeUnderscoreFromText } from '../../utlis/helper';


const StudentReviewSemesterList = () => {
    const [message, setMessage] = useState();
    const [rejectModal, setRejectModal] = useState(false);
    const [userSemesterList, setUserSemesterList] = useState([]);
    const [semesterId, setSemesterId] = useState();
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    
    //Get user id and Semester id from query params
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');
    // const semesterId = queryParams.get('semesterId');
    
    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //Fetch user Semester List by userId
    const fetchUserSemesterList = async () => {
        try {
            showLoading("Loading...");
            const responseFromServer = await backOfficeCommunication.getUserSemesterList(userId);
            if (responseFromServer?.status === "SUCCESS") {
                setUserSemesterList(responseFromServer?.userSemMarks);
            } else {
                showModal(responseFromServer?.message);
            }
            hideLoading();
        } catch (error) {
            showModal(error.message);
            hideLoading();
        }
    }

    useEffect(() => {
        fetchUserSemesterList();
    }, []);

    const validationSchema = Yup.object().shape({
        message: Yup.string().required("Message is required"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    // get functions to build with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const showMarksPreview = (userId, semesterId) => {
        history.push(`/back-office/dashboard/student-marks-preview?userId=${userId}&semesterId=${semesterId}`);
    }

    const approveMarks = async (semesterId) => {
        try {
            const serverResponse = await backOfficeCommunication.approveUserSemMarks(semesterId);
            if (serverResponse?.status === "SUCCESS") {
                showModal(serverResponse?.message);
            } else {
                showModal(serverResponse?.message);
            }

        } catch (error) {
            showModal(error?.message);
        }
    }

    //Sending Warning message
    const onSubmit = async () => {
        try {
            const serverResponse = await backOfficeCommunication.RejectUserSemMarks(semesterId, message);
            if (serverResponse?.status === "SUCCESS") {
                showModal(serverResponse?.message);
            } else {
                showModal(serverResponse?.message);
            }
        } catch (error) {
            showModal(error?.message);
        }
    }

    return (
        <section className="dashboard_background">
            {isLoading?.value ?
                <LoadingForms message={isLoading?.message} />
                :
                <>
                    <div className="container">
                        <div className="dashboard_back_office">
                            <div className="dashboard_view_data_heading">
                                <div className="row m-0">
                                    <div className="col-2 p-0 view_dashboard">
                                        <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Serial No.</H4></span>
                                    </div>
                                    <div className="col-7 ps-3 view_dashboard" style={{ textAlign: "start" }}>
                                        <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Semester</H4></span>
                                    </div>
                                    <div className="col-3 p-0 text_align">
                                        <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Status</H4></span>
                                    </div>
                                </div>
                            </div>

                            {userSemesterList?.map((semesterList, index) => (
                                <div className="dashboard_view_data" style={{ position: "relative" }} key={index}>
                                    <div className="row m-0">
                                        <div className="col-2 p-0 view_dashboard text_align">
                                            <span>
                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{index + 1}</P>
                                            </span>
                                        </div>
                                        <div className="col-7 ps-3 view_dashboard" style={{ textAlign: "start" }}>
                                            <span>
                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a onClick={() => showMarksPreview(semesterList?.userId, semesterList?._id)}>{removeUnderscoreFromText(semesterList?.semester)}</a> </P>
                                            </span>
                                        </div>
                                        <div className="col-3 p-0 text_align">
                                            {semesterList?.status === "APPROVED" &&
                                                <span>
                                                    <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Approved</P>
                                                </span>
                                            }
                                            {semesterList?.status === "REJECT" &&
                                                <span>
                                                    <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Rejected</P>
                                                </span>
                                            }
                                            {semesterList?.status === "NEW" &&
                                                <>
                                                    <button className="me-2" onClick={() => approveMarks(semesterList?._id)}>Approve</button>
                                                    <button className="ms-2" onClick={() => { setRejectModal(true); setSemesterId(semesterList?._id); }}>Reject</button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                        <div className="semester_list_cancel_btn">
                            <button onClick={() => history.goBack()}>Cancel</button>
                        </div>
                    </div>
                    {rejectModal &&
                        <div className="reject_message_modal_box">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <textarea {...register("message")} name="message" onChange={(event) => setMessage(event.target.value)} className={`form-control ${errors.message ? "is-invalid" : ""}`} placeholder="Enter warning message..." />
                                <div className="invalid-feedback">{errors.message?.message}</div>

                                <div className="reject_message_modal_btn">
                                    <button type="submit">Send Warning</button>
                                    <button className="ms-3" onClick={() => setRejectModal(false)}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    }
                </>
            }
        </section>
    )
}

export default StudentReviewSemesterList