import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import H4 from "../../text-components/H4";
import P from "../../text-components/P";
import { useLocation } from "react-router-dom";
import { backOfficeCommunication, getServerUrl } from "../../communications/back-office-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import { useDispatch } from "react-redux";
import { removeUnderscoreFromText } from "../../utlis/helper";


const DocumentScrutinyMilestoneList = () => {

    const location = useLocation();
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const dispatch = useDispatch();

    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    const queryParams = new URLSearchParams(location.search);
    const applicationIdFromParams = queryParams.get('applicationId');


    const [allMilestones, setAllMilestones] = useState([]);
    const [applicationId, setApplicationId] = useState(applicationIdFromParams)
    const history = useHistory();


    // show document scrutiny uploaded documents window
    function showDocumentScrutinyUploadedDocuments(milestoneId, applicationId, type) {
        if (type === "MILESTONE_ONE") {
            history.push(`/back-office/dashboard/milestoneOne?applicationId=${applicationId}&type=${type}&milestoneId=${milestoneId}`)
        }

        if (type === "MILESTONE_TWO") {
            history.push(`/back-office/dashboard/milestoneTwo?applicationId=${applicationId}&type=${type}&milestoneId=${milestoneId}`)
        }

        if (type === "MILESTONE_THREE") {
            history.push(`/back-office/dashboard/milestoneThree?applicationId=${applicationId}&type=${type}&milestoneId=${milestoneId}`)
        }

        if (type === "MILESTONE_FOUR") {
            return history.push(`/back-office/dashboard/milestoneFour?applicationId=${applicationId}&type=${type}&milestoneId=${milestoneId}`);
        }

        if (type === "MILESTONE_FIVE") {
            history.push(`/back-office/dashboard/milestoneFive?applicationId=${applicationId}&type=${type}&milestoneId=${milestoneId}`)
        }

        if (type === "NEW_SESSION") {
            history.push(`/back-office/dashboard/generalMilestoneNewSession?applicationId=${applicationId}&type=${type}&milestoneId=${milestoneId}`)
        }

        if (["MILESTONE_ONE", "MILESTONE_TWO", "MILESTONE_THREE", "MILESTONE_FOUR", "MILESTONE_FIVE", "NEW_SESSION"].includes(type) === false) {
            history.push(`/back-office/dashboard/generalMilestone?applicationId=${applicationId}&type=${type}&milestoneId=${milestoneId}`);
        }
    }

    //close document scrutiny milestone list window
    function closeDocumentScrutinyMilestoneList() {
        history.push(`/back-office/dashboard/document-scrutiny`);
    }

    async function initialLoad() {

        showLoading("Loading...")
        try {
            // const queryParams = new URLSearchParams(location.search);
            // const applicationId = queryParams.get('applicationId');

            const getMilestone = await backOfficeCommunication.fetchAllMilestone(applicationId);
            if (getMilestone?.status === "SUCCESS") {
                // setApplicationId(applicationId)
                setAllMilestones(getMilestone?.milestonesArray);
            }
            else if (getMilestone?.status === "ERROR" || getMilestone?.jwt === null) {
            }
            else {
                showModal(getMilestone?.message)
            }
        } catch (error) {
            showModal(error?.message);
        }
        hideLoading();
    }

    useEffect(() => {
        initialLoad();

    }, [])

    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');
    if (splitUrlArray.includes("document-scrutiny")) {
        dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
    } else {
        dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
    }



    return (
        <>
            <section className="dashboard_background">
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :
                    <div className="container">
                        <div className="row">
                            <div className="col-12 p-0">
                                <div className="dashboard_back_office">

                                    {/*----- card header section---*/}
                                    <div className="dashboard_view_data_heading">
                                        <div className="row m-0">
                                            <div className="col-1 p-0 view_dashboard">
                                                <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Serial No.</H4></span>
                                            </div>
                                            <div className="col-4 p-0 view_dashboard ps-2" style={{ textAlign: "left" }}>
                                                <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Milestone Name</H4></span>
                                            </div>
                                            <div className="col-2 p-0 view_dashboard">
                                                <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Status</H4></span>
                                            </div>
                                            <div className="col-2 p-0 view_dashboard">
                                                <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Creation Date</H4></span>
                                            </div>
                                            <div className="col-3 p-0 ps-2" style={{ textAlign: "center" }}>
                                                <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Comment</H4></span>
                                            </div>
                                        </div>
                                    </div>


                                    {allMilestones?.map((value, index) => {
                                        return (
                                            <div className="dashboard_view_data_main" key={index}>
                                                <div className="dashboard_view_data">
                                                    <div className="row m-0">
                                                        <div className="col-1 p-0 view_dashboard text_align">
                                                            <span>
                                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{index + 1}</P>
                                                            </span>
                                                        </div>

                                                        <div className="col-4 p-0 ps-2 view_dashboard align" style={{ textAlign: "left", overflow: "hidden" }}>
                                                            <span>
                                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a onClick={() => showDocumentScrutinyUploadedDocuments(value?._id, value?.applicationId, value?.type)}>{value.name === "FINAL_MILESTONE" ? "COURSE COMPLETION" : removeUnderscoreFromText(value.name)}</a></P>
                                                            </span>
                                                        </div>
                                                        <div className="col-2 p-0 view_dashboard text_align">
                                                            <span>
                                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                    {
                                                                        value?.backOfficeStatus === "NO_USER_ACTION" ?
                                                                            "NEW" :
                                                                            removeUnderscoreFromText(value?.backOfficeStatus)
                                                                    }
                                                                </P>
                                                            </span>
                                                        </div>
                                                        <div className="col-2 p-0 view_dashboard text_align">
                                                            <span ><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{new Date(value?.createdDate).getDate()} - {new Date(value?.createdDate).getMonth() + 1} - {new Date(value?.createdDate).getFullYear()}</P></span>
                                                        </div>
                                                        <div className="col-3 p-0 ps-2 text_align">
                                                            <span>
                                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                    {
                                                                        !!value?.backOfficeComment ?
                                                                            <>
                                                                                {
                                                                                    value?.backOfficeComment ? <>
                                                                                        {
                                                                                            value?.backOfficeComment?.length > 0 ?
                                                                                                !!value?.backOfficeComment[value?.backOfficeComment.length - 1] ? value?.backOfficeComment[value?.backOfficeComment.length - 1] :
                                                                                                    "No Comment"
                                                                                                : "No Comment"
                                                                                        }
                                                                                    </>
                                                                                        :
                                                                                        <>
                                                                                            No Comment
                                                                                        </>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    value?.comment ? <>
                                                                                        {
                                                                                            value?.comment?.length > 0 ?
                                                                                                !!value?.comment[value?.comment.length - 1] ? value?.comment[value?.comment.length - 1] :
                                                                                                    "No Comment"
                                                                                                : "No Comment"
                                                                                        }
                                                                                    </>
                                                                                        :
                                                                                        <>
                                                                                            No Comment
                                                                                        </>
                                                                                }
                                                                            </>
                                                                    }
                                                                </P>
                                                            </span>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })}

                                    <div className="back_office_button">
                                        <button onClick={() => closeDocumentScrutinyMilestoneList()}>Cancel</button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                }
            </section>

        </>
    );
}
export default DocumentScrutinyMilestoneList;