import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import H1 from "../../text-components/H1";
import H3 from "../../text-components/H3";
import H5 from "../../text-components/H5";
import { adminCommunication } from "../../communications/admin-communication"


const UploadData = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);


    // Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const [startYear, setStartYear] = useState('');
    const [endYear, setEndYear] = useState('');
    const [labels, setLables] = useState(["Excel File - Yearly Applications", "Excel File - Yearly Course Completion", "Excel File - Amount Disbursed", "Excel File - District Wise Applications", "Excel File - Country Wise Applications", "Excel File - Course Wise Applications"])

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let years = [];
    for (let i = 2022; i <= currentYear; i++) {
        years.push(i);
    }

    async function downloadExcel(index) {

        if (index === 0 && startYear.type === "Excel File - Yearly Applications" && endYear.type === "Excel File - Yearly Applications") {
            await adminCommunication.getApplicationByYearExcel(startYear.year, endYear.year);
            return;
        }

        if (index === 1 && startYear.type === "Excel File - Yearly Course Completion" && endYear.type === "Excel File - Yearly Course Completion") {
            const responseFromServer = await adminCommunication.getYearlyCourseComplitionExcel(startYear.year, endYear.year);
            // showModal(responseFromServer.message);
            return;
        }

        if (index === 2 && startYear.type === "Excel File - Amount Disbursed" && endYear.type === "Excel File - Amount Disbursed") {
            await adminCommunication.getAmountDisbursedByYear(startYear.year, endYear.year);
            return;
        }

        if (index === 3 && startYear.type === "Excel File - District Wise Applications") {
            await adminCommunication.getDistriceWiseApplicationExcel(startYear.year);
            return;
        }

        if (index === 4 && startYear.type === "Excel File - Country Wise Applications") {
            await adminCommunication.getCountryWiseApplicationExcel(startYear.year);
            return;
        }

        if (index === 5 && startYear.type === "Excel File - Course Wise Applications") {
            await adminCommunication.getCourseWiseApplicationExcel(startYear.year);
            return;
        }

        showModal("Please select Year");
    }

    return (
        <section>
            <div className="container">
                <section className="dashboard_background" >
                    <div className="dashbord_main">
                        <div className="download_statistics_title">
                            <H3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Download statistics data in Excel file from below link</H3>
                        </div>
                        <div className="dashboard_view_data_heading" >
                            <div className="row m-0">
                                <div className="col-lg-2 col-md-1 col-1 p-0 view_dashboard">
                                    <span><H5 >Serial No.</H5></span>
                                </div>
                                <div className="col-lg-4 col-md-3 col-4 p-0 view_dashboard">
                                    <span><H5 >File Name</H5></span>
                                </div>
                                <div className="col-lg-2 col-md-3 col-2 p-0 view_dashboard">
                                    <span><H5 >start Year</H5></span>
                                </div>
                                <div className="col-lg-2 col-md-3 col-2 p-0 view_dashboard">
                                    <span><H5 >End Year</H5></span>
                                </div>
                                <div className="col-lg-2 col-md-2 col-3 p-0 ">
                                    <span><H5 >Download</H5></span>
                                </div>
                            </div>
                        </div>

                        {labels.map((excelFileName, index) => {
                            return (
                                <div className="dashboard_view_data_main" key={index}>
                                    <div className="dashboard_view_data">
                                        <div className="row m-0">
                                            <div className="col-lg-2 col-md-1 col-1 p-0 view_dashboard text_align">
                                                <span>
                                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                        {index + 1}
                                                    </H5>
                                                </span>
                                            </div>
                                            <div className="col-lg-4 col-md-3 col-4 p-0 view_dashboard text_align">
                                                <span>
                                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                        {excelFileName}
                                                    </H5>
                                                </span>
                                            </div>
                                            <div className="col-lg-2 col-md-3 col-2 p-0 view_dashboard text_align">
                                                <select onChange={(e) => setStartYear({ year: e.target.value, type: excelFileName })} >
                                                    <option value=" ">Select Year</option>
                                                    {years.map((year, index) => {
                                                        return (
                                                            <option value={year} key={index}>{year}</option>
                                                        );

                                                    })}

                                                </select>
                                            </div>


                                            <div className="col-lg-2 col-md-3 col-2 p-0 view_dashboard text_align">
                                                {
                                                    ["Excel File - Yearly Applications", "Excel File - Yearly Course Completion", "Excel File - Amount Disbursed"].includes(excelFileName) ?
                                                        <select onChange={(e) => setEndYear({ year: e.target.value, type: excelFileName })}>
                                                            <option value=" ">Select Year</option>
                                                            {years.map((year, index) => {
                                                                return (
                                                                    <option key={index} value={year}>{year}</option>
                                                                );

                                                            })}

                                                        </select>
                                                        :
                                                        <span></span>
                                                }

                                            </div>



                                            <div className="col-lg-2 col-md-1 col-3 p-0 text_align">
                                                <span onClick={() => downloadExcel(index)} >
                                                    <a >
                                                        {darkWhiteThemeToggle === "WHITE" ? <i className="fa fa-download" style={{ color: "black" }} aria-hidden="true"></i> : <i className="fa fa-download" style={{ color: "white" }} aria-hidden="true"></i>}
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}




                    </div>
                </section>


            </div>
        </section>
    )
}

export default UploadData;