import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormFieldTitle from "./FormFieldTitle";
import * as Yup from "yup";
import { useFormik } from 'formik';
import { applicationCommunication } from "../communications/application-communication";
import LoadingForms from "../shared-components/LoadingForms";
import Label from "../text-components/Label";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { communication } from "../communications/communication"


const FamilyIncomeDetails = () => {

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //****  Stopping field input- Enable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);

    // spinner on loading 
    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    // cancel, save and submit buttons unable & desable
    const [disableActions, setDisableActions] = useState(false);

    const [applicationId, setApplicationId] = useState(null);

    const [initialFormValues, setInitialFormValues] = useState(null);

    const [saveType, setSaveType] = useState(null);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [url, setUrl] = useState("");


    const [annualIncome, setAnnualIncome] = useState({});

    const [ITR, setITR] = useState({});

    const [deathCertificate, setDeathCertificate] = useState({});

    const [divorce, setDivorce] = useState({});




    const history = useHistory();

    const location = useLocation();

    // To get current location url
    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');

    async function initialLoad() {


        showLoading("Loading Form...");

        // to show active tab
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        let familyIncomeDetailUrl = "family-income-detail?applicationId";
        let splitfamilyIncomeDetailUrl = familyIncomeDetailUrl.split('?');

        if (splitfamilyIncomeDetailUrl.includes("family-income-detail")) {
            dispatch({ type: "SHOW_ACTIVE_APPLICATION_FORM_TAB", payload: "FAMILY_INCOME_DETAILS" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_APPLICATION_FORM_TAB" });
        }


        const queryParams = new URLSearchParams(location.search);
        const applicationIdFromParams = await queryParams.get('applicationId');
        setApplicationId(applicationIdFromParams);




        // Fetching form from the server
        const getFamilyIncomeDetailsFormResponse = await applicationCommunication.getFamilyIncomeDetailsFormUserApplication(applicationIdFromParams);
        // Handling response from the server
        if (getFamilyIncomeDetailsFormResponse.status === "FAMILY_INCOME_DETAILS_FORM_FETCHED_SUCCESSFULLY") {
            let familyIncomeDetailsForm = getFamilyIncomeDetailsFormResponse.familyIncomeDetailsForm;
            // setting new form values received from the server
            setInitialFormValues(value => {
                return {
                    ...value, ...{
                        totalAnnualIncome: familyIncomeDetailsForm.totalAnnualIncome,
                        isAnnualIncomeCertificateAttached: familyIncomeDetailsForm.isAnnualIncomeCertificateAttached,
                        incomeCertificateUrl: familyIncomeDetailsForm.incomeCertificateUrl,
                        isItrReturnAttached: familyIncomeDetailsForm.isItrReturnAttached,
                        itrCertificateUrl: familyIncomeDetailsForm.itrCertificateUrl,
                        isFatherAlive: familyIncomeDetailsForm.isFatherAlive,
                        deathCertificateUrl: familyIncomeDetailsForm.deathCertificateUrl,
                        areParentsDivorced: familyIncomeDetailsForm.areParentsDivorced,
                        divorceCertificateUrl: familyIncomeDetailsForm.divorceCertificateUrl
                    }
                };
            });

            setDisabled(value => value = familyIncomeDetailsForm.isSubmitted);
            setIsSubmitted(value => value = familyIncomeDetailsForm.isSubmitted);
            hideLoading();
        } else {
            showModal(getFamilyIncomeDetailsFormResponse.message);
        }

        //Checking application status is incomplete & making fields undisabled
        const applicationsFromServer = await communication.getMyApplications(applicationIdFromParams);

        if (applicationsFromServer.status === "SUCCESS") {
            const applicationStatus = applicationsFromServer.userApplications[0].applicationStatus

            if (applicationStatus === "INCOMPLETE") {
                setDisabled(false);
            } else if (applicationStatus === "SELECTED" || applicationStatus === "COMPLETED" || applicationStatus === "APPLICATION_SUBMITTED") {
                setDisabled(true);
                setIsSubmitted(true);
            }

        }
        return true;
    }

    //** Fetching parent detail form ***//
    useEffect(() => {
        initialLoad();
    }, []);
    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        totalAnnualIncome: "",
        incomeCertificateUrl: "",
        itrCertificateUrl: "",
        deathCertificateUrl: "",
        divorceCertificateUrl: "",
        isAnnualIncomeCertificateAttached: null,
        isItrReturnAttached: null,
        isFatherAlive: true,
        areParentsDivorced: null,

    }
    //****Validation for Family income details form*****/
    let validationSchema = Yup.object().shape({
        totalAnnualIncome: Yup.number().required("Annual Income is required")
            .typeError('Annual Income must be a number')
            .positive('Annual Income must be greater than zero')
            .max(600000, "Annual Income can't be greater than 6 Lakhs"),
        isAnnualIncomeCertificateAttached: Yup.boolean().required("Annual Income Certificate is required"),
        incomeCertificateUrl: Yup.mixed()
            .when("isAnnualIncomeCertificateAttached", {
                is: true,
                then: Yup.mixed().required("Annual Income Certificate is required"),
                otherwise: Yup.mixed().nullable()
            }),
        isItrReturnAttached: Yup.boolean().required("ITR Certificate is required"),
        itrCertificateUrl: Yup.mixed()
            .when("isItrReturnAttached", {
                is: true,
                then: Yup.mixed().required("ITR Certificate is required"),
                otherwise: Yup.mixed().nullable()
            }),
        isFatherAlive: Yup.boolean().required("Field is required"),
        deathCertificateUrl: Yup.mixed()
            .when("isFatherAlive", {
                is: false,
                then: Yup.mixed().required("Death Certificate is required"),
                otherwise: Yup.mixed().nullable()
            }),
        areParentsDivorced: Yup.boolean().required("parent marital staus required"),
        divorceCertificateUrl: Yup.mixed()
            .when("areParentsDivorced", {
                is: true,
                then: Yup.mixed().required("Divorce Certificate is required"),
                otherwise: Yup.mixed().nullable()
            }),
    });

    function loadNextForm() {
        history.push(`/user/application/family-details?applicationId=${applicationId}`);
    }

    const previewDocumentsHandler = async (fileUrl) => {

        if (typeof (fileUrl) === "string") {
            await communication.getUploadedDocumets(fileUrl)
        }

        if (typeof (fileUrl) === "object") {
            const preview = URL.createObjectURL(fileUrl);
            window.open(preview);
        }



    }

    const onFileSelect = (event, forFile) => {

        let data;

        event.preventDefault();
        if (forFile === "INCOME") {
            data = ({ file: event.target.files[0], fileName: "incomeCertificateUrl.pdf" });
            setAnnualIncome(data);
            setFieldValue('incomeCertificateUrl', event.currentTarget.files[0]);
        }

        if (forFile === "ITR") {
            data = ({ file: event.target.files[0], fileName: "itrCertificateUrl.pdf" });
            setITR(data);
            setFieldValue('itrCertificateUrl', event.currentTarget.files[0]);
        }

        if (forFile === "DEATH_CERTIFICATE") {
            data = ({ file: event.target.files[0], fileName: "deathCertificateUrl.pdf" });
            setDeathCertificate(data);
            setFieldValue('deathCertificateUrl', event.currentTarget.files[0]);
        }
        if (forFile === "DIVORCE_CERTIFICATE") {
            data = ({ file: event.target.files[0], fileName: "divorceCertificateUrl.pdf" });
            setDivorce(data);
            setFieldValue('divorceCertificateUrl', event.currentTarget.files[0]);
        }

    }

    const { handleSubmit, handleChange, values, errors, handleReset, setFieldValue } = useFormik({
        initialValues: initialFormValues === null ? defaultFormValues : initialFormValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {
            try {
                // Loading on save and submit button
                showLoading(saveType === "SUBMIT" ? "Submitting Form..." : "Saving Form...");
                // Disable Save, Cancel and Submit Buttons
                setDisableActions(true);
                //set parent details object to send

                let formData = new FormData();
                if (values.isAnnualIncomeCertificateAttached === true) {
                    if (annualIncome.fileName === "incomeCertificateUrl.pdf") {
                        formData.append("incomeCertificate", annualIncome.file, annualIncome.fileName); // "INCOME_CERTIFICATE"
                    }
                }
                if (values.isFatherAlive === false) {
                    if (deathCertificate.fileName === "deathCertificateUrl.pdf") {
                        formData.append("deathCertificate", deathCertificate.file, deathCertificate.fileName); // "FATHER_DEATH_CERTIFICATE"
                    }
                }

                if (values.isItrReturnAttached === true) {
                    if (ITR.fileName === "itrCertificateUrl.pdf") {
                        formData.append("itrCertificate", ITR.file, ITR.fileName); // "ITR_CERTIFICATE"
                    }
                }

                if (values.areParentsDivorced === true) {
                    if (divorce.fileName === "divorceCertificateUrl.pdf") {
                        formData.append("devorceCertificate", divorce.file, divorce.fileName); // "DIVORCE_CERTIFICATE"
                    }
                }



                let familyIncomeDetailsForm = {
                    totalAnnualIncome: values.totalAnnualIncome,
                    incomeCertificateUrl: values.incomeCertificateUrl,
                    isAnnualIncomeCertificateAttached: values.isAnnualIncomeCertificateAttached,
                    isItrReturnAttached: values.isItrReturnAttached,
                    itrCertificateUrl: values.itrCertificateUrl,
                    isFatherAlive: values.isFatherAlive,
                    deathCertificateUrl: values.deathCertificateUrl,
                    areParentsDivorced: values.areParentsDivorced,
                    divorceCertificateUrl: values.divorceCertificateUrl
                }


                formData.append('jsonData', JSON.stringify(familyIncomeDetailsForm));

                // Sending data to server
                let sendFamilyIncomeDetailsFormResponse = await applicationCommunication.uploadFamilyIncomeDetailsForm(formData, applicationId, "SAVE");

                // Handling response from the server
                if (sendFamilyIncomeDetailsFormResponse.data.status === "FORM_SAVED_SUCCESSFULLY" || sendFamilyIncomeDetailsFormResponse.status === "FORM_SAVED_SUCCESSFULLY") {

                    showModal(sendFamilyIncomeDetailsFormResponse.data.message);
                    if (saveType === "SAVE") {
                    } else {
                        history.push(`/user/application/family-details?applicationId=${applicationId}`);
                    }
                }
                if (sendFamilyIncomeDetailsFormResponse.data.status === "VALIDATION_FAILED" || sendFamilyIncomeDetailsFormResponse.status === "VALIDATION_FAILED") {
                    showModal(sendFamilyIncomeDetailsFormResponse.data.validationErrorsList[0].message || sendFamilyIncomeDetailsFormResponse.validationErrorsList[0].message);
                    hideLoading();
                    return false;
                }

                showModal(sendFamilyIncomeDetailsFormResponse.data.message || sendFamilyIncomeDetailsFormResponse.message);
                hideLoading();
                setDisableActions(false);
                return false;

            }
            catch (errors) {
                hideLoading();
                setDisableActions(false);
                showModal(errors.message);
                return false;
            }
        },
        validationSchema: validationSchema,
    });

    return (
        <section className="application_flow_big">
            <div className="family_income_details_main">
                <div className="container">
                    {isLoading.value ?
                        <LoadingForms message={isLoading.message} />
                        :
                        <div className="row">
                            <div className="col-12">
                                <FormFieldTitle name="Income of all the members of the family of previous year" />
                                <form onSubmit={handleSubmit}>
                                    {/*--Family annual income details--*/}
                                    <div className="row mt-4">
                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Income of all the members of the family *" />
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Total Annual Income</Label>
                                            <input type="number" placeholder="E.g. 52314" name="totalAnnualIncome" onChange={handleChange} value={values.totalAnnualIncome} className={`form-control ${errors.totalAnnualIncome ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors.totalAnnualIncome}</div>
                                        </div>
                                    </div>
                                    {/*-- Annual income certificate details--*/}
                                    <div className="row mt-3">
                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Annual Income Certificate *" />
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-12">
                                            <div className="row">
                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Annual Income Certificate Attached</Label>

                                                <div className="col-lg-4 col-md-4 col-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                    <input type="radio" onChange={() => setFieldValue("isAnnualIncomeCertificateAttached", true)} checked={values.isAnnualIncomeCertificateAttached === null ? (values.isAnnualIncomeCertificateAttached) : Boolean(values.isAnnualIncomeCertificateAttached)} value="true" name="annualIncomeCertificateAttachedRadio" id="annualIncomeTrue" className={`form-check-input mx-3 ${errors.isAnnualIncomeCertificateAttached ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.isAnnualIncomeCertificateAttached}</div>
                                                </div>

                                                <div className="col-lg-4 col-md-4 col-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                    <input type="radio" onChange={() => setFieldValue("isAnnualIncomeCertificateAttached", false)} checked={values.isAnnualIncomeCertificateAttached === null ? (values.isAnnualIncomeCertificateAttached) : Boolean(!values.isAnnualIncomeCertificateAttached)} value="false" name="annualIncomeCertificateAttachedRadio" id="annualIncomeFalse" className={`form-check-input mx-3 ${errors.isAnnualIncomeCertificateAttached ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.isAnnualIncomeCertificateAttached}</div>
                                                </div>

                                            </div>
                                        </div>
                                        {values.isAnnualIncomeCertificateAttached === true ?
                                            <>
                                                <div className="col-lg-4 col-md-4 col-8" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div>
                                                        <input type="file" name="incomeCertificate" onChange={(e) => { handleChange(e); onFileSelect(e, "INCOME") }} value={values.incomeCertificateUrl?.filename} className={`form-control ${errors.incomeCertificate ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.incomeCertificateUrl}</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-md-1 col-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <button type="button" onClick={() => { previewDocumentsHandler(values.incomeCertificateUrl) }}>Preview</button>
                                                </div>
                                                {isSubmitted ?
                                                    <span></span>
                                                    :
                                                    <div style={{ textAlign: "end" }}>
                                                        <span style={{ fontSize: "11px", color: "rgb(109, 141, 173)", marginRight: "11%" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                    </div>
                                                }
                                            </>
                                            : <span></span>
                                        }
                                    </div>

                                    {/*-- ITR return details--*/}
                                    <div className="row mt-3">
                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="ITR Return *" />
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-12">
                                            <div className="row">

                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">ITR return Attached</Label>
                                                <div className="col-lg-4 col-md-4 col-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                    <input type="radio" onChange={() => setFieldValue("isItrReturnAttached", true)} checked={values.isItrReturnAttached === null ? (values.isItrReturnAttached) : Boolean(values.isItrReturnAttached)} value="true" name="isItrReturnAttachedRadio" id="isItrReturnAttachedTrue" className={`form-check-input mx-3 ${errors.isItrReturnAttached ? "is-invalid" : ""}`} disabled={disabled} />
                                                </div>

                                                <div className="col-lg-4 col-md-4 col-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                    <input type="radio" onChange={() => setFieldValue("isItrReturnAttached", false)} checked={values.isItrReturnAttached === null ? (values.isItrReturnAttached) : Boolean(!values.isItrReturnAttached)} value="false" name="isItrReturnAttachedRadio" id="annualIncomeFalse" className={`form-check-input mx-3 ${errors.isItrReturnAttached ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.isItrReturnAttached}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {values.isItrReturnAttached === true ?
                                            <>
                                                <div className="col-lg-4 col-md-4 col-8" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div>
                                                        <input type="file" name="itrCertificate" onChange={(event) => { handleChange(event); onFileSelect(event, "ITR") }} value={values.itrCertificateUrl?.filename} className={`form-control ${errors.itrCertificate ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.itrCertificateUrl}</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-md-1 col-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <button type="button" onClick={() => { previewDocumentsHandler(values.itrCertificateUrl) }}>Preview</button>
                                                </div>
                                                {isSubmitted ?
                                                    <span></span>
                                                    :
                                                    <div style={{ textAlign: "end" }}>
                                                        <span style={{ fontSize: "11px", color: "rgb(109, 141, 173)", marginRight: "11%" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                    </div>
                                                }
                                            </>
                                            : <span></span>
                                        }
                                    </div>

                                    {/*-- Father is alive or not details--*/}
                                    <div className="row mt-3">
                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Your Father is alive? *" />
                                        </div>
                                        <div className=" col-lg-5 col-md-5 col-8">
                                            <div className="row">
                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Alive or Not</Label>
                                                <div className="col-lg-4 col-md-4 col-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                    <input type="radio" onChange={() => setFieldValue("isFatherAlive", true)} checked={values.isFatherAlive === null ? (values.isFatherAlive) : Boolean(values.isFatherAlive)} value="true" name="isFatherAliveRadio" id="isFatherAliveTrue" className={`form-check-input mx-3 ${errors.isFatherAlive ? "is-invalid" : ""}`} disabled={disabled} />
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                    <input type="radio" onChange={() => setFieldValue("isFatherAlive", false)} checked={values.isFatherAlive === null ? (values.isFatherAlive) : Boolean(!values.isFatherAlive)} value="false" name="isFatherAliveRadio" id="isFatherAliveFalse" className={`form-check-input mx-3 ${errors.isFatherAlive ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.isFatherAlive}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {values.isFatherAlive === false ?
                                            <>
                                                <div className="col-lg-4 col-md-4 col-8" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div>
                                                        <input type="file" name="deathCertificate" onChange={(event) => { handleChange(event); onFileSelect(event, "DEATH_CERTIFICATE") }} value={values.deathCertificateUrl?.filename} className={`form-control ${errors.deathCertificate ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.deathCertificateUrl}</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-md-1 col-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <button type="button" onClick={() => { previewDocumentsHandler(values.deathCertificateUrl) }}>Preview</button>
                                                </div>
                                                {isSubmitted ?
                                                    <span></span>
                                                    :
                                                    <div style={{ textAlign: "end" }}>
                                                        <span style={{ fontSize: "11px", color: "rgb(109, 141, 173)", marginRight: "11%" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                    </div>
                                                }
                                            </>
                                            : null

                                        }

                                    </div>

                                    {/*-- Marital status details--*/}
                                    <div className="row mt-3">
                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Mother - Father Marital Status*" />
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-8">
                                            <div className="row">
                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Divorced</Label>
                                                <div className="col-lg-4 col-md-4 col-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                    <input onChange={() => setFieldValue("areParentsDivorced", true)} checked={values.areParentsDivorced === null ? (values.areParentsDivorced) : Boolean(values.areParentsDivorced)} type="radio" value="true" name="areParentsDivorcedRadio" id="areParentsDivorcedTrue" className={`form-check-input mx-3 ${errors.areParentsDivorced ? "is-invalid" : ""}`} disabled={disabled} />
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                    <input onChange={() => setFieldValue("areParentsDivorced", false)} checked={values.areParentsDivorced === null ? (values.areParentsDivorced) : Boolean(!values.areParentsDivorced)} type="radio" value="false" name="areParentsDivorcedRadio" id="areParentsDivorcedFalse" className={`form-check-input mx-3 ${errors.areParentsDivorced ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.areParentsDivorced}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {values.areParentsDivorced ?
                                            <>
                                                <div className="col-lg-4 col-md-4 col-8" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div>
                                                        <input type="file" name="divorceCertificate" onChange={(event) => { handleChange(event); onFileSelect(event, "DIVORCE_CERTIFICATE") }} value={values.divorceCertificateUrl?.filename} className={`form-control ${errors.divorceCertificate ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.divorceCertificateUrl}</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-md-1 col-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <button type="button" onClick={() => { previewDocumentsHandler(values.divorceCertificateUrl) }}>Preview</button>
                                                </div>
                                                {isSubmitted ?
                                                    <span></span>
                                                    :
                                                    <div style={{ textAlign: "end" }}>
                                                        <span style={{ fontSize: "11px", color: "rgb(109, 141, 173)", marginRight: "11%" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                    </div>
                                                }
                                            </>
                                            : null
                                        }
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form_button">
                                                {splitUrlArray[4] === "user" ?
                                                    <button type="button" onClick={() => { history.push("/user"); dispatch({ type: "SHOW_APPLICATION_PROCESS_NAV" }); }} className="btn form_cancel_btn" disabled={disableActions}>Cancel</button>
                                                    : <span></span>
                                                }
                                                {
                                                    isSubmitted ?
                                                        <div className="m-1"></div>
                                                        :
                                                        <button type="button" onClick={() => {
                                                            setSaveType(value => value = "SAVE");
                                                            handleSubmit();
                                                        }} className="btn form_save-btn" disabled={disableActions}>Save</button>
                                                }
                                                {
                                                    isSubmitted ?
                                                        (splitUrlArray[4] === "user") ?
                                                            <button type="button" onClick={() => {
                                                                loadNextForm();
                                                            }} className="btn form_submit_btn">Next</button>
                                                            : <span></span>
                                                        :
                                                        <button type="button" onClick={() => {
                                                            setSaveType(value => value = "SAVE_AND_NEXT");
                                                            handleSubmit();
                                                        }} className="btn form_submit_btn" disabled={disableActions}>Save & Next</button>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>
    );
}

export default FamilyIncomeDetails;