import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// import H4 from '../text-components/H4';
import H5 from '../text-components/H5';
import PrivacyH3 from '../text-components/PrivacyH3';
import PrivacyP from '../text-components/PrivacyP';


const WebsitePolicy = () => {

  const [isLoading, setIsLoading] = useState({ message: "", value: false });

  //spinner show and hide function declaration
  function showLoading(message) {
    setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
  }

  function hideLoading() {
    setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
  }

  let history = useHistory();
  const goToPreviousPath = () => {
    history.goBack()
  }

  return (
    <>
      <section className="mb-3">
        <div className="row">
          <div className="col-2 button_div">
            <div className="dashboard_delete_button">
              <button type="button" className="btn" onClick={goToPreviousPath} >

                {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H5>Back</H5>}

              </button>
            </div>
          </div>
        </div>
      </section>

      <div className='container'>
        <div className='row mt-3'>
          <div className='col-12 privacy_policy'>

            <PrivacyH3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontWeight: "Bold" }} >
              Privacy Policy
            </PrivacyH3>
            <br></br>

            <PrivacyP whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
              As a general rule, this website does not collect Personal Information about you when you visit the site. You can generally visit the site without revealing Personal Information, unless you choose to provide such information.
            </PrivacyP>

            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
              Site Visit Data
            </H5>

            <PrivacyP whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
              This website records your visit and logs the following information for statistical purposes your server's address; the name of the top-level domain from which you access the Internet (for example, .gov, .com, .in, etc.); the type of browser you use; the date and time you access the site; the pages you have accessed and the documents downloaded and the previous Internet address from which you linked directly to the site.
              <br></br>
              We will not identify users or their browsing activities, except when a law enforcement agency may exercise a warrant to inspect the service provider's logs.
            </PrivacyP>

            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
              Cookies
            </H5>

            <PrivacyP whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
              A cookie is a piece of software code that an internet web site sends to your browser when you access information at that site.
            </PrivacyP>

            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
              Email Management
            </H5>

            <PrivacyP whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
              Your email address will only be recorded if you choose to send a message. It will only be used for the purpose for which you have provided it and will not be added to a mailing list. Your email address will not be used for any other purpose, and will not be disclosed, without your consent.
            </PrivacyP>

            <br></br>
            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
              Collection of Personal Information
            </H5>

            <PrivacyP whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
              If you are asked for any other Personal Information you will be informed how it will be used if you choose to give it. If at any time you believe the principles referred to in this privacy statement have not been followed, or have any other comments on these principles, please notify the webmaster through the contact us page.
            </PrivacyP>

            <br></br>
            <PrivacyP whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
              <span style={{ fontWeight: "Bold" }} >Note:</span> The use of the term "Personal Information" in this privacy statement refers to any information from which your identity is apparent or can be reasonably ascertained.
            </PrivacyP>

          </div>
        </div>

        <div className='row mt-3'>
          <div className='col-12'>

            <PrivacyH3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontWeight: "Bold" }} >
              Copyright Policy
            </PrivacyH3>
            <br></br>

            <PrivacyP whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
              This contents of this website may not be reproduced partially or fully, without due permission from Social Justice & Special Assistance Department, If referred to as a part of another publication, the source must be appropriately acknowledged. The contents of this website can not be used in any misleading or objectionable context.          </PrivacyP>

          </div>
        </div>

        <div className='row mt-3'>
          <div className='col-12'>

            <PrivacyH3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontWeight: "Bold" }} >
              Hyperlinking Policy
            </PrivacyH3>
            <br></br>

            <PrivacyP whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
              At many places in this website, you shall find links to other websites/portals. This links have been placed for your convenience. Social Justice & Special Assistance Department is not responsible for the contents and reliability of the linked websites and does not necessarily endorse the views expressed in them. Mere presence of the link or its listing on this website should not be assumed as endorsement of any kind. We cannot guarantee that these links will work all the time and we have no control over availability of linked pages.
            </PrivacyP>

          </div>
        </div>

      </div>
    </>

  );
}
export default WebsitePolicy;