import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingForms from "../../shared-components/LoadingForms";
import { frontOfficeCommunication } from "../../communications/front-office-communication";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import H6 from '../../text-components/H6';
import { confirm } from "react-confirm-box";
import ReactPaginate from "react-paginate";



const MeritList = () => {

    const history = useHistory();

    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [meritsApplications, setMeritsApplications] = useState([]);
    const [applicationIds, setApplicationIds] = useState([]);
    const [quotaDetails, setQuotaDetails] = useState([]);
    const [filteredApplication, setFilteredApplication] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;
    const userAccessType = useSelector((state) => state.isOfficeUserAuthenticated?.officeUser?.access);

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    // Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchMeritListBySearch(searchValue, selectedPage + 1);
        } else {
            initializeMeritList(selectedPage + 1);
        }
    }
    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchMeritListBySearch(searchString, currentPage + 1);
        } else {
            const acknowledgedFromServer = await frontOfficeCommunication.getMeritListApplications()
            if (acknowledgedFromServer?.status === "SUCCESS") {
                // console.log("empty string", acknowledgedFromServer?.applications);
                setMeritsApplications(acknowledgedFromServer?.applications);
                setFilteredApplication(acknowledgedFromServer?.applications);
                setQuotaDetails(acknowledgedFromServer?.quota);
                setPageCount(acknowledgedFromServer?.totalPages)
            } else if (acknowledgedFromServer?.status === "UNAUTHORIZED_ACCESS") {
                showModal(acknowledgedFromServer?.message);
                history.push(`/front-office/login`);
            } else {
                setMeritsApplications([]);
                setFilteredApplication([]);
                setQuotaDetails([]);
                setPageCount(0)
            }
        }
    }

    //get Merit List By Search
    const fetchMeritListBySearch = async (searchString, page) => {
        try {
            // console.log(searchString, page);
            const acknowledgedFromServer = await frontOfficeCommunication.getMeritListBySearch(searchString, page);
            if (acknowledgedFromServer?.data?.status === "SUCCESS") {
                // console.log(acknowledgedFromServer?.data?.applications);
                setMeritsApplications(acknowledgedFromServer?.data?.applications);
                setFilteredApplication(acknowledgedFromServer?.data?.applications);
                setQuotaDetails(acknowledgedFromServer?.data?.quota);
                setPageCount(acknowledgedFromServer?.data?.totalPages)
            } else if (acknowledgedFromServer?.data?.status === "UNAUTHORIZED_ACCESS") {
                showModal(acknowledgedFromServer?.message);
                history.push(`/front-office/login`);
            } else {
                setMeritsApplications([]);
                setFilteredApplication([]);
                setQuotaDetails([]);
                setPageCount(0)
            }
        } catch (error) {
            showModal(error?.message);
        }
    }


    //get Merit List on Initial Load
    async function initializeMeritList(page) {
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("merit-list")) {
            dispatch({ type: "ACTIVE_TAB", payload: "MERIT_LIST" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_TAB" });
        }
        showLoading("Loading...");

        const acknowledgedFromServer = await frontOfficeCommunication.getMeritListApplications(page);
        // console.log("insice caode",acknowledgedFromServer);
        if (acknowledgedFromServer?.status === "SUCCESS") {
            setMeritsApplications(acknowledgedFromServer?.applications);
            setFilteredApplication(acknowledgedFromServer?.applications);
            setQuotaDetails(acknowledgedFromServer?.quota)
            setPageCount(acknowledgedFromServer?.totalPages)
        } else if (acknowledgedFromServer?.status === "UNAUTHORIZED_ACCESS") {
            history.push(`/front-office/login`);
            return;
        }
        else {
            setMeritsApplications([]);
            setFilteredApplication([]);
            setQuotaDetails([])
            setPageCount(0)
        }
        hideLoading();
    }
    useEffect(() => {
        initializeMeritList();
    }, []);


    const showChangeQuota = () => {
        history.push("/front-office/dashboard/change-quota");
    }


    // set ids in to state
    const handleOnChange = (e, stream, courseType) => {
        let checked = e.target.checked;
        let id = e.target.value;
        let quotaArray = quotaDetails;
        let idArray = [];
        if (checked) {
            // check application course and courseType in quota 
            for (let i = 0; i < quotaDetails.length; i++) {
                if (quotaDetails[i].courseType === courseType && quotaDetails[i].stream === stream) {
                    // check course and courseType and update quota for this course and courseType
                    quotaArray[i].occupied = quotaArray[i].occupied + 1;
                    // add application id in to state
                    idArray.push(id);
                    setApplicationIds((value) => { return [...applicationIds, ...idArray] });
                }
            }
        } else {
            // decrease occupied quota for this course and courseType
            for (let i = 0; i < quotaArray.length; i++) {
                if (quotaArray[i].courseType === courseType && quotaArray[i].stream === stream) {
                    quotaArray[i].occupied = quotaArray[i].occupied - 1;
                }
            }
            // remove application id from state
            // remove the id from the idArray
            idArray.indexOf(id);
            setApplicationIds(
                applicationIds.filter((Id) => Id !== id)
            );
        }
        // update quota details in state
        setQuotaDetails(quotaDetails);
        return;
    }


    // send merit applications list to server
    const sendSelectedApplicationsListToServer = async () => {
        //check if applicationIds is empty
        if (applicationIds.length !== 0) {
            let fulledQuota = [];
            meritsApplications.forEach((application) => {
                for (let i = 0; i < applicationIds.length; i++) {
                    if (application.applicationId === applicationIds[i]) {
                        for (let j = 0; j < quotaDetails.length; j++) {
                            if (quotaDetails[j].courseType === application.courseType && quotaDetails[j].stream === application.stream) {
                                if (quotaDetails[j].occupied > quotaDetails[j].quota) {
                                    const quotaFullFilteredMessage = fulledQuota?.filter((ele) => ele !== `${quotaDetails[j].courseType} ${quotaDetails[j].stream}`)
                                    fulledQuota = [...quotaFullFilteredMessage, `${quotaDetails[j].courseType} ${quotaDetails[j].stream}`];
                                }
                            }
                        }
                    }
                }
            });

            if (fulledQuota.length !== 0) {
                const confirmBox = await confirm("Quota for " + fulledQuota.join(",") + " is full . Do you want to change the quota ?");
                // showModal("Quota for " + fulledQuota.join(",") + " is full . Do you want to change the quota count for " + fulledQuota.join(",") + " ?");
                if (confirmBox) {
                    history.push("/front-office/dashboard/change-quota");
                }

                return;
            }

            showLoading("Loading...");
            let action = "APPROVED"
            const submitSelectedApplicationsList = await frontOfficeCommunication.submitSelectedApplicationsList(applicationIds, action);
            const updatedQuotaFromServer = await frontOfficeCommunication.updateQuota(quotaDetails);
            if (submitSelectedApplicationsList.status === "SUCCESS" && updatedQuotaFromServer.status === "SUCCESS") {
                // calling refreshPage function after 5 sec to show model before refreshing the page
                showModal(submitSelectedApplicationsList.message);
                initializeMeritList();
                hideLoading();
                return;
            }
            showModal(submitSelectedApplicationsList.message);
            hideLoading();
        }
        else {
            showModal("Please select at least one record");
        }
        return;
    }

    // send application to waiting list 
    const sendApplicationsToWaitingListToServer = async () => {
        if (applicationIds.length !== 0) {
            showLoading("Loading...");
            let action = "WAITING"
            const submitApplicationToWaitingList = await frontOfficeCommunication.sendApplicationToWaitingList(applicationIds, action);
            if (submitApplicationToWaitingList.status === "SUCCESS") {
                // calling refreshPage function after 5 sec to show model before refreshing the page
                initializeMeritList();
                showModal(submitApplicationToWaitingList.message);
                hideLoading();
                return;
            }
            else if (submitApplicationToWaitingList.status === "UNAUTHORIZED_ACCESS") {
                history.push(`/front-office/login`);
                return;
            }
            showModal(submitApplicationToWaitingList.message);
            hideLoading();
        }
        else {
            showModal("Please select atleast one record");
        }
        return;
    }


    async function goToApplication(applicationId) {
        showLoading("Loading...");
        const accessApplicationFromServer = await frontOfficeCommunication.accessToApplication(applicationId);
        if (accessApplicationFromServer.status === "APPLICATION_AVAILABLE") {
        }
        history.push(`/front-office/dashboard/application/?applicationId=${applicationId}&action=MERIT_LIST`);
        hideLoading();
        showModal(accessApplicationFromServer.message);
    }




    function onChangeFilterText(event, searchFor) {

        if (searchFor === "APPLICATION_ID") {
            const filterData = meritsApplications.filter((ele) => ele.shortId.search(event.target.value) === -1 ? false : true);
            setFilteredApplication(filterData);
        }

        if (searchFor === "STATUS") {
            const filterData = meritsApplications.filter((ele) => ele.frontOfficeStatus.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
            setFilteredApplication(filterData);
        }

        if (searchFor === "COURSE") {
            const filterData = meritsApplications.filter((ele) => ele.courseType.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
            setFilteredApplication(filterData);
        }

        if (searchFor === "STREAM") {
            const filterData = meritsApplications.filter((ele) => ele.stream.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
            setFilteredApplication(filterData);
        }
    }


    return (
        <>
            {/* <div className="row mt-3 container" style={{ "margin": "auto" }}>
                <div className="back_office_search_box">
                    <div className="row">
                        <div className="col-3 search_box">
                            <input type="search" className="form-control" placeholder="Application Id" onChange={(event) => onChangeFilterText(event, "APPLICATION_ID")} />
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <div className="col-3 search_box">
                            <input type="search" className="form-control" placeholder="Course" onChange={(event) => onChangeFilterText(event, "COURSE")} />
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <div className="col-3 search_box">
                            <input type="search" className="form-control" placeholder="Stream" onChange={(event) => onChangeFilterText(event, "STREAM")} />
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='container-fluid'>
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :
                    <>
                        <div className="row search_wrapper">
                            <div className="col-lg-4 col-md-4 ps-0">
                                <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Application ID, University, Rank, Course..." />
                            </div>
                            <div className="col-lg-8 col-md-8 pe-0 pagination_outer_wrapper">
                                <div className="pagination_inner_wrapper">
                                    {pageCount > 1 &&
                                        <ReactPaginate
                                            previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            forcePage={currentPage}
                                            activeClassName={"active"} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='wholeTable'>
                            <div className='frontOfficeTable'>
                                <div className='frontOfficeTableHeader'>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder srNo'>
                                        <span><H6>Serial No.</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder ApplicationID' >
                                        <span><H6>Application ID</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder University'>
                                        <span><H6>University</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder UniversityRank'>
                                        <span><H6>University Rank</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Income'>
                                        <span><H6>Income</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Course'>
                                        <span><H6>Course</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Stream'>
                                        <span><H6>Stream</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Age'>
                                        <span><H6>Age</H6></span>
                                    </div>
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder OtherScholar'>
                                        <span><H6>Other Scholarship</H6></span>
                                    </div>
                                    {/* <div className='frontOfficeTableHeaderRow tableRowRightBorder percentage'>
                                    <span><H6>Percentage</H6></span>
                                </div> */}
                                    <div className='frontOfficeTableHeaderRow tableRowRightBorder Attempt'>
                                        <span><H6>Attempt</H6></span>
                                    </div>
                                    {/* <div className='frontOfficeTableHeaderRow tableRowRightBorder Status'>
                                    <span><H6>Status</H6></span>
                                </div> */}
                                    <div className='frontOfficeTableHeaderRow Rating'>
                                        <span><H6>Marks</H6></span>
                                    </div>

                                </div>
                                {
                                    filteredApplication?.length > 0 ?
                                        <div>
                                            {filteredApplication?.map((application, index) => {
                                                const { age, shortId, applicationId, stream, courseType, frontOfficeStatus, income, otherScholarship, university, universityRank, marks, percentage, attempt } = application;
                                                return (
                                                    <div key={index}>

                                                        <div className='frontOfficeTableBody'>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder srNo'>
                                                                <span>
                                                                    <input type="checkbox" value={applicationId} onClick={(e) => { handleOnChange(e, stream, courseType) }} disabled={userAccessType === "READ" ? true : false} />
                                                                    <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  {limit * currentPage + (index + 1)}</H6>
                                                                </span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder applicationId' >
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                    <a onClick={() => { goToApplication(applicationId) }}>
                                                                        {shortId}
                                                                    </a>
                                                                </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder University '>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {university} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder UniversityRank'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  {universityRank} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Income'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {income} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Course'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {courseType}</H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Stream'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {stream} </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Age'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                    {`${age.year} Y ${age.month} M ${age.day} D`}
                                                                </H6></span>

                                                            </div>
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder OtherScholar'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  {otherScholarship === false ? "yes" : "No"} </H6></span>

                                                            </div>
                                                            {/* <div className='frontOfficeTableBodyRow tableRowRightBorder percentage'>
                                                            <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {percentage} </H6></span>

                                                        </div> */}
                                                            <div className='frontOfficeTableBodyRow tableRowRightBorder Attempt'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {attempt} </H6></span>

                                                            </div>
                                                            {/* <div className='frontOfficeTableBodyRow tableRowRightBorder Status'>
                                                            <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {frontOfficeStatus} </H6></span>

                                                        </div> */}
                                                            <div className='frontOfficeTableBodyRow Rating'>
                                                                <span><H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  {marks} </H6></span>

                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        :
                                        <div className="text-center mt-5">
                                            <p>Records not available </p>
                                        </div>
                                }

                            </div>

                        </div>
                    </>
                }
            </div>



            <div className='Front_Office_Btn'>

                <div className="dashboard_delete_button">
                    <button type="button" className="btn" onClick={() => showChangeQuota()}  >
                        {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6> Change Quota</H6>}
                    </button>
                </div>

                <div className="dashboard_delete_button">
                    <button type="button" className="btn" onClick={() => sendSelectedApplicationsListToServer()} disabled={userAccessType === "READ" ? true : false} >
                        {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6>Final Submit</H6>}
                    </button>
                </div>

                <div className="dashboard_delete_button">

                    <button type="button" className="btn" onClick={() => sendApplicationsToWaitingListToServer()} disabled={userAccessType === "READ" ? true : false} >
                        {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H6> Waiting List</H6>}
                    </button>
                </div>

            </div>


        </>

    )
};

export default MeritList;
