import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import H4 from "../text-components/H4";
import P from "../text-components/P";
import LoadingForms from "../shared-components/LoadingForms";
import { communication } from "../communications/communication";
import { removeUnderscoreFromText } from "../utlis/helper"



const UserRefund = () => {

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });


    const [totalAmount, setTotalAmount] = useState(0);
    const [totalRefundAmount, setRefundAmount] = useState(0);

    const [refundData, setRefundData] = useState([]);
    const [comment, setComment] = useState([]);

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    let fullUrl = window.location.href;

    async function initialLoad() {
        // to show active tab
        let fullUrl = window.location.href;
        let splitLatestNotificationUrl = fullUrl?.split('/');
        if (splitLatestNotificationUrl?.includes("refund")) {
            dispatch({ type: "SHOW_ACTIVE_APPLICATION_PROCESS_TAB", payload: "REFUND" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_APPLICATION_PROCESS_TAB" });
        }

        if (splitLatestNotificationUrl?.includes("application")) {
            dispatch({ type: "HIDE_APPLICATION_PROCESS_NAV" });
        } else {
            dispatch({ type: "SHOW_APPLICATION_PROCESS_NAV" });
        }

    }

    async function getRefundData() {
        showLoading("Loading...");
        const refundResponseFromServer = await communication.getRefunds();
        if (refundResponseFromServer.status === "SUCCESS") {
            setRefundData(refundResponseFromServer?.refund?.remittance);
            setTotalAmount(refundResponseFromServer?.refund?.amountDetails?.totalAnnualAmount);
            setRefundAmount(refundResponseFromServer?.refund?.amountDetails?.totalRefundAmount);
            setComment(refundResponseFromServer?.refund?.amountDetails?.comment);
            hideLoading();
        }
        else {
            showModal(refundResponseFromServer.message);
            hideLoading();
        }
    }

    useEffect(() => {
        getRefundData();
    }, [])

    useEffect(() => {
        initialLoad();
    }, [fullUrl])



    return (
        <section className="application_flow_big" >
            {isLoading.value ? <LoadingForms message={isLoading.message} />
                : <div className="container">
                    <div className="row user_refund">
                        <div className="col-12 p-0">
                            <div className="dashboard_back_office">

                                {/*----- card header section---*/}
                                <div className="dashboard_view_data_heading">
                                    <div className="row m-0">
                                        <div className="col-2 p-0 view_dashboard">
                                            <span><H4 style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }}>Serial No.</H4></span>
                                        </div>
                                        <div className="col-8 p-0 view_dashboard ps-3" style={{ textAlign: "left" }}>
                                            <span><H4 style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }}>Remittance</H4></span>
                                        </div>
                                        <div className="col-2 p-0 ">
                                            <span><H4 style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }}>Amount</H4></span>
                                        </div>
                                    </div>
                                </div>
                                {
                                    refundData?.length > 0 ?

                                        <>
                                            {refundData?.map((value, index) => {
                                                return (
                                                    <div className="dashboard_view_data_main" key={index}>
                                                        <div className="dashboard_view_data">
                                                            <div className="row m-0">
                                                                <div className="col-2 p-0 view_dashboard">
                                                                    <span>
                                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{index + 1}</H4>
                                                                    </span>
                                                                </div>
                                                                <div className="col-8 p-0 ps-3 view_dashboard" style={{ textAlign: "left" }}>
                                                                    <span>
                                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{removeUnderscoreFromText(value.milestoneName)}</H4>
                                                                    </span>
                                                                </div>
                                                                <div className="col-2 p-0">
                                                                    <span ><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{value.amount}</H4></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                        :
                                        <div className="row m-5">
                                            <div className="col-12 p-0 text-center">
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Record not available.</H4>
                                            </div>
                                        </div>
                                }
                                <div className="row mt-2">
                                    {
                                        refundData?.length > 0 ?
                                            <div className="col-lg-5 col-md-6 refund_preview_comment">
                                                <label className="mb-1"><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Comment</P></label>
                                                <textarea className="form-control" rows="3" placeholder="Type Your Comment Here" value={comment?.length > 0 ? comment[0] : "No Comment"} disabled />
                                            </div>
                                            : <span></span>
                                    }
                                    <div className="col-lg-7 col-md-6 refund_preview_total_amount" style={{ textAlign: "left" }}>
                                        {
                                            (!!totalAmount) ?
                                                <div className="row">
                                                    <div className="col-lg-8 col-md-7" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                        <label><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Total</P></label>
                                                    </div>
                                                    <div className="col-lg-4 col-md-5">
                                                        <input type="number" className="form-control" disabled value={totalAmount} />
                                                    </div>
                                                </div>

                                                :
                                                ""
                                        }
                                        {
                                            (!!totalRefundAmount) ?
                                                <div className="row mt-3">
                                                    <div className="col-lg-8 col-md-7" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                        <label><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Amount to be refund 10%</P></label>
                                                    </div>
                                                    <div className="col-lg-4 col-md-5">
                                                        <input type="number" className="form-control" value={totalRefundAmount} disabled />
                                                    </div>
                                                </div>
                                                : ""
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }

        </section>
    );

}
export default UserRefund;