import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import H5 from "../../text-components/H5";
import H3 from "../../text-components/H3";



const UsersMaintainanceSave = () => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const location = useLocation();

    const [showPassword, setShowPassword] = useState("password");

    const [allDepartmentsMaintenanceData, setAllDepartmentsMaintenanceData] = useState([]);

    const [selectedDesignationsArray, setSelectedDesignationsArray] = useState([]);

    const [userData, setUserData] = useState({});

    const history = useHistory();


    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    //**********Signing Authority Name Array*** */
    const [signingAuthorityArray, setSigningAuthorityArray] = useState(["COMMISSIONER"]);

    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }



    // Department Data fetched from the server 
    useEffect(async () => {
        showLoading("Loading...");
        dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "USERS_MAINTENANCE_UPDATE" });
        const departmentsFromServer = await adminCommunication.getAllDepartments();
        const designationFromServer = await adminCommunication.getAllDesignations();
        let designationArray = designationFromServer.designations;
        if (departmentsFromServer.status === "SUCCESS" && designationFromServer.status === "SUCCESS") {
            let departmentsArray = departmentsFromServer.departments;
            setAllDepartmentsMaintenanceData(value => {
                return [...value, ...departmentsArray]
            });

        } else {
            showModal(departmentsFromServer.message);
        }

        // getting designation id From queryParams 
        const queryParams = new URLSearchParams(location.search);

        const userIdFromParams = queryParams.get('userId');

        const UserDataFromServer = await adminCommunication.getUserById(userIdFromParams);

        if (UserDataFromServer.status === "SUCCESS") {
            setUserData(value => value = UserDataFromServer.user);
            setDesignationArrayBySelectedDepartment(UserDataFromServer.user.department);
        }
        else {
            showModal(UserDataFromServer.message);
        }

        // distinct name and id from designations array
        let distinctDesignationArray = [];
        designationArray.forEach(designation => {
            const { _id, name } = designation;
            // check in department array if the department id is present 
            let isPresent = departmentsFromServer.departments.find(department => department._id === UserDataFromServer.user.department);
            isPresent.designationArray.forEach(designation => {

                if (designation === _id) {
                    distinctDesignationArray.push({ id: _id, name: name });
                }
            });
        })

        setSelectedDesignationsArray(value => {
            return [...value, ...distinctDesignationArray]
        });

        hideLoading();
    }, []);

    function setDesignationArrayBySelectedDepartment(value) {
        allDepartmentsMaintenanceData.forEach(async (department) => {
            if (department._id === value) {
                let designationIdArray = [];
                let designationArray = [];

                for (let j = 0; j < department.designationArray.length; j++) {
                    designationIdArray.push(department.designationArray[j]);
                }


                designationIdArray.forEach((id) => {
                    let designationFromServer = adminCommunication.getDesignationById(id);
                    designationArray.push(designationFromServer);
                })

                let designationResult = await Promise.all(designationArray);

                let designationNameArray = [];

                for (let i = 0; i < designationResult.length; i++) {

                    if (i < designationResult.length) {
                        let id = designationResult[i].designation._id;
                        let name = designationResult[i].designation.name
                        designationNameArray.push({ id, name });
                    }
                    else {
                        break;
                    }
                }

                setSelectedDesignationsArray(val => val = designationNameArray);
            }
        });

    }




    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        fullName: userData.fullName,
        password: "",
        department: userData.department,
        designation: userData.designation,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        alternatePhoneNumber: userData.alternatePhoneNumber
    }

    //****Validation for Designation Create form*****/
    const validationSchema = Yup.object().shape({
        fullName: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Full Name is required"),
        password: Yup.string().matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain at least 8 characters, one lowercase, one uppercase, one number and one special case character").required("Password is required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match').required("Password is required"),
        department: Yup.string().required("Select Department "),
        designation: Yup.string().required("Select Designation "),
        email: Yup.string().email().required("Email is required"),
        phoneNumber: Yup.string()
            .required("Phone Number is required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits')
            .max(10, 'Must be exactly 10 digits'),
        alternatePhoneNumber: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits')
            .max(10, 'Must be exactly 10 digits'),

    });


    // Destructuring useFormik to get functions to control aspects of form
    // What does Formik do?
    // 1. Handling form events like submit, errors, change in value
    // 2. Fetching values from the form 
    // 3. Support for a validation library on the data fetched. We are using Yup library for validations. 
    // 4. Reset the form
    // 5. Pass initial values to the form
    // 6. Handle errors thrown by the Yup (or any validation library).
    // 7. Reinitialize form values
    // 8. Perform async operations
    const { handleSubmit, handleChange, values, errors, handleReset } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit
        onSubmit: async (values) => {
            // Show loading 
            showLoading("Loading...");

            // Send values to the server
            const UserDataFromFrontEnd = {
                fullName: values.fullName,
                password: values.password,
                department: values.department,
                designation: values.designation,
                phoneNumber: values.phoneNumber,
                alternatePhoneNumber: values.alternatePhoneNumber,
                email: values.email,
                userId: userData.userId,
            }

            const officeUserUpdateResponse = await adminCommunication.updateOfficeUser(UserDataFromFrontEnd);

            // Handle response from the server
            if (officeUserUpdateResponse.status === "SUCCESS") {
                handleReset();
                dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "USERS_MAINTENANCE_UPDATE" });
                history.push('/admin/dashboard/users-maintenance-update');

            }

            // Show response message from server in modal dialog
            showModal(officeUserUpdateResponse.message);

            // Hide spinner 
            hideLoading();
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: validationSchema,
    });




    return (

        <div >
            <section className="create_form create_designation mb-5" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000", border: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }} >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :

                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <H3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Update User</H3>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Full Name</H5>
                            </label>
                            <div className="col-sm-7">
                                <input type="text" name="fullName" onChange={handleChange} value={values.fullName} className={`form-control ${errors.fullName ? "is-invalid" : ""}`} />
                                <div className="invalid-feedback">{errors.fullName}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Password</H5>
                            </label>
                            <div className="col-sm-7 user_password_field">
                                <input type={showPassword} name="password" onChange={handleChange} value={values.password} className={`form-control ${errors.password ? "is-invalid" : ""}`} />
                                <div className="invalid-feedback">{errors.password}</div>
                                {showPassword === "password" ?
                                    darkWhiteThemeToggle === "WHITE" ? <span onClick={() => setShowPassword("text")}><i className="fa fa-eye-slash btn" style={{ color: "black" }} aria-hidden="true"></i></span> : <span onClick={() => setShowPassword("text")}><i className="fa fa-eye-slash btn" style={{ color: "white" }} aria-hidden="true"></i></span>
                                    :
                                    darkWhiteThemeToggle === "WHITE" ? <span className="showPass" onClick={() => setShowPassword("password")}><i className="fa fa-eye" style={{ color: "black" }} aria-hidden="true"></i></span> : <span className="showPass" onClick={() => setShowPassword("password")}><i className="fa fa-eye" style={{ color: "white" }} aria-hidden="true"></i></span>
                                }

                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Re-password</H5>
                            </label>
                            <div className="col-sm-7">
                                <input type="password" name="confirmPassword" onChange={handleChange} value={values.confirmPassword} className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`} />
                                <div className="invalid-feedback">{errors.confirmPassword}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Department</H5>
                            </label>

                            <div className="col-sm-7">
                                <select name="department" onChange={(e) => { handleChange(e); setDesignationArrayBySelectedDepartment(e.target.value) }} value={values.department} className={`form-select ${errors.department ? "is-invalid" : ""}`}>
                                    <option value={""} selected>Select</option>
                                    {allDepartmentsMaintenanceData?.map((department, index) => {
                                        const { _id, name } = department;
                                        return (
                                            <option key={index} value={_id}>{name}</option>
                                        );
                                    })}
                                </select>
                                <div className="invalid-feedback">{errors.department}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Designation</H5>
                            </label>

                            <div className="col-sm-7">
                                <select name="designation" value={values.designation} onChange={handleChange} className={`form-select ${errors.designation ? "is-invalid" : ""}`}>

                                    <option value={""} defaultValue>Select</option>
                                    {selectedDesignationsArray?.map((designation, index) => {
                                        const { id, name } = designation;
                                        return (
                                            <option key={index} defaultValue={values.designation === id ? true : false} value={id}>{name}</option>
                                        )
                                    })}
                                </select>
                                <div className="invalid-feedback">{errors.designation}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Mobile Number</H5>
                            </label>
                            <div className="col-sm-7">
                                <input type="number" name="phoneNumber" onChange={handleChange} value={values.phoneNumber} className={`form-control ${errors.phoneNumber ? "is-invalid" : ""}`} />
                                <div className="invalid-feedback">{errors.phoneNumber}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Alt Mobile Number</H5>
                            </label>
                            <div className="col-sm-7">
                                <input type="number" name="alternatePhoneNumber" onChange={handleChange} value={values.alternatePhoneNumber} className={`form-control ${errors.alternatePhoneNumber ? "is-invalid" : ""}`} />
                                <div className="invalid-feedback">{errors.alternatePhoneNumber}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Email</H5>
                            </label>
                            <div className="col-sm-7">
                                <input type="email" name="email" onChange={handleChange} value={values.email} className={`form-control ${errors.email ? "is-invalid" : ""}`} />
                                <div className="invalid-feedback">{errors.email}</div>
                            </div>
                        </div>


                        <button type="button" onClick={() => {
                            handleSubmit()
                        }} className="button" >
                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H5>Update</H5>}
                        </button>

                    </form>
                }
            </section>
        </div>

    );
}
export default UsersMaintainanceSave;