import H2 from "../text-components/H2";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import NavSelectedUnderline from "../shared-components/NavSelectedUnderline";
import EligibleCriteria from "./EligibleCriteria";
import ApplicationGuideline from "./ApplicationGuideline";
import DownloadsInfo from "./DownloadsInfo";
import Statistic from "./Statistic";
import ApplicationFlow from "./ApplicationFlow";
import StatisticSection from "./StatisticSection";
import DepartmentHierarchy from "./DepartmentHierarchy";
import DepartmentHierarchyImageBox from "./DepartmentHierarchyImageBox";
import { communication } from "../communications/communication"

const HomeScreenNav = () => {
    const [showStatisticSection, setShowStatisticSection] = useState(false);

    const [showDepartmentHierarchy, setShowDepartmentHierarchy] = useState(false);

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const [selectedNavItem, setSelectedNavItem] = useState("");
    const [graphData, setGraphData] = useState([]);

    useEffect(async () => {
        const results = await communication.getGraphData();
        
        if (results.status === "SUCCESS") {
            setGraphData(results.graph);
        }
    }, []);

    const selectNavItem = (navItem) => {
        setSelectedNavItem(value => value = navItem);
    }
    function statisticSectionShow() {
        setShowStatisticSection(value => value = true);
    }

    function hideStatistic() {
        setShowStatisticSection(value => value = false);
    }

    function departmentHierarchyImageBox() {
        setShowDepartmentHierarchy(value => value = true);
    }
    function hideDepartmentHierarchy() {
        setShowDepartmentHierarchy(value => value = false);
    }

    return (
        <>
            {
                showStatisticSection ?
                    <StatisticSection hideStatistic={hideStatistic} graph={graphData} />
                    :
                    <span></span>
            }

            {
                showDepartmentHierarchy ?
                    <DepartmentHierarchyImageBox hideDepartmentHierarchy={hideDepartmentHierarchy} />
                    :
                    <span></span>
            }

            <div>
                <div className="row d-flex m-0 pt-1 pb-1 home-screen-nav-desktop" style={{ height: "50px", backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000", borderTop: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF", borderBottom: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }}>
                    <div className="col-12 d-flex w-100 home_screen_nav">
                        <div onMouseOver={() => { setSelectedNavItem("STATISTICS"); }} onMouseLeave={() => { setSelectedNavItem(""); }} className={" " + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => selectNavItem("STATISTICS")}>
                            <div className="hometab">
                                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Statistics</H2>
                                {selectedNavItem === "STATISTICS" ? <NavSelectedUnderline /> : <span></span>}
                                {selectedNavItem === "STATISTICS" ? <Statistic showStatistic={statisticSectionShow} graph={graphData} /> : <span></span>}
                            </div>
                        </div>

                        <div onMouseOver={() => { setSelectedNavItem("ELIGIBLE_CRITERIA"); }} onMouseLeave={() => { setSelectedNavItem(""); }} className={" " + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer", zIndex: 11 }} onClick={() => selectNavItem("ELIGIBLE_CRITERIA")}>
                            <div className="hometab">
                                <H2 className="eligible_criteria_tab" whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Eligibility Criteria</H2>
                                {selectedNavItem === "ELIGIBLE_CRITERIA" ? <NavSelectedUnderline /> : <span></span>}
                                {selectedNavItem === "ELIGIBLE_CRITERIA" ? <EligibleCriteria /> : <span></span>}
                            </div>
                        </div>

                        <div onMouseOver={() => { setSelectedNavItem("APPLICATION_GUIDELINE"); }} onMouseLeave={() => { setSelectedNavItem(""); }} className={"" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer", zIndex: 11 }} onClick={() => selectNavItem("APPLICATION_GUIDELINE")}>
                            <div className="hometab">
                                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Application Guidelines</H2>
                                {selectedNavItem === "APPLICATION_GUIDELINE" ? <NavSelectedUnderline /> : <span></span>}
                                {selectedNavItem === "APPLICATION_GUIDELINE" ? <ApplicationGuideline /> : <span></span>}
                            </div>
                        </div>

                        {/* <div onMouseOver={() => { setSelectedNavItem("DOWNLOADS"); }} onMouseLeave={() => { setSelectedNavItem(""); }} className={"" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer", zIndex: 11 }} onClick={() => selectNavItem("DOWNLOADS")}>
                            <div className="hometab">
                                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Downloads</H2>
                                {selectedNavItem === "DOWNLOADS" ? <NavSelectedUnderline /> : <span></span>}
                                {selectedNavItem === "DOWNLOADS" ? <DownloadsInfo /> : <span></span>}
                            </div>
                        </div> */}

                        <div onMouseOver={() => { setSelectedNavItem("APPLICATION_FLOW"); }} onMouseLeave={() => { setSelectedNavItem(""); }} className={"" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => selectNavItem("APPLICATION_FLOW")}>
                            <div className="hometab">
                                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Application Flow</H2>
                                {selectedNavItem === "APPLICATION_FLOW" ? <NavSelectedUnderline /> : <span></span>}
                                {selectedNavItem === "APPLICATION_FLOW" ? <ApplicationFlow /> : <span></span>}
                            </div>
                        </div>

                        <div onMouseOver={() => { setSelectedNavItem("DEPARTMENT_HIERARCHY"); }} onMouseLeave={() => { setSelectedNavItem(""); }} className={"" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => selectNavItem("DEPARTMENT_HIERARCHY")}>
                            <div className="hometab">
                                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Department Hierarchy</H2>
                                {selectedNavItem === "DEPARTMENT_HIERARCHY" ? <NavSelectedUnderline /> : <span></span>}
                                {selectedNavItem === "DEPARTMENT_HIERARCHY" ? <DepartmentHierarchy showHierarchyImage={departmentHierarchyImageBox} /> : <span></span>}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeScreenNav;