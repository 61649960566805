import { useSelector } from "react-redux";
import React from "react";
import '../mediaQueries.css';

const H6 = ({ whiteThemeColor, darkThemeColor, children }) => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);

    return (
        <h5 style={{ fontSize: `${12 * fontSizeMultiplier / 100}px`, fontFamily: 'Open Sans', fontWeight: 'bold', color: `${darkWhiteThemeToggle === "WHITE" ? whiteThemeColor : darkThemeColor}` }}>{children}</h5>
    );
}

export default H6;