import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { backOfficeCommunication } from "../../communications/back-office-communication";
import H4 from "../../text-components/H4";
import P from "../../text-components/P";
import LoadingForms from "../../shared-components/LoadingForms";
import H5 from "../../text-components/H5";
import ReactPaginate from "react-paginate";


const GovernmentResolution = () => {

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [grList, setGrList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const history = useHistory();
    const limit = process.env.REACT_APP_PAGE_LIMIT;

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        fetchData(selectedPage + 1);
    }



    async function fetchData(page) {
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("government-resolution")) {
            dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "GOVERNMENT_RESOLUTION" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
        }
        showLoading("Loading...");
        const getAllGr = await backOfficeCommunication.getAllGrList(page);
        // console.log("inside code",getAllGr);
        if (getAllGr?.status === "SUCCESS") {
            setGrList(getAllGr?.grs);
            setPageCount(getAllGr?.totalPages);
        }
        else if (getAllGr.status === "ERROR" || getAllGr.jwt === null) {
            showModal(getAllGr?.message);
            history.push("/back-office/login");
        }
        else {
            setGrList([]);
            setPageCount(0);
        }
        hideLoading();
    }

    useEffect(() => {
        fetchData();
    }, []);

    const showGovernmentResolution = (id) => {
        history.push(`/back-office/dashboard/government-resolution-preview?grId=${id}`);
    }

    return (
        <section className="dashboard_background">
            {isLoading.value ?
                <LoadingForms message={isLoading.message} />
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="dashboard_back_office">
                                <div className="row search_wrapper">
                                    {/* <div className="col-lg-4 col-md-4 ps-0">
                                        <input type="search" className="form-control" placeholder="Search..." />
                                    </div> */}
                                    <div className="col-lg-8 col-md-8 pe-0 pagination_wrapper">
                                        {pageCount > 1 &&
                                            <ReactPaginate
                                                previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                                nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                forcePage={currentPage}
                                                activeClassName={"active"} />
                                        }
                                    </div>
                                </div>
                                {/*-----Grievance card header section---*/}
                                <div className="dashboard_view_data_heading">
                                    <div className="row m-0">
                                        <div className="col-1 p-0 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Serial No.</H4></span>
                                        </div>
                                        <div className="col-5 p-0 view_dashboard ps-4" style={{ textAlign: "left" }}>
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Government Resolution</H4></span>
                                        </div>
                                        <div className="col-3 p-0 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Creation Date</H4></span>
                                        </div>
                                        <div className="col-3 p-0 ">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Status</H4></span>
                                        </div>
                                    </div>
                                </div >
                                {
                                    grList?.length > 0 ?
                                        <>
                                            {
                                                grList?.map((grS, index) => {
                                                    const { _id, createdDate, isGrSigned } = grS;

                                                    return (
                                                        <div className="dashboard_view_data_main" key={index}>
                                                            <div className="dashboard_view_data">
                                                                <div className="row m-0">
                                                                    <div className="col-1 p-0 view_dashboard text_align">
                                                                        <span>
                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{limit * currentPage + (index + 1)}</P>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-5 p-0 ps-4 view_dashboard align" style={{ textAlign: "left" }}>
                                                                        <span>

                                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}><a onClick={() => showGovernmentResolution(_id)}>{`${index + 1} Government resolution of year ${new Date(createdDate).getFullYear()} `}</a></P>

                                                                        </span>
                                                                    </div>
                                                                    <div className="col-3 p-0 view_dashboard text_align">
                                                                        <span ><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {new Date(createdDate).getDate()} - {new Date(createdDate).getMonth() + 1} - {new Date(createdDate).getFullYear()}
                                                                        </P></span>
                                                                    </div>
                                                                    <div className="col-3 p-0  text_align">
                                                                        <span ><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{(isGrSigned === false) ? 'New' : 'Acknowledged'}</P></span>
                                                                    </div>
                                                                </div>
                                                                {isGrSigned === false ?
                                                                    <div className="new_badge">
                                                                        <p>New</p>
                                                                    </div>
                                                                    : <span></span>
                                                                }
                                                            </div>
                                                        </div>

                                                    );

                                                })
                                            }
                                        </>
                                        :
                                        <div className="text-center mt-5">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Records not available</H5>
                                        </div>
                                }
                            </div >

                        </div >
                    </div >
                </div >
            }
        </section >
    );

}
export default GovernmentResolution;