import React, { useState, useEffect, memo } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import H4 from "../../../text-components/H4";
import { useLocation } from "react-router-dom";
import { backOfficeCommunication } from "../../../communications/back-office-communication";
import LoadingForms from "../../../shared-components/LoadingForms";
import { useDispatch } from "react-redux";
import H5 from "../../../text-components/H5";
import Label from "../../../text-components/Label";

function UserMilestoneTwo({ changeHandler }) {
    const dispatch = useDispatch();
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);

    const history = useHistory();
    const location = useLocation();

    const [disabled, setDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [applicationId, setApplicationId] = useState("");
    const [milestone, setMileStone] = useState("")
    const [type, setType] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [comment, setComment] = useState("");
    const [isFileUpload, setIsFileUpload] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab');
    // show document scrutiny uploaded documents preview window
    function showDocumentScrutinyUploadedDocumentPreview(applicationId, type, fileUrl) {
        setType(type)
        history.push(`/back-office/dashboard/document-scrutiny/milestone-list/uploaded-documents/preview?milestoneId=${milestone._id.toString()}&applicationId=${applicationId}&fileUrl=${fileUrl}&type=${type}`);
    }
    //close document scrutiny uploaded files window
    function closeDocumentScrutinyUploadedFiles(applicationId, type) {
        setType(type)
        if (tab === "homeverification") {
            history.push("/back-office/dashboard/home-verification");
        } else {
            history.push(`/back-office/dashboard/document-scrutiny/milestone-list?milestoneId=${milestone._id.toString()}&applicationId=${applicationId}&type=${type}`);
        }
    }


    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    const getComment = (event) => {
        setComment(event.target.value);
    }

    const markCompleteTwo = async () => {
        try {
            showLoading("Loading...");
            const completeMarkMilestoneTwo = await backOfficeCommunication.markTwoComplete(applicationId, type, comment);
            if (completeMarkMilestoneTwo.status === "SUCCESS") {
                await changeHandler();
                // history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`);
                history.goBack();
            }
            showModal(completeMarkMilestoneTwo.message);
            hideLoading();
        } catch (error) {
            showModal(error);
        }
    }

    const markInCompleteTwo = async () => {
        try {
            showLoading("Loading...");
            if (comment === "") {
                showModal("Please add comment !");
                hideLoading();
                return;
            }

            const incompleteMarkMilestoneTwo = await backOfficeCommunication.markTwoInComplete(applicationId, type, comment);

            if (incompleteMarkMilestoneTwo.status === "SUCCESS") {
                await changeHandler();
                // history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}`);
                history.goBack();
            }
            showModal(incompleteMarkMilestoneTwo.message);
            hideLoading();
        } catch (error) {
            showModal(error);
        }
    }

    const uploadHomeVerification = async () => {
        try {
            showLoading("Loading...");
            if (selectedFile === null || selectedFile === undefined) {
                return showModal("Please select file");
            }
            const formData = new FormData();
            formData.append("document", selectedFile);
            //  TODO VALIDATE FILE HERE
            const uploadHomeVerification = await backOfficeCommunication.uploadHomeVerification(applicationId, selectedFile);

            if (uploadHomeVerification.status === "SUCCESS" || uploadHomeVerification.data.status === "SUCCESS") {
                setIsFileUpload(true);
                getMilestoneDocument();
            }
            showModal(uploadHomeVerification.message || uploadHomeVerification.data.message);
            hideLoading();

        } catch (error) {
            showModal(error);
        }
    }


    function selectFile(event) {
        event.preventDefault();
        let formData = new FormData();
        formData.append("document", event.target.files[0], event.target.files[0].name);
        setSelectedFile(formData);
    }


    const goToApplication = async (applicationId, id, type) => {
        showLoading("Loading...");
        if (tab === "homeverification") {
            history.push(`/back-office/dashboard/application/?applicationId=${applicationId}&action=${type}&milestoneId=${id}&tab=${tab}`);
        } else {
            history.push(`/back-office/dashboard/application/?applicationId=${applicationId}&action=${type}&milestoneId=${id}`);
        }
        hideLoading();
    }

    async function getMilestoneDocument() {
        try {
            // let fullUrl = window.location.href;
            // let splitUrlArray = fullUrl.split('/');
            // if (splitUrlArray.includes("document-scrutiny")) {
            //     dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
            // } else {
            //     dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
            // }

            showLoading("Loading...");

            const queryParams = new URLSearchParams(location.search);
            const applicationId = queryParams.get('applicationId');
            const milestoneId = queryParams.get('milestoneId');
            const type = queryParams.get('type');
            const response = await backOfficeCommunication.getMilestone(milestoneId, applicationId, type);

            if (response?.status === "SUCCESS") {
                setMileStone(response?.milestones[0]);
                if (response?.milestones[0]?.backOfficeStatus === "HV_COMPLETED") {
                    setDisabled(true);
                } else {
                    setDisabled(false);
                }
                setApplicationId(applicationId);
                setType(type);
            } else {
                showModal(response?.message);
            }
            hideLoading();

        } catch (error) {
            showModal(error?.message);
            hideLoading();
        }
    }

    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');
    let splitUrl = splitUrlArray[splitUrlArray?.length - 1]?.split('?');
    if (splitUrl.includes("milestoneTwo")) {
        dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
    } else {
        dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
    }

    useEffect(() => {
        getMilestoneDocument();
        return () => {

            setDisabled(false);
            setApplicationId("");
            setMileStone("");
            setType("");
            setSelectedFile(null);
            setComment("");
            setIsFileUpload(false);
            hideLoading();
            dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
        };
    }, []);

    return (
        <section className="back_office_preview_section">
            {
                isLoading?.value && isLoading?.value === true ?
                    <LoadingForms message={isLoading?.message} />
                    :
                    <div className="container">
                        <div className="row">
                            <div className="col-12 p-0">
                                <div className="back_office_preview_box">
                                    <div className="back_office_preview_title">
                                        <H4>{milestone?.name}</H4>
                                        <div className="close_back_office_preview" onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    {
                                        !!milestone?.homeVerificationCertificateUrl && milestone?.homeVerificationCertificateUrl !== "" ?
                                            <div className="uploaded_documents">
                                                {/*---heading section---*/}
                                                <div className="row mt-3">

                                                    <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                                        <span>
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Serial No.</H4>
                                                        </span>
                                                    </div>

                                                    <div className="col-10 p-0 ps-4">
                                                        <span>
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Name</H4>
                                                        </span>
                                                    </div>
                                                </div>
                                                {/*---uploaded file section---*/}
                                                <div className="row mt-3">

                                                    <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                                        <span>
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}>{milestone.index - 1}</H4>
                                                        </span>
                                                    </div>

                                                    <div className="col-10 p-0 ps-4 ">
                                                        {
                                                            !!milestone?.homeVerificationCertificateUrl && milestone?.homeVerificationCertificateUrl !== "" &&
                                                            <span>
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD" style={{ fontSize: `${16 * fontSizeMultiplier / 100}px` }}><a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.homeVerificationCertificateUrl)} title="Click to preview document"> Home Verification Certificate <i className="fa fa-paperclip fa-1x ms-2" aria-hidden="true"></i></a></H4>
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="text-center mt-5">
                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Document not available.</H5>
                                            </div>
                                    }

                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        {
                                            disabled !== true ?
                                                <div className="comment_box_main">
                                                    <div className="comment_box_heading">
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Comment Box</H4>
                                                    </div>
                                                    <div className="comment_box_body">
                                                        <div className="comment_to_write_main">
                                                            <div className="comment_to_write">
                                                                <input className="form-control" placeholder="Write comment here..." onChange={getComment} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <span></span>
                                        }
                                    </div>
                                    <div className="col-lg-6 mt-lg-5">
                                        {disabled !== true ?

                                            <div className="upload_report_section row ml-5" style={{ display: "flex", justifyContent: "end" }}>
                                                {
                                                    isFileUpload !== true ?
                                                        <>
                                                            <div className="row mb-2 mt-3">
                                                                <span style={{ fontSize: "14px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                            </div>
                                                            <div className="col-lg-6 col-md-8">
                                                                <input type="file" name="document" className="form-control" id="inputGroupFile02" onChange={(e) => { selectFile(e) }} disabled={disabled} />
                                                            </div>
                                                            <div className="col-lg-6 col-md-4">
                                                                <button className="ms-3" onClick={uploadHomeVerification}> Upload </button>
                                                            </div>
                                                        </>
                                                        : <></>
                                                }

                                            </div>
                                            : <span></span>
                                        }

                                        <div className="upload_home_verification_button mt-3">
                                            <button className="me-3" onClick={() => goToApplication(milestone.applicationId, milestone._id, milestone.type)}>Application Preview</button>
                                            {disabled !== true ?
                                                <>
                                                    <button className="me-3" onClick={() => markInCompleteTwo()}>Incomplete</button>
                                                    {
                                                        !!milestone?.homeVerificationCertificateUrl && milestone?.homeVerificationCertificateUrl !== ""
                                                            ?
                                                            <button className="me-3" onClick={() => markCompleteTwo()}>Approve</button>
                                                            :
                                                            <> </>
                                                    }
                                                </>
                                                : <span></span>
                                            }
                                            <button onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button>
                                        </div>


                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>


            }

        </section>
    )
}

export default memo(UserMilestoneTwo);
