const CurrencyList = [
    {
        name: 'Albania Lek',
        code: 'ALL'
    },
    {
        name: 'Afghanistan Afghani',
        code: 'AFN'
    },
    {
        name: 'Argentina Peso',
        code: 'ARS'
    },
    {
        name: 'Aruba Guilder',
        code: 'AWG'
    },
    {
        name: 'Australia Dollar',
        code: 'AUD'
    },
    {
        name: 'Azerbaijan New Manat',
        code: 'AZN'
    },
    {
        name: 'Bahamas Dollar',
        code: 'BSD'
    },
    {
        name: 'Barbados Dollar',
        code: 'BBD'
    },
    {
        name: 'Bangladeshi taka',
        code: 'BDT'
    },
    {
        name: 'Belarus Ruble',
        code: 'BYR'
    },
    {
        name: 'Belize Dollar',
        code: 'BZD'
    },
    {
        name: 'Bermuda Dollar',
        code: 'BMD'
    },
    {
        name: 'Bolivia Boliviano',
        code: 'BOB'
    },
    {
        name: 'Bosnia and Herzegovina Convertible Marka',
        code: 'BAM'
    },
    {
        name: 'Botswana Pula',
        code: 'BWP'
    },
    {
        name: 'Bulgaria Lev',
        code: 'BGN'
    },
    {
        name: 'Brazil Real',
        code: 'BRL'
    },
    {
        name: 'Brunei Darussalam Dollar',
        code: 'BND'
    },
    {
        name: 'Cambodia Riel',
        code: 'KHR'
    },
    {
        name: 'Canada Dollar',
        code: 'CAD'
    },
    {
        name: 'Cayman Islands Dollar',
        code: 'KYD'
    },
    {
        name: 'Chile Peso',
        code: 'CLP'
    },
    {
        name: 'China Yuan Renminbi',
        code: 'CNY'
    },
    {
        name: 'Colombia Peso',
        code: 'COP'
    },
    {
        name: 'Costa Rica Colon',
        code: 'CRC'
    },
    {
        name: 'Croatia Kuna',
        code: 'HRK'
    },
    {
        name: 'Cuba Peso',
        code: 'CUP'
    },
    {
        name: 'Czech Republic Koruna',
        code: 'CZK'
    },
    {
        name: 'Denmark Krone',
        code: 'DKK'
    },
    {
        name: 'Dominican Republic Peso',
        code: 'DOP'
    },
    {
        name: 'East Caribbean Dollar',
        code: 'XCD'
    },
    {
        name: 'Egypt Pound',
        code: 'EGP'
    },
    {
        name: 'El Salvador Colon',
        code: 'SVC'
    },
    {
        name: 'Estonia Kroon',
        code: 'EEK'
    },
    {
        name: 'Euro Member Countries',
        code: 'EUR'
    },
    {
        name: 'Falkland Islands (Malvinas) Pound',
        code: 'FKP'
    },
    {
        name: 'Fiji Dollar',
        code: 'FJD'
    },
    {
        name: 'Ghana Cedis',
        code: 'GHC'
    },
    {
        name: 'Gibraltar Pound',
        code: 'GIP'
    },
    {
        name: 'Guatemala Quetzal',
        code: 'GTQ'
    },
    {
        name: 'Guernsey Pound',
        code: 'GGP'
    },
    {
        name: 'Guyana Dollar',
        code: 'GYD'
    },
    {
        name: 'Honduras Lempira',
        code: 'HNL'
    },
    {
        name: 'Hong Kong Dollar',
        code: 'HKD'
    },
    {
        name: 'Hungary Forint',
        code: 'HUF'
    },
    {
        name: 'Iceland Krona',
        code: 'ISK'
    },
    {
        name: 'India Rupee',
        code: 'INR'
    },
    {
        name: 'Indonesia Rupiah',
        code: 'IDR'
    },
    {
        name: 'Iran Rial',
        code: 'IRR'
    },
    {
        name: 'Isle of Man Pound',
        code: 'IMP'
    },
    {
        name: 'Israel Shekel',
        code: 'ILS'
    },
    {
        name: 'Jamaica Dollar',
        code: 'JMD'
    },
    {
        name: 'Japan Yen',
        code: 'JPY'
    },
    {
        name: 'Jersey Pound',
        code: 'JEP'
    },
    {
        name: 'Kazakhstan Tenge',
        code: 'KZT'
    },
    {
        name: 'Korea (North) Won',
        code: 'KPW'
    },
    {
        name: 'Korea (South) Won',
        code: 'KRW'
    },
    {
        name: 'Kyrgyzstan Som',
        code: 'KGS'
    },
    {
        name: 'Laos Kip',
        code: 'LAK'
    },
    {
        name: 'Latvia Lat',
        code: 'LVL'
    },
    {
        name: 'Lebanon Pound',
        code: 'LBP'
    },
    {
        name: 'Liberia Dollar',
        code: 'LRD'
    },
    {
        name: 'Lithuania Litas',
        code: 'LTL'
    },
    {
        name: 'Macedonia Denar',
        code: 'MKD'
    },
    {
        name: 'Malaysia Ringgit',
        code: 'MYR'
    },
    {
        name: 'Mauritius Rupee',
        code: 'MUR'
    },
    {
        name: 'Mexico Peso',
        code: 'MXN'
    },
    {
        name: 'Mongolia Tughrik',
        code: 'MNT'
    },
    {
        name: 'Mozambique Metical',
        code: 'MZN'
    },
    {
        name: 'Namibia Dollar',
        code: 'NAD'
    },
    {
        name: 'Nepal Rupee',
        code: 'NPR'
    },
    {
        name: 'Netherlands Antilles Guilder',
        code: 'ANG'
    },
    {
        name: 'New Zealand Dollar',
        code: 'NZD'
    },
    {
        name: 'Nicaragua Cordoba',
        code: 'NIO'
    },
    {
        name: 'Nigeria Naira',
        code: 'NGN'
    },
    {
        name: 'Norway Krone',
        code: 'NOK'
    },
    {
        name: 'Oman Rial',
        code: 'OMR'
    },
    {
        name: 'Pakistan Rupee',
        code: 'PKR'
    },
    {
        name: 'Panama Balboa',
        code: 'PAB'
    },
    {
        name: 'Paraguay Guarani',
        code: 'PYG'
    },
    {
        name: 'Peru Nuevo Sol',
        code: 'PEN'
    },
    {
        name: 'Philippines Peso',
        code: 'PHP'
    },
    {
        name: 'Poland Zloty',
        code: 'PLN'
    },
    {
        name: 'Qatar Riyal',
        code: 'QAR'
    },
    {
        name: 'Romania New Leu',
        code: 'RON'
    },
    {
        name: 'Russia Ruble',
        code: 'RUB'
    },
    {
        name: 'Saint Helena Pound',
        code: 'SHP'
    },
    {
        name: 'Saudi Arabia Riyal',
        code: 'SAR'
    },
    {
        name: 'Serbia Dinar',
        code: 'RSD'
    },
    {
        name: 'Seychelles Rupee',
        code: 'SCR'
    },
    {
        name: 'Singapore Dollar',
        code: 'SGD'
    },
    {
        name: 'Solomon Islands Dollar',
        code: 'SBD'
    },
    {
        name: 'Somalia Shilling',
        code: 'SOS'
    },
    {
        name: 'South Africa Rand',
        code: 'ZAR'
    },
    {
        name: 'Sri Lanka Rupee',
        code: 'LKR'
    },
    {
        name: 'Sweden Krona',
        code: 'SEK'
    },
    {
        name: 'Switzerland Franc',
        code: 'CHF'
    },
    {
        name: 'Suriname Dollar',
        code: 'SRD'
    },
    {
        name: 'Syria Pound',
        code: 'SYP'
    },
    {
        name: 'Taiwan New Dollar',
        code: 'TWD'
    },
    {
        name: 'Thailand Baht',
        code: 'THB'
    },
    {
        name: 'Trinidad and Tobago Dollar',
        code: 'TTD'
    },
    {
        name: 'Turkey Lira',
        code: 'TRY'
    },
    {
        name: 'Turkey Lira',
        code: 'TRL'
    },
    {
        name: 'Tuvalu Dollar',
        code: 'TVD'
    },
    {
        name: 'Ukraine Hryvna',
        code: 'UAH'
    },
    {
        name: 'United Kingdom Pound',
        code: 'GBP'
    },
    {
        name: 'United States Dollar',
        code: 'USD'
    },
    {
        name: 'Uruguay Peso',
        code: 'UYU'
    },
    {
        name: 'Uzbekistan Som',
        code: 'UZS'
    },
    {
        name: 'Venezuela Bolivar',
        code: 'VEF'
    },
    {
        name: 'Viet Nam Dong',
        code: 'VND'
    },
    {
        name: 'Yemen Rial',
        code: 'YER'
    },
    {
        name: 'Zimbabwe Dollar',
        code: 'ZWD'
    },
];
export default CurrencyList;