import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import H5 from "../../text-components/H5";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";


const UploadDocumentsUpdate = () => {

    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [documentsMaintenanceData, setDocumentsMaintenanceData] = useState([]);
    const [idsOfUploadedDocument, setIdsOfUploadedDocument] = useState([]);
    const history = useHistory();
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;



    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchUploadedDocumentsBySearch(searchValue, selectedPage + 1);
        } else {
            initialLoad(selectedPage + 1);
        }
    }

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchUploadedDocumentsBySearch(searchString, currentPage + 1);
        } else {
            const documentsFromServer = await adminCommunication.getUploadDownloadDocuments();
            if (documentsFromServer.status === "SUCCESS") {
                let documentArray = documentsFromServer?.documents;
                setDocumentsMaintenanceData(documentArray);
                setPageCount(documentsFromServer?.totalPages);
            } else if (documentsFromServer?.status === "JWT_INVALID") {
                history.push("/admin/login");
                showModal(documentsFromServer?.message);
            } else {
                setDocumentsMaintenanceData([]);
                setPageCount(0);
            }
        }
    }

    //get Uploaded Documents By Search
    const fetchUploadedDocumentsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await adminCommunication.getUploadedDocumentsBySearch(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setDocumentsMaintenanceData(responseFromServer?.data?.documents);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                history.push("/admin/login");
                showModal(responseFromServer?.data?.message);
            } else {
                setDocumentsMaintenanceData([]);
                setPageCount(0);
            }
        } catch (error) {
            showModal(error?.message);
        }
    }

  //get Uploaded Documents on Initial Load
    async function initialLoad(page) {
        showLoading("Loading...");
        dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "UPLOAD_DOCUMENTS_UPDATE" });
        const documentsFromServer = await adminCommunication.getUploadDownloadDocuments(page);
        if (documentsFromServer.status === "SUCCESS") {
            let documentArray = documentsFromServer?.documents;
            setDocumentsMaintenanceData(documentArray);
            setPageCount(documentsFromServer?.totalPages);
        } else {
            showModal(documentsFromServer?.message);
            setDocumentsMaintenanceData([]);
            setCurrentPage(0)
        }
        hideLoading();
    }

    useEffect(() => {
        initialLoad();
    }, []);

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    // set ids in to state
    const handleOnChange = (e) => {
        let checked = e.target.checked;
        let id = e.target.value;
        if (checked) {
            setIdsOfUploadedDocument(() => [...idsOfUploadedDocument, id]);
        } else {
            // to remove from local storage
            setIdsOfUploadedDocument(
                idsOfUploadedDocument.filter((Id) => Id !== id)
            );
        }
        return;
    }


    // delete committee by id
    const deleteUploadedDocument = async () => {
        if (idsOfUploadedDocument.length > 0) {
            showLoading("Loading ...");
            const deleteUploadedDocumentByIdData = await adminCommunication.deleteUploadDownloadDocumentById(idsOfUploadedDocument);
            if (deleteUploadedDocumentByIdData.status === "SUCCESS") {
                dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "UPLOAD_DOCUMENTS_UPDATE" });
                showModal(deleteUploadedDocumentByIdData.message);
                // calling refreshPage function after 4 sec to show model before refreshing the page
                setTimeout(() => {
                    history.push(`/admin/dashboard/upload-maintenance-update`);
                }, 1000);
            }
            showModal(deleteUploadedDocumentByIdData.message);
        }
        else {
            showModal("Please select atleast one record ");
        }

        return;
    }
    // redirect to committee maintenance save according to Designation id
    const uploadedDocumentEdit = (documentId) => {
        history.push(`/admin/dashboard/upload-documents-save?documentId=${documentId}`);
        return;
    }








    return (
        <>
            <section className="dashboard_background_update" >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :

                    <div className="dashbord_main">
                    <div className="row search_wrapper">
                    <div className="col-lg-4 col-md-4 ps-0">
                        <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Portal, Type..." />
                    </div>
                    <div className="col-lg-8 col-md-8 pagination_outer_wrapper">
                        <div className="pagination_inner_wrapper">
                            {pageCount > 1 &&
                                <ReactPaginate
                                    previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                    nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            }
                        </div>
                    </div>
                </div>
                        {/*-----Grievance card header section---*/}
                        <div className="dashboard_view_data_heading">
                            <div className="row m-0">
                                <div className="col-lg-1 col-1 p-0 view_dashboard">
                                    <span><H5>Serial No.</H5></span>
                                </div>
                                <div className="col-lg-2 col-2 p-0 view_dashboard">
                                    <span><H5>Portal</H5></span>
                                </div>
                                <div className="col-lg-3 col-3 p-0 view_dashboard">
                                    <span><H5>Subject</H5></span>
                                </div>
                                <div className="col-lg-4 col-3 p-0 view_dashboard">
                                    <span><H5 >Description</H5></span>
                                </div>
                                <div className="col-lg-2 col-3 p-0 ">
                                    <span><H5 >Creation Date</H5></span>
                                </div>
                            </div>
                        </div>

                        {documentsMaintenanceData.length > 0 ?
                            <div>
                                {/*-----Grievance card data section---*/}
                                {documentsMaintenanceData.map((document, index) => {
                                    const { _id, subject, description, createdDate, portal } = document;

                                    return (
                                        <div key={index}>
                                            <div className="dashboard_view_data_main" key={_id}>
                                                <div className="dashboard_view_data" >
                                                    <div className="row m-0">
                                                        <div className="col-lg-1 col-1 p-0 view_dashboard sr-no text_align">
                                                            <span>
                                                                <input type="checkbox" value={_id} onClick={(e) => handleOnChange(e)} />
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {index + 1}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-lg-2 col-2 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {portal}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-lg-3 col-3 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    <a onClick={() => uploadedDocumentEdit(_id)}>
                                                                        {subject}
                                                                    </a>
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-lg-4 col-3 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {description}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-lg-2 col-3 p-0 text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {new Date(createdDate).getDate()}-{new Date(createdDate).getMonth() + 1}-{new Date(createdDate).getFullYear()}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    );
                                })}
                            </div>
                            :
                            <div className="col-12 text-center view_download_dashboard">
                                <h6>Records not available.</h6>
                            </div>
                        }
                    </div>
                }
            </section>
            <section className="mb-3">
                <div className="row">
                    <div className="col-2 button_div">
                        <div className="dashboard_delete_button">
                            <button type="button" className="btn" onClick={() => deleteUploadedDocument()}>
                                {isLoading.value ? <img src="/assets/images/gif/loading-circle.gif" style={{ width: "10px", height: "10px" }} /> : <H5>Delete</H5>}
                            </button>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default UploadDocumentsUpdate;