import React from "react";
import HomeScreenNav from "./HomeScreenNav";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";


const ApplicationFlowPreviewPublicPortal = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const history = useHistory();

    const goToHome = () => {
        history.push("/");
        return true;
    }
    return (
        <>
            <HomeScreenNav />

            <section className="application_flow_public_portal">
                <div className="row m-0">
                    <div className="col-12 p-0">
                        {darkWhiteThemeToggle === "WHITE" ?
                            <img src="/assets/images/jpgs/applicationFlowPublicWhiteTheme.jpg" alt="Application_flow" />
                            :
                            <img src="/assets/images/jpgs/applicationFlowPublicBlackTheme.jpg" alt="Application_flow" />

                        }
                        <span onClick={() => goToHome()}><i className="fa fa-times-circle" aria-hidden="true" style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000" : "#fff" }}></i></span>
                    </div>
                </div>
            </section>
        </>
    );

}
export default ApplicationFlowPreviewPublicPortal;