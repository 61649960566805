import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const ApplicationFlowBigSize = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const dispatch = useDispatch();

    useEffect(() => {
        // to show active tab
        let fullUrl = window.location.href;
        let splitApplicationFlowUrl = fullUrl.split('/');
        if (splitApplicationFlowUrl.includes("applicationFlowBigSize")) {
            dispatch({ type: "SHOW_ACTIVE_APPLICATION_PROCESS_TAB", payload: "APPLICATIONFLOWBIGSIZE" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_APPLICATION_PROCESS_TAB" });
        }
    }, []);

    return (
        <>
            <section className="user_application_flow" >
                <div className="row m-0">
                    <div className="col-12 p-0">
                        {darkWhiteThemeToggle === "WHITE" ?
                            <img src="/assets/images/jpgs/applicationFlowUserWhiteTheme.jpg" alt="Application flow" />
                            :
                            <img src="/assets/images/jpgs/applicationFlowUserBlackTheme.jpg" alt="Application flow" />

                        }
                    </div>
                </div>
            </section>
        </>
    );

}
export default ApplicationFlowBigSize;