import React, { useState, useEffect } from "react";
import P from "../../text-components/P";
import { backOfficeCommunication } from "../../communications/back-office-communication";
import { useDispatch } from "react-redux";
import LoadingForms from "../../shared-components/LoadingForms";
import Label from "../../text-components/Label";


const BulkMessageForm = ({ setBulkMsgForm, get }) => {

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const [message, setMessage] = useState();
    const [fileUrl, setFileUrl] = useState();
    const [validationMessage, setValidationMessage] = useState(false);
    const [studentList, setStudentList] = useState([]);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    // function for loader
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }



    async function initialLoad() {
        const response = await backOfficeCommunication.getUsers();
        if (response?.status === "SUCCESS") {
            setStudentList(response?.userIds);
            return response;
        }
    }

    useEffect(() => {
        initialLoad();
    }, [])


    //Send Bulk Message
    const sendBulkMessage = async () => {
        showLoading("Loading...");

        try {

            if ([undefined, null, ""].includes(message)) {
                setValidationMessage(true);
                hideLoading();
                return false;
            }

            let userIds = [];

            studentList.forEach((student) => {
                userIds.push(student.userId);
            });

            // Send values to the server
            let isFileAttached = false;
            let formData = new FormData();
            if (fileUrl) {
                formData.append("grievanceFile", fileUrl)
                isFileAttached = true;
            }

            const dataToSend = {
                message,
                fileUrl,
                userIds,
                isFileAttached
            }

            const response = await backOfficeCommunication.sendIndividualMessage(dataToSend);

            if (response?.data?.status === "SUCCESS") {
                showModal(response?.data?.message);
                get();
                setBulkMsgForm(false);
                hideLoading();
                return;
            }
            if (response?.data?.status === "VALIDATION_FAILED") {
                showModal(response?.data?.validationErrorsList[response?.data?.validationErrorsList?.length - 1]?.message)
                get();
                setBulkMsgForm(false);
                hideLoading();
                return;
            } else {
                showModal(response?.data?.message);
                get();
                setBulkMsgForm(false);
                hideLoading();
                return;
            }
        }
        catch (err) {
            showModal(err?.message);
            get();
            setBulkMsgForm(false);
            hideLoading();
            return;
        }
    }

    return (
        <div className="individual_msg_form_main">
            <div className="individual_msg_form">
                {isLoading?.value ?
                    <LoadingForms message={isLoading?.message} />
                    :
                    <>
                        <div className="user_chatbot_textarea_main">
                            <div className="user_chatbot_textarea">
                                <div className="user_chatbot_text_file">
                                    <textarea placeholder="Type your message here..." name="message" onChange={(event) => setMessage(event.target.value)} className="form-control" />
                                    <>
                                        <div className="input-group attach_file" title="Click to attach file">
                                            <input type="file" id="file" onChange={(event) => setFileUrl(event.target.files[0])} style={{ display: "none" }} />
                                            <label for="file"><i className="fa fa-paperclip fa-1x ms-2" aria-hidden="true"></i></label>
                                        </div>
                                        <div className="attache_file_name">
                                            <P>{fileUrl?.name}</P>
                                        </div>
                                    </>
                                </div>
                            </div>
                            {
                                validationMessage &&
                                <p className="message_form_validation">Student is required</p>
                            }
                        </div>

                        <div style={{textAlign:"end"}}>
                            <span style={{ fontSize: "12px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf/jpg/jpeg/png & size must be less than 2mb.</b></span>
                        </div>

                        <div className="row individual_form_button">
                            <div className="col-12">
                                <button onClick={() => sendBulkMessage()}>Send</button>
                                <button className="ms-3" onClick={() => setBulkMsgForm(false)}>Cancel</button>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}
export default BulkMessageForm;