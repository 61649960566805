import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import H5 from "../../text-components/H5";
import H3 from "../../text-components/H3";
import H7 from "../../text-components/H7";




const DepartmentMaintainanceSave = () => {
    // const [designationFormData, setDesignationFormData] = useState([]);

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    const history = useHistory();

    const [designationIds, setDesignationIds] = useState([]);

    const location = useLocation();

    const [departmentData, setDepartmentData] = useState({});

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    //**********Signing Authority Name Array*** */
    // const [signingAuthorityArray, setDepartmentArray] = useState(["COMISSIONER"]);

    const [allDesignationsMaintenanceData, setAllDesignationsMaintenanceData] = useState([]);

    useEffect(async () => {
        showLoading("Loading...");

        dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "DEPARTMENT_MAINTENANCE_UPDATE" });

        const designationsFromServer = await adminCommunication.getAllDesignations();

        if (designationsFromServer.status === "SUCCESS") {
            let designationsArray = designationsFromServer.designations;
            setAllDesignationsMaintenanceData(value => {
                return [...value, ...designationsArray]
            });
        } else {
            showModal(designationsFromServer.message);
        }

        // getting designation id From queryParams 
        const queryParams = new URLSearchParams(location.search);

        const departmentIdFromParams = queryParams.get('departmentId');

        const departmentDataFromServer = await adminCommunication.getDepartmentById(departmentIdFromParams);

        if (departmentDataFromServer.status === "SUCCESS") {
            setDepartmentData(value => value = departmentDataFromServer.department);
        }
        else {
            showModal(departmentDataFromServer.message);
        }

        setDesignationIds(value => {
            return [...value, ...departmentDataFromServer.department.designationArray]
        });

        hideLoading();
    }, []);


    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }


    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        name: departmentData.name,
        location: departmentData.location,
        signingAuthority: departmentData.signingAuthority,
        designationArray: departmentData.designationArray
    }


    //****Validation for Designation Create form*****/
    let yupValidationSchema = Yup.object().shape({
        name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Name is required"),
        location: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Location is required"),
        signingAuthority: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Select Signing Authority ")
    });

    // Destructuring useFormik to get functions to control aspects of form
    // What does Formik do?
    // 1. Handling form events like submit, errors, change in value
    // 2. Fetching values from the form 
    // 3. Support for a validation library on the data fetched. We are using Yup library for validations. 
    // 4. Reset the form
    // 5. Pass initial values to the form
    // 6. Handle errors thrown by the Yup (or any validation library).
    // 7. Reinitialize form values
    // 8. Perform async operations
    const { handleSubmit, handleChange, values, errors, handleReset } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit
        onSubmit: async (values) => {
            // Show loading 
            showLoading("Loading...");

            // Send values to the server
            const departments = {
                departmentId: departmentData._id,
                name: values.name,
                location: values.location,
                designationArray: designationIds,
                signingAuthority: values.signingAuthority
            }

            let filtered = departments.designationArray.filter((item, index) => departments.designationArray.indexOf(item) === index)
            departments.designationArray = filtered;

            let showCheckError = checkBoxError(designationIds);

            if (showCheckError == true) {
                const departmentUpdateResponse = await adminCommunication.updateDepartment(departments);

                // Handle response from the server
                if (departmentUpdateResponse.status === "SUCCESS") {
                    handleReset();
                    dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "DEPARTMENT_MAINTENANCE_UPDATE" });
                    history.push('/admin/dashboard/departments-maintenance-update');
                }

                // Show response message from server in modal dialog
                showModal(departmentUpdateResponse.message);
            }
            else {
                // Show error message in modal dialog
                showModal("Please select at least one designation");
            }

            // Hide spinner 
            hideLoading();
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: yupValidationSchema,
    });

    const checkBoxError = (userIds) => {
        if (userIds.length === 0) {
            return false;
        }
        else {
            return true;
        }
    }

    // set ids in to state
    const handleOnChange = (e) => {
        let checked = e.target.checked;
        let id = e.target.value;
        if (checked) {
            setDesignationIds(() => [...designationIds, id]);
            defaultFormValues.designationArray.push(id);
        } else {
            setDesignationIds(
                designationIds.filter((Id) => Id !== id)
            );

            //  Using Array.prototype.splice() function
            // The splice() method in JavaScript is often used to in-place add or 
            //remove elements from an array.The idea is to find an index of 
            //the element to be removed from an array and then remove that index 
            //using the splice() method.


            Array.prototype.remove = function (value) {
                this.splice(this.indexOf(value), 1);
            }

            defaultFormValues.designationArray.remove(id);
        }
        return;
    }

    return (

        <div >
            <section className="create_form create_designation" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000", border: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }}>
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :

                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <H3 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Update Department</H3>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Department Name</H5>
                            </label>
                            <div className="col-sm-7">
                                <input type="text" name="name" onChange={handleChange} value={values.name} className={`form-control ${errors.name ? "is-invalid" : ""}`} />
                                <div className="invalid-feedback">{errors.name}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Location</H5>
                            </label>
                            <div className="col-sm-7">
                                <input type="text" name="location" onChange={handleChange} value={values.location} className={`form-control ${errors.location ? "is-invalid" : ""}`} />
                                <div className="invalid-feedback">{errors.location}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Signing Authority</H5>
                            </label>

                            <div className="col-sm-7">
                                <select name="signingAuthority" onClick={handleChange} className={`form-select ${errors.signingAuthority ? "is-invalid" : ""}`}>
                                    <option value={""} selected>Select Authority</option>
                                    <option selected={values.signingAuthority === "COMMISSIONER" ? true : false} value="COMMISSIONER">COMMISSIONER</option>
                                </select>
                                <div className="invalid-feedback">{errors.signingAuthority}</div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-4 ">
                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Designation</H5>
                            </label>
                            <div className="col-sm-8">
                                <div className="row">
                                    {
                                        allDesignationsMaintenanceData.map((designationData, index) => {
                                            let { _id, name } = designationData;

                                            return (
                                                <div className="col-sm-4" key={index}>
                                                    <div className="Checkbox-inline">
                                                        <input type="checkbox" checked={departmentData.designationArray == [] ? " " : departmentData.designationArray.includes(_id)} value={_id} onClick={(e) => { handleOnChange(e) }} />
                                                        <H7 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{name}</H7>
                                                    </div>
                                                </div>
                                            )

                                        })
                                    }
                                </div>
                            </div>



                        </div>



                        <button type="button" onClick={() => {
                            checkBoxError(designationIds) ? handleSubmit() : showModal("Please select at least one designation")
                        }} className="button" >
                            {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <h3>Update</h3>}
                        </button>
                    </form>
                }
            </section>
        </div>
    );
}
export default DepartmentMaintainanceSave;