import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useDispatch,useSelector} from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import H4 from '../text-components/H4';
import Label from "../text-components/Label";
import { useLocation } from "react-router-dom";
import { applicationCommunication } from "../communications/application-communication";
import { useFormik } from 'formik';
import FormFieldTitle from "./FormFieldTitle";
//****  District and State list Array object for Maping   *****//
import StateAndDistrictList from "./ArrayObjectFolder/StateAndDistrictList";
//**  District list Array object for Maping   ***//
import districtList from "./ArrayObjectFolder/DistrictListForSelect";
import { useHistory } from "react-router";
import LoadingForms from "../shared-components/LoadingForms";
import { communication } from "../communications/communication"
import FamilyMembersDetailsForm from "./FamilyMembersDetailsForm";
import FamilyMembersAvailedScholarshipDetails from "./FamilyMembersAvailedScholarshipDetails";

const ParentDetails = () => {

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    // spinner on loading 
    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    //**  Stopping field input- Enable & Disable Button ***//
    const [disabled, setDisabled] = useState(false);
    // cancel, save and submit buttons unable & desable
    const [disableActions, setDisableActions] = useState(false);

    const [applicationId, setApplicationId] = useState(null);

    const [initialFormValues, setInitialFormValues] = useState(null);

    const [saveType, setSaveType] = useState(null);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const history = useHistory();

    const location = useLocation();

    const [districtList, setDistrictList] = useState([]);

    const [fatherEmploymentCertificateUrl, setFatherEmploymentCertificateUrl] = useState({});

    const [motherEmploymentCertificateUrl, setMotherEmploymentCertificateUrl] = useState({});

    const [annualIncome, setAnnualIncome] = useState({});

    const [ITR, setITR] = useState({});

    const [deathCertificate, setDeathCertificate] = useState({});

    const [divorce, setDivorce] = useState({});

    const [familyMembersDetails, setFamilyMembersDetails] = useState([]);

    const [familyMembersAvailedScholarshipDetails, setFamilyMembersAvailedScholarshipDetails] = useState([]);

    const [totalAnnualFamilyIncome, setTotalAnnualFamilyIncome] = useState(0);

    const [availScholorshipProgram, setAvailScholorshipProgram] = useState(false);

    const [totalAnnualFamilyIncomeError, setTotalAnnualFamilyIncomeError] = useState("");
    const [isFileUpdated, setIsFileUpdated] = useState({
        isItrAttached: false,
        isDivorceCertificateAttached: false,
        isDeathCertificateAttached: false,
        isAnuualIncomeCertiicateAttached: false,
        isFatherEmployementCertificateAttached: false,
        isMotherEmployementCertificateAttached: false,
    });



    //on state selection in city field displaying selected state cities 
    function selectState(value) {
        for (let i = 0; i < StateAndDistrictList.length; i++) {
            if (StateAndDistrictList[i].state === value) {
                setDistrictList(value => value = StateAndDistrictList[i].city);
            }
        }
    }
    // To get current location url
    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');

    //** Fetching parent detail form ***//
    useEffect(() => {
        async function initialLoad() {


            showLoading("Loading Form...");
            // to show active tab
            let fullUrl = window.location.href;
            let splitUrlArray = fullUrl.split('/');
            let parentDetailUrl = "parent-details?applicationId";
            let splitparentDetailUrl = parentDetailUrl.split('?');

            if (splitparentDetailUrl.includes("parent-details")) {
                dispatch({ type: "SHOW_ACTIVE_APPLICATION_FORM_TAB", payload: "PARENT_DETAILS" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_APPLICATION_FORM_TAB" });
            }

            const queryParams = new URLSearchParams(location.search);
            const applicationIdFromParams = await queryParams.get('applicationId');
            setApplicationId(applicationIdFromParams);

            // Fetching form from the server
            const getParentDetailsFormResponse = await applicationCommunication.getParentDetailsForUserApplication(applicationIdFromParams);

            // Handling response from the server
            if (getParentDetailsFormResponse.status === "PARENT_DETAILS_FORM_FETCHED_SUCCESSFULLY") {
                let parentDetailsForm = getParentDetailsFormResponse.parentDetailsForm;
                let familyIncomeData = getParentDetailsFormResponse.familyIncomeDetailsForm;
                setFamilyMembersAvailedScholarshipDetails(getParentDetailsFormResponse?.familyDetailsForm?.familyMembersAvailedScholarshipDetails)
                setFamilyMembersDetails(getParentDetailsFormResponse?.familyDetailsForm?.familyMembersDetails)
                // setting new form values received from the server
                setInitialFormValues(value => {
                    selectState(parentDetailsForm.parentsAddress.state);
                    return {
                        ...value, ...{
                            fatherFirstName: parentDetailsForm.fatherName.firstName,
                            fatherMiddleName: parentDetailsForm.fatherName.middleName,
                            fatherLastName: parentDetailsForm.fatherName.lastName,
                            motherFirstName: parentDetailsForm.motherName.firstName,
                            motherMiddleName: parentDetailsForm.motherName.middleName,
                            motherLastName: parentDetailsForm.motherName.lastName,
                            houseNumber: parentDetailsForm.parentsAddress.houseNumber,
                            street: parentDetailsForm.parentsAddress.street,
                            landmark: parentDetailsForm.parentsAddress.landmark,
                            area: parentDetailsForm.parentsAddress.area,
                            district: parentDetailsForm.parentsAddress.district,
                            state: parentDetailsForm.parentsAddress.state,
                            pincode: parentDetailsForm.parentsAddress.pincode,
                            extension: parentDetailsForm.parentsContactNumber.telephoneNumber.extension,
                            number: parentDetailsForm.parentsContactNumber.telephoneNumber.number,
                            altExtension: parentDetailsForm.parentsContactNumber.mobileNumber.extension,
                            altNumber: parentDetailsForm.parentsContactNumber.mobileNumber.number,
                            email: parentDetailsForm.parentsEmail.email,
                            alternateEmail: parentDetailsForm.parentsEmail.alternateEmail,

                            // Mother Employed
                            isMotherEmployed: parentDetailsForm.motherEmploymentDetails.isEmployed,
                            motherJob: parentDetailsForm.motherEmploymentDetails.details.job,
                            motherJobVerification: parentDetailsForm.motherEmploymentDetails.details.jobVerification,
                            motherPadnam: parentDetailsForm.motherEmploymentDetails.details.padnam,
                            motherAnnualIncome: parentDetailsForm.motherEmploymentDetails.details.annualIncome,
                            motherOfficeHouseNumber: parentDetailsForm.motherEmploymentDetails.officeAddress.houseNumber,
                            motherOfficeStreet: parentDetailsForm.motherEmploymentDetails.officeAddress.street,
                            motherOfficeLandmark: parentDetailsForm.motherEmploymentDetails.officeAddress.landmark,
                            motherOfficeArea: parentDetailsForm.motherEmploymentDetails.officeAddress.area,
                            motherOfficeDistrict: parentDetailsForm.motherEmploymentDetails.officeAddress.district,
                            motherOfficeState: parentDetailsForm.motherEmploymentDetails.officeAddress.state,
                            motherOfficePincode: parentDetailsForm.motherEmploymentDetails.officeAddress.pincode,
                            motherOfficeExtension: parentDetailsForm.motherEmploymentDetails.officeContactNumber.mobileNumber.extension,
                            motherOfficeNumber: parentDetailsForm.motherEmploymentDetails.officeContactNumber.mobileNumber.number,
                            motherOfficeAltExtension: parentDetailsForm.motherEmploymentDetails.officeContactNumber.alternateMobileNumber.extension,
                            motherOfficeAltNumber: parentDetailsForm.motherEmploymentDetails.officeContactNumber.alternateMobileNumber.number,
                            motherOfficeEmail: parentDetailsForm.motherEmploymentDetails.officeEmail.email,
                            motherOfficeAlternateEmail: parentDetailsForm.motherEmploymentDetails.officeEmail.alternateEmail,

                            // father Employed
                            isFatherEmployed: parentDetailsForm.fatherEmploymentDetails.isEmployed,
                            fatherJob: parentDetailsForm.fatherEmploymentDetails.details.job,
                            fatherJobVerification: parentDetailsForm.fatherEmploymentDetails.details.jobVerification,
                            fatherPadnam: parentDetailsForm.fatherEmploymentDetails.details.padnam,
                            fatherAnnualIncome: parentDetailsForm.fatherEmploymentDetails.details.annualIncome,
                            fatherOfficeHouseNumber: parentDetailsForm.fatherEmploymentDetails.officeAddress.houseNumber,
                            fatherOfficeStreet: parentDetailsForm.fatherEmploymentDetails.officeAddress.street,
                            fatherOfficeLandmark: parentDetailsForm.fatherEmploymentDetails.officeAddress.landmark,
                            fatherOfficeArea: parentDetailsForm.fatherEmploymentDetails.officeAddress.area,
                            fatherOfficeDistrict: parentDetailsForm.fatherEmploymentDetails.officeAddress.district,
                            fatherOfficeState: parentDetailsForm.fatherEmploymentDetails.officeAddress.state,
                            fatherOfficePincode: parentDetailsForm.fatherEmploymentDetails.officeAddress.pincode,
                            fatherOfficeExtension: parentDetailsForm.fatherEmploymentDetails.officeContactNumber.mobileNumber.extension,
                            fatherOfficeNumber: parentDetailsForm.fatherEmploymentDetails.officeContactNumber.mobileNumber.number,
                            fatherOfficeAltExtension: parentDetailsForm.fatherEmploymentDetails.officeContactNumber.alternateMobileNumber.extension,
                            fatherOfficeAltNumber: parentDetailsForm.fatherEmploymentDetails.officeContactNumber.alternateMobileNumber.number,
                            fatherOfficeEmail: parentDetailsForm.fatherEmploymentDetails.officeEmail.email,
                            fatherOfficeAlternateEmail: parentDetailsForm.fatherEmploymentDetails.officeEmail.alternateEmail,

                            // Family income income
                            totalAnnualIncome: familyIncomeData.totalAnnualIncome,

                            isAnnualIncomeCertificateAttached: familyIncomeData.isAnnualIncomeCertificateAttached,
                            incomeCertificateUrl: familyIncomeData.incomeCertificateUrl,

                            isItrReturnAttached: familyIncomeData.isItrReturnAttached,
                            itrCertificateUrl: familyIncomeData.itrCertificateUrl,

                            isFatherAlive: familyIncomeData.isFatherAlive,
                            deathCertificateUrl: familyIncomeData.deathCertificateUrl,

                            areParentsDivorced: familyIncomeData.areParentsDivorced,
                            divorceCertificateUrl: familyIncomeData.divorceCertificateUrl

                        }
                    };
                });

                // setIsEmployed(value => value = parentDetailsForm.parentsEmploymentDetails.isEmployed);

                setDisabled(value => value = parentDetailsForm.isSubmitted);
                setIsSubmitted(value => value = parentDetailsForm.isSubmitted);
                hideLoading();
                // handleReset();
            } else {
                showModal(getParentDetailsFormResponse.message);
            }

            //Checking application status is incomplete & making fields undisabled
            const applicationsFromServer = await communication.getMyApplications(applicationIdFromParams);

            if (applicationsFromServer.status === "SUCCESS") {
                const applicationStatus = applicationsFromServer.userApplications[0].applicationStatus

                if (applicationStatus === "INCOMPLETE") {
                    setDisabled(false);
                } else if (applicationStatus === "SELECTED" || applicationStatus === "COMPLETED" || applicationStatus === "APPLICATION_SUBMITTED") {
                    setDisabled(true);
                    setIsSubmitted(true);
                }

            }
        }
        initialLoad();


    }, []);

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    function loadNextForm() {
        history.push(`/user/application/exam-details?applicationId=${applicationId}`);
    }

    //** Setting default value for the form  ***//
    //** Required for when the data is not fetched from the server   ***//
    const defaultFormValues = {
        fatherFirstName: "",
        fatherMiddleName: "",
        fatherLastName: "",
        motherFirstName: "",
        motherMiddleName: "",
        motherLastName: "",
        houseNumber: "",
        street: "",
        landmark: "",
        area: "",
        state: "",
        district: "",
        pincode: "",
        extension: "91",
        number: "",
        altExtension: "91",
        altNumber: "",
        email: "",
        alternateEmail: "",
        // Father Employed
        isFatherEmployed: null,
        fatherJob: "",
        fatherJobVerification: "",
        fatherPadnam: "",
        fatherAnnualIncome: "",
        fatherOfficeHouseNumber: "",
        fatherOfficeStreet: "",
        fatherOfficeLandmark: "",
        fatherOfficeArea: "",
        fatherOfficeDistrict: "",
        fatherOfficeState: "",
        fatherOfficePincode: "",
        fatherOfficeExtension: "91",
        fatherOfficeNumber: "",
        fatherOfficeAltExtension: "91",
        fatherOfficeAltNumber: "",
        fatherOfficeEmail: "",
        fatherOfficeAlternateEmail: "",
        // Mother Employed
        isMotherEmployed: null,
        motherJob: "",
        motherJobVerification: "",
        motherPadnam: "",
        motherAnnualIncome: "",
        motherOfficeHouseNumber: "",
        motherOfficeStreet: "",
        motherOfficeLandmark: "",
        motherOfficeArea: "",
        motherOfficeDistrict: "",
        motherOfficeState: "",
        motherOfficePincode: "",
        motherOfficeExtension: "91",
        motherOfficeNumber: "",
        motherOfficeAltExtension: "91",
        motherOfficeAltNumber: "",
        motherOfficeEmail: "",
        motherOfficeAlternateEmail: "",


        // Family Income Details
        totalAnnualIncome: "",

        isAnnualIncomeCertificateAttached: null,
        incomeCertificateUrl: "",

        isItrReturnAttached: null,
        itrCertificateUrl: "",

        isFatherAlive: null,
        deathCertificateUrl: "",

        areParentsDivorced: null,
        divorceCertificateUrl: "",

    }

    //** Yup libary Form Validation  ***//
    let validationSchema = Yup.object().shape({
        //****Validation for parent details form */
        fatherFirstName: Yup.string().required("First Name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        fatherMiddleName: Yup.string().required("Middle Name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        fatherLastName: Yup.string().required("Last Name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),

        //*Validation for Father Employment details ***//
        isFatherEmployed: Yup.boolean().required('field is required*'),
        fatherJob: Yup.string()
            .when("isFatherEmployed", {
                is: true,
                then: Yup.string().required("Job description required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
                otherwise: Yup.string().nullable()
            }),
        fatherJobVerification: Yup.mixed()
            .when("isFatherEmployed", {
                is: true,
                then: Yup.mixed().required("Attachment is required").nullable(),
                otherwise: Yup.mixed().nullable()
            }),
        fatherPadnam: Yup.string()
            .when("isFatherEmployed", {
                is: true,
                then: Yup.string().required("Padnam is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
                otherwise: Yup.string().nullable()
            }),
        fatherAnnualIncome: Yup.number()
            .when("isFatherEmployed", {
                is: true,
                then: Yup.number().required("Annual Income is required")
                    .typeError('Annual Income must be a number')
                    .positive('Annual Income must be greater than zero')
                    .max(600000, "Annual Income can't be greater than 6 Lakhs"),
                otherwise: Yup.number().nullable()
            }),

        //*Validation for Father Office address details ***//
        fatherOfficeHouseNumber: Yup.string()
            .when("isFatherEmployed", {
                is: true,
                then: Yup.string().required("House Number is required"),
                otherwise: Yup.string().nullable()
            }),
        fatherOfficeStreet: Yup.string()
            .when("isFatherEmployed", {
                is: true,
                then: Yup.string().required("Required"),
                otherwise: Yup.string().nullable()
            }),
        fatherOfficeLandmark: Yup.string()
            .when("isFatherEmployed", {
                is: true,
                then: Yup.string().required("Landmark is required"),
                otherwise: Yup.string().nullable()
            }),
        fatherOfficeArea: Yup.string()
            .when("isFatherEmployed", {
                is: true,
                then: Yup.string().required("Area is required"),
                otherwise: Yup.string().nullable()
            }),
        fatherOfficeDistrict: Yup.string()
            .when("isFatherEmployed", {
                is: true,
                then: Yup.string().required("District is required").test('Tests', 'Select district', value => value && value.trim().length > 0 && value.trim() !== 'select'),
                otherwise: Yup.string().nullable()
            }),
        fatherOfficeState: Yup.string()
            .when("isFatherEmployed", {
                is: true,
                then: Yup.string().required("State is required").test('Tests', 'Select state', value => value && value.trim().length > 0 && value.trim() !== 'select'),
                otherwise: Yup.string().nullable()
            }),
        fatherOfficePincode: Yup.string()
            .when("isFatherEmployed", {
                is: true,
                then: Yup.string().required("Pin code is required")
                    .matches(/^[0-9]+$/, "Must be only digits")
                    .min(6, 'Minimum 6 digits')
                    .max(6, 'Maximum 6 digits'),
                otherwise: Yup.string().nullable()
            }),

        //*Validation for Father office Contact details ***//
        fatherOfficeExtension: Yup.string()
            .when("isFatherEmployed", {
                is: true,
                then: Yup.string().required("Phone Number is required")
                    .matches(/^[0-9]+$/, "Must be only digits")
                    .min(2, 'Minimum 2 digits')
                    .max(2, 'Maximum 2 digits'),
                otherwise: Yup.string().nullable(),
            }),

        fatherOfficeNumber: Yup.string()
            .when("isFatherEmployed", {
                is: true,
                then: Yup.string().required("Phone Number is required")
                    .matches(/^[0-9]+$/, "Must be only digits")
                    .min(10, 'Minimum 10 digits')
                    .max(10, 'Maximum 10 digits'),
                otherwise: Yup.string().nullable()
            }),
        fatherOfficeAltExtension: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(2, 'Minimum 2 digits')
            .max(5, 'Maximum 5 digits'),
        fatherOfficeAltNumber: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, 'Minimum 6 digits')
            .max(10, 'Maximum 10 digits'),

        //*Validation for Father Office Email ***//
        fatherOfficeEmail: Yup.string()
            .when("isFatherEmployed", {
                is: true,
                then: Yup.string().email("must be a valid email").required("Email is required"),
                otherwise: Yup.string().nullable()
            }),
        fatherOfficeAlternateEmail: Yup.string().email("must be a valid email"),


        // ****Validation for mother name details form */
        motherFirstName: Yup.string().required("First Name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        motherMiddleName: Yup.string().required("Husband/Father Name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        motherLastName: Yup.string().required("Last Name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),

        //*Validation for Father Employment details ***//
        isMotherEmployed: Yup.boolean().required('field is required*'),
        motherJob: Yup.string()
            .when("isMotherEmployed", {
                is: true,
                then: Yup.string().required("Job description required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
                otherwise: Yup.string().nullable()
            }),
        motherJobVerification: Yup.mixed()
            .when("isMotherEmployed", {
                is: true,
                then: Yup.mixed().required("Attachment is required").nullable(),
                otherwise: Yup.mixed().nullable()
            }),
        motherPadnam: Yup.string()
            .when("isMotherEmployed", {
                is: true,
                then: Yup.string().required("Padnam is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
                otherwise: Yup.string().nullable()
            }),
        motherAnnualIncome: Yup.number()
            .when("isMotherEmployed", {
                is: true,
                then: Yup.number().required("Annual Income is required")
                    .typeError('Annual Income must be a number')
                    .positive('Annual Income must be greater than zero')
                    .max(600000, "Annual Income can't be greater than 6 Lakhs"),
                otherwise: Yup.number().nullable()
            }),

        //*Validation for mother Office address details ***//
        motherOfficeHouseNumber: Yup.string()
            .when("isMotherEmployed", {
                is: true,
                then: Yup.string().required("House Number is required"),
                otherwise: Yup.string().nullable()
            }),
        motherOfficeStreet: Yup.string()
            .when("isMotherEmployed", {
                is: true,
                then: Yup.string().required("Required"),
                otherwise: Yup.string().nullable()
            }),
        motherOfficeLandmark: Yup.string()
            .when("isMotherEmployed", {
                is: true,
                then: Yup.string().required("Landmark is required"),
                otherwise: Yup.string().nullable()
            }),
        motherOfficeArea: Yup.string()
            .when("isMotherEmployed", {
                is: true,
                then: Yup.string().required("Area is required"),
                otherwise: Yup.string().nullable()
            }),
        motherOfficeDistrict: Yup.string()
            .when("isMotherEmployed", {
                is: true,
                then: Yup.string().required("District is required").test('Tests', 'Select district', value => value && value.trim().length > 0 && value.trim() !== 'select'),
                otherwise: Yup.string().nullable()
            }),
        motherOfficeState: Yup.string()
            .when("isMotherEmployed", {
                is: true,
                then: Yup.string().required("State is required").test('Tests', 'Select state', value => value && value.trim().length > 0 && value.trim() !== 'select'),
                otherwise: Yup.string().nullable()
            }),
        motherOfficePincode: Yup.string()
            .when("isMotherEmployed", {
                is: true,
                then: Yup.string().required("Pin code is required")
                    .matches(/^[0-9]+$/, "Must be only digits")
                    .min(6, 'Minimum 6 digits')
                    .max(6, 'Maximum 6 digits'),
                otherwise: Yup.string().nullable()
            }),

        //*Validation for mother office Contact details ***//
        motherOfficeExtension: Yup.string()
            .when("isMotherEmployed", {
                is: true,
                then: Yup.string().required("Phone Number is required")
                    .matches(/^[0-9]+$/, "Must be only digits")
                    .min(2, 'Minimum 2 digits')
                    .max(2, 'Maximum 2 digits'),
                otherwise: Yup.string().nullable(),
            }),

        motherOfficeNumber: Yup.string()
            .when("isMotherEmployed", {
                is: true,
                then: Yup.string().required("Phone Number is required")
                    .matches(/^[0-9]+$/, "Must be only digits")
                    .min(10, 'Minimum 10 digits')
                    .max(10, 'Maximum 10 digits'),
                otherwise: Yup.string().nullable()
            }),
        motherOfficeAltExtension: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(2, 'Minimum 2 digits')
            .max(5, 'Maximum 5 digits'),
        motherOfficeAltNumber: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, 'Minimum 6 digits')
            .max(10, 'Maximum 10 digits'),

        //*Validation for mother Office Email ***//
        motherOfficeEmail: Yup.string()
            .when("isMotherEmployed", {
                is: true,
                then: Yup.string().email("must be a valid email").required("Email is required"),
                otherwise: Yup.string().nullable()
            }),
        motherOfficeAlternateEmail: Yup.string().email("must be a valid email"),



        //*Validation for parent address details ***//
        houseNumber: Yup.string().required("House Number is required"),
        street: Yup.string().required("Required"),
        landmark: Yup.string().required("Landmark is required"),
        area: Yup.string().required("Area is required"),
        state: Yup.string().required("State is required").test('Tests', 'Select state', value => value && value.trim().length > 0 && value.trim() !== 'select'),
        district: Yup.string().required("District is required").test('Tests', 'Select district', value => value && value.trim().length > 0 && value.trim() !== 'select'),
        pincode: Yup.string().required("Pin code is required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, 'Minimum 6 digits')
            .max(6, 'Maximum 6 digits'),

        //*Validation for Parent Contact details ***//
        extension: Yup.string().required("Pin is required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(2, 'Minimum 2 digits')
            .max(2, 'Maximum 2 digits'),
        number: Yup.string()
            .required("Phone Number is required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Minimum 10 digits')
            .max(10, 'Maximum 10 digits'),
        altExtension: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(2, 'Minimum 2 digits')
            .max(5, 'Maximum 5 digits'),
        altNumber: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, 'Minimum 6 digits')
            .max(10, 'Maximum 10 digits'),

        //*Validation for Parent Email ***//
        email: Yup.string().email("must be a valid email").required("Email is required"),
        alternateEmail: Yup.string().email("must be a valid email"),


        // Family Income Details
        totalAnnualIncome: Yup.number().required("Annual Income is required")
            .typeError('Annual Income must be a number')
            .positive('Annual Income must be greater than zero')
            .max(600000, "Annual Income can't be greater than 6 Lakhs"),
        isAnnualIncomeCertificateAttached: Yup.boolean().required("Annual Income Certificate is required"),
        incomeCertificateUrl: Yup.mixed()
            .when("isAnnualIncomeCertificateAttached", {
                is: true,
                then: Yup.mixed().required("Annual Income Certificate is required"),
                otherwise: Yup.mixed().nullable()
            }),
        isItrReturnAttached: Yup.boolean().required("ITR Certificate is required"),
        itrCertificateUrl: Yup.mixed()
            .when("isItrReturnAttached", {
                is: true,
                then: Yup.mixed().required("ITR Certificate is required"),
                otherwise: Yup.mixed().nullable()
            }),
        isFatherAlive: Yup.boolean().required("Field is required"),
        deathCertificateUrl: Yup.mixed()
            .when("isFatherAlive", {
                is: false,
                then: Yup.mixed().required("Death Certificate is required"),
                otherwise: Yup.mixed().nullable()
            }),
        areParentsDivorced: Yup.boolean().required("parent marital staus required"),
        divorceCertificateUrl: Yup.mixed()
            .when("areParentsDivorced", {
                is: true,
                then: Yup.mixed().required("Divorce Certificate is required"),
                otherwise: Yup.mixed().nullable()
            }),


    });

    function onFileSelect(event, forFile) {
        let data;
        event.preventDefault();
        // let formData = new FormData();

        if (forFile === "fatherEmploymentCertificate") {
            data = ({ file: event.target.files[0], fileName: "fatherEmploymentCertificate.pdf" });

            // formData.append("fatherEmploymentCertificate", event.target.files[0], event.target.files[0].name);
            setFatherEmploymentCertificateUrl(event.target.files[0]);
            setFieldValue('fatherJobVerification', event.target.files[0]);
            setIsFileUpdated({ ...isFileUpdated, isFatherEmployementCertificateAttached: true })
        }

        if (forFile === "motherEmploymentCertificate") {
            data = ({ file: event.target.files[0], fileName: "motherEmploymentCertificate.pdf" });
            // formData.append("motherEmploymentCertificate", event.target.files[0], event.target.files[0].name);
            setMotherEmploymentCertificateUrl(event.target.files[0]);
            setFieldValue('motherJobVerification', event.target.files[0]);
            setIsFileUpdated({ ...isFileUpdated, isMotherEmployementCertificateAttached: true })

        }

        if (forFile === "INCOME") {
            data = ({ file: event.target.files[0], fileName: "incomeCertificateUrl.pdf" });
            setAnnualIncome(event.target.files[0]);
            setFieldValue('incomeCertificateUrl', event.target.files[0]);
            setIsFileUpdated({ ...isFileUpdated, isAnuualIncomeCertiicateAttached: true })

        }

        if (forFile === "ITR") {
            data = ({ file: event.target.files[0], fileName: "itrCertificateUrl.pdf" });
            setITR(event.target.files[0]);
            setFieldValue('itrCertificateUrl', event.target.files[0]);
            setIsFileUpdated({ ...isFileUpdated, isItrAttached: true })

        }

        if (forFile === "DEATH_CERTIFICATE") {
            data = ({ file: event.target.files[0], fileName: "deathCertificateUrl.pdf" });
            setDeathCertificate(event.target.files[0]);
            setFieldValue('deathCertificateUrl', event.target.files[0]);
            setIsFileUpdated({ ...isFileUpdated, isDeathCertificateAttached: true });

        }

        if (forFile === "DIVORCE_CERTIFICATE") {
            data = ({ file: event.target.files[0], fileName: "divorceCertificateUrl.pdf" });
            setDivorce(event.target.files[0]);
            setFieldValue('divorceCertificateUrl', event.target.files[0]);
            setIsFileUpdated({ ...isFileUpdated, isDivorceCertificateAttached: true })

        }

    }


    // Job verification file preview
    const previewDocumentsHandler = async (fileUrl) => {
        if (typeof (fileUrl) === "string") {
            await communication.getUploadedDocumets(fileUrl)
        }

        if (typeof (fileUrl) === "object") {
            const preview = URL.createObjectURL(fileUrl);
            window.open(preview);
        }
    }

    const { handleSubmit, handleChange, setFieldValue, values, errors, handleReset } = useFormik({
        initialValues: initialFormValues === null ? defaultFormValues : initialFormValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {
            try {
                // Loading on save and submit button
                showLoading(saveType === "SUBMIT" ? "Submitting Form..." : "Saving Form...");
                // Disable Save, Cancel and Submit Buttons
                setDisableActions(true);

                let formData = new FormData();
                if (values.isMotherEmployed === true) {
                    if (isFileUpdated.isMotherEmployementCertificateAttached) {

                        formData.append("motherEmploymentCertificate", motherEmploymentCertificateUrl); // "motherEmploymentCertificate"
                    }
                }

                if (values.isFatherEmployed === true) {
                    if (isFileUpdated.isFatherEmployementCertificateAttached) {

                        formData.append("fatherEmploymentCertificate", fatherEmploymentCertificateUrl); // "motherEmploymentCertificate"
                    }
                }

                if (values.isAnnualIncomeCertificateAttached === true) {
                    if (isFileUpdated.isAnuualIncomeCertiicateAttached) {

                        formData.append("incomeCertificate", annualIncome); // "INCOME_CERTIFICATE"
                    }
                }

                if (values.isFatherAlive === false) {
                    if (isFileUpdated.isDeathCertificateAttached) {
                        formData.append("deathCertificate", deathCertificate); // "FATHER_DEATH_CERTIFICATE"
                    }
                }

                if (values.isItrReturnAttached === true) {
                    if (isFileUpdated.isItrAttached) {

                        formData.append("itrCertificate", ITR); // "ITR_CERTIFICATE"
                    }

                }

                if (values.areParentsDivorced === true) {
                    if (isFileUpdated.isDivorceCertificateAttached) {
                        formData.append("divorceCertificate", divorce); // "DIVORCE_CERTIFICATE"

                    }

                }


                //set parent details object to send
                let parentDetailsForm = {
                    fatherName: {
                        firstName: values.fatherFirstName,
                        middleName: values.fatherMiddleName,
                        lastName: values.fatherLastName
                    },
                    motherName: {
                        firstName: values.motherFirstName,
                        middleName: values.motherMiddleName,
                        lastName: values.motherLastName
                    },
                    parentsAddress: {
                        houseNumber: values.houseNumber,
                        street: values.street,
                        landmark: values.landmark,
                        area: values.area,
                        district: values.district,
                        state: values.state,
                        pincode: values.pincode
                    },
                    parentsContactNumber: {
                        telephoneNumber: {
                            extension: values.extension,
                            number: values.number
                        },
                        mobileNumber: {
                            extension: values.altExtension,
                            number: values.altNumber
                        }
                    },
                    parentsEmail: {
                        email: values.email,
                        alternateEmail: values.alternateEmail
                    },

                    motherEmploymentDetails: {
                        isEmployed: values.isMotherEmployed,
                        details: {
                            job: values.motherJob,
                            jobVerification: values.motherJobVerification,
                            padnam: values.motherPadnam,
                            annualIncome: values.motherAnnualIncome
                        },
                        officeAddress: {
                            houseNumber: values.motherOfficeHouseNumber,
                            street: values.motherOfficeStreet,
                            landmark: values.motherOfficeLandmark,
                            area: values.motherOfficeArea,
                            district: values.motherOfficeDistrict,
                            state: values.motherOfficeState,
                            pincode: values.motherOfficePincode,
                        },
                        officeContactNumber: {
                            mobileNumber: {
                                extension: values.motherOfficeExtension,
                                number: values.motherOfficeNumber,
                            },
                            alternateMobileNumber: {
                                extension: values.motherOfficeAltExtension,
                                number: values.motherOfficeAltNumber,
                            }
                        },
                        officeEmail: {
                            email: values.motherOfficeEmail,
                            alternateEmail: values.motherOfficeAlternateEmail,
                        }
                    },

                    fatherEmploymentDetails: {
                        isEmployed: values.isFatherEmployed,
                        details: {
                            job: values.fatherJob,
                            jobVerification: values.fatherJobVerification,
                            padnam: values.fatherPadnam,
                            annualIncome: values.fatherAnnualIncome
                        },
                        officeAddress: {
                            houseNumber: values.fatherOfficeHouseNumber,
                            street: values.fatherOfficeStreet,
                            landmark: values.fatherOfficeLandmark,
                            area: values.fatherOfficeArea,
                            district: values.fatherOfficeDistrict,
                            state: values.fatherOfficeState,
                            pincode: values.fatherOfficePincode,
                        },
                        officeContactNumber: {
                            mobileNumber: {
                                extension: values.fatherOfficeExtension,
                                number: values.fatherOfficeNumber,
                            },
                            alternateMobileNumber: {
                                extension: values.fatherOfficeAltExtension,
                                number: values.fatherOfficeAltNumber,
                            }
                        },
                        officeEmail: {
                            email: values.fatherOfficeEmail,
                            alternateEmail: values.fatherOfficeAlternateEmail,
                        }
                    }
                }

                //  Family income Details
                let familyincomedetails = {

                    totalAnnualIncome: Number(values.totalAnnualIncome),

                    isAnnualIncomeCertificateAttached: values.isAnnualIncomeCertificateAttached,

                    isItrReturnAttached: values.isItrReturnAttached,

                    isFatherAlive: values.isFatherAlive,

                    areParentsDivorced: values.areParentsDivorced,

                }

                let familyDetailsForm = {
                    familyMembersDetails: familyMembersDetails,
                    familyMembersAvailedScholarshipDetails: familyMembersAvailedScholarshipDetails,
                    totalFamilyAnnualIncome: Number(totalAnnualFamilyIncome)
                }


                let data = {
                    applicationId: applicationId,
                    uploadType: "SAVE",
                    parentDetailsForm: JSON.stringify(parentDetailsForm),
                    familyIncomeDetails: JSON.stringify(familyincomedetails),
                    familyDetails: JSON.stringify(familyDetailsForm)
                }
                // Sending data to server

                let sendParentDetailsFormResponse = await applicationCommunication.uploadParentDetailsForm(formData, data);
                // Handling response from the server
                if (sendParentDetailsFormResponse.status === "FORM_SAVED_SUCCESSFULLY" || sendParentDetailsFormResponse.data.status === "FORM_SAVED_SUCCESSFULLY") {

                    if (saveType === "SAVE") {
                    } else {
                        history.push(`/user/application/exam-details?applicationId=${applicationId}`);
                    }
                }
                if (sendParentDetailsFormResponse.data.status === "VALIDATION_FAILED" || sendParentDetailsFormResponse.status === "VALIDATION_FAILED") {
                    showModal(sendParentDetailsFormResponse.data.validationErrorsList[0].message || sendParentDetailsFormResponse.validationErrorsList[0].message)
                    hideLoading();
                    setDisableActions(false);
                    return false;
                }

                if (sendParentDetailsFormResponse.data.status === "FAILED" || sendParentDetailsFormResponse.status === "FAILED") {
                    showModal(sendParentDetailsFormResponse.data.message || sendParentDetailsFormResponse.message)
                    hideLoading();
                    setDisableActions(false);
                    return false;
                }

                showModal(sendParentDetailsFormResponse.message || sendParentDetailsFormResponse.data.message);

                // if (sendParentDetailsFormResponse.status === "VALIDATION_FAILED") {
                //     showModal(sendParentDetailsFormResponse.validationErrorsList[0].message);
                // }
                // showModal(sendParentDetailsFormResponse.message);
                hideLoading();
                setDisableActions(false);

                return false;

            } catch (error) {
                showModal(error?.message);
                hideLoading();
            }
        },
        validationSchema: validationSchema,
    });

    // delete table row
    function deleteFamilyMemberDetails(itemToDelete) {
        setFamilyMembersDetails(value => {
            return value.filter(item => item !== itemToDelete);
        });
    }

    function deleteFamilyMembersAvailedScholarshipDetails(itemToDelete) {
        setFamilyMembersAvailedScholarshipDetails(value => {
            return value.filter(item => item !== itemToDelete);
        });
    }

    //setting max length to total family annual income field
    const maxLengthCheck = (e) => {
        if (e.target.value > 600000) {
            setTotalAnnualFamilyIncomeError("maxError");
        }
    }

    useEffect(() => {

        let calculatedAmount = 0 ;
        familyMembersDetails.forEach((ele) => {
            calculatedAmount = calculatedAmount + ele.totalAnnualIncome
        });

        setTotalAnnualFamilyIncome(calculatedAmount);

    }, [familyMembersDetails]);


    return (
        <section className="application_flow_big">
            <div className="personal_details_main">
                <div className="container">
                    {
                        isLoading.value
                            ?
                            <LoadingForms message={isLoading.message} />
                            :
                            <div className="row">
                                <div className="col-12">
                                    <form onSubmit={handleSubmit}>
                                        {/* Father Details Field */}
                                        <div className="row ">


                                            <div className="col-lg-2 col-md-2">
                                                <FormFieldTitle name="Father Name *" />
                                            </div>

                                            <div className="col-lg-10 col-md-10">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">First Name</Label>
                                                        <input type="text" placeholder="E.g. Saurabh" name="fatherFirstName" onChange={handleChange} value={values.fatherFirstName} className={`form-control ${errors.fatherFirstName ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.fatherFirstName}</div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Middle Name</Label>
                                                        <input type="text" placeholder="E.g. Ram" name="fatherMiddleName" onChange={handleChange} value={values.fatherMiddleName} className={`form-control ${errors.fatherMiddleName ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                        <div className="invalid-feedback">{errors.fatherMiddleName}</div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Last Name</Label>
                                                        <input type="text" placeholder="E.g. Bhosale" name="fatherLastName" onChange={handleChange} value={values.fatherLastName} className={`form-control ${errors.fatherLastName ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                        <div className="invalid-feedback">{errors.fatherLastName}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        {/* Father Business or Employment Details */}
                                        <div className="row mt-3">

                                            <div className="col-lg-2 col-md-2">
                                                <FormFieldTitle name="Father Business or Employment Details *" />
                                            </div>

                                            <div className="col-lg-10 col-md-10">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-6 col-10">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Father Employed? </Label>
                                                        <div className="row mt-1">
                                                            <div className="col-5">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                                <input onChange={() => setFieldValue("isFatherEmployed", true)} id="isFatherEmployedTrue" checked={values.isFatherEmployed === null ? (values.isFatherEmployed) : Boolean(values.isFatherEmployed)} type="radio" value="true" name="isFatherEmployedRadio" className={`form-check-input mx-3 ${errors.isFatherEmployed ? "is-invalid" : ""}`} disabled={disabled} />
                                                            </div>
                                                            <div className="col-5">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                                <input onChange={() => setFieldValue("isFatherEmployed", false)} id="isFatherEmployedTrue" checked={values.isFatherEmployed === null ? (values.isFatherEmployed) : Boolean(!values.isFatherEmployed)} type="radio" value="true" name="isFatherEmployedRadio" className={`form-check-input mx-3 ${errors.isFatherEmployed ? "is-invalid" : ""}`} disabled={disabled} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {values.isFatherEmployed ?
                                                        <>
                                                            <div className="col-lg-4 col-md-4">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Job Details</Label>
                                                                <input type="text" placeholder="E.g. working in TCS" name="fatherJob" value={values.fatherJob} onChange={handleChange} className={`form-control ${errors.fatherJob ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.fatherJob}</div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-4">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Employment Certificate</Label>
                                                                <input type="file" name="fatherJobVerification" onChange={(e) => { onFileSelect(e, "fatherEmploymentCertificate") }} value={values.fatherJobVerification?.filename} className={`form-control ${errors.fatherJobVerification ? "is-invalid" : ""}`} disabled={disabled} />
                                                                <div className="invalid-feedback">{errors.fatherJobVerification}</div>
                                                            </div>
                                                            <div className="col-lg-1 col-md-2 col-4 form_preview_btn">
                                                                <button type="button" onClick={() => { previewDocumentsHandler(typeof values.fatherJobVerification?.fileUrl === "string" ? values.fatherJobVerification?.fileUrl : values.fatherJobVerification) }}>Preview</button>
                                                            </div>
                                                            {isSubmitted ?
                                                                <span></span>
                                                                :
                                                                <div style={{ textAlign: "end" }}>
                                                                    <span style={{ fontSize: "11px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                                </div>
                                                            }
                                                        </>

                                                        : null
                                                    }

                                                </div>
                                            </div>

                                        </div>

                                        {values.isFatherEmployed ?
                                            <div className="row">

                                                <div className="col-lg-2 col-md-2">
                                                    <H4></H4>
                                                </div>

                                                <div className="col-lg-10 col-md-10">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Designation</Label>
                                                            <input type="text" placeholder="E.g. Clerk" name="fatherPadnam" value={values.fatherPadnam} onChange={handleChange} className={`form-control ${errors.fatherPadnam ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                            <div className="invalid-feedback">{errors.fatherPadnam}</div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Annual Income</Label>
                                                            <input type="number" name="fatherAnnualIncome" value={values.fatherAnnualIncome} onChange={handleChange} className={`form-control ${errors.fatherAnnualIncome ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                            <div className="invalid-feedback">{errors.fatherAnnualIncome}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            : null
                                        }

                                        {/* Father Office Address Details Field */}

                                        {values.isFatherEmployed ?
                                            <>
                                                <div className="row mt-3">

                                                    <div className="col-lg-2 col-md-2">
                                                        <FormFieldTitle name="Office Address *" />
                                                    </div>

                                                    <div className="col-lg-10 col-md-10">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Building Number</Label>
                                                                <input type="text" placeholder="E.g. 78" name="fatherOfficeHouseNumber" value={values.fatherOfficeHouseNumber} onChange={handleChange} className={`form-control ${errors.fatherOfficeHouseNumber ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.fatherOfficeHouseNumber}</div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Street</Label>
                                                                <input type="text" placeholder="E.g. Walkar road" name="fatherOfficeStreet" value={values.fatherOfficeStreet} onChange={handleChange} className={`form-control ${errors.fatherOfficeStreet ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.fatherOfficeStreet}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-2 col-md-2">
                                                    </div>

                                                    <div className="col-lg-10 col-md-10 mt-3">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Land Mark</Label>
                                                                <input type="text" placeholder="E.g. Near india gate" name="fatherOfficeLandmark" value={values.fatherOfficeLandmark} onChange={handleChange} className={`form-control ${errors.fatherOfficeLandmark ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.fatherOfficeLandmark}</div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Area</Label>
                                                                <input type="text" placeholder="E.g. Juhu" name="fatherOfficeArea" value={values.fatherOfficeArea} onChange={handleChange} className={`form-control ${errors.fatherOfficeArea ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.fatherOfficeArea}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-2 col-md-2">
                                                    </div>

                                                    <div className="col-lg-10 col-md-10 mt-3">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">State</Label>
                                                                <select name="fatherOfficeState" onChange={(event) => { handleChange(event); selectState(event.target.value); }} value={values.fatherOfficeState} className={`form-select ${errors.fatherOfficeState ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                                    <option value="">Select</option>
                                                                    {StateAndDistrictList?.map((stateList, index) => {
                                                                        const { state } = stateList;
                                                                        return (
                                                                            <option key={index}>{state}</option>
                                                                        );
                                                                    })}
                                                                </select>
                                                                <div className="invalid-feedback">{errors.fatherOfficeState}</div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">District</Label>
                                                                <select name="fatherOfficeDistrict" onChange={handleChange} value={values.fatherOfficeDistrict} className={`form-select ${errors.fatherOfficeDistrict ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                                    <option selected value=" ">Select</option>
                                                                    {districtList.map((districtName, index) => {
                                                                        return (

                                                                            <option key={index} >{districtName}</option>

                                                                        );

                                                                    })}

                                                                </select>
                                                                <div className="invalid-feedback">{errors.fatherOfficeDistrict}</div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-2">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Pin Code</Label>
                                                                <input type="number" placeholder="E.g. 442510" name="fatherOfficePincode" value={values.fatherOfficePincode} onChange={handleChange} className={`form-control ${errors.fatherOfficePincode ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.fatherOfficePincode}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                {/* Father Office Number Details Field */}
                                                <div className="row mt-3">

                                                    <div className="col-lg-2 col-md-2">
                                                        <FormFieldTitle name="Office Number *" />
                                                    </div>

                                                    <div className="col-lg-10 col-md-10">
                                                        <div className="row">

                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-md-4 col-4">
                                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="extension">Code</Label>
                                                                        <div className="code_extension">
                                                                            <span>+</span>
                                                                            <input type="number" name="fatherOfficeExtension" value={values.fatherOfficeExtension} onChange={handleChange} className={`form-control ${errors.fatherOfficeExtension ? "is-invalid" : ""}`} disabled />
                                                                            <div className="invalid-feedback">{errors.fatherOfficeExtension}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-8">
                                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Mobile Number</Label>
                                                                        <input type="number" placeholder="E.g. 7896523140" name="fatherOfficeNumber" value={values.fatherOfficeNumber} onChange={handleChange} className={`form-control ${errors.fatherOfficeNumber ? "is-invalid" : ""}`} disabled={disabled} />
                                                                        <div className="invalid-feedback">{errors.fatherOfficeNumber}</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6 mt-lg-0 mt-md-0 mt-sm-2">
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-md-4 col-4">
                                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="extension">Code</Label>
                                                                        <div className="code_extension">
                                                                            <span>+</span>
                                                                            <div>
                                                                                <input type="number" name="fatherOfficeAltExtension" value={values.fatherOfficeAltExtension} placeholder="E.g. +91/0432" onChange={handleChange} className={`form-control ${errors.fatherOfficeAltExtension ? "is-invalid" : ""}`} disabled={disabled} />
                                                                                <div className="invalid-feedback">{errors.fatherOfficeAltExtension}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-8">
                                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Alternate Mobile Number</Label>
                                                                        <input type="number" placeholder="E.g. 7896523140" name="fatherOfficeAltNumber" value={values.fatherOfficeAltNumber} onChange={handleChange} className={`form-control ${errors.fatherOfficeAltNumber ? "is-invalid" : ""}`} disabled={disabled} />
                                                                        <div className="invalid-feedback">{errors.fatherOfficeAltNumber}</div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>

                                                </div>

                                                {/* Father Office Email Address Details Field */}
                                                <div className="row mt-3">

                                                    <div className="col-lg-2 col-md-2">
                                                        <FormFieldTitle name="Office Email Address *" />
                                                    </div>

                                                    <div className="col-lg-10 col-md-10">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Primary Email</Label>
                                                                <input type="text" placeholder="E.g. xyz@gmail.com" name="fatherOfficeEmail" value={values.fatherOfficeEmail} onChange={handleChange} className={`form-control ${errors.fatherOfficeEmail ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.fatherOfficeEmail}</div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Alternate Email</Label>
                                                                <input type="text" placeholder="E.g. abc@gmail.com" name="fatherOfficeAlternateEmail" value={values.fatherOfficeAlternateEmail} onChange={handleChange} className={`form-control ${errors.fatherOfficeAlternateEmail ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.fatherOfficeAlternateEmail}</div>                                       </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                        }


                                        {/* Mother Details Field */}
                                        <div className="row mt-3">

                                            <div className="col-lg-2 col-md-2">
                                                <FormFieldTitle name="Mother Name *" />
                                            </div>

                                            <div className="col-lg-10 col-md-10">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">First Name</Label>
                                                        <input type="text" placeholder="E.g. Shakshi" name="motherFirstName" onChange={handleChange} value={values.motherFirstName} className={`form-control ${errors.motherFirstName ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                        <div className="invalid-feedback">{errors.motherFirstName}</div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Husband / Father Name</Label>
                                                        <input type="text" placeholder="E.g. Ram" name="motherMiddleName" onChange={handleChange} value={values.motherMiddleName} className={`form-control ${errors.motherMiddleName ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                        <div className="invalid-feedback">{errors.motherMiddleName}</div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Last Name</Label>
                                                        <input type="text" placeholder="E.g. Bhosale" name="motherLastName" onChange={handleChange} value={values.motherLastName} className={`form-control ${errors.motherLastName ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                        <div className="invalid-feedback">{errors.motherLastName}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        {/* Mother Business or Employment Details */}
                                        <div className="row mt-3">

                                            <div className="col-lg-2 col-md-2">
                                                <FormFieldTitle name="Mother Business or Employment Details *" />
                                            </div>

                                            <div className="col-lg-10 col-md-10">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-6 col-10">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Mother Employed? </Label>
                                                        <div className="row mt-1">
                                                            <div className="col-5">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                                <input onChange={() => setFieldValue("isMotherEmployed", true)} id="isMotherEmployedTrue" checked={values.isMotherEmployed === null ? (values.isMotherEmployed) : Boolean(values.isMotherEmployed)} type="radio" value="true" name="isMotherEmployedRadio" className={`form-check-input mx-3 ${errors.isMotherEmployed ? "is-invalid" : ""}`} disabled={disabled} />
                                                            </div>
                                                            <div className="col-5">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                                <input onChange={() => setFieldValue("isMotherEmployed", false)} id="isMotherEmployedTrue" checked={values.isMotherEmployed === null ? (values.isMotherEmployed) : Boolean(!values.isMotherEmployed)} type="radio" value="true" name="isMotherEmployedRadio" className={`form-check-input mx-3 ${errors.isMotherEmployed ? "is-invalid" : ""}`} disabled={disabled} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {values.isMotherEmployed ?
                                                        <>
                                                            <div className="col-lg-4 col-md-4">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Job Details</Label>
                                                                <input type="text" placeholder="E.g. working in TCS" name="motherJob" value={values.motherJob} onChange={handleChange} className={`form-control ${errors.motherJob ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.motherJob}</div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-4">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Employment Certificate</Label>
                                                                <input type="file" name="motherJobVerification" onChange={(e) => { onFileSelect(e, "motherEmploymentCertificate") }} value={values.motherJobVerification?.filename} className={`form-control ${errors.motherJobVerification ? "is-invalid" : ""}`} disabled={disabled} />
                                                                <div className="invalid-feedback">{errors.motherJobVerification}</div>
                                                            </div>
                                                            <div className="col-lg-1 col-md-2 col-4 form_preview_btn">
                                                                <button type="button" onClick={() => { previewDocumentsHandler(typeof values.motherJobVerification?.fileUrl === "string" ? values.motherJobVerification?.fileUrl : values.motherJobVerification) }}>Preview</button>
                                                            </div>
                                                            {isSubmitted ?
                                                                <span></span>
                                                                :
                                                                <div style={{ textAlign: "end" }}>
                                                                    <span style={{ fontSize: "11px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                                </div>
                                                            }
                                                        </>

                                                        : null
                                                    }

                                                </div>
                                            </div>

                                        </div>

                                        {values.isMotherEmployed ?
                                            <div className="row">

                                                <div className="col-lg-2 col-md-2">
                                                    <H4></H4>
                                                </div>

                                                <div className="col-lg-10 col-md-10">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Designation</Label>
                                                            <input type="text" placeholder="E.g. Clerk" name="motherPadnam" value={values.motherPadnam} onChange={handleChange} className={`form-control ${errors.motherPadnam ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                            <div className="invalid-feedback">{errors.motherPadnam}</div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Annual Income</Label>
                                                            <input type="number" name="motherAnnualIncome" value={values.motherAnnualIncome} onChange={handleChange} className={`form-control ${errors.motherAnnualIncome ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                            <div className="invalid-feedback">{errors.motherAnnualIncome}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            : null
                                        }

                                        {/* Mother Office Address Details Field */}
                                        {values.isMotherEmployed ?
                                            <>
                                                <div className="row mt-3">

                                                    <div className="col-lg-2 col-md-2">
                                                        <FormFieldTitle name="Office Address *" />
                                                    </div>

                                                    <div className="col-lg-10 col-md-10">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Building Number</Label>
                                                                <input type="text" placeholder="E.g. 78" name="motherOfficeHouseNumber" value={values.motherOfficeHouseNumber} onChange={handleChange} className={`form-control ${errors.motherOfficeHouseNumber ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.motherOfficeHouseNumber}</div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Street</Label>
                                                                <input type="text" placeholder="E.g. Walkar road" name="motherOfficeStreet" value={values.motherOfficeStreet} onChange={handleChange} className={`form-control ${errors.motherOfficeStreet ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.motherOfficeStreet}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-2 col-md-2">
                                                    </div>

                                                    <div className="col-lg-10 col-md-10 mt-3">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Land Mark</Label>
                                                                <input type="text" placeholder="E.g. Near india gate" name="motherOfficeLandmark" value={values.motherOfficeLandmark} onChange={handleChange} className={`form-control ${errors.motherOfficeLandmark ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.motherOfficeLandmark}</div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Area</Label>
                                                                <input type="text" placeholder="E.g. Juhu" name="motherOfficeArea" value={values.motherOfficeArea} onChange={handleChange} className={`form-control ${errors.motherOfficeArea ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.motherOfficeArea}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-2 col-md-2">
                                                    </div>

                                                    <div className="col-lg-10 col-md-10 mt-3">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">State</Label>
                                                                <select name="motherOfficeState" onChange={(event) => { handleChange(event); selectState(event.target.value); }} value={values.motherOfficeState} className={`form-select ${errors.motherOfficeState ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                                    <option value="">Select</option>
                                                                    {StateAndDistrictList?.map((stateList, index) => {
                                                                        const { state } = stateList;
                                                                        return (
                                                                            <option key={index}>{state}</option>
                                                                        );
                                                                    })}
                                                                </select>
                                                                <div className="invalid-feedback">{errors.motherOfficeState}</div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">District</Label>
                                                                <select name="motherOfficeDistrict" onChange={handleChange} value={values.motherOfficeDistrict} className={`form-select ${errors.motherOfficeDistrict ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                                    <option selected value=" ">Select</option>
                                                                    {districtList.map((districtName, index) => {
                                                                        return (

                                                                            <option key={index} >{districtName}</option>

                                                                        );

                                                                    })}

                                                                </select>
                                                                <div className="invalid-feedback">{errors.motherOfficeDistrict}</div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-2">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Pin Code</Label>
                                                                <input type="number" placeholder="E.g. 442510" name="motherOfficePincode" value={values.motherOfficePincode} onChange={handleChange} className={`form-control ${errors.motherOfficePincode ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.motherOfficePincode}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                {/* Mother Office Number Details Field */}
                                                <div className="row mt-3">

                                                    <div className="col-lg-2 col-md-2">
                                                        <FormFieldTitle name="Office Number *" />
                                                    </div>

                                                    <div className="col-lg-10 col-md-10">
                                                        <div className="row">

                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-md-4 col-4">
                                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="extension">Code</Label>
                                                                        <div className="code_extension">
                                                                            <span>+</span>
                                                                            <input type="number" name="motherOfficeExtension" value={values.motherOfficeExtension} onChange={handleChange} className={`form-control ${errors.motherOfficeExtension ? "is-invalid" : ""}`} disabled />
                                                                            <div className="invalid-feedback">{errors.motherOfficeExtension}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-8">
                                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Mobile Number</Label>
                                                                        <input type="number" placeholder="E.g. 7896523140" name="motherOfficeNumber" value={values.motherOfficeNumber} onChange={handleChange} className={`form-control ${errors.motherOfficeNumber ? "is-invalid" : ""}`} disabled={disabled} />
                                                                        <div className="invalid-feedback">{errors.motherOfficeNumber}</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6 mt-lg-0 mt-md-0 mt-sm-2">
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-md-4 col-4">
                                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="extension">Code</Label>
                                                                        <div className="code_extension">
                                                                            <span>+</span>
                                                                            <div>
                                                                                <input type="number" name="motherOfficeAltExtension" value={values.motherOfficeAltExtension} placeholder="E.g. +91/0432" onChange={handleChange} className={`form-control ${errors.motherOfficeAltExtension ? "is-invalid" : ""}`} disabled={disabled} />
                                                                                <div className="invalid-feedback">{errors.motherOfficeAltExtension}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-8">
                                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Alternate Mobile Number</Label>
                                                                        <input type="number" placeholder="E.g. 7896523140" name="motherOfficeAltNumber" value={values.motherOfficeAltNumber} onChange={handleChange} className={`form-control ${errors.motherOfficeAltNumber ? "is-invalid" : ""}`} disabled={disabled} />
                                                                        <div className="invalid-feedback">{errors.motherOfficeAltNumber}</div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>

                                                </div>

                                                {/* Mother Office Email Address Details Field */}
                                                <div className="row mt-3">

                                                    <div className="col-lg-2 col-md-2">
                                                        <FormFieldTitle name="Office Email Address *" />
                                                    </div>

                                                    <div className="col-lg-10 col-md-10">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Primary Email</Label>
                                                                <input type="text" placeholder="E.g. xyz@gmail.com" name="motherOfficeEmail" value={values.motherOfficeEmail} onChange={handleChange} className={`form-control ${errors.motherOfficeEmail ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.motherOfficeEmail}</div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Alternate Email</Label>
                                                                <input type="text" placeholder="E.g. abc@gmail.com" name="motherOfficeAlternateEmail" value={values.motherOfficeAlternateEmail} onChange={handleChange} className={`form-control ${errors.motherOfficeAlternateEmail ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.motherOfficeAlternateEmail}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                        }



                                        {/* Parents Address Details Field */}
                                        <div className="row mt-3">

                                            <div className="col-lg-2 col-md-2">
                                                <FormFieldTitle name="Parents Complete Address *" />
                                            </div>

                                            <div className="col-lg-10 col-md-10">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">House Number</Label>
                                                        <input type="text" placeholder="E.g. 22" name="houseNumber" value={values.houseNumber} onChange={handleChange} className={`form-control ${errors.houseNumber ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                        <div className="invalid-feedback">{errors.houseNumber}</div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Street</Label>
                                                        <input type="text" placeholder="E.g. Shahid Bhagat Singh Marg" name="street" value={values.street} onChange={handleChange} className={`form-control ${errors.street ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                        <div className="invalid-feedback">{errors.street}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-2 col-md-2">
                                            </div>

                                            <div className="col-lg-10 col-md-10 mt-3">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Land Mark</Label>
                                                        <input type="text" placeholder="E.g. Hanuman Temple" name="landmark" onChange={handleChange} value={values.landmark} className={`form-control ${errors.landmark ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                        <div className="invalid-feedback">{errors.landmark}</div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Area</Label>
                                                        <input type="text" placeholder="E.g. Juhu" name="area" onChange={handleChange} value={values.area} className={`form-control ${errors.area ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.area}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-2 col-md-2">
                                            </div>

                                            <div className="col-lg-10 col-md-10 mt-3">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">State</Label>
                                                        <select name="state" onChange={(event) => { handleChange(event); selectState(event.target.value); }} value={values.state} className={`form-select ${errors.state ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                            <option value="">Select</option>
                                                            {StateAndDistrictList?.map((stateList, index) => {
                                                                const { state } = stateList;
                                                                return (
                                                                    <option key={index}>{state}</option>
                                                                );
                                                            })}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.state}</div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">District</Label>
                                                        <select name="district" onChange={handleChange} value={values.district} className={`form-select ${errors.district ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                            <option value=" ">Select</option>
                                                            {districtList.map((districtName, index) => {
                                                                return (

                                                                    <option key={index}>{districtName}</option>

                                                                );

                                                            })}

                                                        </select>
                                                        <div className="invalid-feedback">{errors.district}</div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Pin Code</Label>
                                                        <input type="number" placeholder="E.g. 441201" name="pincode" onChange={handleChange} value={values.pincode} className={`form-control ${errors.pincode ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                        <div className="invalid-feedback">{errors.pincode}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        {/* Parent Contact Number Details Field */}
                                        <div className="row mt-3">

                                            <div className="col-lg-2 col-md-2">
                                                <FormFieldTitle name="Contact Number *" />
                                            </div>

                                            <div className="col-lg-10 col-md-10">
                                                <div className="row">

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-4">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="extension">Code</Label>
                                                                <div className="code_extension">
                                                                    <span>+</span>
                                                                    <div>
                                                                        <input type="number" placeholder="E.g. 91" name="extension" value={values.extension} onChange={handleChange} className={`form-control ${errors.extension ? "is-invalid" : ""}`} disabled />
                                                                        <div className="invalid-feedback">{errors.extension}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-8">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Mobile Number</Label>
                                                                <input type="number" placeholder="E.g. 7845963210" name="number" value={values.number} onChange={handleChange} className={`form-control ${errors.number ? "is-invalid" : ""}`} disabled={disabled} />
                                                                <div className="invalid-feedback">{errors.number}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 mt-lg-0 mt-md-0 mt-sm-2">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-4">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="extension">Code</Label>
                                                                <div className="code_extension">
                                                                    <span>+</span>
                                                                    <div>
                                                                        <input type="number" placeholder="E.g. +91/0432" name="altExtension" value={values.altExtension} onChange={handleChange} className={`form-control ${errors.altExtension ? "is-invalid" : ""}`} disabled={disabled} />
                                                                        <div className="invalid-feedback">{errors.altExtension}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-8">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Alternate Mobile Number</Label>
                                                                <input type="number" placeholder="E.g. 7845963210" name="altNumber" value={values.altNumber} onChange={handleChange} className={`form-control ${errors.altNumber ? "is-invalid" : ""}`} disabled={disabled} />
                                                                <div className="invalid-feedback">{errors.altNumber}</div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                        </div>

                                        {/* Parent Email Address Details Field */}
                                        <div className="row mt-3">

                                            <div className="col-lg-2 col-md-2">
                                                <FormFieldTitle name="Email Address *" />
                                            </div>

                                            <div className="col-lg-10 col-md-10">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Primary Email</Label>
                                                        <input type="text" placeholder="E.g. xyz@gmail.com" name="email" value={values.email} onChange={handleChange} className={`form-control ${errors.email ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                        <div className="invalid-feedback">{errors.email}</div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Alternate Email</Label>
                                                        <input type="text" placeholder="E.g. abc@gmail.com" name="alternateEmail" value={values.alternateEmail} onChange={handleChange} className={`form-control ${errors.alternateEmail ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                        <div className="invalid-feedback">{errors.alternateEmail}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        {/*--Family annual income details--*/}
                                        <div className="row mt-4">
                                            <div className="col-lg-2 col-md-2">
                                                <FormFieldTitle name="Income of all the members of the family *" />
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Total Annual Income</Label>
                                                <input type="number" placeholder="E.g. 52314" name="totalAnnualIncome" onChange={handleChange} value={values.totalAnnualIncome} className={`form-control ${errors.totalAnnualIncome ? "is-invalid" : ""}`} disabled={disabled} />
                                                <div className="invalid-feedback">{errors.totalAnnualIncome}</div>
                                            </div>
                                        </div>
                                        {/*-- Annual income certificate details--*/}
                                        <div className="row mt-3">
                                            <div className="col-lg-2 col-md-2">
                                                <FormFieldTitle name="Annual Income Certificate *" />
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-12">
                                                <div className="row">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Annual Income Certificate Attached (Issued By Taluka Majistret)</Label>

                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                        <input type="radio" onChange={() => setFieldValue("isAnnualIncomeCertificateAttached", true)} checked={values.isAnnualIncomeCertificateAttached === null ? (values.isAnnualIncomeCertificateAttached) : Boolean(values.isAnnualIncomeCertificateAttached)} value="true" name="annualIncomeCertificateAttachedRadio" id="annualIncomeTrue" className={`form-check-input mx-3 ${errors.isAnnualIncomeCertificateAttached ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.isAnnualIncomeCertificateAttached}</div>
                                                    </div>

                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                        <input type="radio" onChange={() => setFieldValue("isAnnualIncomeCertificateAttached", false)} checked={values.isAnnualIncomeCertificateAttached === null ? (values.isAnnualIncomeCertificateAttached) : Boolean(!values.isAnnualIncomeCertificateAttached)} value="false" name="annualIncomeCertificateAttachedRadio" id="annualIncomeFalse" className={`form-check-input mx-3 ${errors.isAnnualIncomeCertificateAttached ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.isAnnualIncomeCertificateAttached}</div>
                                                    </div>

                                                </div>
                                            </div>
                                            {values.isAnnualIncomeCertificateAttached === true ?
                                                <>
                                                    <div className="col-lg-4 col-md-4 col-8" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <div>
                                                            <input type="file" name="incomeCertificate" onChange={(e) => { handleChange(e); onFileSelect(e, "INCOME") }} value={values.incomeCertificateUrl?.filename} className={`form-control ${errors.incomeCertificateUrl ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.incomeCertificateUrl}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <button type="button" onClick={() => { previewDocumentsHandler(values.incomeCertificateUrl) }}>Preview</button>
                                                    </div>
                                                    {isSubmitted ?
                                                        <span></span>
                                                        :
                                                        <div style={{ textAlign: "end" }}>
                                                            <span style={{ fontSize: "11px", color: "rgb(109, 141, 173)", marginRight: "11%" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                        </div>
                                                    }
                                                </>
                                                : <span></span>
                                            }
                                        </div>

                                        {/*-- ITR return details--*/}
                                        <div className="row mt-3">
                                            <div className="col-lg-2 col-md-2">
                                                <FormFieldTitle name="ITR Return *" />
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-12">
                                                <div className="row">

                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">ITR return Attached</Label>
                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                        <input type="radio" onChange={() => setFieldValue("isItrReturnAttached", true)} checked={values.isItrReturnAttached === null ? (values.isItrReturnAttached) : Boolean(values.isItrReturnAttached)} value="true" name="isItrReturnAttachedRadio" id="isItrReturnAttachedTrue" className={`form-check-input mx-3 ${errors.isItrReturnAttached ? "is-invalid" : ""}`} disabled={disabled} />
                                                    </div>

                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                        <input type="radio" onChange={() => setFieldValue("isItrReturnAttached", false)} checked={values.isItrReturnAttached === null ? (values.isItrReturnAttached) : Boolean(!values.isItrReturnAttached)} value="false" name="isItrReturnAttachedRadio" id="annualIncomeFalse" className={`form-check-input mx-3 ${errors.isItrReturnAttached ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.isItrReturnAttached}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {values.isItrReturnAttached === true ?
                                                <>
                                                    <div className="col-lg-4 col-md-4 col-8" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <div>
                                                            <input type="file" name="itrCertificate" onChange={(event) => { handleChange(event); onFileSelect(event, "ITR") }} value={values.itrCertificateUrl?.filename} className={`form-control ${errors.itrCertificateUrl ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.itrCertificateUrl}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <button type="button" onClick={() => { previewDocumentsHandler(values.itrCertificateUrl) }}>Preview</button>
                                                    </div>
                                                    {isSubmitted ?
                                                        <span></span>
                                                        :
                                                        <div style={{ textAlign: "end" }}>
                                                            <span style={{ fontSize: "11px", color: "rgb(109, 141, 173)", marginRight: "11%" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                        </div>
                                                    }
                                                </>
                                                : <span></span>
                                            }
                                        </div>



                                        {/*-- Father is alive or not details--*/}
                                        <div className="row mt-3">
                                            <div className="col-lg-2 col-md-2">
                                                <FormFieldTitle name="Your Father is alive? *" />
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-12">
                                                <div className="row">

                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Alive or Not</Label>
                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                        <input type="radio" onChange={() => setFieldValue("isFatherAlive", true)} checked={values.isFatherAlive === null ? (values.isFatherAlive) : Boolean(values.isFatherAlive)} value="true" name="isFatherAliveRadio" id="isFatherAliveTrue" className={`form-check-input mx-3 ${errors.isFatherAlive ? "is-invalid" : ""}`} disabled={disabled} />
                                                    </div>

                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                        <input type="radio" onChange={() => setFieldValue("isFatherAlive", false)} checked={values.isFatherAlive === null ? (values.isFatherAlive) : Boolean(!values.isFatherAlive)} value="false" name="isFatherAliveRadio" id="annualIncomeFalse" className={`form-check-input mx-3 ${errors.isFatherAlive ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.isFatherAlive}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {values.isFatherAlive === false ?
                                                <>
                                                    <div className="col-lg-4 col-md-4 col-8" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <div>
                                                            <input type="file" name="deathCertificate" onChange={(event) => { handleChange(event); onFileSelect(event, "DEATH_CERTIFICATE") }} value={values.deathCertificateUrl?.filename} className={`form-control ${errors.deathCertificateUrl ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.deathCertificateUrl}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <button type="button" onClick={() => { previewDocumentsHandler(values.deathCertificateUrl) }}>Preview</button>
                                                    </div>
                                                    {isSubmitted ?
                                                        <span></span>
                                                        :
                                                        <div style={{ textAlign: "end" }}>
                                                            <span style={{ fontSize: "11px", color: "rgb(109, 141, 173)", marginRight: "11%" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                        </div>
                                                    }
                                                </>
                                                : <span></span>
                                            }
                                        </div>


                                        {/*-- Father is alive or not details--*/}
                                        {/* <div className="row mt-3">
                                            <div className="col-lg-2 col-md-2">
                                                <FormFieldTitle name="Your Father is alive? *" />
                                            </div>
                                            <div className=" col-lg-5 col-md-5 col-8">
                                                <div className="row">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Alive or Not</Label>
                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                        <input type="radio" onChange={() => setFieldValue("isFatherAlive", true)} checked={values.isFatherAlive === null ? (values.isFatherAlive) : Boolean(values.isFatherAlive)} value="true" name="isFatherAliveRadio" id="isFatherAliveTrue" className={`form-check-input mx-3 ${errors.isFatherAlive ? "is-invalid" : ""}`} disabled={disabled} />
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                        <input type="radio" onChange={() => setFieldValue("isFatherAlive", false)} checked={values.isFatherAlive === null ? (values.isFatherAlive) : Boolean(!values.isFatherAlive)} value="false" name="isFatherAliveRadio" id="annualIncomeFalse" className={`form-check-input mx-3 ${errors.isFatherAlive ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.isFatherAlive}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {values.isFatherAlive === false ?
                                                <>
                                                    <div className="col-lg-4 col-md-4 col-8" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <div>
                                                            <input type="file" name="deathCertificate" onChange={(event) => { handleChange(event); onFileSelect(event, "DEATH_CERTIFICATE") }} value={values.deathCertificateUrl?.filename} className={`form-control ${errors.deathCertificate ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.deathCertificateUrl}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <button type="button" onClick={() => { previewDocumentsHandler(values.deathCertificateUrl) }}>Preview</button>
                                                    </div>
                                                    {isSubmitted ?
                                                        <span></span>
                                                        :
                                                        <div style={{ textAlign: "end" }}>
                                                            <span style={{ fontSize: "11px", color: "rgb(109, 141, 173)", marginRight: "11%" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                        </div>
                                                    }
                                                </>
                                                : null

                                            }

                                        </div> */}

                                        {/*-- Marital status details--*/}
                                        <div className="row mt-3">
                                            <div className="col-lg-2 col-md-2">
                                                <FormFieldTitle name="Mother - Father Marital Status*" />
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-8">
                                                <div className="row">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Divorced</Label>
                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                        <input onChange={() => setFieldValue("areParentsDivorced", true)} checked={values.areParentsDivorced === null ? (values.areParentsDivorced) : Boolean(values.areParentsDivorced)} type="radio" value="true" name="areParentsDivorcedRadio" id="areParentsDivorcedTrue" className={`form-check-input mx-3 ${errors.areParentsDivorced ? "is-invalid" : ""}`} disabled={disabled} />
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                        <input onChange={() => setFieldValue("areParentsDivorced", false)} checked={values.areParentsDivorced === null ? (values.areParentsDivorced) : Boolean(!values.areParentsDivorced)} type="radio" value="false" name="areParentsDivorcedRadio" id="areParentsDivorcedFalse" className={`form-check-input mx-3 ${errors.areParentsDivorced ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.areParentsDivorced}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {values.areParentsDivorced ?
                                                <>
                                                    <div className="col-lg-4 col-md-4 col-8" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <div>
                                                            <input type="file" name="divorceCertificate" onChange={(event) => { handleChange(event); onFileSelect(event, "DIVORCE_CERTIFICATE") }} value={values.divorceCertificateUrl?.filename} className={`form-control ${errors.divorceCertificateUrl ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.divorceCertificateUrl}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                                        <button type="button" onClick={() => { previewDocumentsHandler(values.divorceCertificateUrl) }}>Preview</button>
                                                    </div>
                                                    {isSubmitted ?
                                                        <span></span>
                                                        :
                                                        <div style={{ textAlign: "end" }}>
                                                            <span style={{ fontSize: "11px", color: "rgb(109, 141, 173)", marginRight: "11%" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                        </div>
                                                    }
                                                </>
                                                : null
                                            }
                                        </div>



                                        {/* Family Details  */}
                                        <div className="row">
                                            <div className="col-10">
                                                <FormFieldTitle name="Family Details *" />
                                            </div>
                                        </div>

                                        {isSubmitted ?
                                            <div className="mt-5"></div>
                                            : <FamilyMembersDetailsForm
                                                setAvailScholorshipProgram={setAvailScholorshipProgram}
                                                formData={(formDataValues) => {
                                                    setFamilyMembersDetails(value => { return [...value, { ...formDataValues }] });
                                                }
                                                } />
                                        }

                                        <div className="container family_member_details_table">

                                            {familyMembersDetails.map((familyMemberDetail, index) => {
                                                return (
                                                    <div className="row mt-3" key={index}>

                                                        <div className="col-lg-1 col-md-1  col-3">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Sr.No</Label>
                                                            <input name="" type="text" value={index + 1} className="form-control text-center" disabled />
                                                        </div>

                                                        <div className="col-lg-2  col-md-2 col-9">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Full Name</Label>
                                                            <input name="fullName" type="text" value={familyMemberDetail.fullName} className="form-control" disabled />
                                                        </div>

                                                        <div className="col-lg-1 col-md-1 col-3 mt-md-0 mt-lg-0 mt-2">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Age</Label>
                                                            <input name="age" type="number" value={familyMemberDetail.age} className="form-control" disabled />
                                                        </div>

                                                        <div className="col-lg-2  col-md-2 col-9 mt-md-0 mt-lg-0 mt-2">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Relation with Student</Label>
                                                            <input name="relationWithStudent" value={familyMemberDetail.relationWithStudent} type="text" className="form-control" disabled />
                                                        </div>

                                                        <div className="col-lg-2 col-md-2 col-7 mt-md-2 mt-lg-0 mt-2">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Occupation</Label>
                                                            <input name="business" value={familyMemberDetail.business} type="text" className="form-control" disabled />
                                                        </div>

                                                        <div className="col-lg-2  col-md-2 col-5 mt-md-2 mt-lg-0 mt-2">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Total Income</Label>
                                                            <input name="totalAnnualIncome" value={familyMemberDetail.totalAnnualIncome} type="number" className="form-control" disabled />
                                                        </div>

                                                        <div className="col-lg-2 col-md-2 col-6 mt-md-2 mt-lg-0 mt-2">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Central Scholarship</Label>
                                                            <input type="text" value={familyMemberDetail.hasAvailedScholarshipProgram ? "Yes" : "No"} className="form-control text-center" disabled />
                                                        </div>

                                                        {isSubmitted ? <span></span> :
                                                            <div className="col-lg-1 col-md-1 col-6 mt-sm-2 mt-lg-0 mt-2 trash_btn">
                                                                <button onClick={() => deleteFamilyMemberDetails(familyMemberDetail)}><i className="fa fa-trash-o" aria-hidden="true"></i></button>
                                                            </div>
                                                        }
                                                    </div>
                                                );
                                            })}

                                            <div className="row mt-3">
                                                <div className="col-lg-8 col-md-0"></div>
                                                <div className="col-lg-2 col-md-4 col-6">
                                                    <H4 whiteThemeColor="#0d2750" darkThemeColor="#FFFFFF">Total Annual Family Income</H4>
                                                </div>
                                                <div className="col-lg-2 col-md-3 col-6">
                                                    <input type="number" value={totalAnnualFamilyIncome} onChange={(event) => { setTotalAnnualFamilyIncome(event.target.value) }} onInput={(e) => maxLengthCheck(e)} className="form-control" disabled />
                                                </div>
                                            </div>
                                        </div>

                                        {availScholorshipProgram === false ?
                                            <div className="mt-5"></div>
                                            : <FamilyMembersAvailedScholarshipDetails
                                                familyMemberAvailedSccholarshipFormData={(familyMemberAvailedSccholarshipFormDataValues) => {
                                                    setFamilyMembersAvailedScholarshipDetails(value => { return [...value, { ...familyMemberAvailedSccholarshipFormDataValues }] });
                                                }
                                                } />
                                        }

                                        <div className="container family_member_availed_scholarship_details_table">
                                            <H4 whiteThemeColor="#0d2750" darkThemeColor="#FFFFFF">Details of the family members who availed Abroad Scholarship except applicant:</H4>
                                            {familyMembersAvailedScholarshipDetails.map((familyMembersAvailedScholarshipDetails, index) => {
                                                return (
                                                    <>
                                                        <div className="row mt-3" key={index}>

                                                            <div className="col-lg-1 col-md-1 col-3 mt-lg-0 mt-2">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Sr. No.</Label>
                                                                <input name="" type="text" value={index + 1} className="form-control text-center" disabled />
                                                            </div>

                                                            <div className="col-lg-2 col-md-3 col-9 mt-lg-0 mt-2">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">College Name</Label>
                                                                <input name="courseYear" type="number" value={familyMembersAvailedScholarshipDetails.courseYear} className="form-control" disabled />
                                                            </div>

                                                            <div className="col-lg-4 col-md-4 col-12 mt-lg-0 mt-2">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">College Name</Label>
                                                                <input name="collegeName" type="text" value={familyMembersAvailedScholarshipDetails.collegeName} className="form-control" disabled />
                                                            </div>

                                                            <div className="col-lg-2 col-md-4 col-12 mt-lg-0 mt-2">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">City</Label>
                                                                <input name="country" value={familyMembersAvailedScholarshipDetails.city} type="text" className="form-control" disabled />
                                                            </div>

                                                            <div className="col-lg-2 col-md-4 col-12 mt-md-2 mt-lg-0 mt-2">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="mb-3">Scholarship Program</Label>
                                                                <textarea name="scholarshipProvider" value={familyMembersAvailedScholarshipDetails.scholarshipProvider} type="text" className="form-control" disabled > </textarea>
                                                            </div>
                                                            {isSubmitted ? <span></span> :
                                                                <div className="col-lg-1 col-md-1 col-12 mt-sm-2 mt-lg-0 mt-2 trash_btn">
                                                                    <button onClick={() => deleteFamilyMembersAvailedScholarshipDetails(familyMembersAvailedScholarshipDetails)}><i className="fa fa-trash-o" aria-hidden="true"></i></button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </>
                                                );
                                            })}

                                        </div>


                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form_button">
                                                    {splitUrlArray[4] === "user" ?
                                                        <button type="button" onClick={() => { history.push("/user"); dispatch({ type: "SHOW_APPLICATION_PROCESS_NAV" }); }} className="btn form_cancel_btn" disabled={disableActions}>Cancel</button>
                                                        : <span></span>
                                                    }
                                                    {
                                                        isSubmitted ?
                                                            <div className="m-1"></div>
                                                            :
                                                            <button type="button" onClick={() => {
                                                                setSaveType(value => value = "SAVE");
                                                                handleSubmit();
                                                            }} className="btn form_save-btn" disabled={disableActions}>Save</button>
                                                    }
                                                    {
                                                        isSubmitted ?
                                                            (splitUrlArray[4] === "user") ?
                                                                <button type="button" onClick={() => {
                                                                    loadNextForm();
                                                                }} className="btn form_submit_btn">Next</button>
                                                                : <span></span>
                                                            :
                                                            <button type="button" onClick={() => {
                                                                setSaveType(value => value = "SAVE_AND_NEXT");
                                                                handleSubmit();
                                                            }} className="btn form_submit_btn" disabled={disableActions}>Save & Next</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </section>
    );
}
export default ParentDetails;