import React, { useState, useEffect, memo } from 'react'
import P from '../../text-components/P'
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoadingForms from "../../shared-components/LoadingForms";
import { backOfficeCommunication } from '../../communications/back-office-communication';
import { removeUnderscoreFromText } from '../../utlis/helper';

const StudentMarksheetDetail = () => {
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [semesterMarks, setSemesterMarks] = useState([]);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    //Get user id and Semester id from query params
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');
    const semesterId = queryParams.get('semesterId');

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }


    useEffect(() => {
        //Fetch user Semester List by userId
        const fetchUserSemesterList = async () => {
            try {
                showLoading("Loading...");
                const responseFromServer = await backOfficeCommunication.getUserSemesterList(userId);
                if (responseFromServer?.status === "SUCCESS") {
                    //Filter data for particular semester
                    responseFromServer?.userSemMarks?.forEach((elem) => {
                        if (semesterId === elem?._id?.toString()) {
                            setSemesterMarks([elem]);
                        }
                    })
                } else {
                    showModal(responseFromServer?.message);
                }
                hideLoading();
            } catch (error) {
                showModal(error?.message);
                hideLoading();
            }
        }
        fetchUserSemesterList();
    }, []);


    function showDocumentScrutinyUploadedDocumentPreview(applicationId, milestoneId, type, fileUrl) {
        history.push(`/back-office/dashboard/document-scrutiny/milestone-list/uploaded-documents/preview?milestoneId=${milestoneId}&applicationId=${applicationId}&fileUrl=${fileUrl}&type=${type}`);
        return;
    }


    return (
        <section className="dashboard_background">
            {isLoading?.value ?
                <LoadingForms message={isLoading?.message} />
                :
                <div className="container">
                    <div className="student_marks_detail">
                        <div className="back_office_preview_title">
                            <P><b>Student Marks Detail</b> </P>
                        </div>

                        <div className="back_office_document_preview">
                            <>
                                {semesterMarks?.map((semesterMark, index) => (
                                    <div className="form" key={index}>
                                        <div className="row">
                                            <div className="col-4">
                                                <input type="text" value={removeUnderscoreFromText(semesterMark?.semester)} className="form-control" />
                                            </div>
                                            <div className="col-4">
                                                <input type="text" value={semesterMark?.typeOfMark} className="form-control" />
                                            </div>
                                            <div className="col-4" style={{ display: "grid", placeItems: "center" }}>
                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a onClick={() => showDocumentScrutinyUploadedDocumentPreview(semesterMark?.applicationId, semesterMark?.milestoneId, semesterMark?.milestoneType, semesterMark?.marksheetReportUrl)} style={{ color: "blue", borderBottom: "1px solid blue", cursor: "pointer" }}>MarkSheet Report</a></P>
                                            </div>
                                        </div>
                                        <div className="row mt-3 mb-3" style={{ textAlign: "center" }}>
                                            <div className="col-4">
                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Subject Name</P>
                                            </div>
                                            <div className="col-4">
                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Total Marks</P>
                                            </div>
                                            <div className="col-4">
                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Obtained Marks</P>
                                            </div>
                                        </div>
                                        {semesterMark?.totalMarks?.map((semester, index) => (
                                            <div className="row mb-3" style={{ textAlign: "center" }} key={index}>
                                                <div className="col-4">
                                                    <input type="text" name="subjectName" value={semester?.subject} className="form-control" />
                                                </div>
                                                <div className="col-4">
                                                    <input type="text" name="subjectName" value={semester?.totalMarks} className="form-control" />
                                                </div>
                                                <div className="col-4">
                                                    <input type="text" name="subjectName" value={semester?.obtainedMarks} className="form-control" />
                                                </div>
                                            </div>
                                        ))}
                                        <div className="row mt-3">
                                            <div className="col-12" style={{ textAlign: "end" }}>
                                                <button onClick={() => history.goBack()}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        </div>
                    </div>
                </div>
            }
        </section>
    )
}

export default memo(StudentMarksheetDetail)