import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { communication } from "../communications/communication";
import { useHistory } from "react-router";
import H4 from "../text-components/H4";
import P from "../text-components/P";
import LoadingForms from "../shared-components/LoadingForms";
import { useFormik } from 'formik';
import * as Yup from "yup";
import MulipleDocumentUpload from '../shared-components/MulipleDocumentUpload';
import { removeUnderscoreFromText } from "../utlis/helper";
import UserMarksheetDetail from "../common/UserMarksheetDetail";
import ReactPaginate from "react-paginate";
// import Label from "../text-components/Label";

const DocSubmission = () => {
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const [showNewMilestoneModalBox, setShowNewMilestoneModalBox] = useState(false);
    const dispatch = useDispatch();
    const [streamType, setStreamType] = useState([]);
    const [checkStreamType, setCheckStreamType] = useState(true);
    const [showFeedback, setShowFeedback] = useState(false);
    const [expenditureReport, setExpenditureReport] = useState([]);
    const [applicationId, setApplicationId] = useState("");
    const [milestoneDetailsData, setMilestoneDetailsData] = useState([]);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const history = useHistory();
    const [disabled, setDisabled] = useState(false);
    const [otherDocumentsUrl, setOtherDocumentsUrl] = useState([]);
    const [triggerNewMilestone, setTriggerNewMilestone] = useState(false);
    const [nameValidation, setNameValidation] = useState(false);
    const [currentYearMilestone, setCurrentYearMilestone] = useState([]);
    const [showMarksheetDetailForm, setShowMarksheetDetailForm] = useState(false);
    const [hideSubjectTabel, setHideSubjectTable] = useState(true);
    const [semesterId, setSemesterId] = useState("");
    const [lastMilestone, setLastMilestone] = useState({});
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchMilestonesBySearch(searchValue, selectedPage + 1);
        } else {
            getUserMileStone(selectedPage + 1);
        }
    }

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchMilestonesBySearch(searchString, currentPage + 1);
        } else {
            const milestoneDetailsFromServer = await communication.getUserMilestones();
            if (milestoneDetailsFromServer.status === "SUCCESS") {
                setApplicationId(milestoneDetailsFromServer.applicationId);
                setMilestoneDetailsData(milestoneDetailsFromServer.milestoneDetails);
                setPageCount(milestoneDetailsFromServer?.totalPages);
            } else {
                setMilestoneDetailsData([]);
                setPageCount(0);
            }
        }
    }

    //get Remittance By Search
    const fetchMilestonesBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.getMilestonesBySearch(searchString, page);
            console.log(responseFromServer);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setMilestoneDetailsData(responseFromServer?.data?.milestoneDetails);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                showModal(responseFromServer?.data?.message);
                history.push("/login");
            } else {
                setMilestoneDetailsData([]);
                setPageCount(0);
            }
        } catch (error) {
            showModal(error?.message);
        }
    }

    //get User Milestones on Initial Load
    async function getUserMileStone(page) {
        showLoading("Loading...");
        let fullUrl = window.location.href;
        let splitDocSubmissionUrl = fullUrl.split('/');
        if (splitDocSubmissionUrl.includes("doc-submission")) {
            dispatch({ type: "SHOW_ACTIVE_APPLICATION_PROCESS_TAB", payload: "DOC_SUBMISSION" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_APPLICATION_PROCESS_TAB" });
        }

        const getCurrentYearMilestone = await communication.getCurrentYearMilestone();
        if (getCurrentYearMilestone.status === "SUCCESS") {

            setCurrentYearMilestone(getCurrentYearMilestone.data)
        }

        const milestoneDetailsFromServer = await communication.getUserMilestones(page);
        if (milestoneDetailsFromServer.status === "SUCCESS") {
            let milestonesDetailsArray = milestoneDetailsFromServer.milestoneDetails;
            setApplicationId(milestoneDetailsFromServer.applicationId);
            setMilestoneDetailsData(milestonesDetailsArray);

            // check if the milestone five is triggered or not 
            milestonesDetailsArray.map((milestone) => {
                if (["MILESTONE_FIVE", "SEM_FEES", "OTHERS", "NEW_SESSION"].includes(milestone.type) && milestone.userStatus === "APPROVED" && milestone.backOfficeStatus === "APPROVED") {
                    setTriggerNewMilestone(true);
                }
                if (milestone.type === "COURSE_COMPLETION" && milestone.userStatus === "APPROVED" && milestone.backOfficeStatus === "APPROVED") {
                    setTriggerNewMilestone(false);
                }
                return milestone;
            });

            // check the last SEM_FEES milestone and set milestone in setLastMilestone state
            for (let i = milestonesDetailsArray.length - 1; i >= 0; i--) {
                if (milestonesDetailsArray[i].type === "SEM_FEES") {
                    setLastMilestone(milestonesDetailsArray[i]);
                    break;
                }
            }

        } else {
            setMilestoneDetailsData([]);
        }
        hideLoading();
    }

    useEffect(() => {
        getUserMileStone();
    }, []);

    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const selectStream = (e) => {
        let stream = e.target.value;
        for (let i = 0; i < currentYearMilestone.length; i++) {

            if (stream === currentYearMilestone[i].name) {

                if (stream === "OTHERS") {
                    setStreamType([]);
                    setCheckStreamType(false);
                    setShowFeedback(true);
                    setNameValidation(false);
                }

                if (stream === "COURSE_COMPLETION") {
                    setStreamType([]);
                    setCheckStreamType(false);
                    setShowFeedback(true);
                    setNameValidation(false);
                }


                if (stream === "SEM_FEES") {
                    const filteredArray = currentYearMilestone.filter((ele) => ele.name === "SEM_FEES")[0];
                    setStreamType(filteredArray.type);
                    setCheckStreamType(true);
                    setShowFeedback(false);
                    setNameValidation(true);
                }

                if (stream === "NEW_SESSION") {
                    const filteredArray = currentYearMilestone.filter((ele) => ele.name === "NEW_SESSION")[0];
                    setStreamType(filteredArray.type);
                    setCheckStreamType(true);
                    setShowFeedback(false);
                    setNameValidation(true);
                }

            }
        }
        return true;
    }


    const showUserMilestone = (id, type, applicationId) => {
        if (type === "MILESTONE_ONE") {
            history.push(`/user/user-milestone-one?milestoneId=${id}&applicationId=${applicationId}`);
        }
        else if (type === "MILESTONE_TWO") {
            history.push(`/user/user-milestone-two?milestoneId=${id}&applicationId=${applicationId}`);
        }
        else if (type === "MILESTONE_THREE") {
            history.push(`/user/user-milestone-three?milestoneId=${id}&applicationId=${applicationId}`);
        }
        else if (type === "MILESTONE_FOUR") {
            history.push(`/user/user-milestone-four?milestoneId=${id}&applicationId=${applicationId}`);
        }
        else if (type === "MILESTONE_FIVE") {
            history.push(`/user/user-milestone-five?milestoneId=${id}&applicationId=${applicationId}`);
        }
        else if (type === "SEM_FEES") {
            history.push(`/user/user-sem-milestone?milestoneId=${id}&applicationId=${applicationId}`);
        }
        else if (type === "NEW_SESSION") {
            history.push(`/user/user-session-milestone?milestoneId=${id}&applicationId=${applicationId}`);
        }
        else if (type === "OTHERS") {
            history.push(`/user/user-other-milestone?milestoneId=${id}&applicationId=${applicationId}`);
        }
        else if (type === "COURSE_COMPLETION") {
            history.push(`/user/user-final-milestone?milestoneId=${id}&applicationId=${applicationId}`);
        }
        else {
            history.push(`/user/user-sem-milestone?milestoneId=${id}&applicationId=${applicationId}`);
        }

    }

    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        applicationId: applicationId,
        milestoneType: "",
        milestoneName: "",
        // expenditureReport: "",
        // documents: "",
    }

    //****Validation for Designation Create form*****/
    let yupValidationSchema = Yup.object().shape({
        applicationId: Yup.string().required("Application Id"),
        milestoneType: Yup.string().required("Select milestone type"),
        hostelFeesReport: Yup.mixed()
            .when("milestoneType", {
                is: "SEM_FEES",
                then: Yup.mixed().required("Please select file."),
                otherwise: Yup.mixed().nullable()
            }),
        marksheetReport: Yup.mixed()
            .when("milestoneType", {
                is: "SEM_FEES",
                then: Yup.mixed().required("Please select file."),
                otherwise: Yup.mixed().nullable()
            }),
        attendenceReport: Yup.mixed()
            .when("milestoneType", {
                is: "SEM_FEES",
                then: Yup.mixed().required("Please select file."),
                otherwise: Yup.mixed().nullable()
            }),
        foodExpenseReport: Yup.mixed()
            .when("milestoneType", {
                is: "SEM_FEES",
                then: Yup.mixed().required("Please select file"),
                otherwise: Yup.mixed().nullable()
            }),
        // milestoneName: Yup.string().when("milestoneType", {
        //     is: "SEM_FEES" || "NEW_SESSION",
        //     then: Yup.string().required("select milestone"),
        //     otherwise: Yup.string().notRequired()
        // }),
        // expenditureReport: Yup.mixed()
        //     .when("milestoneType", {
        //         is: "NEW_SESSION",
        //         then: Yup.mixed().required("Attachment is required").nullable(),
        //         otherwise: Yup.mixed().nullable()
        //     }),
        documents: Yup.mixed().nullable(),
    });


    function onFileSelect(event) {
        event.preventDefault();
        let fileName = event.target.files[0];
        setExpenditureReport(fileName);
        setFieldValue('expenditureReport', event.target.files[0]);
    }

    function onFileSelectForSem(event) {
        event.preventDefault();
        const elementName = event.target.name;
        const fileName = event.target.files[0];
        setFieldValue(elementName, fileName);
    }


    // Destructuring useFormik to get functions to control aspects of form
    // What does Formik do?
    // 1. Handling form events like submit, errors, change in value
    // 2. Fetching values from the form 
    // 3. Support for a validation library on the data fetched. We are using Yup library for validations. 
    // 4. Reset the form
    // 5. Pass initial values to the form
    // 6. Handle errors thrown by the Yup (or any validation library).
    // 7. Reinitialize form values
    // 8. Perform async operations
    const { handleSubmit, handleChange, values, errors, handleReset, setFieldValue } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit

        onSubmit: async (values) => {
            try {

                // Show loading 
                showLoading("Loading...");
                const formData = new FormData();
                if (values?.milestoneType === "NEW_SESSION") {
                    if (expenditureReport?.length === 0) {
                        showModal("Please Attached file");
                        hideLoading();
                        return;
                    } else {
                        formData.append("expenditureReport", expenditureReport, "expenditureReport");
                    }
                }

                if (values.milestoneType === "SEM_FEES" || values.milestoneType === "COURSE_COMPLETION") {
                    formData.append("hostelFeesReport", values.hostelFeesReport, "hostelFeesReport");
                    formData.append("marksheetReport", values.marksheetReport, "marksheetReport");
                    formData.append("attendenceReport", values.attendenceReport, "attendenceReport");
                    formData.append("foodExpenseReport", values.foodExpenseReport, "foodExpenseReport");
                }

                // add document name to files
                let newDocumentArray = [];

                for (let index = 0; index < otherDocumentsUrl.length; index++) {
                    const element = otherDocumentsUrl[index];
                    element.file.documentName = element['documentName'];
                    newDocumentArray.push(element.file);
                }

                //  convert all documents to form data
                newDocumentArray.forEach((ele) => {
                    formData.append('document', ele, ele.documentName);
                });


                // Send values to the server
                const data = {
                    applicationId: applicationId,
                    milestoneType: values.milestoneType,
                    milestoneName: "",
                    expenditureReport: "",
                    documents: "",
                    semesterId: semesterId
                }

                if (!!values.milestoneName) {
                    data.milestoneName = values.milestoneName;
                }

                if (!!values.expenditureReport) {
                    data.expenditureReport = values.expenditureReport;
                }

                if (!!values.documents) {
                    data.documents = values.documents;
                }

                // if (!!semesterId === false && values?.milestoneType !== "OTHERS") {
                //     showModal("Please upload semester marks");
                //     hideLoading();
                //     return;
                // }

                const milestoneCreationResponse = await communication.createGeneralMilestone(data, formData);
                // Handle response from the server
                if (milestoneCreationResponse.data.status === "SUCCESS" || milestoneCreationResponse.status === "SUCCESS") {
                    setOtherDocumentsUrl([]);
                    getUserMileStone();
                }

                setShowNewMilestoneModalBox(false)

                //  Show response message from server in modal dialog
                showModal(milestoneCreationResponse.data.message || milestoneCreationResponse.message);
                setOtherDocumentsUrl([]);
                handleReset();
                hideLoading();
                // Hide spinner 
                return;
            }
            catch (err) {
                showModal(err?.message);
                hideLoading();
            }
            return;
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: yupValidationSchema,
    });


    return (

        <section className="application_flow_big" >
            {isLoading.value ?
                <LoadingForms message={isLoading.message} />
                :
                <>
                    {
                        triggerNewMilestone ?
                            <div className="trigger_new_milestone">
                                <button onClick={() => setShowNewMilestoneModalBox(true)}>Trigger New Milestone</button>
                            </div>
                            :
                            <></>
                    }
                    <div className="docsubmission_main">
                        <div className="row search_wrapper">
                            <div className="col-lg-4 col-md-4 ps-0">
                                <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Milestone Name, Status..." />
                            </div>
                            <div className="col-lg-8 col-md-8 pe-0 pagination_outer_wrapper">
                                <div className="pagination_inner_wrapper">
                                    {pageCount > 1 &&
                                        <ReactPaginate
                                            previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            forcePage={currentPage}
                                            activeClassName={"active"} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="tabel_heading">
                            <div className="row m-0">
                                <div className="col-1 p-0 data">
                                    <span><H4>Serial No.</H4></span>
                                </div>
                                <div className="col-4 p-0 data">
                                    <span><H4>Milestone Name</H4></span>
                                </div>
                                <div className="col-2 p-0 data">
                                    <span><H4>Status</H4></span>
                                </div>
                                <div className="col-2 p-0 data">
                                    <span><H4>Date</H4></span>
                                </div>
                                <div className="col-3 p-0 align" style={{ justifyContent: "center" }}>
                                    <span><H4>Comment</H4></span>
                                </div>
                            </div>
                        </div>
                        {milestoneDetailsData?.length > 0 ?
                            <>
                                {
                                    milestoneDetailsData?.map((milestone, index) => {
                                        return (
                                            <div key={index}>
                                                {
                                                    <div className="tabel_data" key={index}>
                                                        <div className="row m-0">
                                                            <div className="col-1 p-0 data">
                                                                <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{index + 1}</P></span>
                                                            </div>

                                                            <div className="col-4 p-0  data" style={{ cursor: "pointer", overflow: "hidden" }}>
                                                                <span onClick={() => showUserMilestone(milestone._id, milestone.type, applicationId)}><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a>{removeUnderscoreFromText(milestone.name)}</a></P></span>

                                                            </div>
                                                            <div className="col-2 p-0 data">
                                                                <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{removeUnderscoreFromText(milestone.userStatus)}</P></span>
                                                            </div>
                                                            <div className="col-2 p-0 data">
                                                                <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                    {new Date(milestone.createdDate).getDate()} - {new Date(milestone.createdDate).getMonth() + 1} - {new Date(milestone.createdDate).getFullYear()}
                                                                </P></span>
                                                            </div>
                                                            <div className="col-3 p-0 align" style={{ justifyContent: "center" }}>
                                                                <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{
                                                                    milestone.comment ? <>
                                                                        {
                                                                            milestone?.comment?.length > 0 ?
                                                                                !!milestone?.comment[milestone?.comment.length - 1] ? milestone?.comment[milestone?.comment.length - 1] :
                                                                                    "No Comment"
                                                                                : "No Comment"
                                                                        }
                                                                    </>
                                                                        :
                                                                        <>
                                                                            No Comment
                                                                        </>
                                                                }</P></span>
                                                            </div>
                                                        </div>
                                                        {milestone?.userStatus === "NEW" || milestone?.userStatus === "HV_REQUESTED" ?
                                                            < div className="new_badge">
                                                                <p>New</p>
                                                            </div>
                                                            : <span></span>
                                                        }
                                                    </div>
                                                }

                                            </div>
                                        )

                                    })
                                }
                            </>
                            :
                            <div className="col-12 text-center view_download_dashboard">
                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Documents not available.</H4>
                            </div>
                        }
                    </div>
                    {/*-----trigger new milestone modal box---*/}
                    {showNewMilestoneModalBox ?
                        <div className="new_milestone_modal_box">
                            <div className="row">
                                <div className="col-4">
                                    <H4 style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Type of Milestone</H4>
                                </div>
                                <div className="col-8">
                                    <select name="milestoneType" value={values.milestoneType} onChange={(e) => { selectStream(e); handleChange(e) }} className={`form-select ${errors.milestoneType ? "is-invalid" : ""}`} translate="no">
                                        <option value=" ">Select</option>
                                        {currentYearMilestone?.map((typeOfMilestone, index) => {
                                            const { name } = typeOfMilestone;
                                            return (
                                                <option value={name} key={index}>{removeUnderscoreFromText(name)}</option>
                                            );
                                        })}
                                    </select>
                                    <div className="invalid-feedback">{errors?.milestoneType}</div>
                                </div>
                            </div>
                            {
                                (checkStreamType === true) ?
                                    <div className="row mt-4">
                                        <div className="col-4">
                                            <H4 style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Select Milestone</H4>
                                        </div>
                                        <div className="col-8">
                                            <select name="milestoneName" value={values.milestoneName} onChange={handleChange} className={`form-select ${errors.milestoneName ? "is-invalid" : ""}`} translate="no">
                                                <option value=" ">Select</option>
                                                {streamType?.map((type, index) => {
                                                    const { name, value } = type;
                                                    return (
                                                        <option value={value} key={index}>{name}</option>
                                                    );
                                                })}
                                            </select>
                                            <div className="invalid-feedback">{errors?.milestoneName}</div>
                                        </div>
                                    </div>
                                    :
                                    ""

                            }


                            {
                                ["OTHERS", "NEW_SESSION"].includes(values.milestoneType) &&
                                <>
                                    {
                                        ["OTHERS"].includes(values.milestoneType) ?

                                            ""
                                            :
                                            <div className="row mt-4">
                                                <div className="col-6">
                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Expenditure Document</H4>
                                                </div>
                                                <div className="col-6">
                                                    <input type="file" name="document" onChange={(e) => { onFileSelect(e); }} value={values.document?.filename} className={`form-control form-control-sm ${errors.document ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors?.document}</div>
                                                </div>
                                            </div>
                                    }
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <MulipleDocumentUpload
                                                setSelectedDocumentArray={setOtherDocumentsUrl}
                                                selectedDocumentArray={otherDocumentsUrl}
                                                title={"documents"}
                                                status={"NEW"}
                                                uploadedDocumentArray={milestoneDetailsData?.documentsArray}
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                            {
                                ["COURSE_COMPLETION"].includes(values.milestoneType) &&
                                <>
                                    <div className="row mt-4">
                                        <div className="col-6">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Marksheet Report</H4>
                                        </div>
                                        <div className="col-6">
                                            <input type="file" name="marksheetReport" onChange={(e) => { onFileSelectForSem(e); }} value={values.marksheetReport?.filename} className={`form-control form-control-sm ${errors.marksheetReport ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors?.marksheetReport}</div>
                                        </div>
                                    </div>
                                    {hideSubjectTabel &&
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-6"></div>
                                                <div className="col-6 upload_marks_button" style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                    {/* <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                    <a onClick={() => setShowMarksheetDetailForm(true)}>Upload Marks Details</a>
                                                                </P> */}
                                                    <button className="upload_marks me-2" onClick={() => setShowMarksheetDetailForm(true)}>Upload Semester Marks</button>
                                                    <P whiteThemeColor="red" darkThemeColor="#6d8dad">*Required</P>
                                                </div>
                                            </div>
                                            {showMarksheetDetailForm &&
                                                <div className="row mt-2 user_marksheet_main">
                                                    <div className="col-12">
                                                        <UserMarksheetDetail milestoneType={values.milestoneType} milestoneName={values.milestoneName} lastMilestone={milestoneDetailsData[milestoneDetailsData.length - 1]} setSemesterId={setSemesterId} setShowMarksheetDetailForm={setShowMarksheetDetailForm} setHideSubjectTable={setHideSubjectTable} applicationId={applicationId} />
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                    <div className="row mt-4">
                                        <div className="col-6">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Attendance Report</H4>
                                        </div>
                                        <div className="col-6">
                                            <input type="file" name="attendenceReport" onChange={(e) => { onFileSelectForSem(e); }} value={values.attendenceReport?.filename} className={`form-control form-control-sm ${errors.attendenceReport ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors?.attendenceReport}</div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-6">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Hostel Fees</H4>
                                        </div>
                                        <div className="col-6">
                                            <input type="file" name="hostelFeesReport" onChange={(e) => { onFileSelectForSem(e); }} value={values.hostelFeesReport?.filename} className={`form-control form-control-sm ${errors.hostelFeesReport ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors?.hostelFeesReport}</div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-6">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Food Expense</H4>
                                        </div>
                                        <div className="col-6">
                                            <input type="file" name="foodExpenseReport" onChange={(e) => { onFileSelectForSem(e); }} value={values.foodExpenseReport?.filename} className={`form-control form-control-sm ${errors.foodExpenseReport ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors?.foodExpenseReport}</div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <MulipleDocumentUpload
                                                setSelectedDocumentArray={setOtherDocumentsUrl}
                                                selectedDocumentArray={otherDocumentsUrl}
                                                title={"Extra Documents"}
                                                status={"NEW"}
                                                uploadedDocumentArray={milestoneDetailsData?.documentsArray}
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                            {
                                ["SEM_FEES"].includes(values.milestoneType) === true &&
                                <>
                                    <div className="row mt-4">
                                        <div className="col-6">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Marksheet Report</H4>
                                        </div>
                                        <div className="col-6">
                                            <input type="file" name="marksheetReport" onChange={(e) => { onFileSelectForSem(e); }} value={values.marksheetReport?.filename} className={`form-control form-control-sm ${errors.marksheetReport ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors?.marksheetReport}</div>
                                        </div>
                                    </div>
                                    {hideSubjectTabel &&
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-6"></div>
                                                <div className="col-6 upload_marks_button" style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                    {/* <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                    <a onClick={() => setShowMarksheetDetailForm(true)}>Upload Marks Details</a>
                                                                </P> */}
                                                    <button className="upload_marks me-2" onClick={() => setShowMarksheetDetailForm(true)}>Upload Semester Marks</button>
                                                    <P whiteThemeColor="red" darkThemeColor="#6d8dad">*Required</P>
                                                </div>
                                            </div>
                                            {showMarksheetDetailForm &&
                                                <div className="row mt-2 user_marksheet_main">
                                                    <div className="col-12">
                                                        <UserMarksheetDetail milestoneType={values.milestoneType} milestoneName={values.milestoneName} lastMilestone={milestoneDetailsData[milestoneDetailsData.length - 1]} setSemesterId={setSemesterId} setShowMarksheetDetailForm={setShowMarksheetDetailForm} setHideSubjectTable={setHideSubjectTable} applicationId={applicationId} />
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                    <div className="row mt-4">
                                        <div className="col-6">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Attendance Report</H4>
                                        </div>
                                        <div className="col-6">
                                            <input type="file" name="attendenceReport" onChange={(e) => { onFileSelectForSem(e); }} value={values.attendenceReport?.filename} className={`form-control form-control-sm ${errors.attendenceReport ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors?.attendenceReport}</div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-6">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Hostel Fees</H4>
                                        </div>
                                        <div className="col-6">
                                            <input type="file" name="hostelFeesReport" onChange={(e) => { onFileSelectForSem(e); }} value={values.hostelFeesReport?.filename} className={`form-control form-control-sm ${errors.hostelFeesReport ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors?.hostelFeesReport}</div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-6">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Food Expense</H4>
                                        </div>
                                        <div className="col-6">
                                            <input type="file" name="foodExpenseReport" onChange={(e) => { onFileSelectForSem(e); }} value={values.foodExpenseReport?.filename} className={`form-control form-control-sm ${errors.foodExpenseReport ? "is-invalid" : ""}`} disabled={disabled} />
                                            <div className="invalid-feedback">{errors?.foodExpenseReport}</div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <MulipleDocumentUpload
                                                setSelectedDocumentArray={setOtherDocumentsUrl}
                                                selectedDocumentArray={otherDocumentsUrl}
                                                title={"Extra Documents"}
                                                status={"NEW"}
                                                uploadedDocumentArray={milestoneDetailsData?.documentsArray}
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="create_milestone_btn">
                                <button type="button" onClick={() => { handleSubmit() }}>
                                    {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : "Create Milestone"}</button>
                                <button className="ms-4" onClick={() => setShowNewMilestoneModalBox(false)}>Cancel</button>
                            </div>

                            <span style={{ fontSize: "14px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>

                        </div>
                        : <span></span>
                    }
                </>
            }
        </section>

    );
}
export default DocSubmission;





