import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import H3 from "../text-components/H3";
import H5 from "../text-components/H5";
import LoadingForms from "../shared-components/LoadingForms";
import { frontOfficeCommunication } from "../communications/front-office-communication";



const ChangeQuota = () => {

    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    const [courses, setCourses] = useState([]);

    const history = useHistory();

    const [availableQuota, setAvailableQuota] = useState(0);
    const userAccessType = useSelector((state) => state.isOfficeUserAuthenticated?.officeUser?.access);


    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    const CloseChangeQuota = () => {
        history.push("/front-office/dashboard/merit-list");
    }

    async function initialLoad() {
        showLoading("Loading...");
        let quotaDetailsFromServer = await frontOfficeCommunication.getQuotaDetailsFromServer();
        if (quotaDetailsFromServer?.status === "SUCCESS") {
            let quotaArray = quotaDetailsFromServer.quota.courses;
            setCourses(quotaArray);
        }
        else {
            showModal(quotaDetailsFromServer?.message);
        }
        hideLoading();
    }

    useEffect(() => {
        initialLoad();
    }, []);

    // Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const increaseOccupied = (courseType, stream) => {
        let quotaData = [...courses];
        // check  course and courseType  into quotaDetails if it exist than increase the occupied
        for (let i = 0; i < quotaData.length; i++) {
            if (quotaData[i].courseType === courseType && quotaData[i].stream === stream && availableQuota > 0) {
                // we cannot increase quota count than previous quota count
                quotaData[i].quota++;
                setCourses(quotaData);
                // check setAvailableQuota not less than 0 
                if (availableQuota > 0) {
                    setAvailableQuota(availableQuota - 1);
                }
            }
        }
    }


    const decreaseOccupied = (courseType, stream) => {
        // check  course and courseType  into quotaDetails if it exist than decrease the occupied
        let quotaData = [...courses];
        for (let i = 0; i < quotaData.length; i++) {
            //check if occupied is not lesser then 0
            if (quotaData[i].stream === stream && quotaData[i].courseType === courseType) {
                if (quotaData[i].quota !== 0 || quotaData[i].occupied !== 0) {
                    if (quotaData[i].quota === quotaData[i].occupied) {
                        showModal("Occupied Quota is fulled ,you can't decrease quota for this Stream");
                        return;
                    }
                }

                // quota is can't be lesser then 0 quota
                if (quotaData[i].quota > 0) {
                    quotaData[i].quota--;
                    setCourses(quotaData);
                    setAvailableQuota(value => value + 1);
                }
            }
        }
    }
    // send waitList applications list to server
    const updateQuotaFromServer = async () => {
        if (availableQuota === 0) {
            console.log("updateQuotaFromServer", courses);
            showLoading("Loading...");
            const SubmitQuotaData = await frontOfficeCommunication.updateQuota(courses);
            if (SubmitQuotaData.status === "SUCCESS") {
                history.push(`/front-office/dashboard/merit-list`);
            }
            showModal(SubmitQuotaData.message);
        }
        else {
            showModal("Available Quota must be Zero.");
        }
        hideLoading();
        return;
    }




    return (
        <section className="back_office_preview_section">
            {isLoading.value ?
                <LoadingForms message={isLoading.message} />
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="back_office_preview_box">

                                <div className="quota_preview_title">
                                    <H3>Quota</H3>
                                </div>


                                <section className="dashboard_background">
                                    {isLoading.value ?
                                        <LoadingForms message={isLoading.message} />
                                        :

                                        <div className="dashbord_main" style={{ margin: "auto" }}>
                                            {/*-----Grievance card header section---*/}
                                            <div className="dashboard_view_data_heading" >
                                                <div className="row m-0">
                                                    <div className="col-1 p-0 view_dashboard">
                                                        <span><H5 >Serial No.</H5></span>
                                                    </div>
                                                    <div className="col-2 p-0 view_dashboard">
                                                        <span><H5 >Course</H5></span>
                                                    </div>
                                                    <div className="col-2 p-0 view_dashboard">
                                                        <span><H5 >Course Type</H5></span>
                                                    </div>
                                                    <div className="col-2 p-0 view_dashboard">
                                                        <span><H5 >Quota</H5></span>
                                                    </div>
                                                    <div className="col-2 p-0 view_dashboard">
                                                        <span><H5 >Occupied</H5></span>
                                                    </div>
                                                    <div className="col-3 p-0">
                                                        <span><H5 >Action</H5></span>
                                                    </div>
                                                </div>
                                            </div>

                                            {courses.map((quotaData, index) => {
                                                const { stream, courseType, quota, occupied } = quotaData;
                                                return (
                                                    <div key={index}>
                                                        <div className="dashboard_view_data_main" key={index}>
                                                            <div className="dashboard_view_data">
                                                                <div className="row m-0">
                                                                    <div className="col-1 p-0 view_dashboard">
                                                                        <span>
                                                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                                {index + 1}
                                                                            </H5>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-2 p-0 view_dashboard">
                                                                        <span>
                                                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                                {stream}
                                                                            </H5>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-2 p-0 view_dashboard">
                                                                        <span>
                                                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                                {courseType}
                                                                            </H5>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-2 p-0 view_dashboard">
                                                                        <span>
                                                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                                {quota}
                                                                            </H5>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-2 p-0 view_dashboard">
                                                                        <span>
                                                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                                {occupied}
                                                                            </H5>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-3 p-0 ">
                                                                        <span>
                                                                            <button className="ms-4 me-4" onClick={() => increaseOccupied(courseType, stream)} disabled={userAccessType === "READ" ? true : false} >+</button>
                                                                            <button className="ms-4 me-4" onClick={() => decreaseOccupied(courseType, stream)} disabled={userAccessType === "READ" ? true : false} >-</button>
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )

                                            })}
                                        </div>
                                    }
                                </section>
                                <section>
                                    <div className="toatal_qouta_main">
                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Available Quota</H5>
                                        <div className="total_qouta_box">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                {availableQuota}
                                            </H5>
                                        </div>
                                    </div>
                                </section>

                                <div className="close_quota_preview" onClick={() => CloseChangeQuota()}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div className="back_office_button">
                                <button className="ms-4 me-4" onClick={() => updateQuotaFromServer()} disabled={userAccessType === "READ" ? true : false} >Submit</button>
                                <button onClick={() => CloseChangeQuota()}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </section>
    );
}
export default ChangeQuota;