import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormik } from "formik";
import H3 from "../../text-components/H3";
import H5 from "../../text-components/H5";
import P from "../../text-components/P";
import { backOfficeCommunication } from "../../communications/back-office-communication";
import { communication } from "../../communications/communication"
import LoadingForms from "../../shared-components/LoadingForms";


const BackOfficeGrievancePreview = ({ changeHandler }) => {
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const lastElementOfChat = useRef();
    const [userTicket, setUserTicket] = useState([]);
    const [ticketId, setTicketId] = useState();
    const history = useHistory();
    const location = useLocation();


    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    function closebackOfficeGrievancePreview() {
        history.push("/back-office/dashboard/grievance");
    }

    const scrollToLastChild = () => {
        setTimeout(() => {
            lastElementOfChat.current.lastChild.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }, 500);
    }

    async function getTicketData() {
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("grievance")) {
            dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "GRIEVANCE" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
        }
        showLoading("Loading...");
        const queryParams = new URLSearchParams(location.search);
        const ticketId = queryParams.get('ticketId');
        setTicketId(ticketId);
        try {
            const response = await backOfficeCommunication.getUserTickets(ticketId);
            if (response.status === "SUCCESS") {
                setUserTicket(response.ticket);
                scrollToLastChild();
            }
            else if (response.status === "ERROR" || response.jwt === null) {
                showModal(response.message);
            }
            else {
                showModal(response.message);
            }

        } catch (e) {
            console.log(e);
        }
        hideLoading();
    }
    useEffect(() => {
        getTicketData();

    }, []);
    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        message: "",
    }

    //****Validation for Designation Create form*****/
    let yupValidationSchema = Yup.object().shape({
        message: Yup.string().required("Comment is required ")
    });

    // Destructuring useFormik to get functions to control aspects of form
    // What does Formik do?
    // 1. Handling form events like submit, errors, change in value
    // 2. Fetching values from the form 
    // 3. Support for a validation library on the data fetched. We are using Yup library for validations. 
    // 4. Reset the form
    // 5. Pass initial values to the form
    // 6. Handle errors thrown by the Yup (or any validation library).
    // 7. Reinitialize form values
    // 8. Perform async operations
    const { handleSubmit, handleChange, values, errors, handleReset } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit
        onSubmit: async (values) => {
            // Show loading 
            // showLoading("Loading...");
            const backOfficeMessageDataResponse = await backOfficeCommunication.replyUserTickets(ticketId, values.message);
            // Handle response from the server
            if (backOfficeMessageDataResponse.status === "SUCCESS") {
                handleReset();
                getTicketData();
                scrollToLastChild();
                changeHandler();
            } else {
                // Show response message from server in modal dialog
                showModal(backOfficeMessageDataResponse.message);
            }

            // Hide spinner 
            // hideLoading();
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: yupValidationSchema,
    });


    const markTicket = async (action) => {
        showLoading("Loading...");
        // Send values to the server
        let markTicket = {
            ticketId,
            action
        }
        const markTicketAction = await backOfficeCommunication.markTickets(markTicket);
        // Handle response from the server
        if (markTicketAction.status === "SUCCESS") {
            history.push('/back-office/dashboard/grievance');
            return;
        }

        else if (markTicketAction.status === "UNAUTHORIZED_ACCESS") {
            history.push('/back-office/dashboard/grievance');
            return;

        }

        // Show response message from server in modal dialog
        showModal(markTicketAction.message);

        // Hide spinner 
        hideLoading();
    }

    async function PreviewHandler(fileUrl) {
        await communication.getGrivanceFile(fileUrl);
    }


    return (
        <section className="back_office_preview_section">
            {isLoading.value ?
                <LoadingForms message={isLoading.message} />
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="back_office_preview_box">
                                <div className="back_office_preview_title">
                                    <H3 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Subject</H3>
                                    <div className="close_back_office_preview" onClick={() => closebackOfficeGrievancePreview()}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div className="back_office_preview">

                                    <div className="back_office_preview_comment_section" ref={lastElementOfChat}>
                                        {userTicket?.chat && userTicket?.chat?.map((userMessage, index) => {
                                            const { userId, message, date, createdBy, fileUrl, backOfficeUserName } = userMessage;
                                            const splitUrl = fileUrl?.split(".");
                                            const extention = splitUrl[splitUrl?.length - 1];
                                            return (
                                                <div key={index}>
                                                    {

                                                        createdBy === "USER" &&
                                                        <>
                                                            {fileUrl === "" ?
                                                                <div className="row mb-3">
                                                                    <div className="col-lg-12">
                                                                        <div className="user_msg_main">
                                                                            <div className="user_detail">
                                                                                <H5 whiteThemeColor="#6D8DAD" darkThemeColor="#6D8DAD">{userTicket?.userName}</H5>
                                                                                <P whiteThemeColor="#6D8DAD" darkThemeColor="#6D8DAD">
                                                                                    {new Date(date).getDate()}-{new Date(date).getMonth() + 1}-{new Date(date).getFullYear()}
                                                                                </P>
                                                                            </div>
                                                                            <div className="user_msg">
                                                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{message}</P>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="row mb-3" >
                                                                    <div className="col-lg-12" style={{ display: "flex", justifyContent: "start" }}>
                                                                        <div className="attached_dacument_section">
                                                                            <div className="attached_file" onClick={() => PreviewHandler(fileUrl)}>
                                                                                {extention === "pdf" ?
                                                                                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                                                    :
                                                                                    <i className="fa fa-picture-o" style={{ color: "green" }} aria-hidden="true"></i>
                                                                                }
                                                                            </div>
                                                                            <div className="attached_file_with_msg">
                                                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{message}</P>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </>
                                                    }

                                                    {
                                                        createdBy === "BACK_OFFICE" &&
                                                        <div className="row mb-3">
                                                            <div className="col-lg-12" style={{ display: "flex", justifyContent: "end" }}>
                                                                <div className="back_office_user_msg_main">
                                                                    <div className="back_office_user_detail">
                                                                        <H5 whiteThemeColor="#6D8DAD" darkThemeColor="#6D8DAD"> {backOfficeUserName} </H5>
                                                                        <P whiteThemeColor="#6D8DAD" darkThemeColor="#6D8DAD">
                                                                            {new Date(date).getDate()}-{new Date(date).getMonth() + 1}-{new Date(date).getFullYear()}
                                                                        </P>
                                                                    </div>
                                                                    <div className="back_office_user_msg">
                                                                        <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {message} </P>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    }


                                                </div>
                                            );
                                        })}
                                    </div>


                                    {/*----textarea----*/}
                                    {userTicket.backOfficeStatus === "CLOSE" ?
                                        <div className="back_office_user_textarea_main">
                                            <div className="back_office_user_textarea">
                                                <textarea placeholder="Type your message here..." type="text" name="message" onChange={handleChange} className="form-control" disabled />
                                                <input type="button" value="Resolve" disabled style={{ margin: "0px 0px 0px 10px" }} />
                                                <button style={{ marginBottom: "25px" }} onClick={() => closebackOfficeGrievancePreview()}><span>Cancel</span></button>
                                            </div>
                                        </div>
                                        :
                                        <div className="back_office_user_textarea_main">
                                            <div className="back_office_user_textarea">
                                                <textarea placeholder="Type your message here..." type="text" name="message" onChange={handleChange} value={values.message} className={`form-control ${errors.message ? "is-invalid" : ""}`} />
                                                <div className="invalid-feedback">{errors.message}</div>

                                                <button type="button" onClick={() => {
                                                    handleSubmit()
                                                }} >
                                                    {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <span>send</span>}
                                                </button>

                                                <button onClick={() => closebackOfficeGrievancePreview()}><span>Cancel</span></button>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            }
        </section>
    );
}
export default BackOfficeGrievancePreview;