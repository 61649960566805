import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import H4 from "../text-components/H4";
import P from "../text-components/P";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { backOfficeCommunication } from '../communications/back-office-communication';
import Label from "../text-components/Label";



export default function MulipleDocumentUpload({ selectedDocumentArray, setSelectedDocumentArray, title, uploadedDocumentArray, status }) {
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    //****  Stopping field input- Enable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState(null);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    const inputFeild = useRef();


    //***** Setting default value for the form  ****//
    const defaultFormValues = {
        fileUrl: "",
        documentName: ""
    }
    const validationSchema = Yup.object().shape({
        fileUrl: Yup.mixed().required("Select a file"),
        documentName: Yup.string().required("Document name is required"),
    });

    function onFileSelect(event) {
        event.preventDefault();

        setFieldValue("fileUrl", event.currentTarget.files[0]);
    }

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    const { handleSubmit, handleChange, setFieldValue, values, errors, handleReset, resetForm } = useFormik({
        initialValues: defaultFormValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {
            try {

                selectedDocumentArray.push({ documentName: values.documentName, file: values.fileUrl });
                handleReset();
                resetForm({});
                // clearing file input feild
                inputFeild.current.value = null;

                return;

            } catch (error) {
                handleReset();
                return false;
            }
        },
        validationSchema: validationSchema,
    });

    const downloadDocumentFromServer = async (attachment) => {
        if (attachment !== "") {
            await backOfficeCommunication.getUploadUserMilestone(attachment);
        }
    }

    const removeFileFromArray = (idx) => {
        // removing file from array
        const newArr = selectedDocumentArray;
        newArr.splice(idx, 1);
        setSelectedDocumentArray([...newArr]);
    }

    const localDocumentPreview = (idx) => {
        // convert 
        const selectedDocument = selectedDocumentArray[idx];

        const file = new Blob([selectedDocument.file], {
            type: "application/pdf"
        });

        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);

    }


    return (

        <>
            {
                ["IN_PROGRESS", "NEW"].includes(status) === true ? <> <div className="row mt-4">
                    <div className="col-12">
                        <H4>
                            <label>{title} </label>
                        </H4>
                    </div>

                </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="">
                                <input type="text" onChange={handleChange} className="form-control" name="documentName" placeholder='Document Name' value={values.documentName} />
                                <p className='text-danger'> {errors.documentName} </p>
                            </div>
                        </div>
                        <div className="col-6 ">
                            <div className="">
                                <input type="file" onChange={(e) => onFileSelect(e)} className="form-control" name="fileUrl" ref={inputFeild} accept="application/pdf" value={values.fileUrl?.filename} />
                                <p className='text-danger'> {errors.fileUrl} </p>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="">
                                <button style={{float:"right"}} type="button" className="button btn form_save-btn" onClick={handleSubmit}>Add</button>
                            </div>
                        </div>
                    </div>

                    {
                        selectedDocumentArray && selectedDocumentArray.length > 0 && selectedDocumentArray.map((ele, idx) => {
                            return (

                                <div className="row mt-4" key={idx}>
                                    <div className="col-lg-6 col-md-6">
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                            <label>{ele.documentName}</label>
                                        </H4>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        {/* <div className="back_office_remittance_upload_receipt"> */}
                                        <button type="button" className="button" onClick={() => localDocumentPreview(idx)} >Preview</button>
                                        {/* </div> */}
                                    </div>
                                    <div className="col-lg-3 col-md-3 ">
                                        {/* <div className="back_office_remittance_upload_receipt"> */}
                                        <button type="button" className="button btn form_save-btn ms-3" onClick={() => removeFileFromArray(idx)}>Remove</button>
                                        {/* </div> */}
                                    </div>
                                </div>
                            )
                        })
                    }
                </> :

                    <>
                        <div className="col-12 mt-5">
                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                <label>{title} </label>
                            </P>
                        </div>
                        {
                            uploadedDocumentArray && uploadedDocumentArray.length > 0 && uploadedDocumentArray.map((ele, idx) => {
                                return (

                                    <div className="row mt-4" key={idx}>
                                        <div className="col-lg-2 col-md-2">
                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                <label> {idx + 1}</label>
                                            </P>
                                        </div>
                                        <div className="col-lg-7 col-md-7">
                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                <label>{ele.documentName}</label>
                                            </P>
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <button type="button" className="button" onClick={() => downloadDocumentFromServer(ele.fileUrl)} >Preview</button>
                                        </div>
                                        <div className="col-lg-3 col-md-3">

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
            }


        </>
    );
}
