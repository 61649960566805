import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import H4 from "../text-components/H4";
import P from "../text-components/P";
import { communication } from "../communications/communication";
import LoadingForms from "../shared-components/LoadingForms";
import MulipleDocumentUpload from '../shared-components/MulipleDocumentUpload';
import { removeUnderscoreFromText } from "../utlis/helper";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";


const Remittance = ({ changeHandler }) => {
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const [showDialogueBox, setShowDialogueBox] = useState(false);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [selectedRemittanceAndApplicationId, setSelectedRemittanceAndApplicationId] = useState();
    const [userRemittanceData, setUserRemittanceData] = useState([]);
    const [selectedDocumentArray, setSelectedDocumentArray] = useState([]);
    const [selectedRemittanceAndApplicationData, setSelectedRemittanceAndApplicationData] = useState({});
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;

    const dispatch = useDispatch();
    const history = new useHistory();
    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    function showDialogue(remittanceId, applicationId, index) {
        setShowDialogueBox(true);
        setSelectedRemittanceAndApplicationId({ remittanceId, applicationId });
        setSelectedRemittanceAndApplicationData(userRemittanceData[index]);
        return;
    }
    function closeDialogue() {
        setShowDialogueBox(false);
        setSelectedRemittanceAndApplicationId({});
    }

    //onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            getRemittanceBySearch(searchValue, selectedPage + 1);
        } else {
            getRemittanceData(selectedPage + 1);
        }
    }

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            getRemittanceBySearch(searchString, currentPage + 1);
        } else {
            const response = await communication.getAllUserRemittance();
            if (response.status === "SUCCESS") {
                setUserRemittanceData(response.remittances);
                setPageCount(response?.totalPages);
            }
            else {
                setUserRemittanceData([]);
                setPageCount(0);
            }
        }
    }
    
    //get Remittance By Search
    const getRemittanceBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.getRemittanceBySearch(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setUserRemittanceData(responseFromServer?.data?.remittances);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                showModal(responseFromServer?.data?.message);
                history.push("/login");
            } else {
                setUserRemittanceData([]);
                setPageCount(0);
            }
        } catch (error) {
            showModal(error?.message);
        }
    }

    //get Remittance on Initial Load
    async function getRemittanceData(page) {
        // to show active tab
        let fullUrl = window.location.href;
        let splitRemittanceUrl = fullUrl.split('/');
        if (splitRemittanceUrl.includes("remittance")) {
            dispatch({ type: "SHOW_ACTIVE_APPLICATION_PROCESS_TAB", payload: "REMITTANCE" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_APPLICATION_PROCESS_TAB" });
        }
        showLoading("Loading...");
        try {
            const response = await communication.getAllUserRemittance(page);
            if (response.status === "SUCCESS") {
                setUserRemittanceData(response.remittances);
                setPageCount(response?.totalPages);
            }
            else {
                setUserRemittanceData([]);
                setPageCount(0);
            }
        } catch (e) {
            showModal(e?.message);
        }
        hideLoading();
    }
    useEffect(() => {
        getRemittanceData();
    }, []);



    async function uploadReceipt() {
        showLoading("Loading...");
        const formData = new FormData();

        //check file uploaded or not
        if (selectedDocumentArray.length === 0) {
            showModal("Please Attached document");
            hideLoading();
            return;
        }

        // add document name to files
        let newDocumentArray = [];

        for (let index = 0; index < selectedDocumentArray.length; index++) {
            const element = selectedDocumentArray[index];
            element.file.documentName = element['documentName'];
            newDocumentArray.push(element.file);
        }

        //  convert all image to form data
        newDocumentArray.forEach((ele) => {
            formData.append('document', ele, ele.documentName);
        });

        const uploadAcknowledgedReportResponse = await communication.uploadAcknowledgedReport(formData, selectedRemittanceAndApplicationId);
        if (uploadAcknowledgedReportResponse?.data?.status === "SUCCESS" || uploadAcknowledgedReportResponse?.status === "SUCCESS") {
            await changeHandler();
            setShowDialogueBox(false);
            setSelectedRemittanceAndApplicationId({});
            await getRemittanceData();
        }
        showModal(uploadAcknowledgedReportResponse?.data?.message);
        hideLoading();
    }
    return (
        <>
            <section className="application_flow_big" >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :
                    <>
                        <div className="Remittance_main">
                            <div className="row search_wrapper">
                                <div className="col-lg-4 col-md-4 ps-0">
                                    <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Milestone Name, Status..." />
                                </div>
                                <div className="col-lg-8 col-md-8 pe-0 pagination_outer_wrapper">
                                    <div className="pagination_inner_wrapper">
                                        {pageCount > 1 &&
                                            <ReactPaginate
                                                previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                                nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                forcePage={currentPage}
                                                activeClassName={"active"} />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/*-----Grievance card header section---*/}
                            <div className="Remitance_card_heading">
                                <div className="row m-0">
                                    <div className="col-1 p-0 data">
                                        <span><H4>Serial No.</H4></span>
                                    </div>
                                    <div className="col-5 p-0 data">
                                        <span><H4>Milestone</H4></span>
                                    </div>
                                    <div className="col-2 p-0 data">
                                        <span><H4>Status</H4></span>
                                    </div>
                                    <div className="col-2 p-0 data">
                                        <span><H4>Amount</H4></span>
                                    </div>
                                    <div className="col-2 p-0 align" style={{ justifyContent: "center" }}>
                                        <span><H4>Date</H4></span>
                                    </div>
                                </div>
                            </div>
                            {/*-----Grievance card data section---*/}
                            {userRemittanceData?.length > 0 ?
                                <>
                                    {userRemittanceData.map((remittanceData, index) => {
                                        const { milestoneName, userStatus, amount, createdDate, _id, applicationId } = remittanceData;
                                        return (
                                            <div key={index}>
                                                <div className="Remitance_card_data_main">
                                                    <div className="Remitance_card_data">
                                                        <div className="row m-0">
                                                            <div className="col-1 p-0 data">
                                                                <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{limit * currentPage + (index + 1)}</P></span>
                                                            </div>

                                                            <div className="col-5 p-0 data" style={{ overflow: "hidden" }}>
                                                                <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a>{removeUnderscoreFromText(milestoneName)}</a></P></span>

                                                            </div>

                                                            <div className="col-2 p-0 data">
                                                                <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>{removeUnderscoreFromText(userStatus)}</P></span>

                                                            </div>
                                                            <div className="col-2 p-0 data">
                                                                <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{amount}</P></span>
                                                            </div>
                                                            <div className="col-2 p-0 align" style={{ justifyContent: "center" }}>
                                                                <span><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{new Date(createdDate).getDate()}-{new Date(createdDate).getMonth() + 1}-{new Date(createdDate).getFullYear()}</P></span>
                                                            </div>
                                                        </div>

                                                        {userStatus === "NEW" ?
                                                            <div className="new_badge">
                                                                <p>New</p>
                                                            </div>
                                                            : <span></span>
                                                        }
                                                    </div>

                                                    <div className="aknowledge_btn text-center">
                                                        {
                                                            userStatus === "PAID" ?
                                                                <button onClick={() => showDialogue(_id, applicationId, index)}>Acknowledge</button>
                                                                :
                                                                ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        );
                                    })}

                                    {/*----Acknowledge dialogue box----*/}
                                    {showDialogueBox ?
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="dialogue_box">
                                                    {/*------Upload acknowledgement letter----*/}
                                                    {/* <div className="row">
                                                    <div className="col-lg-5">
                                                        <H4 style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>* Upload Acknowledgement <span className="ms-3">Letter</span></H4>
                                                    </div>
                                                    <div className="col-lg-5">
                                                        <input type="file" onChange={(e) => setFileToUpload(e)} name="receipt" className="form-control" required />
                                                    </div>
                                                </div> */}
                                                    <div className="mb-2">
                                                        <span style={{ fontSize: "14px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                    </div>

                                                    <MulipleDocumentUpload
                                                        setSelectedDocumentArray={setSelectedDocumentArray}
                                                        selectedDocumentArray={selectedDocumentArray}
                                                        title={"Attach Required University Receipt and Student Receipt:"}
                                                        status={selectedRemittanceAndApplicationData?.userStatus}
                                                        uploadedDocumentArray={selectedRemittanceAndApplicationData?.userDocumentsUrl}
                                                    />

                                                    {/* ------Upload Other Documents---- */}
                                                    {/* <div className="row mt-3">
                                                    <div className="col-12">
                                                        <H4 style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>* Upload Other Documents</H4>
                                                        <div className="row mt-3 other_documents">
                                                            <div className="col-lg-5">
                                                                <input type="text" className="form-control" placeholder="Enter document name" />
                                                            </div>
                                                            <div className="col-lg-5">
                                                                <input type="file" className="form-control" />
                                                            </div>
                                                            <div className="col-lg-2" style={{ textAlign: "right" }}>
                                                                <button>Add</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                    {/*------Other Documents Table----*/}
                                                    {/* <div className="row mt-3 remittance_other_documents_list">
                                                    <div className="col-lg-1">
                                                        <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">1</P>
                                                    </div>

                                                    <div className="col-lg-5">
                                                        <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Document Name</P>
                                                    </div>

                                                    <div className="col-lg-5">
                                                        <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">File Name</P>
                                                    </div>
                                                    <div className="col-lg-1">
                                                        <button><i className="fa fa-trash-o" aria-hidden="true"></i></button>
                                                    </div>
                                                </div> */}


                                                    <div className="acknowledge_dialogue_box_btn">
                                                        <button className="me-4" onClick={() => uploadReceipt()}>{isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <span>Submit</span>}</button>
                                                        <button onClick={() => closeDialogue()}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : <span></span>
                                    }
                                </>
                                :
                                <div className="col-12 text-center view_download_dashboard">
                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Remittances not available.</H4>
                                </div>
                            }
                        </div>
                    </>


                }
            </section>
        </>
    );
}
export default Remittance;