import React from 'react'
import HomeScreenNav from './HomeScreenNav';

const Commissioner = () => {
    return (
        <>
            <HomeScreenNav />

            <div className='container'>
                <div className='row'>
                    <div className='col-12 hierarchy_img'>
                        <img src="/assets/images/jpgs/engCommissionerGraph.jpg" alt="engCommissionerGraph.jpg" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Commissioner;