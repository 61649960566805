import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import H4 from "../../text-components/H4";
import P from "../../text-components/P";
import { backOfficeCommunication } from "../../communications/back-office-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import ReactPaginate from "react-paginate";


const HomeVerification = () => {
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const history = useHistory();
    const [milestoneData, setMilestoneData] = useState([]);
    const [filteredMilestoneData, setFilteredMilestoneData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchUserHomeVericationMilestoneBySearch(searchValue, selectedPage + 1);
        } else {
            fetchData(selectedPage + 1);
        }
    }

    // Search Filter
    const searchFilter = async (searchString) => {
        // console.log(searchString);
        if (searchString) {
            fetchUserHomeVericationMilestoneBySearch(searchString, currentPage + 1);
        } else {
            const response = await backOfficeCommunication.getUserHomeVericationMilestone();
            if (response.status === "SUCCESS") {
                setMilestoneData(response?.milestones);
                setFilteredMilestoneData(response?.milestones);
                setPageCount(response?.totalPages)
            } else if (response?.status === "ERROR" || response?.jwt === null) {
                history.push('/back-office/login')
            } else {
                setMilestoneData([]);
                setFilteredMilestoneData([]);
                setPageCount(0);
            }
        }
    }

    //getUser Home Verication Milestone by search
    const fetchUserHomeVericationMilestoneBySearch = async (searchString, page) => {
        const response = await backOfficeCommunication.getUserHomeVericationMilestoneBySearc(searchString, page);
        // console.log("search word", searchString);
        if (response?.data?.status === "SUCCESS") {
            setMilestoneData(response?.data?.milestones);
            setFilteredMilestoneData(response?.data?.milestones);
            setPageCount(response?.data?.totalPages)
        } else if (response?.status === "ERROR" || response?.jwt === null) {
            history.push('/back-office/login')
        } else {
            setMilestoneData([]);
            setFilteredMilestoneData([]);
            setPageCount(0)

        }
    }

    //get Home verication Milestone on Initial Load
    async function fetchData(page) {
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        if (splitUrlArray.includes("home-verification")) {
            dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "HOME_VERIFICATION" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
        }

        showLoading("Loading...");
        try {
            const response = await backOfficeCommunication.getUserHomeVericationMilestone(page);
            // console.log("inside code", response);
            if (response.status === "SUCCESS") {
                setMilestoneData(response.milestones);
                setFilteredMilestoneData(response.milestones);
            }
            else if (response.status === "ERROR" || response.jwt === null) {
            }
            else {
                showModal(response.message);
            }
        } catch (e) {
            console.log(e);
        }
        hideLoading();
    }
    useEffect(() => {
        fetchData();
    }, []);

    function forApplicationId(applicationId, milestoneId) {
        const tab = "homeverification"
        history.push(`/back-office/dashboard/milestoneTwo?applicationId=${applicationId}&type=MILESTONE_TWO&milestoneId=${milestoneId}&tab=${tab}`);
    }


    // function searchLogic() {

    //     return new Promise((resolve, reject) => {

    //     })

    // }

    // function onChangeFilterText(event, searchFor) {

    //     if (searchFor === "APPLICATION_ID") {
    //         const filterData = milestoneData.filter((ele) => ele.shortApplicationId.search(event.target.value) === -1 ? false : true);
    //         setFilteredMilestoneData(filterData);
    //     }

    //     if (searchFor === "FIRST_NAME") {
    //         const filterData = milestoneData.filter((ele) => {
    //             const fullname = ele.name.middleName.toLowerCase() + ele.name.lastName.toLowerCase() + ele.name.firstName.toLowerCase();
    //             return fullname.search(event.target.value.toLowerCase()) === -1 ? false : true;
    //         });
    //         setFilteredMilestoneData(filterData);
    //     }

    //     if (searchFor === "DISTRICT") {
    //         const filterData = milestoneData.filter((ele) => ele.district.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
    //         setFilteredMilestoneData(filterData);
    //     }

    //     if (searchFor === "STATUS") {
    //         const filterData = milestoneData.filter((ele) => ele.status.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
    //         setFilteredMilestoneData(filterData);
    //     }
    // }

    return (
        <section className="dashboard_background">
            {isLoading.value ?
                <LoadingForms message={isLoading.message} />
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="dashboard_back_office">
                                {/*-----Document scrutiny search box----*/}
                                <div className="back_office_search_box">
                                    <div className="row">
                                        <div className="col-1"></div>
                                        <div className="col-4 col-md-4 search_box">
                                            <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By ApplicationID,Name,District..." />
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </div>
                                        {/*<div className="col-3 search_box">
                                        <input type="search" className="form-control" placeholder="Name" onChange={(event) => onChangeFilterText(event, "FIRST_NAME")} />
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </div>
                                    <div className="col-3 search_box">
                                        <input type="search" className="form-control" placeholder="District" onChange={(event) => onChangeFilterText(event, "DISTRICT")} />
                                        <i className="fa fa-search" aria-hidden="true"></i>
            </div>*/}
                                        <div className="col-lg-7 col-md-7 pe-0 pagination_outer_wrapper">
                                            <div className="pagination_inner_wrapper">
                                                {pageCount > 1 &&
                                                    <ReactPaginate
                                                        previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                                        nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        forcePage={currentPage}
                                                        activeClassName={"active"} />
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/*----- card header section---*/}
                                <div className="dashboard_view_data_heading">
                                    <div className="row m-0">
                                        <div className="col-1 p-0 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Serial No.</H4></span>
                                        </div>
                                        <div className="col-3 p-0 view_dashboard ps-2" style={{ textAlign: "left" }}>
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Application ID</H4></span>
                                        </div>
                                        <div className="col-3 p-0 view_dashboard ps-2" style={{ textAlign: "left" }}>
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Name</H4></span>
                                        </div>
                                        <div className="col-3 p-0 view_dashboard">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">District</H4></span>
                                        </div>
                                        <div className="col-2 p-0 ">
                                            <span><H4 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Status</H4></span>
                                        </div>
                                    </div>
                                </div>
                                {filteredMilestoneData.map((value, index) => {
                                    return (
                                        <div className="dashboard_view_data_main" key={index}>
                                            <div className="dashboard_view_data">
                                                <div className="row m-0">
                                                    <div className="col-1 p-0 view_dashboard text_align">
                                                        <span>
                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {limit * currentPage + (index + 1)}</P>
                                                        </span>
                                                    </div>
                                                    <div className="col-3 p-0 ps-2 view_dashboard align" style={{ textAlign: "left" }}>
                                                        <span>
                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a onClick={() => forApplicationId(value.applicationId, value._id)}>{value?.shortApplicationId}</a></P>
                                                        </span>
                                                    </div>
                                                    <div className="col-3 p-0 ps-2 view_dashboard align" style={{ textAlign: "left" }}>
                                                        <span>
                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a onClick={() => forApplicationId(value.applicationId)} >{value?.userName} </a></P>
                                                        </span>
                                                    </div>
                                                    <div className="col-3 p-0 ps-2 view_dashboard text_align">
                                                        <span>
                                                            <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{value?.location}</P>
                                                        </span>
                                                    </div>
                                                    <div className="col-2 p-0  text_align">
                                                        <span ><P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{value?.backOfficeStatus}</P></span>
                                                    </div>
                                                </div>

                                                {value?.backOfficeStatus === "HV_REQUESTED" ?
                                                    <div className="new_badge">
                                                        <p>New</p>
                                                    </div>
                                                    : <span></span>
                                                }

                                            </div>
                                        </div>)
                                })}
                            </div>

                        </div>
                    </div>
                </div>
            }
        </section>
    );

}
export default HomeVerification;