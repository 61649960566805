
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import Label from "../text-components/Label";
import { applicationCommunication } from "../communications/application-communication";
import FormFieldTitle from "./FormFieldTitle";
import OffSpringChild from "./ArrayObjectFolder/OffSpringChild";
//----DatePicker (NPM Package)----//
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from 'formik';
//****  District and State list Array object for Maping   *****//
import StateAndDistrictList from "./ArrayObjectFolder/StateAndDistrictList";
//**** Course Spacialization list object for Maping   *****//
import courseSpecialization from "./ArrayObjectFolder/CourseSpecialization";
//**** Countery list object for Maping   *****//
import countryNameList from "./ArrayObjectFolder/CountryNameList";
import subCasteNameList from "./ArrayObjectFolder/SubCasteList";
//**** Exam details table componet   *****//
import ExamDetailsTable from "./ExamDetailsTable";
import LoadingForms from "../shared-components/LoadingForms";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { communication } from "../communications/communication"
import H2 from "../text-components/H2";       

const PersonalDetails = () => {
    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    // cancel, save and submit buttons unable & desable
    const [disableActions, setDisableActions] = useState(false);
    // spinner on loading
    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    // Application id comes fro server
    const [applicationId, setApplicationId] = useState(null);

    // const [collegeDetails, setCollegeDetails] = useState([]);

    const [collegeDetailDisable, setCollegeDetailDisable] = useState(true);

    const [offSpringDetails, setOffSpringDetails] = useState([]);

    const [offSpringDetailsDisable, setOffSpringDetailsDisable] = useState(true);

    const [maritalStatusValue, setMaritalStatusValue] = useState([]);

    const [maritalStatusData, setMaritalStatusData] = useState(true);

    const [ifMarried, setIfMarried] = useState(true);

    const [partnerTake, setPartnerTake] = useState(false);

    const [initialFormValues, setInitialFormValues] = useState(null);

    const [saveType, setSaveType] = useState(null);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [districtList, setDistrictList] = useState([]);

    const [jobVerificationUrl, setJobVerificationUrl] = useState({});

    const [ageOnDate, setAgeOnDate] = useState("");

    //on state selection in city field displaying selected state cities 
    function selectState(value) {
        for (let i = 0; i < StateAndDistrictList.length; i++) {
            if (StateAndDistrictList[i].state === value) {
                setDistrictList(value => value = StateAndDistrictList[i].city);
            }
        }
    }
    //Deleting exam details
    // function deleteCollegeDetails(itemToDelete) {
    //     setCollegeDetails(value => {
    //         return value.filter(item => item !== itemToDelete);
    //     });
    // }
    //Deleting offspring details
    function deleteOffSpringDetails(itemToDelete) {
        setOffSpringDetails(value => {
            return value.filter(item => item !== itemToDelete);
        });
    }


    const history = useHistory();

    const location = useLocation();

    // To get current location url
    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');
    useEffect(() => {
        async function initialLoad() {

            setAgeOnDate(process.env.REACT_APP_DATE_AGE_CALCULATE);



            showLoading("Loading Form...");

            // to show active tab
            let fullUrl = window.location.href;
            let splitUrlArray = fullUrl.split('/');
            let personalDetailUrl = "personal-details?applicationId";
            let splitpersonalDetailUrl = personalDetailUrl.split('?');

            if (splitpersonalDetailUrl.includes("personal-details")) {
                dispatch({ type: "SHOW_ACTIVE_APPLICATION_FORM_TAB", payload: "PERSONAL_DETAILS" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_APPLICATION_FORM_TAB" });
            }

            // Fetching form from the server
            const queryParams = new URLSearchParams(location.search);
            const applicationIdFromParams = await queryParams.get('applicationId');
            setApplicationId(applicationIdFromParams);

            const getPersonalDetailsForm = await applicationCommunication.getPersonalDetailsForUserApplication(applicationIdFromParams);
            // Handling response from the server
            if (getPersonalDetailsForm.status === "PERSONAL_DETAILS_FORM_FETCHED_SUCCESSFULLY") {
                let personalDetailsForm = getPersonalDetailsForm.personalDetailsForm;
                // setting new form values received from the server
                setInitialFormValues(value => {
                    selectState(personalDetailsForm.address.state);
                    return {
                        ...value, ...{
                            appliedForSchemeEarlier: personalDetailsForm.appliedForSchemeEarlier,
                            NoOfAttempts: personalDetailsForm.NoOfAttempts,
                            firstName: personalDetailsForm.studentName.firstName,
                            middleName: personalDetailsForm.studentName.middleName,
                            lastName: personalDetailsForm.studentName.lastName,
                            courseType: personalDetailsForm.courseDetails.courseType,
                            stream: personalDetailsForm.courseDetails.stream,
                            specialization: personalDetailsForm.courseDetails.specialization,
                            houseNumber: personalDetailsForm.address.houseNumber,
                            street: personalDetailsForm.address.street,
                            landmark: personalDetailsForm.address.landmark,
                            area: personalDetailsForm.address.area,
                            district: personalDetailsForm.address.district,
                            state: personalDetailsForm.address.state,
                            pincode: personalDetailsForm.address.pincode,
                            extension: personalDetailsForm.personalContactNumber.mobileNumber.extension,
                            number: personalDetailsForm.personalContactNumber.mobileNumber.number,
                            altExtension: personalDetailsForm.personalContactNumber.alternateMobileNumber.extension,
                            altNumber: personalDetailsForm.personalContactNumber.alternateMobileNumber.number,
                            gender: personalDetailsForm.gender,
                            category: personalDetailsForm.category,
                            subCaste: personalDetailsForm.subCaste,
                            dateOfBirth: personalDetailsForm.dateOfBirth.year === 0 ? "" : new Date(personalDetailsForm.dateOfBirth.year, personalDetailsForm.dateOfBirth.month, personalDetailsForm.dateOfBirth.day),
                            age: { years: personalDetailsForm.dateOfBirth.age.year, months: personalDetailsForm.dateOfBirth.age.month, days: personalDetailsForm.dateOfBirth.age.day },
                            email: personalDetailsForm.personalEmail.email,
                            alternateEmail: personalDetailsForm.personalEmail.alternateEmail,
                            aadharNumber: personalDetailsForm.aadharNumber,
                            panCardNumber: personalDetailsForm.panCardNumber,
                            isMaharashtraResident: personalDetailsForm.isMaharashtraResident,
                            isEmployed: personalDetailsForm.employmentDetails.isEmployed,
                            noObjectionCertificate: personalDetailsForm.employmentDetails.noObjectionCertificate,
                            isCurrentylWorking: personalDetailsForm.employmentDetails.details.endDate === "present" ? true : false,
                            job: personalDetailsForm.employmentDetails.details.job,
                            jobVerification: personalDetailsForm.employmentDetails.details.jobVerification,
                            padnam: personalDetailsForm.employmentDetails.details.padnam,
                            annualIncome: personalDetailsForm.employmentDetails.details.annualIncome,
                            jobStartDate: (personalDetailsForm.employmentDetails.details.startDate === "") ? new Date() : new Date(personalDetailsForm.employmentDetails.details.startDate),
                            jobEndDate: (personalDetailsForm.employmentDetails.details.endDate === "present" || personalDetailsForm.employmentDetails.details.endDate === "") ? new Date() : new Date(personalDetailsForm.employmentDetails.details.endDate),
                            officeHouseNumber: personalDetailsForm.employmentDetails.address.houseNumber,
                            officeStreet: personalDetailsForm.employmentDetails.address.street,
                            officeLandmark: personalDetailsForm.employmentDetails.address.landmark,
                            officeArea: personalDetailsForm.employmentDetails.address.area,
                            officeDistrict: personalDetailsForm.employmentDetails.address.district,
                            officeState: personalDetailsForm.employmentDetails.address.state,
                            officePincode: personalDetailsForm.employmentDetails.address.pincode,
                            officeExtension: personalDetailsForm.employmentDetails.officeContactNumber.mobileNumber.extension,
                            officeNumber: personalDetailsForm.employmentDetails.officeContactNumber.mobileNumber.number,
                            officeAltExtension: personalDetailsForm.employmentDetails.officeContactNumber.alternateMobileNumber.extension,
                            officeAltNumber: personalDetailsForm.employmentDetails.officeContactNumber.alternateMobileNumber.number,
                            officeEmail: personalDetailsForm.employmentDetails.officeEmail.email,
                            officeAlternateEmail: personalDetailsForm.employmentDetails.officeEmail.alternateEmail,
                            maritalStatus: personalDetailsForm.familyDetails.maritalStatus,
                            spouseTakeAbroad: personalDetailsForm.familyDetails?.willYouTakeYourPartnerWithYou,
                            marriedToName: personalDetailsForm.familyDetails.marriedToName,
                            holdingStatus: personalDetailsForm.familyDetails.holdingStatus,
                            accountHolderName: personalDetailsForm.bankDetails.accountHolderName,
                            bankName: personalDetailsForm.bankDetails.bankName,
                            branchName: personalDetailsForm.bankDetails.branchName,
                            accountNumber: personalDetailsForm.bankDetails.accountNumber,
                            ifscCode: personalDetailsForm.bankDetails.ifscCode,
                            micrCode: personalDetailsForm.bankDetails.micrCode,
                            passportNumber: personalDetailsForm.passportDetails.passportNumber,
                            dateOfIssue: personalDetailsForm.passportDetails.dateOfIssue.year === 0 ? "" : new Date(personalDetailsForm.passportDetails.dateOfIssue.year, personalDetailsForm.passportDetails.dateOfIssue.month, personalDetailsForm.passportDetails.dateOfIssue.day),
                            dateOfExpiry: personalDetailsForm.passportDetails.dateOfExpiry.year === 0 ? "" : new Date(personalDetailsForm.passportDetails.dateOfExpiry.year, personalDetailsForm.passportDetails.dateOfExpiry.month, personalDetailsForm.passportDetails.dateOfExpiry.day),
                            offerLetterNumber: personalDetailsForm.collegeDetails.offerLetterNumber,
                            offerLetterDate: personalDetailsForm.collegeDetails.offerLetterDate.year === 0 ? "" : new Date(personalDetailsForm.collegeDetails.offerLetterDate.year, personalDetailsForm.collegeDetails.offerLetterDate.month, personalDetailsForm.collegeDetails.offerLetterDate.day),
                            isOfferLetterConditional: personalDetailsForm.collegeDetails.isOfferLetterConditional,
                            offerLetterCondition: personalDetailsForm.collegeDetails.offerLetterCondition,
                            coursesInOfferLetter: personalDetailsForm.collegeDetails.coursesInOfferLetter,
                            hasAvailedScholarshipSchemeBefore: personalDetailsForm.previousScholarshipDetails.hasAvailedScholarshipSchemeBefore,
                            scholarshipSchemeAvailDate: personalDetailsForm.previousScholarshipDetails.scholarshipSchemeAvailDate.year === 0 ? new Date() : new Date(personalDetailsForm.previousScholarshipDetails.scholarshipSchemeAvailDate.year, personalDetailsForm.previousScholarshipDetails.scholarshipSchemeAvailDate.month, personalDetailsForm.previousScholarshipDetails.scholarshipSchemeAvailDate.day),
                            collegeName: personalDetailsForm.previousScholarshipDetails.collegeName,
                            country: personalDetailsForm.previousScholarshipDetails.country,
                            courseName: personalDetailsForm.previousScholarshipDetails.courseName,
                            periodOfCourse: personalDetailsForm.previousScholarshipDetails.periodOfCourse,
                            receivedScholarshipAmount: personalDetailsForm.previousScholarshipDetails.receivedScholarshipAmount,
                        }
                    };
                });
                setMaritalStatusData(personalDetailsForm.familyDetails.maritalStatus === "SINGLE" ? false : true)
                setIfMarried(personalDetailsForm.familyDetails.maritalStatus === "MARRIED" ? false : true)
                setPartnerTake(personalDetailsForm.familyDetails?.willYouTakeYourPartnerWithYou === "MARRIED" ? false : true)
                // setIsEmployed(value => value = personalDetailsForm.parentsEmploymentDetails.isEmployed);
                setOffSpringDetails(value => value = [...value, ...personalDetailsForm.familyDetails.offsprings]);
                // setCollegeDetails(value => value = [...value, ...personalDetailsForm.educationList]);
                setDisabled(value => value = personalDetailsForm.isSubmitted);
                setIsSubmitted(value => value = personalDetailsForm.isSubmitted);
                hideLoading();
                // handleReset();
            } else {
                showModal(getPersonalDetailsForm.message);
            }

            const applicationsFromServer = await communication.getMyApplications(applicationIdFromParams);

            if (applicationsFromServer.status === "SUCCESS") {
                const applicationStatus = applicationsFromServer.userApplications[0]?.applicationStatus
                if (applicationStatus === "INCOMPLETE") {
                    setDisabled(false);
                    setIsSubmitted(false);
                } else if (applicationStatus === "SELECTED" || applicationStatus === "COMPLETED" || applicationStatus === "APPLICATION_SUBMITTED") {
                    setDisabled(true);
                    setIsSubmitted(true);
                }

            }
        }
        initialLoad();
    }, []);

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }
    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        appliedForSchemeEarlier: null,
        NoOfAttempts: 0,
        firstName: "",
        middleName: "",
        lastName: "",
        courseType: "",
        stream: "",
        specialization: "",
        houseNumber: "",
        street: "",
        landmark: "",
        area: "",
        state: "",
        district: "",
        pincode: "",
        extension: "91",
        number: "",
        altExtension: "",
        altNumber: "",
        gender: "",
        category: "",
        subCaste: "",
        dateOfBirth: null,
        age: { years: "", months: "", days: "" },
        email: "",
        alternateEmail: "",
        aadharNumber: "",
        panCardNumber: "",
        isMaharashtraResident: null,
        isEmployed: null,
        isCurrentylWorking: null,
        job: "",
        jobVerification: "",
        padnam: "",
        annualIncome: "",
        noObjectionCertificate: null,
        jobStartDate: "",
        jobEndDate: "",
        officeHouseNumber: "",
        officeStreet: "",
        officeLandmark: "",
        officeArea: "",
        officeDistrict: "",
        officeState: "",
        officePincode: "",
        officeExtension: "91",
        officeNumber: "",
        officeAltExtension: "91",
        officeAltNumber: "",
        officeEmail: "",
        officeAlternateEmail: "",
        maritalStatus: "",
        spouseTakeAbroad: false,
        marriedToName: "",
        holdingStatus: "",
        accountHolderName: "",
        bankName: "",
        branchName: "",
        accountNumber: "",
        ifscCode: "",
        micrCode: "",
        passportNumber: "",
        dateOfIssue: "",
        dateOfExpiry: "",
        offerLetterNumber: "",
        offerLetterDate: "",
        isOfferLetterConditional: null,
        offerLetterCondition: "",
        coursesInOfferLetter: "",
        hasAvailedScholarshipSchemeBefore: null,
        scholarshipSchemeAvailDate: "",
        collegeName: "",
        country: "",
        courseName: "",
        periodOfCourse: "",
        receivedScholarshipAmount: "",
    }




    //****  Input/Select filed disable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);

    //**********Course Type Array*** */
    const [courseTypeArray, setcourseTypeArray] = useState(
        [
            {
                courseTypeName: "Phd"
            },
            {
                courseTypeName: "PG"
            }
        ]
    );


    const validationSchema = Yup.object().shape({
        appliedForSchemeEarlier: Yup.boolean().required('field is required*'),
        NoOfAttempts: Yup.number()
            .when("appliedForSchemeEarlier", {
                is: true,
                then: Yup.number().positive('Number of Attempts must be greater than zero').required("Number of Attempts is required"),
                otherwise: Yup.number().nullable()
            }),
        firstName: Yup.string().required("First Name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        middleName: Yup.string().required("Middle Name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        lastName: Yup.string().required("Last Name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),

        //****Validation for course details ****//
        courseType: Yup.string().required("Select course").test('Tests', 'Select course', value => value && value.trim().length > 0 && value.trim() !== 'select'), // .test('state ', 'Select Course', (value) => value.trim().length === 0).test('state 3', 'Select Course ', (value) => value.trim().toLowerCase() === 'select'),
        stream: Yup.string().required("Select stream").test('Tests', 'Select stream', value => value && value.trim().length > 0 && value.trim() !== 'select'), // .test('state ', 'select Stream', (value) => value.trim().length === 0).test('state 3', 'Select Stream ', (value) => value.trim().toLowerCase() === 'select'),
        specialization: Yup.string().required("Specialization is required"),

        //****Validation for Address details ****//
        houseNumber: Yup.string().required("House Number is required"),
        street: Yup.string().required("Street is required"),
        landmark: Yup.string().required("Landmark is required"),
        area: Yup.string().required("Area is required"),
        state: Yup.string().required("Select State").test('Tests', 'Select state', value => value && value.trim().length > 0 && value.trim() !== 'select'),
        district: Yup.string().required("Select District").test('Tests', 'Select district', value => value && value.trim().length > 0 && value.trim() !== 'select'),
        pincode: Yup.string().required("Pin code is required")
            .min(6, 'Minimum 6 digits')
            .max(6, 'Maximum 6 digits'),

        //****Validation for Contact details ****//
        // extension: Yup.string().required("Pin is required")
        //     .min(2, 'Minimum 2 digits')
        //     .max(2, 'Maximum 2 digits'),
        number: Yup.string()
            .required("Phone Number is required")
            .min(10, 'Minimum 10 digits')
            .max(10, 'Maximum 10 digits'),
        altExtension: Yup.string()
            .min(2, 'Minimum 2 digits')
            .max(5, 'Maximum 5 digits'),

        altNumber: Yup.string()
            .min(6, 'Minimum 6 digits')
            .max(10, 'Maximum 10 digits'),

        //**** Validation for Gender ****//
        gender: Yup.string().required("Select gender").test('Tests', 'Select gender', value => value && value.trim().length > 0 && value.trim() !== 'select'),

        //**** Validation for Caste ****//
        //category: Yup.string().required("Select Category"),
        subCaste: Yup.string().required("Sub caste is required"),
        //**** Validation for date of birth ****//
        dateOfBirth: Yup.date().required("Date of Birth is required"),

        //**** Validation for age ****//
        // age: Yup.number()
        //     .typeError('age must be a number')
        //     .positive('age must be greater than zero')
        //     .max(100, "age can't be greater than 100"),

        //****Validation for Email *****//
        email: Yup.string().email("must be a valid email").required("Email is required"),
        alternateEmail: Yup.string().email("must be a valid email"),

        //****Validation for Aadhar details *****//
        aadharNumber: Yup.string()
            .required("Aadhar Number is required")
            .min(12, 'Minimum 12 digits')
            .max(12, 'Maximum 12 digits'),

        //****Validation for PAN Card details ****//
        panCardNumber: Yup.string()
            .required("PAN Card Number is required")
            .matches(/^[A-Z0-9]+$/, "Must be only Capital letter & digits")
            .min(10, 'Minimum 10 digits')
            .max(10, 'Maximum 10 digits'),

        //****Validation for PAN Card details ****//
        isMaharashtraResident: Yup.boolean().required('Field is required'),

        //****Validation for Employment details ****//
        isEmployed: Yup.boolean().required('field is required*'),
        job: Yup.string()
            .when("isEmployed", {
                is: true,
                then: Yup.string().required("Job description required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
                otherwise: Yup.string().nullable()
            }),
        jobVerification: Yup.mixed()
            .when("isEmployed", {
                is: true,
                then: Yup.mixed().required("Attachment is required").nullable(),
                otherwise: Yup.mixed().nullable()
            }),

        padnam: Yup.string()
            .when("isEmployed", {
                is: true,
                then: Yup.string().required("Padnam is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
                otherwise: Yup.string().nullable()
            }),
        annualIncome: Yup.number()
            .when("isEmployed", {
                is: true,
                then: Yup.number().required("Annual Income is required")
                    .typeError('Annual Income must be a number')
                    .positive('Annual Income must be greater than zero')
                    .max(600000, "Annual Income can't be greater than 6 Lakhs"),
                otherwise: Yup.number().nullable()
            }),
        noObjectionCertificate: Yup.boolean()
            .when("isEmployed", {
                is: true,
                then: Yup.boolean().required("field is required*")
            }),
        jobStartDate: Yup.date()
            .when("isEmployed", {
                is: true,
                then: Yup.date().required("Date of Birth is required")
            }),
        isCurrentylWorking: Yup.boolean()
            .when("isEmployed", {
                is: true,
                then: Yup.boolean().required("field is required*")
            }),
        jobEndDate: Yup.string()
            .when("isCurrentylWorking", {
                is: false,
                then: Yup.string().required("Date of Birth is required")
            }),

        //****Validation for Office address details ****//
        officeHouseNumber: Yup.string()
            .when("isEmployed", {
                is: true,
                then: Yup.string().required("House Number is required"),
                otherwise: Yup.string().nullable()
            }),
        officeStreet: Yup.string()
            .when("isEmployed", {
                is: true,
                then: Yup.string().required("Street is Required"),
                otherwise: Yup.string().nullable()
            }),
        officeLandmark: Yup.string()
            .when("isEmployed", {
                is: true,
                then: Yup.string().required("Landmark is required"),
                otherwise: Yup.string().nullable()
            }),
        officeArea: Yup.string()
            .when("isEmployed", {
                is: true,
                then: Yup.string().required("Area is required"),
                otherwise: Yup.string().nullable()
            }),
        officeState: Yup.string()
            .when("isEmployed", {
                is: true,
                then: Yup.string().required("State is required").test('Tests', 'Select Office state', value => value && value.trim().length > 0 && value.trim() !== 'select'),
                otherwise: Yup.string().nullable()
            }),

        officeDistrict: Yup.string()
            .when("isEmployed", {
                is: true,
                then: Yup.string().required("District is required")
                    .test('Tests', 'Select Office district', value => value && value.trim().length > 0 && value.trim() !== 'select'),
                otherwise: Yup.string().nullable()
            }),

        officePincode: Yup.string()
            .when("isEmployed", {
                is: true,
                then: Yup.string().required("Pin code is required")
                    .min(6, 'Minimum 6 digits')
                    .max(6, 'Maximum 6 digits'),
                otherwise: Yup.string().nullable()
            }),


        //****Validation for Contact details ****//
        // officeExtension: Yup.string()
        //     .when("isEmployed", {
        //         is: true,
        //         then: Yup.string().required("Pin code is required")
        //             .min(2, 'Minimum 2 digits')
        //             .max(2, 'Maximum 2 digits'),
        //         otherwise: Yup.string().nullable()
        //     }),

        officeNumber: Yup.string()
            .when("isEmployed", {
                is: true,
                then: Yup.string().required("Phone Number is required")
                    .min(10, 'Minimum 10 digits')
                    .max(10, 'Maximum 10 digits'),
                otherwise: Yup.string().nullable()
            }),

        officeAltExtension: Yup.string()
            .min(2, 'Minimum 2 digits')
            .max(5, 'Maximum 5 digits'),
        officeAltNumber: Yup.string()
            .min(6, 'Minimum 6 digits')
            .max(10, 'Maximum 10 digits'),

        //****Validation for Email ****//
        officeEmail: Yup.string()
            .when("isEmployed", {
                is: true,
                then: Yup.string().email("must be a valid email").required("Email is required"),
                otherwise: Yup.string().nullable()
            }),
        officeAlternateEmail: Yup.string()
            .when("isEmployed", {
                is: true,
                then: Yup.string().email("must be a valid email"),
                otherwise: Yup.string().nullable()
            }),

        //****Validation for Marital Status ****//
        maritalStatus: Yup.string().required("Select status").test('Tests', 'Select marital status', value => value && value.trim().length > 0 && value.trim() !== 'select'),
        marriedToName: Yup.string()
            .when("maritalStatus", {
                is: "SINGLE",
                then: Yup.string(),
                otherwise: Yup.string().max(30, 'Maximum 30 digits').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Husband or Wife name is required"),
            }),
        spouseTakeAbroad: Yup.boolean()
            .when("maritalStatus", {
                is: "MARRIED",
                then: Yup.boolean().required('Field is required')
            }),

        // offsprings: Yup.string().max(30, 'Maximum 30 digits').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        // holdingStatus: Yup.string(),

        //****Validation for Bank Details ****//
        accountHolderName: Yup.string().required("Account holder name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        bankName: Yup.string().required("Bank Name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        branchName: Yup.string().required("Branch name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        accountNumber: Yup.string().required("Account Number is required"),
        ifscCode: Yup.string().required("IFSC code is required"),
        micrCode: Yup.string().required("MICR Code is required"),

        //****Validation for Passport Details ****//
        passportNumber: Yup.string().required("Passport Number is required"),
        dateOfIssue: Yup.string().required("Date Of Issue is required"),
        dateOfExpiry: Yup.string().required("Date Of Expiry is required"),

        //****Validation for College Details ****//
        offerLetterNumber: Yup.string().required("Offer Letter Number is required"),
        offerLetterDate: Yup.string().required("Date Of Issue is required"),
        coursesInOfferLetter: Yup.string().required("Courses In Offer Letter is required"),
        isOfferLetterConditional: Yup.boolean().required("Field required"),
        offerLetterCondition: Yup.string()
            .when("isOfferLetterConditional", {
                is: true,
                then: Yup.string().required("Condition is required"),
                otherwise: Yup.string().nullable()
            }),

        //****Validation for State or Central Scholarship Program?  ****//
        hasAvailedScholarshipSchemeBefore: Yup.boolean().required("Select option"),
        scholarshipSchemeAvailDate: Yup.string()
            .when("hasAvailedScholarshipSchemeBefore", {
                is: true,
                then: Yup.string().required("Offer Letter Date is required"),
                otherwise: Yup.string().nullable()
            }),

        collegeName: Yup.string()
            .when("hasAvailedScholarshipSchemeBefore", {
                is: true,
                then: Yup.string().required("College Name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
                otherwise: Yup.string().nullable()
            }),
        country: Yup.string()
            .when("hasAvailedScholarshipSchemeBefore", {
                is: true,
                then: Yup.string().required("Country is required"),
                otherwise: Yup.string().nullable()
            }),
        courseName: Yup.string()
            .when("hasAvailedScholarshipSchemeBefore", {
                is: true,
                then: Yup.string().required("Course Name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
                otherwise: Yup.string().nullable()
            }),
        periodOfCourse: Yup.string()
            .when("hasAvailedScholarshipSchemeBefore", {
                is: true,
                then: Yup.string().required("Period Of Course is required").matches(/^[0-9]+$/, "Must be only digits"),
                otherwise: Yup.string().nullable()
            }),
        receivedScholarshipAmount: Yup.string()
            .when("hasAvailedScholarshipSchemeBefore", {
                is: true,
                then: Yup.string().required("Amount is required").matches(/^[0-9]+$/, "Must be only digits"),
                otherwise: Yup.string().nullable()
            })

    });

    function loadNextForm() {
        history.push(`/user/application/parent-details?applicationId=${applicationId}`);
    }

    function onFileSelect(event) {
        event.preventDefault();
        let formData = new FormData();
        formData.append("employmentCertificate", event.target.files[0], event.target.files[0].name);
        setJobVerificationUrl(formData);
        setFieldValue('jobVerification', event.target.files[0]);
    }

    // Job verification file preview
    const previewDocumentsHandler = async (fileUrl) => {
        if (typeof (fileUrl) === "string") {
            await communication.getUploadedDocumets(fileUrl)
        }

        if (typeof (fileUrl) === "object") {
            const preview = URL.createObjectURL(fileUrl);
            window.open(preview);
        }
    }

    const { handleSubmit, handleChange, setFieldValue, values, errors, handleReset } = useFormik({
        initialValues: initialFormValues === null ? defaultFormValues : initialFormValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {
            try {
                // Loading on save and submit button
                showLoading(saveType === "SUBMIT" ? "Submitting Form..." : "Saving Form...");

                // Disable Save, Cancel and Submit Buttons
                setDisableActions(true);
                //set personal details object to send
                let personalDetailsForm = {
                    appliedForSchemeEarlier: values.appliedForSchemeEarlier,
                    NoOfAttempts: values.NoOfAttempts,
                    studentName: {
                        firstName: values.firstName,
                        middleName: values.middleName,
                        lastName: values.lastName
                    },
                    courseDetails: {
                        courseType: values.courseType,
                        stream: values.stream,
                        specialization: values.specialization
                    },
                    address: {
                        houseNumber: values.houseNumber,
                        street: values.street,
                        landmark: values.landmark,
                        area: values.area,
                        district: values.district,
                        state: values.state,
                        pincode: values.pincode
                    },
                    personalContactNumber: {
                        mobileNumber: {
                            extension: values.extension,
                            number: values.number
                        },
                        alternateMobileNumber: {
                            extension: values.altExtension,
                            number: values.altNumber
                        }
                    },
                    gender: values.gender,
                    category: values.category,
                    subCaste: values.subCaste,
                    dateOfBirth: {
                        day: values.dateOfIssue.getDate(),
                        month: values.dateOfIssue.getMonth() + 1,
                        year: values.dateOfIssue.getFullYear(),
                        age: {
                            year: Number(values.age.years),
                            month: Number(values.age.months),
                            day: Number(values.age.days),
                        }
                    },
                    personalEmail: {
                        email: values.email,
                        alternateEmail: values.alternateEmail
                    },
                    aadharNumber: values.aadharNumber,
                    panCardNumber: values.panCardNumber,
                    isMaharashtraResident: values.isMaharashtraResident,
                    // educationList: collegeDetails,
                    employmentDetails: {
                        isEmployed: values.isEmployed,
                        noObjectionCertificate: values.noObjectionCertificate,
                        details: {
                            job: values.job,
                            jobVerification: values.jobVerification,
                            padnam: values.padnam,
                            annualIncome: values.annualIncome,
                            startDate: values.jobStartDate,
                            endDate: values.jobEndDate,
                        },
                        address: {
                            houseNumber: values.officeHouseNumber,
                            street: values.officeStreet,
                            landmark: values.officeLandmark,
                            area: values.officeArea,
                            district: values.officeDistrict,
                            state: values.officeState,
                            pincode: values.officePincode
                        },
                        officeContactNumber: {
                            mobileNumber: {
                                extension: values.officeExtension,
                                number: values.officeNumber
                            },
                            alternateMobileNumber: {
                                extension: values.officeAltExtension,
                                number: values.officeAltNumber
                            }
                        },
                        officeEmail: {
                            email: values.officeEmail,
                            alternateEmail: values.officeAlternateEmail,
                        },
                    },
                    familyDetails: {
                        maritalStatus: values.maritalStatus,
                        willYouTakeYourPartnerWithYou: (values.maritalStatus === "MARRIED") ? values.spouseTakeAbroad : false,
                        marriedToName: (values.maritalStatus !== "SINGLE") ? values.marriedToName : "",
                        offsprings: (values.maritalStatus !== "SINGLE") ? offSpringDetails : ""
                    },
                    bankDetails: {
                        accountHolderName: values.accountHolderName,
                        bankName: values.bankName,
                        branchName: values.branchName,
                        accountNumber: values.accountNumber,
                        ifscCode: values.ifscCode,
                        micrCode: values.micrCode
                    },
                    passportDetails: {
                        passportNumber: values.passportNumber,
                        dateOfIssue: {
                            day: values.dateOfIssue.getDate(),
                            month: values.dateOfIssue.getMonth() + 1,
                            year: values.dateOfIssue.getFullYear()
                        },
                        dateOfExpiry: {
                            day: values.dateOfExpiry.getDate(),
                            month: values.dateOfExpiry.getMonth() + 1,
                            year: values.dateOfExpiry.getFullYear()
                        }
                    },
                    collegeDetails: {
                        offerLetterDate: {
                            day: values.offerLetterDate.getDate(),
                            month: values.offerLetterDate.getMonth() + 1,
                            year: values.offerLetterDate.getFullYear()
                        },
                        isOfferLetterConditional: values.isOfferLetterConditional,
                        offerLetterCondition: values.offerLetterCondition,
                        coursesInOfferLetter: values.coursesInOfferLetter,
                        offerLetterNumber: values.offerLetterNumber
                    },
                    previousScholarshipDetails: {
                        hasAvailedScholarshipSchemeBefore: values.hasAvailedScholarshipSchemeBefore,
                        scholarshipSchemeAvailDate: {
                            day: values.scholarshipSchemeAvailDate.getDate(),
                            month: values.scholarshipSchemeAvailDate.getMonth() + 1,
                            year: values.scholarshipSchemeAvailDate.getFullYear()
                        },
                        collegeName: values.collegeName,
                        country: values.country,
                        courseName: values.courseName,
                        periodOfCourse: values.periodOfCourse,
                        receivedScholarshipAmount: Number(values.receivedScholarshipAmount)
                    }
                }

                let data = {
                    applicationId: applicationId,
                    uploadType: "SAVE",
                    employmentCertificate: "employmentCertificate",
                    dataToSend: JSON.stringify(personalDetailsForm)
                }

                // Sending data to server
                let sendPersonalDetailsFormResponse = await applicationCommunication.uploadPersonalDetailsForm(jobVerificationUrl, data);
                // Handling response from the server
                if (sendPersonalDetailsFormResponse.status === "FORM_SAVED_SUCCESSFULLY" || sendPersonalDetailsFormResponse.data.status === "FORM_SAVED_SUCCESSFULLY") {
                    if (saveType === "SAVE") {
                    } else {
                        history.push(`/user/application/parent-details?applicationId=${applicationId}`);
                    }
                }

                if (sendPersonalDetailsFormResponse.data.status === "VALIDATION_FAILED" || sendPersonalDetailsFormResponse.status === "VALIDATION_FAILED") {
                    console.log("Validattion");
                    showModal(sendPersonalDetailsFormResponse.data.validationErrorsList[0].message || sendPersonalDetailsFormResponse.validationErrorsList[0].message)
                    hideLoading();
                    setDisableActions(false);
                    return false;
                }


                showModal(sendPersonalDetailsFormResponse.message || sendPersonalDetailsFormResponse.data.message);

                // if (sendPersonalDetailsFormResponse.status === "VALIDATION_FAILED") {
                //     showModal(sendPersonalDetailsFormResponse.validationErrorsList[0].message);
                // }
                // showModal(sendPersonalDetailsFormResponse.message);

                hideLoading();
                setDisableActions(false);
                return false;

            } catch (error) {
                showModal(error.message);
                hideLoading();
            }
        },
        validationSchema: validationSchema,
    });

    // function calculateAge(date) {
    //     setFieldValue("age", new Date().getFullYear() - date.getFullYear());
    // }

    function calculateAge(dateString) {
        const fromDate = process.env.REACT_APP_DATE_AGE_CALCULATE;
        var now = new Date(fromDate);

        var yearNow = now.getYear();
        var monthNow = now.getMonth();
        var dateNow = now.getDate();

        var dob = new Date(dateString.getFullYear(),
            dateString.getMonth(),
            dateString.getDate()
        );

        var yearDob = dob.getYear();
        var monthDob = dob.getMonth();
        var dateDob = dob.getDate();
        var age = {};


        var monthAge;
        var dateAge;

        let yearAge = yearNow - yearDob;

        if (monthNow >= monthDob) {
            monthAge = monthNow - monthDob;
        }
        else {
            yearAge--;
            monthAge = 12 + monthNow - monthDob;
        }

        if (dateNow >= dateDob) {
            dateAge = dateNow - dateDob;
        }
        else {
            monthAge--;
            dateAge = 31 + dateNow - dateDob;

            if (monthAge < 0) {
                monthAge = 11;
                yearAge--;
            }
        }

        age = {
            years: yearAge,
            months: monthAge,
            days: dateAge
        };
        setFieldValue("age", age);
    }





    //disabling fields on marital status
    function setMaritalStatus(e) {
        setMaritalStatusValue(val => val = e.target.value);
        if (e.target.value === "SINGLE" || e.target.value === "Select") {
            setMaritalStatusData(val => val = false);
            setPartnerTake(val => val = false);

        } else if (e.target.value === "MARRIED") {
            setIfMarried(val => val = false);
            setMaritalStatusData(val => val = true);
            setPartnerTake(val => val = true);
        }
        else {
            setMaritalStatusData(val => val = true);
            setIfMarried(val => val = true);
            setPartnerTake(val => val = false);

        }
    }


    return (
        <section className="application_flow_big">
            <div className="personal_details_main  ">
                <div className="container">
                    {isLoading.value ?
                        <LoadingForms message={isLoading.message} />
                        :
                        <div className="row ">
                            <div className="col-12">
                                <form onSubmit={handleSubmit}>
                                    {/* Student Details Field */}
                                    <div className="row">
                                        <div className="col-lg-2 col-md-2 col-0"></div>
                                        <div className="col-10">
                                            <FormFieldTitle name="Have you ever applied for this scheme earlier?" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-2 col-md-2 col-0"></div>
                                        <div className="col-lg-4 col-md-4 col-8">
                                            <div className="row mt-3">
                                                <div className="col-6 col-lg-6 col-md-4 mt-md-3 mt-lg-0"> 
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                    <input type="radio" onChange={() => { setFieldValue("appliedForSchemeEarlier", true) }} checked={values.appliedForSchemeEarlier === null ? (values.appliedForSchemeEarlier) : Boolean(values.appliedForSchemeEarlier)} value="true" name="appliedForSchemeEarlierRadio" id="appliedForSchemeEarlierTrue" className={`form-check-input mx-2 ${errors.appliedForSchemeEarlier ? "is-invalid" : ""}`} disabled={disabled} />
                                                </div>

                                                <div className="col-6 col-lg-6 col-md-4 mt-md-3 mt-lg-0">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                    <input type="radio" onChange={() => { setFieldValue("appliedForSchemeEarlier", false) }} checked={values.appliedForSchemeEarlier === null ? (values.appliedForSchemeEarlier) : Boolean(!values.appliedForSchemeEarlier)} value="false" name="appliedForSchemeEarlierRadio" id="appliedForSchemeEarlierFalse" className={`form-check-input mx-2 ${errors.appliedForSchemeEarlier ? "is-invalid" : ""}`} disabled={disabled} />
                                                </div>
                                                <div className="invalid-feedback">{errors.appliedForSchemeEarlier}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {values.appliedForSchemeEarlier ?
                                        <div className="row mt-3 mb-3">
                                            <div className="col-lg-2 col-md-2">
                                                <FormFieldTitle name="Number of Attempts" />
                                            </div>
                                            <div className="col-lg-10 col-md-10">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4">
                                                        <input type="number" name="NoOfAttempts" onChange={handleChange} value={values.NoOfAttempts} className={`form-control ${errors.NoOfAttempts ? "is-invalid" : ""}`} placeholder="E.g. 1 " disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.NoOfAttempts}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : <span></span>
                                    }
                                    <div className="row mt-2">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Student Name*" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">First Name</Label>
                                                    <input type="text" name="firstName" onChange={handleChange} value={values.firstName} className={`form-control ${errors.firstName ? "is-invalid" : ""}`} placeholder="E.g. Ram " disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.firstName}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Middle Name</Label>
                                                    <input type="text" name="middleName" onChange={handleChange} value={values.middleName} className={`form-control ${errors.middleName ? "is-invalid" : ""}`} placeholder="E.g. Prasad " disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.middleName}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Last Name</Label>
                                                    <input type="text" name="lastName" onChange={handleChange} value={values.lastName} className={`form-control ${errors.lastName ? "is-invalid" : ""}`} placeholder="E.g. Srivastava" disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.lastName}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {/* Course Details Field */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Course Details *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">PG/PhD</Label>
                                                    <select name="courseType" onChange={handleChange} value={values.courseType} className={`form-select ${errors.courseType ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                        <option value="">Select</option>
                                                        {courseTypeArray.map((courseTypeList, index) => {


                                                            return (

                                                                <option key={index} >{courseTypeList?.courseTypeName}</option>

                                                            );
                                                        })}
                                                    </select>
                                                    <div className="invalid-feedback">{errors.courseType}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Stream</Label>
                                                    <select name="stream" onChange={handleChange} value={values.stream} className={`form-select ${errors.stream ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                        <option value="">Select</option>
                                                        <option value="ART">Art</option>
                                                        <option value="COMMERCE">Commerce</option>
                                                        <option value="SCIENCE">Science</option>
                                                        <option value="ENGINEERING">Engineering</option>
                                                        <option value="MANAGEMENT">Management</option>
                                                        <option value="MEDICAL">Medical</option>
                                                        <option value="LAW">Law</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.stream}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Specialization</Label>
                                                    <input type="text" name="specialization" onChange={handleChange} value={values.specialization} className={`form-control ${errors.specialization ? "is-invalid" : ""}`} placeholder="E.g. MBBS" disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.specialization}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {/* Address Details Field */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Address *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">House Number</Label>
                                                    <input type="text" name="houseNumber" placeholder="E.g. 81" onChange={handleChange} value={values.houseNumber} className={`form-control ${errors.houseNumber ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.houseNumber}</div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Street</Label>
                                                    <input type="text" name="street" onChange={handleChange} placeholder="E.g. C.A. Road times square" value={values.street} className={`form-control ${errors.street ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.street}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 col-md-2">
                                        </div>

                                        <div className="col-lg-10 col-md-10 mt-3">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Land Mark</Label>
                                                    <input type="text" name="landmark" placeholder="E.g. Near Public school " onChange={handleChange} value={values.landmark} className={`form-control ${errors.landmark ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.landmark}</div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Area</Label>
                                                    <input type="text" name="area" placeholder="E.g. Juhu" onChange={handleChange} value={values.area} className={`form-control ${errors.area ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.area}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 col-md-2">
                                        </div>

                                        <div className="col-lg-10 col-md-10 mt-3">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">State</Label>
                                                    <select name="state" onChange={(event) => { handleChange(event); selectState(event.target.value); }} value={values.state} className={`form-select ${errors.state ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                        <option value=" ">Select</option>
                                                        {StateAndDistrictList?.map((stateList, index) => {
                                                            const { state } = stateList;
                                                            return (
                                                                <option key={index}>{state}</option>
                                                            );
                                                        })}
                                                    </select>
                                                    <div className="invalid-feedback">{errors.state}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">District</Label>
                                                    <select name="district" onChange={handleChange} value={values.district} className={`form-select ${errors.district ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                        <option value=" ">Select</option>
                                                        {districtList.map((districtName, index) => {
                                                            return (
                                                                <option key={index}>{districtName}</option>
                                                            );

                                                        })}

                                                    </select>
                                                    <div className="invalid-feedback">{errors.district}</div>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Pin Code</Label>
                                                    <input type="number" name="pincode" placeholder="E.g. 441201" onChange={handleChange} value={values.pincode} className={`form-control ${errors.pincode ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.pincode}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {/* Contact Number Details Field */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Contact Number *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">

                                                <div className="col-lg-6 col-md-6">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-4">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="extension">Code</Label >
                                                            <div className="code_extension">
                                                                <span>+</span>
                                                                <input type="number" name="extension" value={values.extension} onChange={handleChange} className={`form-control ${errors.extension ? "is-invalid" : ""}`} disabled />
                                                                <div className="invalid-feedback">{errors.extension}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8 col-md-8 col-8">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Mobile Number</Label >
                                                            <input type="number" name="number" placeholder="E.g. 8660308288" value={values.number} onChange={handleChange} className={`form-control ${errors.number ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.number}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 mt-lg-0 mt-md-0 mt-sm-2">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-4">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="extension">Code</Label >
                                                            <div className="code_extension">
                                                                <span>+</span>
                                                                <div>
                                                                    <input type="number" name="altExtension" placeholder="E.g. +91/0432" value={values.altExtension} onChange={handleChange} className={`form-control ${errors.altExtension ? "is-invalid" : ""}`} disabled={disabled} />
                                                                    <div className="invalid-feedback">{errors.altExtension}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8 col-md-8 col-8">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Alternate Mobile Number</Label>
                                                            <input type="number" name="altNumber" placeholder="E.g. 727662" value={values.altNumber} onChange={handleChange} className={`form-control ${errors.altNumber ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                            <div className="invalid-feedback">{errors.altNumber}</div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                    {/* Gender Details Field */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Gender *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-7">
                                                    <label></label>
                                                    <select name="gender" onChange={handleChange} value={values.gender} className={`form-select ${errors.gender ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                        <option value=" ">Select</option>
                                                        <option value="MALE">Male</option>
                                                        <option value="FEMALE">Female</option>
                                                        <option value="OTHER">Other</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.gender}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {/* Caste Details Field */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Caste *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Category</Label>
                                                    <input type="text" name="category" value={values.category} onChange={handleChange} className="form-control" disabled />
                                                    {/* <select name="category" onChange={handleChange} value={values.category} className={`form-select ${errors.category ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                        <option value=" ">Select</option>
                                                        <option>SC</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.category}</div> */}
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Sub Caste</Label>
                                                    <select name="subCaste" onChange={(e)=>handleChange(e)} value={values.subCaste} className={`form-select ${errors.subCaste ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                        <option value=" ">Select</option>

                                                        
                                                        {subCasteNameList.map((subCast, index) => {
                                                            return (

                                                                <option key={index}>{subCast}</option>

                                                            );

                                                        })}
                                                    </select>
                                                    <div className="invalid-feedback">{errors.subCaste}</div>
                                                    {/*<input type="text" name="subCaste" placeholder="E.g. Mahar" value={values.subCaste} onChange={handleChange} className={`form-control ${errors.subCaste ? "is-invalid" : ""}`} disabled={disabled} />
                                                */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {/* Date of Birth Details Field */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Date of Birth *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">DD/MM/YYYY</Label>
                                                    <DatePicker selected={(values.dateOfBirth && new Date(values.dateOfBirth))} onChange={(date) => { setFieldValue("dateOfBirth", date); calculateAge(date) }} name="dateOfBirth" dateFormat="dd/MM/yyyy" value={values.dateOfBirth} className={`form-control ${errors.dateOfBirth ? "is-invalid" : ""}`} disabled={disabled} translate="no" />
                                                    <div className="invalid-feedback">{errors.dateOfBirth}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name={`Age on ${ageOnDate} *`} />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-1 col-md-2 col-3">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Years</Label>
                                                    <input type="number" name="age" value={values?.age?.years} onChange={handleChange} className={`form-control ${errors.age ? "is-invalid" : ""}`} disabled />
                                                    <div className="invalid-feedback">{errors.age}</div>
                                                </div>
                                                <div className="col-lg-1 col-md-2 col-3">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Months</Label>
                                                    <input type="number" name="age" value={values?.age?.months} onChange={handleChange} className={`form-control ${errors.age ? "is-invalid" : ""}`} disabled />
                                                    <div className="invalid-feedback">{errors.age}</div>
                                                </div>
                                                <div className="col-lg-1 col-md-2 col-3">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">days</Label>
                                                    <input type="number" name="age" value={values?.age?.days} onChange={handleChange} className={`form-control ${errors.age ? "is-invalid" : ""}`} disabled />
                                                    <div className="invalid-feedback">{errors.age}</div>
                                                </div>

                                            </div>
                                        </div>


                                    </div>

                                    {/* Email Address Details Field */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Email Address *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Primary Email</Label>
                                                    <input type="text" name="email" placeholder="E.g. abcd@gmail.com" value={values.email} onChange={handleChange} className={`form-control ${errors.email ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.email}</div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Alternate Email</Label>
                                                    <input type="text" name="alternateEmail" placeholder="E.g. xyz@gmail.com" value={values.alternateEmail} onChange={handleChange} className={`form-control ${errors.alternateEmail ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.alternateEmail}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {/* Aadhar card verification Details Field */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Aadhar Number *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">

                                                <div className="col-lg-6 col-md-6">
                                                    <div className="row">
                                                        <div className="col-lg-8 col-md-8 col-7">
                                                            {/* <label>Maximum 3 Attempts</label> */}
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Aadhar Card Number</Label>
                                                            <input type="number" name="aadharNumber" placeholder="E.g. 789034562134" value={values.aadharNumber} onChange={handleChange} className={`form-control ${errors.aadharNumber ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.aadharNumber}</div>
                                                        </div>
                                                        {/* {disabled === false ?
                                                            <div className="col-lg-4 col-md-4 col-5">
                                                                <label></label>
                                                                <button className="btn mt-4" > Send OTP</button>
                                                            </div>
                                                            : null
                                                        } */}
                                                    </div>
                                                </div>
                                                {/* {disabled === false ?
                                                    <div className="col-lg-6 col-md-6 mt-lg-0 mt-md-0 mt-sm-2">
                                                        <div className="row">
                                                            <div className="col-lg-7 col-md-7 col-7">
                                                                <label>Enter OTP</label>
                                                                <input className="form-control" disabled={disabled}>
                                                                </input>
                                                            </div>
                                                            <div className="col-lg-5 col-md-5 col-5">
                                                                <label></label>
                                                                <button className="btn mt-4" >Verify</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                                } */}


                                            </div>
                                        </div>

                                    </div>

                                    {/* Pancard Details Field */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="PAN Card *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">PAN Card Number</Label>
                                                    <input type="text" name="panCardNumber" placeholder="E.g. CMFPJ8296C" value={values.panCardNumber} onChange={handleChange} className={`form-control ${errors.panCardNumber ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                    <div className="invalid-feedback">{errors.panCardNumber}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {/* Resident of maharashtra check box */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Are you the resident of Maharashtra?" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row mt-4 mx-1">
                                                <div className="col-lg-4 col-md-8">
                                                    <div className="row mt-1">
                                                        <div className="col-5">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                            <input onChange={() => setFieldValue("isMaharashtraResident", true)} id="isMaharashtraResidentTrue" checked={values.isMaharashtraResident === null ? (values.isMaharashtraResident) : Boolean(values.isMaharashtraResident)} type="radio" value="true" name="isMaharashtraResident" className={`form-check-input mx-3 ${errors.isMaharashtraResident ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.isMaharashtraResident?.message}</div>
                                                        </div>
                                                        <div className="col-5">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                            <input onChange={() => setFieldValue("isMaharashtraResident", false)} id="isMaharashtraResidentFalse" checked={values.isMaharashtraResident === null ? (values.isMaharashtraResident) : Boolean(!values.isMaharashtraResident)} type="radio" value="false" name="isMaharashtraResident" className={`form-check-input mx-3 ${errors.isMaharashtraResident ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.isMaharashtraResident?.message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Educational Details */}
                                    {/* {
                                        isSubmitted ?
                                            <div></div>
                                            :
                                            <ExamDetailsTable examDetailsData={(examDetailsDataValues) => {
                                                setCollegeDetails(value => { return [...value, { ...examDetailsDataValues }] });
                                            }} />
                                    } */}

                                    {/* {collegeDetails.map((collegeDetail, index) => {
                                        return (
                                            <div className="row" key={index}>
                                                <div className="col-lg-1 col-md-2 col-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Sr. No.</Label>
                                                    <input type="number" name="examName" value={index + 1} className="form-control text-center" disabled={collegeDetailDisable} />
                                                </div>
                                                <div className="col-lg-2 col-md-4 table_row">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Exam Name</Label>
                                                    <input type="text" name="examName" value={collegeDetail.examName} className="form-control text-center" disabled={collegeDetailDisable} />
                                                </div>
                                                <div className="col-lg-2 col-md-6 table_row">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">College Name</Label>
                                                    <input type="text" name="collegeName" value={collegeDetail.collegeName} className="form-control text-center" disabled={collegeDetailDisable} />
                                                </div>
                                                <div className="col-lg-1 col-md-6 table_row">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Year</Label>
                                                    <input type="number" name="passingYear" value={collegeDetail.passingYear} className="form-control text-center" disabled={collegeDetailDisable} />
                                                </div>
                                                <div className="col-lg-2 col-md-6 table_row">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Passed Exam Name</Label>
                                                    <input type="text" name="passedExamName" value={collegeDetail.passedExamName} className="form-control text-center" disabled={collegeDetailDisable} />
                                                </div>
                                                <div className="col-lg-1 col-md-3 table_row">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Total</Label>
                                                    <input type="number" name="totalNumber" value={collegeDetail.totalNumber} className="form-control text-center" disabled={collegeDetailDisable} />
                                                </div>
                                                <div className="col-lg-1 col-md-3 p-lg-0 table_row">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Obtained</Label>
                                                    <input type="number" name="totalMarks" value={collegeDetail.totalMarks} className="form-control text-center" disabled={collegeDetailDisable} />
                                                </div>
                                                <div className="col-lg-1 col-md-3 table_row">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Percentage</Label>
                                                    <input type="number" name="percentage" value={collegeDetail.percentage} className="form-control text-center" disabled={collegeDetailDisable} />
                                                </div>
                                                {isSubmitted ?
                                                    <span></span>
                                                    : <div className="col-lg-1 col-md-3 educationButton trash_btn">
                                                        <button type="button" onClick={() => deleteCollegeDetails(collegeDetail)}><i className="fa fa-trash-o" aria-hidden="true"></i></button>
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })} */}

                                    {/* Business or Employment Details */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Business or Employment Details *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">

                                                <div className="col-lg-4 col-md-8">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Are you Employed?</Label>
                                                    <div className="row mt-1">
                                                        <div className="col-5">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                            <input onChange={() => setFieldValue("isEmployed", true)} checked={values?.isEmployed === null ? (values?.isEmployed) : Boolean(values?.isEmployed)} type="radio" value="true" id="isEmployedTrue" name="isEmployedRadio" className={`form-check-input mx-3 ${errors.isEmployed ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.isEmployed}</div>
                                                        </div>
                                                        <div className="col-5">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                            <input onChange={() => setFieldValue("isEmployed", false)} checked={values?.isEmployed === null ? (values?.isEmployed) : Boolean(!values?.isEmployed)} type="radio" value="false" id="isEmployedFalse" name="isEmployedRadio" className={`form-check-input mx-3 ${errors.isEmployed ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.isEmployed}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {values.isEmployed ?
                                                    <>
                                                        <div className="col-lg-4 col-md-5">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Job Details</Label>
                                                            <input type="text" name="job" onChange={handleChange} value={values.job} className={`form-control ${errors.job ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.job}</div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-5">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Employment Certificate</Label>
                                                            <input type="file" name="jobVerification" onChange={(e) => { onFileSelect(e) }} value={values.jobVerification?.filename} className={`form-control ${errors.jobVerification ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.jobVerification}</div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-4 form_preview_btn">
                                                            <button type="button" onClick={() => { previewDocumentsHandler(typeof values.jobVerification?.fileUrl === "string" ? values.jobVerification?.fileUrl : values.jobVerification) }}>Preview</button>
                                                        </div>
                                                        {isSubmitted ?
                                                            <span></span>
                                                            :
                                                            <div style={{ textAlign: "end" }}>
                                                                <span style={{ fontSize: "11px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                            </div>
                                                        }
                                                    </>

                                                    : null
                                                }

                                            </div>
                                        </div>

                                    </div>


                                    {values.isEmployed ?

                                        <div className="row">

                                            <div className="col-lg-2 col-md-2">

                                            </div>

                                            <div className="col-lg-10 col-md-10">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Designation</Label>
                                                        <input type="text" name="padnam" placeholder="E.g. Clerk" onChange={handleChange} value={values.padnam} className={`form-control ${errors.padnam ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.padnam}</div>
                                                    </div>

                                                    <div className="col-lg-4 col-md-4 col-12">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Annual Income</Label>
                                                        <input type="number" name="annualIncome" placeholder="E.g. 113000" onChange={handleChange} value={values.annualIncome} className={`form-control ${errors.annualIncome ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.annualIncome}</div>
                                                    </div>

                                                    <div className="col-lg-4 col-md-4 col-12">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Start Date</Label>
                                                        <DatePicker selected={(values.jobStartDate && new Date(values.jobStartDate))} onChange={(date) => setFieldValue("jobStartDate", date)} name="jobStartDate" dateFormat="dd/MM/yyyy" value={values.jobStartDate} className={`form-control ${errors.jobStartDate ? "is-invalid" : ""}`} disabled={disabled} translate="no" />
                                                    </div>

                                                    <div className="col-lg-4 col-md-8">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Are you Currently working?</Label>
                                                        <div className="row mt-1">
                                                            <div className="col-5">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                                <input onChange={() => { setFieldValue("isCurrentylWorking", true); setFieldValue("jobEndDate", "present") }} checked={values.isCurrentylWorking === null ? (values.isCurrentylWorking) : Boolean(values.isCurrentylWorking)} type="radio" value="true" id="isCurrentylWorkingTrue" name="isCurrentylWorkingRadio" className={`form-check-input mx-3 ${errors.isCurrentylWorking ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.isCurrentylWorking}</div>
                                                            </div>
                                                            <div className="col-5">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                                <input onChange={() => { setFieldValue("isCurrentylWorking", false); setFieldValue("jobEndDate", "") }} checked={values.isCurrentylWorking === null ? (values.isCurrentylWorking) : Boolean(!values.isCurrentylWorking)} type="radio" value="false" id="isCurrentylWorkingFalse" name="isCurrentylWorkingRadio" className={`form-check-input mx-3 ${errors.isCurrentylWorking ? "is-invalid" : ""}`} disabled={disabled}></input>
                                                                <div className="invalid-feedback">{errors.isCurrentylWorking}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {!(values.isCurrentylWorking) ?
                                                        <>
                                                            <div className="col-lg-4 col-md-4 col-12">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">End Date</Label>
                                                                <DatePicker selected={((values.jobEndDate === "" || values.jobEndDate === "present") ? new Date() : values.jobEndDate)} onChange={(date) => setFieldValue("jobEndDate", date)} name="jobEndDate" dateFormat="dd/MM/yyyy" value={((values.jobEndDate === "" || values.jobEndDate === "present") ? new Date() : values.jobEndDate)} className={`form-control ${errors.jobEndDate ? "is-invalid" : ""}`} disabled={disabled} translate="no" />
                                                            </div>
                                                        </>
                                                        : null
                                                    }

                                                    <div className="col-lg-4 col-md-4 col-12 mt-1">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No Objection Certificate Attached</Label>
                                                        <div className="row mt-1">
                                                            <div className="col-5 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                                <input onChange={() => setFieldValue("noObjectionCertificate", true)} id="noObjectionCertificateTrue" checked={values.noObjectionCertificate === null ? (values.noObjectionCertificate) : Boolean(values.noObjectionCertificate)} type="radio" value="true" name="noObjectionCertificate" className={`form-check-input mx-3 ${errors.noObjectionCertificate ? "is-invalid" : ""}`} disabled={disabled} />
                                                            </div>
                                                            <div className="col-5 col-md-6">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                                <input onChange={() => setFieldValue("noObjectionCertificate", false)} id="noObjectionCertificateFalse" checked={values.noObjectionCertificate === null ? (values.noObjectionCertificate) : Boolean(!values.noObjectionCertificate)} type="radio" value="false" name="noObjectionCertificate" className={`form-check-input mx-3 ${errors.noObjectionCertificate ? "is-invalid" : ""}`} disabled={disabled} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        : null
                                    }

                                    {/* Office address Details Field */}
                                    {values.isEmployed ?
                                        <>

                                            <div className="row mt-3">

                                                <div className="col-lg-2 col-md-2">
                                                    <FormFieldTitle name="Office Address *" />
                                                </div>

                                                <div className="col-lg-10 col-md-10">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">House Number</Label>
                                                            <input type="text" name="officeHouseNumber" placeholder="E.g. 53" onChange={handleChange} value={values.officeHouseNumber} className={`form-control ${errors.officeHouseNumber ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.officeHouseNumber}</div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Street</Label>
                                                            <input type="text" name="officeStreet" placeholder="E.g. Walkar road" onChange={handleChange} value={values.officeStreet} className={`form-control ${errors.officeStreet ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.officeStreet}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2 col-md-2">
                                                </div>

                                                <div className="col-lg-10 col-md-10 mt-3">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Land Mark</Label>
                                                            <input type="text" name="officeLandmark" placeholder="E.g. Infront of RBI" onChange={handleChange} value={values.officeLandmark} className={`form-control ${errors.officeLandmark ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.officeLandmark}</div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Area</Label>
                                                            <input type="text" name="officeArea" placeholder="E.g. Bandra" onChange={handleChange} value={values.officeArea} className={`form-control ${errors.officeArea ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.officeArea}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2 col-md-2">
                                                </div>

                                                <div className="col-lg-10 col-md-10 mt-3">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">State</Label>
                                                            <select name="officeState" onChange={(event) => { handleChange(event); selectState(event.target.value); }} value={values.officeState} className={`form-select ${errors.officeState ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                                <option value=" ">Select</option>
                                                                {StateAndDistrictList?.map((stateList, index) => {
                                                                    const { state } = stateList;
                                                                    return (
                                                                        <option key={index}>{state}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                            <div className="invalid-feedback">{errors.officeState}</div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">District</Label>
                                                            <select name="officeDistrict" onChange={handleChange} value={values.officeDistrict} className={`form-select ${errors.officeDistrict ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                                <option value=" ">Select</option>
                                                                {districtList.map((districtName, index) => {
                                                                    return (
                                                                        <option key={index}>{districtName}</option>
                                                                    );

                                                                })}
                                                            </select>
                                                            <div className="invalid-feedback">{errors.officeDistrict}</div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Pin Code</Label>
                                                            <input type="number" name="officePincode" placeholder="E.g. 440009" onChange={handleChange} value={values.officePincode} className={`form-control ${errors.officePincode ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.officePincode}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            {/* Office Contact Number Details Field */}
                                            <div className="row mt-3">

                                                <div className="col-lg-2 col-md-2">
                                                    <FormFieldTitle name="Office Contact Number *" />
                                                </div>

                                                <div className="col-lg-10 col-md-10">
                                                    <div className="row">

                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-4">
                                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="extension">Code</Label>
                                                                    <div className="code_extension">
                                                                        <span>+</span>
                                                                        <input type="number" name="officeExtension" onChange={handleChange} value={values.officeExtension} className={`form-control ${errors.officeExtension ? "is-invalid" : ""}`} disabled />
                                                                        <div className="invalid-feedback">{errors.officeExtension}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-8">
                                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Mobile Number</Label>
                                                                    <input type="number" name="officeNumber" placeholder="E.g. 7709227762" onChange={handleChange} value={values.officeNumber} className={`form-control ${errors.officeNumber ? "is-invalid" : ""}`} disabled={disabled} />
                                                                    <div className="invalid-feedback">{errors.officeNumber}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-6 mt-lg-0 mt-md-0 mt-sm-2">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-4">
                                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef" className="extension">Code</Label>
                                                                    <div className="code_extension">
                                                                        <span>+</span>
                                                                        <div>
                                                                            <input type="number" name="officeAltExtension" placeholder="E.g. +91/0432" onChange={handleChange} value={values.officeAltExtension} className={`form-control ${errors.officeAltExtension ? "is-invalid" : ""}`} disabled={disabled} />
                                                                            <div className="invalid-feedback">{errors.officeAltExtension}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-8">
                                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Alternate Mobile/Telephone Number</Label>
                                                                    <input type="number" name="officeAltNumber" placeholder="E.g. 7276324124" onChange={handleChange} value={values.officeAltNumber} className={`form-control ${errors.officeAltNumber ? "is-invalid" : ""}`} disabled={disabled} />
                                                                    <div className="invalid-feedback">{errors.officeAltNumber}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>

                                            {/* Office Email Address Details Field */}
                                            <div className="row mt-3">

                                                <div className="col-lg-2 col-md-2">
                                                    <FormFieldTitle name="Email Address *" />
                                                </div>

                                                <div className="col-lg-10 col-md-10">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Primary Email</Label>
                                                            <input type="text" name="officeEmail" placeholder="E.g. abc@gmail.com" onChange={handleChange} value={values.officeEmail} className={`form-control ${errors.officeEmail ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.officeEmail}</div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Alternate Email</Label>
                                                            <input type="text" name="officeAlternateEmail" placeholder="E.g. abcd@gmail.com" onChange={handleChange} value={values.officeAlternateEmail} className={`form-control ${errors.officeAlternateEmail ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.officeAlternateEmail}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                        : null
                                    }


                                    {/* Marital status Details */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Marital Status *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Marital Status</Label>
                                                    <select name="maritalStatus" onChange={(e) => { setMaritalStatus(e); handleChange(e); }} value={values.maritalStatus} className={`form-select ${errors.maritalStatus ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                        <option value="">Select</option>
                                                        <option value="SINGLE">Single</option>
                                                        <option value="MARRIED">Married</option>
                                                        <option value="WIDOWED">Widowed</option>
                                                        <option value="SEPARATED">Separated</option>
                                                        <option value="DIVORCED">Divorced</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.maritalStatus}</div>
                                                </div>
                                                {
                                                    (maritalStatusData === true) ?
                                                        <div className="col-lg-4 col-md-6">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef"> Married Husband or Wife Name</Label>
                                                            <input type="text" placeholder="E.g. John" name="marriedToName" onChange={handleChange} value={values.marriedToName} className={`form-control ${errors.marriedToName ? "is-invalid" : ""}`} disabled={disabled} />
                                                            <div className="invalid-feedback">{errors.marriedToName}</div>
                                                        </div> :
                                                        " "
                                                }

                                            </div>
                                        </div>

                                        {
                                            (partnerTake === true) ?
                                                <>
                                                    <div className="col-lg-2 col-md-2">
                                                        <FormFieldTitle name="Will you take your partner with you? *" />
                                                    </div>

                                                    <div className="col-lg-10 col-md-10">
                                                        <div className="row mt-4 mx-1">
                                                            <div className="col-lg-4 col-md-8">
                                                                <div className="row mt-1">
                                                                    <div className="col-5">
                                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                                        <input onChange={() => setFieldValue("spouseTakeAbroad", true)} id="spouseTakeAbroadTrue" checked={values.spouseTakeAbroad === null ? (values.spouseTakeAbroad) : Boolean(values.spouseTakeAbroad)} type="radio" value="true" name="spouseTakeAbroad" className={`form-check-input mx-3 ${errors.spouseTakeAbroad ? "is-invalid" : ""}`} disabled={disabled} />
                                                                        <div className="invalid-feedback">{errors.spouseTakeAbroad?.message}</div>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                                        <input onChange={() => setFieldValue("spouseTakeAbroad", false)} id="spouseTakeAbroadFalse" checked={values.spouseTakeAbroad === null ? (values.spouseTakeAbroad) : Boolean(!values.spouseTakeAbroad)} type="radio" value="false" name="spouseTakeAbroad" className={`form-check-input mx-3 ${errors.spouseTakeAbroad ? "is-invalid" : ""}`} disabled={disabled} />
                                                                        <div className="invalid-feedback">{errors.spouseTakeAbroad?.message}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </> : null
                                        }


                                        {(maritalStatusData === true) ?
                                            isSubmitted ?
                                                <div></div>
                                                :
                                                <OffSpringChild ifMarried={ifMarried} maritalStatusValue={maritalStatusValue} offSpringData={(offSpringDataValues) => {
                                                    setOffSpringDetails(value => { return [...value, { ...offSpringDataValues }] });
                                                }} />
                                            : " "
                                        }

                                        {offSpringDetails.map((offSpringDetail, index) => {
                                            return (
                                                <div className="row" key={index}>
                                                    <div className="col-lg-2 col-md-2">
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 mt-3">
                                                        <div className="row">
                                                            <div className="col-lg-1 col-md-2">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Sr. No.</Label>
                                                                <input type="number" name="examName" value={index + 1} className="form-control text-center" disabled={offSpringDetailsDisable} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Son/Daughter Name</Label>
                                                                <input type="text" placeholder="E.g. Pooja" name="examName" value={offSpringDetail.name} className="form-control" disabled={offSpringDetailsDisable} />
                                                            </div>
                                                            {maritalStatusValue === "Married" || offSpringDetail.holdingStatus === "" ?
                                                                <div className="col-lg-4 col-md-5"></div>
                                                                : <div className="col-lg-4 col-md-5">
                                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Holding Status</Label>
                                                                    <input type="text" name="examName" value={offSpringDetail.holdingStatus} className="form-control" disabled={offSpringDetailsDisable} />
                                                                </div>
                                                            }
                                                            {isSubmitted ?
                                                                <span></span>
                                                                : <div className="col-lg-1 col-md-1 col-12 educationButton trash_btn">
                                                                    <button type="button" onClick={() => deleteOffSpringDetails(offSpringDetail)}><i className="fa fa-trash-o" aria-hidden="true"></i></button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}


                                    </div>

                                    {/* Bank Details */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Bank Details *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">A/c Holder Name</Label>
                                                    <input type="text" name="accountHolderName" placeholder="E.g. Ram Prasad Srivastava" onChange={handleChange} value={values.accountHolderName} className={`form-control ${errors.accountHolderName ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.accountHolderName}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Bank Name</Label>
                                                    <input type="text" name="bankName" placeholder="E.g. Bank of India" onChange={handleChange} value={values.bankName} className={`form-control ${errors.bankName ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.bankName}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Branch Name</Label>
                                                    <input type="text" name="branchName" placeholder="E.g.Bhiwapur" onChange={handleChange} value={values.branchName} className={`form-control ${errors.branchName ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.branchName}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 col-md-2">

                                        </div>

                                        <div className="col-lg-10 col-md-10 mt-3">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Account Number</Label>
                                                    <input type="text" name="accountNumber" placeholder="E.g. 789654123012547" onChange={handleChange} value={values.accountNumber} className={`form-control ${errors.accountNumber ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.accountNumber}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">IFSC Code</Label>
                                                    <input type="text" name="ifscCode" placeholder="E.g.BKID0008712" onChange={handleChange} value={values.ifscCode} className={`form-control ${errors.ifscCode ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.ifscCode}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">MICR Code</Label>
                                                    <input type="text" name="micrCode" placeholder="E.g. 441201789" onChange={handleChange} value={values.micrCode} className={`form-control ${errors.micrCode ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.micrCode}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {/* Passport Details */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Passport Details *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Passport Number</Label>
                                                    <input type="text" name="passportNumber" placeholder="E.g. D789654152" onChange={handleChange} value={values.passportNumber} className={`form-control ${errors.passportNumber ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.passportNumber}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Date of Issue</Label>
                                                    <DatePicker selected={(values.dateOfIssue && new Date(values.dateOfIssue))} onChange={(date) => setFieldValue("dateOfIssue", date)} name="dateOfIssue" dateFormat="dd/MM/yyyy" value={values.dateOfIssue} className={`form-control ${errors.dateOfIssue ? "is-invalid" : ""}`} disabled={disabled} translate="no" />
                                                    <div className="invalid-feedback">{errors.dateOfIssue}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Date of Expiry</Label>
                                                    <DatePicker selected={(values.dateOfExpiry && new Date(values.dateOfExpiry))} onChange={(date) => setFieldValue("dateOfExpiry", date)} name="dateOfExpiry" dateFormat="dd/MM/yyyy" value={values.dateOfExpiry} className={`form-control ${errors.dateOfExpiry ? "is-invalid" : ""}`} disabled={disabled} translate="no" />
                                                    <div className="invalid-feedback">{errors.dateOfExpiry}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Offer Letter */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="Offer Letter *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Offer Letter Number</Label>
                                                    <input type="text" name="offerLetterNumber" placeholder="E.g.7894" onChange={handleChange} value={values.offerLetterNumber} className={`form-control ${errors.offerLetterNumber ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.offerLetterNumber}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Offer Letter Date</Label>
                                                    <DatePicker selected={(values.offerLetterDate && new Date(values.offerLetterDate))} onChange={(date) => setFieldValue("offerLetterDate", date)} name="offerLetterDate" dateFormat="dd/MM/yyyy" value={values.offerLetterDate} className={`form-control ${errors.offerLetterDate ? "is-invalid" : ""}`} disabled={disabled} translate="no" />
                                                    <div className="invalid-feedback">{errors.offerLetterDate}</div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Courses in Offer Letter</Label>
                                                    <input type="text" name="coursesInOfferLetter" placeholder="E.g. MBBS" onChange={handleChange} value={values.coursesInOfferLetter} className={`form-control ${errors.coursesInOfferLetter ? "is-invalid" : ""}`} disabled={disabled} />
                                                    <div className="invalid-feedback">{errors.coursesInOfferLetter}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 col-md-2">

                                        </div>

                                        <div className="col-lg-10 col-md-10 mt-3">
                                            <div className="row p-0">
                                                <div className="col-lg-5 col-md-8">
                                                    <div className="row mt-4">
                                                        <div className="col-6">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Conditional</Label>
                                                            <input onChange={() => setFieldValue("isOfferLetterConditional", true)} id="isOfferLetterConditionalTrue" checked={values.isOfferLetterConditional === null ? (values.isOfferLetterConditional) : Boolean(values.isOfferLetterConditional)} type="radio" value="true" name="isOfferLetterConditionalRadio" className={`form-check-input mx-3 ${errors.isOfferLetterConditional ? "is-invalid" : ""}`} disabled={disabled} />
                                                        </div>
                                                        <div className="col-6">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Unconditional</Label>
                                                            <input onChange={() => setFieldValue("isOfferLetterConditional", false)} id="isOfferLetterConditionalFalse" checked={values.isOfferLetterConditional === null ? (values.isOfferLetterConditional) : Boolean(!values.isOfferLetterConditional)} type="radio" value="true" name="isOfferLetterConditionalRadio" className={`form-check-input mx-3 ${errors.isOfferLetterConditional ? "is-invalid" : ""}`} disabled={disabled} />
                                                        </div>
                                                    </div>
                                                    <div className="invalid-feedback">{errors.isOfferLetterConditional}</div>
                                                </div>
                                                {values.isOfferLetterConditional ?
                                                    <div className="col-lg-4 col-md-4">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Mention Below the Condition</Label>
                                                        <textarea type="text" name="offerLetterCondition" onChange={handleChange} value={values.offerLetterCondition} className={`form-control ${errors.offerLetterCondition ? "is-invalid" : ""}`} disabled={disabled} > </textarea>
                                                        <div className="invalid-feedback">{errors.offerLetterCondition}</div>
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </div>

                                    </div>

                                    {/* Availed  scholarship program Details */}
                                    <div className="row mt-3">

                                        <div className="col-lg-2 col-md-2">
                                            <FormFieldTitle name="You ever availed State or Central Scholarship Program? *" />
                                        </div>

                                        <div className="col-lg-10 col-md-10">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="row mt-4">
                                                        <div className="col-5">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Yes</Label>
                                                            <input onChange={() => setFieldValue("hasAvailedScholarshipSchemeBefore", true)} id="hasAvailedScholarshipSchemeBeforeTrue" checked={values.hasAvailedScholarshipSchemeBefore === null ? (values.hasAvailedScholarshipSchemeBefore) : Boolean(values.hasAvailedScholarshipSchemeBefore)} type="radio" value="true" name="hasAvailedScholarshipSchemeBeforeRadio" className={`form-check-input mx-3 ${errors.hasAvailedScholarshipSchemeBefore ? "is-invalid" : ""}`} disabled={disabled} />
                                                        </div>
                                                        <div className="col-5">
                                                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">No</Label>
                                                            <input onChange={() => setFieldValue("hasAvailedScholarshipSchemeBefore", false)} id="hasAvailedScholarshipSchemeBeforeFalse" checked={values.hasAvailedScholarshipSchemeBefore === null ? (values.hasAvailedScholarshipSchemeBefore) : Boolean(!values.hasAvailedScholarshipSchemeBefore)} type="radio" value="false" name="hasAvailedScholarshipSchemeBeforeRadio" className={`form-check-input mx-3 ${errors.hasAvailedScholarshipSchemeBefore ? "is-invalid" : ""}`} disabled={disabled} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {values.hasAvailedScholarshipSchemeBefore ?
                                                    <div className="col-lg-4 col-md-4">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Offer Letter Date</Label>
                                                        <DatePicker selected={(values.scholarshipSchemeAvailDate && new Date(values.scholarshipSchemeAvailDate))} onChange={(date) => setFieldValue("scholarshipSchemeAvailDate", date)} name="scholarshipSchemeAvailDate" dateFormat="dd/MM/yyyy" value={values.scholarshipSchemeAvailDate} className={`form-control ${errors.scholarshipSchemeAvailDate ? "is-invalid" : ""}`} disabled={disabled} translate="no" />
                                                        <div className="invalid-feedback">{errors.scholarshipSchemeAvailDate}</div>
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </div>

                                        <div className="col-lg-2 col-md-2">

                                        </div>

                                        {values.hasAvailedScholarshipSchemeBefore ?

                                            <div className="col-lg-10 col-md-10">
                                                <div className="row">
                                                    <div className="col-lg-8 col-md-8">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">College Name</Label>
                                                        <input type="text" name="collegeName" placeholder="Massachusetts Institute of Technology" onChange={handleChange} value={values.collegeName} className={`form-control ${errors.collegeName ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.collegeName}</div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Country Name</Label>
                                                        <select name="country" onChange={handleChange} value={values.country} className={`form-select ${errors.country ? "is-invalid" : ""}`} disabled={disabled} translate="no">
                                                            {countryNameList.map((country, index) => {
                                                                return (

                                                                    <option key={index}>{country.countryName}</option>

                                                                );

                                                            })}

                                                        </select>
                                                        <div className="invalid-feedback">{errors.country}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                        }

                                        <div className="col-lg-2 col-md-2">

                                        </div>

                                        {values.hasAvailedScholarshipSchemeBefore ?

                                            <div className="col-lg-10 col-md-10 mt-3">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Name of the Course</Label>
                                                        <input type="text" name="courseName" placeholder="E.g. Civil Engineering" onChange={handleChange} value={values.courseName} className={`form-control ${errors.courseName ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.courseName}</div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Period of the Course (in years)</Label>
                                                        <input type="text" name="periodOfCourse" placeholder="E.g. 12" onChange={handleChange} value={values.periodOfCourse} className={`form-control ${errors.periodOfCourse ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.periodOfCourse}</div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-8">
                                                        <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Received Scholarship Amount</Label>
                                                        <input type="number" name="receivedScholarshipAmount" placeholder="E.g. 145698" onChange={handleChange} value={values.receivedScholarshipAmount} className={`form-control ${errors.receivedScholarshipAmount ? "is-invalid" : ""}`} disabled={disabled} />
                                                        <div className="invalid-feedback">{errors.receivedScholarshipAmount}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                        }

                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form_button">
                                                {splitUrlArray[4] === "user" ?
                                                    < button type="button" onClick={() => { history.push("/user"); dispatch({ type: "SHOW_APPLICATION_PROCESS_NAV" }); }} className="btn form_cancel_btn" disabled={disableActions}>Cancel</button>
                                                    : <span></span>
                                                }
                                                {
                                                    isSubmitted ?
                                                        <div className="m-1"></div>
                                                        :
                                                        <button type="button" onClick={() => {
                                                            setSaveType(value => value = "SAVE");
                                                            handleSubmit();
                                                        }} className="btn form_save-btn" disabled={disableActions}>Save</button>
                                                }
                                                {
                                                    isSubmitted ?
                                                        (splitUrlArray[4] === "user") ?
                                                            <button type="button" onClick={() => {
                                                                loadNextForm();
                                                            }} className="btn form_submit_btn">Next</button>
                                                            : <span></span>

                                                        :
                                                        <button type="button" onClick={() => {
                                                            setSaveType(value => value = "SAVE_AND_NEXT");
                                                            handleSubmit();
                                                            console.log("inside error", errors);
                                                        }} className="btn form_submit_btn" disabled={disableActions}>Save & Next</button>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    }
                </div>
            </div >
        </section >
    )
}

export default PersonalDetails;