import React, { useState, useEffect } from "react";
import StatisticMobile from "./StatisticMobile";
import DownloadsInfoMobile from "./DownloadsInfoMobile";
import EligibleCriteriaMobile from "./EligibleCriteriaMobile";
import ApplicationGuidelineMobile from "./EligibleCriteriaMobile";
import ApplicationFlowMobile from "./ApplicationFlowMobile";
import DepartmentHierarchyMobile from "./DepartmentHierarchyMobile";
import { communication } from "../communications/communication";

const MobileMenu = () => {
    const [showEligibleCriteria, setShowEligibleCriteria] = useState("");
    const [ShowMobileMenu, setShowMobileMenu] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const [graphData, setGraphData] = useState([]);

    useEffect(async () => {
        const results = await communication.getGraphData();
        if (results.status === "SUCCESS") {
            setGraphData(results.graph);
        }
    }, []);

    return (
        <>
            <div className="mobile_menu_main">
                <i onClick={() => setShowMobileMenu(true)} className="fa fa-bars hamburger" aria-hidden="true"></i>
                {
                    ShowMobileMenu ? <div className="mobile_menu_list">
                        <i onClick={() => setShowMobileMenu(false)} className="fa fa-times-circle-o close_btn" aria-hidden="true"></i>
                        <ul>
                            <li onClick={() => setSelectedItem(value => value = "STATISTICS")}>Statistics
                                <div className="mobile_tab_info_box" style={{ margin: "10px" }}>{selectedItem === "STATISTICS" ? <StatisticMobile graph={graphData} /> : null}
                                </div>
                            </li>
                            <li onClick={() => setSelectedItem(value => value = "ELIGIBILITY_CRITERIA")}>Eligible Criteria
                                <div className="mobile_tab_info_box" style={{ margin: "10px" }}>{selectedItem === "ELIGIBILITY_CRITERIA" ? <EligibleCriteriaMobile /> : null}
                                </div>
                            </li>
                            <li onClick={() => setSelectedItem(value => value = "APPLICATION_GUIDELINE")}>Application Guideline
                                <div className="mobile_tab_info_box" style={{ margin: "10px" }}>{selectedItem === "APPLICATION_GUIDELINE" ? <ApplicationGuidelineMobile /> : null}</div>
                            </li>
                            <li onClick={() => setSelectedItem(value => value = "DOWNLOADS_INFO")}>Download
                                <div className="mobile_tab_info_box" style={{ margin: "10px" }}>{selectedItem === "DOWNLOADS_INFO" ? <DownloadsInfoMobile /> : null}</div>
                            </li>
                            <li onClick={() => setSelectedItem(value => value = "APPLICATION_FLOW")}>Application Flow
                                <div className="mobile_tab_info_box" style={{ margin: "10px" }}>{selectedItem === "APPLICATION_FLOW" ? <ApplicationFlowMobile /> : null}</div>
                            </li>
                            <li onClick={() => setSelectedItem(value => value = "DEPARTMENT_HIERARCHY")}>Department Hierarchy
                                <div className="mobile_tab_info_box" style={{ margin: "10px" }}>{selectedItem === "DEPARTMENT_HIERARCHY" ? <DepartmentHierarchyMobile /> : null}</div>
                            </li>
                        </ul>
                    </div> : null
                }
            </div>
        </>
    );
}
export default MobileMenu;