import React from 'react';
import { useState } from "react";
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);



const YearlyCourseCompletion = ({ graph }) => {

	const state = graph?.data?.state;


	return (

		<>
			<div className='amount_disbursed'>
				{/* options={state.options} */}
				<Bar data={state?.data}
					options={
					{
						responsive: true,
						radius: 3,
						hitRadius: 3,
						hoverRadius: 5,
						plugins: {
							title: {
								display: true,
								text: 'Yearly Course Completion'
							},
						},
						interaction: {
							intersect: false
						},
						scales: {
							x: {

								display: true,
								ticks: {
									display: true
								},
								title: {
									display: true,
									text: 'Course',
								}

							},
							y: {

								display: true,
								ticks: {
									display: true
								},
								title: {
									display: true,
									text: 'Applicants'

								}
							}
						}
					}}
				/>
			</div>

		</>

	)
}

export default YearlyCourseCompletion;