import React from "react";
import H4 from '../text-components/H4';


const FormFieldTitle = (props) => {
return(
<div className="mt-2">
<H4 whiteThemeColor="#0d2750" darkThemeColor="#FFFFFF">{props.name}</H4>
</div>

);
}
export default FormFieldTitle;

