import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import H5 from "../../text-components/H5";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";


const CommitteeMaintenanceView = () => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const history = new useHistory();
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [allCommitteeMaintenanceData, setAllCommitteeMaintenanceData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchCommitteeListBySearch(searchValue, selectedPage + 1);
        } else {
            newGetData(selectedPage + 1);
        }
    }

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchCommitteeListBySearch(searchString, currentPage + 1);
        } else {
            const committeesFromServer = await adminCommunication.getAllCommittee();
            if (committeesFromServer.status === "SUCCESS") {
                let committeesArray = committeesFromServer.committees;
                setAllCommitteeMaintenanceData(committeesArray);
            } else {
                setAllCommitteeMaintenanceData([]);
            }
        }
    }
    //fetch committee list by search
    const fetchCommitteeListBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await adminCommunication.getCommittieesBySearch(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setAllCommitteeMaintenanceData(responseFromServer?.data?.committees);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                history.push("/admin/login");
                showModal(responseFromServer?.data?.message);
            } else {
                setAllCommitteeMaintenanceData([]);
                setPageCount(0);
            }
        } catch (error) { 
            showModal(error?.message);
        }
    }
    //  old method
    async function oldGetData() {
        showLoading("Loading...");

        dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "COMMITTEE_MAINTENANCE_VIEW" });

        let roleIdArray = [];
        let userIdArray = [];

        const committeesFromServer = await adminCommunication.getAllCommittee();
        if (committeesFromServer.status === "SUCCESS") {
            let committeesArray = committeesFromServer.committees;

            // storing roleId in roleIdArray and userId in userIdArray by iterating committeesArray
            for (let i = 0; i < committeesArray.length; i++) {
                roleIdArray.push(committeesArray[i].roleId);
                for (let j = 0; j < committeesArray[i].members.length; j++) {
                    userIdArray.push(committeesArray[i].members[j].userId);
                }
            }

            //  getting role name through roleId from api and pushing the role object into roleData array
            let roleData = [];
            roleIdArray.forEach((roleId) => {
                const roleFromServer = adminCommunication.getRoleById(roleId);
                roleData.push(roleFromServer);
            });

            const roleResult = await Promise.all(roleData);

            // pushing role name and roleId to rolesNameArray from roleResult
            let rolesNameArray = []

            for (let i = 0; i < roleResult.length; i++) {
                if (i < roleResult.length) {
                    let id = roleResult[i].role._id;
                    let name = roleResult[i].role.name
                    rolesNameArray.push({ id, name });
                }
                else {
                    break;
                }
            }


            // getting user name through userId from api and pushing the user object into userData array
            let userData = [];
            userIdArray.forEach((userId) => {
                const userFromServer = adminCommunication.getUserById(userId);
                userData.push(userFromServer);
            });

            let userResult = await Promise.all(userData);

            // pushing username and userId to userNameArray from userResult
            let userNameArray = [];
            for (let i = 0; i < userResult.length; i++) {
                if (i < userResult.length) {
                    let id = userResult[i].user.userId;
                    let name = userResult[i].user.fullName;
                    userNameArray.push({ id, name });
                }
                else {
                    break;
                }
            }



            // map role names to role ids and attach then to committee object
            committeesArray.forEach((committee) => {
                rolesNameArray.forEach((role) => {
                    if (committee.roleId === role.id) {
                        committee.roleName = role.name;
                    }
                });
            });

            // map user id to user names and attach in members object for that particular user
            committeesArray.forEach((committee) => {
                committee.members.forEach((member) => {
                    userNameArray.forEach((user) => {
                        if (member.userId === user.id) {
                            member.memberName = user.name;
                        }
                    });
                });
            });

            setAllCommitteeMaintenanceData(value => {
                return [...value, ...committeesArray]
            });

        } else {
            showModal(committeesFromServer.message);
        }

        hideLoading();
    }

    // new get data
    async function newGetData(page) {

        showLoading("Loading...");

        dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "COMMITTEE_MAINTENANCE_VIEW" });

        let roleIdArray = [];
        let userIdArray = [];

        const committeesFromServer = await adminCommunication.getAllCommittee(page);
        if (committeesFromServer.status === "SUCCESS") {
            let committeesArray = committeesFromServer.committees;
            setAllCommitteeMaintenanceData(committeesArray);
            setPageCount(committeesFromServer?.totalPages);
        } else {
            showModal(committeesFromServer.message);
            setAllCommitteeMaintenanceData([]);
            setPageCount(0);
        }

        hideLoading();
    }

    useEffect(() => {
        newGetData();
    }, []);


    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }


    return (
        <>
            <section className="dashboard_background" >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :

                    <div className="dashbord_main">
                        <div className="row search_wrapper">
                            <div className="col-lg-4 col-md-4 ps-0">
                                <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Committee, Role..." />
                            </div>
                            <div className="col-lg-8 col-md-8 pagination_outer_wrapper">
                                <div className="pagination_inner_wrapper">
                                    {pageCount > 1 &&
                                        <ReactPaginate
                                            previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            forcePage={currentPage}
                                            activeClassName={"active"} />
                                    }
                                </div>
                            </div>
                        </div>
                        {/*-----Grievance card header section---*/}
                        <div className="dashboard_view_data_heading">
                            <div className="row m-0">
                                <div className="col-1 p-0 view_dashboard">
                                    <span><H5>Serial No.</H5></span>
                                </div>
                                <div className="col-3 p-0 view_dashboard">
                                    <span><H5>Committee Name</H5></span>
                                </div>
                                <div className="col-3 p-0 view_dashboard">
                                    <span><H5>Role</H5></span>
                                </div>
                                <div className="col-5 p-0 ">
                                    <span><H5>Members</H5></span>
                                </div>
                            </div>
                        </div>

                        {allCommitteeMaintenanceData?.length > 0 ?
                            <div>

                                {/*-----Grievance card data section---*/}
                                {allCommitteeMaintenanceData.map((committee, index) => {

                                    const { name, roleId, members, roleName } = committee;

                                    return (
                                        <div key={index}>
                                            <div className="dashboard_view_data_main">
                                                <div className="dashboard_view_data">
                                                    <div className="row m-0">
                                                        <div className="col-1 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {limit * currentPage + (index + 1)}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-3 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {name}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-3 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {roleName}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-5 p-0">
                                                            {
                                                                members.map((member, index, arr) => {
                                                                    const { fullName: memberName } = member;
                                                                    return (
                                                                        <span key={index}>
                                                                            <div className="arrayFetch">
                                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                                    {index != (arr.length - 1) ? `${memberName},` : memberName}
                                                                                </H5>
                                                                            </div>
                                                                        </span>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    );
                                })
                                }
                            </div>
                            :
                            <div className="col-12 text-center view_download_dashboard">
                                <h6>Records not available.</h6>
                            </div>
                        }



                    </div>
                }
            </section>
        </>

    )
}

export default CommitteeMaintenanceView;