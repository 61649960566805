import React, { useEffect, useState } from 'react';
import LoadingForms from "../../shared-components/LoadingForms";
import { useDispatch } from "react-redux";
import { frontOfficeCommunication } from "../../communications/front-office-communication";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import H6 from '../../text-components/H6';
import H5 from '../../text-components/H5';
import ReactPaginate from "react-paginate";


const ShortListApplication = () => {
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [shortListApplications, setShortListApplications] = useState([]);
    const [filteredApplication, setFilteredApplication] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const history = useHistory();
    const limit = process.env.REACT_APP_PAGE_LIMIT;

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchShortListApplicationsBySearch(searchValue, selectedPage + 1);
        } else {
            initialLoad(selectedPage + 1);
        }
    }
    // Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchShortListApplicationsBySearch(searchString, currentPage + 1)
        } else {
            const accessApplicationFromServer = await frontOfficeCommunication.getShortListApplications();
            if (accessApplicationFromServer?.status === "SUCCESS") {
                setShortListApplications(accessApplicationFromServer?.applications);
                setFilteredApplication(accessApplicationFromServer?.applications);
                setPageCount(accessApplicationFromServer?.totalPages);
            } else if (accessApplicationFromServer?.status === "UNAUTHORIZED_ACCESS") {
                history.push(`/front-office/login`);
            } else {
                setShortListApplications([]);
                setFilteredApplication([]);
                setPageCount(0);
            }
        }
    }

    //get Short List Application By Search
    const fetchShortListApplicationsBySearch = async (searchString, page) => {
        try {

            const accessApplicationFromServer = await frontOfficeCommunication.getShortListApplicationsBySearch(searchString, page);
            if (accessApplicationFromServer?.data?.status === "SUCCESS") {
                setFilteredApplication(accessApplicationFromServer?.data?.applications);
                setShortListApplications(accessApplicationFromServer?.applications);
                setPageCount(accessApplicationFromServer?.data?.totalPages);
            } else if (accessApplicationFromServer?.data?.status === "JWT_INVALID") {
                showModal(accessApplicationFromServer?.data?.message);
                history.push("/front-office/login");
            } else {
                setFilteredApplication([]);
                setShortListApplications([]);
                setPageCount(0);
            }
        } catch (error) {
            showModal(error?.message);
        }
    }


    async function goToApplication(applicationId) {
        showLoading("Loading...");
        const accessApplicationFromServer = await frontOfficeCommunication.accessToApplication(applicationId);
        if (accessApplicationFromServer.status === "APPLICATION_AVAILABLE") {
            history.push(`/front-office/dashboard/application/?applicationId=${applicationId}&action=SHORT_LIST_APPLICATION`);
        }
        hideLoading();
        showModal(accessApplicationFromServer?.message);
    }

    //get Short List Application on Initial Load
        async function initialLoad(page) {
            let fullUrl = window.location.href;
            let splitUrlArray = fullUrl.split('/');
            if (splitUrlArray.includes("short-list-application")) {
                dispatch({ type: "ACTIVE_TAB", payload: "SHORT_LIST_APPLICATION" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_TAB" });
            }
        showLoading("Loading...");
        const applicationFromServer = await frontOfficeCommunication.getShortListApplications(page);
        if (applicationFromServer.status === "SUCCESS") {
            let applicationsArray = applicationFromServer?.applications;
            setShortListApplications(applicationsArray);
            setFilteredApplication(applicationsArray);
            setPageCount(applicationFromServer?.totalPages)
        }
        else if (applicationFromServer.status === "UNAUTHORIZED_ACCESS") {
            history.push(`/front-office/login`);
        } else {
            setShortListApplications([]);
            setFilteredApplication([]);
            setPageCount(0)
        }
        hideLoading();
    }
    useEffect(() => {
        initialLoad();
    }, []);


    function onChangeFilterText(event, searchFor) {

        if (searchFor === "APPLICATION_ID") {
            const filterData = shortListApplications.filter((ele) => ele.shortId.search(event.target.value) === -1 ? false : true);
            setFilteredApplication(filterData);
        }

        if (searchFor === "STATUS") {
            const filterData = shortListApplications.filter((ele) => ele.frontOfficeStatus.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
            setFilteredApplication(filterData);
        }

        if (searchFor === "COURSE") {
            const filterData = shortListApplications.filter((ele) => ele.course.toLowerCase().search(event.target.value.toLowerCase()) === -1 ? false : true);
            setFilteredApplication(filterData);
        }
    }


    // Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }


    return (
        <>
            {/* <div className="row mt-3 container">
                <div className="back_office_search_box">
                    <div className="row">
                        <div className="col-3 search_box">
                        </div>
                        <div className="col-3 search_box">
                            <input type="search" className="form-control" placeholder="Application Id" onChange={(event) => onChangeFilterText(event, "APPLICATION_ID")} />
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <div className="col-3 search_box">
                            <input type="search" className="form-control" placeholder="Course" onChange={(event) => onChangeFilterText(event, "COURSE")} />
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div> */}

            <section className="dashboard_background" >
                <div className='container'>

                    {isLoading.value ?
                        <LoadingForms message={isLoading.message} />
                        :

                        <div className="dashboard_Front_office">
                            <div className="row search_wrapper">
                                <div className="col-lg-4 col-md-4 ps-0">
                                    <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Application ID, University, Rank, Course..." />
                                </div>
                                <div className="col-lg-8 col-md-8 pe-0 pagination_outer_wrapper">
                                    <div className="pagination_inner_wrapper">
                                        {pageCount > 1 &&
                                            <ReactPaginate
                                                previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                                nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                forcePage={currentPage}
                                                activeClassName={"active"} />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/*-----Grievance card header section---*/}
                            <div className="dashboard_view_data_heading">
                                <div className="row m-0">
                                    <div className="col-1 p-0 view_dashboard text_align">
                                        <span><H6>Serial No.</H6></span>
                                    </div>

                                    <div className="col-1 p-0 view_dashboard text_align">
                                        <span><H6>Application ID</H6></span>
                                    </div>

                                    <div className="col-1 p-0 view_dashboard text_align">
                                        <span><H6>University Name</H6></span>
                                    </div>

                                    <div className="col-1 p-0 view_dashboard text_align">
                                        <span><H6>University Rank</H6></span>
                                    </div>

                                    <div className="col-1 p-0 view_dashboard text_align">
                                        <span><H6>Income</H6></span>
                                    </div>

                                    <div className="col-1 p-0 view_dashboard text_align">
                                        <span><H6>Course</H6></span>
                                    </div>

                                    <div className="col-1 p-0 view_dashboard text_align">
                                        <span><H6>Age</H6></span>
                                    </div>

                                    <div className="col-1 p-0 view_dashboard text_align">
                                        <span><H6>Other Scholar</H6></span>
                                    </div>

                                    {/* <div className="col-1 p-0 view_dashboard text_align">
                                        <span><H6>Percentage</H6></span>
                                    </div> */}

                                    <div className="col-1 p-0 view_dashboard text_align">
                                        <span><H6>Attempt</H6></span>
                                    </div>

                                    <div className="col-1 p-0 view_dashboard text_align">
                                        <span><H6>status</H6></span>
                                    </div>
                                    <div className="col-1 p-0 text_align">
                                        <span><H6>Comment</H6></span>
                                    </div>
                                </div>
                            </div>

                            {
                                filteredApplication?.length > 0 ?
                                    <div>
                                        {filteredApplication?.map((application, index) => {
                                            const { age, shortId, applicationId, course, frontOfficeStatus, income, otherScholarship, university, universityRank, attempt, percentage, frontOfficeApplicationComment } = application;
                                            return (
                                                <div key={applicationId}>
                                                    <div className="dashboard_view_data_main" key={shortId}>
                                                        <div className="dashboard_view_data">
                                                            <div className="row m-0">
                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {limit * currentPage + (index + 1)}
                                                                        </H6>
                                                                    </span>
                                                                </div>

                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            <a onClick={() => { goToApplication(applicationId) }}>
                                                                                {shortId}
                                                                            </a>
                                                                        </H6>
                                                                    </span>
                                                                </div>

                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {university}
                                                                        </H6>
                                                                    </span>
                                                                </div>

                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {universityRank}
                                                                        </H6>
                                                                    </span>
                                                                </div>

                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {income}
                                                                        </H6>
                                                                    </span>
                                                                </div>

                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {course}
                                                                        </H6>
                                                                    </span>
                                                                </div>

                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {/* {age} */}
                                                                            {`${age?.year} Y ${age?.month} M ${age?.day} D`}
                                                                        </H6>
                                                                    </span>
                                                                </div>

                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {otherScholarship === false ? "yes" : "No"}
                                                                        </H6>
                                                                    </span>
                                                                </div>

                                                                {/* <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>

                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {percentage}
                                                                        </H6>
                                                                    </span>
                                                                </div> */}

                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {attempt}
                                                                        </H6>
                                                                    </span>
                                                                </div>
                                                                <div className="col-1 p-0 view_dashboard text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {frontOfficeStatus}
                                                                        </H6>
                                                                    </span>
                                                                </div>
                                                                <div className="col-1 p-0 text_align">
                                                                    <span>
                                                                        <H6 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                                            {frontOfficeApplicationComment?.length > 0 ?
                                                                                !!frontOfficeApplicationComment[frontOfficeApplicationComment?.length - 1]
                                                                                    ? frontOfficeApplicationComment[frontOfficeApplicationComment?.length - 1]
                                                                                    : "No Comment"
                                                                                : "No Comment"}
                                                                        </H6>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    :
                                    <div className="text-center mt-5">
                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Records not available</H5>

                                    </div>
                            }

                        </div>
                    }
                </div>

            </section>
        </>

    )
};

export default ShortListApplication;
