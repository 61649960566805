import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import H1 from "../text-components/H1";
import H3 from "../text-components/H3";
import ApplicationProcessNav from "../common/ApplicationProcessNav";
import { communication } from "../communications/communication";

const RegistrationForm = () => {


  //Alert Modal react-redux
  const dispatch = useDispatch();
  function showModal(text) {
    dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
  }

  const [showPassword, setShowPassword] = useState("password");

  const history = useHistory();

  const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
  // spinner on loading 
  const [isLoading, setIsLoading] = useState({ message: "", value: false });

  // cancel, save and submit buttons unable & desable
  const [disableActions, setDisableActions] = useState(false);

  const [regstrationFormSubmission, setRegstrationFormSubmission] = useState({});
  const [country, setCountry] = useState(
    [
      {
        name: "Afghanistan",
        phone: 93
      },
      {
        name: "Aland Islands",
        phone: 358
      },
      {
        name: "Albania",
        phone: 355
      },
      {
        name: "Algeria",
        phone: 213
      },
      {
        name: "American Samoa",
        phone: 1684
      },
      {
        name: "Andorra",
        phone: 376
      },
      {
        name: "Angola",
        phone: 244
      },
      {
        name: "Anguilla",
        phone: 1264
      },
      {
        name: "Antarctica",
        phone: 672
      },
      {
        name: "Antigua and Barbuda",
        phone: 1268
      },
      {
        name: "Argentina",
        phone: 54
      },
      {
        name: "Armenia",
        phone: 374
      },
      {
        name: "Aruba",
        phone: 297
      },
      {
        name: "Australia",
        phone: 61
      },
      {
        name: "Austria",
        phone: 43
      },
      {
        name: "Azerbaijan",
        phone: 994
      },
      {
        name: "Bahamas",
        phone: 1242
      },
      {
        name: "Bahrain",
        phone: 973
      },
      {
        name: "Bangladesh",
        phone: 880
      },
      {
        name: "Barbados",
        phone: 1246
      },
      {
        name: "Belarus",
        phone: 375
      },
      {
        name: "Belgium",
        phone: 32
      },
      {
        name: "Belize",
        phone: 501
      },
      {
        name: "Benin",
        phone: 229
      },
      {
        name: "Bermuda",
        phone: 1441
      },
      {
        name: "Bhutan",
        phone: 975
      },
      {
        name: "Bolivia",
        phone: 591
      },
      {
        name: "Bonaire, Sint Eustatius and Saba",
        phone: 599
      },
      {
        name: "Bosnia and Herzegovina",
        phone: 387
      },
      {
        name: "Botswana",
        phone: 267
      },
      {
        name: "Bouvet Island",
        phone: 55
      },
      {
        name: "Brazil",
        phone: 55
      },
      {
        name: "British Indian Ocean Territory",
        phone: 246
      },
      {
        name: "Brunei Darussalam",
        phone: 673
      },
      {
        name: "Bulgaria",
        phone: 359
      },
      {
        name: "Burkina Faso",
        phone: 226
      },
      {
        name: "Burundi",
        phone: 257
      },
      {
        name: "Cambodia",
        phone: 855
      },
      {
        name: "Cameroon",
        phone: 237
      },
      {
        name: "Canada",
        phone: 1
      },
      {
        name: "Cape Verde",
        phone: 238
      },
      {
        name: "Cayman Islands",
        phone: 1345
      },
      {
        name: "Central African Republic",
        phone: 236
      },
      {
        name: "Chad",
        phone: 235
      },
      {
        name: "Chile",
        phone: 56
      },
      {
        name: "China",
        phone: 86
      },
      {
        name: "Christmas Island",
        phone: 61
      },
      {
        name: "Cocos (Keeling) Islands",
        phone: 672
      },
      {
        name: "Colombia",
        phone: 57
      },
      {
        name: "Comoros",
        phone: 269
      },
      {
        name: "Congo",
        phone: 242
      },
      {
        name: "Congo, Democratic Republic of the Congo",
        phone: 242
      },
      {
        name: "Cook Islands",
        phone: 682
      },
      {
        name: "Costa Rica",
        phone: 506
      },
      {
        name: "Cote D'Ivoire",
        phone: 225
      },
      {
        name: "Croatia",
        phone: 385
      },
      {
        name: "Cuba",
        phone: 53
      },
      {
        name: "Curacao",
        phone: 599
      },
      {
        name: "Cyprus",
        phone: 357
      },
      {
        name: "Czech Republic",
        phone: 420
      },
      {
        name: "Denmark",
        phone: 45
      },
      {
        name: "Djibouti",
        phone: 253
      },
      {
        name: "Dominica",
        phone: 1767
      },
      {
        name: "Dominican Republic",
        phone: 1809
      },
      {
        name: "Ecuador",
        phone: 593
      },
      {
        name: "Egypt",
        phone: 20
      },
      {
        name: "El Salvador",
        phone: 503
      },
      {
        name: "Equatorial Guinea",
        phone: 240
      },
      {
        name: "Eritrea",
        phone: 291
      },
      {
        name: "Estonia",
        phone: 372
      },
      {
        name: "Ethiopia",
        phone: 251
      },
      {
        name: "Falkland Islands (Malvinas)",
        phone: 500
      },
      {
        name: "Faroe Islands",
        phone: 298
      },
      {
        name: "Fiji",
        phone: 679
      },
      {
        name: "Finland",
        phone: 358
      },
      {
        name: "France",
        phone: 33
      },
      {
        name: "French Guiana",
        phone: 594
      },
      {
        name: "French Polynesia",
        phone: 689
      },
      {
        name: "French Southern Territories",
        phone: 262
      },
      {
        name: "Gabon",
        phone: 241
      },
      {
        name: "Gambia",
        phone: 220
      },
      {
        name: "Georgia",
        phone: 995
      },
      {
        name: "Germany",
        phone: 49
      },
      {
        name: "Ghana",
        phone: 233
      },
      {
        name: "Gibraltar",
        phone: 350
      },
      {
        name: "Greece",
        phone: 30
      },
      {
        name: "Greenland",
        phone: 299
      },
      {
        name: "Grenada",
        phone: 1473
      },
      {
        name: "Guadeloupe",
        phone: 590
      },
      {
        name: "Guam",
        phone: 1671
      },
      {
        name: "Guatemala",
        phone: 502
      },
      {
        name: "Guernsey",
        phone: 44
      },
      {
        name: "Guinea",
        phone: 224
      },
      {
        name: "Guinea-Bissau",
        phone: 245
      },
      {
        name: "Guyana",
        phone: 592
      },
      {
        name: "Haiti",
        phone: 509
      },
      {
        name: "Heard Island and Mcdonald Islands",
        phone: 0
      },
      {
        name: "Holy See (Vatican City State)",
        phone: 39
      },
      {
        name: "Honduras",
        phone: 504
      },
      {
        name: "Hong Kong",
        phone: 852
      },
      {
        name: "Hungary",
        phone: 36
      },
      {
        name: "Iceland",
        phone: 354
      },
      {
        name: "India",
        phone: 91
      },
      {
        name: "Indonesia",
        phone: 62
      },
      {
        name: "Iran, Islamic Republic of",
        phone: 98
      },
      {
        name: "Iraq",
        phone: 964
      },
      {
        name: "Ireland",
        phone: 353
      },
      {
        name: "Isle of Man",
        phone: 44
      },
      {
        name: "Israel",
        phone: 972
      },
      {
        name: "Italy",
        phone: 39
      },
      {
        name: "Jamaica",
        phone: 1876
      },
      {
        name: "Japan",
        phone: 81
      },
      {
        name: "Jersey",
        phone: 44
      },
      {
        name: "Jordan",
        phone: 962
      },
      {
        name: "Kazakhstan",
        phone: 7
      },
      {
        name: "Kenya",
        phone: 254
      },
      {
        name: "Kiribati",
        phone: 686
      },
      {
        name: "Korea, Democratic People's Republic of",
        phone: 850
      },
      {
        name: "Korea, Republic of",
        phone: 82
      },
      {
        name: "Kosovo",
        phone: 381
      },
      {
        name: "Kuwait",
        phone: 965
      },
      {
        name: "Kyrgyzstan",
        phone: 996
      },
      {
        name: "Lao People's Democratic Republic",
        phone: 856
      },
      {
        name: "Latvia",
        phone: 371
      },
      {
        name: "Lebanon",
        phone: 961
      },
      {
        name: "Lesotho",
        phone: 266
      },
      {
        name: "Liberia",
        phone: 231
      },
      {
        name: "Libyan Arab Jamahiriya",
        phone: 218
      },
      {
        name: "Liechtenstein",
        phone: 423
      },
      {
        name: "Lithuania",
        phone: 370
      },
      {
        name: "Luxembourg",
        phone: 352
      },
      {
        name: "Macao",
        phone: 853
      },
      {
        name: "Macedonia, the Former Yugoslav Republic of",
        phone: 389
      },
      {
        name: "Madagascar",
        phone: 261
      },
      {
        name: "Malawi",
        phone: 265
      },
      {
        name: "Malaysia",
        phone: 60
      },
      {
        name: "Maldives",
        phone: 960
      },
      {
        name: "Mali",
        phone: 223
      },
      {
        name: "Malta",
        phone: 356
      },
      {
        name: "Marshall Islands",
        phone: 692
      },
      {
        name: "Martinique",
        phone: 596
      },
      {
        name: "Mauritania",
        phone: 222
      },
      {
        name: "Mauritius",
        phone: 230
      },
      {
        name: "Mayotte",
        phone: 269
      },
      {
        name: "Mexico",
        phone: 52
      },
      {
        name: "Micronesia, Federated States of",
        phone: 691
      },
      {
        name: "Moldova, Republic of",
        phone: 373
      },
      {
        name: "Monaco",
        phone: 377
      },
      {
        name: "Mongolia",
        phone: 976
      },
      {
        name: "Montenegro",
        phone: 382
      },
      {
        name: "Montserrat",
        phone: 1664
      },
      {
        name: "Morocco",
        phone: 212
      },
      {
        name: "Mozambique",
        phone: 258
      },
      {
        name: "Myanmar",
        phone: 95
      },
      {
        name: "Namibia",
        phone: 264
      },
      {
        name: "Nauru",
        phone: 674
      },
      {
        name: "Nepal",
        phone: 977
      },
      {
        name: "Netherlands",
        phone: 31
      },
      {
        name: "Netherlands Antilles",
        phone: 599
      },
      {
        name: "New Caledonia",
        phone: 687
      },
      {
        name: "New Zealand",
        phone: 64
      },
      {
        name: "Nicaragua",
        phone: 505
      },
      {
        name: "Niger",
        phone: 227
      },
      {
        name: "Nigeria",
        phone: 234
      },
      {
        name: "Niue",
        phone: 683
      },
      {
        name: "Norfolk Island",
        phone: 672
      },
      {
        name: "Northern Mariana Islands",
        phone: 1670
      },
      {
        name: "Norway",
        phone: 47
      },
      {
        name: "Oman",
        phone: 968
      },
      {
        name: "Pakistan",
        phone: 92
      },
      {
        name: "Palau",
        phone: 680
      },
      {
        name: "Palestinian Territory, Occupied",
        phone: 970
      },
      {
        name: "Panama",
        phone: 507
      },
      {
        name: "Papua New Guinea",
        phone: 675
      },
      {
        name: "Paraguay",
        phone: 595
      },
      {
        name: "Peru",
        phone: 51
      },
      {
        name: "Philippines",
        phone: 63
      },
      {
        name: "Pitcairn",
        phone: 64
      },
      {
        name: "Poland",
        phone: 48
      },
      {
        name: "Portugal",
        phone: 351
      },
      {
        name: "Puerto Rico",
        phone: 1787
      },
      {
        name: "Qatar",
        phone: 974
      },
      {
        name: "Reunion",
        phone: 262
      },
      {
        name: "Romania",
        phone: 40
      },
      {
        name: "Russian Federation",
        phone: 70
      },
      {
        name: "Rwanda",
        phone: 250
      },
      {
        name: "Saint Barthelemy",
        phone: 590
      },
      {
        name: "Saint Helena",
        phone: 290
      },
      {
        name: "Saint Kitts and Nevis",
        phone: 1869
      },
      {
        name: "Saint Lucia",
        phone: 1758
      },
      {
        name: "Saint Martin",
        phone: 590
      },
      {
        name: "Saint Pierre and Miquelon",
        phone: 508
      },
      {
        name: "Saint Vincent and the Grenadines",
        phone: 1784
      },
      {
        name: "Samoa",
        phone: 684
      },
      {
        name: "San Marino",
        phone: 378
      },
      {
        name: "Sao Tome and Principe",
        phone: 239
      },
      {
        name: "Saudi Arabia",
        phone: 966
      },
      {
        name: "Senegal",
        phone: 221
      },
      {
        name: "Serbia",
        phone: 381
      },
      {
        name: "Serbia and Montenegro",
        phone: 381
      },
      {
        name: "Seychelles",
        phone: 248
      },
      {
        name: "Sierra Leone",
        phone: 232
      },
      {
        name: "Singapore",
        phone: 65
      },
      {
        name: "Sint Maarten",
        phone: 1
      },
      {
        name: "Slovakia",
        phone: 421
      },
      {
        name: "Slovenia",
        phone: 386
      },
      {
        name: "Solomon Islands",
        phone: 677
      },
      {
        name: "Somalia",
        phone: 252
      },
      {
        name: "South Africa",
        phone: 27
      },
      {
        name: "South Georgia and the South Sandwich Islands",
        phone: 500
      },
      {
        name: "South Sudan",
        phone: 211
      },
      {
        name: "Spain",
        phone: 34
      },
      {
        name: "Sri Lanka",
        phone: 94
      },
      {
        name: "Sudan",
        phone: 249
      },
      {
        name: "Suriname",
        phone: 597
      },
      {
        name: "Svalbard and Jan Mayen",
        phone: 47
      },
      {
        name: "Swaziland",
        phone: 268
      },
      {
        name: "Sweden",
        phone: 46
      },
      {
        name: "Switzerland",
        phone: 41
      },
      {
        name: "Syrian Arab Republic",
        phone: 963
      },
      {
        name: "Taiwan, Province of China",
        phone: 886
      },
      {
        name: "Tajikistan",
        phone: 992
      },
      {
        name: "Tanzania, United Republic of",
        phone: 255
      },
      {
        name: "Thailand",
        phone: 66
      },
      {
        name: "Timor-Leste",
        phone: 670
      },
      {
        name: "Togo",
        phone: 228
      },
      {
        name: "Tokelau",
        phone: 690
      },
      {
        name: "Tonga",
        phone: 676
      },
      {
        name: "Trinidad and Tobago",
        phone: 1868
      },
      {
        name: "Tunisia",
        phone: 216
      },
      {
        name: "Turkey",
        phone: 90
      },
      {
        name: "Turkmenistan",
        phone: 7370
      },
      {
        name: "Turks and Caicos Islands",
        phone: 1649
      },
      {
        name: "Tuvalu",
        phone: 688
      },
      {
        name: "Uganda",
        phone: 256
      },
      {
        name: "Ukraine",
        phone: 380
      },
      {
        name: "United Arab Emirates",
        phone: 971
      },
      {
        name: "United Kingdom",
        phone: 44
      },
      {
        name: "United States",
        phone: 1
      },
      {
        name: "United States Minor Outlying Islands",
        phone: 1
      },
      {
        name: "Uruguay",
        phone: 598
      },
      {
        name: "Uzbekistan",
        phone: 998
      },
      {
        name: "Vanuatu",
        phone: 678
      },
      {
        name: "Venezuela",
        phone: 58
      },
      {
        name: "Viet Nam",
        phone: 84
      },
      {
        name: "Virgin Islands, British",
        phone: 1284
      },
      {
        name: "Virgin Islands, U.s.",
        phone: 1340
      },
      {
        name: "Wallis and Futuna",
        phone: 681
      },
      {
        name: "Western Sahara",
        phone: 212
      },
      {
        name: "Yemen",
        phone: 967
      },
      {
        name: "Zambia",
        phone: 260
      },
      {
        name: "Zimbabwe",
        phone: 263
      }
    ]
  );


  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    password: Yup.string().matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one lowercase, one uppercase, one number and one special case character").required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match').required("Password is required"),
    email: Yup.string().email().required("Email is required"),
    // country: Yup.string().required("Country is required"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, 'Must be exactly 10 digits')
      .max(10, 'Must be exactly 10 digits'),

  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  // Spinner on loading
  function showLoading(message) {
    setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
  }

  function hideLoading() {
    setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
  }

  const onSubmit = async (data) => {
    showLoading();
    data.phoneNumber = Number(data.phoneNumber);
    setRegstrationFormSubmission((value) => {
      return { ...value, ...{ status: "LOADING" } };
    });

    let regstrationResponse = await communication.registrationUser(data);

    setRegstrationFormSubmission(value => {
      let updatedValues = {};
      updatedValues.status = regstrationResponse.status;
      updatedValues.message = regstrationResponse.message;
      return { ...value, ...updatedValues };
    });

    if (regstrationResponse.status === "USER_REGISTERED_SUCCESSFULLY") {
      history.push('/login');
    } else if (regstrationResponse.status === "USER_ALREADY_REGISTERED") {

    } else if (regstrationResponse.status === "USER_REGISTRATION_FAILED") {

    } else if (regstrationResponse.status === "FAILED") {

    } else if (regstrationResponse.status === "VALIDATION_FAILED") {
      let errors = "";
      regstrationResponse.validationErrorsList.forEach(validationError => {
        errors = errors + `\n` + validationError.message;
      });
      setRegstrationFormSubmission(value => {
        return { ...value, ...{ message: errors } };
      });
    }
    hideLoading();
    showModal(regstrationResponse.message);

  };


  return (

    <div style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
      <section className="Registration_form" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000", border: darkWhiteThemeToggle === "WHITE" ? "0px" : "1px solid #FFFFFF" }}>
          <div><H1 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${30 * fontSizeMultiplier / 100}px` }}>Registration</H1></div>

          {/*----fullname field----*/}
          <div className="mb-3 row">
            <label style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }} className="col-sm-4 col-form-label"><H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">Full Name</H3></label>
            <div className="col-sm-7">
              <input type="text" name="fullName" {...register("fullName")} className={`form-control ${errors.fullName ? "is-invalid" : ""}`} />
              <div className="invalid-feedback">{errors.fullName?.message}</div>
            </div>
          </div>

          {/*----password field----*/}
          <div className="mb-3 row">
            <label style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }} className="col-sm-4 col-form-label"><H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">Password</H3></label>
            <div className="col-sm-7 password_field">
              <input type={showPassword} name="password" {...register("password")} className={`form-control ${errors.password ? "is-invalid" : ""}`} />
              <div className="invalid-feedback">{errors.password?.message}</div>

              {showPassword === "password" ?
                <span onClick={() => setShowPassword("text")}> <i style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000000" : "#FFFFFF" }} className="fa fa-eye-slash btn" aria-hidden="true"></i></span>
                :
                <span className="showPass" onClick={() => setShowPassword("password")}><i style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000000" : "#FFFFFF" }} className="fa fa-eye" aria-hidden="true"></i></span>
              }
            </div>
          </div>

          {/*----Re-password field----*/}
          <div className="mb-3 row">
            <label style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }} className="col-sm-4 col-form-label"><H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">Re-Password</H3></label>
            <div className="col-sm-7">
              <input type="password" name="confirmPassword" {...register("confirmPassword")} className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`} />
              <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
            </div>
          </div>
          {/*----email field----*/}
          <div className="mb-3 row">
            <label style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }} className="col-sm-4 col-form-label"><H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">Email</H3></label>
            <div className="col-sm-7">
              <input type="email" name="email" {...register("email")} className={`form-control ${errors.email ? "is-invalid" : ""}`} />
              <div className="invalid-feedback">{errors.email?.message}</div>
            </div>
          </div>
          {/*----mobile no. field----*/}
       {/*<div className="mb-3 row">
        <label style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }} className="col-sm-4 col-form-label"><H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">Country code</H3></label>
        <div className="col-sm-7">
          <select name="country" {...register("country")} className={`form-select ${errors.country ? "is-invalid" : ""}`}>
            <option value="">Select</option>
            {country.map((countryData, index) => {
              const { name, phone } = countryData;
              return (
                <>
                  <option key={index}>{name} - {phone}</option>
                </>
              );
            }
            )}

          </select>
          <div className="invalid-feedback">{errors.country?.message}</div>
        </div>
          </div> */}
          {/*----mobile no. field----*/}
          <div className="row">
            <label style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }} className="col-sm-4 col-form-label"><H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">Mobile No.</H3></label>
            <div className="col-sm-7">
              <input type="tel" name="phoneNumber" {...register("phoneNumber")} className={`form-control ${errors.phoneNumber ? "is-invalid" : ""}`} pattern="[0-9]{10}" />
              <div className="invalid-feedback">{errors.phoneNumber?.message}</div>
            </div>
          </div>

          <button type="submit" style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }} className="button" disabled={disableActions}>{isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H3>Register</H3>}</button>

          <div className="newreg_or_forgot">
            <button onClick={() => history.push('/login')} style={{ fontSize: `${17 * fontSizeMultiplier / 100}px` }}><H3 whiteThemeColor="#707070" darkThemeColor="#FFFFFF">Already Registered? Login</H3></button>
          </div>
        </form>
      </section>
    </div>

  );
}
export default RegistrationForm;