import React, { useRef } from 'react';
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import H4 from "../../text-components/H4";
import H5 from "../../text-components/H5";
import { useReactToPrint } from 'react-to-print'
import { useDispatch, useSelector } from "react-redux";

const BackOfficeRemittancePrintPreview = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const printDocumentRef = useRef();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const remittanceId = queryParams.get('remittanceId');

    const handlePrint = useReactToPrint({
        content: () => printDocumentRef.current,
    })

    const closePrintPreview = (remittanceId) => {
        history.push(`/back-office/dashboard/remittance-preview?&remittanceId=${remittanceId}`);
    }
    return (
        <div className="back_office_remittance_print_preview_section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div ref={printDocumentRef} className="remittance_print_preview_main">
                            <div className="remittance_print_preview_header">
                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">MT 105</H4>
                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">State Bank of India</H4>
                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">International Banking Division, Pune 411001</H4>
                            </div>
                            <table className="table">
                                <tr>
                                    <td colSpan={2} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> Transmit MSG to </H4></td>
                                    <td colSpan={2} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> Swift Code</H4> </td>
                                    <td colSpan={2} style={{ textAlign: "center", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> </H4> </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> TAN NO</H4></td>
                                    {/* <td><H4> NO</H4></td> */}
                                    <td rowSpan={2} colSpan={3} style={{ textAlign: "center", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4> </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Please Remit By TT</H4></td>
                                </tr>
                                <tr>
                                    <td colSpan={4} style={{ textAlign: "center", border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">(To be filled by the Remitter)</H4></td>
                                </tr>

                                <tr>
                                    <td className="remittance_print_preview_table_left" rowSpan={3} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">30 A</H4></td>
                                    <td className="remittance_print_preview_table_right" rowSpan={3} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Value Date</H4></td>
                                    <td className="remittance_print_preview_table_data" style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Currency</H4></td>
                                    <td><H4></H4></td>
                                </tr>
                                <tr>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Amount in figures</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>
                                <tr>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Amount in words</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>

                                <tr>
                                    <td className="remittance_print_preview_table_left" style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">50</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Applicant's name and Add.</H4></td>
                                    <td colSpan={2} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>

                                <tr>
                                    <td className="remittance_print_preview_table_left" rowSpan={4} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">56A</H4></td>
                                    <td rowSpan={4} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Beneficiary bank to be paid through (intermediary bank) name& Add</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Name</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>

                                <tr>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Address</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>
                                <tr>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Swift code</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>
                                <tr>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Routing/sort code</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>

                                <tr>
                                    <td className="remittance_print_preview_table_left" rowSpan={5} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">57</H4></td>
                                    <td rowSpan={5} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Beneficiary Bank Address Swift code</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Name</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>

                                <tr>
                                    <td className="remittance_print_preview_table_address" style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Address</H4></td>
                                    <td className="remittance_print_preview_table_address" style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>
                                <tr>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Swift code</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>
                                <tr>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Routing/sort code</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>

                                <tr>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">IBAN No.</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>

                                <tr>
                                    <td className="remittance_print_preview_table_left" rowSpan={3} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">59</H4></td>
                                    <td rowSpan={3} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Account No. Beneficiary Name Address</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">A/c No.</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>

                                <tr>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Name</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>
                                <tr>
                                    <td className="remittance_print_preview_table_address" style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Address</H4></td>
                                    <td className="remittance_print_preview_table_address" style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>

                                <tr>
                                    <td className="remittance_print_preview_table_left" rowSpan={2} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">70</H4></td>
                                    <td rowSpan={2} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Remittance information Purpose</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Purpose</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>

                                <tr>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Student</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>

                                <tr>
                                    <td className="remittance_print_preview_table_left" rowSpan={2} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">70 A</H4></td>
                                    <td rowSpan={2} style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Foreign Bank Charges to be borne by</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">1) APPL  2) OUR (Applicant)</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>

                                <tr>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">2) BEN</H4></td>
                                    <td style={{ border: darkWhiteThemeToggle === "WHITE" ? "1px solid #0D2750" : "1px solid #FFFFFF" }}><H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"></H4></td>
                                </tr>

                            </table>

                            <div className="row mt-5 mb-3">
                                <div className="col-12" style={{ display: "flex", justifyContent: "end" }}>
                                    <div className="" style={{ textAlign: "center", marginRight: "30px" }}>
                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Accounts Office (Loan)</H5>
                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Directorate of social Welfare M.S. Pune</H5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back_office_button">
                            <button onClick={handlePrint}>Print</button>
                            <button className="ms-3" onClick={() => { closePrintPreview(remittanceId) }}>Cancel</button>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    );
}
export default BackOfficeRemittancePrintPreview;