import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
// import { useHistory } from "react-router";
import H5 from "../../text-components/H5";
import { confirm } from "react-confirm-box";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";



const NotificationMaintenanceUpdate = () => {
const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
const history = useHistory();
const [isLoading, setIsLoading] = useState({ message: "", value: false });
const [notificationMaintenanceData, setNotificationMaintenanceData] = useState([]);
const [idsOfNotification, setIdsOfNotification] = useState([]);
const [pageCount, setPageCount] = useState(0);
const [currentPage, setCurrentPage] = useState(0);
const [searchValue, setSearchValue] = useState("");
const limit = process.env.REACT_APP_PAGE_LIMIT;



    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    
    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchNotificationsBySearch(searchValue, selectedPage + 1);
        } else {
            fetchAllNotifications(selectedPage + 1);
        }
    }

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchNotificationsBySearch(searchString, currentPage + 1);
        } else {
            const notificationsFromServer = await adminCommunication.getNotifications();
            if (notificationsFromServer.status === "SUCCESS") {
                setNotificationMaintenanceData(notificationsFromServer.notifications);
                setPageCount(notificationsFromServer?.totalPages);
            } else if (notificationsFromServer?.status === "JWT_INVALID") {
                history.push("/admin/login");
                showModal(notificationsFromServer?.message);
            } else {
                showModal(notificationsFromServer.message);
                setNotificationMaintenanceData([]);
                setPageCount(0);
            }
        }
    }

     //get Notifications By Searcg
    const fetchNotificationsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await adminCommunication.getNotificationsBySearch(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setNotificationMaintenanceData(responseFromServer?.data?.notifications);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                history.push("/admin/login");
                showModal(responseFromServer?.data?.message);
            } else {
                setNotificationMaintenanceData([]);
                setPageCount(0);
            }
        } catch (error) {
            showModal(error.message);
        }
    }

//get All Notifications on Initial Load
const fetchAllNotifications = async (page) => {
    try {
        showLoading("Loading...");
        dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "NOTIFICATION_MAINTENANCE_UPDATE" });
        const notificationsFromServer = await adminCommunication.getNotifications(page);
        if (notificationsFromServer.status === "SUCCESS") {
            setNotificationMaintenanceData(notificationsFromServer.notifications);
                setPageCount(notificationsFromServer?.totalPages);
            } else if (notificationsFromServer?.status === "JWT_INVALID") {
                history.push("/admin/login");
                showModal(notificationsFromServer?.message);
            } else {
                showModal(notificationsFromServer.message);
                setNotificationMaintenanceData([]);
                setPageCount(0);
            }
            hideLoading();
    } catch (error) {
        hideLoading(); 
    }
}

useEffect(async () => {
    fetchAllNotifications() 
    }, []);


    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    // set ids in to state
    const handleOnChange = (e) => {
        let checked = e.target.checked;
        let id = e.target.value;
        if (checked) {
            setIdsOfNotification(() => [...idsOfNotification, id]);
        } else {
            // to remove from local storage
            setIdsOfNotification(
                idsOfNotification.filter((Id) => Id !== id)
            );
        }
        return;
    }


    // delete notification by id
    const deleteNotification = async (options) => {
        if (idsOfNotification.length !== 0) {
            const confirmBox = await confirm(`Are you sure , do you want to delete this notification ?`, options);
            if (confirmBox === true) {
                showLoading("Loading ...");
                const deleteNotificationByIdData = await adminCommunication.deleteNotificationById(idsOfNotification);
                if (deleteNotificationByIdData.status === "SUCCESS") {
                    // calling refreshPage function after 4 sec to show model before refreshing the page
                    setTimeout(() => {
                        history.push(`/admin/dashboard/notification-maintenance-view`);
                    }, 1000);
                }
                showModal(deleteNotificationByIdData.message);
            }
        } else {
            showModal("Please select atleast one record !");
        }
        hideLoading();
        return;
    }


    // redirect to notification maintenance save according to notification id
    const notificationEdit = (notificationId) => {
        history.push(`/admin/dashboard/notification-maintenance-save?notificationId=${notificationId}`);
        return;
    }


    return (
        <>
            <section className="dashboard_background_update" >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :

                    <div className="dashbord_main">
                    <div className="row search_wrapper">
                            <div className="col-lg-4 col-md-4 ps-0">
                                <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Portal, Type..." />
                            </div>
                            <div className="col-lg-8 col-md-8 pagination_outer_wrapper">
                                <div className="pagination_inner_wrapper">
                                    {pageCount > 1 &&
                                        <ReactPaginate
                                            previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            forcePage={currentPage}
                                            activeClassName={"active"} />
                                    }
                                </div>
                            </div>
                        </div>
                        {/*-----Grievance card header section---*/}
                        <div className="dashboard_view_data_heading">
                            <div className="row m-0">
                                <div className="col-1 p-0 view_dashboard">
                                    <span><H5>Serial No.</H5></span>
                                </div>
                                <div className="col-4 p-0 view_dashboard">
                                    <span><H5>Portal</H5></span>
                                </div>
                                <div className="col-3 p-0 view_dashboard">
                                    <span><H5>Type</H5></span>
                                </div>
                                <div className="col-2 p-0 view_dashboard">
                                    <span><H5>Start Date</H5></span>
                                </div>
                                <div className="col-2 p-0 ">
                                    <span><H5>End Date</H5></span>
                                </div>
                            </div>
                        </div>

                        {notificationMaintenanceData.length > 0 ?
                            <div>

                                {/*-----Grievance card data section---*/}
                                {notificationMaintenanceData.map((notification, index) => {

                                    const { _id, portal, type, startDate, endDate } = notification;

                                    return (
                                        <div key={index}>
                                            <div className="dashboard_view_data_main">
                                                <div className="dashboard_view_data">
                                                    <div className="row m-0">
                                                        <div className="col-1 p-0 view_dashboard sr-no text_align">
                                                            <span>
                                                                <input type="checkbox" value={_id} onClick={(e) => handleOnChange(e)} />
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                {limit * currentPage + (index + 1)}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-4 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    <a onClick={() => notificationEdit(_id)}>
                                                                        {portal}
                                                                    </a>
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-3 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {type}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-2 p-0 view_dashboard text_align">
                                                            <span>
                                                                {
                                                                    startDate == '-' ? <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                        {startDate}
                                                                    </H5>
                                                                        :
                                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                            {new Date(startDate).getDate()} - {new Date(startDate).getMonth() + 1} - {new Date(startDate).getFullYear()}
                                                                        </H5>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="col-2 p-0 text_align">
                                                            <span>
                                                                {
                                                                    endDate == '-' ? <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" > {endDate}</H5>
                                                                        :
                                                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                            {new Date(endDate).getDate()}-{new Date(endDate).getMonth() + 1}-{new Date(endDate).getFullYear()}
                                                                        </H5>
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    );
                                })}
                            </div>
                            :
                            <div className="col-12 text-center view_download_dashboard">
                                <h6>Records not available.</h6>
                            </div>
                        }

                    </div>
                }
            </section>
            <section className="mb-3">
                <div className="row">
                    <div className="col-2 button_div">
                        <div className="dashboard_delete_button">
                            <button type="button" className="btn" onClick={() => deleteNotification()}>
                                {isLoading.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H5 >Delete</H5>}
                            </button>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default NotificationMaintenanceUpdate;