import React, { useState, useEffect, memo } from "react";
import { useHistory } from "react-router";
import H4 from "../../../text-components/H4";
import { useLocation } from "react-router-dom";
import { backOfficeCommunication } from "../../../communications/back-office-communication";
import LoadingForms from "../../../shared-components/LoadingForms";
import { useDispatch } from "react-redux";
import { removeUnderscoreFromText } from "../../../utlis/helper";
import Label from "../../../text-components/Label";

const UserMilestoneFive = ({ changeHandler }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();


    const [disabled, setDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [applicationId, setApplicationId] = useState("");
    const [milestone, setMileStone] = useState("")
    const [type, setType] = useState("");
    const [getSelect, SetGetSelect] = useState(null);
    const [comment, setComment] = useState("");

    function showDocumentScrutinyUploadedDocumentPreview(applicationId, type, fileUrl) {
        setType(type)
        history.push(`/back-office/dashboard/document-scrutiny/milestone-list/uploaded-documents/preview?milestoneId=${milestone._id.toString()}&applicationId=${applicationId}&fileUrl=${fileUrl}&type=${type}`);
    }
    //close document scrutiny uploaded files window
    function closeDocumentScrutinyUploadedFiles(applicationId, type) {
        setType(type)
        history.push(`/back-office/dashboard/document-scrutiny/milestone-list?milestoneId=${milestone._id.toString()}&applicationId=${applicationId}&type=${type}`);
    }


    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }



    const getComment = (event) => {
        setComment(event.target.value);
    }

    const markFundReceive = async () => {
        try {
            showLoading("Loading...");

            const markFundReceive = await backOfficeCommunication.markFundReceive(milestone._id);

            if (markFundReceive.status === "SUCCESS") {
                await changeHandler();
                history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}&type=${type}`);
            }
            hideLoading();
            showModal(markFundReceive.message);
        } catch (error) {
            showModal(error);
        }

    }

    const requestToTreasury = async () => {
        try {
            showLoading("Loading...");
            const requestToTreasury = await backOfficeCommunication.requestToTreasury(applicationId);
            if (requestToTreasury.status === "SUCCESS") {
                history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}&type=${type}`);
            }
            hideLoading();
            showModal(requestToTreasury.message);
        } catch (error) {
            showModal(error);
        }

    }


    const ReceiptUpload = async () => {
        try {
            showLoading("Loading...");

            if (getSelect === null) {
                return showModal("Please select file");
            }

            const formData = new FormData();
            formData.append("document", getSelect);
            //  TODO VALIDATE FILE HERE
            const uploadBankChalan = await backOfficeCommunication.uploadReceipt(applicationId, formData);

            if (uploadBankChalan?.status === "SUCCESS" || uploadBankChalan?.data?.status === "SUCCESS") {
                // history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}&type=${type}`);
            }
            hideLoading();
            showModal(uploadBankChalan?.message || uploadBankChalan?.data?.message);

        } catch (error) {
            showModal(error?.message);
            hideLoading();
        }

    }

    const markAsIncomplete = async () => {
        try {
            showLoading("Loading...");
            if (comment === "") {
                showModal("Please enter a comment");
                hideLoading();
                return;
            }

            const uploadComment = await backOfficeCommunication.markMilestoneFiveAsIncomplete(milestone._id, milestone.userId, comment);

            if (uploadComment.status === "SUCCESS") {
                history.push(`/back-office/dashboard/document-scrutiny/milestone-list?applicationId=${applicationId}&type=${type}`);
            }
            showModal(uploadComment.message);
            hideLoading();
        } catch (error) {
            showModal(error);
        }

    }

    function selectFile(e) {
        SetGetSelect(e.target.files[0]);
    }

    async function getMilestoneDocument() {
        try {
            // let fullUrl = window.location.href;
            // let splitUrlArray = fullUrl.split('/');
            // if (splitUrlArray.includes("document-scrutiny")) {
            //     dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
            // } else {
            //     dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
            // }

            showLoading("Loading...");

            const queryParams = new URLSearchParams(location.search);
            const applicationId = queryParams.get('applicationId');
            const milestoneId = queryParams.get('milestoneId');
            const type = queryParams.get('type');

            const response = await backOfficeCommunication.getMilestone(milestoneId, applicationId, type);
            if (response.status === "SUCCESS") {

                if (response.milestones[response.milestones.length - 1].backOfficeStatus === "APPROVED") {
                    setDisabled(true);
                } else {
                    setDisabled(false);
                }

                setMileStone(response.milestones[response.milestones.length - 1]);
                setApplicationId(applicationId);
                setType(type);
            } else {
                showModal(response.message);
            }
            hideLoading();

        } catch (error) {
            showModal(error?.message);
            return;
        }
    }

    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');
    let splitUrl = splitUrlArray[splitUrlArray?.length - 1]?.split('?');
    if (splitUrl.includes("milestoneFive")) {
        dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "DOCUMENT_SCRUTINY" });
    } else {
        dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
    }


    useEffect(() => {
        getMilestoneDocument();
        return () => {
            setDisabled(false);
            setApplicationId("");
            setMileStone("");
            setType("");
            SetGetSelect(null);
            setComment("");
            dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
            hideLoading();
        }
    }, []);

    return (
        <section className="back_office_preview_section">
            {isLoading.value ?
                <LoadingForms message={isLoading.message} />
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="back_office_preview_box">
                                <div className="back_office_preview_title">
                                    <H4>{removeUnderscoreFromText(milestone?.name)}</H4>
                                    <div className="close_back_office_preview" onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div className="uploaded_documents">
                                    {/*---heading section---*/}
                                    <div className="row mt-3 ms-4">


                                        <div className="col-10 p-0 ps-4">
                                            <span>
                                                <H4 whiteThemeColor="#6D8DAD" darkThemeColor="#FFFF00">Name</H4>
                                            </span>
                                        </div>
                                    </div>
                                    {/*---uploaded file section---*/}

                                    <div className="row mt-3 ms-4">
                                        {
                                            milestone?.prevDocuments && milestone?.prevDocuments !== "" && milestone?.prevDocuments.length > 0 && milestone?.prevDocuments.map((ele, idx) => {
                                                return (
                                                    <div className="row " key={idx}>
                                                        <div className="col-12 p-0 ps-4">
                                                            <span>
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                                    <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, ele.fileUrl)} title="Click to preview document">
                                                                        {ele?.documentName} <i className="fa fa-paperclip fa-1x ms-1" aria-hidden="true"></i>
                                                                    </a>
                                                                </H4>
                                                            </span>
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }

                                    </div>


                                    <div className="row mt-3 ms-4">
                                        {
                                            milestone?.userFundsReceiptUrl && milestone?.userFundsReceiptUrl !== "" && <div className="row ">
                                                <div className="col-12 p-0 ps-4">
                                                    <span>

                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                            <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.fundsReceivedUrl)} title="Click to preview document">
                                                                User fund Receipt  <i className="fa fa-paperclip fa-1x ms-1" aria-hidden="true"></i>
                                                            </a>
                                                        </H4>
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                        {
                                            milestone?.treasuryConfirmationReceipt && milestone?.treasuryConfirmationReceipt !== "" && <div className="row mt-3">

                                                <div className="col-12 p-0 ps-4">
                                                    <span>
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">
                                                            <a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.treasuryConfirmationReceipt,)} title="Click to preview document">
                                                                Treasury confirmation Receipt <i className="fa fa-paperclip fa-1x ms-1" aria-hidden="true"></i>
                                                            </a>
                                                        </H4>
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                        {/* {
                                    milestone?.expenditureReportUrl && milestone?.expenditureReportUrl !== "" && <div className="row mt-3">

                                        <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                            <span>
                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{1}</P>
                                            </span>
                                        </div>

                                        <div className="col-10 p-0 ps-4">
                                            <span>

                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.expenditureReportUrl)} title="Click to preview document">Receipt Url</a></P>
                                            </span>
                                        </div>

                                    </div>
                                } */}
                                        {
                                            milestone?.semesterFeeAndAcknowledgementCertificateUrl && milestone?.semesterFeeAndAcknowledgementCertificateUrl !== "" && <div className="row mt-3">

                                                <div className="col-2 p-0 view_dashboard" style={{ textAlign: "center" }}>
                                                    <span>
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD">{1}</H4>
                                                    </span>
                                                </div>

                                                <div className="col-10 p-0 ps-4">
                                                    <span>
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#6D8DAD"><a onClick={() => showDocumentScrutinyUploadedDocumentPreview(milestone?.applicationId, milestone?.type, milestone?.semesterFeeAndAcknowledgementCertificateUrl)} title="Click to preview document">Semester Fee and Acknowledgement Certificate</a></H4>
                                                    </span>
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    {/* {disabled !== true ?
                                        <div className="comment_box_main">
                                            <div className="comment_box_heading">
                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Comment Box</H4>
                                            </div>
                                            <div className="comment_box_body">
                                                <div className="comment_to_write_main">
                                                    <div className="comment_to_write">
                                                        <input className="form-control" placeholder="Write comment here..." onChange={getComment} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : <span></span>
                                    } */}
                                </div>
                                <div className="col-lg-6 col-md-12 mt-lg-5 mt-md-5">
                                    {disabled !== true ?
                                        <div className="upload_report_section" style={{ flexDirection: "column" }}>
                                            {
                                                milestone?.treasuryConfirmationReceipt && milestone?.treasuryConfirmationReceipt !== "" ?
                                                    <>
                                                    </>
                                                    :
                                                    <>
                                                        {milestone.backOfficeStatus === "REQUEST_TO_TREASURY" ?
                                                            <>

                                                                <div className="col-12">
                                                                    <span style={{ fontSize: "14px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                                </div>

                                                                <div className="col-lg-12" style={{ display: "flex", alignItems: "center" }}>
                                                                    <input type="file" className="form-control" id="inputGroupFile02" title="upload Receipt" onChange={selectFile} />
                                                                    <button className="ms-3" onClick={ReceiptUpload} disabled={disabled}> Upload </button>
                                                                </div>


                                                            </>
                                                            :
                                                            <span></span>
                                                        }
                                                    </>
                                            }
                                        </div>
                                        : <span></span>
                                    }
                                    <div className="upload_files_btn  mt-3 " style={{ textAlign: "right" }}>
                                        {disabled !== true ?
                                            <div className="home_verification_button mb-3">
                                                {/* <button className="me-3" onClick={() => markAsIncomplete()}>Mark Incomplete</button> */}
                                                {milestone.backOfficeStatus !== "REQUEST_TO_TREASURY" ?
                                                    <button className="mt-3 me-2" onClick={() => requestToTreasury()} disabled={disabled}>Requested To Treasury</button>
                                                    :
                                                    <span></span>
                                                }

                                                {
                                                    milestone.backOfficeStatus === "REQUEST_TO_TREASURY" && milestone?.treasuryConfirmationReceipt && milestone?.treasuryConfirmationReceipt !== "" ?
                                                        <button className="mt-3" onClick={() => markFundReceive()} disabled={disabled}>Fund Received by Treasury</button>
                                                        :
                                                        <span></span>
                                                }
                                            </div>
                                            : <span></span>
                                        }
                                        <button onClick={() => closeDocumentScrutinyUploadedFiles(applicationId, type)}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </section>
    )
}

export default UserMilestoneFive