import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
import H5 from "../../text-components/H5";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";


const UploadDocumentsView = () => {

    const history = new useHistory();
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [documentsMaintenanceData, setDocumentsMaintenanceData] = useState([]);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchUploadedDocumentsBySearch(searchValue, selectedPage + 1);
        } else {
            initialLoad(selectedPage + 1);
        }
    }

    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchUploadedDocumentsBySearch(searchString, currentPage + 1);
        } else {
            const documentsFromServer = await adminCommunication.getUploadDownloadDocuments();
            if (documentsFromServer.status === "SUCCESS") {
                let documentArray = documentsFromServer?.documents;
                setDocumentsMaintenanceData(documentArray);
                setPageCount(documentsFromServer?.totalPages);
            } else if (documentsFromServer?.status === "JWT_INVALID") {
                history.push("/admin/login");
                showModal(documentsFromServer?.message);
            } else {
                setDocumentsMaintenanceData([]);
                setPageCount(0);
            }
        }
    }

    //get Uploaded Documents By Search
    const fetchUploadedDocumentsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await adminCommunication.getUploadedDocumentsBySearch(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setDocumentsMaintenanceData(responseFromServer?.data?.documents);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                history.push("/admin/login");
                showModal(responseFromServer?.data?.message);
            } else {
                setDocumentsMaintenanceData([]);
                setPageCount(0);
            }
        } catch (error) {
            showModal(error?.message);
        }
    }

    //get Uploaded Documents on Initial Load
    async function initialLoad(page) {
        showLoading("Loading...");
        dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "UPLOAD_DOCUMENTS_VIEW" });
        const documentsFromServer = await adminCommunication.getUploadDownloadDocuments(page);
        if (documentsFromServer.status === "SUCCESS") {
            let documentArray = documentsFromServer?.documents;
            setDocumentsMaintenanceData(documentArray);
            setPageCount(documentsFromServer?.totalPages);
        } else if (documentsFromServer?.status === "JWT_INVALID") {
            history.push("/admin/login");
            showModal(documentsFromServer?.message);
        } else {
            showModal(documentsFromServer?.message);
            setDocumentsMaintenanceData([]);
            setPageCount(0);
        }
        hideLoading();
    }

    useEffect(() => {
        initialLoad();
    }, []);

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }


    const downloadNotification = async (id, attachment) => {
        if (attachment !== undefined && attachment !== null && attachment !== "" && attachment !== "-") {
            let uploadedFile = attachment.split("/")[1];
            const getNotificationPdf = await adminCommunication.getUploadedDocumentPdfForAdmin(id);
            // fileDownload(getNotificationPdf, uploadedFile);
            return getNotificationPdf;
        }
    }

    return (
        <>
            <section className="dashboard_background" >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :

                    <div className="dashbord_main">
                        <div className="row search_wrapper">
                            <div className="col-lg-4 col-md-4 ps-0">
                                <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Portal, Subject, Description..." />
                            </div>
                            <div className="col-lg-8 col-md-8 pagination_outer_wrapper">
                                <div className="pagination_outer_wrapper">
                                    {pageCount > 1 &&
                                        <ReactPaginate
                                            previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                            nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            forcePage={currentPage}
                                            activeClassName={"active"} />
                                    }
                                </div>
                            </div>
                        </div>
                        {/*-----Grievance card header section---*/}
                        <div className="dashboard_view_data_heading" >
                            <div className="row m-0">
                                <div className="col-1 p-0 view_dashboard">
                                    <span><H5 >Serial No.</H5></span>
                                </div>
                                <div className="col-2 p-0 view_dashboard">
                                    <span><H5 >Portal</H5></span>
                                </div>
                                <div className="col-2 p-0 view_dashboard">
                                    <span><H5 >Subject</H5></span>
                                </div>
                                <div className="col-3 p-0 view_dashboard">
                                    <span><H5 >Description</H5></span>
                                </div>
                                <div className="col-2 p-0 view_dashboard">
                                    <span><H5 >Attachment</H5></span>
                                </div>
                                <div className="col-2 p-0 ">
                                    <span><H5 >Creation Date</H5></span>
                                </div>
                            </div>
                        </div>

                        {documentsMaintenanceData.length > 0 ?
                            <div>

                                {/*-----Grievance card data section---*/}
                                {documentsMaintenanceData.map((document, index) => {
                                    const { _id, subject, description, createdDate, portal, attachment } = document;
                                    return (
                                        <div key={index}>
                                            <div className="dashboard_view_data_main">
                                                <div className="dashboard_view_data">
                                                    <div className="row m-0">
                                                        <div className="col-1 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {limit * currentPage + (index + 1)}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-2 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {portal}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-2 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {subject}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-3 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {description}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-2 p-0 view_dashboard text_align">
                                                            <span>
                                                                {(!!attachment && attachment !== '-') ?
                                                                    <a onClick={() => downloadNotification(_id, attachment)}>
                                                                        {darkWhiteThemeToggle === "WHITE" ? <i className="fa fa-file-pdf-o" style={{ color: "black" }} aria-hidden="true"></i> : <i className="fa fa-file-pdf-o" style={{ color: "white" }} aria-hidden="true"></i>}
                                                                    </a>
                                                                    :
                                                                    portal
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="col-2 p-0 text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {new Date(createdDate).getDate()}-{new Date(createdDate).getMonth() + 1}-{new Date(createdDate).getFullYear()}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    );
                                })}
                            </div>
                            :
                            <div className="col-12 text-center view_download_dashboard">
                                <h6>Records not available.</h6>
                            </div>
                        }

                    </div>
                }
            </section>
        </>

    )
}

export default UploadDocumentsView;