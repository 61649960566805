import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import Label from "../text-components/Label"
import { applicationCommunication } from "../communications/application-communication";
import FormFieldTitle from "./FormFieldTitle";
import H4 from "../text-components/H4";
import { useFormik } from 'formik';
import { communication } from "../communications/communication"


const ExamDetailsTable = ({ examDetailsData }) => {
    // Dark White Theme
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    //****  Stopping field input- Enable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);

    const [initialFormValues, setInitialFormValues] = useState(null);

    const [applicationId, setApplicationId] = useState(null);

    const [saveType, setSaveType] = useState(null);


    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        examName: "",
        collegeName: "",
        passingYear: "",
        passedExamName: "",
        totalNumber: "",
        totalMarks: "",
    }

    const validationSchema = Yup.object().shape({

        //****Validation for course details ****//
        examName: Yup.string().required("Exam name required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        collegeName: Yup.string().required("College name required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        passingYear: Yup.string().required("Passing year required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(4, 'Minimum 4 digits')
            .max(4, 'Maximum 4 digits'),
        passedExamName: Yup.string().required("Passed exam name required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        totalNumber: Yup.string().required("Number is required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(1, 'Minimum 1 digits')
            .max(4, 'Maximum 4 digits'),
        totalMarks: Yup.string().required("Marks is required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(1, 'Minimum 1 digits')
            .max(4, 'Maximum 4 digits').test("test", "obtained marks exceeds total marks", function (marks) {
                const { totalNumber } = this.parent;
                return Number(marks) <= Number(totalNumber);
            })
    });

    const { handleSubmit, handleChange, setFieldValue, values, errors, handleReset } = useFormik({
        initialValues: initialFormValues === null ? defaultFormValues : initialFormValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {

            //set personal details object to send
            // let percentage = personalDetailsForm.totalMarks / personalDetailsForm.totalNumber * 100;
            let personalDetailsForm = {
                examName: values.examName,
                collegeName: values.collegeName,
                passingYear: values.passingYear,
                passedExamName: values.passedExamName,
                totalNumber: values.totalNumber,
                totalMarks: values.totalMarks,
                percentage: (values.totalMarks / values.totalNumber * 100).toFixed(2)
            };
            examDetailsData(personalDetailsForm);
            handleReset();
            return false;
        },
        validationSchema: validationSchema,
    });

    const formOptions = { resolver: yupResolver(validationSchema) };





    return (

        <div className="row mt-4 mb-1">

            <div className="col-lg-12 col-md-12">
                <H4 whiteThemeColor="#0d2750" darkThemeColor="#FFFFFF">Educational Condition/ Eligibility Information CGPA/GPA Conversion Certification Details:</H4>
                <span><H4 whiteThemeColor="#0d2750" darkThemeColor="#FFFFFF">*If Information is not filled then student is declared as disqualified. </H4></span>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-2 col-md-6 table_row">
                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Exam Name</Label>
                            <input type="text" name="examName" placeholder="E.g. SAT" onChange={handleChange} value={values.examName} className={`form-control ${errors.examName ? "is-invalid" : ""}`} disabled={disabled} />
                            <div className="invalid-feedback">{errors.examName}</div>
                        </div>
                        <div className="col-lg-2 col-md-6 table_row">
                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">College Name</Label>
                            <input type="text" name="collegeName" placeholder="E.g. Fergusson College" onChange={handleChange} value={values.collegeName} className={`form-control ${errors.collegeName ? "is-invalid" : ""}`} disabled={disabled} />
                            <div className="invalid-feedback">{errors.collegeName}</div>
                        </div>
                        <div className="col-lg-2 col-md-6 table_row">
                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Passing Year</Label>
                            <input type="number" name="passingYear" placeholder="E.g. 2021" onChange={handleChange} value={values.passingYear} className={`form-control ${errors.passingYear ? "is-invalid" : ""}`} disabled={disabled} />
                            <div className="invalid-feedback">{errors.passingYear}</div>
                        </div>
                        <div className="col-lg-2 col-md-6 table_row">
                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Passed Exam Name</Label>
                            <input type="text" name="passedExamName" placeholder="E.g. University Exam" onChange={handleChange} value={values.passedExamName} className={`form-control ${errors.passedExamName ? "is-invalid" : ""}`} disabled={disabled} />
                            <div className="invalid-feedback">{errors.passedExamName}</div>
                        </div>
                        <div className="col-lg-2 col-md-3 table_row">
                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Total Marks</Label>
                            <input type="number" name="totalNumber" placeholder="E.g. 600" onChange={handleChange} value={values.totalNumber} className={`form-control ${errors.totalNumber ? "is-invalid" : ""}`} disabled={disabled} />
                            <div className="invalid-feedback">{errors.totalNumber}</div>
                        </div>
                        <div className="col-lg-2 col-md-3 p-lg-0 p-md-0 table_row">
                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Obtained Marks</Label>
                            <input type="number" name="totalMarks" placeholder="E.g. 534" onChange={handleChange} value={values.totalMarks} className={`form-control ${errors.totalMarks ? "is-invalid" : ""}`} disabled={disabled} />
                            <div className="invalid-feedback">{errors.totalMarks}</div>
                        </div>
                        <div className="col-lg-1 col-md-3 educationButton">
                            <button type="button" onClick={() => { handleSubmit(); }}>
                                Add
                            </button>
                        </div>


                    </div>

                </form>



            </div>





        </div>


    )
}
export default ExamDetailsTable;