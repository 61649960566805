import H3 from "../text-components/H3";

const LoadingForms = ({ message }) => {
    return (
        <div className="row m-0">
            <div className="col-12 loading_spinner">
                <H3 whiteThemeColor="#0d2750" darkThemeColor="#FFFFFF">{message}</H3>
                {/* <img src="/assets/images/gif/loading-circle.gif" style={{width:"30px", height:"30px"}} /> */}
                <div className="spinner_circle_main">
                    <div className="inner_spinner"></div>
                </div>
            </div>
        </div>
    );
}

export default LoadingForms;