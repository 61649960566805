import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import Label from "../../text-components/Label"
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import { useFormik } from 'formik';
import { applicationCommunication } from "../../communications/application-communication";


const OffSpringChild = ({ offSpringData, ifMarried, maritalStatusValue }) => {

    //****  Stopping field input- Enable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);

    const [initialFormValues, setInitialFormValues] = useState(null);

    const [applicationId, setApplicationId] = useState(null);

    const [saveType, setSaveType] = useState(null);

    const [validationError, setValidationError] = useState("");

    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        name: "",
        holdingStatus: "",
    }

    const validationSchema = Yup.object().shape({

        //****Validation for course details ****//
        offsprings: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required('Son/Daughter name is required'),
        // holdingStatus: Yup.string().required("Holding status is required"),
    });

    const { handleSubmit, handleChange, setFieldValue, values, errors, handleReset } = useFormik({
        initialValues: initialFormValues === null ? defaultFormValues : initialFormValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {

            if (maritalStatusValue === "MARRIED") {
                setValidationError("");
            } else {

                if (values.holdingStatus === "") {
                    setValidationError("holding status is required");
                    return;
                }
            }


            // display form data on submit
            //set personal details object to send
            let personalDetailsForm = {
                name: values.offsprings,
                holdingStatus: (maritalStatusValue === "MARRIED") ? "" : values.holdingStatus
            };

            offSpringData(personalDetailsForm);
            handleReset();
            setFieldValue("offsprings", "");

            setValidationError("")
            return false;
        },
        validationSchema: validationSchema,
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    return (


        <form onSubmit={handleSubmit} className="mt-3">
            <div className="row p-0">
                <div className="col-lg-2 col-md-2 col-0">
                </div>

                <div className="col-lg-8 col-md-10 col-12 mt-3">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Son/Daughter Name</Label>
                            <input type="text" name="offsprings" onChange={handleChange} value={values.offsprings} className={`form-control ${errors.offsprings ? "is-invalid" : ""}`} disabled={disabled} />
                            <div className="invalid-feedback">{errors.offsprings}</div>
                        </div>
                        {ifMarried === true ?
                            <div className="col-lg-5 col-md-6 col-12">
                                <Label whiteThemeColor="#7e7e7e" darkThemeColor="#efefef">Holding Status</Label>
                                <select name="holdingStatus" onChange={handleChange} value={values.holdingStatus} className="form-select" disabled={disabled} translate="no">
                                    <option value="">Select</option>
                                    <option>Husband</option>
                                    <option>Wife</option>
                                    <option>Guardian</option>
                                </select>
                                <div style={{ color: "red" }}>{validationError}</div>
                            </div>
                            : <span></span>
                        }
                    </div>
                </div>
                <div className="col-lg-1 col-12 educationButton">
                    <button type="button" onClick={() => {
                        handleSubmit()
                    }}>Add</button>
                </div>


            </div>



        </form>


    )
}
export default OffSpringChild;