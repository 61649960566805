import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useFormik } from 'formik';
import * as Yup from "yup";
import H3 from "../text-components/H3";
import H5 from "../text-components/H5";
import P from "../text-components/P";
import { communication } from "../communications/communication";
import LoadingForms from ".././shared-components/LoadingForms";


const GrievanceUserReply = ({ changeHandler }) => {
    const [grievanceData, setGrievanceData] = useState([]);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [fileUrl, setFileUrl] = useState();
    const [showSearchBox, setShowSearchBox] = useState(false);
    const lastElementOfChat = useRef();
    const [filteredData, setFilteredData] = useState([]);


    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    const scrollToLastChild = () => {
        lastElementOfChat?.current?.lastChild?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    async function getTicketDetails() {
        showLoading("Loading...");
        const userTicketDataFromServer = await communication.getAllUserGrievance();
        if (userTicketDataFromServer.status === "SUCCESS") {
            let userTicketData = userTicketDataFromServer?.tickets[userTicketDataFromServer?.tickets?.length - 1];
            setGrievanceData(userTicketData?.chat);
            setFilteredData(userTicketData?.chat);
            hideLoading();
            scrollToLastChild();

        } else {
            hideLoading();
            showModal(userTicketDataFromServer?.message);
        }

    }


    useEffect(() => {
        // to show active tab
        let fullUrl = window.location.href;
        let splitGrievanceUrl = fullUrl.split('/');
        if (splitGrievanceUrl.includes("grievance")) {
            dispatch({ type: "SHOW_ACTIVE_APPLICATION_PROCESS_TAB", payload: "GRIEVANCE" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_APPLICATION_PROCESS_TAB" });
        }
        getTicketDetails();
    }, []);

    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        message: "",
    }

    //****Validation for Designation Create form*****/
    let yupValidationSchema = Yup.object().shape({
        message: Yup.string().required("Comment is required")
    });

    // Destructuring useFormik to get functions to control aspects of form
    // What does Formik do?
    // 1. Handling form events like submit, errors, change in value
    // 2. Fetching values from the form 
    // 3. Support for a validation library on the data fetched. We are using Yup library for validations. 
    // 4. Reset the form
    // 5. Pass initial values to the form
    // 6. Handle errors thrown by the Yup (or any validation library).
    // 7. Reinitialize form values
    // 8. Perform async operations
    const { handleSubmit, handleChange, values, errors, handleReset } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit
        onSubmit: async (values) => {

            try {
                // Show loading 
                showLoading("Loading...");

                // Send values to the server
                let isFileAttached = false;
                let formData = new FormData();
                if (fileUrl) {
                    formData.append("grievanceFile", fileUrl)
                    isFileAttached = true;
                }

                const response = await communication.userCreateGrievance(values?.message, formData, isFileAttached);

                // Handle response from the server
                if (response?.data?.status === "SUCCESS") {
                    hideLoading();
                    // await changeHandler();
                    handleReset();
                    await getTicketDetails();
                    setFileUrl();
                    scrollToLastChild();
                }
                if (response?.data?.status === "VALIDATION_FAILED") {
                    showModal(response?.data?.validationErrorsList[response?.data?.validationErrorsList?.length - 1]?.message)
                } else {
                    showModal(response?.data?.message);
                }
                // Show response message from server in modal dialog
                // Hide spinner 
                hideLoading();
                return;
            }
            catch (err) {
                showModal(err?.message);
                hideLoading();
                return;
            }
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: yupValidationSchema,
    });

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    async function PreviewHandler(fileUrl) {
        await communication.getGrivanceFile(fileUrl);
    }

    function onChangeFilterText(event, searchFor) {

        if (searchFor === "MESSAGE") {
            const filterData = grievanceData?.filter((ele) => ele.message.toLowerCase().search(event.target.value) === -1 ? false : true);
            setFilteredData(filterData);
        }

    }

    console.log("isLoading.value", isLoading.value)

    return (
        <section className="user_grievance_chatbot_section">
            {isLoading.value ?
                <LoadingForms message={isLoading.message} />
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="user_grievance_chatbot_box">
                                <div className="user_grievance_chatbot_title">
                                    <div className="chatbot_searchbox_main">
                                        <H3 whiteThemeColor="#0D2750" darkThemeColor="#0D2750">Grievance</H3>
                                        {showSearchBox &&
                                            <div className="chatbot_search_box">
                                                <input type="text" name="search" onChange={(event) => onChangeFilterText(event, "MESSAGE")} className="form-control" placeholder="Search" />
                                            </div>
                                        }
                                        <div className="chatbot_search_icon">
                                            <i className="fa fa-search" aria-hidden="true" onClick={() => setShowSearchBox(!showSearchBox)}></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="user_grievance_chatbot_comment_main">
                                    <div className="user_grievance_chatbot_comment_section" ref={lastElementOfChat}>
                                        {!!filteredData && filteredData?.map((grievanceMessage, index) => {
                                            const { createdBy, message, date, fileUrl } = grievanceMessage;
                                            const splitUrl = fileUrl?.split(".");
                                            let extension = "";
                                            if (splitUrl) {
                                                extension = splitUrl[splitUrl?.length - 1];
                                            }
                                            return (
                                                <div key={index}>
                                                    {/*----applicant message---*/}
                                                    {createdBy === "BACK_OFFICE" &&
                                                        <div className="row mb-3">
                                                            <div className="col-lg-12">
                                                                <div className="back_office_user_message_main">
                                                                    <div className="user_back_office_detail">
                                                                        <H5 whiteThemeColor="#6D8DAD" darkThemeColor="#6D8DAD">{createdBy}</H5>
                                                                        <P whiteThemeColor="#6D8DAD" darkThemeColor="#6D8DAD">
                                                                            {new Date(date)?.getDate()}-{new Date(date)?.getMonth() + 1}-{new Date(date)?.getFullYear()}
                                                                        </P>
                                                                    </div>
                                                                    <div className="back_office_user_message">
                                                                        <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{message}</P>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {/*----back office user message*/}
                                                    {createdBy === "USER" &&

                                                        <>
                                                            {fileUrl === "" ?
                                                                <div className="row mb-3">
                                                                    <div className="col-lg-12" style={{ display: "flex", justifyContent: "end" }}>
                                                                        <div className="user_message_main">
                                                                            <div className="user_detail">
                                                                                <H5 whiteThemeColor="#6D8DAD" darkThemeColor="#6D8DAD">{createdBy}</H5>
                                                                                <P whiteThemeColor="#6D8DAD" darkThemeColor="#6D8DAD">
                                                                                    {new Date(date).getDate()}-{new Date(date).getMonth() + 1}-{new Date(date).getFullYear()}
                                                                                </P>
                                                                            </div>
                                                                            <div className="user_message">
                                                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{message}</P>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="row mb-3">
                                                                    <div className="col-lg-12" style={{ display: "flex", justifyContent: "end" }}>
                                                                        <div className="attached_dacument_section">
                                                                            <div className="attached_file" onClick={() => PreviewHandler(fileUrl)}>
                                                                                {extension === "pdf" ?
                                                                                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                                                    :
                                                                                    <i className="fa fa-picture-o" style={{ color: "green" }} aria-hidden="true"></i>
                                                                                }
                                                                            </div>
                                                                            <div className="attached_file_with_msg">
                                                                                <P whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">{message}</P>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    }

                                                </div>
                                            );
                                        })}

                                    </div>

                                    <div className="user_chatbot_textarea_main">
                                        <div className="user_chatbot_textarea">
                                            <div className="user_chatbot_text_file">
                                                <textarea placeholder="Type your message here..." name="message" onChange={handleChange} value={values.message} className={`form-control ${errors.message ? "is-invalid" : ""}`} disabled={grievanceData?.userStatus === "CLOSE" ? true : false} />
                                                <div className="invalid-feedback">{errors.message}</div>
                                                <div className="input-group" title="Click to attach file">
                                                    <input type="file" id="file" onChange={(event) => setFileUrl(event.target.files[0])} style={{ display: "none" }} />
                                                    <label htmlFor="file"><i className="fa fa-paperclip fa-1x ms-2" aria-hidden="true"></i></label>
                                                </div>
                                                <div className="attache_file_name">
                                                    <P>{fileUrl?.name}</P>
                                                </div>
                                            </div>
                                            <div className="grievance_button">
                                                <button type="button" onClick={() => { handleSubmit() }} className="button" disabled={grievanceData?.userStatus === "CLOSE" ? true : false}>
                                                    {isLoading?.value ? <div style={{ width: "20px", height: "20px" }} className="spinner_circle_main"><div className="inner_spinner"></div></div> : <H5>Send</H5>}
                                                </button>

                                                <button onClick={() => history.goBack()}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </section >
    );
}
export default GrievanceUserReply;