import React, { useEffect, useState, memo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from "yup";
import H5 from "../../text-components/H5";
import P from "../../text-components/P";
import LoadingForms from "../../shared-components/LoadingForms";
import { backOfficeCommunication } from "../../communications/back-office-communication";
import MulipleDocumentUploadForRemiitance from '../../shared-components/MulipleDocumentUploadForRemiitance';
import { removeUnderscoreFromText } from "../../utlis/helper";



const BackOfficeRemittancePreview = ({ changeHandler }) => {

    const history = useHistory();
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [remittanceDataById, setRemittanceDataById] = useState({});
    const [disableActions, setDisableActions] = useState(false);
    const location = useLocation();
    const [isSubmit, setIsSubmit] = useState("");
    const [isFundTransferred, setIsFundTransFerred] = useState(false);
    const [selectedDocumentArray, setSelectedDocumentArray] = useState([]);
    const [selectedTwoDocumentArray, setSelectedTwoDocumentArray] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);

    //Alert Modal react-redux
    const dispatch = useDispatch();

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //Close back office remittance preview window
    function closeBackOfficeRemittancePreview() {
        history.push("/back-office/dashboard/remittance");
    }

    function showPrintPreview(remittanceId) {
        history.push(`/back-office/dashboard/grievance-print-preview?remittanceId=${remittanceId}`);
    }

    const queryParams = new URLSearchParams(location.search);
    const remittanceId = queryParams.get('remittanceId');


    const goToApplication = async (id, applicationId) => {
        showLoading("Loading...");
        history.push(`/back-office/dashboard/application/?applicationId=${applicationId}&action=REMITTANCE&remittanceId=${id}`);
        hideLoading();
    }


    async function fetchData() {
        // let fullUrl = window.location.href;
        // let splitUrlArray = fullUrl.split('/');
        // if (splitUrlArray.includes("remittance")) {
        //     dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "REMITTANCE" });
        // } else {
        //     dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
        // }
        showLoading("Loading...");
        const remittanceDataByIdFromServer = await backOfficeCommunication.getRemittancesDataById(remittanceId);
        if (remittanceDataByIdFromServer?.status === "SUCCESS") {
            let remittanceIdArray = remittanceDataByIdFromServer?.remittance;
            setRemittanceDataById(remittanceDataByIdFromServer?.remittance);

            if (remittanceIdArray.backOfficeStatus === "FUND_TRANSFERRED") {
                setIsFundTransFerred(true);
            }
            if (["IN_PROGRESS", "FUND_TRANSFERRED", "ACKNOWLEDGED", "PAID"].includes(remittanceIdArray.backOfficeStatus)) {
                setDisableActions(true);
            }
            else {
                setDisableActions(false);
            }
        } else {
            showModal(remittanceDataByIdFromServer?.message);
        }

        hideLoading();
        return;
    }

    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');
    let splitUrl = splitUrlArray[splitUrlArray?.length - 1]?.split('?');
    if (splitUrl.includes("remittance-preview")) {
        dispatch({ type: "ACTIVE_BACK_OFFICE_TAB", payload: "REMITTANCE" });
    } else {
        dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
    }

    useEffect(() => {
        fetchData();
        return () => {
            setRemittanceDataById({});
            setIsSubmit("");
            setIsFundTransFerred(false);
            setSelectedDocumentArray([]);
            setSelectedTwoDocumentArray([]);
            setTotalAmount(0);
            setDisableActions(false);
            dispatch({ type: "HIDE_ACTIVE_SIDEBAR_TAB" });
            hideLoading();
        }
    }, []);



    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }


    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        studentName: '',
        course: '',
        university: '',
        studentAmount: '',
        universityAmount: '',
        amount: '',
        comment: '',
    }

    //****Validation for Designation Create form*****/
    let yupValidationSchema = Yup.object().shape({
        // semester: Yup.string().required("Semester is required"),
        // bankName: Yup.string().required("Bank Name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        // bankAccountNumber: Yup.string().required("Bank Account Number is required"),
        studentAmount: Yup.string().required("Student Amount is required"),
        universityAmount: Yup.string().required("University Amount is required"),
        // amount: Yup.string().required("Amount is required"),
        comment: Yup.string().required("Comment is required"),

    });

    // Destructuring useFormik to get functions to control aspects of form
    // What does Formik do?
    // 1. Handling form events like submit, errors, change in value
    // 2. Fetching values from the form 
    // 3. Support for a validation library on the data fetched. We are using Yup library for validations. 
    // 4. Reset the form
    // 5. Pass initial values to the form
    // 6. Handle errors thrown by the Yup (or any validation library).
    // 7. Reinitialize form values
    // 8. Perform async operations
    const { handleSubmit, handleChange, values, errors, handleReset } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: remittanceDataById === {} ? defaultFormValues : remittanceDataById,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit
        onSubmit: async (values) => {

            // Show loading 
            showLoading("Form Submitting...");
            // Send values to the server
            const remittanceCreate = {
                remittanceId: remittanceId,
                studentName: remittanceDataById?.studentName,
                course: remittanceDataById?.course,
                university: remittanceDataById?.university,
                studentAmount: values?.studentAmount,
                universityAmount: values?.universityAmount,
                amount: totalAmount,
                comment: values?.comment,
                status: isSubmit,
            }

            const remittanceCreationResponse = await backOfficeCommunication.remittanceUpdate(remittanceCreate);
            // Handle response from the server
            if (remittanceCreationResponse?.status === "SUCCESS") {
                handleReset();
                await fetchData();
                // history.push('/back-office/dashboard/remittance');
            }

            // Show response message from server in modal dialog
            showModal(remittanceCreationResponse?.message);

            // Hide spinner 
            hideLoading();
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: yupValidationSchema,
    });

    useEffect(() => {
        const amount = Number(values.studentAmount) + Number(values.universityAmount);
        setTotalAmount((value) => value = amount);
    }, [values]);


    const getUploadedRemittanceDocument = async (filePath) => {
        if (filePath !== "") {
            await backOfficeCommunication.getUploadUserMilestone(filePath);
        }
    }

    // upload bank receipt and signed remittance receipt

    const uploadMultipleBankReceiptAndSignedRemittancereceipt = async () => {
        try {

            if (selectedTwoDocumentArray?.length === 0) {
                showModal("Please upload remittance receipt");
                hideLoading();
                return;
            }
            if (selectedDocumentArray?.length === 0) {
                showModal("Please upload bank receipt");
                hideLoading();
                return;
            }

            const formData = new FormData();

            showLoading("...Loading");

            selectedTwoDocumentArray.forEach(element => {
                formData.append('signedRemittanceReceipt', element?.file, element?.documentName);
            });

            //  add all the selected multiple document to form data

            selectedDocumentArray.forEach(element => {
                formData.append('bankReceipts', element?.file, element?.documentName);
            });

            const uploadBankReceiptResponse = await backOfficeCommunication.uploadBankReceipt(formData, remittanceId, remittanceDataById.applicationId);

            if (uploadBankReceiptResponse?.data?.status === "SUCCESS") {
                await changeHandler();
                showModal(uploadBankReceiptResponse?.data?.message);
                // setSelectedDocumentArray([]);
                // setSelectedTwoDocumentArray([]);
                await fetchData();
            } else {
                showModal(uploadBankReceiptResponse?.data?.message);
                // setSelectedDocumentArray([]);
                // setSelectedTwoDocumentArray([]);
            }
            hideLoading();
            return;
        } catch (error) {
            showModal(error?.message);
            hideLoading();
            return;
        }

    }

    return (
        <section className="remittance_preview_section">
            {isLoading?.value ?
                <LoadingForms message={isLoading?.message} />
                :

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="remittance_preview">
                                <div className="back_office_preview_title" style={{ textAlign: 'left', paddingLeft: "20px" }}>
                                    <P><b>For Milestone: {remittanceDataById?.milestone ? removeUnderscoreFromText(remittanceDataById?.milestone?.name) : ""} </b> </P>
                                    <P><b>Application Id: {remittanceDataById?.shortApplicationId} </b> </P>
                                </div>


                                <div className="back_office_document_preview">
                                    <div className="form">
                                        <div className="row mb-3">
                                            <div className="col-lg-4 pe-0" style={{ display: "flex", alignItems: "center" }}><H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><label>Name :</label></H5></div>
                                            <div className="col-lg-8">
                                                <input type="text" name="studentName" value={remittanceDataById.studentName} className="form-control" disabled />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-lg-4 pe-0" style={{ display: "flex", alignItems: "center" }}><H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><label>Course :</label></H5></div>
                                            <div className="col-lg-8">
                                                <input type="text" value={remittanceDataById.course} className="form-control" disabled />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-lg-4 pe-0" style={{ display: "flex", alignItems: "center" }}><H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><label>University :</label></H5></div>
                                            <div className="col-lg-8">
                                                <input type="text" value={remittanceDataById.university} className="form-control" disabled />
                                            </div>
                                        </div>

                                        <div className="row mb-3">

                                            <div className="col-lg-4 col-md-4 pe-0" style={{ display: "flex", alignItems: "center" }}><H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><label><a >Student Application Form :</a></label></H5></div>
                                            <div className="col-lg-8 col-md-8 pe-0 mt-3"><button onClick={() => { goToApplication(remittanceDataById._id, remittanceDataById.applicationId) }}>Preview</button></div>

                                            <div className="col-lg-4 col-md-4 pe-0 mt-3" style={{ display: "flex", alignItems: "center" }} > <H5 H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><label> <a >Remittance Template: </a></label> </H5></div>
                                            <div className="col-lg-8 col-md-8 pe-0 mt-3"><button onClick={() => showPrintPreview(remittanceDataById._id)}>Preview</button></div>

                                            {
                                                remittanceDataById?.milestoneName === "Milestone Created by user" && <>
                                                    {
                                                        remittanceDataById?.other && remittanceDataById?.other !== "" && remittanceDataById?.other?.length > 0 && remittanceDataById?.other?.map((ele, idx) => {
                                                            return (
                                                                <div className="row pe-0 mt-3" key={idx}>
                                                                    <div className="col-lg-4 col-md-2 "><H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a>{removeUnderscoreFromText(ele?.documentName)}</a></H5></div>
                                                                    <div className="col-lg-8 col-md-4"><button onClick={() => getUploadedRemittanceDocument(ele?.fileUrl)}>Preview</button></div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>
                                            }

                                            {
                                                !remittanceDataById?.prevDocuments ?
                                                    <>
                                                        <div className="col-lg-4 col-md-4 pe-0 mt-3"><H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a>Current Year Annual Expenditure :</a></H5></div>
                                                        <div className="col-lg-8 col-md-8 pe-0 mt-3"><button onClick={() => getUploadedRemittanceDocument(remittanceDataById?.expenditureReportUrl)}>Preview</button></div>
                                                    </>
                                                    :
                                                    ""
                                            }


                                            {/* {
                                                remittanceDataById.prevDocuments && remittanceDataById.prevDocuments !== "" &&
                                                <>
                                                    <div className="col-lg-12 pe-0 mt-3"><H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a>Current year paid remittance Receipt :</a></H5></div>
                                                </>
                                            } */}

                                            {
                                                remittanceDataById?.prevDocuments && remittanceDataById?.prevDocuments !== "" && remittanceDataById?.prevDocuments?.length > 0 && remittanceDataById?.prevDocuments?.map((ele, idx) => {
                                                    return (
                                                        <div className="row pe-0 mt-3" key={idx}>
                                                            <div className="col-lg-4 col-md-2 "><H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><a>{removeUnderscoreFromText(ele?.documentName)}</a></H5></div>
                                                            <div className="col-lg-8 col-md-4 mt-md-3" style={{paddingLeft:"16px"}}><button  onClick={() => getUploadedRemittanceDocument(ele?.fileUrl)}>Preview</button></div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>


                                        <div className="row mb-3">
                                            <div className="col-lg-4 pe-0" style={{ display: "flex", alignItems: "center" }}><H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><label>Student Amount :</label></H5></div>
                                            <div className="col-lg-5 mt-1">
                                                <input type="number" name="studentAmount" onChange={handleChange} value={values?.studentAmount} className={`form-control ${errors?.studentAmount ? "is-invalid" : ""}`} disabled={disableActions} />
                                                <div className="invalid-feedback">{errors?.studentAmount}</div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-lg-4 col-md-2 pe-0" style={{ display: "flex", alignItems: "center" }}><H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><label>University Amount :</label></H5></div>
                                            <div className="col-lg-5  mt-1">
                                                <input type="number" name="universityAmount" onChange={handleChange} value={values?.universityAmount} className={`form-control ${errors?.universityAmount ? "is-invalid" : ""}`} disabled={disableActions} />
                                                <div className="invalid-feedback">{errors?.universityAmount}</div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-lg-4 pe-0" style={{ display: "flex", alignItems: "center" }}><H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><label>Total Amount :</label></H5></div>
                                            <div className="col-lg-5  mt-1">
                                                <input type="number" name="amount" onChange={handleChange} value={totalAmount} className={`form-control ${errors?.amount ? "is-invalid" : ""}`} disabled={disableActions} />
                                                <div className="invalid-feedback">{errors?.amount}</div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-lg-4 pe-0" style={{ display: "flex", alignItems: "center" }}><H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"><label>Comment :</label></H5></div>
                                            <div className="col-lg-8">
                                                <textarea name="comment" value={values?.comment} onChange={handleChange} className={`form-control ${errors?.comment ? "is-invalid" : ""}`} placeholder="Comment..." disabled={disableActions} />
                                                <div className="invalid-feedback">{errors?.comment}</div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-3  col-md-3"> </div>
                                            <div className="col-lg-9 col-md-9 back_office_remittance_preview_btn">
                                                {/* <button className="me-3" >Print Preview</button> */}
                                                {
                                                    ["NEW"].includes(remittanceDataById?.backOfficeStatus) === true &&
                                                    <button type="button" onClick={() => { handleSubmit(); setIsSubmit("IN_PROGRESS") }} className="button btn form_save-btn" disabled={disableActions} >
                                                        Save
                                                    </button>
                                                }
                                            </div>
                                        </div>

                                        <div className="back_office_remittance_receipt_main">

                                            {/*----upload bank receipt----*/}
                                            <MulipleDocumentUploadForRemiitance
                                                title={"Bank Challan :"}
                                                status={remittanceDataById?.backOfficeStatus}
                                                selectedDocumentArray={selectedDocumentArray}
                                                setSelectedDocumentArray={setSelectedDocumentArray}
                                                uploadedDocumentArray={remittanceDataById?.bankReceiptUrl === "" ? [] : remittanceDataById?.bankReceiptUrl}
                                                key={'multiple document array Bank Challan'}
                                            />

                                            {/*----upload fund Transfer receipt----*/}
                                            <MulipleDocumentUploadForRemiitance
                                                title={"Fund Transfer Receipt :"}
                                                status={remittanceDataById?.backOfficeStatus}
                                                selectedDocumentArray={selectedTwoDocumentArray}
                                                setSelectedDocumentArray={setSelectedTwoDocumentArray}
                                                uploadedDocumentArray={remittanceDataById?.remittanceReceiptUrl === "" ? [] : remittanceDataById?.remittanceReceiptUrl}
                                                key={'multiple document array Fund Transfer Receipt'}
                                            />

                                            {
                                                remittanceDataById?.backOfficeStatus === "ACKNOWLEDGED" && <>

                                                    {/* <MulipleDocumentUploadForRemiitance
                                                        title={"User Acknowledge Proof :"}
                                                        status={remittanceDataById?.backOfficeStatus}
                                                        selectedDocumentArray={[]}
                                                        setSelectedDocumentArray={() => { }}
                                                        uploadedDocumentArray={remittanceDataById?.userAckReportUrl}
                                                        key={'User Acknowledge Proof :'}
                                                    /> */}

                                                    <MulipleDocumentUploadForRemiitance
                                                        title={"User Acknowledge Documents :"}
                                                        status={remittanceDataById?.backOfficeStatus}
                                                        selectedDocumentArray={[]}
                                                        setSelectedDocumentArray={() => { }}
                                                        uploadedDocumentArray={remittanceDataById?.userDocumentsUrl}
                                                        key={'User Acknowledge Documents :'}
                                                    />
                                                </>
                                            }

                                            {
                                                ["PAID", "ACKNOWLEDGED"].includes(remittanceDataById?.backOfficeStatus) === false && <div className="row mt-3">
                                                    <>
                                                        <div className="col-lg-12" style={{ textAlign: "right" }}>
                                                            <button type="button" className="button btn form_save-btn ms-3" onClick={uploadMultipleBankReceiptAndSignedRemittancereceipt} disabled={isFundTransferred}>Fund Paid</button>
                                                            <button className="button btn form_save-btn ms-3" onClick={() => closeBackOfficeRemittancePreview()}>Cancel</button>
                                                        </div>
                                                        <div className="mt-3">
                                                            <span style={{ fontSize: "14px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                                                        </div>
                                                    </>
                                                </div>
                                            }

                                            {
                                                ["PAID", "ACKNOWLEDGED"].includes(remittanceDataById?.backOfficeStatus) === true && <div className="row mt-5">
                                                    <div className="col-lg-12" style={{ textAlign: "right" }}>
                                                        <button className="me-4" onClick={() => closeBackOfficeRemittancePreview()}>Cancel</button>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }
        </section>
    );
}
export default memo(BackOfficeRemittancePreview);