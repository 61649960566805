import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
// import H1 from "../text-components/H1";
// import H2 from "../text-components/H2";
// import H3 from "../text-components/H3";
// import H4 from "../text-components/H4";
import H5 from "../text-components/H5";
import { communication } from "../communications/communication";
import fileDownload from 'js-file-download';
import LoadingForms from "../shared-components/LoadingForms";

const HomeScreenNotificationsCard = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);

    const [notificationList, setNotificationList] = useState([]);

    const [isLoading, setIsLoading] = useState({ message: "", value: false });


    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    async function fetchData() {
        showLoading("Loading...");
        const notificationListData = await communication.getAllPublicNotifications();
        if (notificationListData.status === "SUCCESS") {
            let data = notificationListData.notifications;
            setNotificationList((value) => { return [...value, ...data] });
        }
        hideLoading();
    }


    useEffect(async () => {
        fetchData();
    }, []);

    async function getGeneralNotificationPdf(id, attachment, grFileUrl) {
        // if the attachment is empty or not
        if (attachment === "") {
            // let uploadedFile = grFileUrl.split("/")[1];
            const getNotificationPdf = await communication.getNotificationPdf(id);
            // fileDownload(getNotificationPdf, uploadedFile);
            return getNotificationPdf;
        }
        else {
            // let uploadedFile = attachment.split("/")[1];
            const getNotificationPdf = await communication.getNotificationPdf(id);
            // fileDownload(getNotificationPdf, uploadedFile);
            return getNotificationPdf;
        }
    }

    return (
        <div className="homescreen_notification_card" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
            <div className="homescreen_notification_card_header" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#6D8DAD" : "#000000" }}>
                <div className="homescreen_notification_card_title" style={{ fontSize: `${20 * fontSizeMultiplier / 100}px`, color: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#FFFF00" }}>
                    <b>Notification</b>
                </div>
            </div>

            {
                isLoading.value ? <LoadingForms message={isLoading.message} />
                    :
                    <div className="homescreen_notification_card_body">
                        {notificationList.map((notification, index) => {
                            const { _id, subject, attachment, grFileUrl, description } = notification;
                            return (
                                <div key={index}>
                                    <div className='mb-3'>
                                        <div className='row'>

                                            <div className='col-10'>
                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.252" height="16.914" viewBox="0 0 17.252 16.914">
                                                            <g id="Group_18" data-name="Group 18" transform="translate(-291.061 -2128.062)" opacity="0.8">
                                                                <path id="Path_2" data-name="Path 2" d="M194.475,1355.475l6.984,6.867-6.984,7.219" transform="translate(98 774)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <path id="Path_3" data-name="Path 3" d="M194.475,1355.475l6.984,6.867-6.984,7.219" transform="translate(105.853 774)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    &nbsp;&nbsp;
                                                    <span >
                                                        {subject}
                                                    </span>
                                                </H5>
                                            </div>

                                            <div className='col-2'>
                                                {
                                                    grFileUrl && 
                                                <span style={{ cursor: "pointer" }} onClick={() => getGeneralNotificationPdf(_id, attachment, grFileUrl)}>
                                                    <a>
                                                        {darkWhiteThemeToggle === "WHITE" ? <i className="fa fa-paperclip" style={{ color: "black", fontSize: "1.2rem" }} aria-hidden="true"></i> : <i className="fa fa-paperclip" style={{ color: "white", fontSize: "1.2rem" }} aria-hidden="true"></i>}
                                                    </a>
                                                </span>
                                                }
                                            </div>


                                        </div>

                                        <div className='row'>

                                            <div className='col-10'>
                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                                    <span style={{ paddingLeft: "8%" }}>
                                                        {description}
                                                    </span>
                                                </H5>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            )
                        })}
                    </div>
            }
        </div>
    );
}

export default HomeScreenNotificationsCard;

{/* <div className='mb-2' key={index} style={{ width: "100%", display: "flex" }}><div style={{ display: "inline-block", display: "flex", alignSelf: "start" }}>
    <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="17.252" height="16.914" viewBox="0 0 17.252 16.914">
            <g id="Group_18" data-name="Group 18" transform="translate(-291.061 -2128.062)" opacity="0.8">
                <path id="Path_2" data-name="Path 2" d="M194.475,1355.475l6.984,6.867-6.984,7.219" transform="translate(98 774)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <path id="Path_3" data-name="Path 3" d="M194.475,1355.475l6.984,6.867-6.984,7.219" transform="translate(105.853 774)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            </g>
        </svg>
    </span>
</div>&nbsp;&nbsp;
    <div style={{ display: "inline-block", display: "flex", alignSelf: "center" }}>
        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
            <span style={{ cursor: "pointer" }} onClick={() => getGeneralNotificationPdf(_id, attachment, grFileUrl)}>{subject} - {description}</span>
        </H5>
    </div>
</div>  */}
