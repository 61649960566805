import React from 'react'
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const FirstSemesterGraph = ({ typeOfMark, semesterName, totalMarks }) => {

    const options = {
        responsive: true,
        radius: 3,
        hitRadius: 3,
        hoverRadius: 5,
        barThickness: 25,
        plugins: {
            title: {
                display: true,
                text: semesterName
            },
        },
        interaction: {
            intersect: false
        },
        scales: {
            x: {

                display: true,
                ticks: {
                    display: true
                },
                title: {
                    display: true,
                    text: 'Subjects',
                },
            },
            y: {

                display: true,
                ticks: {
                    display: true
                },
                title: {
                    display: true,
                    text: 'Marks'
                },
                suggestedMin: 0,
                suggestedMax: 100

            }
        }
    }

    const labels = totalMarks.map((mark, index) => mark.subject);

    const data = {
        labels,
        datasets: [
            {
                label: "Obtained Marks",
                data: typeOfMark === "Percentage" ? totalMarks.map((mark, index) => mark.obtainedMarks) : totalMarks.map((mark, index) => {
                    // if the grade A than generate random number between 75 and 85
                    if (mark.obtainedMarks === "A") {
                        return Math.floor(Math.random() * (90 - 81 + 1)) + 81;
                    }
                    // if the grade is A+ than generate random number between 85 and 95
                    if (mark.obtainedMarks === "A+") {
                        return Math.floor(Math.random() * (100 - 91 + 1) + 91);
                    }
                    if (mark.obtainedMarks === "B") {
                        return Math.floor(Math.random() * (70 - 61 + 1)) + 61;
                    }
                    if (mark.obtainedMarks === "B+") {
                        return Math.floor(Math.random() * (80 - 71 + 1)) + 71;
                    }
                    if (mark.obtainedMarks === "C") {
                        return Math.floor(Math.random() * (50 - 41 + 1)) + 41;
                    }
                    if (mark.obtainedMarks === "C+") {
                        return Math.floor(Math.random() * (60 - 51 + 1)) + 51;
                    }
                    if (mark.obtainedMarks === "D") {
                        return Math.floor(Math.random() * (40 - 33 + 1)) + 33;
                    }
                    if (mark.obtainedMarks === "E") {
                        return Math.floor(Math.random() * (20 - 0 + 1)) + 0;
                    }
                    if (mark.obtainedMarks === "E+") {
                        return Math.floor(Math.random() * (32 - 21 + 1)) + 21;
                    }
                }),
                backgroundColor: "#418dd9",
            }
        ]
    }

    return (
        <>
            <Bar data={data} options={options} />
        </>
    );
}

export default FirstSemesterGraph