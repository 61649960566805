import H2 from "../text-components/H2";
import { useSelector } from "react-redux";
import { useState } from "react";
import NavSelectedUnderline from "../shared-components/NavSelectedUnderline";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { communication } from "../communications/communication";


const ApplicationProcessNav = ({ refreshCount }) => {

  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

  const showActiveApplicationProcessTab = useSelector(state => state.showActiveApplicationProcessTab);

  const [selectedNavItem, setSelectedNavItem] = useState("");

  const [counts, setCounts] = useState();

  const history = useHistory();

  //   function goToHome () {
  //     history.push('/home');
  // }
  async function getNotificationsCounts() {
    const responseFromServer = await communication.getCounts();
    if (responseFromServer.status === "SUCCESS") {
      setCounts(responseFromServer.counts);
    }
  }


  useEffect(() => {
    getNotificationsCounts();
  }, [refreshCount]);

  useEffect(() => {
    switch (window.location.pathname) {
      case "/user/latest-notification":
        setSelectedNavItem(value => value = "LATEST_NOTIFICATION")
        break;
      case "/user/my-application":
        setSelectedNavItem(value => value = "MY_APPLICATION")
        break;
      case "/user/remittance":
        setSelectedNavItem(value => value = "REMITTANCE")
        break;
      case "/user/doc-submission":
        setSelectedNavItem(value => value = "DOC_SUBMISSION")
        break;
      case "/user/milestone":
        setSelectedNavItem(value => value = "MILESTONE")
        break;
      case "/user/alert":
        setSelectedNavItem(value => value = "ALERT")
        break;
      case "/user/grievance":
        setSelectedNavItem(value => value = "GRIEVANCE")
        break;
      case "/user/refund":
        setSelectedNavItem(value => value = "REFUND")
        break;
      case "/user/downloadsInfoBigSize":
        setSelectedNavItem(value => value = "DOWNLOADSINFOBIGSIZE")
        break;
      case "/user/applicationFlowBigSize":
        setSelectedNavItem(value => value = "APPLICATIONFLOWBIGSIZE")
        break;
    }
  }, []);

  const selectNavItem = (navItem) => {
    setSelectedNavItem(value => value = navItem);

    switch (navItem) {
      case "LATEST_NOTIFICATION":
        history.push('/user/latest-notification');
        break;
      case "MY_APPLICATION":
        history.push('/user/my-application');
        break;
      case "REMITTANCE":
        history.push('/user/remittance');
        break;
      case "DOC_SUBMISSION":
        history.push('/user/doc-submission');
        break;
      case "MILESTONE":
        history.push('/user/milestone');
        break;
      case "ALERT":
        history.push('/user/alert');
        break;
      case "GRIEVANCE":
        history.push('/user/grievance');
        break;
      case "REFUND":
        history.push('/user/refund');
        break;
      case "DOWNLOADSINFOBIGSIZE":
        history.push('/user/downloadsInfoBigSize');
        break;
      case "APPLICATIONFLOWBIGSIZE":
        history.push('/user/applicationFlowBigSize');
        break;
    }
  }

  useEffect(() => {
  }, [selectedNavItem]);



  return (
    <>

      {/*-----navbar for laptop and mobile device--------------*/}

      <nav className="navbar navbar-expand-lg application_process_nav" style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#F0F0F3" : "#000000" }}>
        <div className="container-fluid p-0">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span><i className="fa fa-bars" aria-hidden="true"></i></span>
          </button>
          <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
            <ul className="navbar-nav application_process_nav_list">

              {/* <li className="home_icon"><H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00"><i className="fa fa-home" aria-hidden="true" onClick={goToHome}></i></H2></li> */}

              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationProcessTab === "LATEST_NOTIFICATION") ? selectNavItem(" ") : selectNavItem("LATEST_NOTIFICATION")}>
                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Latest Notification</H2>
                {(showActiveApplicationProcessTab === "LATEST_NOTIFICATION") ? <NavSelectedUnderline /> : <span></span>}
              </li>
              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationProcessTab === "MY_APPLICATION") ? selectNavItem(" ") : selectNavItem("MY_APPLICATION")}>
                <H2 className="MY_APPLICATION" whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">My Application</H2>
                {(showActiveApplicationProcessTab === "MY_APPLICATION") ? <NavSelectedUnderline /> : <span></span>}
              </li>
              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationProcessTab === "DOC_SUBMISSION") ? selectNavItem(" ") : selectNavItem("DOC_SUBMISSION")}>
                <div style={{ position: "relative" }}>
                  <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Document Submission</H2>
                  {counts?.countOfNewDocumentSubmission !== 0 ?
                    <span className="badge rounded-pill">
                      {counts?.countOfNewDocumentSubmission}
                    </span>
                    :
                    <span></span>
                  }

                </div>
                {(showActiveApplicationProcessTab === "DOC_SUBMISSION") ? <NavSelectedUnderline /> : <span></span>}
              </li>
              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationProcessTab === "REMITTANCE") ? selectNavItem(" ") : selectNavItem("REMITTANCE")}>
                <div style={{ position: "relative" }}>
                  <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Remittance</H2>
                  {counts?.countOfNewRemittance !== 0 ?
                    <span className="badge rounded-pill">
                      {counts?.countOfNewRemittance}
                    </span>
                    :
                    <span></span>
                  }
                </div>
                {(showActiveApplicationProcessTab === "REMITTANCE") ? <NavSelectedUnderline /> : <span></span>}
              </li>
              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationProcessTab === "MILESTONE") ? selectNavItem(" ") : selectNavItem("MILESTONE")}>
                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Milestones</H2>
                {(showActiveApplicationProcessTab === "MILESTONE") ? <NavSelectedUnderline /> : <span></span>}
              </li>
              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationProcessTab === "ALERT") ? selectNavItem(" ") : selectNavItem("ALERT")}>
                <div style={{ position: "relative" }}>
                  <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Alert</H2>
                  {counts?.countOfUserUnSeenAlert !== 0 ?
                    <span className="badge rounded-pill">
                      {counts?.countOfUserUnSeenAlert}
                    </span>
                    :
                    <span></span>
                  }
                </div>
                {(showActiveApplicationProcessTab === "ALERT") ? <NavSelectedUnderline /> : <span></span>}
              </li>

              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationProcessTab === "GRIEVANCE") ? selectNavItem(" ") : selectNavItem("GRIEVANCE")}>
                <div style={{ position: "relative" }}>
                  <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Grievance</H2>
                  {counts?.countOfNewGreivance !== 0 ?
                    <span className="badge rounded-pill">
                      {counts?.countOfNewGreivance}
                    </span>
                    :
                    <span></span>
                  }
                </div>
                {(showActiveApplicationProcessTab === "GRIEVANCE") ? <NavSelectedUnderline /> : <span></span>}
              </li>
              
              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationProcessTab === "REFUND") ? selectNavItem(" ") : selectNavItem("REFUND")}>
                <div style={{ position: "relative" }}>
                  <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Refund</H2>
                  {counts?.countOfRefunds !== 0 ?
                    <span className="badge rounded-pill">
                      {counts?.countOfRefunds}
                    </span>
                    :
                    <span></span>
                  }
                </div>
                {(showActiveApplicationProcessTab === "REFUND") ? <NavSelectedUnderline /> : <span></span>}
              </li>
              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationProcessTab === "DOWNLOADSINFOBIGSIZE") ? selectNavItem(" ") : selectNavItem("DOWNLOADSINFOBIGSIZE")}>
                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Downloads</H2>
                {(showActiveApplicationProcessTab === "DOWNLOADSINFOBIGSIZE") ? <NavSelectedUnderline /> : <span></span>}
              </li>
              <li className={"nav-item" + (darkWhiteThemeToggle === "WHITE" ? 'hover-underline-animation' : 'hover-underline-animation-dark')} style={{ cursor: "pointer" }} onClick={() => (showActiveApplicationProcessTab === "APPLICATIONFLOWBIGSIZE") ? selectNavItem(" ") : selectNavItem("APPLICATIONFLOWBIGSIZE")}>
                <H2 whiteThemeColor="#0D2750" darkThemeColor="#FFFF00">Application Flow</H2>
                {(showActiveApplicationProcessTab === "APPLICATIONFLOWBIGSIZE") ? <NavSelectedUnderline /> : <span></span>}
              </li>
            </ul>

          </div>
        </div>
      </nav>

    </>
  );
};

export default ApplicationProcessNav;