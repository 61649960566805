import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ApplicationProcessNav from "../common/ApplicationProcessNav";
import LatestNotification from "./LatestNotification";
import { Redirect } from 'react-router';
import { useEffect } from "react";
import MyApplication from "./MyApplication";
import Remittance from "./Remittance";
import Milestone from "./Milestone";
import Grievance from "./Grievance";
import DocSubmission from "./DocSubmission";
import AlertNotification from "../common/AlertNotification";
import Application from "./Application";
import ApplicationFlowBigSize from "../common/ApplicationFlowBigSize";
import DownloadsInfoBigSize from "../common/DownloadInfoBigSize";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { applicationCommunication } from "../communications/application-communication";
import UserMilestone1Form from "../common/UserMilestone1Form";
import UserMilestone3Form from "../common/UserMilestone3Form";
import UserMilestone4Form from "../common/UserMilestone4Form";
import UserMilestone5Form from "../common/UserMilestone5Form";
import GrievanceUserReply from "./GrievanceUserReply";
import UserMilestone2Form from "../common/UserMilestone2Form";
import UserMilestoneGeneral from "../common/userMilestoneGeneral";
import UserRefund from "./UserRefund";




const ApplicationProcess = () => {
  const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

  const showApplicationProcessNav = useSelector(state => state.showApplicationProcessNav);

  let isUserAuthenticated = useSelector((state) => state.isUserAuthenticated);

  let [isUserVerifiedAgain, setIsUserVerifiedAgain] = useState(false);

  const [getTicket, setGetTicket] = useState([]);

  const [refreshCount, setRefreshCount] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();

  async function initialLoad() {
    if (isUserAuthenticated === false) {
      let userJwtVerification = await applicationCommunication.getUserAuthenticateByJWT();

      if (userJwtVerification.status === "SUCCESS") {
        dispatch({ type: "AUTHENTICATE_USER", payload: userJwtVerification.jwt });
      } else {
        dispatch({ type: "DEAUTHENTICATE_USER" });
      }
    }
    const queryParams = new URLSearchParams(location.search);
    const ticketId = queryParams.get('ticketId');
    setGetTicket(ticketId);

    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');
    if (splitUrlArray.includes("application")) {
      dispatch({ type: "HIDE_APPLICATION_PROCESS_NAV" });
    } else {
      dispatch({ type: "SHOW_APPLICATION_PROCESS_NAV" });
    }

    setIsUserVerifiedAgain(true);
  }

  useEffect(() => {
    initialLoad();
  }, []);

  function changeHandler() {
    setRefreshCount(!refreshCount);
  }
  return (

    <div >
      {/* <UserDownloadApplicationFlowNav /> */}
      {
        showApplicationProcessNav
          ?
          <ApplicationProcessNav refreshCount={refreshCount} />
          :
          <span></span>
      }
      <section className="application_process_main " style={{ backgroundColor: darkWhiteThemeToggle === "WHITE" ? "#FFFFFF" : "#000000" }}>
        <div className="row m-0 ">
          {
            isUserVerifiedAgain ?
              (<div className="col-12 p-0 ">
                <Route exact path="/user" render={() => {
                  return (<Redirect to="/user/latest-notification" />);
                }} />

                {/* {
                  getTicket === null ?
                    (<div></div>)
                    :
                    (
                      <Route exact path="/user/grievance-user-reply" render={() => {
                        return (<Redirect to={"/user/grievance-user-reply?ticketId=" + getTicket} />);
                      }} />
                    )
                } */}


                {
                  isUserAuthenticated ?
                    <Route exact path="/user/latest-notification" component={() => <LatestNotification changeHandler={changeHandler} />} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route exact path="/user/my-application" component={MyApplication} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route exact path="/user/doc-submission" component={DocSubmission} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route exact path="/user/remittance" component={() => <Remittance changeHandler={changeHandler} />} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route exact path="/user/milestone" component={Milestone} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route exact path="/user/alert" component={AlertNotification} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route exact path="/user/grievance" component={GrievanceUserReply} />
                    :
                    <Redirect to="/login" />
                }
                
                {
                  isUserAuthenticated ?
                    <Route exact path="/user/refund" component={UserRefund} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route exact path="/user/downloadsInfoBigSize" component={DownloadsInfoBigSize} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route exact path="/user/applicationFlowBigSize" component={ApplicationFlowBigSize} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route path="/user/application" component={Application} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route path="/user/user-milestone-one" component={() => <UserMilestone1Form changeHandler={changeHandler} />} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route path="/user/user-milestone-two" component={UserMilestone2Form} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route path="/user/user-milestone-three" component={() => <UserMilestone3Form changeHandler={changeHandler} />} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route path="/user/user-milestone-four" component={() => <UserMilestone4Form changeHandler={changeHandler} />} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route path="/user/user-milestone-five" component={UserMilestone5Form} />
                    :
                    <Redirect to="/login" />
                }
                {/* {
                  isUserAuthenticated ?
                    <Route exact path="/user/grievance-user-reply" component={() => <GrievanceUserReply changeHandler={changeHandler} />} />
                    :
                    <Redirect to="/login" />
                } */}
                {
                  isUserAuthenticated ?
                    <Route path="/user/user-session-milestone" component={UserMilestoneGeneral} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route path="/user/user-sem-milestone" component={UserMilestoneGeneral} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route path="/user/user-other-milestone" component={UserMilestoneGeneral} />
                    :
                    <Redirect to="/login" />
                }
                {
                  isUserAuthenticated ?
                    <Route path="/user/user-final-milestone" component={UserMilestoneGeneral} />
                    :
                    <Redirect to="/login" />
                }
              </div>)
              :
              <span></span>
          }
        </div>
      </section>
    </div>
  );

}
export default ApplicationProcess;