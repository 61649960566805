import React, { useEffect, useState } from 'react'
import H5 from '../text-components/H5'
import { useDispatch } from "react-redux";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { communication } from '../communications/communication';
import { removeUnderscoreFromText } from '../utlis/helper';

const UpdateUserMarksheetForAutoTriggeredMilestones = ({ getMileStoneDocument, semesterId, setShowUpdateMarksheetDetailForm, applicationId }) => {

    const [semesterMarks, setSemesterMarks] = useState({});
    const [validationMsg, setValidationMsg] = useState({ subject: false, heighestGrade: false, obtainedGrade: false });
    const [addedSubjects, setAddedSubjects] = useState([]);
    const [grades, setGrades] = useState({
        subjects: "",
        highestGrade: "",
        obtainedGrade: ""
    });

    const { subjects, highestGrade, obtainedGrade } = grades;
    const dispatch = useDispatch();

    //If type of marks is grade then get the values of grades
    const gradeDetail = (event) => {
        setGrades({ ...grades, [event.target.name]: event.target.value });
    }
    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //Fetch user Semester List by userId
    const fetchUserSemesterMarks = async () => {
        try {
            const responseFromServer = await communication.getUserSemesterMarksBySemesterId(semesterId);
            if (responseFromServer.status === "SUCCESS") {
                setSemesterMarks(responseFromServer?.userSemMarks);
                setAddedSubjects(responseFromServer?.userSemMarks?.totalMarks);
            } else {
                showModal(responseFromServer.message);
            }
        } catch (error) {
            showModal(error.message);
        }
    }

    useEffect(() => {
        fetchUserSemesterMarks();
    }, []);

    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        subject: "",
        totalMarks: "",
        obtainedMarks: "",
    }

    //****Validation for Designation Create form*****/
    let yupValidationSchema = Yup.object().shape({
        // subject: Yup.string().required("Subject Name is required"),
        // totalMarks: Yup.string().required("Total Marks is required"),
        // obtainedMarks: Yup.string().required("Obtained Marks is required")
        // .test("test", "obtained marks exceeds total marks", function (marks) {
        //     const { totalMarks } = this.parent;
        //     return Number(marks) <= Number(totalMarks);
        // }),
    });

    const { handleSubmit, handleChange, values, errors, handleReset, setFieldValue } = useFormik({
        // Passing default values to the form until the values from server are not fetched.
        // After the values are fetched from the server then use the initialFormValues variable to set the form.
        // To set these values fetched from the server then enableReinitialize property neeeds to be enabled. 
        initialValues: defaultFormValues,
        // This enables values to be reinitialized. For example, fetching values from the server after the form is loaded.
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: false,
        // If there are no errors after validating all data using yup, then onSubmit function is called
        // and all form data is passed as a parameter to the lambda function assigned to onSubmit

        onSubmit: async (values) => {

            if ((semesterMarks?.typeOfMark === "Percentage") && (values.obtainedMarks > values.totalMarks)) {
                showModal("Obtained marks can't be greater than total marks");
                return false;
            }

            let subject = "";
            let actualTotalMarks = "";
            let actualObtainedMarks = "";

            if ((semesterMarks?.typeOfMark === "Percentage") && ["undefined", "null", ""].includes(values.subject)) {
                setValidationMsg({ subject: true });
                return false;
            } else {
                setValidationMsg({ subject: false });
            }
            if ((semesterMarks?.typeOfMark === "Percentage") && ["undefined", "null", ""].includes(values.totalMarks)) {
                setValidationMsg({ heighestGrade: true });
                return false;
            } else {
                setValidationMsg({ heighestGrade: false });
            }

            if ((semesterMarks?.typeOfMark === "Percentage") && ["undefined", "null", ""].includes(values.obtainedMarks)) {
                setValidationMsg({ obtainedGrade: true });
                return false;
            }

            if ((semesterMarks?.typeOfMark === "Grade") && ["undefined", "null", ""].includes(subjects)) {
                setValidationMsg({ subject: true });
                return false;
            } else {
                setValidationMsg({ subject: false });
            }
            if ((semesterMarks?.typeOfMark === "Grade") && ["undefined", "null", ""].includes(highestGrade)) {
                setValidationMsg({ heighestGrade: true });
                return false;
            } else {
                setValidationMsg({ heighestGrade: false });
            }

            if ((semesterMarks?.typeOfMark === "Grade") && ["undefined", "null", ""].includes(obtainedGrade)) {
                setValidationMsg({ obtainedGrade: true });
                return false;
            }

            if (semesterMarks?.typeOfMark === "Percentage") {
                subject = values.subject
                actualTotalMarks = values.totalMarks;
                actualObtainedMarks = values.obtainedMarks;
            }
            if (semesterMarks?.typeOfMark === "Grade") {
                subject = subjects;
                actualTotalMarks = highestGrade;
                actualObtainedMarks = obtainedGrade;
            }

            let dataToAdd = {
                subject: subject,
                totalMarks: actualTotalMarks,
                obtainedMarks: actualObtainedMarks,
            }

            //Overiding Marks if subject name is same 
            const filterMarks = addedSubjects.filter((ele) => {
                return ele.subject.toLowerCase() !== dataToAdd.subject.toLowerCase()
            })

            setAddedSubjects([...filterMarks, { subject: dataToAdd?.subject, totalMarks: dataToAdd?.totalMarks, obtainedMarks: dataToAdd?.obtainedMarks }]);
            handleReset();
        },
        // Validation schema for validating the form data. Using yup in this case.
        validationSchema: yupValidationSchema,
    });

    // Delete added subjects from table
    const deleteSubject = (index) => {
        const updatedSubjectList = addedSubjects.filter((elem, id) => {
            return index !== id;
        })
        setAddedSubjects(updatedSubjectList);
    }

    const updateDetails = async () => {
        try {

            if (addedSubjects?.length <= 1) {
                showModal("All Subjects Marks Required");
                return false;
            }

            let dataToSend = {
                semesterId,
                typeOfMark: semesterMarks?.typeOfMark,
                totalMarks: addedSubjects,
                applicationId,
                semester: semesterMarks.semester,
            }

            const serverResponse = await communication.updateSemMarks(dataToSend);

            if (serverResponse?.data?.status === "SUCCESS") {
                showModal(serverResponse?.data?.message);
            } else {
                showModal(serverResponse?.data?.message);
            }
            getMileStoneDocument();
            setShowUpdateMarksheetDetailForm(false);
        }
        catch (error) {
            showModal(error?.message);
        }
    }


    return (
        <div className="marks_detail_section">

            <div className="row mb-3">
                <div className="col-6">
                    <select name="semester" className="form-select text-center" disabled>
                        <option value="">{removeUnderscoreFromText(semesterMarks?.semester)}</option>
                    </select>
                </div>
                <div className="col-6">
                    <input type="text" name="typeOfMark" value={semesterMarks?.typeOfMark} className="form-control" />
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="row mb-2">
                    <div className="col-5">
                        {semesterMarks?.typeOfMark === "Grade" ?
                            <>
                                <H5>Subject Name</H5>
                                <input type="text" name="subjects" value={subjects} onChange={(event) => gradeDetail(event)} className="form-control" placeholder="Enter Subject Name" />
                                {validationMsg.subject === true &&
                                    <span style={{ color: "red" }}>Subject is Required</span>
                                }
                            </>
                            :
                            <>
                                <H5>Subject Name</H5>
                                <input type="text" name="subject" value={values.subject} onChange={handleChange} className="form-control" placeholder="Enter Subject Name" />
                                {validationMsg.subject === true &&
                                    <span style={{ color: "red" }}>Subject is Required</span>
                                }
                            </>
                        }
                    </div>
                    <div className="col-3">
                        {semesterMarks?.typeOfMark === "Grade" ?
                            <>
                                <H5>Highest Grade</H5>
                                <select name="heighestGrade" value={highestGrade} onChange={(event) => gradeDetail(event)} className="form-select">
                                    <option value="">Select Grade</option>
                                    <option value="A++">A++</option>
                                    <option value="A+">A+</option>
                                    <option value="A">A</option>
                                </select>
                                {validationMsg.heighestGrade === true &&
                                    <span style={{ color: "red" }}>Select Highest Grade</span>
                                }
                            </>
                            :
                            <>
                                <H5>Total Marks</H5>
                                <input type={semesterMarks?.typeOfMark === "Grade" ? "text" : "number"} name="totalMarks" value={values.totalMarks} onChange={handleChange} className="form-control" placeholder="Enter Total Marks" />
                                {validationMsg.heighestGrade === true &&
                                    <span style={{ color: "red" }}>Total Marks is Required</span>
                                }
                            </>
                        }
                    </div>
                    <div className="col-3">
                        {semesterMarks?.typeOfMark === "Grade" ?
                            <>
                                <H5>Obtained Grade</H5>
                                <select name="obtainedGrade" value={obtainedGrade} onChange={(event) => gradeDetail(event)} className="form-select">
                                    <option value="">Select Grade</option>
                                    <option value="A++">A++</option>
                                    <option value="A+">A+</option>
                                    <option value="A">A</option>
                                    <option value="B++">B++</option>
                                    <option value="B+">B+</option>
                                    <option value="B">B</option>
                                    <option value="C++">C++</option>
                                    <option value="C+">C+</option>
                                    <option value="C">C</option>
                                </select>
                                {validationMsg.obtainedGrade === true &&
                                    <span style={{ color: "red" }}>Select Obtained Grade</span>
                                }
                            </>
                            :
                            <>
                                <H5>Obtained Marks</H5>
                                <input type={semesterMarks?.typeOfMark === "Grade" ? "text" : "number"} name="obtainedMarks" value={values.obtainedMarks} onChange={handleChange} className="form-control" placeholder="Enter Obtained Marks" />
                                {validationMsg.obtainedGrade === true &&
                                    <span style={{ color: "red" }}>Obtained Marks is Required</span>
                                }
                            </>
                        }
                    </div>
                    <div className="col-1 ps-0 add_button">
                        <button type="submit">Add</button>
                    </div>
                </div>
            </form>

            {/*-----Subject table----*/}
            {addedSubjects?.map((subject, index) => (
                <div className="row mb-2" key={index}>
                    <div className="col-5">
                        <input type="text" value={subject?.subject} name="subject" className="form-control" disabled />
                    </div>
                    <div className="col-3">
                        <input type="text" value={subject?.totalMarks} name="totalMarks" className="form-control" disabled />
                    </div>
                    <div className="col-3">
                        <input type="text" value={subject?.obtainedMarks} name="obtainedMarks" className="form-control" disabled />
                    </div>
                    <div className="col-1 delete_button">
                        <i className="fa fa-trash-o" aria-hidden="true" onClick={() => deleteSubject(index)}></i>
                    </div>
                </div>
            ))}

            <div className="row mt-4">
                <div className="col-12 submit_marks_button">
                    <button onClick={() => updateDetails()}>Update</button>
                    <button className="ms-3" onClick={() => setShowUpdateMarksheetDetailForm(false)}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default UpdateUserMarksheetForAutoTriggeredMilestones