import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { frontOfficeCommunication } from '../../communications/front-office-communication';
import LoadingForms from '../../shared-components/LoadingForms';
import H5 from '../../text-components/H5';
import Heading from '../../text-components/Heading';

const CourseWiseApplication = () => {
    const [allAppplicationData, setAllAppplicationData] = useState([]);
    console.log("allAppplicationData", allAppplicationData);

    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    // Modal Showing With redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }




    async function initialLoad() {
        try {
            let fullUrl = window.location.href;
            let splitUrlArray = fullUrl.split('/');
            if (splitUrlArray.includes("course-wise-application")) {
                dispatch({ type: "ACTIVE_TAB", payload: "COURSE_WISE_APPLICATION" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_TAB" });
            }

            showLoading("Loading...");
            // Get course Wise Application data From the server
            const courseWiseDataResponse = await frontOfficeCommunication.getCourseWiseApplicationsDetails();
            const courseWiseApplicationData = courseWiseDataResponse?.data;
            setAllAppplicationData(courseWiseApplicationData);
        } catch (error) {
            hideLoading()
            showModal(error.message)
        }
        hideLoading()
    }

    useEffect(() => {
        initialLoad();
    }, [])


    return (
        <>
            <section className="dashboard_background" >
                <div className='container'>

                    {isLoading.value ?
                        <LoadingForms message={isLoading.message} />
                        :

                        <>
                            <div className="row m-2 coures_main ">

                                <div className='Heading_Div mt-4'>
                                    <Heading className whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">New Applications Course Wise</Heading>
                                </div>

                                <div className="col-6 p-4">

                                    <div className="row mt-4 mb-4">
                                     <div className="col-12 text-center">
                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  Application for PG</H5>
                                    </div>
                                       {/* <div className="col-8">
                                            <input type="number" value={allAppplicationData?.PGApplications} className='form-control coures_input' disabled />
                                    </div> */}
                                    </div>

                                    <div className="row mt-1 mb-1">
                                        <div className="col-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  Art</H5>
                                        </div>
                                        <div className="col-8">
                                            <input type="number" value={allAppplicationData?.ART_PG_Count} className='form-control coures_input' disabled />
                                        </div>
                                    </div>

                                    <div className="row mt-1 mb-1">
                                        <div className="col-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  Commerce</H5>
                                        </div>
                                        <div className="col-8">
                                            <input type="number" value={allAppplicationData?.COMMERCE_PG_Count} className='form-control coures_input' disabled />
                                        </div>
                                    </div>

                                    <div className="row mt-1 mb-1">
                                        <div className="col-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">   Science</H5>
                                        </div>
                                        <div className="col-8">
                                            <input type="number" value={allAppplicationData?.SCIENCE_PG_Count} className='form-control coures_input' disabled />
                                        </div>
                                    </div>

                                    <div className="row mt-1 mb-1">
                                        <div className="col-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">   Engineering</H5>
                                        </div>
                                        <div className="col-8">
                                            <input type="number" value={allAppplicationData?.ENGINEERING_PG_Count} className='form-control coures_input' disabled />
                                        </div>
                                    </div>

                                    <div className="row mt-1 mb-1">
                                        <div className="col-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">   Management</H5>
                                        </div>
                                        <div className="col-8">
                                            <input type="number" value={allAppplicationData?.MANAGEMENT_PG_Count} className='form-control coures_input' disabled />
                                        </div>
                                    </div>

                                    <div className="row mt-1 mb-1">
                                        <div className="col-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  Medical</H5>
                                        </div>
                                        <div className="col-8">
                                            <input type="number" value={allAppplicationData?.MEDICAL_PG_Count} className='form-control coures_input' disabled />
                                        </div>
                                    </div>

                                    <div className="row mt-1 mb-4">
                                        <div className="col-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">   Law</H5>
                                        </div>
                                        <div className="col-8">
                                            <input type="number" value={allAppplicationData?.LAW_PG_Count} className='form-control coures_input' disabled />
                                        </div>
                                        
                                        </div>
                                    <div className="row mt-4 mb-4">
                                    <div className="col-4">
                                       <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> Total Applications </H5>
                                   </div>
                                      <div className="col-8">
                                           <input type="number" value={allAppplicationData?.PGApplications} className='form-control coures_input' disabled />
                                       </div>
                                   </div>

                                </div>

                                {/*  ART_PG_Count
                                     ART_PhD_Count

                                    COMMERCE_PG_Count
                                    COMMERCE_PhD_Count

                                    ENGINEERING_PG_Count
                                    ENGINEERING_PhD_Count

                                    LAW_PG_Count

                                    MANAGEMENT_PG_Count
                                    MANAGEMENT_PhD_Count

                                   MEDICAL_PG_Count
                                   MEDICAL_PhD_Count

                                   PGApplications
                                   PhDApplications

                                   SCIENCE_PG_Count
                                   SCIENCE_PhD_Count
                                 */}



                                <div className="col-6 p-4">

                                    <div className="row mt-4 mb-4">
                                        <div className="col-12">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  Application for PhD</H5>
                                        </div>
                                       {/*<div className="col-8">
                                            <input type="number" value={allAppplicationData?.PhDApplications} className='form-control coures_input' disabled />
                                </div>*/}
                                    </div>

                                    <div className="row mt-1 mb-1">
                                       { /*<div className="col-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  PhD - Art</H5>
                            </div> */}
                                        <div className="col-8">
                                            <input type="number" value={allAppplicationData?.ART_PhD_Count} className='form-control coures_input' disabled />
                                        </div>
                                    </div>

                                    <div className="row mt-1 mb-1">
                                       { /*<div className="col-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  PhD - Commerce</H5>
                        </div> */}
                                        <div className="col-8">
                                            <input type="number" value={allAppplicationData?.COMMERCE_PhD_Count} className='form-control coures_input' disabled />
                                        </div>
                                    </div>

                                    <div className="row mt-1 mb-1">
                                        {/*<div className="col-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  PhD - Science</H5>
                    </div>*/}
                                        <div className="col-8">
                                            <input type="number" value={allAppplicationData?.SCIENCE_PhD_Count} className='form-control coures_input' disabled />
                                        </div>
                                    </div>

                                    <div className="row mt-1 mb-1">
                                        {/*<div className="col-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  PhD - Engineering</H5>
                </div>*/}
                                        <div className="col-8">
                                            <input type="number" value={allAppplicationData?.ENGINEERING_PhD_Count} className='form-control coures_input' disabled />
                                        </div>
                                    </div>

                                    <div className="row mt-1 mb-1">
                                        {/*<div className="col-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  PhD - Management</H5>
            </div>*/}
                                        <div className="col-8">
                                            <input type="number" value={allAppplicationData?.MANAGEMENT_PhD_Count} className='form-control coures_input' disabled />
                                        </div>
                                    </div>

                                    <div className="row mt-1 mb-1">
                                        { /*<div className="col-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  PhD - Medical</H5>
        </div>*/}
                                        <div className="col-8">
                                            <input type="number" value={allAppplicationData?.MEDICAL_PhD_Count} className='form-control coures_input' disabled />
                                        </div>
                                    </div>

                                    <div className="row mt-1 mb-4">
                                        {/*<div className="col-4 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  PhD - Law</H5>
    </div>*/}
                                        <div className="col-8">
                                            <input type="number" value={allAppplicationData?.LAW_PhD_Count} className='form-control coures_input' disabled />
                                        </div>
                                    </div>

                                    <div className="row mt-3 mb-4">
                                  { /* <div className="col-4 ">
                                        <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">  Application of PhD</H5>
</div> */}
                                   <div className="col-8">
                                        <input type="number" value={allAppplicationData?.PhDApplications} className='form-control coures_input' disabled />
                            </div>
                                </div>

                                </div>

                                <div className="col-12 p-4">
                                    <div className="row mb-4">
                                        <div className="col-2 ">
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> All Applications</H5>
                                        </div>
                                        <div className="col-4">
                                            <input type="number" value={allAppplicationData?.totalApplications} className='form-control coures_input' disabled />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    }
                </div>

            </section>


        </>

    )
}

export default CourseWiseApplication