import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from 'formik';
import FormFieldTitle from "./FormFieldTitle";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { applicationCommunication, getServerUrl } from "../communications/application-communication";
import LoadingForms from "../shared-components/LoadingForms";
import { useDispatch, useSelector } from "react-redux";
import P from "../text-components/P";
import { communication } from "../communications/communication"

const DocumentSubmissionDetails = () => {

    const [requiredDocuments, setRequiredDocuments] = useState([]);

    const [otherDocumentsDetails, setOtherDocumentsDetails] = useState([]);
    //****  Stopping field input- Enable & Disable Button *****//
    const [disabled, setDisabled] = useState(false);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [initialFormValues, setInitialFormValues] = useState(null);

    const [urls, setUrls] = useState([]);

    const [applicationId, setApplicationId] = useState(null);

    const [isLoading, setIsLoading] = useState({ message: "", value: false });

    const [modalBoxImgSrc, setModalBoxImgSrc] = useState();

    const [showDocumentPreviewModal, setShowDocumentPreviewModal] = useState(false);
    //***** Setting default value for the form  ****//
    //***** Required for when the data is not fetched from the server   ****//
    const defaultFormValues = {
        title: "",
        link: ""
    }

    //****Validation for Family income details form*****/
    let validationSchema = Yup.object().shape({
        // fileUrl: Yup.string().required("Select file")
        // fileUrl: Yup.mixed().required("Attachment is required").nullable()
    });

    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    const history = useHistory();

    const location = useLocation();

    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    // To get current location url
    let fullUrl = window.location.href;
    let splitUrlArray = fullUrl.split('/');

    //** Fetching parent detail form ***//

    async function initialLoad() {

        showLoading("Loading Form...");

        // to show active tab
        let fullUrl = window.location.href;
        let splitUrlArray = fullUrl.split('/');
        let documentSubmissionDetailUrl = "document-submission-details?applicationId";
        let splitdocumentSubmissionDetailUrl = documentSubmissionDetailUrl.split('?');
        if (splitdocumentSubmissionDetailUrl.includes("document-submission-details")) {
            dispatch({ type: "SHOW_ACTIVE_APPLICATION_FORM_TAB", payload: "DOCUMENT_SUBMISSION_DETAILS" });
        } else {
            dispatch({ type: "HIDE_ACTIVE_APPLICATION_FORM_TAB" });
        }

        const queryParams = new URLSearchParams(location.search);
        const applicationIdFromParams = await queryParams.get('applicationId');
        setApplicationId(applicationIdFromParams);

        // Fetching form from the server
        await fetchDocumentsFromServer(applicationIdFromParams);



        //Checking application status is incomplete & making fields undisabled
        const applicationsFromServer = await communication.getMyApplications(applicationIdFromParams);

        if (applicationsFromServer.status === "SUCCESS") {
            const applicationStatus = applicationsFromServer.userApplications[0].applicationStatus

            if (applicationStatus === "INCOMPLETE") {
                setDisabled(false);
                setIsSubmitted(false);
            } else if (applicationStatus === "SELECTED" || applicationStatus === "COMPLETED" || applicationStatus === "APPLICATION_SUBMITTED") {
                setDisabled(true);
                setIsSubmitted(true);
            }

        }

        hideLoading();
    }

    useEffect(() => {
        initialLoad();
    }, []);

    async function fetchDocumentsFromServer(applicationId) {
        const getDocumentsResponse = await applicationCommunication.getDocumentsSubmission(applicationId);

        // Handling response from the server
        if (getDocumentsResponse.status === "DOCUMENTS_FORM_FETCHED_SUCCESSFULLY") {
            let documentsSubmissionForm = getDocumentsResponse.documentsForm;

            let documentsObject = [];
            Object.keys(documentsSubmissionForm.urls).forEach(documentName => {
                let fileUrl = documentsSubmissionForm.urls[documentName];
                let documentRequired = documentsSubmissionForm.requiredDocuments[documentName];
                let fileTypeArray = [];
                let fileType = "";
                if (typeof (fileUrl) !== "object" && documentRequired) {
                    fileTypeArray = fileUrl.split('.');
                    fileType = fileTypeArray[1];
                    documentsObject.push({ fileName: documentName.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase(), fileUrl: fileUrl, fieldName: documentName, fileType: fileType });
                } else {
                }
            });

            setRequiredDocuments(value => value = documentsObject);
            setIsSubmitted(value => value = documentsSubmissionForm.isSubmitted);
            setDisabled(value => value = documentsSubmissionForm.isSubmitted);
        }
    }

    const { handleSubmit, handleChange, values, errors, handleReset, setFieldValue } = useFormik({
        initialValues: initialFormValues === null ? defaultFormValues : initialFormValues,
        enableReinitialize: true,
        onSubmit: async (values) => {
            //set parent details object to send
            let documentsForm = {
                title: values.title,
                link: values.link
            }
            setOtherDocumentsDetails(value => { return [...value, { ...documentsForm }] });
            return false;
        },
        validationSchema: validationSchema,
    });

    function setFileToUpload(event, document) {
        event.preventDefault();
        let fileNameArray = event.target.files[0].name.split('.');
        let formData = new FormData();
        let documentIndex = requiredDocuments.indexOf(document);
        formData.append(document.fieldName, event.target.files[0], event.target.files[0].name);
        requiredDocuments[documentIndex].fileToUpload = formData;
        requiredDocuments[documentIndex].fileType = fileNameArray[1];
    }

    async function uploadDocument(document) {
        if (document.fileToUpload !== undefined && document.fileToUpload !== null) {
            showLoading("Uploading Document...");
            if (document.fileToUpload !== undefined || document.fileToUpload !== null || document.fileToUpload !== "") {
                const fileUploadResult = await applicationCommunication.uploadDocument(document.fileToUpload, document.fieldName, document.fileType, applicationId);
                if (fileUploadResult.data.status === "FILE_UPLOADED_SUCCESSFULLY") {

                    // Fetching form from the server
                    await fetchDocumentsFromServer(applicationId);

                    hideLoading();
                }

                if (fileUploadResult.data.status === "VALIDATION_FAILED" || fileUploadResult.status === "VALIDATION_FAILED") {
                    showModal(fileUploadResult.data.validationErrorsList[0].message || fileUploadResult.validationErrorsList[0].message)
                    hideLoading();
                    return false;
                }


                showModal(fileUploadResult.data.message || fileUploadResult.message);
                hideLoading();
            }
        } else {
            showModal("Please select a document to upload.");
            hideLoading();
        }
    }

    function goToHome() {
        history.push('/user/my-application');
    }

    function showImg(fileUrl) {
        setShowDocumentPreviewModal(true);
        setModalBoxImgSrc(fileUrl);
    }

    async function submitApplicationForm() {
        showLoading("Submitting Form...");

        // BEFORE submitting application check if file are uploaded

        const applicationSubmitResponse = await applicationCommunication.submitApplicationForm(applicationId);

        if (applicationSubmitResponse?.status === "SUCCESS") {
            showLoading("Loading Form...");
        }

        showModal(applicationSubmitResponse.message);


        await fetchDocumentsFromServer(applicationId);

        hideLoading();
        setIsSubmitted(true);
    }

    function deleteOtherDocumentDetails(itemToDelete) {
        setOtherDocumentsDetails(value => {
            return value.filter(item => item !== itemToDelete);
        });
    }

    const showFilePreview = async (filePath) => {
        await communication.getUploadedDocumets(`${filePath}`);
    }

    return (
        <>
            <section className="application_flow_big">
                <div className="document_submission_main">
                    <div className="container">
                        {isLoading.value ?
                            <LoadingForms message={isLoading.message} />
                            :
                            <div className="row">
                                <div> {isSubmitted ? <span></span> : <P whiteThemeColor="#0d2750" darkThemeColor="#FFFFFF"><span style={{ letterSpacing: "1px", color: "rgb(109, 141, 173)" }}>*NOTE:- You can upload jpg / png / jpeg / pdf format file & file size must be less than 2mb.</span></P>}</div>
                                <div className="col-12">
                                    <div className="row">
                                        {requiredDocuments.map((document, index) => {
                                            {/* if (document.fieldName) { */ }
                                            return (
                                                <div className="col-lg-6 mt-3" key={index}>
                                                    <div className="row">
                                                        {/* After submitting the form hide select input box */}
                                                        {isSubmitted ?
                                                            <div className="col-lg-5 col-md-12 col-12 ms-lg-5 select_file text-center"> <FormFieldTitle name={document.fileName} /></div>
                                                            :
                                                            <div className="col-lg-7 col-md-7 select_file">
                                                                <FormFieldTitle name={document.fileName} />
                                                                <input type="file" onChange={(e) => setFileToUpload(e, document)} name="fileUrl" className={`form-control ${errors.fileUrl ? "is-invalid" : ""}`} disabled={disabled} />
                                                                <div className="invalid-feedback">{errors.fileUrl}</div>
                                                            </div>

                                                        }
                                                        {/* After submitting the form hide select input box */}
                                                        {isSubmitted ?
                                                            <div className="col-lg-1 col-md-1 col-5"></div>
                                                            :
                                                            <div className="col-lg-2 col-md-2 col-5 upload_btn">
                                                                <button onClick={() => uploadDocument(document)} disabled={disabled}>Upload</button>
                                                            </div>
                                                        }
                                                        {/* After submitting the form hide select input box */}
                                                        {isSubmitted ?
                                                            <div className="col-lg-3 col-md-4 col-8 m-auto doc_image" >
                                                                {
                                                                    document.fileType === "jpg" || document.fileType === "png" ?
                                                                        <img src={`${getServerUrl()}/getUserFiles/${document.fileUrl}`} width="100%" height="100%" alt="" onClick={() => showImg(`${getServerUrl()}/getUserFiles/${document.fileUrl}`)} />
                                                                        :
                                                                        <span></span>
                                                                }
                                                                {
                                                                    document.fileType === "pdf" ?
                                                                        <div className="pdf_preview" onClick={() => showFilePreview(document.fileUrl)} ><img src="/assets/images/pngs/pdf-icon.png" alt="line graph" /></div>
                                                                        :
                                                                        <span></span>
                                                                }
                                                            </div>
                                                            :
                                                            <div className="col-lg-3 col-md-3 col-6 doc_image" >

                                                                {
                                                                    document.fileType === "jpg" || document.fileType === "png" ?
                                                                        <img src={`${getServerUrl()}/getUserFiles/${document.fileUrl}`} width="100%" height="100%" alt="" onClick={() => showImg(`${getServerUrl()}/getUserFiles/${document.fileUrl}`)} />
                                                                        :
                                                                        <span></span>
                                                                }


                                                                {
                                                                    document.fileType === "pdf" ?
                                                                        <div className="pdf_preview" onClick={() => showFilePreview(document.fileUrl)} ><img src="/assets/images/pngs/pdf-icon.png" alt="line graph" /></div>
                                                                        :
                                                                        <span></span>
                                                                }


                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <div className="form_button">
                                                {splitUrlArray[4] === "user" ?
                                                    <button type="button" onClick={() => { history.push("/user"); dispatch({ type: "SHOW_APPLICATION_PROCESS_NAV" }); }} className="btn form_cancel_btn me-2">Cancel</button>
                                                    : <span></span>
                                                }
                                                {
                                                    isSubmitted
                                                        ?
                                                        (splitUrlArray[4] === "user") ?
                                                            <button type="button" onClick={() => {
                                                                goToHome();
                                                            }} className="btn form_submit_btn">Go Home</button>
                                                            : <span></span>
                                                        :
                                                        <button type="button" onClick={() => {
                                                            submitApplicationForm();
                                                        }} className="btn form_submit_btn ms-2">Submit</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div >
                {/*  Document preview modal box */}
            </section>
            {showDocumentPreviewModal ?
                <div className="document_preview_modal">
                    <div className="document_preview">
                        <span onClick={() => setShowDocumentPreviewModal(false)}><i className="fa fa-times" aria-hidden="true"></i></span>
                        <img src={modalBoxImgSrc} className="img-fluid" />
                    </div>
                </div>
                : <span></span>
            }
        </>

    );
}
export default DocumentSubmissionDetails;