import axios from "axios";
const serverDevUrl = "http://localhost:3700";
const awsServerDevUrl = "http://ec2-13-233-236-197.ap-south-1.compute.amazonaws.com:3700";
const serverProdUrl = "";
const ngrokUrl = "http://67fe-2405-201-1004-e0c7-816e-9396-6036-886a.ngrok.io";
const newNgrokUrl = "http://bda0-47-247-214-149.ngrok.io";
const contoboUrl = "http://194.233.89.236:3700";
const machine_Ip = "http://192.168.29.169:3700";

export const getServerUrl = () => {
    //  get node environment variable 
    const nodeEnvirnment = process.env.REACT_APP_NODE_ENV;

    if (nodeEnvirnment === "development") {
        return serverDevUrl;
    }

    if (nodeEnvirnment === "production") {
        return process.env.REACT_APP_BACKEND_SERVER_IP;
    }

    if (nodeEnvirnment === "contobo_dev") {
        return contoboUrl;
    }
    if (nodeEnvirnment === "machine_Ip") {
        return machine_Ip;
    }

    return process.env.REACT_APP_BACKEND_SERVER_IP;
}

function getCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if ("sjsadFrontOfficeToken" == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}


function getJwtTokenFromCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if ("sjsadFrontOfficeToken" == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}

export const frontOfficeCommunication = {

    loginFrontOffice: (frontOfficeCredentials) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/frontOffice/login-office-user`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify(frontOfficeCredentials)); // Converting JS object to JSON to send to server
        });
    },

    getNewApplications: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/frontOffice/get-user-new-applications?page=${page}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    //------------------------------------------- AcknowledgedApplications----------------------------------
    getAcknowledgedApplications: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/frontOffice/get-acknowledged-user-applications?page=${page}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
getShortListApplications: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/frontOffice/get-verified-applications?page=${page}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    getMeritListApplications: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/frontOffice/get-rated-applications?page=${page}`,true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    getApprovedListApplications: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/frontOffice/get-approved-revoked-replaced-applications?page=${page}`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    getWaitingListApplications: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/frontOffice/get-waiting-applications`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    acknowledgeNewApplication: (userAction, comment, applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/frontOffice/acknowledge-new-application`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ action: userAction, comment: comment, applicationId: applicationId })); // Converting JS object to JSON to send to server
        });
    },
    getUserApplicationById: (applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/frontOffice/get-user-application-by-id`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ applicationId: applicationId })); // Converting JS object to JSON to send to server
        });
    },
    getOfficeUserAuthenticateByJWT: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/frontOffice/get-office-user-authenticate-by-jwt`, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                // Converting response from json to js objects
                const response = JSON.parse(json);
                // Attaching jwt from cookie itself as it is validated by the server.
                response.jwt = getCookie();
                resolve(response);
            };
            xhr.send();
        })
    },
    verifyApplication: (userAction, comment, applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/frontOffice/verify-application`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ action: userAction, comment: comment, applicationId: applicationId })); // Converting JS object to JSON to send to server
        });
    },
    rejectApplication: (userAction, comment, applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/frontOffice/reject-application`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ action: userAction, comment: comment, applicationId: applicationId })); // Converting JS object to JSON to send to server
        });
    },
    submitApplication: (userAction, comment, applicationId, marks) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/frontOffice/rate-application`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ action: userAction, comment: comment, applicationId: applicationId, marks: marks })); // Converting JS object to JSON to send to server
        });
    },
    submitSelectedApplicationsList: (applicationIds, action) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/frontOffice/approved-applications`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ applicationIds: applicationIds, action: action })); // Converting JS object to JSON to send to server
        });
    },
    sendApplicationToWaitingList: (applicationIds, action) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/frontOffice/waiting-list-application`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ applicationIds: applicationIds, action: action })); // Converting JS object to JSON to send to server
        });
    },
    sendApplicationToRevokedList: (applicationIds, action) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/frontOffice/revoked-application`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ applicationIds: applicationIds, action: action })); // Converting JS object to JSON to send to server
        });
    },


    createMinutesOfMeeting: (uploadedFile, documentName, documentType, title, description) => {
        return axios.post(`${getServerUrl()}/frontOffice/add-minutes-of-meeting?title=${title}&description=${description}&documentName=${documentName}&documentType=${documentType}`, uploadedFile, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getMinutesOfMeetingList: (page) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/frontOffice/get-minutes-of-meeting?page=${page}`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(); // Converting JS object to JSON to send to server
        });
    },
    getMinutesOfMeetingFile: (title, uploadedFile) => {
        return axios.get(`${getServerUrl()}/frontOffice/get-minutes-of-meeting-file?title=${title}&uploadedFile=${uploadedFile}`, {
            headers: {
                "Content-Type": "application/pdf",
                "Authorization": `Bearer ${getCookie()}`
            },
            responseType: "blob"
        }).then(response => {
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], {
                type: "application/pdf"
            });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        }).catch(error => {
            console.log(error);
        })
    },
    sendToRevokedApplication: (applicationIds, action) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/frontOffice/revoked-application`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ applicationIds: applicationIds, action: action })); // Converting JS object to JSON to send to server
        });
    },
    submitWaitingListApplicationTOApproved: (applicationIds, action) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/frontOffice/approved-application-from-waiting-list`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ applicationIds: applicationIds, action: action })); // Converting JS object to JSON to send to server
        });
    },
    accessToApplication: (applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/frontOffice/get-access-to-application`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ applicationId: applicationId })); // Converting JS object to JSON to send to server
        });
    },
    closeAccessToApplication: (applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/frontOffice/close-access-to-application`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ applicationId: applicationId })); // Converting JS object to JSON to send to server
        });
    },
    submitGrApprovedList: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/frontOffice/create-gr`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({})); // Converting JS object to JSON to send to server
        });
    },
    updateQuota: (courses) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/frontOffice/update-quota`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ courses })); // Converting JS object to JSON to send to server
        });
    },
    getQuotaDetailsFromServer: () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("get", `${getServerUrl()}/frontOffice/get-quota-details`, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + getCookie());
            xhr.onload = () => {
                const json = xhr.responseText;
                resolve(JSON.parse(json));
            };
            xhr.send();
        });
    },
    incompleteApplication: (userAction, comment, applicationId) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("post", `${getServerUrl()}/frontOffice/incomplete-application`, true);
            xhr.setRequestHeader("Authorization", "Bearer " + getCookie());
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.onload = () => {
                const json = xhr.responseText; // Response data is in JSON (Javascript object notation - string ).
                resolve(JSON.parse(json)); // Converting JSON data to Javascript object and return it.
            };
            xhr.send(JSON.stringify({ action: userAction, comment: comment, applicationId: applicationId })); // Converting JS object to JSON to send to server
        });
    },

    getCourseWiseApplicationsDetails: () => {
        return axios.get(`${getServerUrl()}/frontOffice/get-course-wise-applications`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }

        })
    },
    getNewApplicationsBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/frontOffice/search-user-new-application`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            })
        } catch (error) {
            alert(error?.message);
        }
    },
    getAcknowledgedApplicationsBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/frontOffice/search-acknowledged-application`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            })
        } catch (error) {
            alert(error?.message);
        }
    },
    getShortListApplicationsBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/frontOffice/search-verified-applications`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            })
        } catch (error) {
            alert(error?.message);
        }
    },
    getMeritListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/frontOffice/search-rated-applications`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            })
        } catch (error) {
            alert(error?.message);
        }
    },
    getApprovedListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/frontOffice/search-approved-revoked-replaces-applications`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            })
        } catch (error) {
            alert(error?.message);
        }
    },
    getwaitListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/frontOffice/search-waiting-list-application`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            })
        } catch (error) {
            alert(error?.message);
        }
    },

    getMinutesOfMeetingListBySearch: (searchString, page) => {
        try {
            return axios.post(`${getServerUrl()}/frontOffice/search-minutes-of-meetings`, { searchString, page }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getCookie()}`
                }
            })
        } catch (error) {
            alert(error?.message);
        }
    },
}





