import React from "react";
import { Button, Modal } from "react-bootstrap";

// const TextModal = ({ text }) => {
//     return (
//         <>

//             <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
//                 <div className="modal-dialog" role="document">
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <button type="button" className="close" data-dismiss="modal" aria-label="Close">
//                                 <span aria-hidden="true">&times;</span>
//                             </button>
//                         </div>
//                         <div className="modal-body">
//                             <p>{text}</p>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//         </>
//     )
// }

// export default TextModal;


function TextModal(props) {
    return (
        <Modal className="modal"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/* <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Message
                </Modal.Title>
            </Modal.Header> */}
            <Modal.Body className="modal_body">
                <h4>Message</h4>
                <p>{props.text}</p>
            </Modal.Body>
            <Modal.Footer className="modal_footer">
                <Button className="modal_button" onClick={props.onHide}>Ok</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TextModal;


