import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AdminLogoutBox from './AdminLogoutBox';

const AdminLogout = () => {
    const [selectedNavItem, setSelectedNavItem] = useState(false);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    function logOutBox() {
        setSelectedNavItem(false);
    }
    return (
        <>
            <div className='Admin_profile_tab' onClick={() => { setSelectedNavItem("ADMIN_LOGOUT"); }}  >
                <a>
                    {darkWhiteThemeToggle === "WHITE" ? <i className="fa fa-user-circle-o" style={{ color: "black" }} aria-hidden="true"></i> : <i className="fa fa-user-circle-o" style={{ color: "white" }} aria-hidden="true"></i>}
                </a>
            </div>
            {selectedNavItem === "ADMIN_LOGOUT" ? <AdminLogoutBox hideLogoutBox={logOutBox} /> : <span></span>}

        </>

    )
};

export default AdminLogout;
