import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import H4 from "../text-components/H4";
import { communication } from "../communications/communication"
import fileDownload from 'js-file-download';
import LoadingForms from "../shared-components/LoadingForms";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";


const DownloadsInfoBigSize = () => {
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [downloadData, setDownloadData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const dispatch = useDispatch();
    const history = new useHistory();

    //Alert Modal react-redux
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }
    // Spinner show and hide
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    //onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            getDownloadsBySearch(searchValue, selectedPage + 1);
        } else {
            fetchDownloads(selectedPage + 1);
        }
    }


    //Search Filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            getDownloadsBySearch(searchString, currentPage + 1);
        } else {
            const results = await communication.getUploadDownloadDocumentsForUser();
            if (results.status === "SUCCESS") {
                setDownloadData(results.documents);
                setPageCount(results?.totalPages);
            }
            else {
                setDownloadData([]);
                setPageCount(0);
            }
        }
    }

    //get Remittance By Search
    const getDownloadsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.getDownloadsBySearch(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setDownloadData(responseFromServer?.data?.documents);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                showModal(responseFromServer?.data?.message);
                history.push("/login");
            } else {
                setDownloadData([]);
                setPageCount(0);
            }
        } catch (error) {
            showModal(error?.message);
        }
    }


    //get Downloads data on Initial Load
    const fetchDownloads = async (page) => {
        try {
            showLoading("Loading...");
            // to show active tab
            let fullUrl = window.location.href;
            let splitDownloadsInfoUrl = fullUrl.split('/');
            if (splitDownloadsInfoUrl.includes("downloadsInfoBigSize")) {
                dispatch({ type: "SHOW_ACTIVE_APPLICATION_PROCESS_TAB", payload: "DOWNLOADSINFOBIGSIZE" });
            } else {
                dispatch({ type: "HIDE_ACTIVE_APPLICATION_PROCESS_TAB" });
            }
            const results = await communication.getUploadDownloadDocumentsForUser(page);
            if (results.status === "SUCCESS") {
                setDownloadData(results.documents);
                setPageCount(results?.totalPages);
            }
            else {
                showModal(results.message);
                setDownloadData([]);
                setPageCount(0);
            }
            hideLoading();
        } catch (error) {
            showModal(error.message);
        }
    }

    useEffect(() => {
        fetchDownloads();
    }, []);


    async function downloadDocument(id, attachment) {
        // if the attachment is empty or not
        let attachmentSplit = attachment.split("/");
        if (id !== "") {
            const getDownloadDocuments = await communication.getDownloadPdf(id);
            // fileDownload(getDownloadDocuments, attachmentSplit[1]);
            return getDownloadDocuments;
        }
    }


    return (

        <>
            <section className="application_flow_big" >
                {
                    isLoading.value ?
                        <LoadingForms message={isLoading.message} />
                        :
                        <div className="container ">
                            <div className="row">
                                <div className="row ps-0 pe-0 search_wrapper">
                                    <div className="col-lg-4 col-md-4 ps-0">
                                        <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Subject" />
                                    </div>
                                    <div className="col-lg-8 col-md-8 pe-0 pagination_outer_wrapper">
                                        <div className="pagination_inner_wrapper">
                                            {pageCount > 1 &&
                                                <ReactPaginate
                                                    previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                                    nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    forcePage={currentPage}
                                                    activeClassName={"active"} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                {downloadData?.length > 0 ?
                                    <div className="col-12">
                                        {downloadData?.map((download, index) => {
                                            const { _id, subject, description, attachment } = download;
                                            return (
                                                <div key={index}>
                                                    <div className="row downloadinfo_card_apply">
                                                        <div className="col-lg-10 col-md-9 col-8 info_align">
                                                            <ul style={{ listStyleType: "none" }}>
                                                                <li style={{ color: darkWhiteThemeToggle === "WHITE" ? "#000000" : "#FFFFFF", }}>{subject}</li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-2 col-md-3 col-4">
                                                            <div className="download_user_button" >
                                                                <H4 style={{ alignSelf: "end", cursor: "pointer" }}><span onClick={() => downloadDocument(_id, attachment)}>Download</span></H4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    :
                                    <div className="col-12 text-center view_download_dashboard">
                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">Download documents not available.</H4>
                                    </div>

                                }
                            </div>
                        </div>
                }
            </section>
        </>


    );
}

export default DownloadsInfoBigSize;