import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import BackOfficeLogoutBox from './BackOfficeLogoutBox';

const BackOfficeLogout = () => {

    const [selectedNavItem, setSelectedNavItem] = useState(false);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);

    function logOutBox() {
        setSelectedNavItem(false);
    }
    return (
        <>
            <div className='profile_tab' onClick={() => { setSelectedNavItem("BACK_OFFICE_LOGOUT"); }}  >
                <a>
                    {darkWhiteThemeToggle === "WHITE" ? <i className="fa fa-user-circle-o" style={{ color: "black" }}></i> : <i className="fa fa-user-circle-o" style={{ color: "white" }}></i>}
                </a>
            </div>
            {selectedNavItem === "BACK_OFFICE_LOGOUT" ? <BackOfficeLogoutBox hideLogoutBox={logOutBox} /> : <span></span>}

        </>

    )
};

export default BackOfficeLogout;
