import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import H4 from "../text-components/H4";
import { communication } from '../communications/communication';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormik } from 'formik';
import MulipleDocumentUpload from '../shared-components/MulipleDocumentUpload';
import LoadingForms from "../shared-components/LoadingForms";
import { removeUnderscoreFromText } from "../utlis/helper";
import UpdateUserMarksheetDetail from './UpdateUserMarksheetDetail';
import PreviewUserMarksheetDetail from './PreviewUserMarksheetDetail';
import UserMarksheetDetail from './UserMarksheetDetail';
import UserMarksheetDetailForAutoTriggeredMilestones from './UserMarksheetDetailForAutoTriggeredMilestones';
import UpdateUserMarksheetForAutoTriggeredMilestones from './UpdateUserMarksheetForAutoTriggeredMilestones';
import Label from "../text-components/Label";
import UploadRequiredDocuments from './UploadRequiredDocuments';


const UserMilestoneGeneral = () => {

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);
    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [userAcknowledgeView, setExpensesReportUrl] = useState();
    const [expenditureReport, setExpenditureReport] = useState([]);
    const [otherDocumentsUrl, setOtherDocumentsUrl] = useState([]);
    const [showMarksheetDetailForm, setShowMarksheetDetailForm] = useState(false);
    const [showUpdateMarksheetDetailForm, setShowUpdateMarksheetDetailForm] = useState(false);
    const [requiredDocumentsForm, setRequiredDocumentsForm] = useState(false);
    const [hideSubjectTabel, setHideSubjectTable] = useState(true);
    const history = useHistory();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [initialFormValues, setInitialFormValues] = useState(null);
    const [remittance, setRemittance] = useState();
    const [semesterId, setSemesterId] = useState();
    const [userId, setUserId] = useState();
    const [semesterStatus, setSemesterStatus] = useState("");

    // getting committee id by queryParams
    const queryParams = new URLSearchParams(location.search);
    let milestoneId = queryParams.get('milestoneId');
    const applicationId = queryParams.get('applicationId');


    const [milestoneDocument, setMilestoneDocument] = useState({});

    const CloseUserMilestone5 = () => {
        history.push("/user/doc-submission");
    }

    function getFilePath(e) {
        setExpensesReportUrl(e.currentTarget.files[0]);
        setFieldValue('userAcknowledgeView', e.currentTarget.files[0]);
    }

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }
    //onClick redirecting user to personal details form
    const formStatus = async (applicationId) => {
        history.push(`/user/application/?applicationId=${applicationId}`);
    }

    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    useEffect(() => {

        const milestoneId = queryParams.get('milestoneId');

        getMileStoneDocument(applicationId);
    }, []);

    async function getMileStoneDocument() {
        try {
            showLoading("Loading...");
            const result = await communication.getUserMilestoneById(milestoneId);
            if (result.status === "SUCCESS") {
                setMilestoneDocument(result?.milestone);
                setRemittance(result?.remittance);
                setSemesterId(result?.milestone?.semesterId);
                setUserId(result?.milestone?.userId);
                fetchSemesterDetail(result?.milestone?.semesterId);

            } else {
                showModal(result?.message);
            }
            hideLoading();
            return;

        } catch (error) {
            showModal(error?.message);
            hideLoading();
            return;
        }

    }

    //Get semester Detail
    const fetchSemesterDetail = async (id) => {
        try {
            const getSemesterDetail = await communication.getUserSemesterMarksBySemesterId(id);
            if (getSemesterDetail?.status === "SUCCESS") {
                setSemesterStatus(getSemesterDetail?.userSemMarks?.status);
            } else {

            }
        } catch (error) {
            alert(error.message);
        }
    }

    const defaultFormValues = {
        userAcknowledgeView: null,
    }
    const validationSchema = Yup.object().shape({
        userAcknowledgeView: Yup.mixed().required(),
    });

    const { handleSubmit, handleChange, setFieldValue, values, errors, handleReset } = useFormik({
        initialValues: initialFormValues === null ? defaultFormValues : initialFormValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {

            try {
                showLoading("Uploading...");

                let formData = new FormData();
                if (values.userAcknowledgeView === null || values.userAcknowledgeView === "") {
                    showModal("Please Attached file");
                    hideLoading();
                    return;
                }
                formData.append("document", values.userAcknowledgeView, values.userAcknowledgeView.name);


                const uploadResult = await communication.uploadUserAcknowledgeReportFromUniversity(formData, applicationId);
                if (uploadResult.data.status === "SUCCESS") {
                    showModal("Document uploaded successfully.")
                } else {
                    showModal(uploadResult.data.message);
                }

                handleReset();
                hideLoading();
                return true;

            } catch (error) {
                hideLoading();
                showModal(error.message);
                return false
            }
        },
        validationSchema: validationSchema,
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const downloadDocumentFromServer = async (attachment) => {
        if (attachment !== "") {
            await communication.getMilestoneDocumentfromServer(attachment);
        }
    }

    function onFileSelect(event) {
        event.preventDefault();
        let fileName = event.target.files[0];
        setExpenditureReport(fileName);
        setFieldValue('expenditureReport', event.target.files[0]);
    }

    async function submitHandler() {

        try {
            // Show loading 
            showLoading("Uploading...");

            const formData = new FormData();
            if (expenditureReport.length === 0) {
                showModal("Please Attached expenditure Report");
                hideLoading();
                return;
            } else {
                formData.append("expenditureReport", expenditureReport, "expenditureReport");
            }

            // if (otherDocumentsUrl.length === 0) {
            //     showModal("Please Attached document");
            //     hideLoading();
            //     return;
            // }

            // add document name to files
            let newDocumentArray = [];

            for (let index = 0; index < otherDocumentsUrl.length; index++) {
                const element = otherDocumentsUrl[index];
                element.file.documentName = element['documentName'];
                newDocumentArray.push(element.file);
            }
            //  convert all documents to form data
            newDocumentArray.forEach((ele) => {
                formData.append('document', ele, ele.documentName);
            });
            // Send values to the server

            const serverResponseOfUpdateMilestoneDocuments = await communication.updateIncompleteMilestone(milestoneDocument._id, applicationId, formData);

            // Handle response from the server
            if (serverResponseOfUpdateMilestoneDocuments.data.status === "SUCCESS" || serverResponseOfUpdateMilestoneDocuments.status === "SUCCESS") {
                history.push("/user/doc-submission");
                hideLoading(); // Hide spinner
                showModal(serverResponseOfUpdateMilestoneDocuments.data.message || serverResponseOfUpdateMilestoneDocuments.message);

            } else {
                //  Show response message from server in modal dialog
                showModal(serverResponseOfUpdateMilestoneDocuments.data.message || serverResponseOfUpdateMilestoneDocuments.message);
                hideLoading(); // Hide spinner 
            }

        }
        catch (err) {
            showModal(err?.message);
            hideLoading();
        }
    }


    return (
        <>

            {milestoneDocument?.userStatus === "IN_COMPLETED" && ["NEW_SESSION"].includes(milestoneDocument.type) ?
                <section className="application_flow_big" >

                    {isLoading?.value ?
                        <LoadingForms message={isLoading?.message} />
                        :
                        <div className="new_milestone_modal_box">

                            <H4 style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> {removeUnderscoreFromText(milestoneDocument?.name)} </H4>

                            <div className="row mt-4">
                                <div className="col-5">
                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Expenditure Document </H4>
                                </div>
                                <div className="col-5">
                                    <input type="file" name="document" onChange={(e) => { onFileSelect(e); }} value={values.document?.filename} className={`form-control form-control-sm ${errors.document ? "is-invalid" : ""}`} />
                                    <div className="invalid-feedback">{errors?.document}</div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <MulipleDocumentUpload
                                        setSelectedDocumentArray={setOtherDocumentsUrl}
                                        selectedDocumentArray={otherDocumentsUrl}
                                        title={"documents"}
                                        status={"NEW"}
                                    // uploadedDocumentArray={milestoneDetailsData?.documentsArray}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-12 create_milestone_btn mt-2" style={{ textAlign: "right" }}>
                                <button type="submit" onClick={submitHandler} >Submit</button>
                                <button className='ms-3' onClick={() => CloseUserMilestone5()} >Cancel</button>
                            </div>
                            <div>
                                <span style={{ fontSize: "14px", color: "rgb(109, 141, 173)" }}><b>*NOTE:- format support pdf & size must be less than 2mb.</b></span>
                            </div>

                        </div>
                    }
                </section>
                :
                <div className="user_milestone_main">
                    {isLoading?.value ?
                        <LoadingForms message={isLoading?.message} />
                        :
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    {/* check Milestone is incomplete on not*/}

                                    <div className="user_milestone">
                                        <div className="user_milestone_header">
                                            <H4 style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> {removeUnderscoreFromText(milestoneDocument?.name)} </H4>
                                        </div>
                                        <div className="user_milestone_body">
                                            {
                                                milestoneDocument?.userStatus !== "" && <>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Status: {removeUnderscoreFromText(milestoneDocument?.userStatus)} </H4>
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            {/* show uploaded documents here */}

                                            {/* Expenditure report url */}
                                            {
                                                !!milestoneDocument?.expenditureReportUrl && milestoneDocument?.expenditureReportUrl?.map((ele, idx) => {
                                                    const { documentName, fileUrl } = ele;
                                                    return (
                                                        <div className="row mt-3" key={idx}>
                                                            <div className="col-8">
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Expenditure report :  </H4>
                                                            </div>
                                                            <div className="col-4">
                                                                <button onClick={() => downloadDocumentFromServer(fileUrl)}>Preview</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/*  user uploaded form */}


                                            {
                                                milestoneDocument?.hostelFeesReportUrl && milestoneDocument?.hostelFeesReportUrl !== "" && <div className="row mt-3">
                                                    <div className="col-8">
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Hostel Fees Report :  </H4>
                                                    </div>
                                                    <div className="col-4">
                                                        <button onClick={() => downloadDocumentFromServer(milestoneDocument.hostelFeesReportUrl)}>Preview</button>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                milestoneDocument?.marksheetReportUrl && milestoneDocument?.marksheetReportUrl !== "" && <div className="row mt-3">
                                                    <div className="col-8">
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>MarkSheet Report : </H4>
                                                    </div>
                                                    <div className="col-4">
                                                        <button onClick={() => downloadDocumentFromServer(milestoneDocument.marksheetReportUrl)}>Preview</button>
                                                    </div>
                                                </div>
                                            }

                                            {/* {["IN_COMPLETED", "REJECTED"].includes(milestoneDocument?.userStatus) &&
                                                <>
                                                    <div className="row mt-3">
                                                        <div className="col-8">
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Edit Subject Marks : </H4>
                                                        </div>
                                                        <div className="col-4">
                                                            <button onClick={() => setShowUpdateMarksheetDetailForm(true)}>Edit</button>
                                                        </div>
                                                    </div>
                                                    {showUpdateMarksheetDetailForm &&
                                                        <div className="upload_marks_detail">
                                                            <UpdateUserMarksheetDetail getMileStoneDocument={getMileStoneDocument} applicationId={applicationId} semesterId={semesterId} setShowUpdateMarksheetDetailForm={setShowUpdateMarksheetDetailForm} />
                                                        </div>
                                                    }
                                                </>
                                            } */}

                                            {["SEM_FEES"].includes(milestoneDocument.type) && ["NEW"].includes(milestoneDocument?.userStatus) && (!!milestoneDocument?.semesterId === false) &&

                                                <>
                                                    {hideSubjectTabel &&
                                                        <>
                                                            <div className="row mt-3">
                                                                <div className="col-8">
                                                                    <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Upload Required Documents : </H4>
                                                                </div>
                                                                <div className="col-4">
                                                                    <button style={{backgroundColor:"rgb(109, 141, 173)", color:"#fff"}} onClick={() => setShowMarksheetDetailForm(true)}>Upload</button>
                                                                </div>
                                                            </div>
                                                            {showMarksheetDetailForm &&
                                                                <div className="upload_marks_detail">
                                                                    <UserMarksheetDetailForAutoTriggeredMilestones getMileStoneDocument={getMileStoneDocument} milestoneName={milestoneDocument?.name} milestoneId={milestoneId} setShowMarksheetDetailForm={setShowMarksheetDetailForm} setHideSubjectTable={setHideSubjectTable} applicationId={applicationId} />
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }


                                            {["SEM_FEES", "COURSE_COMPLETION"].includes(milestoneDocument.type) && ["SUBMITTED", "APPROVED"].includes(milestoneDocument?.userStatus) && (!!milestoneDocument?.semesterId) &&
                                                <>
                                                    <div className="row mt-3">
                                                        <div className="col-8">
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Subject Marks Report : </H4>
                                                        </div>
                                                        <div className="col-4">
                                                            <button onClick={() => setShowUpdateMarksheetDetailForm(true)}>Preview</button>
                                                        </div>
                                                    </div>
                                                    {showUpdateMarksheetDetailForm &&
                                                        <div className="upload_marks_detail">
                                                            <PreviewUserMarksheetDetail userId={userId} semesterId={semesterId} setShowUpdateMarksheetDetailForm={setShowUpdateMarksheetDetailForm} />
                                                        </div>
                                                    }
                                                </>
                                            }
                                            {["SEM_FEES", "COURSE_COMPLETION"].includes(milestoneDocument.type) && ["IN_COMPLETED"].includes(milestoneDocument?.userStatus) && ["NEW", "APPROVED"].includes(semesterStatus) && (!!milestoneDocument?.semesterId) &&
                                                <>
                                                    <div className="row mt-3">
                                                        <div className="col-8">
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Subject Marks Report: </H4>
                                                        </div>
                                                        <div className="col-4">
                                                            <button onClick={() => setShowUpdateMarksheetDetailForm(true)}>Preview</button>
                                                        </div>
                                                    </div>
                                                    {showUpdateMarksheetDetailForm &&
                                                        <div className="upload_marks_detail">
                                                            <PreviewUserMarksheetDetail userId={userId} semesterId={semesterId} setShowUpdateMarksheetDetailForm={setShowUpdateMarksheetDetailForm} />
                                                        </div>
                                                    }
                                                </>
                                            }

                                            {["SEM_FEES", "COURSE_COMPLETION"].includes(milestoneDocument.type) && ["IN_COMPLETED", "REJECTED"].includes(milestoneDocument?.userStatus) && ["REJECT"].includes(semesterStatus) &&
                                                <>
                                                    <div className="row mt-3">
                                                        <div className="col-8">
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Edit Subject Marks : </H4>
                                                        </div>
                                                        <div className="col-4">
                                                            <button style={{backgroundColor:"rgb(109, 141, 173)", color:"#fff"}} onClick={() => setShowUpdateMarksheetDetailForm(true)}>Edit</button>
                                                        </div>
                                                    </div>
                                                    {showUpdateMarksheetDetailForm &&
                                                        <div className="upload_marks_detail">
                                                            <UpdateUserMarksheetForAutoTriggeredMilestones getMileStoneDocument={getMileStoneDocument} semesterId={semesterId} applicationId={applicationId} setShowUpdateMarksheetDetailForm={setShowUpdateMarksheetDetailForm} />
                                                        </div>
                                                    }
                                                </>
                                            }

                                            {["SEM_FEES", "COURSE_COMPLETION"].includes(milestoneDocument.type) && ["IN_COMPLETED", "REJECTED"].includes(milestoneDocument?.userStatus) && ["NEW", "APPROVED"].includes(semesterStatus) &&
                                                <>
                                                    <div className="row mt-3">
                                                        <div className="col-8">
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}>Upload Required Documents : </H4>
                                                        </div>
                                                        <div className="col-4">
                                                            <button style={{backgroundColor:"rgb(109, 141, 173)", color:"#fff"}} onClick={() => setRequiredDocumentsForm(true)}>Upload</button>
                                                        </div>
                                                    </div>
                                                    {requiredDocumentsForm &&
                                                        <div className="upload_marks_detail">
                                                            <UploadRequiredDocuments getMileStoneDocument={getMileStoneDocument} milestoneId={milestoneId} applicationId={applicationId} setRequiredDocumentsForm={setRequiredDocumentsForm} />
                                                        </div>
                                                    }
                                                </>
                                            }

                                            {
                                                milestoneDocument?.attendenceReportUrl && milestoneDocument?.attendenceReportUrl !== "" && <div className="row mt-3">
                                                    <div className="col-8">
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Attendance Report : </H4>
                                                    </div>
                                                    <div className="col-4">
                                                        <button onClick={() => downloadDocumentFromServer(milestoneDocument.attendenceReportUrl)}>Preview</button>
                                                    </div>
                                                </div>
                                            }


                                            {
                                                milestoneDocument?.foodExpenseReportUrl && milestoneDocument?.foodExpenseReportUrl !== "" && <div className="row mt-3">
                                                    <div className="col-8">
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Foods Expense Report :  </H4>
                                                    </div>
                                                    <div className="col-4">
                                                        <button onClick={() => downloadDocumentFromServer(milestoneDocument?.foodExpenseReportUrl)}>Preview</button>
                                                    </div>
                                                </div>
                                            }


                                            {/*  user uploaded form */}
                                            {/* Office expenditure url */}


                                            {
                                                milestoneDocument?.officeExpenditureReportUrl && milestoneDocument?.officeExpenditureReportUrl !== "" && milestoneDocument?.backOfficeStatus === "APPROVED" && milestoneDocument?.userStatus === "APPROVED" ?
                                                    <div className="row mt-3">
                                                        <div className="col-8">
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Office Expenditure report :  </H4>
                                                        </div>
                                                        <div className="col-4">
                                                            <button onClick={() => downloadDocumentFromServer(milestoneDocument?.officeExpenditureReportUrl)}>Preview</button>
                                                        </div>
                                                    </div>
                                                    :
                                                    ""
                                            }

                                            {
                                                milestoneDocument?.userFundsReceiptUrl && milestoneDocument?.userFundsReceiptUrl !== "" && milestoneDocument?.userFundsReceiptUrl.length > 0 && <div className="row mt-3">
                                                    <div className="col-12">
                                                        <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Funds Received url : </H4>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                milestoneDocument?.userFundsReceiptUrl && milestoneDocument?.userFundsReceiptUrl?.length > 0 && milestoneDocument?.userFundsReceiptUrl.map((ele, idx) => {
                                                    return (
                                                        <div className="row mt-3" key={idx}>
                                                            <div className="col-8">
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" > {ele?.documentName} </H4>
                                                            </div>
                                                            <div className="col-4">
                                                                <button onClick={() => downloadDocumentFromServer(ele?.fileUrl)}>Preview</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                            {/* {
                                    milestoneDocument?.treasuryConfirmationReceipt && milestoneDocument?.treasuryConfirmationReceipt !== "" && <div className="row mt-3">
                                        <div className="col-10">
                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> Treasury Confirmation Receipt:  </H4>
                                        </div>
                                        <div className="col-2">
                                            <button onClick={() => downloadDocumentFromServer(milestoneDocument?.treasuryConfirmationReceipt)}>Preview</button>
                                        </div>
                                    </div>
                                } */}

                                            {
                                                milestoneDocument?.userAckReportUrl && milestoneDocument?.userAckReportUrl !== "" && milestoneDocument?.userAckReportUrl.length > 0 && <>
                                                    <div className="row mt-3">
                                                        <div className="col-12">
                                                            <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" style={{ fontSize: `${18 * fontSizeMultiplier / 100}px` }}> User Acknowledged Receipt : </H4>
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            {

                                                milestoneDocument?.userAckReportUrl && milestoneDocument?.userAckReportUrl.length > 0 && milestoneDocument?.userAckReportUrl.map((ele, idx) => {
                                                    return (
                                                        <div className="row mt-3" key={idx}>

                                                            <div className="col-8">
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF"> {ele?.documentName} </H4>
                                                            </div>
                                                            <div className="col-4">
                                                                <button onClick={() => downloadDocumentFromServer(ele?.fileUrl)}>Preview</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                milestoneDocument?.otherDocuments && milestoneDocument?.otherDocuments.length > 0 && milestoneDocument?.otherDocuments.map((ele, idx) => {
                                                    return (
                                                        <div className="row mt-3" key={idx}>
                                                            <div className="col-8">
                                                                <H4 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" > {ele?.documentName} </H4>
                                                            </div>
                                                            <div className="col-2">
                                                                <button onClick={() => downloadDocumentFromServer(ele?.fileUrl)}>Preview</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* show uploaded documents here */}
                                            <div className="row mt-4">
                                                <div className="col-12" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                    <button className="ms-4" onClick={() => CloseUserMilestone5()}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    }
                </div>

            }
        </>
    );
}
export default UserMilestoneGeneral;