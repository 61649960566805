import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const FrontOfficeLogoutBox = ({ hideLogoutBox }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const officeUser = useSelector((state) => state?.isOfficeUserAuthenticated?.officeUser)

    // logout 
    function logoutFrontOfficeUser() {
        dispatch({ type: "DEAUTHENTICATE_FRONTOFFICEUSER" });
        document.cookie = "sjsadFrontOfficeToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        history.push('/front-office/login');
    }


    return (
        <>
            <div className="row logout_box_front">
                <span onClick={() => { hideLogoutBox() }}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                </span>
                <div className="col-12" style={{ marginBottom: "0px", marginTop: "5px" }}>
                    <h5>Name : {officeUser?.name}</h5>
                    <h5>Id : {officeUser?.id}</h5>
                    <h5>Designation : {officeUser?.designation}</h5>
                    <button type="button" className="button" onClick={() => logoutFrontOfficeUser()} >
                        <h3>Logout</h3>
                    </button>
                </div>
            </div>
        </>
    )
};

export default FrontOfficeLogoutBox;
