import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import AdminWelcome from "./panels/AdminWelcome";
import DesignationMaintainanceView from "./panels/DesignationMaintainanceView";
import DesignationMaintainanceCreate from "./panels/DesignationMaintainanceCreate";
import DesignationMaintainanceUpdate from "./panels/DesignationMaintainanceUpdate";
import DepartmentMaintenanceView from "./panels/DepartmentMaintenanceView";
import UsersMaintenanceView from "./panels/UsersMaintenanceView";
import RoleMaintenanceView from "./panels/RoleMaintenanceView";
import CommitteeMaintenanceView from "./panels/CommitteeMaintenanceView";
import DepartmentMaintainanceCreate from "./panels/DepartmentMaintenanceCreate";
import UsersMaintainanceCreate from "./panels/UsersMaintenanceCreate";
import RoleMaintenanceCreate from "./panels/RoleMaintenanceCreate";
import CommitteeMaintenanceCreate from "./panels/CommitteeMaintenanceCreate";
import CommitteeMaintenanceUpdate from "./panels/CommitteeMaintenanceUpdate";
import CommitteeMaintenanceSave from "./panels/CommitteeMaintenanceSave";
import RoleMaintenanceUpdate from "./panels/RoleMaintenanceUpdate";
import RoleMaintenanceSave from "./panels/RoleMaintenanceSave";
import DepartmentMaintenanceUpdate from "./panels/DepartmentMaintenanceUpdate";
import UsersMaintenanceUpdate from "./panels/UsersMaintenanceUpdate";
import NotificationMaintenanceView from "./panels/NotificationMaintenanceView";
import NotificationMaintenanceCreate from "./panels/NotificationMaintenanceCreate";
import UploadData from "./panels/UploadData";
import DesignationMaintainanceSave from "./panels/DesignationMaintainanceSave";
import DepartmentMaintainanceSave from './panels/DepartmentMaintenanceSave';
import UsersMaintainanceSave from './panels/UsersMaintenanceSave';
import { useSelector, useDispatch } from "react-redux";
import NotificationMaintenanceUpdate from "./panels/NotificationMaintenanceUpdate";
import NotificationMaintenanceSave from "./panels/NotificationMaintenanceSave";
import H5 from "../text-components/H5";
import H3 from "../text-components/H3";
import AdminLogout from "./AdminLogout";
import UploadDocumentsCreate from "./panels/UploadDocumentsCreate";
import UploadDocumentsView from "./panels/UploadDocumentsView";
import UploadDocumentsUpdate from "./panels/UploadDocumentsUpdate";
import UploadDocumentsSave from "./panels/UploadDocumentsSave";
import UpdateQuote from "./panels/UpdateQuote";
import UpdateGraphData from "./panels/UpdataGraphData";
import { set } from "react-hook-form";

const AdminDashboard = () => {

    const fontSizeMultiplier = useSelector((state) => state.fontSizeMultiplier);

    const isAdminAuthenticated = useSelector((state) => state.isAdminAuthenticated.value ?? true);

    const selectedParentNav = useSelector((state) => state.adminPanelParentActiveTab);

    const [selectedDropdown, setSelectedDropdown] = useState(null);

    // Sidebar Nav selection//
    const [sidebarNav, setSidebarNav] = useState("");

    const [committeeId, setCommitteeId] = useState(null);

    const [roleId, setRoleId] = useState(null);

    const [designationId, setDesignationId] = useState(null);

    const [departmentId, setDepartmentId] = useState(null);

    const [userId, setUserId] = useState(null);

    const [notificationId, setNotificationId] = useState(null);

    const [documentId, setDocumentId] = useState(null);

    let [isUserVerifiedAgain, setIsUserVerifiedAgain] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    async function initialLoad(locationPAth) {
        // getting committee id by queryParams
        const queryParams = new URLSearchParams(location.search);
        const committeeIdFromParams = queryParams.get('committeeId');
        setCommitteeId(value => value = committeeIdFromParams);

        const roleIdFromParams = queryParams.get('roleId');
        setRoleId(value => value = roleIdFromParams);

        const designationIdFromParams = queryParams.get('designationId');
        setDesignationId(value => value = designationIdFromParams);

        const departmentIdFromParams = queryParams.get('departmentId');
        setDepartmentId(value => value = departmentIdFromParams);

        const userIdFromParams = queryParams.get('userId');
        setUserId(value => value = userIdFromParams);

        const notificationIdFromParams = queryParams.get('notificationId');
        setNotificationId(value => value = notificationIdFromParams);

        const documentIdFromParams = queryParams.get('documentId');
        setDocumentId(value => value = documentIdFromParams);

        setIsUserVerifiedAgain(true);

        // const queryParams = new URLSearchParams(location.search);
        // const applicationIdFromParams = await queryParams.get('applicationId');
        // setApplicationId(value => value = applicationIdFromParams);

        //------ useLocation redirect to component-----//
        switch (locationPAth) {
            case "/admin/dashboard/designation-maintenance-view":
                setSelectedDropdown("DESIGNATION_MAINTAINANCE")
                setSidebarNav("DESIGNATION_MAINTAINANCE_VIEW")
                break;

            case "/admin/dashboard/designation-maintenance-create":
                setSelectedDropdown("DESIGNATION_MAINTAINANCE")
                setSidebarNav("DESIGNATION_MAINTAINANCE_CREATE")
                break;

            case "/admin/dashboard/designation-maintenance-update":
                setSelectedDropdown("DESIGNATION_MAINTAINANCE")
                setSidebarNav("DESIGNATION_MAINTAINANCE_UPDATE")
                break;

            case "/admin/dashboard/department-maintenance-view":
                setSelectedDropdown("DEPARTMENT_MAINTENANCE")
                setSidebarNav("DEPARTMENT_MAINTENANCE_VIEW")
                break;

            case "/admin/dashboard/departments-maintenance-create":
                setSelectedDropdown("DEPARTMENT_MAINTENANCE")
                setSidebarNav("DEPARTMENT_MAINTENANCE_CREATE")
                break;

            case "/admin/dashboard/departments-maintenance-update":
                setSelectedDropdown("DEPARTMENT_MAINTENANCE")
                setSidebarNav("DEPARTMENT_MAINTENANCE_UPDATE")
                break;

            case "/admin/dashboard/users-maintenance-view":
                setSelectedDropdown("USERS_MAINTENANCE")
                setSidebarNav("USERS_MAINTENANCE_VIEW")
                break;

            case "/admin/dashboard/users-maintenance-create":
                setSelectedDropdown("USERS_MAINTENANCE")
                setSidebarNav("USERS_MAINTENANCE_CREATE")
                break;

            case "/admin/dashboard/users-maintenance-update":
                setSelectedDropdown("USERS_MAINTENANCE")
                setSidebarNav("USERS_MAINTENANCE_UPDATE")
                break;

            case "/admin/dashboard/roles-maintenance-view":
                setSelectedDropdown("ROLES_MAINTENANCE")
                setSidebarNav("ROLES_MAINTENANCE_VIEW")
                break;

            case "/admin/dashboard/roles-maintenance-create":
                setSelectedDropdown("ROLES_MAINTENANCE")
                setSidebarNav("ROLES_MAINTENANCE_CREATE")
                break;

            case "/admin/dashboard/roles-maintenance-update":
                setSelectedDropdown("ROLES_MAINTENANCE")
                setSidebarNav("ROLES_MAINTENANCE_UPDATE")
                break;

            case "/admin/dashboard/committee-maintenance-view":
                setSelectedDropdown("COMMITTEE_MAINTENANCE")
                setSidebarNav("COMMITTEE_MAINTENANCE_VIEW")
                break;

            case "/admin/dashboard/committee-maintenance-create":
                setSelectedDropdown("COMMITTEE_MAINTENANCE")
                setSidebarNav("COMMITTEE_MAINTENANCE_CREATE")
                break;

            case "/admin/dashboard/committee-maintenance-update":
                setSelectedDropdown("COMMITTEE_MAINTENANCE")
                setSidebarNav("COMMITTEE_MAINTENANCE_UPDATE")
                break;

            case "/admin/dashboard/notification-maintenance-view":
                setSelectedDropdown("NOTIFICATION_MAINTENANCE")
                setSidebarNav("NOTIFICATION_MAINTENANCE_VIEW")
                break;

            case "/admin/dashboard/notification-maintenance-create":
                setSelectedDropdown("NOTIFICATION_MAINTENANCE")
                setSidebarNav("NOTIFICATION_MAINTENANCE_CREATE")
                break;

            case "/admin/dashboard/notification-maintenance-update":
                setSelectedDropdown("NOTIFICATION_MAINTENANCE")
                setSidebarNav("NOTIFICATION_MAINTENANCE_UPDATE")
                break;

            case "/admin/dashboard/upload-data":
                setSelectedDropdown("UPLOAD_DATA")
                setSidebarNav("UPLOAD_DATA")
                break;

            case "/admin/dashboard/upload-documents-create":
                setSelectedDropdown("UPLOAD_DOCUMENTS")
                setSidebarNav("UPLOAD_DOCUMENTS_CREATE")
                break;

            case "/admin/dashboard/upload-documents-view":
                setSelectedDropdown("UPLOAD_DOCUMENTS")
                setSidebarNav("UPLOAD_DOCUMENTS_VIEW")
                break;

            case "/admin/dashboard/upload-documents-update":
                setSelectedDropdown("UPLOAD_DOCUMENTS")
                setSidebarNav("UPLOAD_DOCUMENTS_UPDATE")
                break;

            case "/admin/dashboard/upload-documents-save":
                setSelectedDropdown("UPLOAD_DOCUMENTS")
                setSidebarNav("UPLOAD_DOCUMENTS_SAVE")
                break;

            case "/admin/dashboard/update-quota":
                setSelectedDropdown("UPDATE_QUOTE")
                setSidebarNav("UPDATE_QUOTE")
                break;

            case "/admin/dashboard/update-statistic":
                setSelectedDropdown("UPDATE_STATISTIC_DATA")
                setSidebarNav("UPDATE_STATISTIC_DATA")
                break;

        }
    }

    //------ This useEffect use for redirect side bar button to the component-----//
    useEffect(() => {
        initialLoad(window.location.pathname);
    }, [window.location.pathname]);


    //------ onclick redirect to path-----//
    const selectNavItem = (sidebarItem) => {
        setSidebarNav(value => value = sidebarItem);

        switch (sidebarItem) {
            case "DESIGNATION_MAINTAINANCE_VIEW":
                history.push("/admin/dashboard/designation-maintenance-view");
                break;

            case "DESIGNATION_MAINTAINANCE_CREATE":
                history.push("/admin/dashboard/designation-maintenance-create");
                break;

            case "DESIGNATION_MAINTAINANCE_UPDATE":
                history.push("/admin/dashboard/designation-maintenance-update");
                break;

            case "DEPARTMENT_MAINTENANCE_VIEW":
                history.push("/admin/dashboard/department-maintenance-view");
                break;

            case "DEPARTMENT_MAINTENANCE_CREATE":
                history.push("/admin/dashboard/departments-maintenance-create");
                break;

            case "DEPARTMENT_MAINTENANCE_UPDATE":
                history.push("/admin/dashboard/departments-maintenance-update");
                break;

            case "USERS_MAINTENANCE_VIEW":
                history.push("/admin/dashboard/users-maintenance-view");
                break;

            case "USERS_MAINTENANCE_CREATE":
                history.push("/admin/dashboard/users-maintenance-create");
                break;

            case "USERS_MAINTENANCE_UPDATE":
                history.push("/admin/dashboard/users-maintenance-update");
                break;

            case "ROLES_MAINTENANCE_VIEW":
                history.push("/admin/dashboard/roles-maintenance-view");
                break;

            case "ROLES_MAINTENANCE_CREATE":
                history.push("/admin/dashboard/roles-maintenance-create");
                break;

            case "ROLES_MAINTENANCE_UPDATE":
                history.push("/admin/dashboard/roles-maintenance-update");
                break;

            case "COMMITTEE_MAINTENANCE_VIEW":
                history.push("/admin/dashboard/committee-maintenance-view");
                break;

            case "COMMITTEE_MAINTENANCE_CREATE":
                history.push("/admin/dashboard/committee-maintenance-create");
                break;

            case "COMMITTEE_MAINTENANCE_UPDATE":
                history.push("/admin/dashboard/committee-maintenance-update");
                break;

            case "NOTIFICATION_MAINTENANCE_VIEW":
                history.push("/admin/dashboard/notification-maintenance-view");
                break;

            case "NOTIFICATION_MAINTENANCE_CREATE":
                history.push("/admin/dashboard/notification-maintenance-create");
                break;

            case "NOTIFICATION_MAINTENANCE_UPDATE":
                history.push("/admin/dashboard/notification-maintenance-update");
                break;

            case "UPLOAD_DATA":
                history.push("/admin/dashboard/upload-data");
                break;

            case "UPLOAD_DOCUMENTS_CREATE":
                history.push("/admin/dashboard/upload-documents-create");
                break;

            case "UPLOAD_DOCUMENTS_VIEW":
                history.push("/admin/dashboard/upload-documents-view");
                break;

            case "UPLOAD_DOCUMENTS_UPDATE":
                history.push("/admin/dashboard/upload-documents-update");
                break;

            case "UPLOAD_DOCUMENTS_SAVE":
                history.push("/admin/dashboard/upload-documents-save");
                break;

            case "UPDATE_QUOTE":
                history.push("/admin/dashboard/update-quota");
                break;

            case "UPDATE_STATISTIC_DATA":
                history.push("/admin/dashboard/update-statistic");
                break;
        }
    }

    useEffect(() => {
        initialLoad(location.pathname);
    }, [location.pathname]);


    return (
        <>
            <section className="admin_main_section">

                <div className="row m-0">
                    <div className="col-3 side_navbar">

                        <div className="conatainer sidebar_parent">

                            <div className="row sidebar_child">
                                <div className={(selectedDropdown === "DESIGNATION_MAINTAINANCE" ? "sidebar_tab_selected" : "sidebar_tab")} onClick={() => setSelectedDropdown("DESIGNATION_MAINTAINANCE")}>
                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                        Designation Maintenance
                                        {selectedDropdown !== "DESIGNATION_MAINTAINANCE" ? <i className="fa fa-caret-down ms-2"></i> : <i className="fa fa-caret-up ms-2"></i>}
                                    </H5>
                                </div>

                                {selectedDropdown === "DESIGNATION_MAINTAINANCE" ?
                                    <div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "DESIGNATION_MAINTAINANCE_VIEW" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("DESIGNATION_MAINTAINANCE_VIEW")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >View</H5>
                                        </div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "DESIGNATION_MAINTAINANCE_CREATE" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("DESIGNATION_MAINTAINANCE_CREATE")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Create</H5>
                                        </div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "DESIGNATION_MAINTAINANCE_UPDATE" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("DESIGNATION_MAINTAINANCE_UPDATE")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Update</H5>
                                        </div>
                                    </div>
                                    : null
                                }


                                <div className={(selectedDropdown === "DEPARTMENT_MAINTENANCE" ? "sidebar_tab_selected" : "sidebar_tab")} onClick={() => setSelectedDropdown("DEPARTMENT_MAINTENANCE")}>
                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                        Department Maintenance
                                        {selectedDropdown !== "DEPARTMENT_MAINTENANCE" ? <i className="fa fa-caret-down ms-2"></i> : <i className="fa fa-caret-up ms-2"></i>}
                                    </H5>
                                </div>

                                {selectedDropdown === "DEPARTMENT_MAINTENANCE" ?
                                    <div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "DEPARTMENT_MAINTENANCE_VIEW" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("DEPARTMENT_MAINTENANCE_VIEW")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >View</H5>
                                        </div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "DEPARTMENT_MAINTENANCE_CREATE" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("DEPARTMENT_MAINTENANCE_CREATE")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Create</H5>
                                        </div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "DEPARTMENT_MAINTENANCE_UPDATE" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("DEPARTMENT_MAINTENANCE_UPDATE")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Update</H5>
                                        </div>
                                    </div>
                                    : null
                                }


                                <div className={(selectedDropdown === "USERS_MAINTENANCE" ? "sidebar_tab_selected" : "sidebar_tab")} onClick={() => setSelectedDropdown("USERS_MAINTENANCE")}>
                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                        User Maintenance
                                        {selectedDropdown !== "USERS_MAINTENANCE" ? <i className="fa fa-caret-down ms-2"></i> : <i className="fa fa-caret-up ms-2"></i>}
                                    </H5>
                                </div>

                                {selectedDropdown === "USERS_MAINTENANCE" ?
                                    <div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "USERS_MAINTENANCE_VIEW" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("USERS_MAINTENANCE_VIEW")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >View</H5>
                                        </div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "USERS_MAINTENANCE_CREATE" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("USERS_MAINTENANCE_CREATE")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Create</H5>
                                        </div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "USERS_MAINTENANCE_UPDATE" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("USERS_MAINTENANCE_UPDATE")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Update</H5>
                                        </div>
                                    </div>
                                    : null
                                }


                                <div className={(selectedDropdown === "ROLES_MAINTENANCE" ? "sidebar_tab_selected" : "sidebar_tab")} onClick={() => setSelectedDropdown("ROLES_MAINTENANCE")}>
                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                        Role Maintenance
                                        {selectedDropdown !== "ROLES_MAINTENANCE" ? <i className="fa fa-caret-down ms-2"></i> : <i className="fa fa-caret-up ms-2"></i>}
                                    </H5>
                                </div>

                                {selectedDropdown === "ROLES_MAINTENANCE" ?
                                    <div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "ROLES_MAINTENANCE_VIEW" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("ROLES_MAINTENANCE_VIEW")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >View</H5>
                                        </div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "ROLES_MAINTENANCE_CREATE" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("ROLES_MAINTENANCE_CREATE")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Create</H5>
                                        </div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "ROLES_MAINTENANCE_UPDATE" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("ROLES_MAINTENANCE_UPDATE")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Update</H5>
                                        </div>
                                    </div>
                                    : null
                                }


                                <div className={(selectedDropdown === "COMMITTEE_MAINTENANCE" ? "sidebar_tab_selected" : "sidebar_tab")} onClick={() => setSelectedDropdown("COMMITTEE_MAINTENANCE")}>
                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                        Committee Maintenance
                                        {selectedDropdown !== "COMMITTEE_MAINTENANCE" ? <i className="fa fa-caret-down ms-2"></i> : <i className="fa fa-caret-up ms-2"></i>}
                                    </H5>
                                </div>

                                {selectedDropdown === "COMMITTEE_MAINTENANCE" ?
                                    <div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "COMMITTEE_MAINTENANCE_VIEW" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("COMMITTEE_MAINTENANCE_VIEW")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >View</H5>
                                        </div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "COMMITTEE_MAINTENANCE_CREATE" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("COMMITTEE_MAINTENANCE_CREATE")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Create</H5>
                                        </div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "COMMITTEE_MAINTENANCE_UPDATE" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("COMMITTEE_MAINTENANCE_UPDATE")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Update</H5>
                                        </div>
                                    </div>
                                    : null
                                }


                                <div className={(selectedDropdown === "NOTIFICATION_MAINTENANCE" ? "sidebar_tab_selected" : "sidebar_tab")} onClick={() => setSelectedDropdown("NOTIFICATION_MAINTENANCE")}>
                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                        Notification Maintenance
                                        {selectedDropdown !== "NOTIFICATION_MAINTENANCE" ? <i className="fa fa-caret-down ms-2"></i> : <i className="fa fa-caret-up ms-2"></i>}
                                    </H5>
                                </div>

                                {selectedDropdown === "NOTIFICATION_MAINTENANCE" ?
                                    <div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "NOTIFICATION_MAINTENANCE_VIEW" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("NOTIFICATION_MAINTENANCE_VIEW")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >View</H5>
                                        </div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "NOTIFICATION_MAINTENANCE_CREATE" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("NOTIFICATION_MAINTENANCE_CREATE")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Create</H5>
                                        </div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "NOTIFICATION_MAINTENANCE_UPDATE" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("NOTIFICATION_MAINTENANCE_UPDATE")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Update</H5>
                                        </div>
                                    </div>
                                    : null
                                }

                                <div className={(selectedDropdown === "UPLOAD_DOCUMENTS" ? "sidebar_tab_selected" : "sidebar_tab")} onClick={() => setSelectedDropdown("UPLOAD_DOCUMENTS")}>
                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                        Upload Documents
                                        {selectedDropdown !== "UPLOAD_DOCUMENTS" ? <i className="fa fa-caret-down ms-2"></i> : <i className="fa fa-caret-up ms-2"></i>}
                                    </H5>
                                </div>

                                {selectedDropdown === "UPLOAD_DOCUMENTS" ?
                                    <div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "UPLOAD_DOCUMENTS_VIEW" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("UPLOAD_DOCUMENTS_VIEW")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >View</H5>
                                        </div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "UPLOAD_DOCUMENTS_CREATE" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("UPLOAD_DOCUMENTS_CREATE")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Create</H5>
                                        </div>
                                        <div className={"sidebar_tab mt-1 " + (sidebarNav === "UPLOAD_DOCUMENTS_UPDATE" ? "dropdown-button-selected" : "dropdown-button")}
                                            onClick={() => selectNavItem("UPLOAD_DOCUMENTS_UPDATE")}
                                        >
                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >Update</H5>
                                        </div>
                                    </div>
                                    : null
                                }
                                <div className={(selectedDropdown === "UPDATE_QUOTE" ? "sidebar_tab_selected" : "sidebar_tab")} onClick={() => { setSelectedDropdown("UPDATE_QUOTE"); selectNavItem("UPDATE_QUOTE") }}
                                >
                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                        Update Quota
                                    </H5>
                                </div>
                                <div className={(selectedDropdown === "UPLOAD_DATA" ? "sidebar_tab_selected" : "sidebar_tab")} onClick={() => { setSelectedDropdown("UPLOAD_DATA"); selectNavItem("UPLOAD_DATA") }}
                                >
                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                        Download Data
                                    </H5>
                                </div>
                                <div className={(selectedDropdown === "UPDATE_STATISTIC_DATA" ? "sidebar_tab_selected" : "sidebar_tab")} onClick={() => { setSelectedDropdown("UPDATE_STATISTIC_DATA"); selectNavItem("UPDATE_STATISTIC_DATA") }} >
                                    <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF">
                                        Update Statistic
                                    </H5>
                                </div>


                            </div>

                        </div>

                    </div>

                    <div className="col-9 Dashboard_right_side pe-0">
                        <div>
                            {
                                committeeId === null ?
                                    (<div></div>)
                                    :
                                    (
                                        <Route exact path="/admin/dashboard/committee-maintenance-save" render={() => {
                                            return (<Redirect to={"/admin/dashboard/committee-maintenance-save?committeeId=" + committeeId} />);
                                        }} />
                                    )
                            }

                            {
                                roleId === null ?
                                    (<div></div>)
                                    :
                                    (
                                        <Route exact path="/admin/dashboard/roles-maintenance-save" render={() => {
                                            return (<Redirect to={"/admin/dashboard/roles-maintenance-save?roleId=" + roleId} />);
                                        }} />
                                    )
                            }

                            {
                                userId === null ?
                                    (<div></div>)
                                    :
                                    (
                                        <Route exact path="/admin/dashboard/user-maintenance-save" render={() => {
                                            return (<Redirect to={"/admin/dashboard/user-maintenance-save?userId=" + userId} />);
                                        }} />
                                    )
                            }

                            {
                                departmentId === null ?
                                    (<div></div>)
                                    :
                                    (
                                        <Route exact path="/admin/dashboard/department-maintenance-save" render={() => {
                                            return (<Redirect to={"/admin/dashboard/department-maintenance-save?departmentId=" + departmentId} />);
                                        }} />
                                    )
                            }

                            {
                                designationId === null ?
                                    (<div></div>)
                                    :
                                    (
                                        <Route exact path="/admin/dashboard/designation-maintenance-save" render={() => {
                                            return (<Redirect to={"/admin/dashboard/designation-maintenance-save?designationId=" + designationId} />);
                                        }} />
                                    )
                            }

                            {
                                notificationId === null ?
                                    (<div></div>)
                                    :
                                    (
                                        <Route exact path="/admin/dashboard/notification-maintenance-save" render={() => {
                                            return (<Redirect to={"/admin/dashboard/notification-maintenance-save?notificationId=" + notificationId} />);
                                        }} />
                                    )
                            }

                            {
                                documentId === null ?
                                    (<div></div>)
                                    :
                                    (
                                        <Route exact path="/admin/dashboard/upload-documents-save" render={() => {
                                            return (<Redirect to={"/admin/dashboard/upload-documents-save?documentId=" + documentId} />);
                                        }} />
                                    )
                            }
                            <Route
                                exact path="/admin/dashboard"
                                render={() => { return (<Redirect to="/admin/dashboard/admin-welcome" />); }}
                            />
                            {
                                isUserVerifiedAgain ?
                                    (<div className="col-12 p-0 ">
                                        <Route exact path="/admin" render={() => {
                                            return (<Redirect to="/admin/dashboard/admin-welcome" />);
                                        }} />
                                        {
                                            isAdminAuthenticated ?
                                                <Route exact path="/admin/dashboard/admin-welcome" component={AdminWelcome} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/designation-maintenance-view" component={DesignationMaintainanceView} />
                                                :
                                                <Redirect to="/admin/login" />

                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/designation-maintenance-create" component={DesignationMaintainanceCreate} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?

                                                <Route path="/admin/dashboard/designation-maintenance-update" component={DesignationMaintainanceUpdate} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/designation-maintenance-save" component={DesignationMaintainanceSave} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/department-maintenance-view" component={DepartmentMaintenanceView} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/departments-maintenance-create" component={DepartmentMaintainanceCreate} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/departments-maintenance-update" component={DepartmentMaintenanceUpdate} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/department-maintenance-save" component={DepartmentMaintainanceSave} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/users-maintenance-view" component={UsersMaintenanceView} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/users-maintenance-create" component={UsersMaintainanceCreate} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/users-maintenance-update" component={UsersMaintenanceUpdate} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/user-maintenance-save" component={UsersMaintainanceSave} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/roles-maintenance-view" component={RoleMaintenanceView} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/roles-maintenance-create" component={RoleMaintenanceCreate} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/roles-maintenance-update" component={RoleMaintenanceUpdate} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/roles-maintenance-save" component={RoleMaintenanceSave} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/committee-maintenance-view" component={CommitteeMaintenanceView} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/committee-maintenance-create" component={CommitteeMaintenanceCreate} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/committee-maintenance-update" component={CommitteeMaintenanceUpdate} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/committee-maintenance-save" component={CommitteeMaintenanceSave} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/notification-maintenance-view" component={NotificationMaintenanceView} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/notification-maintenance-create" component={NotificationMaintenanceCreate} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/notification-maintenance-update" component={NotificationMaintenanceUpdate} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/upload-data" component={UploadData} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/notification-maintenance-save" component={NotificationMaintenanceSave} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/upload-documents-create" component={UploadDocumentsCreate} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/upload-documents-view" component={UploadDocumentsView} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/upload-documents-update" component={UploadDocumentsUpdate} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/upload-documents-save" component={UploadDocumentsSave} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/update-quota" component={UpdateQuote} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                        {
                                            isAdminAuthenticated ?
                                                <Route path="/admin/dashboard/update-statistic" component={UpdateGraphData} />
                                                :
                                                <Redirect to="/admin/login" />
                                        }
                                    </div>)
                                    :
                                    <span></span>
                            }

                        </div>
                    </div>
                </div>

                <AdminLogout />

            </section>
        </>
    )
}

export default AdminDashboard;