import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminCommunication } from "../../communications/admin-communication";
import LoadingForms from "../../shared-components/LoadingForms";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import H5 from "../../text-components/H5";
import { confirm } from "react-confirm-box";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";



const DepartmentMaintenanceUpdate = () => {

    const darkWhiteThemeToggle = useSelector((state) => state.darkWhiteThemeToggle);
    const [isLoading, setIsLoading] = useState({ message: "", value: false });
    const [allDepartmentsMaintanceData, setAllDepartmentsMaintanceData] = useState([]);
    const [idsOfDepartment, setIdsOfDepartment] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const limit = process.env.REACT_APP_PAGE_LIMIT;
    const history = useHistory();

    //spinner show and hide function declaration
    function showLoading(message) {
        setIsLoading(value => value = { ...value, ...{ message: message, value: true } });
    }

    function hideLoading() {
        setIsLoading(value => value = { ...value, ...{ message: "", value: false } });
    }

    
    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchDepartementsBySearch(searchValue, selectedPage + 1);
        } else {
            fetchAllDepartments(selectedPage + 1);
        }
    }

    //Search Filter 
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchDepartementsBySearch(searchString, currentPage + 1);
        } else {
            const departmentsFromServer = await adminCommunication.getAllDepartments();
            console.log("inside code",departmentsFromServer);
            if (departmentsFromServer.status === "SUCCESS") {
                let departmentsArray = departmentsFromServer.departments;

                setAllDepartmentsMaintanceData(departmentsArray);
                setPageCount(departmentsFromServer?.totalPages);
            } else {
                showModal(departmentsFromServer.message);
                setAllDepartmentsMaintanceData([]);
                setPageCount(0);
            }
        }
    }

     //get departments by search
    const fetchDepartementsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await adminCommunication.getDepartmentsBySearch(searchString, page);
            console.log("inside code",responseFromServer);
            if (responseFromServer?.data?.status === "SUCCESS") {
                let departmentsArray = responseFromServer?.data?.departments;

                setAllDepartmentsMaintanceData(departmentsArray);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                history.push("/admin/login");
                showModal(responseFromServer?.data?.message);
            } else {
                setAllDepartmentsMaintanceData([]);
                setPageCount(0);
            }
        } catch (error) {
            showModal(error?.message);
        }
    }
 //get all departments Initial Load
    const fetchAllDepartments = async (page) => {
    try {
        showLoading("Loading...");
        dispatch({ type: "ACTIVE_SIDEBAR_TAB", payload: "DEPARTMENT_MAINTENANCE_UPDATE" });
        const departmentsFromServer = await adminCommunication.getAllDepartments(page);
        console.log("departmentsFromServer",departmentsFromServer);
        if (departmentsFromServer.status === "SUCCESS") {
            let departmentsArray = departmentsFromServer.departments;

            setAllDepartmentsMaintanceData(departmentsArray);
            setPageCount(departmentsFromServer?.totalPages);
        } else {
            showModal(departmentsFromServer.message);
            setAllDepartmentsMaintanceData([]);
            setPageCount(0);
        }
        hideLoading();
    } catch (error) {
        showModal(error?.message);
    }
}

    useEffect(async () => {
        fetchAllDepartments()  ;

    }, []);

    //Alert Modal react-redux
    const dispatch = useDispatch();
    function showModal(text) {
        dispatch({ type: "SHOW_TEXT_MODAL", payload: text });
    }

    // set ids in to state
    const handleOnChange = (e) => {
        let checked = e.target.checked;
        let id = e.target.value;
        if (checked) {
            setIdsOfDepartment(() => [...idsOfDepartment, id]);
        } else {
            // to remove from local storage
            setIdsOfDepartment(
                idsOfDepartment.filter((Id) => Id !== id)
            );
        }
        return;
    }

    // delete committee by id
    const deleteDepartment = async (options) => {
        if (idsOfDepartment.length !== 0) {
            const confirmBox = await confirm(`Are you sure , do you want to delete this department . If you deleted this department,all the related  office users will be deleted.`, options);
            if (confirmBox === true) {
                showLoading("Loading...");
                const deleteDepartmentByIdData = await adminCommunication.deleteDepartmentById(idsOfDepartment);
                if (deleteDepartmentByIdData.status === "SUCCESS") {
                    // calling refreshPage function after 4 sec to show model before refreshing the page
                    setTimeout(() => {
                        history.push(`/admin/dashboard/department-maintenance-view`);
                    }, 1000);
                }
                showModal(deleteDepartmentByIdData.message);

            }
        }
        else {
            showModal("Please select atleast one record.");
        }
        hideLoading();
        return;
    }
    // redirect to committee maintenance save according to Department id
    const departmentEdit = (departmentId) => {
        history.push(`/admin/dashboard/department-maintenance-save?departmentId=${departmentId}`);
        return;
    }

    return (
        <>
            <section className="dashboard_background_update" >
                {isLoading.value ?
                    <LoadingForms message={isLoading.message} />
                    :

                    <div className="dashbord_main">
                    <div className="row search_wrapper">
                    <div className="col-lg-4 col-md-4 ps-0">
                        <input type="search" onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} className="form-control" placeholder="Search By Department, Designation..." />
                    </div>
                    <div className="col-lg-8 col-md-8 pagination_outer_wrapper">
                        <div className="pagination_inner_wrapper">
                            {pageCount > 1 &&
                                <ReactPaginate
                                    previousLabel={currentPage === 0 ? "First" : <i className="fa fa-angle-double-left"></i>}
                                    nextLabel={(currentPage + 1) === pageCount ? "Last" : <i className="fa fa-angle-double-right"></i>}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            }
                        </div>
                    </div>
                </div>
                        {/*-----Grievance card header section---*/}
                        <div className="dashboard_view_data_heading">
                            <div className="row m-0">
                                <div className="col-lg-1 col-md-2 col-1 p-0 view_dashboard">
                                    <span><H5>Serial No.</H5></span>
                                </div>
                                <div className="col-lg-4 col-md-3 col-3 p-0 view_dashboard">
                                    <span><H5>Department Name</H5></span>
                                </div>
                                <div className="col-lg-2 col-md-2 col-3 p-0 view_dashboard">
                                    <span><H5>location</H5></span>
                                </div>
                                <div className="col-lg-3 col-md-3 col-3 p-0 view_dashboard">
                                    <span><H5>Designation</H5></span>
                                </div>
                                <div className="col-lg-2 col-md-2 col-2 p-0 ">
                                    <span><H5>Creation Date</H5></span>
                                </div>
                            </div>
                        </div>

                        {allDepartmentsMaintanceData.length > 0 ?
                            <div>

                                {/*-----Grievance card data section---*/}
                                {allDepartmentsMaintanceData.map((users, index) => {
                                    const { _id, name, location, designationNameArray, creationDate } = users;

                                    return (
                                        <div key={index}>
                                            <div className="dashboard_view_data_main">
                                                <div className="dashboard_view_data">
                                                    <div className="row m-0">
                                                        <div className="col-lg-1 col-md-2 col-1 p-0 view_dashboard sr-no text_align">
                                                            <span>
                                                                {/* <input type="checkbox" value={_id} onClick={(e) => handleOnChange(e)} /> */}
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                {limit * currentPage + (index + 1)}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-lg-4 col-md-3 col-3 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    <a onClick={() => departmentEdit(_id)}>
                                                                        {name}
                                                                    </a>
                                                                </H5>
                                                            </span>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-3 p-0 view_dashboard text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {location}
                                                                </H5>
                                                            </span>
                                                        </div>

                                                        <div className="col-lg-3 col-md-3 col-3 p-0 view_dashboard text_align">
                                                            <span >
                                                                <div className="arrayFetch">
                                                                    {designationNameArray.map((designation, index, arr) => {
                                                                        const { name } = designation;
                                                                        return (
                                                                            <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" key={index} >
                                                                                {index != (arr.length - 1) ? `${designation},` : designation}

                                                                            </H5>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-2 p-0 text_align">
                                                            <span>
                                                                <H5 whiteThemeColor="#0D2750" darkThemeColor="#FFFFFF" >
                                                                    {new Date(users.creationDate).getDate()}-{new Date(users.creationDate).getMonth() + 1}-{new Date(users.creationDate).getFullYear()}
                                                                </H5>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    );
                                })}
                            </div>
                            :
                            <div className="col-12 text-center view_download_dashboard">
                                <h6>Records not available.</h6>
                            </div>
                        }

                    </div>
                }
            </section>
            {/* <section className="mb-3">
                <div className="row">
                    <div className="col-2 button_div">
                        <div className="dashboard_delete_button">
                            <button type="button" className="btn" onClick={() => deleteDepartment()}>
                                {isLoading.value ? <img src="/assets/images/gif/loading-circle.gif" style={{ width: "10px", height: "10px" }} /> : <H5>Delete</H5>}
                            </button>
                        </div>
                    </div>
                </div>
            </section> */}

        </>

    )
}

export default DepartmentMaintenanceUpdate;